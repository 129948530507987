import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles/createTheme';
import { typography } from 'components/common/Typography/styles';

export const buttonOverrides: Components<Theme>['MuiButton'] = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      // Reduce by 1 due to border
      padding: '7px 14px',
      borderRadius: 4,
      transition: 'opacity 0.2s',
      lineHeight: 1.25,
      textTransform: 'none',
      '&:hover': {
        opacity: '0.8',
      },
      fontFamily: typography['body-xs'].fontFamily,
    },
  },
  variants: [
    {
      props: { variant: 'primary' },
      style: ({ theme }) => {
        return {
          background: theme.colors?.primary.maroon,
          color: theme.colors?.primary.white,
          border: `1px solid ${theme.colors?.primary.maroon}`,
          '&:hover': {
            backgroundColor: theme.colors?.primary.maroon,
          },
          '&:disabled': {
            color: `${theme.colors?.primary.white} !important`,
            backgroundColor: `${theme.colors?.utility[500]} !important`,
            borderColor: `${theme.colors?.utility[500]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'primary-alt' },
      style: ({ theme }) => {
        return {
          background: theme.colors?.primary.black,
          color: theme.colors?.primary.parchment,
          border: `1px solid ${theme.colors?.primary.black}`,
          '&:hover': {
            backgroundColor: theme.colors?.primary.black,
          },
          '&:disabled': {
            color: `${theme.colors?.primary.parchment} !important`,
            backgroundColor: `${theme.colors?.utility[500]} !important`,
            borderColor: `${theme.colors?.utility[500]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'primary-reverse' },
      style: ({ theme }) => {
        return {
          background: theme.colors?.primary.white,
          color: theme.colors?.primary.maroon,
          border: `1px solid ${theme.colors?.primary.white}`,
          '&:hover': {
            backgroundColor: theme.colors?.primary.white,
          },
          '&:disabled': {
            color: `${theme.colors?.primary.white} !important`,
            backgroundColor: `${theme.colors?.utility[500]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'secondary' },
      style: ({ theme }) => {
        return {
          background: 'transparent',
          color: theme.colors?.primary.maroon,
          border: `1px solid ${theme.colors?.primary.maroon}`,
          '&:disabled': {
            color: `${theme.colors?.utility[500]} !important`,
            borderColor: `${theme.colors?.utility[500]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'secondary-reverse' },
      style: ({ theme }) => {
        return {
          background: 'transparent',
          color: theme.colors?.primary.white,
          border: `1px solid ${theme.colors?.primary.white}`,
          '&:disabled': {
            color: `${theme.colors?.utility[500]} !important`,
            borderColor: `${theme.colors?.utility[500]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'tertiary' },
      style: ({ theme }) => {
        return {
          background: theme.colors?.utility[300],
          color: theme.colors?.primary.black,
          '&:disabled': {
            color: `${theme.colors?.utility[700]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'text' },
      style: ({ theme }) => {
        return {
          background: 'transparent',
          color: theme.colors?.primary.black,
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiTouchRipple-root': {
            display: 'none !important',
          },
        };
      },
    },
    {
      props: { variant: 'pill' },
      style: ({ theme }) => {
        return {
          fontWeight: 500,
          background: theme.colors?.primary.black,
          color: theme.colors?.primary.parchment,
          padding: theme.spacing(2, 6),
          border: `1.5px solid ${theme.colors?.primary.black}`,
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: theme.colors?.primary.black,
          },
          '&:disabled': {
            backgroundColor: `${theme.colors?.utility[400]} !important`,
            borderColor: `${theme.colors?.utility[400]} !important`,
          },
        };
      },
    },
    {
      props: { variant: 'pill-reverse' },
      style: ({ theme }) => {
        return {
          fontWeight: 500,
          background: theme.colors?.primary.parchment,
          color: theme.colors?.primary.black,
          padding: theme.spacing(2, 6),
          border: `1.5px solid ${theme.colors?.primary.black}`,
          borderRadius: '100px',
          '&:hover': {
            backgroundColor: theme.colors?.primary.parchment,
          },
          '&:disabled': {
            backgroundColor: `${theme.colors?.utility[400]} !important`,
            borderColor: `${theme.colors?.utility[400]} !important`,
          },
        };
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: 18,
      },
    },
    {
      props: { size: 'medium' },
      style: {
        fontSize: 16,
      },
    },
    {
      props: { size: 'small' },
      style: {
        fontSize: 13,
      },
    },
  ],
};
