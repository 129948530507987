import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { ProgressBar } from 'components/common/ProgressBar';
import { useGetBrandsParsedHoursForBillingHourUsageProgressQuery } from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { Parser } from 'utils/parser';

// eslint-disable-next-line
gql`
  query GetBrandsParsedHoursForBillingHourUsageProgress(
    $totalHoursParsedData: BrandHoursParsedInput
  ) {
    me {
      id
      organization {
        id
        billing {
          id
          brandHoursParseLimit
        }
        socialListeningBrands {
          id
          name
          totalHoursParsedCount(data: $totalHoursParsedData)
        }
      }
    }
  }
`;

type BillingHourUsageProgressProps = {
  sx?: SxProps;
};

export const BillingHourUsageProgress = (
  props: BillingHourUsageProgressProps,
) => {
  const { sx } = props;

  const { data: parsedHoursData } =
    useGetBrandsParsedHoursForBillingHourUsageProgressQuery({
      variables: {
        totalHoursParsedData: {
          startDate: moment().startOf('month').toDate(),
          endDate: moment().endOf('month').toDate(),
        },
      },
    });

  const totalHoursParsed = useMemo(() => {
    if (!parsedHoursData) {
      return 0;
    }

    return parsedHoursData.me.organization.socialListeningBrands.reduce(
      (totalHoursParsed, brand) => {
        return totalHoursParsed + brand.totalHoursParsedCount;
      },
      0,
    );
  }, [parsedHoursData]);

  const totalHoursLimit =
    parsedHoursData?.me.organization.billing.brandHoursParseLimit || 0;

  const percentageUsed = useMemo(() => {
    return Parser.to2Decimal((totalHoursParsed / totalHoursLimit) * 100);
  }, [totalHoursParsed, totalHoursLimit]);

  const progressBarFilledColor = useMemo(() => {
    if (percentageUsed >= 0 && percentageUsed < 70) {
      return theme.colors?.utility['green-2'];
    }

    if (percentageUsed >= 70 && percentageUsed < 80) {
      return theme.colors?.utility['yellow-3'];
    }

    if (percentageUsed >= 80 && percentageUsed < 90) {
      return theme.colors?.utility['orange-3'];
    }

    return theme.colors?.utility['pink-3'];
  }, [percentageUsed]);

  if (!totalHoursLimit || !percentageUsed) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={theme.spacing(2)} sx={sx}>
      <Box>
        <Typography color={theme.colors?.utility[1000]} variant="headline-xxs">
          Hours parsed
        </Typography>
        <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
          {' '}
          • {percentageUsed}% used
        </Typography>
      </Box>
      <Box width="100%">
        <ProgressBar
          variant="percentage"
          completed={percentageUsed}
          total={100}
          bgColor={theme.colors?.utility[275]}
          filledColor={progressBarFilledColor}
        />
      </Box>

      <Typography variant="subhead-sm" color={theme.colors?.utility[700]}>
        {totalHoursParsed} hours of {totalHoursLimit} hours
      </Typography>
    </Box>
  );
};
