import { gql } from '@apollo/client';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { HighlightableSection } from 'components/common/HighlightableSection';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { useUserContext } from 'contexts/users/User.context';
import {
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import {
  SentimentColorMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity';
import { SocialPostCommentCardView } from 'features/socialPostComment';
import {
  CreatorProfilePaginatedSuperfanEngagementInputSortField,
  CreatorProfileSuperfanEngagementStatsInput,
  SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc,
  useGetSocialPostCommentsForSlCreatorProfileSuperfanEngagementViewQuery,
  useGetStatsForSlCreatorProfileSuperfanEngagementViewQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SocialListeningCreatorProfileSuperfanEngagementViewSkeleton } from './SocialListeningCreatorProfileSuperfanEngagementViewSkeleton';

// eslint-disable-next-line
gql`
  query GetStatsForSLCreatorProfileSuperfanEngagementView(
    $input: CreatorProfileSuperfanEngagementStatsInput!
  ) {
    getCreatorProfileSuperfanEngagementStats(input: $input) {
      maxLikes
      sentiment
      totalComments
      totalEngagement
      sentimentScore
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetSocialPostCommentsForSLCreatorProfileSuperfanEngagementView(
    $brandId: String!
    $input: CreatorProfilePaginatedSuperfanEngagementInput!
  ) {
    getCreatorProfileSuperfanEngagement(input: $input) {
      data {
        ...SocialPostCommentFragmentSocialPostCommentCardView
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc}
`;

export type SocialListeningCreatorProfileSuperfanEngagementViewProps = {
  filters: CreatorProfileSuperfanEngagementStatsInput;
  setSuperfanEngagementCount: (count: number) => void;
  setSentimentScore: (score: number) => void;
};

export const SocialListeningCreatorProfileSuperfanEngagementView = ({
  filters,
  setSuperfanEngagementCount,
  setSentimentScore,
}: SocialListeningCreatorProfileSuperfanEngagementViewProps) => {
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const [sortKey, setSortKey] =
    useState<CreatorProfilePaginatedSuperfanEngagementInputSortField>(
      CreatorProfilePaginatedSuperfanEngagementInputSortField.Recency,
    );

  const { data: engagementStatsData, loading: engagementStatsLoading } =
    useGetStatsForSlCreatorProfileSuperfanEngagementViewQuery({
      variables: {
        input: filters,
      },
    });

  const socialPostCommentVariables = useMemo(() => {
    return {
      brandId: filters.brandId,
      input: {
        ...filters,
        take: 10,
        sortBy: {
          field: sortKey,
        },
      },
    };
  }, [filters, sortKey]);

  const {
    data: socialPostCommentData,
    loading: socialPostCommentLoading,
    fetchMore: fetchMoreSocialPostComments,
  } = useGetSocialPostCommentsForSlCreatorProfileSuperfanEngagementViewQuery({
    variables: socialPostCommentVariables,
  });

  const socialPostComments =
    socialPostCommentData?.getCreatorProfileSuperfanEngagement.data || [];

  const statsData =
    engagementStatsData?.getCreatorProfileSuperfanEngagementStats;

  const sortOptions = [
    {
      label: 'Total like count',
      value: CreatorProfilePaginatedSuperfanEngagementInputSortField.DiggCount,
    },
    {
      label: 'Total reply count',
      value:
        CreatorProfilePaginatedSuperfanEngagementInputSortField.ChildCommentCount,
    },
    {
      label: 'Total engagement',
      value:
        CreatorProfilePaginatedSuperfanEngagementInputSortField.EngagementRate,
    },
    {
      label: 'Recency',
      value: CreatorProfilePaginatedSuperfanEngagementInputSortField.Recency,
    },
  ];

  const stats = useMemo(() => {
    const renderSkeleton = (width = 50) => {
      return <Skeleton variant="text" width={width} height={30} />;
    };
    return [
      {
        label: 'Number of comments',
        text: engagementStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.totalComments ?? 0),
      },
      {
        label: 'Total engagement',
        text: engagementStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.totalEngagement ?? 0),
      },
      {
        label: 'Max likes',
        text: engagementStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.maxLikes ?? 0),
      },
      {
        label: 'Overall sentiment',
        text: engagementStatsLoading
          ? renderSkeleton(120)
          : statsData && (
              <Typography
                variant="headline-xl"
                fontSize={24}
                fontWeight={500}
                color={SentimentColorMap[statsData.sentiment]}
              >
                {SentimentLabelMap[statsData.sentiment]}
              </Typography>
            ),
      },
    ];
  }, [engagementStatsLoading, statsData]);

  useEffect(() => {
    if (statsData) {
      setSuperfanEngagementCount(statsData.totalComments);
      setSentimentScore(statsData.sentimentScore);
    }
  }, [statsData?.totalEngagement, statsData?.sentimentScore]); // eslint-disable-line

  if (engagementStatsLoading) {
    return <SocialListeningCreatorProfileSuperfanEngagementViewSkeleton />;
  }

  return (
    <HighlightableSection
      sectionKey="superfan-engagement"
      sx={{
        p: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          display="flex"
          alignItems="center"
          gap={1}
        >
          Superfan engagement
          <Tooltip title="Comments on brand's posts">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconBoldInfoCircleAlt size={16} />
            </Box>
          </Tooltip>
        </Typography>
        <NestedFiltersMenuView
          values={{
            sort: {
              value: sortOptions.find((option) => option.value === sortKey),
            },
          }}
          onChange={(values) => {
            setSortKey(
              (values.sort.value as NestedFiltersOptionType)
                .value as CreatorProfilePaginatedSuperfanEngagementInputSortField,
            );
          }}
          items={[
            {
              type: 'single-select',
              key: 'sort',
              options: sortOptions,
            },
          ]}
          componentsProps={{
            trigger: {
              text: sortOptions.find((option) => option.value === sortKey)
                ?.label,
              Icon: IconLinearSort,
            },
          }}
        />
      </Box>

      {(socialPostCommentLoading || socialPostComments.length > 0) && (
        <Box
          sx={{
            background: theme.colors?.utility[275],
            borderRadius: 3,
            display: 'flex',
            flexWrap: 'wrap',
            p: 4,
            gap: 4,
          }}
        >
          {stats.map((stat, index) => (
            <Box
              sx={{
                [theme.breakpoints.up('md')]: {
                  borderRight:
                    index + 1 !== stats.length
                      ? `1px solid ${theme.colors?.utility[400]}`
                      : 'none',
                },
                flex: 1,
                minWidth: 140,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 3,
                }}
              >
                <Typography
                  variant="headline-xxs"
                  color={theme.colors?.utility[800]}
                >
                  {stat.label}
                </Typography>
                <Typography
                  variant="headline-xl"
                  fontSize={24}
                  fontWeight={500}
                >
                  {stat.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Box
        sx={{
          maxHeight: 500,
          overflowY: 'auto',
        }}
      >
        <InfiniteScroll
          loadMore={() => {
            fetchMoreSocialPostComments({
              variables: {
                input: {
                  ...socialPostCommentVariables.input,
                  skip: socialPostComments.length,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  getCreatorProfileSuperfanEngagement: {
                    ...fetchMoreResult.getCreatorProfileSuperfanEngagement,
                    data: [
                      ...prev.getCreatorProfileSuperfanEngagement.data,
                      ...fetchMoreResult.getCreatorProfileSuperfanEngagement
                        .data,
                    ],
                  },
                };
              },
            });
          }}
          hasMore={
            socialPostCommentData?.getCreatorProfileSuperfanEngagement.pageInfo
              .hasNextPage
          }
          threshold={250}
          useWindow={false}
          initialLoad={false}
          loader={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mt: 4,
              }}
            >
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[900]}
              >
                Loading...
              </Typography>
            </Box>
          }
        >
          {socialPostComments.length ? (
            <Grid container spacing={6}>
              {socialPostComments.map((socialPostComment) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    xl={4}
                    key={socialPostComment.id}
                    onClick={(e) => {
                      analytics.track(
                        EventName.CreatorProfileAboutPostClicked,
                        {
                          brandId: filters.brandId,
                          userId: user?.id,
                          section: 'superfan-engagement',
                          commentId: socialPostComment.id,
                        },
                      );
                    }}
                  >
                    <SocialPostCommentCardView
                      socialPostComment={socialPostComment}
                      brandId={filters.brandId}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[600]}
              >
                {socialPostCommentLoading
                  ? 'Loading...'
                  : 'No superfan engagement spotted 👀'}
              </Typography>
            </Box>
          )}
        </InfiniteScroll>
      </Box>
    </HighlightableSection>
  );
};
