import { Box, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileMomentsViewSkeleton = () => {
  return (
    <Box
      sx={{
        px: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        maxHeight: 400,
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: theme.colors?.primary.white,
          py: 6,
          zIndex: 1,
        }}
      >
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Moments
        </Typography>
        <Skeleton
          variant="rectangular"
          width={120}
          height={32}
          sx={{ borderRadius: 2 }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          mb: 6,
        }}
      >
        {[1, 2, 3].map((_, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'flex-start',
              p: theme.spacing(6, 4, 0, 4),
              ...(index === 0 ? { pt: 0, mt: -4 } : {}),
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                height: '100%',
                alignItems: 'center',
              }}
            >
              <Skeleton variant="circular" width={15} height={15} />
              {index !== 2 && (
                <Box
                  sx={{
                    width: 2,
                    height: '100%',
                    backgroundColor: theme.colors?.utility[300],
                    mb: -6,
                  }}
                />
              )}
            </Box>
            <Skeleton
              variant="rectangular"
              width={48}
              height={68}
              sx={{ borderRadius: 2 }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                maxWidth: 472,
                ml: 2,
                flex: 1,
              }}
            >
              <Skeleton variant="text" width={120} height={24} />
              <Skeleton variant="text" width="100%" height={24} />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
