import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconOutlineTrash } from 'components/icons/components/outline/IconOutlineTrash';
import { bookmarkOptions } from 'features/bookmark';
import { useBrandCompetitorBookmarkHandlers } from 'features/brandCompetitors/hooks';
import {
  CompetitorFragmentForBrandCompetitorActionContextMenuFragment,
  useDeleteCompetitorForBrandCompetitorActionContextMenuMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  fragment CompetitorFragmentForBrandCompetitorActionContextMenu on CompetitorModel {
    id
    isBookmarked
  }
`;

// eslint-disable-next-line
gql`
  mutation DeleteCompetitorForBrandCompetitorActionContextMenu(
    $data: RemoveCompetitorsInput!
  ) {
    removeCompetitors(data: $data) {
      id
    }
  }
`;

type BrandCompetitorActionContextMenuProps = Omit<
  ContextMenuProps,
  'options'
> & {
  onAfterDelete?: () => void;
  competitor: CompetitorFragmentForBrandCompetitorActionContextMenuFragment;
  canDelete: boolean;
};

export const BrandCompetitorActionContextMenu = (
  props: BrandCompetitorActionContextMenuProps,
) => {
  const { onAfterDelete, canDelete, competitor, renderButton, ...rest } = props;

  const { onBookmarkCompetitor, onRemoveCompetitorBookmark } =
    useBrandCompetitorBookmarkHandlers();
  const [deleteCompetitor] =
    useDeleteCompetitorForBrandCompetitorActionContextMenuMutation();

  const {
    dialog: deleteProductDialog,
    onOpen: openDeleteProductConfirmationDialog,
  } = useConfirmationDialog();

  const menuOptions = [
    {
      title: 'Delete Competitor',
      icon: IconOutlineTrash,
      color: theme.colors?.utility['pink-3'],
      iconBgColor: 'rgba(128, 5, 50, 0.08)',
      onClick: () =>
        openDeleteProductConfirmationDialog({
          title: (
            <Typography
              variant="headline-lg"
              color={theme.colors?.primary.black}
            >
              Delete Competitor?
            </Typography>
          ),
          subtitle: (
            <Box
              sx={{
                display: 'grid',
                gap: 3,
              }}
            >
              <Typography
                variant="subhead-xl"
                color={theme.colors?.primary.black}
              >
                Deleting this will permanently remove the competitor.
              </Typography>
              <Typography
                variant="subhead-xl"
                color={theme.colors?.primary.black}
              >
                Do you want to continue?
              </Typography>
            </Box>
          ),
          confirmText: 'Yes, continue to delete.',
          onConfirm: () => {
            deleteCompetitor({
              variables: {
                data: {
                  competitorIds: [competitor.id],
                },
              },
              update: (cache) => {
                evictObject(cache, competitor.id, 'CompetitorModel');
              },
            });
            onAfterDelete?.();
          },
        }),
      disabled: !canDelete,
      closeOnClick: false,
    },
  ];

  const stopEvents = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <ContextMenu
        onClick={(e) => {
          e.stopPropagation();
        }}
        renderButton={renderButton ?? false}
        options={[
          ...bookmarkOptions({
            isBookmarked: competitor.isBookmarked,
            onToggleBookmark: () => {
              if (competitor.isBookmarked) {
                onRemoveCompetitorBookmark(competitor.id);
              } else {
                onBookmarkCompetitor(competitor.id);
              }
            },
          }),
          ...menuOptions.map((option) => {
            const Icon = option.icon;
            return {
              renderCustomComponent: () => (
                <IconButton
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    p: 2,
                    borderRadius: 2,
                    width: '100%',
                    justifyContent: 'flex-start',
                    opacity: option.disabled ? 0.6 : 1,
                  }}
                  onClick={option.onClick}
                  disabled={option.disabled}
                >
                  <Box
                    sx={{
                      height: 24,
                      width: 24,
                      borderRadius: 1,
                      background: option.iconBgColor ?? 'rgba(35, 6, 3, 0.05)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {Icon && <Icon size={16} color={option.color} />}
                  </Box>
                  <Typography variant="subhead-lg" color={option.color}>
                    {option.title}
                  </Typography>
                </IconButton>
              ),
              closeOnClick: option.closeOnClick,
            };
          }),
        ]}
        MenuListProps={{
          sx: {
            width: 266,
            gap: `${theme.spacing(2)} !important`,
          },
        }}
        disableRestoreFocus
        PaperProps={{
          style: {
            background: `rgba(255, 255, 255, 0.80)`,
            backdropFilter: `blur(20px)`,
            border: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onMouseDown={stopEvents}
        onMouseMove={stopEvents}
        {...rest}
      />
      {deleteProductDialog}
    </>
  );
};
