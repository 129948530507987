import { Box, Divider, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconLinearMoreVert } from 'components/icons/components/linear/IconLinearMoreVert';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { bookmarkOptions } from 'features/bookmark';
import { useSocialListeningAnalyticsBookmarkHandlers } from 'features/socialListeningAnalytics';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import {
  SocialListeningFeatureType,
  SocialMediaListeningTopHeaderBreadcrumbs,
  useBrandDetails,
} from 'features/socialMediaListening';
import { BookmarkType, ViewEntityType } from 'graphql/generated';
import { usePageViewEntity } from 'hooks/useViewEntity';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialMediaListeningAnalyticsPage = () => {
  const { brandId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  usePageViewEntity(brandId, ViewEntityType.BrandAnalytics);

  const {
    setRenderTitle,
    setHeaderSx,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
    renderMenuExtraLeft,
  } = useCustomHeaderContext();

  const { onBookmarkBrandAnalytics, onRemoveBrandAnalyticsBookmark } =
    useSocialListeningAnalyticsBookmarkHandlers();

  const { brand } = useBrandDetails({ brandId });

  useEffect(() => {
    const renderInviteButton = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );
    addRenderMenuExtraLeft(renderInviteButton, 1);

    // show add to bookmark button if the page is the brand analytics page
    if (
      brand &&
      location.pathname.includes(
        PlotRoutes.socialListeningAnalyticsInbound(brand.id),
      )
    ) {
      const isBookmarked = brand.bookmarkState.brandAnalytics;
      addRenderMenuExtraLeft(
        () => (
          <ContextMenu
            renderButton={() => (
              <IconButton sx={{ ml: 1, p: 1 }}>
                <IconLinearMoreVert color={theme.colors?.primary.black} />
              </IconButton>
            )}
            options={bookmarkOptions({
              isBookmarked,
              onToggleBookmark: () => {
                if (isBookmarked) {
                  onRemoveBrandAnalyticsBookmark(
                    brand.id,
                    BookmarkType.BrandAnalytics,
                  );
                } else {
                  onBookmarkBrandAnalytics(
                    brand.id,
                    BookmarkType.BrandAnalytics,
                  );
                }
              },
            })}
          />
        ),
        2,
      );
    }

    setHeaderSx({
      borderBottom: `1px solid rgba(35, 6, 3, 0.05)`,
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box display="flex" alignItems="center" gap={3}>
        <SocialMediaListeningTopHeaderBreadcrumbs
          brandId={brandId}
          featureType={SocialListeningFeatureType.BrandAnalytics}
          componentProps={{
            brandSelector: {
              generateUrl: (brandId) =>
                PlotRoutes.socialListeningAnalyticsInbound(brandId),
            },
          }}
        />
      </Box>
    ));

    return () => {
      setHeaderSx({});
      setRenderTitle(() => null);
      renderMenuExtraLeft?.map((render, index) =>
        // eslint-disable-next-line
        removeRenderMenuExtraLeft(() => <>{render ?? ''}</>, index),
      );
    };
  }, [location.pathname, brand, JSON.stringify(renderMenuExtraLeft)]); // eslint-disable-line

  return (
    <Box mt={18} mx={8}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        my={2}
        pt={2}
      >
        <Typography
          variant="headline-xl"
          sx={{
            fontSize: 32,
            letterSpacing: '-1.28px',
          }}
        >
          Brand Analytics
        </Typography>
        {brand && (
          <Box
            borderRadius={10}
            bgcolor={theme.colors?.utility[275]}
            padding={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: 'center',
            }}
            component="button"
            onClick={() => {
              navigate(
                PlotRoutes.socialListeningSettings({
                  brandId: brand.id,
                }),
              );
            }}
          >
            <IconBoldSettings2 size={22} color={theme.colors?.utility[600]} />
          </Box>
        )}
      </Box>
      <Divider sx={{ borderColor: 'rgba(35, 6, 3, 0.05)', mx: -8, my: 4 }} />
      <Box sx={{ bgcolor: theme.colors?.utility[100] }}>
        <Outlet />
      </Box>
    </Box>
  );
};
