import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearChainlinkLink } from 'components/icons/components/linear/IconLinearChainlinkLink';
import { IconLinearDollarCircle } from 'components/icons/components/linear/IconLinearDollarCircle';
import { IconLinearGroup } from 'components/icons/components/linear/IconLinearGroup';
import { IconLinearLanguageCircle } from 'components/icons/components/linear/IconLinearLanguageCircle';
import { IconLinearMap } from 'components/icons/components/linear/IconLinearMap';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearSave } from 'components/icons/components/linear/IconLinearSave';
import { IconLinearSend2 } from 'components/icons/components/linear/IconLinearSend2';
import { IconLinearTimer1 } from 'components/icons/components/linear/IconLinearTimer1';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineChart3 } from 'components/icons/components/outline/IconOutlineChart3';
import { IconOutlineLike } from 'components/icons/components/outline/IconOutlineLike';
import { IconOutlinePlay } from 'components/icons/components/outline/IconOutlinePlay';
import { IconOutlineStatus } from 'components/icons/components/outline/IconOutlineStatus';
import { OrganicPaidOptions } from 'features/socialMediaListening/components/filterSelection/FilterBy/organicPaid';
import {
  BrandSocialPostEngagementStatus,
  FollowersType,
  Platform,
  Sentiment,
  SocialListeningPostsSortBy,
} from 'graphql/generated';

export enum SocialListeningFiltersKey {
  Platforms = 'platforms',
  Followers = 'followers',
  Sentiments = 'sentiments',
  Statuses = 'statuses',
  Period = 'dateRange',
  TopicRelevancy = 'topicRelevancy',
  OrganicPaid = 'isOrganic',
  MinimumPlayCount = 'minimumPlayCount',
  Types = 'types',
  Languages = 'languages',
  Regions = 'regions',
  IncludeTaggedPosts = 'includeTaggedPosts',
  OnlyShowBrandMentions = 'onlyShowBrandMentions',
  Signals = 'signalDefinitionIds',
  SignalOptions = 'signalOptionIds',
}

export const socialListeningFilterIconMap = {
  [SocialListeningFiltersKey.Platforms]: IconLinearMonitorMobile,
  [SocialListeningFiltersKey.Types]: IconLinearMonitorMobile,
  [SocialListeningFiltersKey.Followers]: IconLinearUser,
  [SocialListeningFiltersKey.Sentiments]: IconCustomSmiley,
  [SocialListeningFiltersKey.Statuses]: IconOutlineStatus,
  [SocialListeningFiltersKey.Period]: IconOutlineCalendar,
  [SocialListeningFiltersKey.OrganicPaid]: IconLinearDollarCircle,
  [SocialListeningFiltersKey.MinimumPlayCount]: IconOutlinePlay,
  [SocialListeningFiltersKey.TopicRelevancy]: IconLinearGroup,
  [SocialListeningFiltersKey.Languages]: IconLinearLanguageCircle,
  [SocialListeningFiltersKey.Regions]: IconLinearMap,
  [SocialListeningFiltersKey.Signals]: IconCustomSparkles,
  [SocialListeningFiltersKey.SignalOptions]: IconCustomSparkles,
};

export const socialListeningFilterOptions = [
  {
    key: SocialListeningFiltersKey.Platforms,
    options: [Platform.Tiktok, Platform.Instagram],
  },
  {
    key: SocialListeningFiltersKey.Types,
    options: [Platform.Instagram],
  },
  {
    key: SocialListeningFiltersKey.Followers,
    options: [
      FollowersType.Nano,
      FollowersType.Micro,
      FollowersType.Macro,
      FollowersType.Mega,
    ],
  },
  {
    key: SocialListeningFiltersKey.Sentiments,
    options: [
      Sentiment.Positive,
      Sentiment.Neutral,
      Sentiment.Negative,
      Sentiment.None,
    ],
  },
  {
    key: SocialListeningFiltersKey.Statuses,
    options: [
      BrandSocialPostEngagementStatus.NotStarted,
      BrandSocialPostEngagementStatus.ForResearch,
      BrandSocialPostEngagementStatus.Pass,
      BrandSocialPostEngagementStatus.Responded,
    ],
  },
  {
    key: SocialListeningFiltersKey.TopicRelevancy,
    options: [],
  },
  {
    key: SocialListeningFiltersKey.OrganicPaid,
    options: [OrganicPaidOptions.ORGANIC, OrganicPaidOptions.PAID],
  },
  {
    key: SocialListeningFiltersKey.MinimumPlayCount,
    options: [],
  },
  {
    key: SocialListeningFiltersKey.Period,
    options: [],
  },
  {
    key: SocialListeningFiltersKey.Languages,
    options: [],
  },
  {
    key: SocialListeningFiltersKey.Signals,
    options: [],
  },
  {
    key: SocialListeningFiltersKey.SignalOptions,
    options: [],
  },
];

export const socialListeningSortOptions = [
  {
    key: SocialListeningPostsSortBy.CreatedAt,
    label: 'Recency',
    icon: IconLinearTimer1,
  },
  {
    key: SocialListeningPostsSortBy.ConfidenceScore,
    label: 'Topic Relevancy',
    icon: IconLinearChainlinkLink,
  },
  {
    key: SocialListeningPostsSortBy.EngagementScore,
    label: 'Engagement Rate',
    icon: IconOutlineLike,
  },
  {
    key: SocialListeningPostsSortBy.TotalEngagementScore,
    label: 'Total Engagement',
    icon: IconOutlineChart3,
  },
  {
    key: SocialListeningPostsSortBy.TotalBookmarks,
    label: 'Total Bookmarks',
    icon: IconLinearSave,
  },
  {
    key: SocialListeningPostsSortBy.TotalShares,
    label: 'Total Shares',
    icon: IconLinearSend2,
  },
  {
    key: SocialListeningPostsSortBy.FollowerCount,
    label: 'Follower Count',
    icon: IconLinearUser,
  },
  {
    key: SocialListeningPostsSortBy.ViewCount,
    label: 'Views',
    icon: IconOutlinePlay,
  },
];
