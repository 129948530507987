import { Box, Typography } from '@mui/material';
import { IconBoldCamera } from 'components/icons/components/bold/IconBoldCamera';
import { IconCustomStar } from 'components/icons/components/custom/IconCustomStar';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfilePeekingPill = () => {
  return (
    <Box
      sx={{
        p: theme.spacing(1, 3),
        borderRadius: 25,
        background: 'rgba(248, 232, 202, 0.50)',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      <IconBoldCamera size={16} color={theme.colors?.utility['yellow-3']} />
      <Typography
        variant="headline-sm"
        color={theme.colors?.utility['yellow-3']}
      >
        Peeking
      </Typography>
    </Box>
  );
};
