import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconCustomSort } from 'components/icons/components/custom/IconCustomSort';
import { IconCustomSortAsc } from 'components/icons/components/custom/IconCustomSortAsc';
import { IconCustomSortDesc } from 'components/icons/components/custom/IconCustomSortDesc';
import { useUserContext } from 'contexts/users/User.context/User.context';
import {
  PaginatedCreatorProfileInput,
  PaginatedCreatorProfileInputSortBy,
  PaginatedCreatorProfileInputSortField,
  SortOrder,
  useGetCreatorProfilesForSlCommunityTableQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { NestedFiltersValues } from 'pages/socialMediaListening/community/Filters';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { SocialListeningCommunityTableRow } from './SocialListeningCommunityTableRow';
import { SocialListeningCommunityTableRowSkeleton } from './SocialListeningCommunityTableRowSkeleton';

export type SocialListeningCommunityTableViewProps = {
  brandId: string;
  filters: PaginatedCreatorProfileInput;
  rawFilters?: NestedFiltersValues;
  configForSaving?: PaginatedCreatorProfileInput;
  setConfigForSaving: (config: PaginatedCreatorProfileInput) => void;
};

export const SocialListeningCommunityTableView = ({
  brandId,
  filters,
  rawFilters,
  configForSaving,
  setConfigForSaving,
}: SocialListeningCommunityTableViewProps) => {
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const [lastScrollLeft, setLastScrollLeft] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement | null>(null);
  const [scrollDirection, setScrollDirection] = useState<
    'left' | 'right' | null
  >(null);

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [sortBy, setSortBy] = useState<PaginatedCreatorProfileInputSortBy>({
    field: PaginatedCreatorProfileInputSortField.RelationshipStrength,
    order: SortOrder.Desc,
  });

  const creatorProfileVariableData = useMemo(() => {
    const config = {
      ...filters,
      brandId,
      sortBy,
      take: 10,
    };
    if (JSON.stringify(configForSaving) !== JSON.stringify(config)) {
      setConfigForSaving(config);
    }
    return config;
  }, [brandId, filters, sortBy]); // eslint-disable-line

  useEffect(() => {
    analytics.track(EventName.CrmDashboardFilterApplied, {
      brandId,
      userId: user?.id,
      filters,
    });
  }, [JSON.stringify(filters)]); // eslint-disable-line

  useEffect(() => {
    analytics.track(EventName.CrmDashboardSearchUsed, {
      brandId,
      userId: user?.id,
      query: filters.query,
    });
  }, [filters.query]); // eslint-disable-line

  const {
    data: creatorProfileData,
    fetchMore: fetchMoreCreatorProfileData,
    loading: creatorProfileLoading,
  } = useGetCreatorProfilesForSlCommunityTableQuery({
    variables: {
      input: creatorProfileVariableData,
      brandId,
    },
    skip: !creatorProfileVariableData.dateRange,
    fetchPolicy: 'cache-and-network',
  });

  const isLoading = creatorProfileLoading || isLoadingMore;

  const totalCount =
    creatorProfileData?.paginatedCreatorProfiles.meta.totalCount || 0;

  const headCells = [
    {
      id: 'superfan',
      label: `Superfan${totalCount > 0 ? ` (${totalCount})` : ''}`,
      minWidth: 300,
    },
    {
      id: PaginatedCreatorProfileInputSortField.RelationshipStrength,
      label: 'Relationship',
      canSort: true,
      minWidth: 175,
      tooltip:
        "Relationship Score measures a creator's interaction strength with this brand relative to all creators. Higher scores indicate stronger brand connection.",
    },
    {
      id: PaginatedCreatorProfileInputSortField.FollowersCount,
      label: 'Followers',
      canSort: true,
      minWidth: 175,
    },
    {
      id: PaginatedCreatorProfileInputSortField.BrandMentionsCount,
      label: 'Brand mentions',
      canSort: true,
      minWidth: 175,
    },
    {
      id: PaginatedCreatorProfileInputSortField.BrandEngagementCount,
      label: 'Brand Engagement',
      canSort: true,
      tooltip: 'Comments only',
      minWidth: 175,
    },
    {
      id: PaginatedCreatorProfileInputSortField.BrandEngagementRate,
      label: 'UGC Engagement rate',
      canSort: true,
      tooltip: (
        <>
          <b>Engagement rate</b>
          <br />
          Measures how many interactions a post receives relative to the
          creator's follower count.
          <br />
          Calculated by dividing the post's total interactions (likes, comments,
          shares) by the creator's total followers.
        </>
      ),
      minWidth: 175,
    },
    {
      id: 'averageSentiment',
      label: 'Average sentiment',
      minWidth: 175,
    },
    {
      id: 'labels',
      label: 'Labels',
      minWidth: 200,
    },
    {
      id: 'activity',
      label: 'Activity',
      minWidth: 300,
    },
    {
      id: 'contentNiche',
      label: 'Content Niche',
      minWidth: 300,
    },
    {
      id: 'products',
      label: 'Products',
      minWidth: 300,
    },
  ];

  const creatorProfiles = useMemo(() => {
    return (creatorProfileData?.paginatedCreatorProfiles.data || []).filter(
      (e, index, self) => index === self.findIndex((t) => t.id === e.id),
    );
  }, [creatorProfileData]);

  useEffect(() => {
    const handleScroll = () => {
      if (tableContainerRef.current) {
        const currentScrollLeft = tableContainerRef.current.scrollLeft;

        // Determine horizontal scroll direction (left/right)
        if (currentScrollLeft === 0) {
          setScrollDirection(null);
        } else if (currentScrollLeft > lastScrollLeft) {
          setScrollDirection('right');
        } else if (currentScrollLeft < lastScrollLeft) {
          setScrollDirection('left');
        }

        setLastScrollLeft(currentScrollLeft);
      }
    };

    // Add event listener for scroll
    const currentTable = tableContainerRef.current;
    currentTable?.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      currentTable?.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollLeft]);

  const defaultStyleForFixedColumn: CSSProperties = {
    position: 'sticky',
    left: 0,
    borderRight: `1px solid ${theme.colors?.utility[400]}`,
    boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
    backgroundColor: theme.colors?.primary.white,
    zIndex: 99,
  };

  return (
    <TableContainer
      sx={{
        maxHeight: {
          xs: 'calc(100vh - 268px)',
          sm: 'calc(100vh - 240px)',
          md: 'calc(100vh - 250px)',
          lg: 'calc(100vh - 440px)',
        },
        overflowY: 'auto',
        'td:first-child, th:first-child': {
          paddingLeft: theme.spacing(8),
        },

        'td:last-child, th:last-child': {
          paddingRight: theme.spacing(8),
        },
        '@media (max-height: 800px)': {
          maxHeight: 'calc(100vh - 275px)',
        },
        '@media (max-height: 550px)': {
          maxHeight: 300,
        },
      }}
      ref={tableContainerRef}
    >
      <InfiniteScroll
        loadMore={() => {
          if (
            !creatorProfileData?.paginatedCreatorProfiles.pageInfo.hasNextPage
          ) {
            return;
          }
          setIsLoadingMore(true);
          fetchMoreCreatorProfileData({
            variables: {
              input: {
                ...creatorProfileVariableData,
                skip: creatorProfileData?.paginatedCreatorProfiles.data.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setIsLoadingMore(false);
              if (!fetchMoreResult) return prev;
              return {
                ...fetchMoreResult,
                paginatedCreatorProfiles: {
                  ...fetchMoreResult.paginatedCreatorProfiles,
                  data: [
                    ...prev.paginatedCreatorProfiles.data,
                    ...fetchMoreResult.paginatedCreatorProfiles.data,
                  ],
                  pageInfo: {
                    ...prev.paginatedCreatorProfiles.pageInfo,
                    ...fetchMoreResult.paginatedCreatorProfiles.pageInfo,
                  },
                },
              };
            },
          });
        }}
        threshold={510}
        useWindow={false}
        initialLoad={false}
        hasMore={
          creatorProfileData?.paginatedCreatorProfiles.pageInfo.hasNextPage
        }
      >
        <Table stickyHeader sx={{ minWidth: '100vw', overflowX: 'auto' }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell
                  key={headCell.id}
                  sx={{
                    borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                    minWidth: headCell.minWidth ?? 200,
                    ...(index === 0 &&
                      scrollDirection && {
                        ...defaultStyleForFixedColumn,
                        zIndex: 1000,
                      }),
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Typography
                        variant="headline-xs"
                        color={theme.colors?.utility[700]}
                        whiteSpace="nowrap"
                      >
                        {headCell.label}
                      </Typography>
                      {headCell.tooltip && (
                        <Tooltip
                          title={headCell.tooltip}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                maxWidth: 232,
                              },
                            },
                          }}
                        >
                          <Box
                            display="inline-flex"
                            color={theme.colors?.utility[700]}
                          >
                            <IconBoldInfoCircleAlt size={16} />
                          </Box>
                        </Tooltip>
                      )}
                    </Box>
                    {headCell.canSort && (
                      <IconButton
                        disableRipple
                        sx={{
                          p: 0,
                          display: creatorProfiles.length ? 'block' : 'none',
                        }}
                        onClick={() => {
                          analytics.track(EventName.CrmDashboardSortApplied, {
                            brandId,
                            userId: user?.id,
                            sortBy: headCell.id,
                            order: sortBy?.order,
                          });

                          setSortBy((prev) => {
                            if (prev?.field === headCell.id) {
                              return {
                                ...prev,
                                order:
                                  prev?.order === SortOrder.Asc
                                    ? SortOrder.Desc
                                    : SortOrder.Asc,
                              };
                            }
                            return {
                              field: headCell.id,
                              order: SortOrder.Asc,
                            };
                          });
                        }}
                      >
                        {sortBy?.field === headCell.id &&
                        sortBy?.order === SortOrder.Asc ? (
                          <IconCustomSortAsc
                            size={16}
                            color={theme.colors?.utility[800]}
                          />
                        ) : sortBy?.field === headCell.id &&
                          sortBy?.order === SortOrder.Desc ? (
                          <IconCustomSortDesc
                            size={16}
                            color={theme.colors?.utility[800]}
                          />
                        ) : (
                          <IconCustomSort
                            size={16}
                            color={theme.colors?.utility[600]}
                          />
                        )}
                      </IconButton>
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {creatorProfiles.map((creatorProfile) => (
              <SocialListeningCommunityTableRow
                key={creatorProfile.id}
                creatorProfile={creatorProfile}
                scrollDirection={scrollDirection}
                defaultStyleForFixedColumn={defaultStyleForFixedColumn}
                rawFilters={rawFilters}
              />
            ))}
            {isLoading &&
              [...Array(creatorProfileLoading ? 3 : 1)].map((_, index) => (
                <SocialListeningCommunityTableRowSkeleton key={index} />
              ))}
            {!isLoading && !creatorProfiles.length && (
              <TableRow>
                <TableCell
                  colSpan={headCells.length}
                  sx={{
                    borderBottom: 'none',
                    padding: 0,
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      ...theme.typography['subhead-xl'],
                      color: theme.colors?.utility[700],
                      height: theme.spacing(20),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    No data found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </InfiniteScroll>
    </TableContainer>
  );
};
