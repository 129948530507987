import { gql } from '@apollo/client';
import { Box, Button } from '@mui/material';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  SocialPostCommentCreatedTime,
  SocialPostCommentLikes,
  SocialPostCommentReplies,
  SocialPostCommentResponderPill,
  SocialPostCommentText,
} from 'features/socialPostComment';
import {
  GetManuallyParsedSocialPostTrackersForManuallyParsedSocialPostTrackerDialogViewDocument,
  ManuallyParsedSocialPostTrackerFragmentMpsptOutboundCommentCardFragment,
  SocialPostFragmentSocialPostThumbnailFragmentDoc,
  useDeleteManuallyParsedSocialPostTrackerForMpsptOutboundCommentCardMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import moment from 'moment';
import { theme } from 'styles/theme';
import { evictObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeleteManuallyParsedSocialPostTrackerForMPSPTOutboundCommentCard(
    $data: DeleteManuallyParsedSocialPostTrackerInput!
  ) {
    deleteManuallyParsedSocialPostTracker(data: $data) {
      success
      message
    }
  }
`;

export const MPSPT_OUTBOUND_COMMENT_CARD_FRAGMENT = gql`
  fragment ManuallyParsedSocialPostTrackerFragmentMPSPTOutboundCommentCard on ManuallyParsedSocialPostTrackerModel {
    id
    createdAt
    socialPost {
      id
      ...SocialPostFragmentSocialPostThumbnail
    }
    parsedByUser {
      id
      ...UserFragmentAvatarGroup
    }
  }
  ${SocialPostFragmentSocialPostThumbnailFragmentDoc}
`;

export type MPSPTOutboundCommentCardProps = {
  manuallyParsedSocialPostTracker: ManuallyParsedSocialPostTrackerFragmentMpsptOutboundCommentCardFragment;
};

export const MPSPTOutboundCommentCard = (
  props: MPSPTOutboundCommentCardProps,
) => {
  const { manuallyParsedSocialPostTracker } = props;

  // If it has been more than 24 hours since the tracker was created, and
  // we still couldn't find the brand creator comment, we'll show a different UI and allow users
  // to delete the tracker
  const has24HoursPassed =
    moment().diff(moment(manuallyParsedSocialPostTracker.createdAt), 'hours') >=
    24;

  const dummySocialPostComment = {
    // Constructing a dummy social post comment object to be used as placeholder
    id: manuallyParsedSocialPostTracker.socialPost.id,
    socialPost: {
      ...manuallyParsedSocialPostTracker.socialPost,
      brandSocialPostEngagement: {
        id: '',
        brandId: '',
        respondedByUser: manuallyParsedSocialPostTracker.parsedByUser,
      },
    },
    platformCreateTime: null,
    childCommentCount: 0,
    text: has24HoursPassed
      ? 'We are unable to load your comment for this post.'
      : 'Loading comment...',
    diggCount: 0,
  };

  const {
    onOpen: openDeleteConfirmationDialog,
    dialog: deleteConfirmationDialog,
  } = useConfirmationDialog();
  const [deleteManuallyParsedSocialPostTracker] =
    useDeleteManuallyParsedSocialPostTrackerForMpsptOutboundCommentCardMutation();

  const onDeleteManuallyParsedSocialPostTracker = async () => {
    await deleteManuallyParsedSocialPostTracker({
      variables: {
        data: {
          manuallyParsedSocialPostTrackerId: manuallyParsedSocialPostTracker.id,
        },
      },
      update: (cache) => {
        evictObject(
          cache,
          manuallyParsedSocialPostTracker.id,
          'ManuallyParsedSocialPostTrackerModel',
        );
      },
      refetchQueries: [
        GetManuallyParsedSocialPostTrackersForManuallyParsedSocialPostTrackerDialogViewDocument,
      ],
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box>
          <SocialPostThumbnail
            socialPost={dummySocialPostComment.socialPost}
            sx={{
              width: theme.spacing(37),
              height: theme.spacing(49),
              borderRadius: theme.spacing(4),
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(3),
            bgcolor: theme.colors?.primary.parchment,
            borderRadius: theme.spacing(4),
            padding: theme.spacing(3),
            marginLeft: theme.spacing(-7),
            flex: 1,
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <SocialPostCommentCreatedTime
              socialPostComment={dummySocialPostComment}
            />
            <SocialPostCommentResponderPill
              socialPostComment={dummySocialPostComment}
            />
          </Box>

          <Box>
            <SocialPostCommentText
              socialPostComment={dummySocialPostComment}
              sx={{
                color: theme.colors?.utility[600],
                fontStyle: has24HoursPassed ? undefined : 'italic',
              }}
            />
          </Box>

          {has24HoursPassed ? (
            <Box>
              <Button
                variant="text"
                startIcon={<IconLinearTrash size={16} />}
                size="small"
                sx={{
                  color: theme.colors?.utility['pink-3'],
                  px: 0,
                  fontSize: 12,
                }}
                onClick={() => {
                  openDeleteConfirmationDialog({
                    onConfirm: onDeleteManuallyParsedSocialPostTracker,
                  });
                }}
              >
                Remove Post
              </Button>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
              <SocialPostCommentLikes
                socialPostComment={dummySocialPostComment}
                componentProps={{
                  sx: {
                    bgcolor: theme.colors?.utility['purple-1'],
                    borderRadius: theme.spacing(1),
                    padding: theme.spacing(0.2, 2),
                  },
                  textSx: {
                    color: theme.colors?.utility['purple-4'],
                  },
                }}
              />
              <SocialPostCommentReplies
                socialPostComment={dummySocialPostComment}
                componentProps={{
                  sx: {
                    bgcolor: theme.colors?.utility['teal-1'],
                    borderRadius: theme.spacing(1),
                    padding: theme.spacing(0.2, 2),
                  },
                  textSx: {
                    color: theme.colors?.utility['teal-4'],
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Box>

      {deleteConfirmationDialog}
    </>
  );
};
