import { RadarEventType } from 'graphql/generated';

export const eventTypeLabelMap = {
  [RadarEventType.Ugc]: 'Brand mentions in UGC',
  [RadarEventType.Comment]: 'Brand mentions in comments',
  [RadarEventType.Engagement]: 'Brand engagement',
  [RadarEventType.Moment]: 'Personal moments',
};

export const eventTypeTooltipMap = {
  [RadarEventType.Engagement]: 'Comments only',
};
