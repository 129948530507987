import { gql } from '@apollo/client';
import {
  CreateRadarEventActivityInput,
  RadarEventActivityFragmentSlRadarActivityHandlersFragmentDoc,
  UpdateRadarEventActivityInput,
  useCreateRadarEventActivityForSlRadarActivityHandlersMutation,
  useUpdateRadarEventActivityForSlRadarActivityHandlersMutation,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  fragment RadarEventActivityFragmentSLRadarActivityHandlers on RadarEventActivityModel {
    id
    title
    direction
    summary
    dateTime
    createdForCreatorProfiles {
      id
    }
    channels {
      id
    }
    assets {
      id
    }
    radarEvents {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetRadarEventActivitiesForSLRadarActivityHandlers(
    $input: PaginatedRadarEventActivityInput!
  ) {
    paginatedRadarEventActivities(input: $input) {
      data {
        ...RadarEventActivityFragmentSLRadarActivityHandlers
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${RadarEventActivityFragmentSlRadarActivityHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateRadarEventActivityForSLRadarActivityHandlers(
    $input: CreateRadarEventActivityInput!
  ) {
    createRadarEventActivity(input: $input) {
      ...RadarEventActivityFragmentSLRadarActivityHandlers
    }
  }
  ${RadarEventActivityFragmentSlRadarActivityHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UpdateRadarEventActivityForSLRadarActivityHandlers(
    $input: UpdateRadarEventActivityInput!
  ) {
    updateRadarEventActivity(input: $input) {
      ...RadarEventActivityFragmentSLRadarActivityHandlers
    }
  }
  ${RadarEventActivityFragmentSlRadarActivityHandlersFragmentDoc}
`;

export const useSocialListeningRadarActivityHandlers = () => {
  const [createRadarEventActivity] =
    useCreateRadarEventActivityForSlRadarActivityHandlersMutation();
  const [updateRadarEventActivity] =
    useUpdateRadarEventActivityForSlRadarActivityHandlersMutation();

  const onCreateRadarEventActivity = (input: CreateRadarEventActivityInput) => {
    return createRadarEventActivity({
      variables: { input },
      refetchQueries: ['GetRadarEventActivitiesForSLRadarActivityHandlers'],
    });
  };

  const onUpdateRadarEventActivity = (input: UpdateRadarEventActivityInput) => {
    return updateRadarEventActivity({
      variables: { input },
      refetchQueries: ['GetRadarEventActivitiesForSLRadarActivityHandlers'],
    });
  };

  return { onCreateRadarEventActivity, onUpdateRadarEventActivity };
};
