import React, { useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme/theme';
import { Box, styled, SxProps, Typography } from '@mui/material';

const StyledOption = styled(Box)({
  textAlign: 'center',
  width: '50%',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const Indicator = styled(Box)({
  position: 'absolute',
  width: '50%',
  height: '100%',
  borderRadius: theme.spacing(3.5),
  transition: 'all 300ms ease-in-out',
});

interface TextSwitchProps {
  sx?: SxProps;
  textOptionLeft?: React.ReactNode;
  textOptionRight?: React.ReactNode;
  itemLeft?: React.ReactNode;
  itemRight?: React.ReactNode;
  isLeftOptionSelected?: boolean;
  onClick?: (isLeftOptionSelected: boolean) => void;
  selectedOptionColor?: string;
  componentProps?: {
    text?: {
      sx?: SxProps;
    };
    item?: {
      sx?: SxProps;
    };
    indicator?: {
      sx?: SxProps;
    };
  };
}

export const TextSwitch = (props: TextSwitchProps) => {
  const {
    sx,
    textOptionLeft,
    textOptionRight,
    itemLeft,
    itemRight,
    componentProps,
    isLeftOptionSelected,
    selectedOptionColor,
    onClick,
  } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const indicatorRef = useRef<HTMLDivElement | null>(null);
  const itemLeftRef = useRef<HTMLSpanElement | null>(null);
  const itemRightRef = useRef<HTMLSpanElement | null>(null);

  const [selectedOption, setSelectedOption] = useState(isLeftOptionSelected);

  useEffect(() => {
    setSelectedOption(isLeftOptionSelected);
  }, [isLeftOptionSelected]);

  useEffect(() => {
    if (
      containerRef.current &&
      indicatorRef.current &&
      itemLeftRef.current &&
      itemRightRef.current
    ) {
      const containerStyles = window.getComputedStyle(containerRef.current);
      const containerPaddingLeft = parseInt(containerStyles.paddingLeft, 10);
      const containerGap = parseInt(containerStyles.gap, 10) || 0;

      if (selectedOption) {
        indicatorRef.current.style.width = `${itemLeftRef.current.clientWidth}px`;
        indicatorRef.current.style.left = `${containerPaddingLeft}px`;

        if (selectedOptionColor) {
          itemLeftRef.current!.style.color = selectedOptionColor;
        }
        itemRightRef.current!.style.color = 'inherit';
      } else {
        indicatorRef.current.style.width = `${itemRightRef.current.clientWidth}px`;
        indicatorRef.current.style.left = `${
          containerPaddingLeft + itemLeftRef.current.clientWidth + containerGap
        }px`;

        itemLeftRef.current!.style.color = 'inherit';

        if (selectedOptionColor) {
          itemRightRef.current!.style.color = selectedOptionColor;
        }
      }
    }
  }, [containerRef, indicatorRef, selectedOption, selectedOptionColor]);

  const selectOption = () => {
    if (onClick) {
      onClick(!selectedOption);
    }

    setSelectedOption(!selectedOption);
  };

  return (
    <Box
      ref={containerRef}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: theme.spacing(3.5),
        ...sx,
      }}
      onClick={selectOption}
    >
      <StyledOption ref={itemLeftRef} sx={componentProps?.item?.sx}>
        {itemLeft || textOptionLeft}
      </StyledOption>
      <StyledOption ref={itemRightRef} sx={componentProps?.item?.sx}>
        {itemRight || textOptionRight}
      </StyledOption>
      <Indicator
        ref={indicatorRef}
        sx={{
          borderRadius: theme.spacing(3.5),
          ...componentProps?.indicator?.sx,
        }}
      />
    </Box>
  );
};
