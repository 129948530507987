import { useDisclosure } from '@dwarvesf/react-hooks';
import { createContext } from '@dwarvesf/react-utils';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { PropsWithChildren, useMemo } from 'react';
import { TabName } from '../types';

export const PERSISTENT_DRAWER_KEY = 'isPersistentDrawer';
export const DRAWER_OPENED_STATE_KEY = 'drawerOpenedState';

type LeftDrawerContextProps = {
  isPersistentDrawer: boolean;
  setIsPersistentDrawer: (isPersistentDrawer: boolean) => void;
  isOpenDrawer: boolean;
  onCloseDrawer: () => void;
  onToggleDrawer: () => void;
  onOpenDrawer: () => void;
  drawerOpenedState: string[];
  onAddDrawerOpenedState: (value: string) => void;
  onRemoveDrawerOpenedState: (value: string) => void;
};

const [Provider, useLeftDrawerContext] =
  createContext<LeftDrawerContextProps>();

export const LeftDrawerContextProvider = (props: PropsWithChildren) => {
  const { children } = props;

  const isMobileView = useMediaQueryMobile();

  const { value: isPersistentDrawer, setValue: setIsPersistentDrawer } =
    useLocalStorage(PERSISTENT_DRAWER_KEY, false);

  const { value: drawerOpenedState, setValue: setDrawerOpenedState } =
    useLocalStorage<string[]>(DRAWER_OPENED_STATE_KEY, [TabName.PRODUCTS]);

  const {
    isOpen: isOpenDrawer,
    onClose: onCloseDrawer,
    onToggle: onToggleDrawer,
    onOpen: onOpenDrawer,
  } = useDisclosure();

  const _isPersistentDrawer = useMemo(() => {
    if (isMobileView) {
      onCloseDrawer();
      return false;
    }

    if (isPersistentDrawer) {
      onOpenDrawer();
    }

    return isPersistentDrawer;
  }, [isMobileView, isPersistentDrawer, onCloseDrawer, onOpenDrawer]);

  const onAddDrawerOpenedState = (value: string) => {
    setDrawerOpenedState((prev) =>
      prev.includes(value) ? prev : [...prev, value],
    );
  };

  const onRemoveDrawerOpenedState = (value: string) => {
    setDrawerOpenedState((prev) => prev.filter((x) => x !== value));
  };

  return (
    <Provider
      value={{
        onCloseDrawer,
        onOpenDrawer,
        isOpenDrawer,
        onToggleDrawer,
        isPersistentDrawer: _isPersistentDrawer,
        setIsPersistentDrawer,
        drawerOpenedState,
        onAddDrawerOpenedState,
        onRemoveDrawerOpenedState,
      }}
    >
      {children}
    </Provider>
  );
};

export { useLeftDrawerContext };
