import { Box, Typography } from '@mui/material';
import { ImageWithVideoFallback } from 'components/common/Image';
import { IconBoldEye } from 'components/icons/components/bold/IconBoldEye';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldLike } from 'components/icons/components/bold/IconBoldLike';
import { IconBoldMessage } from 'components/icons/components/bold/IconBoldMessage';
import { IconBoldSmiley } from 'components/icons/components/bold/IconBoldSmiley';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening';
import {
  CreatorProfileBrandMentionedStatsInput,
  useGetBrandMentionStatsForSlCreatorProfileMentionedBrandsViewQuery,
  useGetSocialPostsForSlCreatorProfileMentionedBrandsViewQuery,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { StyledBrandMentionStat } from './styles';

export type MentionedBrandCardProps = {
  filters: CreatorProfileBrandMentionedStatsInput;
  brandName: string;
};

export const MentionedBrandCard = ({
  brandName,
  filters,
}: MentionedBrandCardProps) => {
  const { data: brandStatsData } =
    useGetBrandMentionStatsForSlCreatorProfileMentionedBrandsViewQuery({
      variables: {
        input: filters,
      },
    });
  const brandStats = brandStatsData?.creatorProfileBrandMentionStats;
  const brandMentionCount = brandStats?.brandMentionCount || 0;

  const { generateLink } = useSocialListeningPostLinkGenerator();

  const { data: brandMentionSocialPostsData } =
    useGetSocialPostsForSlCreatorProfileMentionedBrandsViewQuery({
      variables: {
        input: {
          ...filters,
          take: 3,
        },
      },
    });

  const socialPosts =
    brandMentionSocialPostsData?.creatorProfileBrandMentions.data || [];

  return (
    <Box
      sx={{
        p: 4,
        borderRadius: 3,
        backgroundColor: theme.colors?.utility[250],
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        mb: 6,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gap: 2,
          flex: 1,
        }}
      >
        <Typography variant="headline-sm">{brandName}</Typography>
        <Typography variant="headline-xs" color={theme.colors?.utility[600]}>
          {brandMentionCount} mention{brandMentionCount > 1 ? 's' : ''}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          flexWrap: 'wrap',
          flex: 1,
          alignItems: 'flex-start',
        }}
      >
        <StyledBrandMentionStat>
          <IconBoldHeart />
          {formatBigNumber(brandStats?.totalLikes || 0)}
        </StyledBrandMentionStat>
        <StyledBrandMentionStat>
          <IconBoldMessage />
          {formatBigNumber(brandStats?.totalComments || 0)}
        </StyledBrandMentionStat>
        <StyledBrandMentionStat>
          <IconBoldEye />
          {formatBigNumber(brandStats?.totalViews || 0)}
        </StyledBrandMentionStat>
        <StyledBrandMentionStat>
          <IconBoldLike />
          {brandStats?.engagementRate
            ? `${(brandStats.engagementRate * 100).toFixed(2)}%`
            : '0%'}
        </StyledBrandMentionStat>
        <StyledBrandMentionStat>
          <IconBoldSmiley />
          <Box component="span">
            {brandStats?.sentiment
              ? brandStats.sentiment.charAt(0).toUpperCase() +
                brandStats.sentiment.slice(1).toLowerCase()
              : ''}
          </Box>
        </StyledBrandMentionStat>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          flex: 0.5,
          justifyContent: 'flex-end',
        }}
      >
        {socialPosts
          .filter((item) => item.thumbnailUrl || item.videoUrl)
          .map((item) => (
            <Box component="a" href={generateLink(item)} target="_blank">
              <ImageWithVideoFallback
                imageUrl={item.thumbnailUrl || ''}
                videoUrl={item.videoUrl || ''}
                sx={{
                  width: 32,
                  height: 46,
                  borderRadius: 1.5,
                  objectFit: 'cover',
                }}
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
};
