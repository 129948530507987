/**
 * TODO: Move this to creator feature?
 */

import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { CreatorAvatar } from 'features/creator';
import { useSocialListeningCreatorLinkGenerator } from 'features/socialMediaListening/hooks/useSocialListeningCreatorLinkGenerator';
import { platformIconMap, platformLabelMap } from 'features/socialPost';
import {
  CreatorFragmentCreatorAvatarFragmentDoc,
  CreatorFragmentSlCreatorProfileHandlerViewFragment,
  CreatorFragmentUseSocialListeningCreatorLinkGeneratorFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

export const CREATOR_FRAGMENT_SL_CREATOR_PROFILE_HANDLER_VIEW = gql`
  fragment CreatorFragmentSLCreatorProfileHandlerView on CreatorModel {
    id
    platform
    nickName
    profilePictureUrl
    videoCount
    followerCount
    engagementRate
    ...CreatorFragmentCreatorAvatar
    ...CreatorFragmentUseSocialListeningCreatorLinkGenerator
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
  ${CreatorFragmentUseSocialListeningCreatorLinkGeneratorFragmentDoc}
`;

export type SocialListeningCreatorProfileHandlerViewProps = {
  creator: CreatorFragmentSlCreatorProfileHandlerViewFragment;
};

export const SocialListeningCreatorProfileHandlerView = ({
  creator,
}: SocialListeningCreatorProfileHandlerViewProps) => {
  const { generateLink } = useSocialListeningCreatorLinkGenerator();

  const Icon = platformIconMap[creator.platform];

  const data = [
    {
      label: 'posts',
      value: formatBigNumber(creator.videoCount),
    },
    {
      label: 'followers',
      value: formatBigNumber(creator.followerCount),
    },
    {
      label: 'engagement',
      value: `${(creator.engagementRate * 100).toFixed(0)}%`,
      tooltip: (
        <>
          <b>Engagement rate</b>
          <br />
          Measures how many interactions a post receives relative to the
          creator's follower count.
          <br />
          Calculated by dividing the post's total interactions (likes, comments,
          shares) by the creator's total followers.
        </>
      ),
    },
  ];
  return (
    <Box
      component="a"
      key={creator.id}
      href={generateLink(creator)}
      target="_blank"
      sx={{
        p: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        '&:hover': {
          background: theme.colors?.utility[300],

          '.external-link-icon': {
            opacity: 1,
          },
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          component="div"
          variant="headline-sm"
          color={theme.colors?.utility[700]}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <Icon size={16} />
          {platformLabelMap[creator.platform]}
        </Typography>
        <Box
          className="external-link-icon"
          sx={{
            color: theme.colors?.primary.black,
            opacity: 0,
          }}
        >
          <IconLinearExport2 />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <CreatorAvatar creator={creator} size={48} />
        <Box
          sx={{
            display: 'grid',
          }}
        >
          <Typography
            variant="headline-md"
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {creator.handle}
          </Typography>
          <Typography variant="subhead-xl">{creator.nickName}</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {data.map((item) => (
          <Typography
            variant="headline-md"
            gap={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {item.value}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Typography variant="subhead-xl">{item.label}</Typography>
              {item.tooltip && (
                <Tooltip
                  title={item.tooltip}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: 232,
                      },
                    },
                  }}
                >
                  <Box display="inline-flex" color={theme.colors?.utility[700]}>
                    <IconBoldInfoCircleAlt size={16} />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
