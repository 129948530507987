import { Box, IconButton, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledFilterButton = styled(IconButton)<{ isActive: boolean }>(
  ({ isActive }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    borderRadius: theme.spacing(25),
    backgroundColor: isActive
      ? theme.colors?.utility[800]
      : theme.colors?.utility[250],
    padding: theme.spacing(1, 2),
    color: isActive
      ? theme.colors?.primary.parchment
      : theme.colors?.utility[800],
    ...theme.typography['headline-xs'],
  }),
);

export const StyledBrandMentionStat = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  ...theme.typography['headline-xs'],
  color: theme.colors?.utility[800],
  svg: {
    height: 16,
    width: 16,
    color: theme.colors?.utility[600],
  },
});
