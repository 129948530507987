import { Box, Button, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconLinearArrowLeft } from 'components/icons/components/linear/IconLinearArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { useIsPaidMemberLimitReached } from 'features/billing';
import { OrgRoleSelector } from 'features/organizationMembers/components/OrgRoleSelector';
import { ManageUsersInput } from 'features/permission/components/manageUsersInput/ManageUsersInput';
import {
  ExternalOrganizationRole,
  InternalOrganizationRole,
  UserFragmentAddUsersToOrgViewFragment,
} from 'graphql/generated';
import { useCheckOrgDomainUtils } from 'hooks/useCheckOrgDomain';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { BrandSelectionView } from './sections/brandSelection';
import { ManageUsersList, NEW_USER_ID } from './sections/manageUsersList';
import { MemberInviteConfirmation } from './sections/memberInviteConfirmation';
import { MemberUserRoleSelection } from './sections/memberUserRoleSelection';
import { SocialListeningUsersInvited } from './sections/socialListeningUsersInvited';
import { EmailValidationErrorForAddUser, Props } from './types';

const getTempUser = (email: string) => {
  return { id: NEW_USER_ID, firstName: '', lastName: '', email, title: '' };
};

enum MemberInviteState {
  EmailSelection,
  RoleSelection,
  BrandSelection,
  MemberAddedToSocialListening,
  ConfirmPaidMembersInvite,
}

export const AddUsersToOrgView = ({
  validateEmail,
  existingInternalUsers,
  existingExternalUsers,
  hideShareModal,
  onInviteMembers,
  guestOptions,
  hideRoleSelection,
  componentProps,
  brandId,
}: Props) => {
  const [error, setError] = useState<EmailValidationErrorForAddUser>();
  const { user, orgBilling } = useUserContext();

  const isSocialListeningEnabled = orgBilling?.socialListeningEnabled;
  const { allowGuests, defaultSelectedRoleInUI } = guestOptions || {};
  const socialListeningEnabled = orgBilling?.socialListeningEnabled;
  const paidMemberLimitReached = useIsPaidMemberLimitReached({
    organizationBilling: orgBilling,
  });

  const [alreadyExistingMember, setAlreadyExistingMember] =
    useState<UserFragmentAddUsersToOrgViewFragment | null>(null);
  const [searchStr, setSearchStr] = useState('');

  // This will maintain the actual actively selected emails by the user, and is the list that will ben sent to the server
  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  // Default role based on current user's role & paywall
  const defaultRole = useMemo(() => {
    if (
      socialListeningEnabled &&
      (user?.role === InternalOrganizationRole.SocialListeningUser ||
        paidMemberLimitReached)
    ) {
      return InternalOrganizationRole.SocialListeningUser;
    }

    return InternalOrganizationRole.User;
  }, [user]); // eslint-disable-line

  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const [userRoleData, setUserRoleData] = useState<{
    [email: string]: InternalOrganizationRole | ExternalOrganizationRole;
  }>({});

  const [currentView, setCurrentView] = useState<MemberInviteState>(
    MemberInviteState.EmailSelection,
  );

  const [selectedBrandId, setSelectedBrandId] = useState<string>(brandId || '');

  const onRoleChange = (
    email: string,
    role: InternalOrganizationRole | ExternalOrganizationRole,
  ) => {
    setUserRoleData({ ...userRoleData, [email]: role });
  };

  const { isWorkDomain, getSuggestedEmails } = useCheckOrgDomainUtils();

  // check if there is at least one email have org domain, in the adding user list
  const haveAtLeastOneInternalUserInAddingUserList = useMemo(() => {
    return selectedEmails.some((email) =>
      isWorkDomain(email.trim().split('@')[1]),
    );
  }, [selectedEmails]);

  // Only allow changing the role if user is an admin, or all-access member AND there is at least one internal user in the adding user list
  const canUpdateRole = useMemo(() => {
    return (
      user?.role === InternalOrganizationRole.Admin ||
      user?.role === InternalOrganizationRole.User
    );
  }, [user]);

  useEffect(() => {
    // setHasError(false);
    setError(null);
  }, [searchStr]);

  const checkIfExternalUser = (email: string) => {
    const newEmailDomain = email.trim().split('@')[1];
    const currentUserDomain = user!.email.trim().split('@')[1];
    if (
      isWorkDomain(currentUserDomain) &&
      newEmailDomain !== currentUserDomain
    ) {
      return true;
    }

    if (!isWorkDomain(currentUserDomain) && isWorkDomain(newEmailDomain)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const newUserRoleData: {
      [email: string]: InternalOrganizationRole | ExternalOrganizationRole;
    } = {};
    selectedEmails.forEach((email) => {
      const isExternalUser = checkIfExternalUser(email);
      if (isExternalUser) {
        if (allowGuests) {
          newUserRoleData[email] = ExternalOrganizationRole.Collaborator;
        } else {
          setError('differentOrgDomain');
        }
      } else {
        newUserRoleData[email] =
          userRoleData[email] || InternalOrganizationRole.User;
      }
    });
    setUserRoleData(newUserRoleData);
  }, [JSON.stringify({ selectedEmails })]); // eslint-disable-line

  // Added users will allow to re-add users without typing them again using the checkbox after removing from the list
  const [tempAddedUsers, setTempAddedUsers] = useState<
    UserFragmentAddUsersToOrgViewFragment[]
  >([]);

  const addUser = (email: string) => {
    if (!tempAddedUsers.find((x) => x.email === email)) {
      setTempAddedUsers([
        ...tempAddedUsers,
        { id: NEW_USER_ID, firstName: '', lastName: '', email },
      ]);
    }
    setSelectedEmails([...selectedEmails, email]);
  };

  const removeUser = (email: string) => {
    setUserRoleData((prev) => {
      delete prev[email];
      return prev;
    });
    setSelectedEmails(selectedEmails.filter((x) => x !== email));
  };

  const allUsers = searchStr
    ? // Convert the search string to lowercase to avoid case sensitivity
    getSuggestedEmails(
      user?.organization.domain || '',
      searchStr.toLowerCase(),
    )
      .map(getTempUser)
      .filter((x) => x.email !== user?.email)
    : [...tempAddedUsers, ...existingInternalUsers]
      .filter((x) => x.email !== user?.email)
      .slice(0, 5);

  const validateAndAddEmail = (emailToAdd: string) => {
    if (emailToAdd === '') {
      // if the email is empty, do nothing
      return;
    }

    if (emailToAdd === user?.email) {
      setError('ownEmail');
      return;
    }

    const { type } = validateEmail({
      selectedEmails,
      email: emailToAdd,
    });
    console.log('validateAndAddEmail emailToAdd', { emailToAdd, type });
    let roleToSet: InternalOrganizationRole | ExternalOrganizationRole =
      InternalOrganizationRole.User;

    if (!type) {
      // type = null
      setError(null);

      const isExternalUser = checkIfExternalUser(emailToAdd);
      if (isExternalUser && !allowGuests) {
        setError('differentOrgDomain');
        return;
      }
      if (isExternalUser) {
        roleToSet = ExternalOrganizationRole.Collaborator;
      }
      if (paidMemberLimitReached && !isExternalUser) {
        roleToSet = InternalOrganizationRole.SocialListeningUser;
      }

      setAlreadyExistingMember(null);
      addUser(emailToAdd.trim());
      setSearchStr('');

      // add new mail with role to list data
      setUserRoleData({
        ...userRoleData,
        [emailToAdd]: roleToSet,
      });
    } else {
      setError(type);

      if (type === 'existsAlready') {
        const existingUser = [
          ...existingInternalUsers,
          ...existingExternalUsers,
        ].find((x) => x.email === emailToAdd);
        if (existingUser) {
          setAlreadyExistingMember(existingUser);
        }

        addUser(emailToAdd.trim());
        setSearchStr('');

        setUserRoleData({
          ...userRoleData,
          [emailToAdd]: defaultRole,
        });
      }
    }
  };

  const _onInviteMembers = async () => {
    // if not SL case => use onInviteMembers with the role data
    if (!isSocialListeningEnabled) {
      await onInviteMembers(
        selectedEmails.map((email) => ({
          email,
          role: userRoleData[email],
        })),
        true,
      );
      return;
    }

    // invite all users as social listening users since the current user is a social listening user
    if (user?.role === InternalOrganizationRole.SocialListeningUser) {
      await onInviteMembers(
        selectedEmails.map((email) => ({
          email,
          role: InternalOrganizationRole.SocialListeningUser,
          brandIds: brandId ? [brandId] : undefined,
        })),
        true,
      );
      return;
    }

    // if current user try to invite new admin role => show paid members invite confirmation
    if (selectedRole === InternalOrganizationRole.Admin) {
      setCurrentView(MemberInviteState.ConfirmPaidMembersInvite);
      return;
    }

    // if current view is email selection => show role selection
    if (currentView === MemberInviteState.EmailSelection) {
      setCurrentView(MemberInviteState.RoleSelection);
    } else if (currentView === MemberInviteState.RoleSelection) {
      // Check if any user has external role
      const hasExternalUser = Object.values(userRoleData).some(
        (role) => role === ExternalOrganizationRole.Collaborator,
      );

      if (hasExternalUser && !brandId) {
        // Only show brand selection if brandId is not provided
        setCurrentView(MemberInviteState.BrandSelection);
        return;
      }

      // if current view is role selection => call api to invite members
      const rolesToInvite = Object.values(userRoleData);
      if (
        rolesToInvite.every((role) => role === InternalOrganizationRole.User)
      ) {
        // all invited members are paid users
        setCurrentView(MemberInviteState.ConfirmPaidMembersInvite);
      } else if (
        rolesToInvite.every(
          (role) =>
            role === ExternalOrganizationRole.Collaborator ||
            role === InternalOrganizationRole.SocialListeningUser,
        )
      ) {
        // Invite all users as social listening users
        await onInviteMembers(
          selectedEmails.map((email) => ({
            email,
            role: userRoleData[email],
            brandIds: brandId
              ? [brandId]
              : selectedBrandId
              ? [selectedBrandId]
              : undefined,
          })),
        );
        setCurrentView(MemberInviteState.MemberAddedToSocialListening);
      } else {
        setCurrentView(MemberInviteState.ConfirmPaidMembersInvite);
      }
    } else if (currentView === MemberInviteState.BrandSelection) {
      // Call API to update brand permissions
      await onInviteMembers(
        selectedEmails.map((email) => ({
          email,
          role: userRoleData[email],
          brandIds: [selectedBrandId],
        })),
      );
      setCurrentView(MemberInviteState.MemberAddedToSocialListening);
    }
  };

  const addMemberButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (currentView === MemberInviteState.RoleSelection) {
      // Focus the Add member button when transitioning to RoleSelection
      addMemberButtonRef.current?.focus();
    }
  }, [currentView]);

  const getAddMemberButtonDisabled = () => {
    // Base case - no emails selected
    if (Object.values(userRoleData).length === 0) {
      return true;
    }

    // If we're in brand selection view (which means we have external users) and no brandId is provided
    if (currentView === MemberInviteState.BrandSelection && !brandId) {
      return !selectedBrandId; // Disable if no brand selected
    }

    return false;
  };

  if (currentView === MemberInviteState.MemberAddedToSocialListening) {
    return (
      <SocialListeningUsersInvited
        customTitle={
          !Object.keys(userRoleData).every(
            (email) =>
              userRoleData[email] ===
              InternalOrganizationRole.SocialListeningUser,
          )
            ? 'For Social Listening Members,'
            : ''
        }
        emails={selectedEmails.filter(
          (email) =>
            userRoleData[email] ===
            InternalOrganizationRole.SocialListeningUser ||
            userRoleData[email] === ExternalOrganizationRole.Collaborator,
        )}
        onDone={hideShareModal}
      />
    );
  }

  if (currentView === MemberInviteState.ConfirmPaidMembersInvite) {
    return (
      <MemberInviteConfirmation
        customTitle={
          !Object.keys(userRoleData).every(
            (email) =>
              userRoleData[email] === InternalOrganizationRole.User ||
              userRoleData[email] === InternalOrganizationRole.Admin,
          )
            ? 'For "All Plot Features" added members,'
            : ''
        }
        emails={selectedEmails.filter(
          (email) =>
            userRoleData[email] === InternalOrganizationRole.User ||
            userRoleData[email] === InternalOrganizationRole.Admin,
        )}
        onConfirm={async () => {
          await onInviteMembers(
            Object.entries(userRoleData).map(([email, role]) => ({
              email,
              role,
            })),
          );
          const uniqueRoles = new Set(Object.values(userRoleData));
          if (uniqueRoles.size === 1) {
            hideShareModal();
          } else {
            setCurrentView(MemberInviteState.MemberAddedToSocialListening);
          }
        }}
        onCancel={() => {
          setCurrentView(MemberInviteState.EmailSelection);
        }}
      />
    );
  }

  return (
    <Box
      sx={{
        background: theme.colors?.primary.white,
        minHeight: '500px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 500,
        maxWidth: 500,
      }}
    >
      <Box
        sx={{
          background: 'rgba(250, 243, 236, 0.5)',
          pb: 4,
          pt: 0,
          '& input': {
            color: error ? theme.colors?.utility['pink-3'] : 'inherit',
          },
        }}
      >
        {socialListeningEnabled &&
          [
            MemberInviteState.RoleSelection,
            MemberInviteState.BrandSelection,
          ].includes(currentView) && (
            <Button
              variant="text"
              onClick={() => {
                if (currentView === MemberInviteState.BrandSelection) {
                  setCurrentView(MemberInviteState.RoleSelection);
                } else {
                  setCurrentView(MemberInviteState.EmailSelection);
                }
              }}
              sx={{ p: theme.spacing(2, 2), m: 4, mb: 0, minWidth: 0 }}
              startIcon={<IconLinearArrowLeft size={16} />}
            >
              <Typography
                variant="body-lg"
                color={theme.colors?.primary.black}
                fontWeight={600}
              >
                Back
              </Typography>
            </Button>
          )}
        <ManageUsersInput
          sx={{
            py: 2,
            pt:
              socialListeningEnabled &&
                [
                  MemberInviteState.RoleSelection,
                  MemberInviteState.BrandSelection,
                ].includes(currentView)
                ? 0
                : 6,
          }}
          searchStr={searchStr}
          // Convert the search string to lowercase to avoid case sensitivity
          onEnter={() => validateAndAddEmail(searchStr.toLowerCase())}
          onSearchStrUpdate={(text) => {
            setSearchStr(text);
          }}
          onInvite={_onInviteMembers}
          onRemoveUser={(index) => {
            removeUser(selectedEmails[index]);
          }}
          users={selectedEmails.map(getTempUser)}
          componentProps={{
            tag: { sx: { bgcolor: 'rgba(35, 6, 3, 0.1)' } },
            input: {
              sx: {
                '& .MuiChip-label': {
                  pl: 1,
                },
                '.MuiOutlinedInput-root': {
                  p: `0 !important`,

                  input: {
                    ...theme.typography['headline-xs'],
                    p: '0 !important',
                  },

                  '.MuiOutlinedInput-notchedOutline': {
                    display: 'none !important',
                  },
                },
              },
            },
          }}
        />
        {!hideRoleSelection &&
          selectedEmails.length > 0 &&
          currentView === MemberInviteState.EmailSelection && (
            <Box ml={3}>
              <OrgRoleSelector
                minimalView
                canUpdate={canUpdateRole}
                viewOnly={!canUpdateRole}
                initialValue={selectedRole}
                onRoleChange={(role) => {
                  if (role !== selectedRole) {
                    setSelectedRole(role);
                    setUserRoleData(
                      selectedEmails.reduce((acc, email) => {
                        const isExternalUser =
                          userRoleData[email] ===
                          ExternalOrganizationRole.Collaborator;
                        acc[email] = isExternalUser
                          ? ExternalOrganizationRole.Collaborator
                          : role;

                        return acc;
                      }, {} as { [email: string]: InternalOrganizationRole | ExternalOrganizationRole }),
                    );
                  }
                }}
                showPaywall
              />
            </Box>
          )}
      </Box>
      <Box
        sx={{
          background: 'rgba(250, 243, 236, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        {!error && currentView === MemberInviteState.EmailSelection && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: theme.spacing(2),
            }}
          >
            <ManageUsersList
              users={(
                [...allUsers] as UserFragmentAddUsersToOrgViewFragment[]
              ).filter((x) => x.email !== user?.email)}
              selectedEmails={selectedEmails}
              onAddEmail={validateAndAddEmail}
              onRemoveEmail={removeUser}
            />
          </Box>
        )}
        {!error && currentView === MemberInviteState.RoleSelection && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: theme.spacing(2),
            }}
          >
            <MemberUserRoleSelection
              userRoleData={userRoleData}
              onRoleChange={onRoleChange}
              guestOptions={{
                defaultSelectedRoleForExternalUser:
                  defaultSelectedRoleInUI ||
                  InternalOrganizationRole.SocialListeningUser,
              }}
            />
          </Box>
        )}
        {!error && currentView === MemberInviteState.BrandSelection && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: theme.spacing(3),
            }}
          >
            <BrandSelectionView
              selectedBrandId={selectedBrandId}
              onBrandSelect={setSelectedBrandId}
            />
          </Box>
        )}

        {error && (
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 2,
            }}
          >
            {error === 'ownEmail' && (
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[1000]}
                fontWeight={600}
                textAlign="center"
              >
                You cannot add yourself to the organization.
              </Typography>
            )}
            {error === 'existsAlready' && alreadyExistingMember && (
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[1000]}
                fontWeight={600}
                textAlign="center"
                px={6}
              >
                The member{' '}
                <Box component="span" color={theme.colors?.utility['pink-3']}>
                  {`${getFullName(alreadyExistingMember)} (${alreadyExistingMember.email
                    })`}
                </Box>{' '}
                you are trying to add is already a part of this organization.
              </Typography>
            )}
            {error === 'differentOrgDomain' && (
              <>
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[1000]}
                  fontWeight={600}
                  textAlign="center"
                >
                  You can only add members with <br />
                  <Box component="span" color={theme.colors?.utility['pink-3']}>
                    {isWorkDomain(user!.organization.domain)
                      ? `name@${user!.organization.domain} `
                      : 'personal email '}
                  </Box>
                  from here.
                </Typography>
                <Typography
                  textAlign="center"
                  variant="body-sm"
                  color={theme.colors?.utility[700]}
                  fontWeight={500}
                >
                  You can invite the guests directly to a <br />
                  collection or a note/post.
                </Typography>
                {selectedEmails.length === 0 && (
                  <Link to={PlotRoutes.juicebox()}>
                    <Typography
                      variant="body-sm"
                      sx={{ textDecoration: 'underline' }}
                      fontWeight={500}
                    >
                      Head to Creative Juicebox
                    </Typography>
                  </Link>
                )}
              </>
            )}
          </Box>
        )}
      </Box>
      <Button
        ref={addMemberButtonRef}
        variant="primary-alt"
        onClick={_onInviteMembers}
        disabled={getAddMemberButtonDisabled()}
        sx={{
          p: 3,
          '&:focus': {
            outline: 'none',
            backgroundColor: theme.colors?.primary.black,
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.colors?.primary.maroon}`,
            outlineOffset: -2,
          },
        }}
      >
        Add members
      </Button>
    </Box>
  );
};
