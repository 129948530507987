import { gql } from '@apollo/client';
import { Avatar, Box } from '@mui/material';
import { platformIconMap } from 'features/socialPost';
import { CreatorFragmentCreatorAvatarFragment } from 'graphql/generated';
import { forwardRef } from 'react';
import { theme } from 'styles/theme';

export const CREATOR_FRAGMENT_CREATOR_AVATAR = gql`
  fragment CreatorFragmentCreatorAvatar on CreatorModel {
    id
    platform
    handle
    profilePictureUrl
  }
`;

export type CreatorAvatarProps = {
  creator: CreatorFragmentCreatorAvatarFragment;
  size?: number;
  hidePlatformIcon?: boolean;
};

export const CreatorAvatar = forwardRef((props: CreatorAvatarProps, ref) => {
  const { creator, size = 24, hidePlatformIcon, ...rest } = props;

  const Icon = platformIconMap[creator.platform];

  return (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
      }}
      {...rest}
    >
      <Avatar
        src={creator.profilePictureUrl || ''}
        alt={creator.handle || creator.platform}
        sx={{
          width: size,
          height: size,
        }}
      />
      {!hidePlatformIcon && (
        <Box
          sx={{
            position: 'absolute',
            bottom: -4,
            right: -4,
            width: size / 2,
            height: size / 2,
            borderRadius: 5,
            backgroundColor: theme.colors?.utility[100],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {Icon && (
            <Icon size={size / 2 - 4} color={theme.colors?.primary.black} />
          )}
        </Box>
      )}
    </Box>
  );
});
