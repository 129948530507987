import { Box, createFilterOptions, Typography } from '@mui/material';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { CommunityLabelColorMap } from 'features/socialListeningCommunity/constants';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

export type CommunicationChannelOptionType = {
  id: string;
  name: string;
  color?: string | null;
  bgColor?: string | null;
};

const filter = createFilterOptions<
  CommunicationChannelOptionType & { label: string; value: string }
>();

type SocialListeningRadarMultiSelectLabelProps = {
  options: CommunicationChannelOptionType[];
  values: string[];
  onChange: (value: string[]) => void;
  renderTrigger: (props: { isOpen: boolean; options: any }) => JSX.Element;
  onCreate: (option: CommunicationChannelOptionType) => void;
  onSetSearch?: (value: string) => void;
  fetchMoreItems?: () => void;
};

export const SocialListeningRadarMultiSelectLabel = ({
  options: autocompleteOptions,
  values,
  onChange,
  renderTrigger,
  onCreate,
  onSetSearch,
  fetchMoreItems,
}: SocialListeningRadarMultiSelectLabelProps) => {
  const [inputValue, setInputValue] = useState('');

  const [newOption, setNewOption] =
    useState<CommunicationChannelOptionType | null>(null);

  useEffect(() => {
    onSetSearch?.(inputValue);
  }, [inputValue]); // eslint-disable-line

  const options = useMemo(
    () =>
      autocompleteOptions.map((option) => ({
        ...option,
        label: option.name,
        value: option.id,
      })),
    [autocompleteOptions],
  );

  const renderOption = (option: CommunicationChannelOptionType) => {
    const randomColor =
      option.color && option.bgColor
        ? {
            backgroundColor: option.bgColor,
            color: option.color,
          }
        : CommunityLabelColorMap[0];

    return (
      <Box
        sx={{
          borderRadius: 1,
          ...theme.typography['headline-xs'],
          padding: theme.spacing(1, 2),
          color: randomColor.color,
          backgroundColor: randomColor.backgroundColor,
        }}
      >
        {option.name}
      </Box>
    );
  };

  return (
    <AutocompletePopup
      defaultValue={options.filter((o) => values.some((v) => v === o.id))}
      multiple
      options={options}
      placeholder="Search"
      loadMoreItems={() => {
        fetchMoreItems?.();
      }}
      onChange={(e) => {
        e.stopPropagation();
        if (newOption) {
          onCreate(newOption);
        }
        setNewOption(null);
        setInputValue('');
      }}
      renderTrigger={(open) =>
        renderTrigger({
          isOpen: open || false,
          options,
        })
      }
      triggerWrapperSx={{
        width: '100%',
      }}
      renderOption={(props, option) => {
        const selected = values.some((o) => o === option.value);

        const fieldOption = options.find((o) => o.id === option.value);

        if (!fieldOption) {
          return (
            <Box
              component="li"
              key={option.value}
              {...props}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                borderRadius: 2,
              }}
            >
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility[900]}
              >
                Create
              </Typography>
              {renderOption(
                option as unknown as CommunicationChannelOptionType,
              )}
            </Box>
          );
        }

        return (
          <Box
            component="li"
            {...props}
            sx={{
              borderRadius: 2,
              p: `0 !important`,
            }}
            key={fieldOption.id}
            onClick={() => {
              if (selected) {
                onChange(values.filter((v) => v !== fieldOption.id));
              } else {
                onChange([...values, fieldOption.id]);
              }
            }}
          >
            <CheckboxMenuItem
              checked={selected}
              value={fieldOption.id}
              label={renderOption(fieldOption)}
            />
          </Box>
        );
      }}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') {
          setInputValue(value);
        } else {
          setInputValue('');
        }
      }}
      filterOptions={(_options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;

        const isExisting = _options.some(
          (option) =>
            inputValue.trim().toLowerCase() === option.label.toLowerCase(),
        );

        if (inputValue.trim() !== '' && !isExisting) {
          const randomColor = newOption
            ? {
                backgroundColor: newOption.bgColor,
                color: newOption.color,
              }
            : CommunityLabelColorMap[
                Math.floor(Math.random() * CommunityLabelColorMap.length)
              ];
          const option = {
            id: inputValue,
            bgColor: randomColor.backgroundColor,
            color: randomColor.color ?? '',
            name: inputValue,
            value: inputValue,
            label: inputValue,
          };
          if (option.value !== newOption?.id) {
            setNewOption(option);
          }
          filtered.push(option);
        } else {
          setNewOption(null);
        }

        return filtered;
      }}
      // eslint-disable-next-line
      PaperComponent={({ children, ...rest }) => {
        return (
          <Box
            {...rest}
            sx={{
              '.MuiAutocomplete-listbox': {
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                maxHeight: 250,
              },
            }}
          >
            {!newOption && children}
            <Box
              sx={{
                px: 3,
                ...(newOption ? { pb: 3, pt: 1 } : { pt: 3 }),
              }}
            >
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[600]}
              >
                Select an Option or Create One
              </Typography>
            </Box>
            {newOption && children}
          </Box>
        );
      }}
    />
  );
};
