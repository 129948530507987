import { gql } from '@apollo/client';
import { useBookmarkHandlers } from 'features/bookmark';
import {
  BookmarkFragmentForBookmarkHandlersFragmentDoc,
  BookmarkType,
  useCreateBookmarkForSocialListeningAnalyticsBookmarkHandlersMutation,
  useDeleteBookmarkForSocialListeningAnalyticsBookmarkHandlersMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreateBookmarkForSocialListeningAnalyticsBookmarkHandlers(
    $input: CreateBrandBookmarkInput!
  ) {
    createBrandBookmark(input: $input) {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
  ${BookmarkFragmentForBookmarkHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteBookmarkForSocialListeningAnalyticsBookmarkHandlers(
    $input: DeleteBrandBookmarkInput!
  ) {
    deleteBrandBookmark(input: $input) {
      message
      success
      deleted {
        id
      }
    }
  }
`;

const bookmarkTypeToBookmarkStateMap = {
  [BookmarkType.BrandAnalytics]: 'brandAnalytics',
  [BookmarkType.BrandOwnedAnalytics]: 'ownedBrandAnalytics',
  [BookmarkType.BrandUgcAnalytics]: 'ugcAnalytics',
};

export const useSocialListeningAnalyticsBookmarkHandlers = () => {
  const [createBookmark] =
    useCreateBookmarkForSocialListeningAnalyticsBookmarkHandlersMutation();
  const [deleteBookmark] =
    useDeleteBookmarkForSocialListeningAnalyticsBookmarkHandlersMutation();

  const { addBookmarkItemToSidebar, removeBookmarkItemFromSidebar } =
    useBookmarkHandlers();

  const onBookmarkBrandAnalytics = (brandId: string, type: BookmarkType) => {
    createBookmark({
      variables: {
        input: {
          brandId,
          type,
        },
      },
      update: (cache, { data }) => {
        if (data?.createBrandBookmark.id) {
          addBookmarkItemToSidebar(data.createBrandBookmark);
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                [bookmarkTypeToBookmarkStateMap[type]]: true,
              };
            },
          });
        }
      },
    });
  };

  const onRemoveBrandAnalyticsBookmark = (
    brandId: string,
    type: BookmarkType,
  ) => {
    deleteBookmark({
      variables: {
        input: {
          brandId,
          type,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteBrandBookmark.success) {
          removeBookmarkItemFromSidebar(
            data.deleteBrandBookmark.deleted?.[0]?.id ?? '',
          );
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                [bookmarkTypeToBookmarkStateMap[type]]: false,
              };
            },
          });
        }
      },
    });
  };

  return {
    onBookmarkBrandAnalytics,
    onRemoveBrandAnalyticsBookmark,
  };
};
