import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Popover } from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { StyledTextField } from 'components/common/form/Autocomplete/AutocompletePopup/styles';
import { RadioMenuItem } from 'components/common/form/Select';
import { toast } from 'components/common/Toast';
import { useUserContext } from 'contexts/users/User.context';
import {
  Exact,
  SocialPostCommentFragmentUseResponderChangeFragment,
  UpdateBrandSocialPostEngagementResponderInput,
  UserFragmentAvatarGroupFragment,
  useUpdateBrandSocialPostEngagementResponderForUseResponderChangeMutation,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

gql`
  mutation UpdateBrandSocialPostEngagementResponderForUseResponderChange(
    $data: UpdateBrandSocialPostEngagementResponderInput!
  ) {
    updateBrandSocialPostEngagementResponder(data: $data) {
      success
      message
    }
  }
`;

gql`
  fragment SocialPostCommentFragmentUseResponderChange on SocialPostCommentModel {
    id
    socialPost {
      id
      brandSocialPostEngagement(brandId: $brandId) {
        id
        respondedByUser {
          id
        }
      }
    }
  }
`;

interface UseResponderChangeProps {
  socialPostComment: SocialPostCommentFragmentUseResponderChangeFragment;
  onResponderChanged?: (responderId: string | undefined) => void;
  anchorElRef: React.RefObject<HTMLButtonElement>;
  brandId?: string;
}

export const useResponderChange = ({
  socialPostComment,
  onResponderChanged,
  anchorElRef,
  brandId,
}: UseResponderChangeProps) => {
  const { orgBilling, user } = useUserContext();
  const socialListeningEnabled = Boolean(orgBilling?.socialListeningEnabled);

  const brandSocialPostEngagementId: string | undefined =
    socialPostComment.socialPost.brandSocialPostEngagement?.id;
  const initialResponderId: string | undefined =
    socialPostComment?.socialPost?.brandSocialPostEngagement?.respondedByUser
      ?.id;

  const [updateBrandSocialPostEngagementResponder, { loading }] =
    useUpdateBrandSocialPostEngagementResponderForUseResponderChangeMutation();

  const [selectedResponder, setSelectedResponder] = useState<
    UserFragmentAvatarGroupFragment | undefined
  >(
    initialResponderId
      ? user?.organization.users.find((user) => user.id === initialResponderId)
      : undefined,
  );
  const [searchStr, setSearchStr] = useState<string>('');

  const handleResponderChange = (
    responder: UserFragmentAvatarGroupFragment,
  ) => {
    // If the selected responder is the same as the current one => remove it
    if (responder.id === selectedResponder?.id) {
      // update the responder in the database with optimistic update
      updateBrandSocialPostEngagementResponder({
        // variables: mutationVariables,
        variables: {
          data: {
            responderId: null,
            brandSocialPostEngagementId,
          },
        },
        onCompleted: (data) => {
          if (data.updateBrandSocialPostEngagementResponder.success) {
            onResponderChanged?.(undefined);
            toast({
              message: data.updateBrandSocialPostEngagementResponder.message,
              type: 'success',
            });
          } else {
            toast({
              message: 'Failed to remove responder',
              type: 'error',
            });
          }
          // update the local selected responder id to undefined => show no responder on UI
          setSelectedResponder(undefined);
        },
        onError: (error) => {
          console.error(error);
          toast({
            message: error.message || 'Failed to remove responder',
            type: 'error',
          });
        },
      });
      return;
    }

    if (!user?.organization.users.find((user) => user.id === responder.id)) {
      // if the responder is not in the organization, do not update the responder
      return;
    }

    // create a new engagement if there is no existing engagement
    // required fields: brandId, socialPostId
    const addNewEngagementData: Exact<UpdateBrandSocialPostEngagementResponderInput> =
      {
        brandId,
        socialPostId: socialPostComment.socialPost.id,
        // status is undefined by default when a new engagement is created
        // If users manually update the responder, the backend also mark the social post as "responded"
        status: undefined,
      };

    // Prepare mutation variables based on whether we have an existing engagement
    const mutationVariables:
      | Exact<{
          data: UpdateBrandSocialPostEngagementResponderInput;
        }>
      | undefined = {
      data: {
        responderId: responder.id,
        ...(brandSocialPostEngagementId
          ? { brandSocialPostEngagementId } // update the existing engagement
          : addNewEngagementData),
      },
    };

    // update the responder in the database with optimistic update
    updateBrandSocialPostEngagementResponder({
      variables: mutationVariables,
      onCompleted: (data) => {
        if (data.updateBrandSocialPostEngagementResponder.success) {
          // only update the local state if the mutation is successful
          setSelectedResponder(responder);
          onResponderChanged?.(responder.id);
          toast({
            message: data.updateBrandSocialPostEngagementResponder.message,
            type: 'success',
          });
        }
      },
      onError: (error) => {
        console.error(error);
        toast({
          message: error.message || 'Failed to update responder',
          type: 'error',
        });
      },
    });
  };

  // All users have access to current SL brand
  // include external users (external users getting invited to the brand) as an option for responders
  const allSLBrandUsers: UserFragmentAvatarGroupFragment[] = useMemo(() => {
    const internalUsers = user?.organization.users ?? [];

    const brandInviteMembers =
      user?.socialListeningBrands
        .filter((brand) => brand.id === brandId) // filter by current SL brand
        .flatMap((brand) => brand.inviteMembers) // get all invite members
        .map((inviteMember) => inviteMember.user) ?? []; // map to user

    // make sure there is no duplicate users
    const uniqueUsers = Array.from(
      Array.from(new Map([...internalUsers, ...brandInviteMembers].map(user => [user.id, user])).values()),
    );
    return uniqueUsers;
  }, [user, brandId]);

  const filteredUsers: UserFragmentAvatarGroupFragment[] = useMemo(() => {
    return (
      allSLBrandUsers?.filter((user) => {
        return (
          user.firstName.includes(searchStr) ||
          user.lastName.includes(searchStr) ||
          user.email.includes(searchStr)
        );
      }) ?? []
    );
  }, [searchStr, allSLBrandUsers]);

  const hasSLEnabledAndAccessToCurrentBrandId = useMemo(() => {
    return (
      socialListeningEnabled &&
      user?.socialListeningBrands.some((brand) => brand.id === brandId)
    );
  }, [user?.socialListeningBrands, brandId, socialListeningEnabled]);

  // user have access to SL feature
  // AND access to current SL brand
  // AND brandId is not undefined (required field to create a new engagement)
  const canAddResponder = hasSLEnabledAndAccessToCurrentBrandId && !!brandId;

  // user have access to SL feature
  // AND brandSocialPostEngagementId is not undefined (required field to update a responder)
  const canUpdateResponder: boolean | undefined =
    socialListeningEnabled && !!brandSocialPostEngagementId;

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();

  const renderPopoverUI = () => {
    return (
      <Popover
        open={isPopoverOpen}
        onClose={closePopover}
        anchorEl={anchorElRef.current}
        PaperProps={{
          sx: {
            mt: 2,
            width: 291,
            maxHeight: 400,
            bgcolor: theme.colors?.utility[300],
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            bgcolor: theme.colors?.utility[300],
            padding: theme.spacing(6, 6, 3, 6),
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          {/* Search bar */}
          <StyledTextField
            defaultValue={searchStr}
            placeholder="Search"
            onChange={(event) => {
              setSearchStr(event.target.value);
            }}
            sx={{
              p: 0,
              bgcolor: 'rgba(255, 255, 255, 1)',
              '& .MuiInputBase-root': {
                bgcolor: 'rgba(255, 255, 255, 1)',
              },
              borderRadius: '100px',
            }}
          />
        </Box>
        <Box sx={{ padding: theme.spacing(0, 4, 4, 4) }}>
          {filteredUsers.map((user) => {
            const checked = selectedResponder?.id === user.id;
            return (
              <RadioMenuItem
                key={user.id}
                value={user.id}
                checked={checked}
                onClick={() => {
                  handleResponderChange(user);
                }}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AvatarWithName user={user} avatarSize={16} />
                  </Box>
                }
              />
            );
          })}
        </Box>
      </Popover>
    );
  };

  return {
    selectedResponder,
    handleResponderChange,
    canUpdateResponder,
    canAddResponder,
    loading,
    renderPopoverUI,
    isPopoverOpen,
    openPopover,
  };
};
