import { Box, Button, Chip, Grid, Skeleton, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { RadarEventTimelineItemViewSkeleton } from 'features/radarEvent/views/RadarEventTimelineItemViewSkeleton';
import { SocialListeningCreatorProfileDetailsViewSkeleton } from 'features/socialListeningCreatorProfile/views/details/SocialListeningCreatorProfileDetailsViewSkeleton';
import { theme } from 'styles/theme';

export const ActivityTabContentSkeleton = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            {/* Year section skeleton */}
            <Box
              sx={{
                borderRadius: 6,
                border: `1px solid ${theme.colors?.utility[300]}`,
                p: 6,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                bgcolor: theme.colors?.primary.white,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Skeleton variant="text" width={100} height={32} />
                <Button
                  variant="primary-alt"
                  disabled
                  startIcon={
                    <Skeleton variant="circular" width={16} height={16} />
                  }
                >
                  <Skeleton variant="text" width={120} height={24} />
                </Button>
              </Box>

              {/* Week section skeleton */}
              <CollapsibleSection
                renderHeaderTitle={() => {
                  return (
                    <Box
                      role="button"
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <Chip
                        variant="filled-borderless"
                        label={
                          <Skeleton variant="text" width={100} height={24} />
                        }
                      />
                      <Box
                        sx={{
                          flex: 1,
                          height: '1px',
                          background: theme.colors?.utility[400],
                        }}
                      />
                    </Box>
                  );
                }}
                divider={false}
                defaultIsOpen
                iconColor={theme.colors?.utility[600]}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 6,
                    py: 6,
                  }}
                >
                  {[1, 2, 3].map((index) => (
                    <RadarEventTimelineItemViewSkeleton key={index} />
                  ))}
                </Box>
              </CollapsibleSection>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <SocialListeningCreatorProfileDetailsViewSkeleton />
        </Grid>
      </Grid>
    </Box>
  );
};
