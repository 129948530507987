import { Box } from '@mui/material';
import { useState } from 'react';
import { theme } from 'styles/theme';

export const StaticImage = ({
  thumbnailUrl,
  renderActionsBottom,
  renderActionsTopRight,
}: {
  thumbnailUrl: string;
  renderActionsBottom?: (isHovered: boolean) => React.ReactNode;
  renderActionsTopRight?: (isHovered: boolean) => React.ReactNode;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      position="relative"
      width="100%"
      height="0"
      sx={{ paddingTop: '177.77%', overflow: 'hidden' }} // 56.25% for 16:9 aspect ratio
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      data-testid="static-image-container"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="100%"
        sx={{ borderRadius: 4, cursor: 'pointer', objectFit: 'cover' }}
        component="img"
        src={thumbnailUrl}
        data-testid="static-image"
      />

      <Box
        position="absolute"
        top={theme.spacing(2)}
        right={theme.spacing(2)}
        display="flex"
        gap={2}
        alignItems="center"
        minHeight={24}
        data-testid="top-right-actions-container"
      >
        {renderActionsTopRight && renderActionsTopRight(isHovered)}
      </Box>
      {renderActionsBottom && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            px: 2,
            pb: 2,
            pt: 6,
            borderRadius: '0 0 16px 16px',

            // Linear gradient from bottom to top, black -> transparent
            backgroundImage:
              'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
          }}
          data-testid="bottom-actions-container"
        >
          {renderActionsBottom(isHovered)}
        </Box>
      )}
    </Box>
  );
};
