import { gql } from '@apollo/client';
import { useBookmarkHandlers } from 'features/bookmark';
import {
  BookmarkFragmentForBookmarkHandlersFragmentDoc,
  BookmarkType,
  useCreateBookmarkForSocialListeningCommunityBookmarkHandlersMutation,
  useDeleteBookmarkForSocialListeningCommunityBookmarkHandlersMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreateBookmarkForSocialListeningCommunityBookmarkHandlers(
    $input: CreateBrandBookmarkInput!
  ) {
    createBrandBookmark(input: $input) {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
  ${BookmarkFragmentForBookmarkHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteBookmarkForSocialListeningCommunityBookmarkHandlers(
    $input: DeleteBrandBookmarkInput!
  ) {
    deleteBrandBookmark(input: $input) {
      message
      success
      deleted {
        id
      }
    }
  }
`;

export const useSocialListeningCommunityBookmarkHandlers = () => {
  const [createBookmark] =
    useCreateBookmarkForSocialListeningCommunityBookmarkHandlersMutation();
  const [deleteBookmark] =
    useDeleteBookmarkForSocialListeningCommunityBookmarkHandlersMutation();

  const { addBookmarkItemToSidebar, removeBookmarkItemFromSidebar } =
    useBookmarkHandlers();

  const onBookmarkCommunityAnalytics = (brandId: string) => {
    createBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.BrandCommunityHub,
        },
      },
      update: (cache, { data }) => {
        if (data?.createBrandBookmark.id) {
          addBookmarkItemToSidebar(data.createBrandBookmark);
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                communityHub: true,
              };
            },
          });
        }
      },
    });
  };

  const onRemoveCommunityAnalyticsBookmark = (brandId: string) => {
    deleteBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.BrandCommunityHub,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteBrandBookmark.success) {
          removeBookmarkItemFromSidebar(
            data.deleteBrandBookmark.deleted?.[0]?.id ?? '',
          );
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                communityHub: false,
              };
            },
          });
        }
      },
    });
  };

  return {
    onBookmarkCommunityAnalytics,
    onRemoveCommunityAnalyticsBookmark,
  };
};
