import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { HighlightableSection } from 'components/common/HighlightableSection';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { useUserContext } from 'contexts/users/User.context/User.context';
import {
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import {
  SentimentColorMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity';
import { SocialMediaListeningPostDetailDialogView } from 'features/socialMediaListening';
import { SocialPostCardView } from 'features/socialPost';
import {
  CreatorProfileBrandMentionedStatsInput,
  CreatorProfilePaginatedBrandMentionsInputSortField,
  SocialPostFragmentSocialPostCardViewFragmentDoc,
  useGetBrandMentionStatsForSlCreatorProfileBrandMentionViewQuery,
  useGetSocialPostsForSlCreatorProfileBrandMentionViewQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SocialListeningCreatorProfileBrandMentionViewSkeleton } from './SocialListeningCreatorProfileBrandMentionViewSkeleton';

// eslint-disable-next-line
gql`
  query GetBrandMentionStatsForSLCreatorProfileBrandMentionView(
    $input: CreatorProfileBrandMentionedStatsInput!
  ) {
    creatorProfileBrandMentionStats(input: $input) {
      brandMentionCount
      engagementRate
      sentiment
      totalLikes
      totalViews
      sentimentScore
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetSocialPostsForSLCreatorProfileBrandMentionView(
    $input: CreatorProfilePaginatedBrandMentionsInput!
  ) {
    creatorProfileBrandMentions(input: $input) {
      data {
        ...SocialPostFragmentSocialPostCardView
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

export type SocialListeningCreatorProfileBrandMentionViewProps = {
  filters: CreatorProfileBrandMentionedStatsInput;
  setBrandMentionCount: (count: number) => void;
  setSentimentScore: (score: number) => void;
};

export const SocialListeningCreatorProfileBrandMentionView = ({
  filters,
  setBrandMentionCount,
  setSentimentScore,
}: SocialListeningCreatorProfileBrandMentionViewProps) => {
  const analytics = useAnalytics();
  const { user } = useUserContext();
  const socialPostDisclosure = useDisclosure();
  const [selectedSocialPostId, setSelectedSocialPostId] = useState('');
  const [searchParams] = useSearchParams();

  const [sortKey, setSortKey] =
    useState<CreatorProfilePaginatedBrandMentionsInputSortField>(
      CreatorProfilePaginatedBrandMentionsInputSortField.PlayCount,
    );

  const { data: brandStatsData, loading: brandStatsLoading } =
    useGetBrandMentionStatsForSlCreatorProfileBrandMentionViewQuery({
      variables: {
        input: filters,
      },
    });

  const socialPostFilters = useMemo(() => {
    return {
      ...filters,
      take: 10,
      sortBy: {
        field: sortKey,
      },
    };
  }, [filters, sortKey]);

  const {
    data: socialPostsData,
    fetchMore: fetchMoreSocialPosts,
    loading: socialPostsLoading,
  } = useGetSocialPostsForSlCreatorProfileBrandMentionViewQuery({
    variables: {
      input: socialPostFilters,
    },
  });

  const socialPosts = socialPostsData?.creatorProfileBrandMentions.data || [];

  const statsData = brandStatsData?.creatorProfileBrandMentionStats;

  const sortOptions = [
    {
      label: 'Play count',
      value: CreatorProfilePaginatedBrandMentionsInputSortField.PlayCount,
    },
    {
      label: 'Total like count',
      value: CreatorProfilePaginatedBrandMentionsInputSortField.DiggCount,
    },
    {
      label: 'Engagement rate',
      value: CreatorProfilePaginatedBrandMentionsInputSortField.EngagementRate,
    },
    {
      label: 'Recency',
      value: CreatorProfilePaginatedBrandMentionsInputSortField.Recency,
    },
  ];

  const stats = useMemo(() => {
    const renderSkeleton = (width = 50) => {
      return <Skeleton variant="text" width={width} height={30} />;
    };
    return [
      {
        label: 'Brand mentions',
        text: brandStatsLoading
          ? renderSkeleton()
          : statsData?.brandMentionCount,
      },
      {
        label: 'Total view count',
        text: brandStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.totalViews ?? 0),
      },
      {
        label: 'Total like count',
        text: brandStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.totalLikes ?? 0),
      },
      {
        label: 'Engagement rate',
        text: brandStatsLoading
          ? renderSkeleton()
          : `${((statsData?.engagementRate ?? 0) * 100).toFixed(2)}%`,
      },
      {
        label: 'Overall sentiment',
        text: brandStatsLoading
          ? renderSkeleton(120)
          : statsData && (
              <Typography
                variant="headline-xl"
                fontSize={24}
                fontWeight={500}
                color={SentimentColorMap[statsData.sentiment]}
              >
                {SentimentLabelMap[statsData.sentiment]}
              </Typography>
            ),
      },
    ];
  }, [statsData, brandStatsLoading]);

  useEffect(() => {
    if (statsData) {
      setBrandMentionCount(statsData.brandMentionCount);
      setSentimentScore(statsData.sentimentScore);
    }
  }, [statsData?.brandMentionCount, statsData?.sentimentScore]); // eslint-disable-line

  useEffect(() => {
    const postId = searchParams.get('postId');
    if (postId) {
      setSelectedSocialPostId(postId);
      socialPostDisclosure.onOpen();
    }
  }, []);

  const shareableLink = useMemo(() => {
    const url = new URL(window.location.href);
    url.searchParams.set('postId', selectedSocialPostId);
    return url.toString();
  }, [selectedSocialPostId]);

  if (brandStatsLoading) {
    return <SocialListeningCreatorProfileBrandMentionViewSkeleton />;
  }

  return (
    <HighlightableSection
      sectionKey="brand-mention"
      sx={{
        p: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Brand mentions
        </Typography>
        <NestedFiltersMenuView
          values={{
            sort: {
              value: sortOptions.find((option) => option.value === sortKey),
            },
          }}
          items={[
            {
              type: 'single-select',
              key: 'sort',
              options: sortOptions,
            },
          ]}
          onChange={(values) => {
            setSortKey(
              (values.sort.value as NestedFiltersOptionType)
                ?.value as CreatorProfilePaginatedBrandMentionsInputSortField,
            );
          }}
          componentsProps={{
            trigger: {
              text:
                sortOptions.find((option) => option.value === sortKey)?.label ??
                'Sort',
              Icon: IconLinearSort,
            },
          }}
        />
      </Box>
      {(socialPostsLoading || socialPosts.length > 0) && (
        <Box
          sx={{
            background: theme.colors?.utility[275],
            borderRadius: 3,
            display: 'flex',
            flexWrap: 'wrap',
            p: 4,
            gap: 4,
          }}
        >
          {stats.map((stat, index) => (
            <Box
              sx={{
                [theme.breakpoints.up('md')]: {
                  borderRight:
                    index + 1 !== stats.length
                      ? `1px solid ${theme.colors?.utility[400]}`
                      : 'none',
                },
                flex: 1,
                minWidth: 140,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 3,
                }}
              >
                <Typography
                  variant="headline-xxs"
                  color={theme.colors?.utility[800]}
                >
                  {stat.label}
                </Typography>
                <Typography
                  variant="headline-xl"
                  fontSize={24}
                  fontWeight={500}
                >
                  {stat.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Box
        sx={{
          maxHeight: 500,
          overflowY: 'auto',
        }}
      >
        <InfiniteScroll
          loadMore={() => {
            fetchMoreSocialPosts({
              variables: {
                input: {
                  ...socialPostFilters,
                  skip: socialPostsData?.creatorProfileBrandMentions.data
                    .length,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  creatorProfileBrandMentions: {
                    ...fetchMoreResult.creatorProfileBrandMentions,
                    data: [
                      ...prev.creatorProfileBrandMentions.data,
                      ...fetchMoreResult.creatorProfileBrandMentions.data,
                    ],
                  },
                };
              },
            });
          }}
          hasMore={
            socialPostsData?.creatorProfileBrandMentions.pageInfo.hasNextPage
          }
          threshold={250}
          useWindow={false}
          initialLoad={false}
          loader={
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                mt: 4,
              }}
            >
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[900]}
              >
                Loading...
              </Typography>
            </Box>
          }
        >
          {socialPosts.length ? (
            <Grid container spacing={6}>
              {socialPosts.map((socialPost) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={2.4}
                    key={socialPost.id}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSelectedSocialPostId(socialPost.id);
                      socialPostDisclosure.onOpen();
                      analytics.track(
                        EventName.CreatorProfileAboutPostClicked,
                        {
                          brandId: filters.brandId,
                          userId: user?.id,
                          creatorProfileId: filters.creatorProfileId,
                          postId: socialPost.id,
                          section: 'brand-mention',
                        },
                      );
                    }}
                  >
                    <SocialPostCardView socialPost={socialPost} />
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[600]}
              >
                {socialPostsLoading
                  ? 'Loading...'
                  : 'No brand mentions spotted 👀'}
              </Typography>
            </Box>
          )}
        </InfiniteScroll>
      </Box>

      <SocialMediaListeningPostDetailDialogView
        {...socialPostDisclosure}
        postId={selectedSocialPostId}
        brandId={filters.brandId}
        copyLink={shareableLink}
      />
    </HighlightableSection>
  );
};
