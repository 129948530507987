import { gql } from '@apollo/client';
import {
  CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragment,
  CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragmentDoc,
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragmentDoc,
  Platform,
  UpdateTrackingInsightsForCreatorProfileByCreatorIdInput,
  useGetFollowingForUseCreatorsTopicTrackingForSocialListeningQuery,
  useGetTrackedCreatorsForTopicQuery,
  useSearchCreatorsForUseCreatorsTopicTrackingForSocialListeningQuery,
  useTrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation,
  useUntrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation,
  useUntrackCreatorForCreatorTrackingTopicsForUseCreatorTopicTrackingMutation,
  useUpdateCreatorTrackingInsightsForUseCreatorsTopicTrackingForSocialListeningMutation,
} from 'graphql/generated';
import { useState } from 'react';

// eslint-disable-next-line
gql`
  mutation TrackCreatorForCreatorTrackingTopicForUseCreatorTopicTracking(
    $data: TrackCreatorInput!
    $trackingInsightsBrandId: String!
    $creatorTrackedTopicBrandId: String!
  ) {
    trackCreatorForCreatorTrackingTopic(data: $data) {
      ...CreatorFragmentUseCreatorsTopicTrackingForSocialListening
    }
  }
  ${CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UntrackCreatorForCreatorTrackingTopicForUseCreatorTopicTracking(
    $data: TrackCreatorInput!
    $trackingInsightsBrandId: String!
    $creatorTrackedTopicBrandId: String!
  ) {
    untrackCreatorForCreatorTrackingTopic(data: $data) {
      ...CreatorFragmentUseCreatorsTopicTrackingForSocialListening
    }
  }
  ${CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation UntrackCreatorForCreatorTrackingTopicsForUseCreatorTopicTracking(
    $data: UntrackCreatorForTopicsInput!
    $trackingInsightsBrandId: String!
    $creatorTrackedTopicBrandId: String!
  ) {
    untrackCreatorForCreatorTrackingTopics(data: $data) {
      ...CreatorFragmentUseCreatorsTopicTrackingForSocialListening
    }
  }
  ${CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CreatorFragmentUseCreatorsTopicTrackingForSocialListening on CreatorModel {
    id
    creatorTrackedTopics(brandId: $creatorTrackedTopicBrandId) {
      id
    }
    isTrackingInsightsForCreatorProfile(brandId: $trackingInsightsBrandId)
    ...CreatorProfileFragmentSocialMediaListeningCreatorProfile
  }
  ${CreatorProfileFragmentSocialMediaListeningCreatorProfileFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetTrackedCreatorsForTopic(
    $data: TopicTrackedCreatorsInput!
    $trackingInsightsBrandId: String!
    $creatorTrackedTopicBrandId: String!
  ) {
    getTrackedCreatorsForTopic(data: $data) {
      ...CreatorFragmentUseCreatorsTopicTrackingForSocialListening
    }
  }
  ${CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetFollowingForUseCreatorsTopicTrackingForSocialListening(
    $filters: SocialCreatorFilters!
  ) {
    getFollowing(filters: $filters) {
      id
      nickName
      platform
      platformId
      profilePictureUrl
      updatedAt
      handle
      followingCount
      followerCount
      createdAt
    }
  }
`;

// eslint-disable-next-line
gql`
  query SearchCreatorsForUseCreatorsTopicTrackingForSocialListening(
    $query: String!
    $filters: SocialCreatorFilters!
  ) {
    searchCreators(query: $query, filters: $filters) {
      id
      nickName
      platform
      platformId
      profilePictureUrl
      updatedAt
      handle
      followingCount
      followerCount
      createdAt
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCreatorTrackingInsightsForUseCreatorsTopicTrackingForSocialListening(
    $input: UpdateTrackingInsightsForCreatorProfileByCreatorIdInput!
    $trackingInsightsBrandId: String!
    $creatorTrackedTopicBrandId: String!
  ) {
    updateTrackingInsightsForCreatorProfileByCreatorId(input: $input) {
      ...CreatorFragmentUseCreatorsTopicTrackingForSocialListening
    }
  }
  ${CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragmentDoc}
`;

type Props = {
  searchCreator?: string;
  topicId?: string;
  platform: Platform;
  handle?: string;
  brandId: string;
};

export const useCreatorsTopicTrackingForSocialListening = ({
  searchCreator,
  topicId: _topicId,
  platform,
  handle,
  brandId,
}: Props) => {
  // This is to show a loading spinner when the user clicks on the follow button since the API takes a while to respond while connecting to rapidAPI
  const [updatingCreatorPlatformIds, setUpdatingCreatorPlatformIds] = useState<
    string[]
  >([]);

  const [trackCreator] =
    useTrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation();
  const [untrackCreator] =
    useUntrackCreatorForCreatorTrackingTopicForUseCreatorTopicTrackingMutation();
  const [untrackCreatorForTopics] =
    useUntrackCreatorForCreatorTrackingTopicsForUseCreatorTopicTrackingMutation();
  const [updateCreatorTrackingInsights] =
    useUpdateCreatorTrackingInsightsForUseCreatorsTopicTrackingForSocialListeningMutation();

  const { data: followingCreatorsData, loading: loadingFollowingCreators } =
    useGetFollowingForUseCreatorsTopicTrackingForSocialListeningQuery({
      variables: {
        filters: {
          platform,
          handle,
        },
      },
      skip: !handle,
    });

  const { data: searchCreatorsData, loading: searchingCreators } =
    useSearchCreatorsForUseCreatorsTopicTrackingForSocialListeningQuery({
      variables: {
        query: searchCreator || '',
        filters: {
          platform,
        },
      },
      skip: !searchCreator,
    });

  const {
    data: trackedCreatorsData,
    loading,
    updateQuery,
  } = useGetTrackedCreatorsForTopicQuery({
    variables: {
      data: {
        topicId: _topicId || '',
        platforms: [platform],
      },
      trackingInsightsBrandId: brandId,
      creatorTrackedTopicBrandId: brandId,
    },
    skip: !_topicId || !brandId,
  });

  const trackedCreators = trackedCreatorsData?.getTrackedCreatorsForTopic || [];

  const updateTrackCreatorsQuery = (
    creator: CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragment,
  ) => {
    updateQuery((prev) => {
      const creatorIndex = prev.getTrackedCreatorsForTopic.findIndex(
        (c) => c.id === creator.id,
      );

      if (creatorIndex === -1) {
        return {
          ...prev,
          getTrackedCreatorsForTopic: [
            ...prev.getTrackedCreatorsForTopic,
            creator,
          ],
        };
      }

      const updatedCreators = {
        ...prev,
        getTrackedCreatorsForTopic: prev.getTrackedCreatorsForTopic.map(
          (c, index) => (index === creatorIndex ? creator : c),
        ),
      };

      return updatedCreators;
    });
  };

  const handleTrackCreator = async (
    creatorPlatformId: string,
    topicId: string,
    handle: string,
  ) => {
    setUpdatingCreatorPlatformIds([
      ...updatingCreatorPlatformIds,
      creatorPlatformId,
    ]);

    return trackCreator({
      variables: {
        data: {
          topicId,
          data: {
            platformId: creatorPlatformId,
            platform,
            handle,
          },
        },
        creatorTrackedTopicBrandId: brandId,
        trackingInsightsBrandId: brandId,
      },
      onError: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
      },
      onCompleted: (data) => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
        if (_topicId) {
          updateTrackCreatorsQuery(data.trackCreatorForCreatorTrackingTopic);
        }
      },
    });
  };

  const handleUntrackCreator = async (
    creatorPlatformId: string,
    topicId: string,
    handle: string,
  ) => {
    setUpdatingCreatorPlatformIds([
      ...updatingCreatorPlatformIds,
      creatorPlatformId,
    ]);

    return untrackCreator({
      variables: {
        data: {
          topicId,
          data: {
            platformId: creatorPlatformId,
            platform,
            handle,
          },
        },
        trackingInsightsBrandId: brandId,
        creatorTrackedTopicBrandId: brandId,
      },
      onError: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
      },
      onCompleted: (data) => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
        if (_topicId) {
          updateTrackCreatorsQuery(data.untrackCreatorForCreatorTrackingTopic);
        }
      },
    });
  };

  const handleUntrackCreatorForTopics = async (
    creatorPlatformId: string,
    topicIds: string[],
    handle: string,
  ) => {
    setUpdatingCreatorPlatformIds([
      ...updatingCreatorPlatformIds,
      creatorPlatformId,
    ]);

    return untrackCreatorForTopics({
      variables: {
        data: {
          topicIds,
          data: {
            platformId: creatorPlatformId,
            platform,
            handle,
          },
        },
        trackingInsightsBrandId: brandId,
        creatorTrackedTopicBrandId: brandId,
      },
      onError: () => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
      },
      onCompleted: (data) => {
        setUpdatingCreatorPlatformIds(
          updatingCreatorPlatformIds.filter((id) => id !== creatorPlatformId),
        );
        if (_topicId) {
          updateTrackCreatorsQuery(data.untrackCreatorForCreatorTrackingTopics);
        }
      },
    });
  };

  const handleUpdateCreatorTrackingInsights = async (
    input: UpdateTrackingInsightsForCreatorProfileByCreatorIdInput,
  ) => {
    return updateCreatorTrackingInsights({
      variables: {
        input,
        trackingInsightsBrandId: brandId,
        creatorTrackedTopicBrandId: brandId,
      },
      onCompleted: (data) => {
        if (_topicId) {
          updateTrackCreatorsQuery(
            data.updateTrackingInsightsForCreatorProfileByCreatorId,
          );
        }
      },
    });
  };

  return {
    data: {
      trackedCreators: {
        data: trackedCreators,
        loading,
      },
      followingCreators: {
        data: followingCreatorsData?.getFollowing || [],
        loading: loadingFollowingCreators,
      },
      searchedCreators: {
        data: searchCreatorsData?.searchCreators || [],
        loading: searchingCreators,
      },

      inProgress: {
        updatingCreatorPlatformIds,
      },
    },

    actions: {
      handleTrackCreator,
      handleUntrackCreator,
      handleUntrackCreatorForTopics,
      handleUpdateCreatorTrackingInsights,
    },
  };
};
