import { gql } from '@apollo/client';
import { Box, Button, Fade, Grid, Typography } from '@mui/material';
import { TextSwitch } from 'components/common/TextSwitch';
import { IconBoldGrid2 } from 'components/icons/components/bold/IconBoldGrid2';
import { IconLinearGrid2 } from 'components/icons/components/linear/IconLinearGrid2';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineBulletedList } from 'components/icons/components/outline/IconOutlineBulletedList';
import { ManuallyParsedSocialPostTrackerDialogView } from 'features/manuallyParsedSocialPostTracker';
import { NestedFiltersMenuView } from 'features/nestedFilters/views/menu/NestedFiltersMenuView';
import { SocialPostCommentCardViewSkeleton } from 'features/socialPostComment';
import {
  SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc,
  SocialPostCommentsFilters,
  SortOrder,
  useGetSocialPostCommentsForSocialMediaListeningMostEngagedResponsesQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SocialPostCommentsGridView } from './views';
import { SocialPostCommentsListView } from './views/SocialPostCommentsListView/SocialPostCommentsListView';
import { SocialPostCommentsListViewSkeleton } from './views/SocialPostCommentsListView/SocialPostCommentsListViewSkeleton';

// eslint-disable-next-line
gql`
  query GetSocialPostCommentsForSocialMediaListeningMostEngagedResponses(
    $brandId: String!
    $data: SocialPostCommentsInput!
  ) {
    socialPostComments(data: $data) {
      data {
        ...SocialPostCommentFragmentSocialPostCommentCardView
      }
      meta {
        totalCount
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${SocialPostCommentFragmentSocialPostCommentCardViewFragmentDoc}
`;

const TAKE = 6;

type SocialMediaListeningMostEngagedResponsesProps = {
  brandId: string;
  filters: SocialPostCommentsFilters;
};

export const MOST_ENGAGED_RESPONSES_VIEW_KEY_LABEL = 'mostEngagedResponsesView';

type SortConfig = {
  field: 'diggCount' | 'childCommentCount';
  order: SortOrder;
} | null;

export enum SocialPostCommentSortField {
  Recency = 'Recency',
  TotalEngagement = 'TotalEngagement',
}

const SORT_OPTIONS = [
  {
    label: 'Recency',
    value: SocialPostCommentSortField.Recency,
  },
  {
    label: 'Total Engagement',
    value: SocialPostCommentSortField.TotalEngagement,
  },
];

export const SocialMediaListeningMostEngagedResponses = (
  props: SocialMediaListeningMostEngagedResponsesProps,
) => {
  const { brandId, filters } = props;

  // local sort type state
  const [sortType, setSortType] = useState<SocialPostCommentSortField>(
    SocialPostCommentSortField.Recency,
  );
  const [isAnimating, setIsAnimating] = useState(false);
  const [isDataAnimating, setIsDataAnimating] = useState(false);
  const [columnSort, setColumnSort] = useState<SortConfig>(null);

  const handleSort = (sortField: SocialPostCommentSortField) => {
    setIsDataAnimating(true);
    setSortType(sortField);
    setColumnSort(null); // Reset column sort when using global sort
    setTimeout(() => setIsDataAnimating(false), 300);
  };

  const handleColumnSort = (field: 'diggCount' | 'childCommentCount') => {
    setColumnSort((prev) => {
      if (!prev || prev.field !== field) {
        return {
          field,
          order: SortOrder.Desc,
        };
      }
      if (prev.order === SortOrder.Desc) {
        return {
          field,
          order: SortOrder.Asc,
        };
      }
      return null;
    });
  };

  const { value: cachedView, setValue: setView } = useLocalStorage<
    'grid' | 'list'
  >(MOST_ENGAGED_RESPONSES_VIEW_KEY_LABEL, 'grid');
  const view = cachedView;

  const navigate = useGuardNavigate();
  const location = useLocation();
  const { backgroundLocation } =
    (location.state as {
      backgroundLocation?: Location;
    }) || {};

  // sort by recency or total engagement
  const sortByBySortType = useMemo(() => {
    if (columnSort) {
      return {
        field: columnSort.field,
        order: columnSort.order,
      };
    }
    // if sortType is recency, sort by platformCreateTime
    if (sortType === SocialPostCommentSortField.Recency) {
      return {
        field: 'platformCreateTime',
        order: SortOrder.Desc,
      };
    }
    if (sortType === SocialPostCommentSortField.TotalEngagement) {
      // if sortType is total engagement, sort by engagement
      return {
        field: 'engagement',
        order: SortOrder.Desc,
      };
    }
  }, [sortType, columnSort]);

  const { data, loading, fetchMore } =
    useGetSocialPostCommentsForSocialMediaListeningMostEngagedResponsesQuery({
      variables: {
        brandId,
        data: {
          filters,
          sortBy: sortByBySortType,
          take: TAKE,
        },
      },
      fetchPolicy: 'network-only',
    });

  const socialPostComments = useMemo(
    () => data?.socialPostComments.data || [],
    [data?.socialPostComments.data],
  );

  const isEmpty = !loading && !socialPostComments.length;

  const handleLoadMore = async () => {
    if (!data?.socialPostComments.pageInfo.hasNextPage) return;

    await fetchMore({
      variables: {
        brandId,
        data: {
          filters,
          sortBy: sortByBySortType,
          take: TAKE,
          cursor: data.socialPostComments.pageInfo.endCursor,
        },
      },
    });
  };

  const handleViewChange = (isLeftOptionSelected: boolean) => {
    setIsAnimating(true);
    setView(isLeftOptionSelected ? 'grid' : 'list');
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(6) }}
    >
      <Box
        sx={{
          bgcolor: theme.colors?.primary.white,
          borderRadius: theme.spacing(6),
          border: `1px solid ${theme.colors?.utility[300]}`,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
          p: 6,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <Typography variant="headline-lg">
              Most engaged responses
            </Typography>
            <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
              This displays the most engaged responses in your community.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(3),
            }}
          >
            <NestedFiltersMenuView
              items={[
                {
                  type: 'single-select',
                  key: 'sort',
                  options: SORT_OPTIONS,
                },
              ]}
              values={{
                sort: {
                  value: SORT_OPTIONS.find(
                    (option) => option.value === sortType,
                  ),
                },
              }}
              onChange={(values) => {
                const newValue = values.sort
                  ?.value as (typeof SORT_OPTIONS)[number];
                if (newValue) {
                  handleSort(newValue.value);
                }
              }}
              componentsProps={{
                trigger: {
                  text: SORT_OPTIONS.find((option) => option.value === sortType)
                    ?.label,
                  Icon: IconLinearSort,
                  sx: {
                    borderRadius: 2,
                  },
                },
              }}
            />
            <TextSwitch
              itemLeft={
                view === 'grid' ? (
                  <IconBoldGrid2 size={16} />
                ) : (
                  <IconLinearGrid2 size={16} />
                )
              }
              itemRight={<IconOutlineBulletedList size={16} />}
              selectedOptionColor={theme.colors?.primary.white}
              isLeftOptionSelected={view === 'grid'}
              onClick={handleViewChange}
              sx={{
                bgcolor: theme.colors?.utility[275],
                borderRadius: theme.spacing(2),
                padding: theme.spacing(1),
                gap: theme.spacing(1),
                alignItems: 'center',
              }}
              componentProps={{
                item: {
                  sx: {
                    width: theme.spacing(7),
                    height: theme.spacing(7),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                },
                indicator: {
                  sx: {
                    bgcolor: theme.colors?.primary.black,
                    borderRadius: theme.spacing(2),
                    width: theme.spacing(7),
                    height: theme.spacing(7),
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* List view  */}
        <Box sx={{ margin: theme.spacing(9, 0) }}>
          {loading ? (
            view === 'grid' ? (
              <SocialPostCommentCardViewSkeleton numberOfCards={3} />
            ) : (
              <SocialPostCommentsListViewSkeleton numberOfRows={5} />
            )
          ) : isEmpty ? (
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} md={4} xl={3}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      width: theme.spacing(37),
                      height: theme.spacing(49),
                      borderRadius: theme.spacing(4),
                      bgcolor: theme.colors?.utility[275],
                    }}
                  />
                  <Box
                    sx={{
                      bgcolor: theme.colors?.utility[250],
                      borderRadius: 4,
                      padding: 3,
                      zIndex: 1,
                      ml: -7,
                    }}
                  >
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.utility[700]}
                    >
                      No comments yet
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <>
              {socialPostComments.length === 0 && (
                <Fade in={!isDataAnimating} timeout={300}>
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.utility[600]}
                    textAlign="center"
                    display="block"
                  >
                    None found
                  </Typography>
                </Fade>
              )}
              <Box sx={{ margin: theme.spacing(9, 0) }}>
                {view === 'grid' && (
                  <SocialPostCommentsGridView
                    socialPostComments={socialPostComments}
                    brandId={brandId}
                    hasMore={data?.socialPostComments.pageInfo.hasNextPage}
                    loadMore={handleLoadMore}
                  />
                )}
                {view === 'list' && (
                  <SocialPostCommentsListView
                    socialPostComments={socialPostComments}
                    brandId={brandId}
                    hasMore={data?.socialPostComments.pageInfo.hasNextPage}
                    loadMore={handleLoadMore}
                    columnSort={columnSort}
                    onColumnSort={handleColumnSort}
                  />
                )}
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <ManuallyParsedSocialPostTrackerDialogView
              filters={{
                brandIds: [brandId],
                platforms: filters.platforms,
              }}
            />
          </Box>

          {socialPostComments.length > 0 && (
            <Box sx={{ textAlign: 'center' }}>
              <Button
                sx={{
                  textDecoration: 'underline',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={() => {
                  navigate(
                    PlotRoutes.socialListeningAnalyticsOutboundInteractions(
                      brandId,
                      {
                        platforms: filters.platforms,
                        startDate: filters.dateRange?.startDate,
                        endDate: filters.dateRange?.endDate,
                      },
                    ),
                    {
                      state: {
                        backgroundLocation: backgroundLocation || location,
                      },
                    },
                  );
                }}
              >
                See all
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {/* Footer note view */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subhead-xl" color={theme.colors?.utility[600]}>
          Note: Only comments ranking in the top 100 by engagement are
          processed. Processing takes up to 24 hours
        </Typography>
        <Typography variant="subhead-xl" color={theme.colors?.utility[600]}>
          Last updated yesterday at 12pm
        </Typography>
      </Box>
    </Box>
  );
};
