import { Box, Switch, Typography } from '@mui/material';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import {
  NestedFiltersDefaultContextProvider,
  NestedFiltersSelectedChips,
} from 'features/nestedFilters';
import { SocialListeningRadarEventsView } from 'features/socialListeningRadar';
import {
  SocialListeningFeatureType,
  SocialMediaListeningTopHeaderBreadcrumbs,
} from 'features/socialMediaListening';
import {
  PaginatedRadarEventInput,
  PlotFeature,
  RadarEventReadStatus,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { Filters } from './Filters';

export const SocialMediaListeningRadarPage = () => {
  const { brandId = '' } = useParams();
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  const { isFeatureEnabled, loading: isFeatureFlagLoading } =
    useFeatureFlagContext();

  useEffect(() => {
    if (!isFeatureFlagLoading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.SocialListeningCrm,
      );

      if (!_isFeatureEnabled) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, isFeatureFlagLoading]); // eslint-disable-line

  const analytics = useAnalytics();

  const [totalCount, setTotalCount] = useState(0);
  const [unreadOnly, setUnreadOnly] = useState(true);
  const [filters, setFilters] = useState<Partial<PaginatedRadarEventInput>>({});

  useEffect(() => {
    analytics.track(EventName.CommunityUpdatesViewed, {
      brandId,
      userId: user?.id,
      unreadOnly,
    });
  }, [unreadOnly]); // eslint-disable-line

  useEffect(() => {
    analytics.track(EventName.CommunityUpdatesFilterApplied, {
      brandId,
      userId: user?.id,
      unreadOnly,
      filters,
    });
  }, [filters]); // eslint-disable-line

  useEffect(() => {
    setHeaderSx({
      borderBottom: '1px solid rgba(35, 6, 3, 0.05)',
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box display="flex" alignItems="center" gap={3}>
        <SocialMediaListeningTopHeaderBreadcrumbs
          featureType={SocialListeningFeatureType.Community}
          brandId={brandId}
          customLabel={
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[700]}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Community Hub
            </Typography>
          }
        />
        <Typography variant="headline-sm">/</Typography>
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.black}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Community Updates
        </Typography>
      </Box>
    ));

    return () => {
      setRenderTitle(() => null);
      setHeaderSx({});
    };
  }, [location.pathname]); // eslint-disable-line

  return (
    <Box
      mt={16}
      px={8}
      sx={{
        boxShadow: '0px 4px 14px 0px rgba(0, 0, 0, 0.05)',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      <NestedFiltersDefaultContextProvider>
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              pt: 6,
            }}
          >
            <Typography variant="headline-xl" fontSize={24} fontWeight={500}>
              Community Updates
            </Typography>
            {totalCount > 0 ? (
              <Typography
                variant="headline-md"
                color={theme.colors?.utility[700]}
              >
                ({totalCount})
              </Typography>
            ) : null}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              component="div"
              variant="headline-sm"
              color={theme.colors?.utility[700]}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Unreads
              <Switch
                checked={unreadOnly}
                onChange={() => setUnreadOnly(!unreadOnly)}
              />
            </Typography>
            <Filters setFilters={setFilters} brandId={brandId} />
          </Box>
          <NestedFiltersSelectedChips
            sx={{
              mt: 6,
            }}
          />
        </Box>
      </NestedFiltersDefaultContextProvider>
      <SocialListeningRadarEventsView
        filters={{
          ...filters,
          brandId,
          readStatuses: unreadOnly
            ? [RadarEventReadStatus.Unread]
            : [RadarEventReadStatus.Unread, RadarEventReadStatus.Read],
        }}
        setTotalCount={setTotalCount}
      />
    </Box>
  );
};
