import { Box, IconButton, Typography } from '@mui/material';
import { CommandKey } from 'components/common/CommandKey';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { theme } from 'styles/theme';
import { getMetaKey } from 'utils/keyboard';

export const SearchSidebarLink = () => {
  const { triggerCommand } = useCommandContext();
  return (
    <IconButton
      disableRipple
      onClick={() => triggerCommand(COMMAND_TYPE.SEARCH)}
      sx={{
        p: 2,
        borderRadius: 2,
        ':hover': {
          backgroundColor: 'rgba(35, 6, 3, 0.05)',
        },
        color: theme.colors?.utility[800],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <IconOutlineSearchNormal1
          size={16}
          color={theme.colors?.utility[700]}
        />
        <Typography variant="subhead-lg">Search</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 0.5,
          alignItems: 'center',
        }}
      >
        <CommandKey
          sx={{
            border: 'none',
            background: theme.colors?.utility[400],
          }}
        >
          {getMetaKey()}
        </CommandKey>
        <Typography variant="subhead-sm">+</Typography>
        <CommandKey
          sx={{
            border: 'none',
            background: theme.colors?.utility[400],
          }}
        >
          k
        </CommandKey>
      </Box>
    </IconButton>
  );
};
