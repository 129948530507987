import { gql } from '@apollo/client';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconCustomAnnounce } from 'components/icons/components/custom/IconCustomAnnounce';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { IconCustomVideo } from 'components/icons/components/custom/IconCustomVideo';
import { CreatorProfileFragmentSlCreatorProfileInsightsViewFragment } from 'graphql/generated';
import { theme } from 'styles/theme';
import { StyledSection, StyledSectionTitle } from './styles';

export const CREATOR_PROFILE_FRAGMENT_SL_CREATOR_PROFILE_INSIGHTS_VIEW = gql`
  fragment CreatorProfileFragmentSLCreatorProfileInsightsView on CreatorProfileModel {
    id
    creators {
      id
      audience_demographics
      otherInsights
      industry
    }
  }
`;

export type SocialListeningCreatorProfileInsightsViewProps = {
  creatorProfile: CreatorProfileFragmentSlCreatorProfileInsightsViewFragment;
};

export const SocialListeningCreatorProfileInsightsView = (
  props: SocialListeningCreatorProfileInsightsViewProps,
) => {
  const { creatorProfile } = props;

  const audienceDemographics =
    creatorProfile.creators.find((creator) => creator.audience_demographics)
      ?.audience_demographics ?? '';
  const industries = creatorProfile.creators
    .flatMap((creator) => creator.industry?.split('|'))
    .filter(Boolean);
  const otherInsights = creatorProfile.creators
    .flatMap((creator) => creator.otherInsights?.split('|'))
    .filter(Boolean);
  const isEmpty =
    !audienceDemographics &&
    industries.length === 0 &&
    otherInsights.length === 0;

  return (
    <Box
      sx={{
        p: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Creator Insights
        </Typography>
        <Tooltip
          title="'Superfan Creator' data is available only for creators who have made content about your brand."
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: 232,
              },
            },
          }}
        >
          <Chip
            variant="filled-borderless"
            icon={
              <IconCustomAnnounce
                size={16}
                style={{ color: theme.colors?.utility['blue-4'] }}
              />
            }
            label="Superfan Creator"
            sx={{
              ...typography['headline-sm'],
              color: theme.colors?.utility['blue-4'],
              bgcolor: theme.colors?.utility['blue-1'],
            }}
          />
        </Tooltip>
      </Box>
      {isEmpty ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="headline-sm" color={theme.colors?.utility[600]}>
            Getting to know them better... Check back soon! 🔍
          </Typography>
        </Box>
      ) : (
        <Grid container spacing={4} height="100%">
          <Grid item xs={12} md={5}>
            <StyledSection>
              <StyledSectionTitle>
                <IconCustomUsers size={16} />
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.primary.black}
                >
                  Audience
                </Typography>
              </StyledSectionTitle>
              {audienceDemographics && (
                <Typography
                  component="p"
                  variant="subhead-xl"
                  color={theme.colors?.utility[900]}
                >
                  {audienceDemographics}
                </Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  pt: 2,
                  mt: 'auto',
                  gap: 2,
                }}
              >
                {industries.map((industry) => (
                  <Chip
                    key={industry}
                    variant="filled-borderless"
                    label={industry}
                  />
                ))}
              </Box>
            </StyledSection>
          </Grid>
          {otherInsights && (
            <Grid item xs={12} md={7}>
              <StyledSection height="100%">
                <StyledSectionTitle>
                  <IconCustomVideo size={16} />
                  <Typography
                    variant="headline-sm"
                    color={theme.colors?.primary.black}
                  >
                    Content Type
                  </Typography>
                </StyledSectionTitle>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {otherInsights.map((insight) => (
                    <Typography
                      key={insight}
                      component="p"
                      variant="subhead-xl"
                      color={theme.colors?.utility[900]}
                    >
                      {insight}
                    </Typography>
                  ))}
                </Box>
              </StyledSection>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
};
