import { gql } from '@apollo/client';
import { Badge, Box, Typography } from '@mui/material';
import { ImageWithVideoFallback } from 'components/common/Image';
import { IconLinearArrowRight3 } from 'components/icons/components/linear/IconLinearArrowRight3';
import { useUserContext } from 'contexts/users/User.context/User.context';
import { getMessageByEventType } from 'features/radarEvent';
import {
  RadarEventReadStatus,
  RadarEventType,
  RadarEventViewType,
  SocialPostFragmentSocialPostCardViewFragmentDoc,
  useGetPaginatedRadarEventsForSlCommunityInfoViewQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetPaginatedRadarEventsForSLCommunityInfoView(
    $input: PaginatedRadarEventInput!
  ) {
    getPaginatedRadarEvents(input: $input) {
      data {
        id
        eventType
        eventTypeCounter
        message
        creatorProfile {
          id
          fullName
        }
        socialPost {
          id
          thumbnailUrl
          videoUrl
        }
      }
      meta {
        totalCount
      }
    }
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

export type SocialListeningCommunityInfoViewProps = {
  brandId: string;
};

export const SocialListeningCommunityInfoView = ({
  brandId,
}: SocialListeningCommunityInfoViewProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const analytics = useAnalytics();

  const { data } = useGetPaginatedRadarEventsForSlCommunityInfoViewQuery({
    variables: {
      input: {
        brandId,
        readStatuses: [RadarEventReadStatus.Unread],
        viewType: RadarEventViewType.KeyEvents,
        take: 3,
      },
    },
  });
  const radarEvents = data?.getPaginatedRadarEvents.data || [];
  const totalCount = data?.getPaginatedRadarEvents.meta.totalCount || 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 4,
        bgcolor: theme.colors?.utility[275],
        borderRadius: 3,
        gap: 3,
        cursor: 'pointer',
      }}
      onClick={() => {
        analytics.track(EventName.CrmDashboardCommunityUpdatesClicked, {
          brandId,
          userId: user?.id,
        });
        navigate(PlotRoutes.socialListeningRadar(brandId));
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Typography
          component="div"
          variant="headline-xs"
          color={theme.colors?.utility['blue-4']}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}
        >
          Community Updates
          {totalCount > 0 ? (
            <Badge
              sx={{
                bgcolor: theme.colors?.utility['blue-3'],
                color: theme.colors?.primary.parchment,
                borderRadius: 8,
                ...theme.typography['headline-xxs'],
                p: theme.spacing(0.5, 2),
              }}
            >
              {totalCount} new
            </Badge>
          ) : null}
        </Typography>
        <Link to={PlotRoutes.socialListeningRadar(brandId)}>
          <IconLinearArrowRight3
            size={16}
            color={theme.colors?.utility['green-4']}
          />
        </Link>
      </Box>
      {radarEvents.length > 0 ? (
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
          {radarEvents.map((radarEvent) => (
            <Box
              key={radarEvent.id}
              sx={{
                padding: 3,
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                justifyContent: 'space-between',
                borderRadius: 2,
                background: 'rgba(217, 227, 243, 0.50)',
                flex: 1,
              }}
            >
              {/* detail view */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 3,
                }}
              >
                <Typography variant="subhead-xl">
                  <b>{radarEvent.creatorProfile.fullName}</b>{' '}
                  {getMessageByEventType(radarEvent.eventType) ||
                    radarEvent.message}{' '}
                  {[
                    RadarEventType.Ugc,
                    RadarEventType.Comment,
                    RadarEventType.Engagement,
                  ].includes(radarEvent.eventType)
                    ? `${radarEvent.eventTypeCounter} time${
                        radarEvent.eventTypeCounter > 1 ? 's' : ''
                      }`
                    : null}
                </Typography>
              </Box>
              {/* post card */}
              {(radarEvent.socialPost?.thumbnailUrl ||
                radarEvent.socialPost?.videoUrl) && (
                <ImageWithVideoFallback
                  imageUrl={radarEvent.socialPost.thumbnailUrl || ''}
                  videoUrl={radarEvent.socialPost.videoUrl || ''}
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 2,
                    objectFit: 'cover',
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 80,
          }}
        >
          <Typography variant="headline-md" color={theme.colors?.utility[500]}>
            No New Updates
          </Typography>
        </Box>
      )}
    </Box>
  );
};
