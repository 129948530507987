import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from 'components/common/DatePicker';
import { MultipleTagInput } from 'components/common/MultipleTagInput';
import { toast } from 'components/common/Toast';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCake } from 'components/icons/components/bold/IconBoldCake';
import { IconBoldEnvelope } from 'components/icons/components/bold/IconBoldEnvelope';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconBoldLocation } from 'components/icons/components/bold/IconBoldLocation';
import { IconBoldShare } from 'components/icons/components/bold/IconBoldShare';
import { IconBoldTag } from 'components/icons/components/bold/IconBoldTag';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomUser } from 'components/icons/components/custom/IconCustomUser';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { useUserContext } from 'contexts/users/User.context';
import {
  RelationshipBackgroundColorMap,
  RelationshipColorMap,
  RelationshipLabelMap,
} from 'features/socialListeningCommunity';
import { SocialListeningLabelSelector } from 'features/socialListeningCommunity/components';
import { useSocialListeningCreatorLinkGenerator } from 'features/socialMediaListening/hooks/useSocialListeningCreatorLinkGenerator';
import {
  CreatorFragmentSlCreatorProfileHandlerViewFragmentDoc,
  CreatorFragmentUseSocialListeningCreatorLinkGeneratorFragmentDoc,
  CreatorProfileFragmentSlCreatorProfileDetailsViewFragment,
  CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc,
  Platform,
  SocialListeningCreatorProfileMergeViewFragmentCreatorFragment,
  SocialListeningCreatorProfileMergeViewFragmentCreatorFragmentDoc,
  useAddCreatorToCreatorProfileForSlCreatorProfileDetailsViewMutation,
  useCreateCreatorForSlCreatorProfileDetailsViewMutation,
  useGetCreatorProfilesByCreatorIdForSlCreatorProfileDetailsViewLazyQuery,
  useMergeCreatorProfilesForSlCreatorProfileDetailsViewMutation,
  useRemoveCreatorFromCreatorProfileForSlCreatorProfileDetailsViewMutation,
  useUpdateCreatorProfileForSlCreatorProfileDetailsViewMutation,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';
import { emailValidation } from 'utils/validations';
import { SocialListeningCreatorProfileHandlerView } from '../handler';
import { SocialListeningCreatorProfileMergeView } from '../merge';
import { StyledInput } from './styles';

export const CREATOR_PROFILE_FRAGMENT_SL_CREATOR_PROFILE_DETAILS_VIEW = gql`
  fragment CreatorProfileFragmentSLCreatorProfileDetailsView on CreatorProfileModel {
    id
    fullName
    emails
    brandId
    creators {
      id
      platform
      handle
      nickName
      audience_demographics
      industry
      otherInsights
      ...CreatorFragmentSLCreatorProfileHandlerView
      ...CreatorFragmentUseSocialListeningCreatorLinkGenerator
    }
    labels {
      id
      label
      color
      bgColor
    }
    relationshipStrength
    location
    reasonForLocation
    locationReferenceSocialPost {
      id
    }
    birthdate
  }
  ${CreatorFragmentSlCreatorProfileHandlerViewFragmentDoc}
  ${CreatorFragmentUseSocialListeningCreatorLinkGeneratorFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetCreatorProfilesByCreatorIdForSLCreatorProfileDetailsView(
    $input: CreatorProfilesByCreatorIdInput!
  ) {
    creatorProfilesByCreatorId(input: $input) {
      data {
        ...CreatorProfileFragmentSLCreatorProfileDetailsView
      }
    }
  }
  ${CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateCreatorProfileForSLCreatorProfileDetailsView(
    $input: UpdateCreatorProfileInput!
  ) {
    updateCreatorProfile(input: $input) {
      success
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation RemoveCreatorFromCreatorProfileForSLCreatorProfileDetailsView(
    $input: RemoveCreatorFromCreatorProfileInput!
  ) {
    removeCreatorFromCreatorProfile(input: $input) {
      success
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateCreatorForSLCreatorProfileDetailsView(
    $data: CreateCreatorInput!
  ) {
    createCreator(data: $data) {
      id
      ...SocialListeningCreatorProfileMergeViewFragmentCreator
    }
  }
  ${SocialListeningCreatorProfileMergeViewFragmentCreatorFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation AddCreatorToCreatorProfileForSLCreatorProfileDetailsView(
    $input: AddCreatorToCreatorProfileInput!
  ) {
    addCreatorToCreatorProfile(input: $input) {
      success
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation MergeCreatorProfilesForSLCreatorProfileDetailsView(
    $input: MergeCreatorProfilesInput!
  ) {
    mergeCreatorProfiles(input: $input) {
      success
    }
  }
`;

// GraphQL fragments and mutations remain unchanged...
const SocialHandleInput = ({
  platform,
  creators,
  creatorProfile,
  currentPlatform,
  setCurrentPlatform,
  createCreatorLoading,
  onCreateCreator,
  onRemoveCreator,
}) => {
  const { generateLink } = useSocialListeningCreatorLinkGenerator();

  const [showInput, setShowInput] = useState(creators.length === 0);

  useEffect(() => {
    setShowInput(creators.length === 0);
  }, [creators]);

  const platformConfig = useMemo(() => {
    const config = {
      [Platform.Instagram]: {
        icon: IconBoldInstagram,
        label: 'Instagram',
        placeholder: 'Add Instagram handle...',
      },
      [Platform.Tiktok]: {
        icon: IconCustomTiktok,
        label: 'Tiktok',
        placeholder: 'Add Tiktok handle...',
      },
      [Platform.Youtube]: {
        icon: IconCustomYoutubeShorts,
        label: 'YouTube Shorts',
        placeholder: 'Add YouTube Shorts handle...',
      },
    };
    return config[platform];
  }, [platform]);

  const renderLabel = useCallback(({ icon, label }) => {
    const Icon = icon;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          width: '40%',
          flexShrink: 0,
        }}
      >
        <Icon size={14} color={theme.colors?.utility[1100]} />
        <Typography variant="headline-sm" color={theme.colors?.primary.black}>
          {label}
        </Typography>
      </Box>
    );
  }, []);

  const [inputValue, setInputValue] = useState('');

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 4 }}>
      {renderLabel({
        icon: platformConfig.icon,
        label: platformConfig.label,
      })}
      <Box flex={1}>
        <Autocomplete
          multiple
          freeSolo
          value={creators.map((creator) => creator.handle)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={async (event, newValue) => {
            const existingHandles = creators.map((c) => c.handle);
            const addedHandles = (newValue || []).filter(
              (h) => !existingHandles.includes(h),
            );
            const removedHandles = existingHandles.filter(
              (h) => !newValue.includes(h),
            );

            // Handle removals
            const removalPromises = removedHandles.map(async (handle) => {
              const creator = creators.find((c) => c.handle === handle);
              if (creator && creator.id !== creatorProfile.defaultCreatorId) {
                await onRemoveCreator(creator.id);
              }
            });
            await Promise.all(removalPromises);

            // Handle additions
            const createPromises = addedHandles.map((handle) =>
              onCreateCreator(handle),
            );
            await Promise.all(createPromises);

            setShowInput(false);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const creator = creators.find((c) => c.handle === option);
              const canDelete = creator?.id !== creatorProfile.defaultCreatorId;

              return (
                <Chip
                  component="a"
                  href={generateLink(creator)}
                  target="_blank"
                  {...getTagProps({ index })}
                  key={index}
                  label={option}
                  variant="filled-borderless"
                  onDelete={
                    !canDelete
                      ? undefined
                      : (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          getTagProps({ index }).onDelete(e);
                        }
                  }
                  sx={{
                    cursor: 'pointer',
                    '& .MuiChip-label': {
                      ...typography['headline-sm'],
                      lineHeight: '24px',
                    },
                    height: 24,
                    m: '0 !important',
                  }}
                />
              );
            })
          }
          renderInput={(params) => (
            <Box
              ref={params.InputProps.ref}
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 1,
                borderRadius: 1,
                alignItems: 'center',
              }}
            >
              {params.InputProps.startAdornment}
              {createCreatorLoading && currentPlatform === platform && (
                <CircularProgress size={16} />
              )}
              {!showInput ? (
                <IconButton
                  sx={{
                    bgcolor: theme.colors?.utility[300],
                    height: 20,
                    width: 20,
                  }}
                  onClick={() => setShowInput(true)}
                >
                  <IconLinearAdd
                    color={theme.colors?.utility[800]}
                    size={16}
                    style={{
                      flexShrink: 0,
                    }}
                  />
                </IconButton>
              ) : (
                <TextField
                  {...params.inputProps}
                  placeholder={platformConfig.placeholder}
                  variant="standard"
                  color="primary"
                  size="medium"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={{
                    minWidth: 100,
                    width: '100%',
                    '& .MuiInputBase-input': {
                      ...typography['headline-sm'],
                      color: theme.colors?.utility[800],
                      '&::placeholder': {
                        color: theme.colors?.utility[600],
                        opacity: 1,
                      },
                      height: '24px',
                      lineHeight: '24px',
                      p: '0 !important',
                    },
                    '& .MuiInput-root': {
                      '&:before, &:after': {
                        display: 'none',
                      },
                    },
                  }}
                  onFocus={() => setCurrentPlatform(platform)}
                  onBlur={() => setCurrentPlatform(null)}
                  autoFocus={creators.length > 0}
                />
              )}
            </Box>
          )}
          options={[]}
          PaperComponent={() => null}
          sx={{
            '> div': {
              gap: 2,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export type SocialListeningCreatorProfileDetailsViewProps = {
  creatorProfile: CreatorProfileFragmentSlCreatorProfileDetailsViewFragment;
};

export const SocialListeningCreatorProfileDetailsView = (
  props: SocialListeningCreatorProfileDetailsViewProps,
) => {
  const { creatorProfile } = props;
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const [currentPlatform, setCurrentPlatform] = useState<Platform | null>(null);
  const [createCreator, { loading: createCreatorLoading }] =
    useCreateCreatorForSlCreatorProfileDetailsViewMutation();

  const [updateCreatorProfile] =
    useUpdateCreatorProfileForSlCreatorProfileDetailsViewMutation();
  const [addCreatorToCreatorProfile] =
    useAddCreatorToCreatorProfileForSlCreatorProfileDetailsViewMutation();
  const [removeCreatorFromCreatorProfile] =
    useRemoveCreatorFromCreatorProfileForSlCreatorProfileDetailsViewMutation();
  const [mergeCreatorProfiles] =
    useMergeCreatorProfilesForSlCreatorProfileDetailsViewMutation();

  const [creatorForMerge, setCreatorForMerge] = useState<
    SocialListeningCreatorProfileMergeViewFragmentCreatorFragment | undefined
  >(undefined);
  const [
    existingCreatorProfilesByCreator,
    setExistingCreatorProfilesByCreator,
  ] = useState<CreatorProfileFragmentSlCreatorProfileDetailsViewFragment[]>([]);
  const {
    isOpen: isMergeCreatorProfileModalOpen,
    onOpen: onMergeCreatorProfileModalOpen,
    onClose: onMergeCreatorProfileModalClose,
  } = useDisclosure();
  const [getCreatorProfilesByCreatorId] =
    useGetCreatorProfilesByCreatorIdForSlCreatorProfileDetailsViewLazyQuery();

  const creators = useMemo(
    () => ({
      instagram: creatorProfile.creators.filter(
        (creator) => creator.platform === Platform.Instagram,
      ),
      tiktok: creatorProfile.creators.filter(
        (creator) => creator.platform === Platform.Tiktok,
      ),
      youtube: creatorProfile.creators.filter(
        (creator) => creator.platform === Platform.Youtube,
      ),
    }),
    [creatorProfile.creators],
  );

  const addCreatorSeparately = useCallback(
    async (
      creator: SocialListeningCreatorProfileMergeViewFragmentCreatorFragment,
    ) => {
      await addCreatorToCreatorProfile({
        variables: {
          input: {
            creatorId: creator.id,
            creatorProfileId: creatorProfile.id,
          },
        },
        update: (cache) => {
          modifyObject(cache, creatorProfile.id, 'CreatorProfileModel', {
            creators: (existingCreators) => [...existingCreators, creator],
          });
        },
      });

      analytics.track(EventName.CreatorProfileSocialAccountUpdated, {
        brandId: creatorProfile.brandId,
        userId: user?.id,
        creatorProfileId: creatorProfile.id,
        creatorId: creator.id,
        type: 'added',
      });
    },
    [addCreatorToCreatorProfile, creatorProfile.id],
  );

  const runCreateCreator = useCallback(
    async (handle: string) => {
      if (!currentPlatform) {
        return;
      }

      const { data } = await createCreator({
        variables: {
          data: {
            data: {
              handle,
              platform: currentPlatform,
            },
          },
        },
      });

      if (!data?.createCreator) {
        toast({
          message: 'Some error occurred while verifying the creator handle',
          type: 'error',
        });
        return;
      }

      // get creator profiles by creator id
      const { data: creatorProfilesByCreatorIdData } =
        await getCreatorProfilesByCreatorId({
          variables: {
            input: {
              creatorId: data?.createCreator.id ?? '',
              brandId: creatorProfile.brandId,
              take: 999_999,
              skip: 0,
            },
          },
          fetchPolicy: 'network-only',
        });

      if (
        creatorProfilesByCreatorIdData?.creatorProfilesByCreatorId.data.length
      ) {
        setExistingCreatorProfilesByCreator(
          creatorProfilesByCreatorIdData?.creatorProfilesByCreatorId.data,
        );
        setCreatorForMerge(data?.createCreator);

        analytics.track(EventName.CreatorProfileMergeTriggered, {
          brandId: creatorProfile.brandId,
          userId: user?.id,
          currentCreatorProfileId: creatorProfile.id,
          creatorForMergeId: data?.createCreator.id,
        });

        onMergeCreatorProfileModalOpen();
        return;
      }

      if (data?.createCreator) {
        await addCreatorSeparately(data.createCreator);
      }

      return data?.createCreator;
    },
    [
      currentPlatform,
      createCreator,
      creatorProfile.brandId,
      getCreatorProfilesByCreatorId,
      onMergeCreatorProfileModalOpen,
      addCreatorSeparately,
    ],
  );

  const handleRemoveCreator = useCallback(
    async (creatorId: string) => {
      await removeCreatorFromCreatorProfile({
        variables: {
          input: {
            creatorId,
            creatorProfileId: creatorProfile.id,
          },
        },
        update: (cache) => {
          modifyObject(cache, creatorProfile.id, 'CreatorProfileModel', {
            creators: (existingCreators = []) =>
              existingCreators.filter((c) => c.id !== creatorId),
          });

          cache.evict({
            id: cache.identify({ id: creatorId, __typename: 'CreatorModel' }),
          });
        },
      });

      analytics.track(EventName.CreatorProfileSocialAccountUpdated, {
        brandId: creatorProfile.brandId,
        userId: user?.id,
        creatorProfileId: creatorProfile.id,
        creatorId,
        type: 'removed',
      });
    },
    [removeCreatorFromCreatorProfile, creatorProfile.id],
  );

  const renderLabel = useCallback(({ icon: Icon, label }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          width: '40%',
          flexShrink: 0,
          height: '24px',
          lineHeight: '24px',
        }}
      >
        <Icon size={14} color={theme.colors?.utility[1100]} />
        <Typography variant="headline-sm" color={theme.colors?.primary.black}>
          {label}
        </Typography>
      </Box>
    );
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Box
        sx={{
          p: 6,
          borderRadius: 6,
          border: `1px solid ${theme.colors?.utility[300]}`,
          backgroundColor: theme.colors?.primary.white,
          boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Typography variant="headline-sm">Details</Typography>
        <Divider
          sx={{ borderWidth: '1.5px', borderColor: theme.colors?.utility[275] }}
        />
        {/* Name */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({ icon: IconCustomUser, label: 'Name' })}
          <Typography variant="headline-sm" color={theme.colors?.utility[800]}>
            {creatorProfile.fullName}
          </Typography>
        </Box>
        {/* Email addresses */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({
            icon: IconBoldEnvelope,
            label: 'Email addresses',
          })}
          <Box flex={1}>
            <MultipleTagInput
              value={creatorProfile.emails ?? []}
              sx={{
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
              componentsProps={{
                chip: {
                  variant: 'filled-borderless',
                  sx: {
                    ...typography['headline-sm'],
                  },
                },
                input: {
                  placeholder: 'Add email address...',
                  sx: {
                    ...typography['headline-sm'],
                    color: theme.colors?.utility[800],
                    '&::placeholder': {
                      color: theme.colors?.utility[600],
                      opacity: 1,
                    },
                  },
                },
              }}
              onChange={async (emails) => {
                // remove invalid emails and duplicates
                emails = emails
                  .filter((email) => emailValidation.isValidSync(email))
                  .filter(
                    (email, index, self) => self.indexOf(email) === index,
                  );

                await updateCreatorProfile({
                  variables: {
                    input: {
                      id: creatorProfile.id,
                      data: { emails },
                    },
                  },
                  update: (cache) => {
                    modifyObject(
                      cache,
                      creatorProfile.id,
                      'CreatorProfileModel',
                      {
                        emails: () => emails,
                      },
                    );
                  },
                });
              }}
            />
          </Box>
        </Box>

        <SocialHandleInput
          platform={Platform.Instagram}
          creators={creators.instagram}
          creatorProfile={creatorProfile}
          currentPlatform={currentPlatform}
          setCurrentPlatform={setCurrentPlatform}
          createCreatorLoading={createCreatorLoading}
          onCreateCreator={runCreateCreator}
          onRemoveCreator={handleRemoveCreator}
        />

        <SocialHandleInput
          platform={Platform.Tiktok}
          creators={creators.tiktok}
          creatorProfile={creatorProfile}
          currentPlatform={currentPlatform}
          setCurrentPlatform={setCurrentPlatform}
          createCreatorLoading={createCreatorLoading}
          onCreateCreator={runCreateCreator}
          onRemoveCreator={handleRemoveCreator}
        />

        <SocialHandleInput
          platform={Platform.Youtube}
          creators={creators.youtube}
          creatorProfile={creatorProfile}
          currentPlatform={currentPlatform}
          setCurrentPlatform={setCurrentPlatform}
          createCreatorLoading={createCreatorLoading}
          onCreateCreator={runCreateCreator}
          onRemoveCreator={handleRemoveCreator}
        />

        {/* address */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({
            icon: IconBoldLocation,
            label: 'Address',
          })}
          <Box flex={1}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
              }}
            >
              <StyledInput
                disableUnderline
                defaultValue={creatorProfile.location}
                placeholder="Add address..."
                onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (
                    e.key === 'Enter' &&
                    (e.target as HTMLInputElement).value !==
                      creatorProfile.location
                  ) {
                    await updateCreatorProfile({
                      variables: {
                        input: {
                          id: creatorProfile.id,
                          data: {
                            address: (e.target as HTMLInputElement).value,
                          },
                        },
                      },
                      update: (cache) => {
                        modifyObject(
                          cache,
                          creatorProfile.id,
                          'CreatorProfileModel',
                          {
                            location: () =>
                              (e.target as HTMLInputElement).value,
                          },
                        );
                      },
                    });
                  }
                }}
                onBlur={async (e) => {
                  if (e.target.value !== creatorProfile.location) {
                    await updateCreatorProfile({
                      variables: {
                        input: {
                          id: creatorProfile.id,
                          data: { address: e.target.value },
                        },
                      },
                      update: (cache) => {
                        modifyObject(
                          cache,
                          creatorProfile.id,
                          'CreatorProfileModel',
                          {
                            location: () => e.target.value,
                          },
                        );
                      },
                    });
                  }
                }}
              />
              {creatorProfile.reasonForLocation && (
                <Box
                  sx={{
                    py: 1,
                    px: 2,
                    width: '100%',
                    bgcolor: theme.colors?.utility[275],
                    borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="headline-xxs"
                    color={theme.colors?.utility[700]}
                  >
                    {creatorProfile.reasonForLocation}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/* Birthdate */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({
            icon: IconBoldCake,
            label: 'Birthday',
          })}
          <Box flex={1}>
            <DatePicker
              value={
                creatorProfile.birthdate
                  ? moment(creatorProfile.birthdate)
                  : null
              }
              onChange={async (value) => {
                await updateCreatorProfile({
                  variables: {
                    input: {
                      id: creatorProfile.id,
                      data: { birthdate: value },
                    },
                  },
                  update: (cache) => {
                    modifyObject(
                      cache,
                      creatorProfile.id,
                      'CreatorProfileModel',
                      {
                        birthdate: () => value,
                      },
                    );
                  },
                });
              }}
              renderInput={({ inputRef, openPicker }) => {
                return (
                  <Typography
                    ref={inputRef}
                    role="button"
                    variant="headline-sm"
                    color={
                      creatorProfile.birthdate
                        ? theme.colors?.utility[800]
                        : theme.colors?.utility[600]
                    }
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={openPicker}
                  >
                    {creatorProfile.birthdate
                      ? moment(creatorProfile.birthdate).format('MMM DD, YYYY')
                      : 'Add birthday...'}
                  </Typography>
                );
              }}
            />
          </Box>
        </Box>
        <Divider
          sx={{
            borderWidth: '1.5px',
            borderColor: theme.colors?.utility[275],
          }}
        />
        {/* Relationship */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({
            icon: IconBoldShare,
            label: 'Relationship',
          })}
          <Box flex={1}>
            <Chip
              variant="filled-borderless"
              sx={{
                borderRadius: 2,
                bgcolor:
                  RelationshipBackgroundColorMap[
                    creatorProfile.relationshipStrength
                  ],
                span: {
                  ...typography['headline-sm'],
                  color:
                    RelationshipColorMap[creatorProfile.relationshipStrength],
                },
              }}
              label={RelationshipLabelMap[creatorProfile.relationshipStrength]}
            />
          </Box>
        </Box>
        {/* Labels */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({
            icon: IconBoldTag,
            label: 'Labels',
          })}
          <Box flex={1}>
            <SocialListeningLabelSelector
              selectedLabels={creatorProfile.labels}
              creatorProfileId={creatorProfile.id}
              onLabelAdded={() => {
                analytics.track(EventName.CrmDashboardLabelUpdated, {
                  brandId: creatorProfile.brandId,
                  userId: user?.id,
                  creatorProfileId: creatorProfile.id,
                  type: 'added',
                });
              }}
              onLabelRemoved={() => {
                analytics.track(EventName.CrmDashboardLabelUpdated, {
                  brandId: creatorProfile.brandId,
                  userId: user?.id,
                  creatorProfileId: creatorProfile.id,
                  type: 'removed',
                });
              }}
              brandId={creatorProfile.brandId}
            />
          </Box>
        </Box>
      </Box>
      {creatorProfile.creators.map((creator) => (
        <SocialListeningCreatorProfileHandlerView
          key={creator.id}
          creator={creator}
        />
      ))}
      {creatorForMerge && (
        <SocialListeningCreatorProfileMergeView
          creatorForMerge={creatorForMerge}
          existingCreatorProfiles={existingCreatorProfilesByCreator}
          isOpen={isMergeCreatorProfileModalOpen}
          onClose={onMergeCreatorProfileModalClose}
          onMergeProfiles={async () => {
            await mergeCreatorProfiles({
              variables: {
                input: {
                  creatorIdsToAdd: [creatorForMerge.id],
                  creatorProfileIdsToMerge:
                    existingCreatorProfilesByCreator.map(
                      (creatorProfile) => creatorProfile.id,
                    ),
                  currentCreatorProfileId: creatorProfile.id,
                },
              },
              refetchQueries: ['GetCreatorProfileForSLCreatorProfilePage'],
            });

            analytics.track(EventName.CreatorProfileMergeDecision, {
              brandId: creatorProfile.brandId,
              userId: user?.id,
              currentCreatorProfileId: creatorProfile.id,
              creatorForMergeId: creatorForMerge.id,
              decision: 'merged',
            });

            onMergeCreatorProfileModalClose();
          }}
          onKeepSeparate={async () => {
            await addCreatorSeparately(creatorForMerge);
            onMergeCreatorProfileModalClose();

            analytics.track(EventName.CreatorProfileMergeDecision, {
              brandId: creatorProfile.brandId,
              userId: user?.id,
              currentCreatorProfileId: creatorProfile.id,
              creatorForMergeId: creatorForMerge.id,
              decision: 'keep separate',
            });
          }}
        />
      )}
    </Box>
  );
};
