import { useClipboard } from '@dwarvesf/react-hooks';
import { Box, Divider, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { toast } from 'components/common/Toast';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconCustomLightning } from 'components/icons/components/custom/IconCustomLightning';
import { IconCustomMobile } from 'components/icons/components/custom/IconCustomMobile';
import { IconLinearMusic } from 'components/icons/components/linear/IconLinearMusic';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineHome } from 'components/icons/components/outline/IconOutlineHome';
import { IconOutlineMessage3 } from 'components/icons/components/outline/IconOutlineMessage3';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { SUPPORT_EMAIL } from 'constants/support';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { useAccessRequestHandlers } from 'features/accessRequest';
import { BillingTrendsPaywallIndicator } from 'features/billing';
import { InternalOrganizationRole, PlotFeature } from 'graphql/generated';
import { useIntercomWidget } from 'hooks/useIntercomWidget';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  DownloadMobileAppCTA,
  LogoutCTA,
  NotificationSidebarLink,
  SearchSidebarLink,
  SocialListeningSidebarLink,
} from '../components';
import { DrawerTab, TabName } from '../types';

export const useDrawerTabs = () => {
  const { onCopy } = useClipboard(SUPPORT_EMAIL);
  const { user, orgBilling } = useUserContext();
  const { isFeatureEnabled } = useFeatureFlagContext();

  const isSocialListeningUser =
    user?.role === InternalOrganizationRole.SocialListeningUser;

  const [isPendingRequest, setIsPendingRequest] = useState(false);
  const { onCheckIsAccessRequestExist, onCreateOrganizationAccessRequest } =
    useAccessRequestHandlers();

  useEffect(() => {
    if (isSocialListeningUser) {
      onCheckIsAccessRequestExist().then((result) => {
        if (result) {
          setIsPendingRequest(true);
        }
      });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const { isIntercomWidgetAvailable, openIntercomWidget } = useIntercomWidget();

  const topTabs: DrawerTab[] = [
    {
      name: TabName.GLOBAL_SEARCH,
      type: 'component',
      renderComponent: () => {
        return <SearchSidebarLink />;
      },
    },
    {
      name: TabName.NOTIFICATIONS,
      type: 'component',
      renderComponent: () => {
        return <NotificationSidebarLink />;
      },
    },
    // this is the divider
    {
      name: TabName.CUSTOM_UI,
      type: 'component',
      renderComponent: () => {
        return (
          <Divider
            sx={{ borderColor: theme.colors?.utility[300], my: 3, mx: 2 }}
          />
        );
      },
    },
  ];

  const productTabs: DrawerTab[] = useMemo(
    () => [
      {
        name: TabName.HOME,
        type: 'link',
        icon: IconOutlineHome,
        isActive: location.pathname === PlotRoutes.home(),
        href: PlotRoutes.home(),
      },
      {
        name: TabName.CREATIVE_JUICEBOX,
        type: 'link',
        icon: IconCustomCreativeJuiceBox,
        // do not set active if the pathname includes trending
        isActive: location.pathname.includes('trending')
          ? false
          : location.pathname.includes(PlotRoutes.juicebox()) ||
            location.pathname.includes(PlotRoutes.collection('')),
        href: PlotRoutes.juicebox(),
      },
      ...(isFeatureEnabled(PlotFeature.ContentCalendar)
        ? [
            {
              name: TabName.CONTENT_CALENDAR,
              type: 'link' as 'link',
              icon: IconOutlineCalendar,
              isActive:
                location.pathname.includes(PlotRoutes.contentCalendar()) ||
                location.pathname.includes(PlotRoutes.contentIdea('')),
              href: PlotRoutes.contentCalendar(),
            },
          ]
        : []),
      {
        name: TabName.ALL_TASKS,
        type: 'link',
        icon: IconOutlineTask,
        isActive: location.pathname.includes(PlotRoutes.tasks()),
        href: PlotRoutes.tasks(),
        shortCut: 'T',
        tooltip: "Press 'T' to create a new Task",
      },
      {
        name: TabName.TRENDS,
        type: 'link',
        icon: IconCustomLightning,
        isActive:
          location.pathname === PlotRoutes.juiceboxTrending() ||
          location.pathname.includes(PlotRoutes.trendingJuice('')),
        href: PlotRoutes.juiceboxTrending(),
        paywall:
          !orgBilling || orgBilling.trendsEnabled ? null : (
            <BillingTrendsPaywallIndicator organizationBilling={orgBilling} />
          ),
      },
      {
        name: TabName.SOCIAL_LISTENING,
        type: 'component' as 'component',
        renderComponent: () => {
          return <SocialListeningSidebarLink />;
        },
      },

      ...(isFeatureEnabled(PlotFeature.AudioTrendGroup)
        ? [
            {
              name: TabName.TOP_AUDIOS,
              type: 'link' as 'link',
              icon: IconLinearMusic,
              isActive: location.pathname === PlotRoutes.trendingAudio(),
              href: PlotRoutes.trendingAudio(),
              beta: true,
            },
          ]
        : []),

      {
        name: TabName.CUSTOM_UI,
        type: 'component',
        renderComponent: () => {
          if (!isSocialListeningUser) {
            return null;
          }

          return (
            <>
              <Divider
                sx={{
                  borderColor: theme.colors?.utility[300],
                  my: 3,
                  mx: 2,
                }}
              />
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[300],
                  p: 2,
                  my: 1,
                  borderRadius: 1,
                }}
                color={theme.colors?.utility[700]}
              >
                {isPendingRequest ? (
                  <Typography variant="body-md">
                    <Box component="span" color={theme.colors?.utility[900]}>
                      Request Sent to Admin
                    </Box>{' '}
                    for access to all Plot features.
                  </Typography>
                ) : (
                  <Typography variant="body-md">
                    <Box
                      onClick={() => {
                        onCreateOrganizationAccessRequest({});
                        setIsPendingRequest(true);
                      }}
                      color={theme.colors?.utility[900]}
                      sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      component="span"
                    >
                      Request Admin
                    </Box>{' '}
                    for access to all Plot features.
                  </Typography>
                )}
              </Box>
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [
      isFeatureEnabled,
      location.pathname,
      orgBilling,
      user?.socialListeningBrands.length,
    ],
  );

  const settingTabs: DrawerTab[] = [
    {
      name: TabName.TEXT_IDEAS,
      type: 'link',
      icon: IconCustomMobile,
      href: PlotRoutes.registerIdeas(),
      state: {
        variant: 'setting',
      },
      disabled: isSocialListeningUser,
    },
    {
      name: TabName.DOWNLOAD_THE_MOBILE_APP,
      type: 'component',
      renderComponent: () => {
        return <DownloadMobileAppCTA />;
      },
      disabled: isSocialListeningUser,
    },
    {
      name: TabName.SUPPORT,
      type: 'button',
      icon: IconOutlineMessage3,
      onClick: () => {
        if (isIntercomWidgetAvailable) {
          openIntercomWidget();
        } else {
          onCopy();
          toast({
            type: 'success',
            message: 'Support email has been copied!',
          });

          window.open(`mailto:${SUPPORT_EMAIL}`, '_blank');
        }
      },
    },
    {
      name: TabName.CUSTOM_UI,
      type: 'component',
      renderComponent: () => {
        return <Divider sx={{ borderColor: theme.colors?.utility[300] }} />;
      },
    },
    {
      name: TabName.LOG_OUT,
      type: 'component',
      renderComponent: () => <LogoutCTA />,
    },
  ];

  return {
    topTabs,
    productTabs,
    settingTabs,
  };
};
