import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import { HighlightableSection } from 'components/common/HighlightableSection';
import { typography } from 'components/common/Typography/styles';
import { IconBoldEdit } from 'components/icons/components/bold/IconBoldEdit';
import { PostPreview } from 'features/post';
import { SocialListeningRadarActivityDialogView } from 'features/socialListeningRadar';
import { ExpandableText } from 'features/socialMediaListening';
import {
  PostFragmentPostPreviewFragmentDoc,
  RadarEventFragmentRadarEventTimelineItemInternalActivityFragment,
} from 'graphql/generated';
import moment from 'moment';
import { theme } from 'styles/theme';

export const RADAR_EVENT_FRAGMENT_RADAR_EVENT_TIMELINE_ITEM_INTERNAL_ACTIVITY = gql`
  fragment RadarEventFragmentRadarEventTimelineItemInternalActivity on RadarEventModel {
    id
    brandId
    radarEventActivities {
      id
      title
      summary
      direction
      createdAt
      dateTime
      assets {
        id
        ...PostFragmentPostPreview
      }
      channels {
        id
        name
        color
        bgColor
      }
      createdForCreatorProfiles {
        id
      }
      radarEvents {
        id
      }
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

export type RadarEventTimelineItemInternalActivityProps = {
  radarEvent: RadarEventFragmentRadarEventTimelineItemInternalActivityFragment;
};

export const RadarEventTimelineItemInternalActivity = (
  props: RadarEventTimelineItemInternalActivityProps,
) => {
  const { radarEvent } = props;

  const radarEventActivity = radarEvent.radarEventActivities[0];
  const {
    isOpen: isRadarActivityDialogOpen,
    onOpen: openRadarActivityDialog,
    onClose: closeRadarActivityDialog,
  } = useDisclosure();

  return (
    <>
      <HighlightableSection
        sx={{
          borderRadius: 3,
          p: 6,
          bgcolor: theme.colors?.utility[250],
          display: 'flex',
          gap: 8,
          position: 'relative',
          '--highlighted-fade-final-bgcolor': theme.colors?.utility[250],
          scrollMarginTop: 200,
        }}
        sectionKey={`rea-${radarEventActivity.id}`}
      >
        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            mt: -8,
          }}
          onClick={openRadarActivityDialog}
        >
          <IconBoldEdit size={16} color={theme.colors?.utility[700]} />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            flex: 1,
          }}
        >
          <Typography variant="headline-sm">
            {radarEventActivity.title}
          </Typography>
          <ExpandableText
            collapsedHeight={64}
            text={radarEventActivity.summary}
            componentProps={{
              text: {
                sx: {
                  ...typography['subhead-xl'],
                },
              },
              seeMore: {
                label: 'See more',
              },
              seeLess: {
                label: 'Hide',
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              mt: 'auto',
              gap: 1,
            }}
          >
            <Typography variant="subhead-lg">
              {radarEventActivity.direction}
              {' · '}
              {moment(radarEventActivity.createdAt).format(
                'MMM D, YYYY | h:mmA',
              )}
              {' · '}
            </Typography>
            {radarEventActivity.channels.map((channel) => {
              return (
                <Chip
                  sx={{
                    borderRadius: 1,
                    color: channel.color,
                    backgroundColor: channel.bgColor,
                    height: 26,
                    '.MuiChip-label': {
                      filter: 'none !important',
                      color: channel.color,
                      ...theme.typography['headline-xs'],
                    },
                    '& svg': {
                      pl: 0.5,
                    },
                  }}
                  label={channel.name}
                  variant="filled-borderless-color-dodge"
                />
              );
            })}
          </Box>
        </Box>
        {!!radarEventActivity.assets.length && (
          <Box>
            <Box
              sx={{
                width: 100,
                aspectRatio: '7 / 10',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                }}
              >
                {radarEventActivity.assets.slice(0, 2).map((asset, index) => {
                  return (
                    <PostPreview
                      key={asset.id}
                      post={asset}
                      sx={{
                        position: 'absolute',
                        borderRadius: 4,
                        transformOrigin: 'bottom center',
                        transform: `rotate(${
                          -1 *
                          (radarEventActivity.assets.length - 1 - index) *
                          10
                        }deg)`,
                      }}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}
      </HighlightableSection>
      {isRadarActivityDialogOpen && (
        <SocialListeningRadarActivityDialogView
          onSave={closeRadarActivityDialog}
          onClose={closeRadarActivityDialog}
          brandId={radarEvent.brandId}
          variant="edit"
          radarEventActivityId={radarEventActivity.id}
          defaultValues={{
            assetIds: radarEventActivity.assets.map((asset) => asset.id),
            createdForCreatorProfileIds:
              radarEventActivity.createdForCreatorProfiles.map(
                (profile) => profile.id,
              ),
            direction: radarEventActivity.direction,
            summary: radarEventActivity.summary,
            title: radarEventActivity.title,
            dateTime: radarEventActivity.dateTime,
            radarEventIds: radarEventActivity.radarEvents.map(
              (radarEvent) => radarEvent.id,
            ),
            radarEventActivityCommunicationChannelIds:
              radarEventActivity.channels.map((channel) => channel.id),
          }}
        />
      )}
    </>
  );
};
