import {
  FILTERABLE_ITEM_TYPES,
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuItemType,
} from '../components';

/**
 * Checks if a filter item has a value selected based on its type
 * @param itemType The type of the filter item
 * @param value The value to check
 * @returns boolean indicating if the filter has a value
 */
export const hasFilterValue = (
  itemType: string,
  value: NestedFiltersMenuItemBaseValueType<unknown> | undefined,
): boolean => {
  if (!value) return false;

  // Different types have different value structures
  switch (itemType) {
    case 'single-select':
      return !!value.value;
    case 'multi-select':
      return Array.isArray(value.value) && value.value.length > 0;
    case 'form':
      return !!value.value && Object.keys(value.value as object).length > 0;
    case 'date-range':
      return !!value.value;
    default:
      return false;
  }
};

/**
 * Recursively collect all filterable items from the nested structure
 */

// Helper type for tracking the path to a filter item
type FilterPath = {
  item: NestedFiltersMenuItemType;
  parentKeys: string[];
};

export const collectFilterableItems = (
  menuItems: NestedFiltersMenuItemType[],
  parentKeys: string[] = [],
) => {
  const filterableItems: FilterPath[] = [];

  menuItems.forEach((item) => {
    if (item.key) {
      // Check if this is a filterable item type
      if (FILTERABLE_ITEM_TYPES.includes(item.type)) {
        filterableItems.push({
          item,
          parentKeys: [...parentKeys],
        });
      }

      // If it's a container type, recursively process its children
      if (item.type === 'group' || item.type === 'submenu') {
        filterableItems.push(
          ...collectFilterableItems(item.items, [...parentKeys, item.key]),
        );
      }
    }
  });

  return filterableItems;
};

/**
 * Get the value for a specific item based on its path in the nested structure
 */
export const getValueForItem = (
  item: NestedFiltersMenuItemType,
  parentKeys: string[],
  values: Record<string, any>,
): any => {
  if (!item.key) return null;

  let currentValues = JSON.parse(JSON.stringify(values));

  // Navigate through parent keys to find the correct nested value
  for (const key of parentKeys) {
    if (currentValues[key] && typeof currentValues[key] === 'object') {
      currentValues = currentValues[key] as Record<string, any>;
    } else {
      return null;
    }
  }

  return currentValues[item.key];
};

/**
 * Count the number of selected filters
 * based on the values & items provided
 */
export const countSelectedFilters = ({
  items,
  values,
}: {
  items: NestedFiltersMenuItemType[];
  values: Record<
    string,
    | NestedFiltersMenuItemBaseValueType<unknown>
    | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
  >;
}) => {
  let count = 0;

  const filterableItems = collectFilterableItems(items);

  filterableItems.forEach((item) => {
    const value = getValueForItem(item.item, item.parentKeys, values);

    if (hasFilterValue(item.item.type, value)) {
      count++;
    }
  });

  return count;
};
