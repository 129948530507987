import { gql } from '@apollo/client';
import { Box, TableCell, TableRow } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconLinearExport4 } from 'components/icons/components/linear/IconLinearExport4';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening/hooks';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  SocialPostCommentCreatedTime,
  SocialPostCommentLikes,
  SocialPostCommentReplies,
  SocialPostCommentResponderButton,
  SocialPostCommentText,
  SocialPostCommentTextProps,
} from 'features/socialPostComment';
import { SocialPostCommentFragmentSocialPostCommentsListViewTableRowFragment } from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostCommentFragmentSocialPostCommentsListViewTableRow on SocialPostCommentModel {
    id
    socialPost {
      ...SocialPostFragmentSocialPostThumbnail
    }
    ...SocialPostCommentFragmentSocialPostCommentCreatedTime
    ...SocialPostCommentFragmentSocialPostCommentText
    ...SocialPostCommentFragmentSocialPostCommentResponderPill
    ...SocialPostCommentFragmentSocialPostCommentLikes
    ...SocialPostCommentFragmentSocialPostCommentReplies
    ...SocialPostCommentFragmentSocialPostCommentResponderButton
  }
`;

export const SocialPostCommentsListViewTableRow = ({
  socialPostComment,
  componentsProps = {},
  brandId,
}: {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentsListViewTableRowFragment;
  brandId: string;
  componentsProps?: {
    text?: Pick<SocialPostCommentTextProps, 'sx'>;
  };
}) => {
  const { generateLink } = useSocialListeningPostLinkGenerator();

  return (
    <TableRow
      sx={{
        borderBottom: 'none',
        transition: 'background-color 0.2s ease',
        '&:hover': {
          backgroundColor: theme.colors?.utility[250],
          cursor: 'pointer',
        },
      }}
    >
      <TableCell sx={{ borderBottom: 'none' }}>
        <SocialPostCommentCreatedTime
          socialPostComment={socialPostComment}
          componentProps={{
            textSx: {
              ...typography['headline-sm'],
              color: theme.colors?.primary.black,
            },
          }}
        />
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>
        <SocialPostThumbnail
          socialPost={socialPostComment?.socialPost}
          sx={{
            width: theme.spacing(12),
            height: theme.spacing(12),
            borderRadius: theme.spacing(2),
          }}
          componentProps={{
            hidePlatform: true,
            hideCreatedTime: true,
            platformCreateTime: {
              ...typography['headline-sm'],
              color: theme.colors?.primary.black,
            },
          }}
        />
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>
        <SocialPostCommentText
          socialPostComment={socialPostComment}
          {...componentsProps.text}
          sx={{
            ...typography['headline-sm'],
            color: theme.colors?.primary.black,
          }}
        />
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>
        <SocialPostCommentResponderButton
          socialPostComment={socialPostComment}
          brandId={brandId}
          showEndIcon
        />
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>
        <SocialPostCommentLikes
          socialPostComment={socialPostComment}
          showUnitLabel={false}
          componentProps={{
            textSx: {
              ...typography['headline-sm'],
              color: theme.colors?.primary.black,
            },
          }}
        />
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>
        <SocialPostCommentReplies
          socialPostComment={socialPostComment}
          showUnitLabel={false}
          componentProps={{
            textSx: {
              ...typography['headline-sm'],
              color: theme.colors?.primary.black,
            },
          }}
        />
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>
        <Box
          component="a"
          href={generateLink(socialPostComment.socialPost)}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <IconLinearExport4 size={12} color={theme.colors?.utility[700]} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
