import { gql } from '@apollo/client';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { HIGHLIGHTABLE_SECTION_PARAM_KEY } from 'components/common/HighlightableSection';
import { Timestamp } from 'components/common/Timestamp';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldProfileCircle } from 'components/icons/components/bold/IconBoldProfileCircle';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { useUserContext } from 'contexts/users/User.context';
import { platformIconMap } from 'features/contentIdea';
import { CreatorAvatar } from 'features/creator';
import { getMessageByEventType } from 'features/radarEvent/utils';
import {
  CommunityLabelColorMap,
  RelationshipBackgroundColorMap,
  RelationshipColorMap,
  RelationshipLabelMap,
} from 'features/socialListeningCommunity';
import { SocialListeningRadarAddActivityButtonView } from 'features/socialListeningRadar';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening';
import {
  CreatorFragmentCreatorAvatarFragmentDoc,
  RadarEventFragmentRadarEventCommunityUpdateItemViewFragment,
  RadarEventReadStatus,
  RadarEventType,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
  useGetRadarEventForRadarEventCommunityUpdateItemViewQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { StyledActivityButton } from './styles';

export const RADAR_EVENT_FRAGMENT_RADAR_EVENT_COMMUNITY_UPDATE_ITEM_VIEW = gql`
  fragment RadarEventFragmentRadarEventCommunityUpdateItemView on RadarEventModel {
    id
    brandId
    createdAt
    message
    eventType
    eventTypeCounter
    readStatus
    radarEventActivities {
      id
      title
    }
    creatorProfile {
      id
      fullName
      relationshipStrength
      creators {
        id
        handle
        ...CreatorFragmentCreatorAvatar
      }
      labels {
        id
        label
      }
    }
    socialPost {
      id
      ...SocialPostFragmentUseSocialListeningPostLinkGenerator
    }
    socialPostComment {
      id
    }
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetRadarEventForRadarEventCommunityUpdateItemView($id: String!) {
    radarEvent(id: $id) {
      id
      radarEventActivities {
        id
        title
      }
    }
  }
`;

export type RadarEventCommunityUpdateItemViewProps = {
  radarEvent: RadarEventFragmentRadarEventCommunityUpdateItemViewFragment;
};

export const RadarEventCommunityUpdateItemView = (
  props: RadarEventCommunityUpdateItemViewProps,
) => {
  const { radarEvent } = props;

  const navigate = useNavigate();
  const { user } = useUserContext();
  const analytics = useAnalytics();

  const { generateLink } = useSocialListeningPostLinkGenerator();

  // Use this fetchner to re-fetch the radar event activities for a radar event
  const { refetch } = useGetRadarEventForRadarEventCommunityUpdateItemViewQuery(
    {
      variables: {
        id: radarEvent.id,
      },
      fetchPolicy: 'cache-only',
      nextFetchPolicy: 'cache-and-network',
    },
  );

  // Upon clicking the radar event, navigate to the appropriate page
  // based on event type & available data
  const onClick = () => {
    analytics.track(EventName.CommunityUpdatesCreatorClicked, {
      brandId: radarEvent.brandId,
      userId: user?.id,
      creatorProfileId: radarEvent.creatorProfile.id,
    });

    if (
      [RadarEventType.Ugc, RadarEventType.Comment].includes(
        radarEvent.eventType,
      )
    ) {
      navigate(
        `${PlotRoutes.socialListeningCreatorProfile(
          radarEvent.brandId,
          radarEvent.creatorProfile.id,
        )}?${HIGHLIGHTABLE_SECTION_PARAM_KEY}=brand-mention`,
      );
    } else if ([RadarEventType.Engagement].includes(radarEvent.eventType)) {
      navigate(
        `${PlotRoutes.socialListeningCreatorProfile(
          radarEvent.brandId,
          radarEvent.creatorProfile.id,
        )}?${HIGHLIGHTABLE_SECTION_PARAM_KEY}=superfan-engagement`,
      );
    } else if (radarEvent.socialPost) {
      navigate(
        PlotRoutes.socialListeningPost(
          radarEvent.socialPost.id,
          radarEvent.brandId,
          {
            tab: radarEvent.socialPostComment ? 'comments' : undefined,
          },
        ),
      );
    }
  };

  const hasActivity = radarEvent.radarEventActivities.length > 0;
  const isUnread = radarEvent.readStatus === RadarEventReadStatus.Unread;
  const firstCreator = radarEvent.creatorProfile.creators[0];
  const CreatorPlatformIcon = platformIconMap[firstCreator.platform];
  const SocialPostPlatformIcon = radarEvent.socialPost
    ? platformIconMap[radarEvent.socialPost?.platform]
    : () => null;
  const activityCountToDisplay = 2;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3, 4),
        borderRadius: 4,
        background: !isUnread ? 'transparent' : 'rgba(35, 6, 3, 0.05)',
        '.social-listening-radar-events-view-action': {
          display: 'none',
        },
        ':hover': {
          background: 'rgba(35, 6, 3, 0.10)',
          '& .social-listening-radar-events-view-action': {
            display: 'block',
          },
        },
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: 'fit-content',
          }}
        >
          <CreatorAvatar size={56} creator={firstCreator} hidePlatformIcon />
          {/* <IconButton
            sx={{
              borderRadius: '100%',
              bgcolor: theme.colors?.utility[250],
              p: 1,
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translate(30%, 20%)',
            }}
          >
            <IconCustomStar
              size={16}
              color={theme.colors?.utility['yellow-3']}
            />
          </IconButton> */}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Typography variant="headline-md" fontWeight={500}>
              <Typography variant="headline-md">
                {radarEvent.creatorProfile.fullName}
              </Typography>{' '}
              {getMessageByEventType(radarEvent.eventType) ||
                radarEvent.message}{' '}
              {[
                RadarEventType.Ugc,
                RadarEventType.Comment,
                RadarEventType.Engagement,
              ].includes(radarEvent.eventType) && (
                <Typography variant="headline-md">
                  {radarEvent.eventTypeCounter} time
                  {radarEvent.eventTypeCounter > 1 ? 's' : ''}
                </Typography>
              )}
            </Typography>
            <Timestamp
              date={radarEvent.createdAt}
              sx={{
                ...typography['subhead-lg'],
                color: theme.colors?.utility[700],
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 1.5,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 2,
                background: 'rgba(35, 6, 3, 0.05)',
                padding: theme.spacing(1, 1.5),
              }}
            >
              <CreatorPlatformIcon
                size={16}
                color={theme.colors?.utility[700]}
              />
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[700]}
              >
                {firstCreator.handle}
              </Typography>
              {radarEvent.creatorProfile.creators.length > 1 ? (
                <Tooltip
                  title={
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      {radarEvent.creatorProfile.creators.map((creator) => (
                        <Typography variant="subhead-sm">
                          {creator.handle}
                        </Typography>
                      ))}
                    </Box>
                  }
                >
                  <Box component="span">
                    +{radarEvent.creatorProfile.creators.length - 1}
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
            <Box
              sx={{
                padding: theme.spacing(1, 2),
                borderRadius: 2,
                background:
                  RelationshipBackgroundColorMap[
                    radarEvent.creatorProfile.relationshipStrength
                  ],
                color:
                  RelationshipColorMap[
                    radarEvent.creatorProfile.relationshipStrength
                  ],
                ...theme.typography['headline-sm'],
              }}
            >
              {
                RelationshipLabelMap[
                  radarEvent.creatorProfile.relationshipStrength
                ]
              }
            </Box>
            {radarEvent.creatorProfile.labels.map((label) => {
              const randomCommunityLabelColor =
                CommunityLabelColorMap[
                  Math.floor(Math.random() * CommunityLabelColorMap.length)
                ];

              return (
                <Box
                  key={label.id}
                  sx={{
                    padding: theme.spacing(1, 2),
                    borderRadius: 2,
                    background: randomCommunityLabelColor.backgroundColor,
                    color: randomCommunityLabelColor.color,
                    ...theme.typography['headline-sm'],
                  }}
                >
                  {label.label}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 3,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {radarEvent.socialPost ? (
          <Box
            component="a"
            href={generateLink(radarEvent.socialPost)}
            onClick={() => {
              analytics.track(EventName.CommunityUpdatesPostClicked, {
                brandId: radarEvent.brandId,
                userId: user?.id,
                postId: radarEvent.socialPost?.id,
              });
            }}
            target="_blank"
            className="social-listening-radar-events-view-action"
          >
            <IconButton sx={{ p: 1 }} disableRipple>
              <SocialPostPlatformIcon />
            </IconButton>
          </Box>
        ) : null}
        <Link
          to={PlotRoutes.socialListeningCreatorProfile(
            radarEvent.brandId,
            radarEvent.creatorProfile.id,
          )}
          className="social-listening-radar-events-view-action"
        >
          <IconButton sx={{ p: 0 }} disableRipple>
            <IconBoldProfileCircle color={theme.colors?.utility[700]} />
          </IconButton>
        </Link>
        {hasActivity ? (
          <>
            {radarEvent.radarEventActivities
              .slice(0, activityCountToDisplay)
              .map((activity) => (
                <StyledActivityButton key={activity.id} disableRipple>
                  {activity.title}
                </StyledActivityButton>
              ))}
            {radarEvent.radarEventActivities.length > activityCountToDisplay ? (
              <Tooltip
                title={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                    }}
                  >
                    {radarEvent.radarEventActivities
                      .slice(activityCountToDisplay)
                      .map((activity) => (
                        <Typography key={activity.id} variant="subhead-sm">
                          {activity.title}
                        </Typography>
                      ))}
                  </Box>
                }
              >
                <StyledActivityButton disableRipple>
                  +
                  {radarEvent.radarEventActivities.length -
                    activityCountToDisplay}
                </StyledActivityButton>
              </Tooltip>
            ) : null}
          </>
        ) : null}
        <Box className="social-listening-radar-events-view-action">
          <SocialListeningRadarAddActivityButtonView
            brandId={radarEvent.brandId}
            radarEventId={radarEvent.id}
            creatorProfileIds={[radarEvent.creatorProfile.id]}
            onActivityAdded={(activityId, isReused) => {
              if (isReused) {
                analytics.track(EventName.CommunityUpdatesActivityReused, {
                  brandId: radarEvent.brandId,
                  userId: user?.id,
                  creatorProfileId: radarEvent.creatorProfile.id,
                  activityId,
                });
              } else {
                analytics.track(EventName.CommunityUpdatesActivityAdded, {
                  brandId: radarEvent.brandId,
                  userId: user?.id,
                  creatorProfileId: radarEvent.creatorProfile.id,
                  activityId,
                });
              }
              setTimeout(() => {
                refetch();
              }, 1000);
            }}
            {...(!hasActivity && {
              renderBtn: (
                <Button
                  variant="pill-reverse"
                  startIcon={<IconLinearAdd size={16} />}
                  sx={{
                    bgcolor: 'rgba(35, 6, 3, 0.1)',
                    border: 'none',
                    '&:hover': {
                      bgcolor: 'rgba(35, 6, 3, 0.1)',
                    },
                  }}
                  size="small"
                >
                  <Typography variant="headline-sm">Add Activity</Typography>
                </Button>
              ),
            })}
          />
        </Box>
      </Box>
    </Box>
  );
};
