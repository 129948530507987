import { gql } from '@apollo/client';
import { Avatar, Box, Button, Dialog, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { IconLinearExport4 } from 'components/icons/components/linear/IconLinearExport4';
import {
  CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc,
  Platform,
  SocialListeningCreatorProfileMergeViewFragmentCreatorFragment,
  SocialListeningCreatorProfileMergeViewFragmentCreatorProfileFragment,
  useGetBrandMentionStatsForSlCreatorProfileMergeViewQuery,
  useGetSuperfanEngagementStatsForSlCreatorProfileMergeViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SocialListeningCreatorProfileHoverView } from './SocialListeningCreatorProfileHoverView';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialListeningCreatorProfileMergeViewFragmentCreator on CreatorModel {
    id
    platform
    handle
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialListeningCreatorProfileMergeViewFragmentCreatorProfile on CreatorProfileModel {
    id
    ...CreatorProfileFragmentSLCreatorProfileDetailsView
  }
  ${CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetBrandMentionStatsForSLCreatorProfileMergeView(
    $input: CreatorProfileBrandMentionedStatsInput!
  ) {
    creatorProfileBrandMentionStats(input: $input) {
      brandMentionCount
      totalComments
      sentimentScore
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetSuperfanEngagementStatsForSLCreatorProfileMergeView(
    $input: CreatorProfileSuperfanEngagementStatsInput!
  ) {
    getCreatorProfileSuperfanEngagementStats(input: $input) {
      sentimentScore
    }
  }
`;

type SocialListeningCreatorProfileMergeViewProps = {
  creatorForMerge: SocialListeningCreatorProfileMergeViewFragmentCreatorFragment;
  existingCreatorProfiles: SocialListeningCreatorProfileMergeViewFragmentCreatorProfileFragment[];
  isOpen: boolean;
  onClose: () => void;
  onMergeProfiles: () => void;
  onKeepSeparate: () => void;
};

export const SocialListeningCreatorProfileMergeView = ({
  creatorForMerge,
  existingCreatorProfiles,
  isOpen,
  onClose,
  onMergeProfiles,
  onKeepSeparate,
}: SocialListeningCreatorProfileMergeViewProps) => {
  const platformConfig = useMemo(() => {
    const config = {
      [Platform.Instagram]: {
        icon: IconBoldInstagram,
      },
      [Platform.Tiktok]: {
        icon: IconCustomTiktok,
      },
      [Platform.Youtube]: {
        icon: IconCustomYoutubeShorts,
      },
    };
    return config[creatorForMerge.platform];
  }, [creatorForMerge.platform]);

  const renderLabel = useCallback(({ icon, label }) => {
    const Icon = icon;
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          width: 'fit-content',
          flexShrink: 0,
          backgroundColor: theme.colors?.utility[275],
          p: theme.spacing(1, 2),
          borderRadius: theme.spacing(6),
        }}
      >
        <Icon size={20} color={theme.colors?.utility[800]} />
        <Typography variant="headline-md" color={theme.colors?.utility[800]}>
          {label}
        </Typography>
      </Box>
    );
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          p: 6,
          backgroundColor: theme.colors?.primary.white,
          minWidth: theme.spacing(120),
          maxWidth: theme.spacing(120),
          maxHeight: '80vh',
          backdropFilter: 'blur(20px)',
          borderRadius: theme.spacing(6),
          boxShadow: 'none',
        },
      }}
    >
      <Box display="flex" gap={6} flexDirection="column">
        <Box>
          <Typography
            variant="headline-xl"
            fontSize={24}
            fontWeight={500}
            letterSpacing="-0.96px"
          >
            Merge profiles
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box>
            <Typography
              variant="headline-md"
              fontWeight={500}
              color={theme.colors?.utility[800]}
            >
              We found another profile with the handle:
            </Typography>
          </Box>
          <Box>
            {renderLabel({
              icon: platformConfig.icon,
              label: creatorForMerge.handle,
            })}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={2}>
          {existingCreatorProfiles.map((creatorProfile) => {
            return <CreatorProfileCardView creatorProfile={creatorProfile} />;
          })}
        </Box>

        <Typography
          variant="headline-md"
          fontWeight={500}
          color={theme.colors?.utility[800]}
        >
          Would you like to merge these profiles to prevent duplicates?
        </Typography>

        <Box display="flex" flexDirection="column" gap={2}>
          <Button
            sx={{
              '&, &:hover': {
                width: '100%',
                p: theme.spacing(2, 4),
                color: theme.colors?.primary.white,
                backgroundColor: theme.colors?.primary.black,
                borderRadius: 2,
                border: `1px solid ${theme.colors?.primary.black}`,
              },
            }}
            variant="primary"
            onClick={onMergeProfiles}
          >
            Merge profiles
          </Button>
          <Button variant="text" color="primary" onClick={onKeepSeparate}>
            Keep separate
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

interface CreatorProfileCardViewProps {
  creatorProfile: SocialListeningCreatorProfileMergeViewFragmentCreatorProfileFragment;
}
const CreatorProfileCardView = ({
  creatorProfile,
}: CreatorProfileCardViewProps) => {
  const defaultDateRange = useMemo(() => {
    return {
      startDate: moment().subtract(7, 'days').toDate(),
      endDate: moment().toDate(),
    };
  }, []);

  const { data: brandMentionStatsData } =
    useGetBrandMentionStatsForSlCreatorProfileMergeViewQuery({
      variables: {
        input: {
          creatorProfileId: creatorProfile.id,
          brandId: creatorProfile.brandId,
          currentBrandId: creatorProfile.brandId,
          dateRange: defaultDateRange,
        },
      },
    });

  const { data: superFanEngagementStatsData } =
    useGetSuperfanEngagementStatsForSlCreatorProfileMergeViewQuery({
      variables: {
        input: {
          creatorProfileId: creatorProfile.id,
          brandId: creatorProfile.brandId,
          dateRange: defaultDateRange,
        },
      },
    });

  const brandMentionStats =
    brandMentionStatsData?.creatorProfileBrandMentionStats;
  const superFanEngagementStats =
    superFanEngagementStatsData?.getCreatorProfileSuperfanEngagementStats;

  const { brandMentionCount, sentimentScore, totalComments } = useMemo(() => {
    const brandMentionSentimentScore = brandMentionStats?.sentimentScore ?? 0;
    const superFanEngagementSentimentScore =
      superFanEngagementStats?.sentimentScore ?? 0;
    const sentimentScore =
      (brandMentionSentimentScore + superFanEngagementSentimentScore) / 2;

    const totalComments = brandMentionStats?.totalComments ?? 0;
    const brandMentionCount = brandMentionStats?.brandMentionCount ?? 0;

    return {
      sentimentScore,
      totalComments,
      brandMentionCount,
    };
  }, [
    brandMentionStats?.brandMentionCount,
    brandMentionStats?.sentimentScore,
    brandMentionStats?.totalComments,
    superFanEngagementStats?.sentimentScore,
  ]);

  return (
    <Tooltip
      title={
        <SocialListeningCreatorProfileHoverView
          creatorProfile={creatorProfile}
          totalComments={totalComments}
          brandMentionCount={brandMentionCount}
          sentimentScore={sentimentScore}
        />
      }
      placement="bottom-end"
      componentsProps={{
        tooltip: {
          sx: {
            p: theme.spacing(6),
            borderRadius: theme.spacing(6),
            background: theme.colors?.utility[200],
            boxShadow:
              '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
            backdropFilter: 'blur(37px)',
            color: theme.colors?.primary.black,
            minWidth: 510,
            maxHeight: 400,
            overflowY: 'auto',
          },
        },
      }}
    >
      <Box
        sx={{
          bgcolor: theme.colors?.utility[275],
          p: theme.spacing(4),
          borderRadius: theme.spacing(4),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          ':hover': {
            '& .creator-profile-merge-view-link': {
              opacity: 1,
            },
          },
        }}
        key={creatorProfile.id}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(3),
          }}
        >
          <Avatar
            src={creatorProfile.creators[0]?.profilePictureUrl ?? undefined}
            sx={{
              width: theme.spacing(12),
              height: theme.spacing(12),
            }}
          />

          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="headline-md">
              {creatorProfile.fullName}
            </Typography>

            <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
              {brandMentionStats?.brandMentionCount} brand mention
              {(brandMentionStats?.brandMentionCount || 0) > 1 && 's'} •{' '}
              {brandMentionStats?.totalComments} comment
              {(brandMentionStats?.totalComments || 0) > 1 && 's'}
            </Typography>
          </Box>
        </Box>
        <Box
          className="creator-profile-merge-view-link"
          sx={{
            opacity: 0,
          }}
        >
          <Link
            to={PlotRoutes.socialListeningCreatorProfile(
              creatorProfile.brandId,
              creatorProfile.id,
            )}
            target="_blank"
          >
            <IconLinearExport4 size={16} />
          </Link>
        </Box>
      </Box>
    </Tooltip>
  );
};
