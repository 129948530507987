import { IconButton, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledActivityButton = styled(IconButton)({
  background: 'rgba(248, 232, 202, 0.50)',
  color: theme.colors?.utility['yellow-4'],
  borderRadius: theme.spacing(2),
  padding: theme.spacing(1, 2),
  ...theme.typography['headline-sm'],
});

export const StyledActivityTooltipButton = styled(IconButton)({
  ...theme.typography['subhead-sm'],
  color: theme.colors?.primary.parchment,
  p: 0,
});
