/**
 * NOTE: This filter allows nested multi-select options.
 * option
 *  -> optionGroups
 *    -> options
 *      -> optionGroups
 *        ...
 */

import { Box, Input, Typography } from '@mui/material';
import { InfiniteScrollBoundary } from 'components/common/InfiniteScrollBoundary';
import {
  useAsyncOptions,
  UseAsyncOptionsProps,
} from 'features/nestedFilters/hooks';
import { debounce } from 'lodash';
import { ReactNode, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  NestedFiltersMenuItemBaseType,
  NestedFiltersMenuItemBaseValueType,
} from '../menuItem';
import { NestedFiltersMultiSelectOption } from './NestedFiltersMultiSelectOption';

export type NestedFiltersOptionType = {
  label: string;
  value: string;
  description?: string;
  optionGroups?: NestedFiltersOptionGroupType[];

  // Custom render for the option label
  renderLabel?: () => ReactNode;
};

export type NestedFiltersOptionGroupType = {
  key: string;
  label?: string;
  options: NestedFiltersOptionType[];
};

export type NestedFiltersMultiSelectType = NestedFiltersMenuItemBaseType &
  UseAsyncOptionsProps & {
    type: 'multi-select';
    label?: string;
    options: NestedFiltersOptionType[];
    filterable?: boolean;
    disableDefaultFiltering?: boolean;
  };

export type NestedFiltersMultiSelectProps = {
  multiSelect: NestedFiltersMultiSelectType;
  value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
  onChange: (
    value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>,
  ) => void;
};

export const NestedFiltersMultiSelect = (
  props: NestedFiltersMultiSelectProps,
) => {
  const { multiSelect, value, onChange } = props;

  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(() => debounce(setQuery, 300), []);

  const {
    options: asyncOptions,
    loading,
    loadingMore,
    hasMore,
    getNextPage,
  } = useAsyncOptions({
    query,
    getAsyncOptions: multiSelect.getAsyncOptions,
  });

  const options = useMemo(() => {
    if (multiSelect.options && multiSelect.options.length > 0) {
      return [
        ...(value.value ?? []),
        ...multiSelect.options.filter(
          (option) => !value.value?.find((v) => v.value === option.value),
        ),
      ];
    }

    if (asyncOptions && asyncOptions.length > 0) {
      return [
        ...(value.value ?? []),
        ...asyncOptions.filter(
          (option) => !value.value?.find((v) => v.value === option.value),
        ),
      ];
    }

    return value.value ?? [];
  }, [multiSelect.options, asyncOptions, value]);

  const isEmpty = options.length === 0 && !loading && !loadingMore;

  return (
    <Box>
      {multiSelect.label && (
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          {multiSelect.label}
        </Typography>
      )}
      {multiSelect.filterable && (
        <Input
          disableUnderline
          autoFocus
          placeholder="Search"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            debouncedSetQuery(e.currentTarget.value);
          }}
          fullWidth
          sx={{
            my: 2,
            p: theme.spacing(0.5, 3),
            ...theme.typography['subhead-lg'],
            backgroundColor: theme.colors?.utility[275],
            borderRadius: 25,
          }}
        />
      )}
      {loading && (
        <Typography
          variant="headline-xs"
          component="div"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          Loading...
        </Typography>
      )}
      {isEmpty && (
        <Typography
          variant="headline-xs"
          component="div"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          No result
        </Typography>
      )}
      {(multiSelect.disableDefaultFiltering
        ? options
        : filterBy(query, options)
      ).map((option, index) => {
        return (
          <NestedFiltersMultiSelectOption
            key={index}
            option={option}
            value={value}
            onChange={onChange}
          />
        );
      })}
      {hasMore && (
        <InfiniteScrollBoundary
          disabled={loadingMore || !hasMore}
          onVisible={getNextPage}
        />
      )}
      {loadingMore && (
        <Typography
          variant="headline-xs"
          component="div"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          Loading more...
        </Typography>
      )}
    </Box>
  );
};

/**
 * Filter options by query, can be overridden by the user.
 * By default, it loops through all levels of options recursively bottom-up and
 * checks if the query matches the label, description, or value.
 *
 * For example:
 * option1
 *   group1
 *     groupOption1
 *     groupOption2
 *   group2
 *     groupOption3
 * option2
 *
 * Query: 1
 *
 * Expected result:
 * option1
 *   group1
 *     groupOption1
 */
const filterBy = (
  query: string,
  options: NestedFiltersOptionType[],
): NestedFiltersOptionType[] => {
  return options
    .map((option) => {
      let filteredOptionGroups: NestedFiltersOptionGroupType[] = [];
      if (option.optionGroups) {
        filteredOptionGroups = option.optionGroups
          .map((group) => {
            return {
              ...group,
              options: filterBy(query, group.options).filter(
                Boolean,
              ) as NestedFiltersOptionType[],
            };
          })
          .filter((group) => group.options.length > 0);
      }

      if (
        option.label.toLowerCase().includes(query.toLowerCase()) ||
        (option.description &&
          option.description.toLowerCase().includes(query.toLowerCase())) ||
        option.value.toLowerCase().includes(query.toLowerCase())
      ) {
        return {
          ...option,
          optionGroups: filteredOptionGroups,
        };
      }

      return filteredOptionGroups.length > 0
        ? {
            ...option,
            optionGroups: filteredOptionGroups,
          }
        : null;
    })
    .filter(Boolean) as NestedFiltersOptionType[];
};
