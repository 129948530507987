import { gql } from '@apollo/client';
import { useBookmarkHandlers } from 'features/bookmark';
import {
  BookmarkFragmentForBookmarkHandlersFragmentDoc,
  useCreateBookmarkForCollectionBookmarkHandlersMutation,
  useDeleteBookmarkForCollectionBookmarkHandlersMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeleteBookmarkForCollectionBookmarkHandlers(
    $input: DeleteCollectionBookmarkInput!
  ) {
    deleteCollectionBookmark(input: $input) {
      message
      success
      deleted {
        id
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation CreateBookmarkForCollectionBookmarkHandlers(
    $input: CreateCollectionBookmarkInput!
  ) {
    createCollectionBookmark(input: $input) {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
  ${BookmarkFragmentForBookmarkHandlersFragmentDoc}
`;

export const useCollectionBookmarkHandlers = () => {
  const [createBookmarkForCollectionBookmarkHandlers] =
    useCreateBookmarkForCollectionBookmarkHandlersMutation();
  const [deleteBookmarkForCollectionBookmarkHandlers] =
    useDeleteBookmarkForCollectionBookmarkHandlersMutation();

  const { addBookmarkItemToSidebar, removeBookmarkItemFromSidebar } =
    useBookmarkHandlers();

  const onBookmarkCollection = (collectionId: string) => {
    createBookmarkForCollectionBookmarkHandlers({
      variables: { input: { collectionId } },
      update: (cache, { data }) => {
        if (!data?.createCollectionBookmark) {
          return;
        }
        addBookmarkItemToSidebar(data.createCollectionBookmark);
        modifyObject(cache, collectionId, 'CollectionModel', {
          isBookmarked: () => true,
        });
      },
    });
  };

  const onRemoveCollectionBookmark = (collectionId: string) => {
    deleteBookmarkForCollectionBookmarkHandlers({
      variables: {
        input: {
          collectionId,
        },
      },
      update: (cache, { data }) => {
        if (!data?.deleteCollectionBookmark) {
          return;
        }
        removeBookmarkItemFromSidebar(
          data.deleteCollectionBookmark.deleted?.[0]?.id ?? '',
        );
        modifyObject(cache, collectionId, 'CollectionModel', {
          isBookmarked: () => false,
        });
      },
    });
  };

  return {
    onBookmarkCollection,
    onRemoveCollectionBookmark,
  };
};
