import { Box, BoxProps } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const HIGHLIGHTABLE_SECTION_PARAM_KEY = 'highlightedSection';

export type HighlightableSectionProps = BoxProps & {
  sectionKey: string;
};

/**
 * HighlightableSection is a wrapper around Box component
 * that allows to scroll to the section with the given key
 * on page load if the section is highlighted in the URL.
 */
export const HighlightableSection = forwardRef(
  (props: HighlightableSectionProps, ref) => {
    const { sectionKey, ...rest } = props;

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const highlightedSection = searchParams.get(
      HIGHLIGHTABLE_SECTION_PARAM_KEY,
    );

    const [shouldHighlight] = useState(highlightedSection === sectionKey);

    // Scroll to highlighted section on page load
    // if highlightedSection is present in the URL
    useEffect(() => {
      if (
        highlightedSection &&
        sectionKey &&
        highlightedSection === sectionKey
      ) {
        const element = document.getElementById(highlightedSection);

        // Scroll to element with offset
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }

        // Clear the highlightedSection params
        searchParams.delete(HIGHLIGHTABLE_SECTION_PARAM_KEY);
        navigate(
          {
            search: searchParams.toString(),
          },
          {
            replace: true,
          },
        );
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box
        ref={ref}
        {...rest}
        id={sectionKey}
        className={[shouldHighlight ? 'highlighted' : '', rest.className].join(
          ' ',
        )}
        sx={{ scrollMarginTop: '80px', ...rest.sx }}
      />
    );
  },
);
