import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ImageWithVideoFallback } from 'components/common/Image';
import { useUserContext } from 'contexts/users/User.context';
import { SocialListeningCreatorProfilePeekingPill } from 'features/socialListeningCreatorProfile/components';
import {
  BrandContentType,
  BrandInboundFiltersInputForCapturedProduct,
  CapturedProductFragmentForSocialListeningCreatorProfileProductsViewFragment,
  CapturedProductFragmentForSocialListeningCreatorProfileProductsViewFragmentDoc,
  CapturedProductSectionSortField,
  CapturedProductSectionViewType,
  CreatorProfileFragmentSocialListeningCreatorProfileProductsDialogViewFragmentDoc,
  CreatorProfileFragmentSocialListeningCreatorProfileProductsViewFragment,
  useGetProductsForSlCreatorProfileProductsViewQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SocialListeningCreatorProfileProductsDialogView } from '../productsDialog';
import { SocialListeningCreatorProfileProductsViewSkeleton } from './SocialListeningCreatorProfileProductsViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CapturedProductFragmentForSocialListeningCreatorProfileProductsView on CapturedProductDataForInbound {
    id
    socialPostCount
    previewPosts(count: $count) {
      id
      thumbnailUrl
      videoUrl
    }
    sentimentReasons
    attributeUsage {
      attribute
      attributeId
      count
    }
    category {
      id
      name
    }
    line
    model
    brand {
      id
      name
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorProfileFragmentSocialListeningCreatorProfileProductsView on CreatorProfileModel {
    id
    ...CreatorProfileFragmentSocialListeningCreatorProfileProductsDialogView
  }
  ${CreatorProfileFragmentSocialListeningCreatorProfileProductsDialogViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetProductsForSLCreatorProfileProductsView(
    $data: BrandInboundFiltersInputForCapturedProduct!
    $count: Int!
  ) {
    capturedProductsPaginatedForInbound(data: $data) {
      data {
        id
        ...CapturedProductFragmentForSocialListeningCreatorProfileProductsView
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${CapturedProductFragmentForSocialListeningCreatorProfileProductsViewFragmentDoc}
`;

type SocialListeningCreatorProfileProductsViewProps = {
  filters: Pick<
    BrandInboundFiltersInputForCapturedProduct,
    'brandId' | 'dateRange' | 'creatorIds'
  >;
  creatorProfile: CreatorProfileFragmentSocialListeningCreatorProfileProductsViewFragment;
};

export const SocialListeningCreatorProfileProductsView = ({
  filters,
  creatorProfile,
}: SocialListeningCreatorProfileProductsViewProps) => {
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const productsVariables: BrandInboundFiltersInputForCapturedProduct = {
    ...filters,
    viewType: CapturedProductSectionViewType.ProductLineModel,
    contentType: BrandContentType.Ugc,
    capturedProductBrandIds: [filters.brandId],
    sortBy: {
      field: CapturedProductSectionSortField.NumberOfPosts,
    },
    take: 5,
  };
  const { data, fetchMore, loading } =
    useGetProductsForSlCreatorProfileProductsViewQuery({
      variables: {
        data: { ...productsVariables, excludeEmptyLineModelCombinations: true },
        count: 3,
      },
    });

  const [dialogProduct, setDialogProduct] =
    useState<CapturedProductFragmentForSocialListeningCreatorProfileProductsViewFragment | null>(
      null,
    );

  // filter out products that don't have a brand name, line, or model
  const products =
    data?.capturedProductsPaginatedForInbound.data.filter(
      (product) => product.brand?.name || product.line || product.model,
    ) || [];

  if (loading) {
    return <SocialListeningCreatorProfileProductsViewSkeleton />;
  }

  return (
    <Box
      sx={{
        px: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        maxHeight: 450,
        overflowY: 'auto',
      }}
    >
      <InfiniteScroll
        loadMore={() =>
          fetchMore({
            variables: {
              data: {
                ...productsVariables,
                skip: products.length,
                take: 10,
              },
              count: 3,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                ...prev,
                capturedProductsPaginatedForInbound: {
                  ...prev.capturedProductsPaginatedForInbound,
                  ...fetchMoreResult.capturedProductsPaginatedForInbound,
                  data: [
                    ...prev.capturedProductsPaginatedForInbound.data,
                    ...fetchMoreResult.capturedProductsPaginatedForInbound.data,
                  ],
                },
              };
            },
          })
        }
        initialLoad={false}
        useWindow={false}
        threshold={250}
        hasMore={
          data?.capturedProductsPaginatedForInbound.pageInfo.hasNextPage ||
          false
        }
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
        }}
        loader={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mb: 6,
              mt: -4,
            }}
          >
            <Typography variant="subhead-lg" color={theme.colors?.utility[900]}>
              Loading...
            </Typography>
          </Box>
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: theme.colors?.primary.white,
            py: 6,
            zIndex: 1,
          }}
        >
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            Products
          </Typography>
          <SocialListeningCreatorProfilePeekingPill />
        </Box>
        {products.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={6}
            mt={-4}
          >
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[600]}
            >
              {loading
                ? 'Loading...'
                : 'Waiting for their first product spotlight ✨'}
            </Typography>
          </Box>
        ) : (
          products.map((product, index) => {
            return (
              <Box
                key={index}
                sx={{
                  p: 4,
                  borderRadius: 3,
                  gap: 3,
                  background: theme.colors?.utility[250],
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: index === 0 ? -4 : 0,
                  mb: index === products.length - 1 ? 6 : 0,
                  cursor: 'pointer',
                  ':hover': {
                    background: theme.colors?.utility[300],
                  },
                }}
                onClick={(e) => {
                  setDialogProduct(product);
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <Typography variant="headline-sm">
                    {`${
                      product.line && product.model
                        ? `${product.line} - ${product.model}`
                        : `${
                            product.line ||
                            product.model ||
                            product.brand?.name ||
                            '-'
                          }`
                    }`}
                  </Typography>
                  <Box
                    component="ul"
                    sx={{
                      pl: 7,
                      m: 0,
                    }}
                  >
                    {product.sentimentReasons.slice(0, 3).map((item) => (
                      <Box
                        component="li"
                        sx={{
                          color: theme.colors?.utility[900],
                          fontSize: 12,
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="subhead-xl"
                          color={theme.colors?.utility[900]}
                        >
                          {item}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    alignItems: 'flex-end',
                  }}
                >
                  {/* TODO: add the mention count when BE is ready */}
                  <Typography
                    variant="headline-sm"
                    color={theme.colors?.utility[800]}
                  >
                    {product.socialPostCount} mention
                    {product.socialPostCount > 1 && 's'}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 3,
                    }}
                  >
                    {product.previewPosts.map((item) => (
                      <Box
                        key={item.id}
                        component={Link}
                        to={PlotRoutes.socialListeningPost(
                          item.id || '',
                          filters.brandId,
                        )}
                        onClick={(e) => {
                          analytics.track(
                            EventName.CreatorProfileAboutPostClicked,
                            {
                              brandId: filters.brandId,
                              userId: user?.id,
                              section: 'products',
                              postId: item.id,
                            },
                          );
                        }}
                      >
                        <ImageWithVideoFallback
                          imageUrl={item.thumbnailUrl || ''}
                          videoUrl={item.videoUrl || ''}
                          sx={{
                            width: 48,
                            height: 68,
                            borderRadius: 2,
                            objectFit: 'cover',
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
      </InfiniteScroll>

      {dialogProduct && (
        <SocialListeningCreatorProfileProductsDialogView
          creatorProfile={creatorProfile}
          mentionsCount={dialogProduct.socialPostCount}
          onClose={() => {
            setDialogProduct(null);
          }}
          filters={{
            ...productsVariables,
            viewType: CapturedProductSectionViewType.ProductLineModel,
            capturedProductLineModelInputs: [
              {
                line: dialogProduct.line,
                model: dialogProduct.model,
              },
            ],
            ...(dialogProduct.brand
              ? { capturedProductBrandIds: [dialogProduct.brand.id] }
              : {}),
          }}
          capturedProduct={dialogProduct}
        />
      )}
    </Box>
  );
};
