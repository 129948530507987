import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Badge, Box, Button, Snackbar, Typography } from '@mui/material';
import { LinearProgress } from 'components/common/LinearProgress';
import { IconBoldBuildings } from 'components/icons/components/bold/IconBoldBuildings';
import { IconBoldLock } from 'components/icons/components/bold/IconBoldLock';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { useUserContext } from 'contexts/users/User.context';
import { CollectionMultiPostPreview } from 'features/collection/components';
import { UpdateCollectionPermissionsDataInput } from 'features/collectionPermission/hooks';
import {
  PermissionCreateRequestDialog,
  PermissionDialogLayout,
} from 'features/permission';
import {
  CollectionFragmentCollectionMultiPostPreviewFragmentDoc,
  CollectionInviteType,
  GeneralPermission,
  useGetCollectionPermissionForDialogViewQuery,
} from 'graphql/generated';
import { useCallback, useEffect, useRef, useState } from 'react';
import { theme } from 'styles/theme';
import { CollectionPermissionManyPostsPermissionDialogView } from '../manyPostsPermissionDialog';
import {
  COLLECTION_FRAGMENT_COLLECTION_PERMISSION_DIALOG_BODY,
  CollectionPermissionDialogBody,
} from './CollectionPermissionDialogBody';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
export const GET_COLLECTION_PERMISSION_FOR_DIALOG_VIEW = gql`
  query GetCollectionPermissionForDialogView($collectionId: String!) {
    collection(id: $collectionId) {
      name
      ...CollectionFragmentCollectionMultiPostPreview
      ...CollectionFragmentCollectionPermissionDialogBody
    }
  }
  ${COLLECTION_FRAGMENT_COLLECTION_PERMISSION_DIALOG_BODY}
  ${CollectionFragmentCollectionMultiPostPreviewFragmentDoc}
`;

export type CollectionPermissionDialogViewProps = {
  collectionId: string;

  /**
   * External dialog controls
   */
  open?: boolean;
  onClose?: () => void;

  renderCustomButton?: () => React.ReactNode;
  collectionInviteType?: CollectionInviteType;
};

export const CollectionPermissionDialogView = (
  props: CollectionPermissionDialogViewProps,
) => {
  const {
    collectionId,
    renderCustomButton,
    open,
    onClose,
    collectionInviteType,
  } = props;

  const defaultInputData = {
    inviteType: collectionInviteType,
  };
  const [inputData, setInputData] =
    useState<UpdateCollectionPermissionsDataInput>(defaultInputData);

  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [showingUpdatesConfirmation, setShowingUpdatesConfirmation] =
    useState(false);
  const updateBtnRef = useRef<HTMLButtonElement>(null);

  const { user } = useUserContext();

  const {
    data: collectionPermissionData,
    loading: collectionPermissionDataLoading,
    refetch: refetchCollectionPermissionData,
  } = useGetCollectionPermissionForDialogViewQuery({
    variables: {
      collectionId,
    },
    // NOTE: I don't know why, but setting this to 'cache-and-network' would cause
    // the CollectionPermissionDialogBody to be unmounted after the permission update mutation,
    // thus causing the confirmation dialog to be open & closed immediately
    fetchPolicy: 'network-only',
  });
  const collection = collectionPermissionData?.collection;

  const {
    isOpen: isOpenPermissionDialog,
    onOpen: onOpenPermissionDialog,
    onClose: onClosePermissionDialog,
  } = useDisclosure();

  const handleClosePermissionDialog = useCallback(() => {
    onClosePermissionDialog();
    onClose?.();
  }, [onClose, onClosePermissionDialog]);

  const {
    isOpen: isOpenCreateRequestDialog,
    onOpen: onOpenCreateRequestDialog,
    onClose: onCloseCreateRequestDialog,
  } = useDisclosure();

  const [
    showManyPostsPermissionUpdateDialog,
    setShowManyPostsPermissionUpdateDialog,
  ] = useState(false);

  const handleOnCallbackAfterUpdate = ({
    showCustomSelectionView,
    closeMainDialog = true,
  }: {
    showCustomSelectionView?: boolean;
    closeMainDialog?: boolean;
  }) => {
    if (closeMainDialog) {
      handleClosePermissionDialog();
    }
    if (showCustomSelectionView) {
      setShowManyPostsPermissionUpdateDialog(true);
    } else if (closeMainDialog) {
      showUpdatedToast();
    }
  };

  const handleManyPostsOnCallback = () => {
    setShowManyPostsPermissionUpdateDialog(false);
    setInputData(defaultInputData);
    showUpdatedToast();
  };

  const handleManyPostsOnNavigateBack = () => {
    setShowManyPostsPermissionUpdateDialog(false);
    onOpenPermissionDialog();
  };

  const [showToast, setShowToast] = useState(false);
  const showUpdatedToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 5000);
  };

  // When external open prop changes, open/close the dialog
  useEffect(() => {
    if (open) {
      onOpenPermissionDialog();
    } else {
      handleClosePermissionDialog();
    }
  }, [handleClosePermissionDialog, onOpenPermissionDialog, open]);

  // Refetch collection permission data when dialog is opened
  // This is to ensure that the data is up-to-date
  useEffect(() => {
    if (isOpenPermissionDialog) {
      refetchCollectionPermissionData();

      // Reset inputData when dialog is open
      // TODO: Find a better way to reset inputData. It's supposed to be reset when we are done with
      // all the updates, but because we have many update flows going on here, I'll just quickly hack it
      setInputData(defaultInputData);
    }
  }, [isOpenPermissionDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PermissionDialogLayout
        isOpen={isOpenPermissionDialog}
        onOpen={onOpenPermissionDialog}
        onClose={handleClosePermissionDialog}
        componentsProps={{
          dialog: {
            PaperProps: {
              sx: showingUpdatesConfirmation
                ? { minHeight: 'unset', height: 'auto', maxHeight: 570 }
                : {},
            },
          },
        }}
        customBtn={
          renderCustomButton ? (
            renderCustomButton()
          ) : (
            <Badge
              variant="dot"
              invisible={
                collection?.creator.id === user?.id
                  ? collection?.permissionRequests?.length === 0
                  : true
              }
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: theme.colors?.utility['pink-3'],
                  width: 12,
                  height: 12,
                  borderRadius: 50,
                },
              }}
            >
              <Button
                sx={{
                  ...theme.typography['headline-sm'],
                  color: theme.colors?.primary.parchment,
                  bgcolor: theme.colors?.primary.black,
                  borderRadius: 2,
                  padding: theme.spacing(2, 4),
                  ':hover': {
                    bgcolor: theme.colors?.primary.black,
                  },
                }}
                startIcon={
                  collection?.generalPermission ===
                  GeneralPermission.InviteOnly ? (
                    collection.inviteMembers.length === 0 ? (
                      <IconBoldLock
                        size={16}
                        color={theme.colors?.primary.parchment}
                      />
                    ) : (
                      <IconCustomUsers
                        size={16}
                        color={theme.colors?.primary.parchment}
                      />
                    )
                  ) : (
                    <IconBoldBuildings
                      size={16}
                      color={theme.colors?.primary.parchment}
                    />
                  )
                }
              >
                Share
              </Button>
            </Badge>
          )
        }
        hasPendingChanges={hasPendingChanges}
        onPressSaveChanges={() => {
          setHasPendingChanges(false);
          updateBtnRef.current?.click();
          handleClosePermissionDialog();
        }}
        renderDialogBody={() =>
          collectionPermissionDataLoading ? (
            <LinearProgress />
          ) : !collection ? (
            <Box>
              <Typography>Error getting collection data</Typography>
            </Box>
          ) : (
            <CollectionPermissionDialogBody
              collection={collection}
              onCallbackAfterUpdate={handleOnCallbackAfterUpdate}
              setHasPendingChanges={setHasPendingChanges}
              updateBtnRef={updateBtnRef}
              onRequestCreateAccess={() => {
                handleClosePermissionDialog();
                onOpenCreateRequestDialog();
              }}
              inputData={inputData}
              setInputData={setInputData}
              setShowingUpdatesConfirmation={setShowingUpdatesConfirmation}
            />
          )
        }
      />

      <PermissionCreateRequestDialog
        isOpen={isOpenCreateRequestDialog}
        onClose={onCloseCreateRequestDialog}
        title={collection?.name}
        user={collection?.creator}
        collectionId={collection?.id}
      />

      {collection && showManyPostsPermissionUpdateDialog && (
        <CollectionPermissionManyPostsPermissionDialogView
          isOpen
          collection={collection}
          onNavigateBack={handleManyPostsOnNavigateBack}
          onCallbackAfterClose={handleManyPostsOnCallback}
          onCallBackAfterSubmit={handleManyPostsOnCallback}
          inputData={inputData}
          setInputData={setInputData}
        />
      )}

      <Snackbar
        sx={{
          position: 'fixed !important',
          left: '50% !important',
          transform: 'translate(-50%, -50%)',
        }}
        ContentProps={{
          sx: {
            bgcolor: theme.colors?.utility['orange-1'],
            color: theme.colors?.primary.black,
            borderRadius: theme.spacing(6),
            p: theme.spacing(0, 4),
            boxShadow:
              '0px 6px 8px -6px rgba(24, 39, 75, 0.12), 0px 8px 16px -6px rgba(24, 39, 75, 0.08)',
            '&.MuiSnackbarContent-root': {
              border: '1px solid transparent !important',
            },
          },
        }}
        open={showToast}
        message={
          <Box display="flex" gap={2} alignItems="center">
            <Box
              sx={{
                width: theme.spacing(15),
                height: theme.spacing(15),
                mt: 4,
                ml: 4,
              }}
            >
              {collection && (
                <CollectionMultiPostPreview
                  sx={{ width: theme.spacing(15), height: theme.spacing(15) }}
                  collection={collection}
                  variant="card-stack-square"
                />
              )}
            </Box>
            <Box>
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility['orange-4']}
              >
                Permissions Updated
              </Typography>
            </Box>
          </Box>
        }
      />
    </>
  );
};
