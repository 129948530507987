import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { BillingHourUsageProgress } from 'features/billing/components/hourUsage';
import {
  BillingPlanName,
  BillingSubscriptionStatus,
  OrganizationBillingFragmentCollectionPaywallFragmentDoc,
  OrganizationBillingFragmentFileStorageLimitProgressFragmentDoc,
  OrganizationBillingFragmentGuestPaywallFragmentDoc,
  OrganizationBillingFragmentLimitProgressFragment,
  OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo } from 'react';
import { theme } from 'styles/theme';
import { DateUtils } from 'utils/date';
import { CreatePortalById } from 'utils/helpers';
import {
  BillingCollectionLimitProgress,
  BillingFileStorageLimitProgress,
  BillingGuestLimitProgress,
  BillingOrganizationMemberLimitProgress,
  BillingUpgradeModal,
} from '../../components';

// eslint-disable-next-line
gql`
  fragment OrganizationBillingFragmentLimitProgress on OrganizationBillingModel {
    id
    plan
    hasUsedFreeTrial
    subscription {
      status
      trialEnd
      currentPeriodEnd
    }
    ...OrganizationBillingFragmentFileStorageLimitProgress
    ...OrganizationBillingFragmentCollectionPaywall
    ...OrganizationBillingFragmentOrganizationMemberPaywall
    ...OrganizationBillingFragmentGuestPaywall
  }
  ${OrganizationBillingFragmentFileStorageLimitProgressFragmentDoc}
  ${OrganizationBillingFragmentCollectionPaywallFragmentDoc}
  ${OrganizationBillingFragmentOrganizationMemberPaywallFragmentDoc}
  ${OrganizationBillingFragmentGuestPaywallFragmentDoc}
`;

type BillingLimitProgressViewProps = {
  organizationBilling: OrganizationBillingFragmentLimitProgressFragment;
};

export const PlanNameMap = {
  [BillingPlanName.Free]: 'Starter',
  [BillingPlanName.Basic]: 'Basic',
  [BillingPlanName.Standard]: 'Business',
  [BillingPlanName.Enterprise]: 'Enterprise',
};

export const BillingLimitProgressView = (
  props: BillingLimitProgressViewProps,
) => {
  const { organizationBilling } = props;

  const {
    isOpen: upgradeOpen,
    onClose: upgradeOnClose,
    onOpen: upgradeOnOpen,
  } = useDisclosure();

  const shouldShowCTAForUpgrade = useMemo(() => {
    return organizationBilling.plan === BillingPlanName.Free;
  }, [organizationBilling]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
        borderRadius: theme.spacing(2),
        padding: theme.spacing(3, 4),
        bgcolor: theme.colors?.utility[275],
      }}
    >
      <CreatePortalById containerId="sidebar-plan-info">
        <Box
          sx={{
            borderRadius: 1.5,
            p: theme.spacing(1, 2),
            background: theme.colors?.secondary['pale-maroon'],
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          <Typography
            variant="headline-xxs"
            color={theme.colors?.secondary['dark-maroon']}
          >
            {PlanNameMap[organizationBilling.plan]} Plan
          </Typography>
          {organizationBilling.subscription?.status ===
            BillingSubscriptionStatus.Trialing && (
            <Typography
              variant="headline-xxs"
              color={theme.colors?.utility[700]}
            >
              • Free trial
            </Typography>
          )}

          <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
            {organizationBilling.subscription?.status ===
              BillingSubscriptionStatus.Trialing &&
            organizationBilling.subscription?.trialEnd
              ? `• ${DateUtils.fromNowInDays(
                  organizationBilling.subscription.trialEnd * 1000,
                )} left`
              : organizationBilling.subscription?.status ===
                  BillingSubscriptionStatus.Active &&
                organizationBilling.subscription.currentPeriodEnd
              ? `• Due on ${moment(
                  organizationBilling.subscription.currentPeriodEnd * 1000,
                ).format('Do MMM, YYYY')}`
              : ''}
          </Typography>
        </Box>
        {shouldShowCTAForUpgrade && (
          <>
            <Typography
              variant="headline-xxs"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={upgradeOnOpen}
            >
              {organizationBilling.hasUsedFreeTrial
                ? 'Upgrade'
                : `Start Free Trial`}
            </Typography>

            <BillingUpgradeModal
              isOpen={upgradeOpen}
              onClose={upgradeOnClose}
            />
          </>
        )}
      </CreatePortalById>

      <BillingHourUsageProgress />

      <BillingFileStorageLimitProgress
        organizationBilling={organizationBilling}
      />
      <BillingCollectionLimitProgress
        organizationBilling={organizationBilling}
      />
      <BillingOrganizationMemberLimitProgress
        organizationBilling={organizationBilling}
      />
      <BillingGuestLimitProgress organizationBilling={organizationBilling} />
    </Box>
  );
};
