import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { CreatorPeekingContent } from 'features/socialMediaListening/views/manageCreators/sections/creatorPeekingContent';
import {
  CreatorProfileFragmentSlPeekCreatorDialogViewFragment,
  TopicFragmentCreatorPeekingContentFragmentDoc,
  useGetTopicsTrackingListForSlPeekCreatorDialogViewQuery,
  useUpdateCreatorProfileForSlPeekCreatorDialogViewMutation,
  useUpdateCreatorTrackingTopicStatusForSlPeekCreatorDialogViewMutation,
} from 'graphql/generated';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { EventName, useAnalytics } from 'hooks/useAnalytics';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetTopicsTrackingListForSLPeekCreatorDialogView(
    $data: CreatorTopicTrackingInput!
  ) {
    getTopicsTrackingList(data: $data) {
      isTracking
      topic {
        ...TopicFragmentCreatorPeekingContent
        active
      }
    }
  }
  ${TopicFragmentCreatorPeekingContentFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CreatorProfileFragmentSLPeekCreatorDialogView on CreatorProfileModel {
    id
    isTrackingInsights
    creators {
      id
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCreatorTrackingTopicStatusForSLPeekCreatorDialogView(
    $input: UpdateCreatorTrackingTopicStatusInput!
  ) {
    updateCreatorTrackingTopicStatus(input: $input) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation UpdateCreatorProfileForSLPeekCreatorDialogView(
    $input: UpdateCreatorProfileInput!
  ) {
    updateCreatorProfile(input: $input) {
      message
      success
    }
  }
`;

interface SocialListeningPeekCreatorDialogViewProps {
  brandId: string;
  creatorProfile: CreatorProfileFragmentSlPeekCreatorDialogViewFragment;
  onClose: (isDataUpdated: boolean) => void;
}

export const SocialListeningPeekCreatorDialogView = ({
  brandId,
  creatorProfile,
  onClose,
}: SocialListeningPeekCreatorDialogViewProps) => {
  const [selectedTopicIds, setSelectedTopicIds] = useState<string[]>([]);
  const [isTrackingInsights, setIsTrackingInsights] = useState(false);
  const analytics = useAnalytics();

  const [updateCreatorTrackingTopicStatus] =
    useUpdateCreatorTrackingTopicStatusForSlPeekCreatorDialogViewMutation();
  const [updateCreatorProfile] =
    useUpdateCreatorProfileForSlPeekCreatorDialogViewMutation();

  const { data: creatorTrackingData, updateQuery: updateCreatorTrackingData } =
    useGetTopicsTrackingListForSlPeekCreatorDialogViewQuery({
      variables: {
        data: {
          creatorIds: creatorProfile.creators.map((creator) => creator.id),
          brandId,
        },
      },
    });

  const trackingData = useMemo(
    () => creatorTrackingData?.getTopicsTrackingList || [],
    [creatorTrackingData],
  );

  const { topics, selectedTrackedTopicIds } = useMemo(() => {
    const topics = trackingData
      .filter((data) => data.topic.active)
      .map((data) => data.topic);

    const selectedTrackedTopicIds = trackingData
      .filter((data) => data.isTracking)
      .map((data) => data.topic.id);

    setSelectedTopicIds(selectedTrackedTopicIds);

    return { topics, selectedTrackedTopicIds };
  }, [trackingData]);

  const { canSubmit, isSelectedTopicIdsChanged, isTrackingInsightsChanged } =
    useMemo(() => {
      const isTrackingInsightsChanged =
        isTrackingInsights !== creatorProfile.isTrackingInsights;
      const isSelectedTopicIdsChanged =
        JSON.stringify(selectedTopicIds) !==
        JSON.stringify(selectedTrackedTopicIds);

      return {
        canSubmit: isTrackingInsightsChanged || isSelectedTopicIdsChanged,
        isTrackingInsightsChanged,
        isSelectedTopicIdsChanged,
      };
    }, [
      isTrackingInsights,
      creatorProfile.isTrackingInsights,
      selectedTopicIds,
      selectedTrackedTopicIds,
    ]);

  useEffect(() => {
    setIsTrackingInsights(creatorProfile.isTrackingInsights);
  }, [creatorProfile.isTrackingInsights]);

  const onConfirm = useCallback(async () => {
    // Track the creator peek event
    analytics.track(EventName.CrmDashboardCreatorPeeked, {
      brandId,
      creatorProfileId: creatorProfile.id,
      isTrackingInsights,
      trackedTopicIds: selectedTopicIds,
    });

    if (isSelectedTopicIdsChanged) {
      const untrackedTopicIds = selectedTrackedTopicIds.filter(
        (topicId) => !selectedTopicIds.includes(topicId),
      );
      const trackedTopicIds = selectedTopicIds.filter(
        (topicId) => !selectedTrackedTopicIds.includes(topicId),
      );
      await updateCreatorTrackingTopicStatus({
        variables: {
          input: {
            creatorProfileId: creatorProfile.id,
            topicTrackingStatuses: [
              ...untrackedTopicIds.map((topicId) => ({
                topicId,
                trackingStatus: false,
              })),
              ...trackedTopicIds.map((topicId) => ({
                topicId,
                trackingStatus: true,
              })),
            ],
          },
        },
      });

      updateCreatorTrackingData((prevData) => {
        const newData = prevData.getTopicsTrackingList.map((data) => {
          if (trackedTopicIds.includes(data.topic.id)) {
            return {
              ...data,
              isTracking: true,
            };
          }
          if (untrackedTopicIds.includes(data.topic.id)) {
            return {
              ...data,
              isTracking: false,
            };
          }
          return data;
        });
        return {
          ...prevData,
          getTopicsTrackingList: newData,
        };
      });
    }

    if (isTrackingInsightsChanged) {
      await updateCreatorProfile({
        variables: {
          input: {
            id: creatorProfile.id,
            data: {
              isTrackingInsights,
            },
          },
        },
      });
    }
    onClose(canSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    analytics,
    brandId,
    creatorProfile.id,
    isSelectedTopicIdsChanged,
    isTrackingInsightsChanged,
    canSubmit,
    selectedTrackedTopicIds,
    selectedTopicIds,
    isTrackingInsights,
  ]);

  return (
    <Dialog
      open
      maxWidth="sm"
      PaperProps={{
        sx: {
          maxWidth: 450,
          padding: 8,
          borderRadius: 6,
          boxShadow:
            '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)',
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
        },
      }}
      onClose={() => onClose(false)}
    >
      <DialogTitle
        sx={{
          p: 0,
        }}
      >
        <Typography
          variant="headline-xl"
          fontSize={24}
          fontWeight={500}
          letterSpacing="-0.96px"
          lineHeight="normal"
        >
          How do you want to Peek at the creator?
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          li: {
            '> :first-child': {
              alignItems: 'flex-start',
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <CreatorPeekingContent
            brandId={brandId}
            selectedTopicIds={selectedTopicIds}
            handleEngagementTracking={(topicId) => {
              if (selectedTopicIds.includes(topicId)) {
                setSelectedTopicIds(
                  selectedTopicIds.filter((id) => id !== topicId),
                );
              } else {
                setSelectedTopicIds([...selectedTopicIds, topicId]);
              }
            }}
            handleUntrackEngagementTrackingForTopics={() => {
              setSelectedTopicIds([]);
            }}
            handleUpdateCreatorTrackingInsights={(isTrackingInsights) => {
              setIsTrackingInsights(isTrackingInsights);
            }}
            isTrackingInsights={isTrackingInsights}
            topics={topics}
            componentsProps={{
              item: {
                sx: {
                  background: theme.colors?.utility[250],
                  borderRadius: 2,
                },
              },
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <Button
          variant="primary-alt"
          fullWidth
          sx={{
            borderRadius: 2,
          }}
          disabled={!canSubmit}
          onClick={onConfirm}
        >
          Confirm
        </Button>
        <Button
          variant="text"
          fullWidth
          sx={{ color: theme.colors?.utility[800], m: '0 !important' }}
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
