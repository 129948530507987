import {
  Box,
  CircularProgress,
  InputAdornment,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  SocialMediaListeningCreatorProfile,
  TrackUntrackCreatorButton,
} from 'features/socialMediaListening/components';
import { useCreatorsTopicTrackingForSocialListening } from 'features/socialMediaListening/hooks/useCreatorsTopicTrackingForSocialListening';
import { platformIconMap, platformLabelMap } from 'features/socialPost';
import {
  BrandFragmentUseSocialListeningOnboardingBrandFragment,
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment,
  Platform,
  PlotFeature,
  useGetSuggestedCreatorsForSocialListeningOnboardingCreatorTrackingQuery,
} from 'graphql/generated';
import { ReactNode, useCallback, useState } from 'react';
import { theme } from 'styles/theme';
import { RenderNextButton } from '../renderNextButton/RenderNextButton';

interface SocialMediaListeningOnboardingCreatorTrackingProps {
  platform: Platform;
  currentTopicId: string;
  onNext: () => void;
  brand?: BrandFragmentUseSocialListeningOnboardingBrandFragment;
}

type TableHead = {
  label: ReactNode;
  tooltip?: string;
};

const renderLoading = (loadingText: string) => {
  return (
    <Typography
      variant="body-xl"
      color={theme.colors?.primary.parchment}
      display="flex"
      alignItems="center"
      gap={4}
      my={4}
    >
      <CircularProgress
        size={16}
        sx={{
          color: theme.colors?.primary.parchment,
        }}
      />
      {loadingText}
    </Typography>
  );
};

export const SocialMediaListeningOnboardingCreatorTracking = ({
  platform,
  currentTopicId,
  brand,
  onNext,
}: SocialMediaListeningOnboardingCreatorTrackingProps) => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const isSocialListeningCrmEnabled = isFeatureEnabled(
    PlotFeature.SocialListeningCrm,
  );
  const [searchedTerm, setSearchedTerm] = useState('');
  const [currentSearchStr, setCurrentSearchStr] = useState('');
  const brandHandle = brand?.creators.find(
    (c) => c.platform === platform,
  )?.handle;

  const { data: creatorData, actions: creatorActions } =
    useCreatorsTopicTrackingForSocialListening({
      platform,
      topicId: currentTopicId,
      searchCreator: searchedTerm,
      handle: brandHandle,
      brandId: brand?.id || '',
    });
  const {
    followingCreators: {
      data: followingCreators,
      loading: loadingFollowingCreators,
    },
    searchedCreators: { data: searchedCreators, loading: searchingCreators },
    trackedCreators: { data: trackedCreators },
    inProgress: { updatingCreatorPlatformIds },
  } = creatorData;
  const {
    handleTrackCreator,
    handleUntrackCreator,
    handleUpdateCreatorTrackingInsights,
  } = creatorActions;

  const validateAndProceed = () => {
    onNext();
  };

  const { data: suggestedCreatorsData, loading: loadingSuggestedCreators } =
    useGetSuggestedCreatorsForSocialListeningOnboardingCreatorTrackingQuery({
      variables: {
        brandId: brand?.id || '',
        filters: {
          platform,
        },
      },
      skip: !brand,
    });
  const suggestedCreators = suggestedCreatorsData?.getSuggestedCreators || [];

  const handleCreatorTracking = useCallback(
    (platformId: string, handle: string, track: boolean) => {
      if (track) {
        handleTrackCreator(platformId, currentTopicId, handle);
      } else {
        handleUntrackCreator(platformId, currentTopicId, handle);
      }
    },
    [currentTopicId], // eslint-disable-line
  );

  const commonTableHead = [
    {
      label: 'Engagement',
      tooltip:
        'See all the creator’s posts in your engagement dashboard to engage daily.',
    },
    {
      label: 'AI Creator Insights',
      tooltip:
        'Track personal moments (eg: birthdays), brand partnerships, and their favorite products from your brand.',
    },
  ];

  const renderRadioItem = useCallback(
    (isSelected: boolean, updating: boolean, onClick: () => void) => {
      if (updating) {
        return (
          <CircularProgress
            size={24}
            sx={{ color: theme.colors?.primary.parchment }}
          />
        );
      }

      return (
        <Radio
          checked={isSelected}
          checkedIcon={<IconBoldTickCircle size={24} />}
          sx={{
            p: 0,
            '&.MuiRadio-root': {
              color: theme.colors?.primary.parchment,
            },
          }}
          onClick={onClick}
        />
      );
    },
    [],
  );

  const creators: any = searchedCreators.length
    ? searchedCreators
    : followingCreators;

  const renderCreatorTable = useCallback(
    (
      creators: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[],
      headers: TableHead[],
    ) => {
      return (
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((head, index) => {
                const tooltip = head?.['tooltip'] || '';
                return (
                  <TableCell
                    sx={{
                      background: theme.colors?.utility.blueberry,
                      borderBottom: 'none',
                    }}
                    key={index}
                  >
                    <Tooltip
                      title={tooltip}
                      componentsProps={{
                        tooltip: {
                          sx: {
                            maxWidth: 242,
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: index === 0 ? 'flex-start' : 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="headline-sm"
                          sx={{
                            color: theme.colors?.primary.parchment,
                          }}
                        >
                          {head.label}
                        </Typography>
                        {tooltip && (
                          <IconBoldInfoCircle
                            size={16}
                            color={theme.colors?.primary.parchment}
                            style={{
                              rotate: '180deg',
                            }}
                          />
                        )}
                      </Box>
                    </Tooltip>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {creators.map((creator) => {
              const isSelected = !!trackedCreators.find(
                (c) => c.platformId === creator.platformId,
              );
              const isTrackingInsights =
                trackedCreators.find((c) => c.platformId === creator.platformId)
                  ?.isTrackingInsightsForCreatorProfile || false;
              return (
                <TableRow
                  sx={{
                    td: {
                      borderBottom: 'none',
                    },
                  }}
                >
                  <TableCell>
                    <SocialMediaListeningCreatorProfile
                      creator={
                        creator as unknown as CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment
                      }
                      componentProps={{
                        avatarSx: {
                          sx: {
                            width: theme.spacing(12),
                            height: theme.spacing(12),
                            border: `2px solid rgba(250, 243, 236, 0.6)`,
                          },
                        },
                        containerSx: {
                          sx: {
                            color: theme.colors?.primary.parchment,
                            '& .nickName': {
                              color: theme.colors?.primary.parchment,
                              ...theme.typography['subhead-xl'],
                            },
                            '& .handle': {
                              ...theme.typography['headline-md'],
                              fontSize: 20,
                            },
                          },
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell align="center">
                    {renderRadioItem(
                      isSelected,
                      updatingCreatorPlatformIds.includes(creator.platformId),
                      () =>
                        handleCreatorTracking(
                          creator.platformId,
                          creator.handle,
                          !isSelected,
                        ),
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {renderRadioItem(
                      isTrackingInsights,
                      updatingCreatorPlatformIds.includes(creator.platformId),
                      () => {
                        handleUpdateCreatorTrackingInsights({
                          brandId: brand?.id || '',
                          creatorHandle: creator.handle,
                          creatorPlatform: creator.platform,
                          creatorPlatformId: creator.platformId,
                          isTrackingInsights: !isTrackingInsights,
                        });
                      },
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      );
    },
    [renderRadioItem, trackedCreators, updatingCreatorPlatformIds], // eslint-disable-line
  );

  const renderSearch = useCallback(() => {
    return (
      <TextField
        placeholder="Search"
        value={currentSearchStr}
        disabled={false}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{ color: theme.colors?.primary.parchment }}
              position="start"
            >
              <IconOutlineSearchNormal1 size={20} />
            </InputAdornment>
          ),
          endAdornment: currentSearchStr &&
            currentSearchStr !== searchedTerm && (
              <InputAdornment
                sx={{ color: theme.colors?.primary.parchment }}
                position="end"
              >
                <Typography
                  variant="body-lg"
                  color={theme.colors?.primary.parchment}
                >
                  <Box
                    component="span"
                    p={theme.spacing(0.5, 1)}
                    sx={{
                      backgroundColor: 'rgba(250, 243, 236, 0.15)',
                      mx: 1,
                      border: `2px solid ${theme.colors?.utility[500]}`,
                      borderRadius: theme.spacing(2),
                      fontWeight: 600,
                      fontSize: theme.spacing(3),
                    }}
                  >
                    ⏎
                  </Box>{' '}
                  to search
                </Typography>
              </InputAdornment>
            ),
        }}
        onChange={(e) => {
          setCurrentSearchStr(e.target.value);
          if (!e.target.value) {
            setSearchedTerm('');
          }
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();

            setSearchedTerm(currentSearchStr);
          }
        }}
        sx={{
          '.MuiOutlinedInput-root': {
            borderRadius: theme.spacing(10),
            py: `${theme.spacing(3)} !important`,
            px: `${theme.spacing(6)} !important`,
            bgcolor: 'rgba(255, 255, 255, 0.10)',
            color: theme.colors?.primary.parchment,

            '& .Mui-disabled': {
              backgroundColor: 'transparent',
              color: theme.colors?.primary.parchment,
            },

            input: {
              ...theme.typography['headline-lg'],
              p: '0 !important',
            },

            '.MuiOutlinedInput-notchedOutline': {
              display: 'none !important',
            },
          },
        }}
      />
    );
  }, [currentSearchStr, searchedTerm]);

  const PlatformIcon = platformIconMap[platform];

  return (
    <Box
      width="60vw"
      maxHeight="80vh"
      sx={{
        overflowY: 'auto',
      }}
      minWidth={theme.spacing(150)}
      display="block"
      py={10}
      px={6}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        flexDirection="column"
        gap={4}
      >
        {!isSocialListeningCrmEnabled ? (
          <SocialMediaListeningOnboardingCreatorTrackingOldView
            suggestedCreators={suggestedCreators}
            creators={creators}
            trackedCreators={trackedCreators}
            updatingCreatorPlatformIds={updatingCreatorPlatformIds}
            handleCreatorTracking={handleCreatorTracking}
            loadingSuggestedCreators={loadingSuggestedCreators}
            searchingCreators={searchingCreators}
            loadingFollowingCreators={loadingFollowingCreators}
            renderSearch={renderSearch}
            platform={platform}
          />
        ) : (
          <>
            <Typography
              variant="body-lg"
              sx={{
                fontWeight: 600,
                width: 'fit-content',
                padding: theme.spacing(1, 3),
                bgcolor: 'rgba(250, 243, 236, 0.20)',
                color: theme.colors?.primary.parchment,
                borderRadius: theme.spacing(4),
              }}
            >
              Peeking
            </Typography>
            <Typography
              color={theme.colors?.primary.parchment}
              variant="body-lg"
              fontSize={theme.spacing(7)}
              fontWeight={600}
              display="flex"
              gap={1}
              alignItems="center"
            >
              Peek at <PlatformIcon size={28} /> Creators
            </Typography>
            <Typography
              color={theme.colors?.primary.parchment}
              variant="body-xl"
              fontWeight={500}
            >
              Track your favorite {platformLabelMap[platform]} creators and
              customize how you stay connected - whether engaging with their
              daily posts or tracking moments for hyper-personalization.
            </Typography>
            {loadingSuggestedCreators &&
              renderLoading('Loading suggested creators...')}
            {!!suggestedCreators.length && (
              <>
                {renderCreatorTable(suggestedCreators, [
                  {
                    label: (
                      <Typography
                        mt={4}
                        color={theme.colors?.primary.parchment}
                        variant="headline-sm"
                        display="flex"
                        gap={2}
                        alignItems="center"
                      >
                        <IconCustomSparkles size={16} />
                        AI recommended
                      </Typography>
                    ),
                  },
                  ...commonTableHead,
                ])}

                <Box
                  sx={{ borderBottom: '2px solid rgba(250, 243, 236, 0.10)' }}
                />
              </>
            )}
            {renderSearch()}
            {searchingCreators && renderLoading('Searching creators...')}
            {loadingFollowingCreators &&
              renderLoading('Loading following creators...')}
            {!!creators.length &&
              renderCreatorTable(creators, [
                { label: 'Creators' },
                ...commonTableHead,
              ])}
          </>
        )}

        <RenderNextButton onNextClick={validateAndProceed} />
      </Box>
    </Box>
  );
};

interface SocialMediaListeningOnboardingCreatorTrackingOldViewProps {
  suggestedCreators: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];
  creators: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];
  trackedCreators: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];
  updatingCreatorPlatformIds: string[];
  handleCreatorTracking: (
    platformId: string,
    handle: string,
    track: boolean,
  ) => void;
  loadingSuggestedCreators: boolean;
  searchingCreators: boolean;
  loadingFollowingCreators: boolean;
  renderSearch: () => ReactNode;
  platform: Platform;
}

const SocialMediaListeningOnboardingCreatorTrackingOldView = ({
  suggestedCreators,
  creators,
  trackedCreators,
  updatingCreatorPlatformIds,
  handleCreatorTracking,
  platform,
  loadingSuggestedCreators,
  searchingCreators,
  loadingFollowingCreators,
  renderSearch,
}: SocialMediaListeningOnboardingCreatorTrackingOldViewProps) => {
  const renderCreators = useCallback(
    (
      creators: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[],
    ) => {
      return (
        <Box
          display="flex"
          gap={5}
          justifyContent="space-between"
          flexDirection="column"
        >
          {creators.map((creator) => (
            <Box display="flex" justifyContent="space-between" width="100%">
              <SocialMediaListeningCreatorProfile
                creator={creator}
                componentProps={{
                  showDetailsOnHover: true,
                  containerSx: {
                    sx: {
                      color: theme.colors?.primary.parchment,
                      '& .nickName': {
                        color: theme.colors?.primary.parchment,
                      },
                    },
                  },
                }}
              />

              <TrackUntrackCreatorButton
                updating={updatingCreatorPlatformIds.includes(
                  creator.platformId,
                )}
                tracking={
                  !!trackedCreators.find(
                    (c) => c.platformId === creator.platformId,
                  )
                }
                onChange={(track) => {
                  handleCreatorTracking(
                    creator.platformId,
                    creator.handle,
                    track,
                  );
                }}
              />
            </Box>
          ))}
        </Box>
      );
    },
    [trackedCreators, updatingCreatorPlatformIds],
  );

  const PlatformIcon = platformIconMap[platform];

  return (
    <>
      <Typography
        variant="body-lg"
        sx={{
          fontWeight: 600,
          width: 'fit-content',
          padding: theme.spacing(1, 3),
          bgcolor: 'rgba(250, 243, 236, 0.20)',
          color: theme.colors?.primary.parchment,
          borderRadius: theme.spacing(4),
        }}
      >
        Creator Focus
      </Typography>
      <Typography
        color={theme.colors?.primary.parchment}
        variant="body-lg"
        fontSize={theme.spacing(7)}
        fontWeight={600}
        display="flex"
        gap={1}
        alignItems="center"
      >
        Track creators on
        <PlatformIcon size={28} />
      </Typography>
      <Typography
        color={theme.colors?.primary.parchment}
        variant="body-xl"
        fontWeight={500}
      >
        Track specific creators and get notified when they mention your chosen
        topics or keywords.
      </Typography>
      {loadingSuggestedCreators &&
        renderLoading('Loading suggested creators...')}
      {!!suggestedCreators.length && (
        <>
          <Typography
            mt={4}
            color={theme.colors?.primary.parchment}
            variant="body-xl"
            fontWeight={600}
            display="flex"
            gap={2}
            alignItems="center"
          >
            <IconCustomSparkles size={16} />
            AI recommended
          </Typography>

          {renderCreators(suggestedCreators)}

          <Box sx={{ borderBottom: '1px solid rgba(250, 243, 236, 0.10)' }} />
        </>
      )}
      {renderSearch()}
      {searchingCreators && renderLoading('Searching creators...')}
      {loadingFollowingCreators &&
        renderLoading('Loading following creators...')}

      {!!creators.length && renderCreators(creators)}
    </>
  );
};
