import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileInsightsViewSkeleton = () => {
  return (
    <Box
      sx={{
        p: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          <Skeleton variant="text" width="120px" height={24} />
        </Typography>
        <Skeleton variant="rounded" width="140px" height={32} />
      </Box>

      <Grid container spacing={4} height="100%">
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              p: 4,
              borderRadius: 3,
              border: `1px solid ${theme.colors?.utility[300]}`,
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Skeleton variant="circular" width={16} height={16} />
              <Skeleton variant="text" width="80px" height={24} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Skeleton variant="text" width="90%" height={20} />
              <Skeleton variant="text" width="85%" height={20} />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 2,
                mt: 'auto',
                pt: 2,
              }}
            >
              {[1, 2, 3].map((index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={80}
                  height={32}
                />
              ))}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              p: 4,
              borderRadius: 3,
              border: `1px solid ${theme.colors?.utility[300]}`,
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Skeleton variant="circular" width={16} height={16} />
              <Skeleton variant="text" width="100px" height={24} />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Skeleton variant="text" width="95%" height={20} />
              <Skeleton variant="text" width="90%" height={20} />
              <Skeleton variant="text" width="85%" height={20} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
