import { Box, IconButton } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearMoreVert } from 'components/icons/components/linear/IconLinearMoreVert';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { bookmarkOptions } from 'features/bookmark';
import {
  BrandCompetitorsView,
  useBrandCompetitorBookmarkHandlers,
} from 'features/brandCompetitors';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import {
  SocialListeningFeatureType,
  SocialMediaListeningTopHeaderBreadcrumbs,
  useBrandDetails,
} from 'features/socialMediaListening';
import { PlotFeature, ViewEntityType } from 'graphql/generated';
import { usePageViewEntity } from 'hooks/useViewEntity';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

export const SocialMediaListeningCompetitorsPage = () => {
  const { brandId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const {
    setRenderTitle,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
    renderMenuExtraLeft,
  } = useCustomHeaderContext();
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  const { brand } = useBrandDetails({ brandId });

  const { onBookmarkCompetitorAnalytic, onRemoveCompetitorAnalyticBookmark } =
    useBrandCompetitorBookmarkHandlers();

  usePageViewEntity(brandId, ViewEntityType.BrandCompetitorAnalytics);

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.CompetitorAnalysis,
      );
      if (
        !_isFeatureEnabled &&
        !user?.socialListeningBrands.find((b) => b.id === brandId)
      ) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line

  useEffect(() => {
    const renderInviteButton = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );
    addRenderMenuExtraLeft(renderInviteButton, 1);

    if (brand) {
      const isBookmarked = brand.bookmarkState.competitorAnalytics;
      addRenderMenuExtraLeft(
        () => (
          <ContextMenu
            renderButton={() => (
              <IconButton sx={{ p: 1, ml: 1 }}>
                <IconLinearMoreVert color={theme.colors?.primary.black} />
              </IconButton>
            )}
            options={bookmarkOptions({
              isBookmarked,
              onToggleBookmark: () => {
                if (isBookmarked) {
                  onRemoveCompetitorAnalyticBookmark(brand.id);
                } else {
                  onBookmarkCompetitorAnalytic(brand.id);
                }
              },
            })}
          />
        ),
        2,
      );
    }
    setRenderTitle(() => (
      <Box display="flex" alignItems="center" gap={3}>
        <SocialMediaListeningTopHeaderBreadcrumbs
          brandId={brandId}
          featureType={SocialListeningFeatureType.CompetitorAnalytics}
          componentProps={{
            brandSelector: {
              generateUrl: (brandId) =>
                PlotRoutes.socialListeningCompetitors(brandId),
            },
          }}
        />
      </Box>
    ));

    return () => {
      setRenderTitle(() => null);
      renderMenuExtraLeft?.map((render, index) =>
        // eslint-disable-next-line
        removeRenderMenuExtraLeft(() => <>{render ?? ''}</>, index),
      );
    };
  }, [location.pathname, brand, JSON.stringify(renderMenuExtraLeft)]); // eslint-disable-line

  return <BrandCompetitorsView brand={brand ?? undefined} brandId={brandId} />;
};
