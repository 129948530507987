export const CommunityLabelColorMap = [
  {
    color: '#888569',
    backgroundColor: 'rgba(224, 228, 202, 0.50)',
  },
  {
    color: '#726e87',
    backgroundColor: 'rgba(230, 221, 237, 0.50)',
  },
  {
    color: '#697b88',
    backgroundColor: 'rgba(217, 227, 243, 0.50)',
  },
  {
    color: '#698884',
    backgroundColor: 'rgba(205, 226, 223, 0.50)',
  },
  {
    color: '#9D762E',
    backgroundColor: 'rgba(248, 232, 202, 0.50)',
  },
  {
    color: '#936e54',
    backgroundColor: 'rgba(246, 229, 216, 0.50)',
  },
  {
    color: '#886969',
    backgroundColor: 'rgba(246, 225, 230, 0.50)',
  },
];
