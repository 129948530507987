import { gql } from '@apollo/client';
import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { HIGHLIGHTABLE_SECTION_PARAM_KEY } from 'components/common/HighlightableSection';
import { InfiniteScrollBoundary } from 'components/common/InfiniteScrollBoundary';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { useUserContext } from 'contexts/users/User.context';
import { RadarEventTimelineItemView } from 'features/radarEvent';
import { SocialListeningCreatorProfileDetailsView } from 'features/socialListeningCreatorProfile';
import { SocialListeningRadarAddActivityButtonView } from 'features/socialListeningRadar';
import {
  CreatorProfileFragmentActivityTabContentFragment,
  CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc,
  RadarEventFragmentRadarEventTimelineItemViewFragment,
  RadarEventFragmentRadarEventTimelineItemViewFragmentDoc,
  RadarEventType,
  RadarEventViewType,
  useGetPaginatedRadarEventsForActivityTabContentQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';
import { ActivityTabContentSkeleton } from './ActivityTabContentSkeleton';

export const CREATOR_PROFILE_FRAGMENT_ACTIVITY_TAB_CONTENT = gql`
  fragment CreatorProfileFragmentActivityTabContent on CreatorProfileModel {
    id
    brandId
    isTrackingInsights
    creatorTrackedTopics(brandId: $brandId) {
      id
    }
    ...CreatorProfileFragmentSLCreatorProfileDetailsView
  }
  ${CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetPaginatedRadarEventsForActivityTabContent(
    $input: PaginatedRadarEventInput!
  ) {
    getPaginatedRadarEvents(input: $input) {
      data {
        id
        ...RadarEventFragmentRadarEventTimelineItemView
      }
      pageInfo {
        hasNextPage
      }
    }
  }
  ${RadarEventFragmentRadarEventTimelineItemViewFragmentDoc}
`;

export type ActivityTabContentProps = {
  creatorProfile: CreatorProfileFragmentActivityTabContentFragment;
};

export const ActivityTabContent = (props: ActivityTabContentProps) => {
  const { creatorProfile } = props;
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    analytics.track(EventName.CreatorProfileActivityViewed, {
      brandId: creatorProfile.brandId,
      userId: user?.id,
      creatorProfileId: creatorProfile.id,
    });
  }, [creatorProfile.id]);

  const isCurrentCreatorProfilePeeked = useMemo(() => {
    return (
      creatorProfile.isTrackingInsights ||
      creatorProfile.creatorTrackedTopics.length > 0
    );
  }, [creatorProfile]);

  const { data, loading, refetch, fetchMore } =
    useGetPaginatedRadarEventsForActivityTabContentQuery({
      variables: {
        input: {
          brandId: creatorProfile.brandId,
          creatorProfileIds: [creatorProfile.id],
          viewType: RadarEventViewType.AllEvents,
          eventTypes: isCurrentCreatorProfilePeeked
            ? Object.values(RadarEventType)
            : Object.values(RadarEventType).filter(
                (eventType) =>
                  ![
                    RadarEventType.Moment,
                    RadarEventType.Anniversary,
                    RadarEventType.Birthday,
                  ].includes(eventType),
              ),
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  const radarEvents = data?.getPaginatedRadarEvents.data || [];
  const hasNextPage =
    data?.getPaginatedRadarEvents.pageInfo.hasNextPage ?? false;

  const fetchMoreRadarEvents = async () => {
    return fetchMore({
      variables: {
        input: {
          brandId: creatorProfile.brandId,
          creatorProfileIds: [creatorProfile.id],
          viewType: RadarEventViewType.AllEvents,
          skip: radarEvents.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          getPaginatedRadarEvents: {
            ...fetchMoreResult.getPaginatedRadarEvents,
            data: [
              ...prev.getPaginatedRadarEvents.data,
              ...fetchMoreResult.getPaginatedRadarEvents.data,
            ],
          },
        };
      },
    });
  };

  const onActivityAdded = (radarEventActivityId: string) => {
    analytics.track(EventName.CreatorProfileActivityAdded, {
      brandId: creatorProfile.brandId,
      userId: user?.id,
      creatorProfileId: creatorProfile.id,
      radarEventActivityId,
    });

    refetch();

    // Push HIGHLIGHTABLE_SECTION_PARAM_KEY to url so it will trigger highlight effect
    // for the newly added activity
    navigate(
      {
        search: `?${HIGHLIGHTABLE_SECTION_PARAM_KEY}=rea-${radarEventActivityId}`,
      },
      {
        replace: true,
      },
    );
  };

  // Group radar events by year and week
  // Format:
  // [{ year: ..., weeks: [{ from: ..., to: ... }, { from: ..., to: ... }] }]
  const { radarEventsByYearAndWeek } = useMemo(() => {
    const radarEventsByYearAndWeek = radarEvents.reduce((acc, radarEvent) => {
      const radarEventCreatedAt = moment(radarEvent.platformCreateTime);
      const year = radarEventCreatedAt.year();
      const week = radarEventCreatedAt.week();

      const yearIndex = acc.findIndex((item) => item.year === year);
      if (yearIndex === -1) {
        acc.push({
          year,
          weeks: [
            {
              from: radarEventCreatedAt.clone().startOf('week'),
              to: radarEventCreatedAt.clone().endOf('week'),
              items: [radarEvent],
            },
          ],
        });
      } else {
        const weekIndex = acc[yearIndex].weeks.findIndex(
          (item) => item.from.week() === week,
        );

        if (weekIndex === -1) {
          acc[yearIndex].weeks.push({
            from: radarEventCreatedAt.clone().startOf('week'),
            to: radarEventCreatedAt.clone().endOf('week'),
            items: [radarEvent],
          });
        } else {
          acc[yearIndex].weeks[weekIndex].items.push(radarEvent);
        }
      }

      return acc;
    }, [] as { year: number; weeks: { from: moment.Moment; to: moment.Moment; items: RadarEventFragmentRadarEventTimelineItemViewFragment[] }[] }[]);

    return {
      radarEventsByYearAndWeek,
    };
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(radarEvents),
  ]);

  if (loading && !radarEventsByYearAndWeek.length) {
    return <ActivityTabContentSkeleton />;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
      }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
            }}
          >
            {radarEventsByYearAndWeek.map(({ year, weeks }, index) => {
              return (
                <Box
                  key={year}
                  sx={{
                    borderRadius: 6,
                    border: `1px solid ${theme.colors?.utility[300]}`,
                    p: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 4,
                    bgcolor: theme.colors?.primary.white,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="headline-md"
                      color={theme.colors?.utility[700]}
                    >
                      {year}
                    </Typography>
                    {index === 0 && (
                      <SocialListeningRadarAddActivityButtonView
                        brandId={creatorProfile.brandId}
                        creatorProfileIds={[creatorProfile.id]}
                        onActivityAdded={onActivityAdded}
                        renderBtn={
                          <Button
                            variant="primary-alt"
                            startIcon={<IconLinearAdd size={16} />}
                          >
                            <Typography variant="headline-sm">
                              Add activity
                            </Typography>
                          </Button>
                        }
                      />
                    )}
                  </Box>
                  {weeks.map(({ from, to, items }, index) => {
                    const isCurrentWeek = moment().isBetween(from, to);

                    return (
                      <CollapsibleSection
                        key={index}
                        renderHeaderTitle={() => {
                          return (
                            <Box
                              role="button"
                              sx={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                width: '100%',
                              }}
                            >
                              <Chip
                                variant="filled-borderless"
                                label={
                                  isCurrentWeek
                                    ? 'This week'
                                    : `${from.format('MMM D')} - ${to.format(
                                        'MMM D',
                                      )}`
                                }
                              />
                              <Box
                                sx={{
                                  flex: 1,
                                  height: '1px',
                                  background: theme.colors?.utility[400],
                                }}
                              />
                            </Box>
                          );
                        }}
                        divider={false}
                        defaultIsOpen
                        iconColor={theme.colors?.utility[600]}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 6,
                            py: 6,
                          }}
                        >
                          {items.map((radarEvent) => {
                            return (
                              <RadarEventTimelineItemView
                                key={radarEvent.id}
                                radarEvent={radarEvent}
                              />
                            );
                          })}
                        </Box>
                      </CollapsibleSection>
                    );
                  })}
                </Box>
              );
            })}
            <InfiniteScrollBoundary
              disabled={!hasNextPage || loading}
              onVisible={fetchMoreRadarEvents}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <SocialListeningCreatorProfileDetailsView
            creatorProfile={creatorProfile}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
