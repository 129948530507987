import { Box, Chip, Typography } from '@mui/material';
import { useSocialListeningRadarActivityCommunicationChannelHandlers } from 'features/socialListeningRadar/hooks';
import {
  useGetCommunicationChannelByIdsForSlRadarActivityCommunicationChannelHandlersLazyQuery,
  useGetCommunicationChannelForSlRadarActivityCommunicationChannelHandlersQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  CommunicationChannelOptionType,
  SocialListeningRadarMultiSelectLabel,
} from '../multiSelectLabel';

interface SocialListeningRadarCommunicationChannelSelectorProps {
  selectedCommunicationChannelIds: string[];
  onChange: (ids: string[]) => void;
  onDelete: (id: string) => void;
}

export const SocialListeningRadarCommunicationChannelSelector = ({
  selectedCommunicationChannelIds,
  onChange,
  onDelete,
}: SocialListeningRadarCommunicationChannelSelectorProps) => {
  const { onCreateCommunicationChannel } =
    useSocialListeningRadarActivityCommunicationChannelHandlers();
  const [channelSearch, setChannelSearch] = useState('');
  // this is for the new communication channel options that are created by the user
  const [newCommunicationChannelOptions, setNewCommunicationChannelOptions] =
    useState<CommunicationChannelOptionType[]>([]);
  const [
    selectedCommunicationChannelOptions,
    setSelectedCommunicationChannelOptions,
  ] = useState<CommunicationChannelOptionType[]>([]);

  const debouncedSetChannelSearch = debounce((newQuery) => {
    setChannelSearch(newQuery);
  }, 250);

  const [getCommunicationChannelByIds] =
    useGetCommunicationChannelByIdsForSlRadarActivityCommunicationChannelHandlersLazyQuery(
      {
        fetchPolicy: 'cache-and-network',
      },
    );

  const communicationChannelVariables = useMemo(() => {
    return {
      search: channelSearch,
      take: 10,
    };
  }, [channelSearch]);

  const {
    data: communicationChannelData,
    fetchMore: fetchMoreCommunicationChannelData,
  } =
    useGetCommunicationChannelForSlRadarActivityCommunicationChannelHandlersQuery(
      {
        variables: {
          input: communicationChannelVariables,
        },
      },
    );

  useEffect(() => {
    const uniqueIds = selectedCommunicationChannelIds.filter(
      (id) => !selectedCommunicationChannelOptions.some((c) => c.id === id),
    );

    if (uniqueIds.length > 0) {
      getCommunicationChannelByIds({
        variables: {
          input: { communicationChannelIds: uniqueIds },
        },
        onCompleted: (data) => {
          setSelectedCommunicationChannelOptions((prev) => [
            ...prev,
            ...data.radarEventActivityCommunicationChannelByIds,
          ]);
        },
      });
    }
  }, [
    selectedCommunicationChannelIds,
    selectedCommunicationChannelOptions,
    getCommunicationChannelByIds,
  ]);

  const options = useMemo(() => {
    return [
      ...newCommunicationChannelOptions,
      ...(communicationChannelData
        ?.paginatedRadarEventActivityCommunicationChannels.data ?? []),
    ].filter(
      (channel, index, self) =>
        self.findIndex((c) => c.id === channel.id) === index,
    );
  }, [newCommunicationChannelOptions, communicationChannelData]);

  return (
    <SocialListeningRadarMultiSelectLabel
      values={selectedCommunicationChannelIds}
      onChange={(value) => {
        onChange(value);
        setSelectedCommunicationChannelOptions([
          ...options.filter((o) => value.includes(o.id)),
        ]);
      }}
      onSetSearch={debouncedSetChannelSearch}
      fetchMoreItems={() => {
        if (
          communicationChannelData
            ?.paginatedRadarEventActivityCommunicationChannels.pageInfo
            .hasNextPage
        ) {
          fetchMoreCommunicationChannelData({
            variables: {
              input: {
                ...communicationChannelVariables,
                after:
                  communicationChannelData
                    ?.paginatedRadarEventActivityCommunicationChannels.pageInfo
                    .endCursor,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                ...prev,
                paginatedRadarEventActivityCommunicationChannels: {
                  ...prev.paginatedRadarEventActivityCommunicationChannels,
                  data: [
                    ...prev.paginatedRadarEventActivityCommunicationChannels
                      .data,
                    ...fetchMoreResult
                      .paginatedRadarEventActivityCommunicationChannels.data,
                  ].filter(
                    (channel, index, self) =>
                      self.findIndex((c) => c.id === channel.id) === index,
                  ),
                  pageInfo: {
                    ...prev.paginatedRadarEventActivityCommunicationChannels
                      .pageInfo,
                    ...fetchMoreResult
                      .paginatedRadarEventActivityCommunicationChannels
                      .pageInfo,
                  },
                },
              };
            },
          });
        }
      }}
      options={options}
      onCreate={async (option) => {
        const res = await onCreateCommunicationChannel({
          name: option.name,
          color: option.color,
          bgColor: option.bgColor,
        });
        const id = res.data?.createRadarEventActivityCommunicationChannel.id;
        if (id) {
          setNewCommunicationChannelOptions((prev) => [
            {
              ...option,
              id,
            },
            ...prev,
          ]);
          onChange([id, ...selectedCommunicationChannelIds]);
        }
      }}
      renderTrigger={() => {
        if (selectedCommunicationChannelIds.length === 0) {
          return (
            <Typography variant="subhead-lg" color={theme.colors?.utility[600]}>
              Add channel
            </Typography>
          );
        }

        return (
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexWrap: 'wrap',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {selectedCommunicationChannelOptions.map((option) => {
              return (
                <Chip
                  sx={{
                    borderRadius: 1,
                    color: option.color,
                    backgroundColor: option.bgColor,
                    height: 26,
                    '.MuiChip-label': {
                      filter: 'none !important',
                      color: option.color,
                      ...theme.typography['headline-xs'],
                    },
                    '& svg': {
                      pl: 0.5,
                    },
                  }}
                  label={option.name}
                  onDelete={() => {
                    onDelete(option.id);
                    setSelectedCommunicationChannelOptions((prev) =>
                      prev.filter((c) => c.id !== option.id),
                    );
                  }}
                  variant="filled-borderless-color-dodge"
                />
              );
            })}
          </Box>
        );
      }}
    />
  );
};
