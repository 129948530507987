import { gql } from '@apollo/client';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'components/common/Toast';
import { typography } from 'components/common/Typography/styles';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearEdit } from 'components/icons/components/linear/IconLinearEdit';
import { IconLinearLink } from 'components/icons/components/linear/IconLinearLink';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearTrash } from 'components/icons/components/linear/IconLinearTrash';
import { IconOutlineCrossXClose } from 'components/icons/components/outline/IconOutlineCrossXClose';
import {
  PaginatedCreatorProfileInput,
  SavedConfigFragmentSavedViewsMenuFragmentDoc,
  SavedConfigType,
  useCreateSavedConfigForSavedMenuViewsMutation,
  useDeleteSavedConfigForSavedMenuViewsMutation,
  useGetSavedConfigsByTypeForSavedMenuViewsQuery,
  useUpdateSavedConfigForSavedMenuViewsMutation,
} from 'graphql/generated';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SavedConfigFragmentSavedViewsMenu on SavedConfigModel {
    id
    name
    type
    config
    brandId
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSavedConfigsByTypeForSavedMenuViews($input: ListSavedConfigsInput!) {
    getSavedConfigsByType(input: $input) {
      id
      ...SavedConfigFragmentSavedViewsMenu
    }
  }
  ${SavedConfigFragmentSavedViewsMenuFragmentDoc}
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation CreateSavedConfigForSavedMenuViews($input: CreateSavedConfigInput!) {
    createSavedConfig(input: $input) {
      success
      message
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateSavedConfigForSavedMenuViews($input: UpdateSavedConfigInput!) {
    updateSavedConfig(input: $input) {
      success
      message
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation DeleteSavedConfigForSavedMenuViews($input: DeleteSavedConfigInput!) {
    deleteSavedConfig(input: $input) {
      success
      message
    }
  }
`;

type SaveViewsMenuProps = {
  brandId: string;
  configForSaving?: PaginatedCreatorProfileInput;
  onApplyConfig: (config: PaginatedCreatorProfileInput) => void;
};

export const SaveViewsMenu = ({
  brandId,
  configForSaving,
  onApplyConfig,
}: SaveViewsMenuProps) => {
  const {
    data: savedConfigs,
    loading: savedConfigsLoading,
    refetch: refetchSavedConfigs,
  } = useGetSavedConfigsByTypeForSavedMenuViewsQuery({
    variables: {
      input: {
        type: SavedConfigType.CreatorProfileFiltersConfig,
        brandId,
      },
    },
  });

  const [createSavedConfig] = useCreateSavedConfigForSavedMenuViewsMutation();
  const [updateSavedConfig] = useUpdateSavedConfigForSavedMenuViewsMutation();
  const [deleteSavedConfig] = useDeleteSavedConfigForSavedMenuViewsMutation();

  const [viewMenuAnchorEl, setViewMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const [selectedView, setSelectedView] = useState<string | null>(null);
  const [showSaveForm, setShowSaveForm] = useState(false);
  const [showRenameForm, setShowRenameForm] = useState(false);
  const [title, setTitle] = useState('');
  const [renameTitle, setRenameTitle] = useState('');
  const [error, setError] = useState('');

  const {
    dialog: deleteConfirmationDialog,
    onOpen: openDeleteConfirmationDialog,
  } = useConfirmationDialog();

  const handleViewMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    viewId: string,
  ) => {
    event.stopPropagation();
    setViewMenuAnchorEl(event.currentTarget);
    setSelectedView(viewId);
  };

  const handleViewMenuClose = () => {
    setViewMenuAnchorEl(null);
    setSelectedView(null);
    setShowRenameForm(false);
    setError('');
  };

  const handleCopyLink = () => {
    const selectedConfig = savedConfigs?.getSavedConfigsByType.find(
      (config) => config.id === selectedView,
    );
    if (selectedConfig) {
      const configString = window.btoa(selectedConfig.config);
      const url = new URL(window.location.href);
      url.searchParams.set('config', configString);
      navigator.clipboard.writeText(url.toString());
      toast({
        message: 'Link copied to clipboard',
        type: 'success',
      });
    }
    handleViewMenuClose();
  };

  const handleRename = () => {
    const selectedConfig = savedConfigs?.getSavedConfigsByType.find(
      (config) => config.id === selectedView,
    );
    if (selectedConfig) {
      setRenameTitle(selectedConfig.name);
      setShowRenameForm(true);
    }
  };

  const handleRenameSubmit = async () => {
    if (!renameTitle.trim()) {
      setError('Name cannot be empty');
      return;
    }

    const isDuplicate = savedConfigs?.getSavedConfigsByType.some(
      (config) => config.name === renameTitle && config.id !== selectedView,
    );

    if (isDuplicate) {
      setError('A view with this name already exists');
      return;
    }

    await updateSavedConfig({
      variables: {
        input: {
          id: selectedView as string,
          name: renameTitle,
          config: JSON.stringify(configForSaving),
        },
      },
    });
    refetchSavedConfigs();
    handleViewMenuClose();
  };

  const handleDelete = () => {
    const selectedConfig = savedConfigs?.getSavedConfigsByType.find(
      (config) => config.id === selectedView,
    );

    if (!selectedConfig) return;

    openDeleteConfirmationDialog({
      title: (
        <>
          Would you like to delete <b>{selectedConfig.name}</b>?
        </>
      ),
      subtitle: 'Once you delete, this view will no longer be accessible.',
      onConfirm: async () => {
        await deleteSavedConfig({
          variables: {
            input: {
              id: selectedView as string,
            },
          },
        });
        refetchSavedConfigs();
        handleViewMenuClose();
      },
    });
  };

  const handleSaveClick = async () => {
    if (!configForSaving) {
      return;
    }

    if (!title.trim()) {
      setError('Name cannot be empty');
      return;
    }

    const isDuplicate = savedConfigs?.getSavedConfigsByType.some(
      (config) => config.name === title,
    );

    if (isDuplicate) {
      setError('A view with this name already exists');
      return;
    }

    await createSavedConfig({
      variables: {
        input: {
          type: SavedConfigType.CreatorProfileFiltersConfig,
          brandId,
          config: JSON.stringify(configForSaving),
          name: title,
        },
      },
    });
    refetchSavedConfigs();
    onApplyConfig(configForSaving);
    setShowSaveForm(false);
    setTitle('');
    setError('');
  };

  return (
    <>
      <List>
        <ListItem>
          <Typography
            variant="body-lg"
            fontWeight={600}
            sx={{
              color: theme.colors?.utility[700],
            }}
          >
            Views
          </Typography>
        </ListItem>
        {savedConfigs?.getSavedConfigsByType.map((config) => (
          <ListItem
            key={config.id}
            sx={{
              py: 3,
              my: 1,
              borderRadius: 3,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: theme.colors?.utility[250],
              },
            }}
            onClick={() => {
              onApplyConfig(JSON.parse(config.config));
            }}
            secondaryAction={
              <IconButton
                edge="end"
                size="small"
                onClick={(e) => handleViewMenuClick(e, config.id)}
              >
                <IconLinearMore />
              </IconButton>
            }
          >
            <Typography
              variant="body-lg"
              sx={{
                color: theme.colors?.utility[1100],
              }}
            >
              {config.name}
            </Typography>
          </ListItem>
        ))}
        {!showSaveForm && (
          <ListItem>
            <Button
              fullWidth
              onClick={() => setShowSaveForm(true)}
              sx={{
                justifyContent: 'flex-start',
                borderRadius: 2,
                backgroundColor: 'rgba(35, 6, 3, 0.10)',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                '&:hover': {
                  backgroundColor: 'rgba(35, 6, 3, 0.10)',
                },
              }}
            >
              <IconLinearAdd size={16} />
              <Typography fontWeight={500} variant="body-xl">
                Save this configuration
              </Typography>
            </Button>
          </ListItem>
        )}
        {showSaveForm && (
          <ListItem
            sx={{ flexDirection: 'column', alignItems: 'stretch', gap: 1 }}
          >
            <Box
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}
            >
              <Typography
                variant="body-lg"
                sx={{
                  color: theme.colors?.utility[700],
                  fontWeight: 600,
                  mb: 4,
                }}
              >
                Create a view
              </Typography>
              <TextField
                placeholder="Name the view"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                error={!!error}
                helperText={error}
                sx={{
                  my: theme.spacing(1),
                  width: '100%',
                  borderRadius: theme.spacing(2),
                  backgroundColor: theme.colors?.utility[300],
                  '& .MuiOutlinedInput-root': {
                    borderRadius: `${theme.spacing(2)} !important`,
                    backgroundColor: theme.colors?.utility[300],
                    border: `1px solid ${theme.colors?.utility[400]}`,
                  },
                  '& .MuiInputBase-input': {
                    ...typography['body-lg'],
                    borderRadius: `${theme.spacing(2)} !important`,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {},
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 1,
                  mt: 3,
                }}
              >
                <Button
                  onClick={() => {
                    setShowSaveForm(false);
                    setError('');
                  }}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary-alt"
                  onClick={handleSaveClick}
                  sx={{
                    textTransform: 'none',
                    borderRadius: theme.spacing(5),
                  }}
                  disabled={!title.trim()}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </ListItem>
        )}
      </List>

      <Menu
        anchorEl={viewMenuAnchorEl}
        open={Boolean(viewMenuAnchorEl)}
        onClose={handleViewMenuClose}
        PaperProps={{
          sx: {
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(20px)',
            p: 3,
            borderRadius: 3,
            boxShadow:
              'box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12); box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12);',
          },
        }}
      >
        {!showRenameForm ? (
          <>
            <MenuItem
              onClick={handleCopyLink}
              sx={{
                my: 2,
                minWidth: theme.spacing(60),
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <IconLinearLink size={16} />
              <Typography
                variant="body-md"
                color={theme.colors?.utility[900]}
                fontWeight={600}
              >
                Copy Link
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={handleRename}
              sx={{
                my: 2,
                minWidth: theme.spacing(60),
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <IconLinearEdit size={16} />
              <Typography
                variant="body-md"
                color={theme.colors?.utility[900]}
                fontWeight={600}
              >
                Rename View
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={handleDelete}
              sx={{
                my: 2,
                minWidth: theme.spacing(60),
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <IconLinearTrash size={16} />
              <Typography
                variant="body-md"
                color={theme.colors?.utility[900]}
                fontWeight={600}
              >
                Delete View
              </Typography>
            </MenuItem>
          </>
        ) : (
          <Box sx={{ p: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              autoFocus
              value={renameTitle}
              onChange={(e) => setRenameTitle(e.target.value)}
              error={!!error}
              helperText={error}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(2),
                },
              }}
            />
            <IconButton
              onClick={() => {
                setShowRenameForm(false);
                setError('');
              }}
              size="small"
            >
              <IconOutlineCrossXClose />
            </IconButton>
            <IconButton
              onClick={handleRenameSubmit}
              size="small"
              disabled={!renameTitle.trim()}
            >
              <IconBoldTickCircle />
            </IconButton>
          </Box>
        )}
      </Menu>

      {deleteConfirmationDialog}
    </>
  );
};
