import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, Input, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconLinearMoreVert } from 'components/icons/components/linear/IconLinearMoreVert';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { bookmarkOptions } from 'features/bookmark';
import {
  NestedFiltersDefaultContextProvider,
  NestedFiltersSelectedChips,
} from 'features/nestedFilters';
import {
  SocialListeningCommunityInfoView,
  SocialListeningCommunityTableView,
  useSocialListeningCommunityBookmarkHandlers,
} from 'features/socialListeningCommunity';
import {
  SocialListeningFeatureType,
  SocialMediaListeningTopHeaderBreadcrumbs,
  useBrandDetails,
} from 'features/socialMediaListening';
import {
  PaginatedCreatorProfileInput,
  PlotFeature,
  ViewEntityType,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { usePageViewEntity } from 'hooks/useViewEntity';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { Filters, NestedFiltersValues } from './Filters';

export const SocialMediaListeningCommunityPage = () => {
  const { brandId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const { user } = useUserContext();

  usePageViewEntity(brandId, ViewEntityType.BrandCommunityHub);

  const [rawFilters, setRawFilters] = useState<NestedFiltersValues>();
  const [filters, setFilters] = useState<Partial<PaginatedCreatorProfileInput>>(
    {},
  );
  const [searchTerm, setSearchTerm] = useState('');

  const [configForSaving, setConfigForSaving] = useState<
    PaginatedCreatorProfileInput | undefined
  >();

  // this is used for controlling to show search input or not
  const searchExpand = useDisclosure();
  const debouncedSearchTerm = debounce((value) => setSearchTerm(value), 300);

  const {
    setRenderTitle,
    setHeaderSx,
    addRenderMenuExtraLeft,
    renderMenuExtraLeft,
    removeRenderMenuExtraLeft,
  } = useCustomHeaderContext();
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  const { brand } = useBrandDetails({ brandId });

  const { onBookmarkCommunityAnalytics, onRemoveCommunityAnalyticsBookmark } =
    useSocialListeningCommunityBookmarkHandlers();

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.SocialListeningCrm,
      );
      if (!_isFeatureEnabled) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line

  useEffect(() => {
    analytics.track(EventName.CrmDashboardViewed, {
      brandId,
      userId: user?.id,
    });
  }, [brandId]); // eslint-disable-line

  useEffect(() => {
    setHeaderSx({
      borderBottom: '1px solid rgba(35, 6, 3, 0.05)',
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    if (brand) {
      const isBookmarked = brand.bookmarkState.communityHub;
      addRenderMenuExtraLeft(
        () => (
          <ContextMenu
            renderButton={() => (
              <IconButton sx={{ p: 1 }}>
                <IconLinearMoreVert color={theme.colors?.primary.black} />
              </IconButton>
            )}
            options={bookmarkOptions({
              isBookmarked,
              onToggleBookmark: () => {
                if (isBookmarked) {
                  onRemoveCommunityAnalyticsBookmark(brand.id);
                } else {
                  onBookmarkCommunityAnalytics(brand.id);
                }
              },
            })}
          />
        ),
        1,
      );
    }

    setRenderTitle(() => (
      <Box display="flex" alignItems="center" gap={3}>
        <SocialMediaListeningTopHeaderBreadcrumbs
          brandId={brandId}
          featureType={SocialListeningFeatureType.Community}
          componentProps={{
            brandSelector: {
              generateUrl: (brandId) =>
                PlotRoutes.socialListeningCommunity(brandId),
            },
          }}
        />
      </Box>
    ));

    return () => {
      setHeaderSx({});
      setRenderTitle(() => null);
      renderMenuExtraLeft?.map((render, index) =>
        // eslint-disable-next-line
        removeRenderMenuExtraLeft(() => <>{render ?? ''}</>, index),
      );
    };
  }, [location.pathname, brand, JSON.stringify(renderMenuExtraLeft)]); // eslint-disable-line

  return (
    <Box mt={18} bgcolor={theme.colors?.utility[100]}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          pt: 4,
          pb: 6,
          px: 8,
          borderBottom: '1px solid rgba(35, 6, 3, 0.05)',
          position: 'sticky',
          top: 64,
          zIndex: 10,
          bgcolor: theme.colors?.utility[100],
          '@media (max-height: 550px)': {
            position: 'relative',
            top: 0,
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="headline-xl"
            sx={{
              fontSize: 32,
              letterSpacing: '-1.28px',
            }}
          >
            Your Community 🫶
          </Typography>
          <Box
            marginLeft="auto"
            display="flex"
            alignItems="center"
            columnGap={2}
          >
            {brand && (
              <Box
                borderRadius={10}
                bgcolor={theme.colors?.utility[275]}
                padding={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: 'center',
                }}
                component="button"
                onClick={() => {
                  navigate(
                    PlotRoutes.socialListeningSettings({
                      brandId: brand.id,
                    }),
                  );
                }}
              >
                <IconBoldSettings2
                  size={22}
                  color={theme.colors?.utility[600]}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 8,
          py: 6,
          background: theme.colors?.utility[200],
          borderBottom: '1px solid rgba(35, 6, 3, 0.05)',
        }}
      >
        <SocialListeningCommunityInfoView brandId={brandId} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, py: 6 }}>
        <NestedFiltersDefaultContextProvider>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 3,
              px: 8,
            }}
          >
            {searchExpand.isOpen ? (
              <Input
                disableUnderline
                fullWidth
                placeholder="Search"
                autoFocus
                onChange={(e) => {
                  debouncedSearchTerm(e.target.value);
                }}
                startAdornment={
                  <IconOutlineSearchNormal1
                    size={16}
                    style={{
                      marginRight: theme.spacing(2),
                      color: theme.colors?.utility[700],
                    }}
                  />
                }
                endAdornment={
                  <IconBoldCloseCircle
                    size={20}
                    style={{
                      color: theme.colors?.utility[700],
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      searchExpand.onClose();
                      setSearchTerm('');
                    }}
                  />
                }
                sx={{
                  borderRadius: 25,
                  background: theme.colors?.utility[275],
                  padding: theme.spacing(1, 4),
                  ...theme.typography['subhead-xl'],
                }}
              />
            ) : (
              <IconButton
                sx={{
                  borderRadius: 25,
                  background: theme.colors?.utility[275],
                  width: 40,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 0,
                }}
                onClick={searchExpand.onOpen}
              >
                <IconOutlineSearchNormal1 size={16} />
              </IconButton>
            )}
            <Filters
              configForSaving={configForSaving}
              setFilters={(values) =>
                setFilters((o) => ({
                  ...o,
                  ...values,
                }))
              }
              brandId={brandId}
              setRawFilters={setRawFilters}
              setFiltersFromSavedConfig={(config) => {
                setFilters(config);
              }}
            />
          </Box>
          <NestedFiltersSelectedChips
            sx={{
              pt: 4,
              px: 8,
            }}
          />
        </NestedFiltersDefaultContextProvider>
        <SocialListeningCommunityTableView
          configForSaving={configForSaving}
          setConfigForSaving={setConfigForSaving}
          brandId={brandId}
          filters={{
            ...filters,
            dateRange: filters.dateRange!, // force existing here because the value will always be there. See Filters.tsx
            query: searchTerm,
            brandId,
          }}
          rawFilters={rawFilters}
        />
      </Box>
    </Box>
  );
};
