import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { HighlightableSection } from 'components/common/HighlightableSection';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileSuperfanEngagementViewSkeleton =
  () => {
    const stats = [
      { label: 'Number of comments' },
      { label: 'Total engagement' },
      { label: 'Max likes' },
      { label: 'Overall sentiment' },
    ];

    return (
      <HighlightableSection
        sectionKey="superfan-engagement"
        sx={{
          p: 6,
          borderRadius: 6,
          border: `1px solid ${theme.colors?.utility[300]}`,
          backgroundColor: theme.colors?.primary.white,
          boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            display="flex"
            alignItems="center"
            gap={1}
          >
            Superfan engagement
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Skeleton variant="text" width={100} />
            <IconLinearSort size={16} />
          </Box>
        </Box>

        <Box
          sx={{
            background: theme.colors?.utility[275],
            borderRadius: 3,
            display: 'flex',
            flexWrap: 'wrap',
            p: 4,
            gap: 4,
          }}
        >
          {stats.map((stat, index) => (
            <Box
              key={index}
              sx={{
                [theme.breakpoints.up('md')]: {
                  borderRight:
                    index + 1 !== stats.length
                      ? `1px solid ${theme.colors?.utility[400]}`
                      : 'none',
                },
                flex: 1,
                minWidth: 140,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 3,
                }}
              >
                <Typography
                  variant="headline-xxs"
                  color={theme.colors?.utility[800]}
                >
                  {stat.label}
                </Typography>
                <Skeleton
                  variant="text"
                  width={stat.label === 'Overall sentiment' ? 120 : 50}
                  height={30}
                />
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            maxHeight: 500,
            overflowY: 'auto',
          }}
        >
          <Grid container spacing={6}>
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <Grid item xs={12} sm={6} md={6} xl={4} key={index}>
                <Box
                  sx={{
                    p: 4,
                    borderRadius: 3,
                    border: `1px solid ${theme.colors?.utility[300]}`,
                  }}
                >
                  <Box
                    sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
                  >
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                      <Skeleton variant="circular" width={40} height={40} />
                      <Box sx={{ flex: 1 }}>
                        <Skeleton variant="text" width="60%" />
                        <Skeleton variant="text" width="40%" />
                      </Box>
                    </Box>
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="90%" />
                    <Box sx={{ display: 'flex', gap: 4 }}>
                      <Skeleton variant="text" width={60} />
                      <Skeleton variant="text" width={60} />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </HighlightableSection>
    );
  };
