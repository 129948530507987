import { gql } from '@apollo/client';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearChart2 } from 'components/icons/components/linear/IconLinearChart2';
import { IconLinearHeart } from 'components/icons/components/linear/IconLinearHeart';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlinePlay } from 'components/icons/components/outline/IconOutlinePlay';
import { SocialPostCardView } from 'features/socialPost';
import {
  PaginatedBrandInboundFiltersInputForCapturedProductSortField,
  SocialPostFragmentSlaRankingProductSocialPostsFragment,
  SocialPostFragmentSocialPostCardViewFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSLARankingProductSocialPosts on SocialPostModel {
    ...SocialPostFragmentSocialPostCardView
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

interface SLARankingProductSocialPostsProps {
  socialPosts: SocialPostFragmentSlaRankingProductSocialPostsFragment[];
  onPostClick: (postId: string) => void;
  selectedSortKey?: PaginatedBrandInboundFiltersInputForCapturedProductSortField;
  setSelectedSortKey: (
    sortKey: PaginatedBrandInboundFiltersInputForCapturedProductSortField,
  ) => void;
}

export const SLARankingProductSocialPosts = ({
  socialPosts,
  onPostClick,
  selectedSortKey,
  setSelectedSortKey,
}: SLARankingProductSocialPostsProps) => {
  const sortOptions = [
    {
      label: 'Total engagement',
      value:
        PaginatedBrandInboundFiltersInputForCapturedProductSortField.TotalEngagement,
      icon: IconLinearChart2,
    },
    {
      label: 'Play count',
      value:
        PaginatedBrandInboundFiltersInputForCapturedProductSortField.TotalImpressions,
      icon: IconOutlinePlay,
    },
    {
      label: 'Like count',
      value:
        PaginatedBrandInboundFiltersInputForCapturedProductSortField.DiggCount,
      icon: IconLinearHeart,
    },
  ];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        m={4}
        mb={2}
      >
        <Typography variant="headline-md" fontSize={20}>
          Posts
        </Typography>
        <ContextMenu
          options={sortOptions.map((option) => ({
            renderOption: () => (
              <Box display="flex" alignItems="center" gap={2}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 1,
                    background: 'rgba(35, 6, 3, 0.05)',
                  }}
                >
                  <option.icon size={16} color={theme.colors?.primary.black} />
                </Box>
                <Typography variant="subhead-lg">{option.label}</Typography>
              </Box>
            ),
            onClick: () => {
              setSelectedSortKey(option.value);
            },
          }))}
          renderButton={() => (
            <Button
              variant="tertiary"
              sx={{
                backgroundColor: theme.colors?.primary.parchment,
                borderRadius: theme.spacing(2),
                gap: 2,
                px: 2,
                '&:hover': {
                  backgroundColor: theme.colors?.primary.parchment,
                },
              }}
              disableRipple={false}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: 'rgba(35, 6, 3, 0.05)',
                  p: theme.spacing(1),
                  borderRadius: theme.spacing(1),
                }}
              >
                <IconLinearSort size={16} />
              </Box>
              <Typography variant="subhead-lg">
                {selectedSortKey
                  ? sortOptions.find(
                      (option) => option.value === selectedSortKey,
                    )?.label
                  : 'Sort by'}
              </Typography>
            </Button>
          )}
        />
      </Box>
      <Box sx={{ m: 4 }}>
        {!socialPosts?.length ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="body-xl"
              fontWeight={600}
              color={theme.colors?.utility[800]}
            >
              No posts found
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={2}>
            {socialPosts?.map((post) => {
              return (
                <Grid item xs={12} sm={6} md={3} lg={2.4} key={post.id}>
                  <Box onClick={() => onPostClick(post.id)}>
                    <SocialPostCardView socialPost={post} />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
    </>
  );
};
