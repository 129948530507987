import { gql } from '@apollo/client';
import {
  BrandFragmentBrandConnectedCreatorsFragmentDoc,
  BrandFragmentForBrandCompetitorsViewFragmentDoc,
  BrandFragmentUseBrandDetailsFragment,
  BrandFragmentUseBrandDetailsFragmentDoc,
  useGetBrandsQuery,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  query GetBrands {
    brands {
      id
      ...BrandFragmentUseBrandDetails
    }
  }
  ${BrandFragmentUseBrandDetailsFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment BrandFragmentUseBrandDetails on BrandModel {
    id
    name
    bookmarkState {
      brandAnalytics
      ownedBrandAnalytics
      ugcAnalytics
      communityHub
      outbound
    }
    ...BrandFragmentBrandConnectedCreators
    ...BrandFragmentForBrandCompetitorsView
  }
  ${BrandFragmentBrandConnectedCreatorsFragmentDoc}
  ${BrandFragmentForBrandCompetitorsViewFragmentDoc}
`;

type Props = {
  brandId: string;
};

type UseBrandDetailsReturn = {
  brand?: BrandFragmentUseBrandDetailsFragment | null;
  brandNotFound: boolean;
  isLoading: boolean;
};

export const useBrandDetails = ({ brandId }: Props): UseBrandDetailsReturn => {
  const { data, loading } = useGetBrandsQuery();

  if (!loading && data?.brands.length && brandId) {
    const matchBrand: BrandFragmentUseBrandDetailsFragment | undefined =
      data.brands.find((b) => b.id === brandId);
    return {
      brand: matchBrand,
      brandNotFound: !matchBrand,
      isLoading: loading,
    };
  }

  // return the first brand if no brandId is provided
  const firstBrand = data?.brands.length ? data.brands[0] : null;

  return {
    brand: firstBrand,
    brandNotFound: true,
    isLoading: loading,
  };
};
