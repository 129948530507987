import { gql } from '@apollo/client';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Image } from 'components/common/Image';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { useUserContext } from 'contexts/users/User.context';
import { useUpdateUserOnboardingStateForSlCommunityIntroDialogViewMutation } from 'graphql/generated';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  mutation UpdateUserOnboardingStateForSLCommunityIntroDialogView(
    $data: UpdateUserOnboardingStateInput!
  ) {
    updateUserOnboardingState(data: $data) {
      id
      onboardingState {
        hasSeenSLCommunityIntroduction
      }
    }
  }
`;

interface SocialListeningCommunityIntroDialogViewProps {
  onClose: VoidFunction;
}

export const SocialListeningCommunityIntroDialogView = ({
  onClose,
}: SocialListeningCommunityIntroDialogViewProps) => {
  const { user } = useUserContext();
  const [updateUserOnboardingState] =
    useUpdateUserOnboardingStateForSlCommunityIntroDialogViewMutation();

  const firstBrand = user?.organization.socialListeningBrands?.[0];

  const onCloseModal = () => {
    updateUserOnboardingState({
      variables: {
        data: {
          onboardingState: {
            hasSeenSLCommunityIntroduction: true,
          },
        },
      },
      updateQueries: {
        GetMe: (prev) => {
          return {
            me: {
              ...prev.me,
              onboardingState: {
                ...prev.me.onboardingState,
                hasSeenSLCommunityIntroduction: true,
              },
            },
          };
        },
      },
      onCompleted(data) {
        if (data) {
          onClose();
        }
      },
    });
  };
  return (
    <Dialog
      open
      onClose={onCloseModal}
      maxWidth="lg"
      PaperProps={{
        sx: {
          borderRadius: 6,
          background: theme.colors?.utility['yellow-4'],
          boxShadow: `0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)`,
          border: 'none',
          p: 12.5,
          gap: 9,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          alignItems: 'center',
        },
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 12,
          right: 16,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton
            onClick={onCloseModal}
            sx={{
              opacity: 0.7,
            }}
          >
            <IconBoldCloseCircle
              size={32}
              color={theme.colors?.primary.parchment}
            />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 480,
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Typography
            variant="headline-xl"
            fontSize={28}
            fontWeight={500}
            letterSpacing="-1.12px"
            color={theme.colors?.primary.parchment}
            textAlign="center"
          >
            Community Hub is now live!
          </Typography>
          <Typography
            variant="headline-md"
            color={theme.colors?.primary.parchment}
            textAlign="center"
          >
            Turn your fans into superfans—all your community data in one place
            so you can create surprise and delight moments that strengthen
            relationships.
          </Typography>
        </Box>
      </Box>
      <Image
        src="/gif/community-intro.gif"
        sx={{
          maxWidth: 560,
          borderRadius: 4,
        }}
      />
      <Link
        to={
          firstBrand
            ? PlotRoutes.socialListeningCommunity(firstBrand.id)
            : PlotRoutes.socialListeningCommunityIntro()
        }
      >
        <Button
          variant="primary-reverse"
          sx={{
            color: theme.colors?.primary.black,
            borderRadius: 2,
          }}
          onClick={onCloseModal}
        >
          Explore
        </Button>
      </Link>
    </Dialog>
  );
};
