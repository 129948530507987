import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconLinearExport3 } from 'components/icons/components/linear/IconLinearExport3';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineSetting2 } from 'components/icons/components/outline/IconOutlineSetting2';
import { useUserContext } from 'contexts/users/User.context';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

type Props = {
  generateUrl: (brandId: string) => string;
};

export const SLPageBrandSelector = ({ generateUrl }: Props) => {
  const { brandId = '' } = useParams();
  const navigate = useGuardNavigate();
  const { user } = useUserContext();

  const slBrands = useMemo(() => user?.socialListeningBrands || [], [user]);

  const currentBrand = useMemo(() => {
    const brand = slBrands.find((brand) => brand.id === brandId);
    if (!brand && slBrands.length) {
      return slBrands[0];
    }
    return brand;
  }, [brandId, slBrands]);

  if (!currentBrand) {
    return null;
  }

  return (
    <Box>
      <ContextMenu
        renderButton={(isOpen) => (
          <IconButton
            sx={{
              ...theme.typography['headline-sm'],
              color: theme.colors?.utility[700],
              borderRadius: 2,
              backgroundColor: !isOpen ? 'transparent' : 'rgba(35, 6, 3, 0.05)',
              p: theme.spacing(1),
            }}
          >
            {currentBrand.name}
          </IconButton>
        )}
        options={(user?.socialListeningBrands || []).map((brand) => ({
          renderCustomComponent: () => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderRadius: 2,
                cursor: 'pointer',
                ':hover': {
                  background: 'rgba(35, 6, 3, 0.05)',
                  '.more-button': {
                    opacity: 1,
                  },
                },
              }}
              onClick={() => {
                navigate(generateUrl(brand.id));
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                }}
              >
                <Avatar
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  src={
                    brand.creators[0]?.profilePictureUrl ||
                    brand.creators[1]?.profilePictureUrl ||
                    ''
                  }
                />
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[800]}
                  fontWeight={600}
                  flex={1}
                  minWidth={theme.spacing(40)}
                >
                  {brand.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  opacity: 0,
                }}
                className="more-button"
              >
                <ContextMenu
                  disablePortal
                  options={[
                    {
                      renderOption: () => (
                        <Typography
                          width="100%"
                          variant="body-lg"
                          fontWeight={600}
                          color={theme.colors?.utility[700]}
                          display="flex"
                          alignItems="center"
                          gap={2}
                        >
                          <IconOutlineSetting2 size={16} />
                          Settings
                        </Typography>
                      ),
                      onClick: () => {
                        navigate(
                          PlotRoutes.socialListeningSettings({
                            brandId: brand.id,
                          }),
                        );
                      },
                    },
                    {
                      renderOption: () => (
                        <Box onClick={(e) => e.stopPropagation()} width="100%">
                          <SocialListeningUserPermissionsView
                            brandId={brand.id}
                            renderCustomBtn={() => (
                              <Typography
                                width="100%"
                                variant="body-lg"
                                fontWeight={600}
                                color={theme.colors?.utility[700]}
                                display="flex"
                                alignItems="center"
                                gap={2}
                              >
                                <IconLinearExport3 size={16} />
                                Share
                              </Typography>
                            )}
                          />
                        </Box>
                      ),
                    },
                  ]}
                  renderButton={() => (
                    <IconLinearMore
                      color={theme.colors?.utility[600]}
                      size={20}
                    />
                  )}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 50,
                  }}
                />
              </Box>
            </Box>
          ),
        }))}
        PaperProps={{
          style: {
            minWidth: 266,
            backdropFilter: 'none',
            backgroundColor: theme.colors?.primary.white,
          },
          sx: {
            p: 2,
            ul: {
              gap: `${theme.spacing(2)} !important`,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      />
    </Box>
  );
};
