import { Box, Button, SxProps, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import React, { useEffect, useRef, useState } from 'react';

type Props = {
  text: string | React.ReactNode;
  collapsedHeight: number;
  componentProps?: {
    container?: {
      sx: SxProps;
    };
    text?: {
      sx: SxProps;
    };
    seeMore?: {
      label?: string;
      sx?: SxProps;
    };
    seeLess?: {
      label?: string;
      sx?: SxProps;
    };
  };
};

export const ExpandableText = ({
  collapsedHeight,
  componentProps,
  text,
}: Props) => {
  const [isOverflowingText, setIsOverflowingText] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const containerRef = useRef<HTMLElement | null>(null);
  const textRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current && containerRef.current) {
        const textHeight = textRef.current.getBoundingClientRect().height;
        const containerHeight =
          containerRef.current.getBoundingClientRect().height;
        const isOverflowing = textHeight > containerHeight;
        setIsOverflowingText(isOverflowing);
      }
    };

    // Check immediately
    checkOverflow();

    // Add a small delay to check again after content is fully rendered
    const timeoutId = setTimeout(checkOverflow, 100);

    // Optional: Add resize listener to handle window size changes
    window.addEventListener('resize', checkOverflow);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', checkOverflow);
    };
  }, [text, collapsedHeight]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      position="relative"
    >
      <Box
        sx={{
          ...(componentProps?.container?.sx || {}),
          maxHeight: isExpanded ? 'none' : `${collapsedHeight}px`,
          overflow: 'hidden',
        }}
        ref={containerRef}
      >
        <Typography
          ref={textRef}
          display="block"
          variant="body-lg"
          fontWeight={600}
          sx={{
            wordWrap: 'break-word',
            overflowWrap: 'break-word',
            ...(componentProps?.text?.sx || {}),
          }}
        >
          {text}
        </Typography>
      </Box>
      {isOverflowingText && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsExpanded(!isExpanded);
          }}
          sx={{
            p: 0,
            minWidth: 0,
            ...typography['body-lg'],
            ...(isExpanded
              ? componentProps?.seeLess?.sx
              : componentProps?.seeMore?.sx),
          }}
        >
          {isExpanded
            ? componentProps?.seeLess?.label || 'See less'
            : componentProps?.seeMore?.label || 'See more'}
        </Button>
      )}
    </Box>
  );
};
