import { gql } from '@apollo/client';
import { Box, Input, Typography } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearBox } from 'components/icons/components/linear/IconLinearBox';
import {
  PaginatedCapturedProductAllLabelsInput,
  ProductLineModelInput,
  useGetProductLinesModelsForSlaAnalyticsFilterByProductLineModelQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetProductLinesModelsForSLAAnalyticsFilterByProductLineModel(
    $input: PaginatedCapturedProductAllLabelsInput!
  ) {
    productLinesModelsForCapturedProductAllLabels(input: $input) {
      data {
        productModel
        productLine
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

interface SLAAnalyticsFilterByProductLineModelProps {
  renderTitle?: () => React.ReactNode;
  selectedProductLineModels: ProductLineModelInput[];
  onChange: (selectedProductLineModels: ProductLineModelInput[]) => void;
  variant?: 'normal' | 'accordion';
  filters: Pick<
    PaginatedCapturedProductAllLabelsInput,
    'topicIds' | 'dateRange'
  >;
}

export const SLAAnalyticsFilterByProductLineModel = ({
  renderTitle,
  selectedProductLineModels,
  onChange,
  variant = 'accordion',
  filters,
}: SLAAnalyticsFilterByProductLineModelProps) => {
  const [shouldLoadData, setShouldLoadData] = useState(variant === 'normal');
  const [query, setQuery] = useState('');
  const debouncedSetQuery = useMemo(
    () =>
      debounce((q: string) => {
        setQuery(q);
      }, 300),
    [],
  );

  const payload = useMemo(() => {
    return {
      ...filters,
      search: query,
    };
  }, [query, filters]);

  const {
    data: productLineModelsData,
    loading,
    fetchMore,
  } = useGetProductLinesModelsForSlaAnalyticsFilterByProductLineModelQuery({
    variables: {
      input: payload,
    },
    fetchPolicy: 'cache-and-network',
    skip: !shouldLoadData,
  });

  const productLineModels = useMemo(() => {
    const models =
      productLineModelsData?.productLinesModelsForCapturedProductAllLabels
        ?.data || [];

    // Create a map to deduplicate entries
    const uniqueModels = new Map();

    models.forEach((model) => {
      const productLine = model.productLine || '';
      const productModel = model.productModel || '';
      const key = `${productLine}-${productModel}`;

      if (!uniqueModels.has(key)) {
        uniqueModels.set(key, model);
      }
    });

    return Array.from(uniqueModels.values());
  }, [productLineModelsData]);

  const fetchMoreProductLineModels = useCallback(() => {
    if (
      !productLineModelsData?.productLinesModelsForCapturedProductAllLabels
        .pageInfo.hasNextPage
    ) {
      return;
    }
    fetchMore({
      variables: {
        input: {
          ...payload,
          skip: productLineModelsData
            .productLinesModelsForCapturedProductAllLabels.data.length,
        },
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...fetchMoreResult,
          productLinesModelsForCapturedProductAllLabels: {
            ...fetchMoreResult.productLinesModelsForCapturedProductAllLabels,
            data: [
              ...prev.productLinesModelsForCapturedProductAllLabels.data,
              ...fetchMoreResult.productLinesModelsForCapturedProductAllLabels
                .data,
            ],
          },
        };
      },
    });
  }, [productLineModelsData, fetchMore, payload]);

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconLinearBox
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-lg">Product line & Model</Typography>
      </Box>
    );
  }, [renderTitle]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      onCloseCallback={() => setShouldLoadData(false)}
      onOpenCallback={() => setShouldLoadData(true)}
      label={renderLabel}
      loadMoreItems={fetchMoreProductLineModels}
      sx={{
        ...(variant === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(variant === 'normal' && {
            display: 'none',
          }),
        },
      }}
      componentsProps={{
        dropDownOptions: {
          container: {
            sx: {
              maxHeight: 300,
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                width: 0,
              },
              li: {
                flexShrink: 0,
              },
            },
          },
        },
      }}
      defaultOpen={variant === 'normal'}
      renderDropDownOptionSearchInput={() => (
        <Input
          disableUnderline
          autoFocus
          placeholder="Search"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            debouncedSetQuery(e.currentTarget.value);
          }}
          fullWidth
          sx={{
            mb: 2,
            p: theme.spacing(0.5, 3),
            ...theme.typography['subhead-lg'],
            backgroundColor: theme.colors?.utility[275],
            borderRadius: 25,
          }}
        />
      )}
      dropDownOptions={
        loading || !productLineModels.length
          ? [
              {
                label: 'Loading',
                renderOption: () => (
                  <Typography
                    variant="headline-xs"
                    color={theme.colors?.utility[700]}
                    textAlign="center"
                  >
                    {loading ? 'Loading...' : 'No product line & model found'}
                  </Typography>
                ),
              },
            ]
          : productLineModels
              .filter((m) => m.productLine || m.productModel)
              .map((model) => {
                const productLine = model.productLine || '';
                const productModel = model.productModel || '';
                const value = `${productLine} - ${productModel}`;

                return {
                  label: value,
                  value,
                  renderOption: () => (
                    <CheckboxMenuItem
                      disableRipple
                      value={value}
                      label={
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography
                            variant="subhead-lg"
                            color={theme.colors?.primary.black}
                          >
                            {productLine}
                          </Typography>
                          <Typography
                            variant="subhead-lg"
                            color={theme.colors?.utility[700]}
                          >
                            {productModel}
                          </Typography>
                        </Box>
                      }
                      onClick={() => {
                        const existing = selectedProductLineModels.find(
                          (m) =>
                            (m.line || '') === productLine &&
                            (m.model || '') === productModel,
                        );

                        const after = existing
                          ? selectedProductLineModels.filter(
                              (o) =>
                                (o.line || '') !== productLine ||
                                (o.model || '') !== productModel,
                            )
                          : [
                              ...selectedProductLineModels,
                              {
                                line: productLine,
                                model: productModel,
                              },
                            ];

                        onChange(after);
                      }}
                      checked={Boolean(
                        selectedProductLineModels.find(
                          (m) =>
                            (m.line || '') === productLine &&
                            (m.model || '') === productModel,
                        ),
                      )}
                    />
                  ),
                };
              })
      }
    />
  );
};
