import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldMessage } from 'components/icons/components/bold/IconBoldMessage';
import { ExpandableText } from 'features/socialMediaListening';
import { SocialPostCardView } from 'features/socialPost';
import { SocialPostFragmentSocialPostCardViewFragmentDoc } from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

export const RADAR_EVENT_FRAGMENT_RADAR_EVENT_TIMELINE_ITEM_SOCIAL_POST_COMMENT = gql`
  fragment RadarEventFragmentRadarEventTimelineItemSocialPostComment on RadarEventModel {
    id
    socialPost {
      id
      ...SocialPostFragmentSocialPostCardView
    }
    socialPostComment {
      id
      text
      diggCount
      childCommentCount
    }
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

export type RadarEventTimelineItemSocialPostCommentProps = {
  radarEvent: any;
};

export const RadarEventTimelineItemSocialPostComment = (
  props: RadarEventTimelineItemSocialPostCommentProps,
) => {
  const { radarEvent } = props;

  const socialPost = radarEvent.socialPost;
  const socialPostComment = radarEvent.socialPostComment;

  return (
    <Box
      sx={{
        p: 6,
        borderRadius: 3,
        bgcolor: theme.colors?.utility[250],
        display: 'flex',
        gap: 4,
      }}
    >
      <Box>
        <Box
          sx={{
            width: 100,
          }}
        >
          <SocialPostCardView socialPost={socialPost} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          flex: 1,
        }}
      >
        <ExpandableText
          collapsedHeight={64}
          text={socialPostComment.text}
          componentProps={{
            text: {
              sx: {
                ...typography['subhead-xl'],
              },
            },
            seeMore: {
              label: 'See more',
            },
            seeLess: {
              label: 'Hide',
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            flexWrap: 'wrap',
            alignItems: 'center',
            mt: 'auto',
          }}
        >
          {[
            ...(socialPostComment.diggCount >= 0
              ? [
                  [
                    IconBoldHeart,
                    formatBigNumber(socialPostComment.diggCount || 0),
                  ],
                ]
              : []),
            ...(socialPostComment.childCommentCount >= 0
              ? [
                  [
                    IconBoldMessage,
                    formatBigNumber(socialPostComment.childCommentCount || 0),
                  ],
                ]
              : []),
          ].map(([Icon, text], index) => {
            return (
              <Box key={index} display="flex" gap={1} alignItems="center">
                <Icon size={16} color={theme.colors?.utility[800]} />
                <Typography
                  color={theme.colors?.utility[800]}
                  variant="headline-xs"
                >
                  {String(text)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
