import { Box, Grid } from '@mui/material';
import { SocialPostCommentCardView } from 'features/socialPostComment';
import { SocialPostCommentFragmentSocialPostCommentCardViewFragment } from 'graphql/generated';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

export type SocialPostCommentsGridViewProps = {
  socialPostComments: SocialPostCommentFragmentSocialPostCommentCardViewFragment[];
  brandId: string;
  hasMore?: boolean;
  loadMore?: VoidFunction;
};

export const SocialPostCommentsGridView = ({
  socialPostComments,
  brandId,
  hasMore,
  loadMore,
}: SocialPostCommentsGridViewProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={scrollContainerRef}
      sx={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        '::-webkit-scrollbar': {
          width: 0,
        },
        scrollbarWidth: 0,
        msOverflowStyle: 'none',
      }}
    >
      <InfiniteScroll
        useWindow={false}
        loadMore={() => {
          if (loadMore && hasMore) {
            loadMore();
          }
        }}
        hasMore={hasMore}
        threshold={100}
        getScrollParent={() => scrollContainerRef.current}
      >
        <Grid container spacing={8}>
          {socialPostComments.map((socialCommentPost, index) => {
            return (
              <Grid
                item
                key={`${socialCommentPost.id}-${index}`}
                xs={12}
                sm={6}
                md={4}
                xl={3}
              >
                <SocialPostCommentCardView
                  socialPostComment={socialCommentPost}
                  brandId={brandId}
                />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </Box>
  );
};
