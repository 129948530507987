import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { IconBoldDollarCircle } from 'components/icons/components/bold/IconBoldDollarCircle';
import { IconBoldHeartCircle } from 'components/icons/components/bold/IconBoldHeartCircle';
import { SocialListeningCreatorProfilePeekingPill } from 'features/socialListeningCreatorProfile/components';
import {
  CreatorProfileMentionedBrandsInput,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
  SocialPostMentionSource,
  useGetMentionedBrandsForSlCreatorProfileMentionedBrandsViewQuery,
} from 'graphql/generated';
import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { MentionedBrandCard } from './MentionedBrandCard';
import { StyledFilterButton } from './styles';
import { SocialListeningCreatorProfileMentionedBrandsViewSkeleton } from './SocialListeningCreatorProfileMentionedBrandsViewSkeleton';

// eslint-disable-next-line
gql`
  query GetMentionedBrandsForSLCreatorProfileMentionedBrandsView(
    $input: CreatorProfileMentionedBrandsInput!
  ) {
    creatorProfileMentionedBrands(input: $input) {
      data {
        id
        name
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetBrandMentionStatsForSLCreatorProfileMentionedBrandsView(
    $input: CreatorProfileBrandMentionedStatsInput!
  ) {
    creatorProfileBrandMentionStats(input: $input) {
      brandMentionCount
      engagementRate
      sentiment
      totalLikes
      totalViews
      totalComments
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetSocialPostsForSLCreatorProfileMentionedBrandsView(
    $input: CreatorProfilePaginatedBrandMentionsInput!
  ) {
    creatorProfileBrandMentions(input: $input) {
      data {
        id
        thumbnailUrl
        videoUrl
        ...SocialPostFragmentUseSocialListeningPostLinkGenerator
      }
    }
  }
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

type SocialListeningCreatorProfileMentionedBrandsViewProps = {
  filters: Pick<
    CreatorProfileMentionedBrandsInput,
    'creatorProfileId' | 'dateRange' | 'platforms' | 'currentBrandId'
  >;
};

export const SocialListeningCreatorProfileMentionedBrandsView = ({
  filters,
}: SocialListeningCreatorProfileMentionedBrandsViewProps) => {
  const [currentTab, setCurrentTab] = useState<SocialPostMentionSource>(
    SocialPostMentionSource.Paid,
  );
  const filterOptions = [
    {
      label: 'Paid partnerships',
      value: SocialPostMentionSource.Paid,
      Icon: IconBoldDollarCircle,
    },
    {
      label: 'Brand mentions',
      value: SocialPostMentionSource.Organic,
      Icon: IconBoldHeartCircle,
    },
  ];

  const mentionedBrandsVariables = useMemo(() => {
    return {
      ...filters,
      take: 5,
      mentionSources: [currentTab],
    };
  }, [filters, currentTab]);

  const { data, fetchMore, loading } =
    useGetMentionedBrandsForSlCreatorProfileMentionedBrandsViewQuery({
      variables: {
        input: mentionedBrandsVariables,
      },
    });

  const mentionedBrands = data?.creatorProfileMentionedBrands.data || [];

  if (loading) {
    return <SocialListeningCreatorProfileMentionedBrandsViewSkeleton />;
  }

  return (
    <Box
      sx={{
        px: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 450,
        overflowY: 'auto',
      }}
    >
      <InfiniteScroll
        loadMore={() =>
          fetchMore({
            variables: {
              input: {
                ...mentionedBrandsVariables,
                skip: mentionedBrands.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                ...prev,
                creatorProfileMentionedBrands: {
                  ...prev.creatorProfileMentionedBrands,
                  ...fetchMoreResult.creatorProfileMentionedBrands,
                  data: [
                    ...prev.creatorProfileMentionedBrands.data,
                    ...fetchMoreResult.creatorProfileMentionedBrands.data,
                  ],
                },
              };
            },
          })
        }
        initialLoad={false}
        useWindow={false}
        threshold={250}
        hasMore={
          data?.creatorProfileMentionedBrands.pageInfo.hasNextPage || false
        }
        loader={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mb: 6,
            }}
          >
            <Typography variant="subhead-lg" color={theme.colors?.utility[900]}>
              Loading...
            </Typography>
          </Box>
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 4,
            position: 'sticky',
            top: 0,
            backgroundColor: theme.colors?.primary.white,
            py: 6,
            zIndex: 1,
          }}
        >
          <Box sx={{ display: 'flex', gap: 2 }}>
            {filterOptions.map((option) => (
              <StyledFilterButton
                isActive={currentTab === option.value}
                key={option.value}
                onClick={() => setCurrentTab(option.value)}
                disableRipple
              >
                <option.Icon
                  size={16}
                  color={
                    currentTab === option.value
                      ? theme.colors?.primary.parchment
                      : theme.colors?.utility[800]
                  }
                />

                {option.label}
              </StyledFilterButton>
            ))}
          </Box>
          <SocialListeningCreatorProfilePeekingPill />
        </Box>
        {mentionedBrands.length === 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            pb={6}
          >
            <Typography
              variant="headline-sm"
              color={theme.colors?.utility[600]}
            >
              {loading ? 'Loading...' : 'No brand conversations spotted 👀'}
            </Typography>
          </Box>
        ) : (
          mentionedBrands.map((brand) => (
            <MentionedBrandCard
              key={brand.id}
              brandName={brand.name}
              filters={{
                ...filters,
                brandId: brand.id,
                mentionSources: [currentTab],
              }}
            />
          ))
        )}
      </InfiniteScroll>
    </Box>
  );
};
