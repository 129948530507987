// this is the new component, will used when socialListeningCrmEnabled is true and show in the place of creatorTrackingList
import { gql } from '@apollo/client';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCreatorsTopicTrackingForSocialListening } from 'features/socialMediaListening/hooks/useCreatorsTopicTrackingForSocialListening';
import { CreatorPeekingButton } from 'features/socialMediaListening/views/manageCreators/sections/creatorPeekingButton';
import {
  CreatorFragmentCreatorPeekingFragment,
  PlotFeature,
  TopicFragmentCreatorPeekingContentFragmentDoc,
  useGetCreatorInsightsByCreatorIdForCreatorPeekingQuery,
  useGetTopicsTrackingListForCreatorPeekingQuery,
} from 'graphql/generated';
import { useMemo } from 'react';

// eslint-disable-next-line
gql`
  query GetTopicsTrackingListForCreatorPeeking(
    $data: CreatorTopicTrackingInput!
  ) {
    getTopicsTrackingList(data: $data) {
      isTracking
      topic {
        ...TopicFragmentCreatorPeekingContent
        active
      }
    }
  }
  ${TopicFragmentCreatorPeekingContentFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCreatorInsightsByCreatorIdForCreatorPeeking(
    $input: IsTrackingCreatorProfileInsightsByCreatorIdInput!
  ) {
    isTrackingCreatorProfileInsightsByCreatorId(input: $input)
  }
`;

// eslint-disable-next-line
gql`
  fragment CreatorFragmentCreatorPeeking on CreatorModel {
    id
    platformId
    handle
    platform
  }
`;

type Props = {
  brandId: string;
  creator: CreatorFragmentCreatorPeekingFragment;
  onAfterTracking?: (data: {
    isTrackingInsights: boolean;
    trackedTopicIds: string[];
  }) => void;
};

export const CreatorPeeking = ({
  brandId,
  creator,
  onAfterTracking,
}: Props) => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);

  const {
    actions: {
      handleTrackCreator,
      handleUntrackCreator,
      handleUpdateCreatorTrackingInsights,
      handleUntrackCreatorForTopics,
    },
  } = useCreatorsTopicTrackingForSocialListening({
    platform: creator.platform,
    brandId,
  });
  const { data: creatorTrackingData, updateQuery: updateCreatorTrackingData } =
    useGetTopicsTrackingListForCreatorPeekingQuery({
      variables: {
        data: {
          creatorIds: [creator.id],
          brandId,
        },
      },
      skip: !creatorTrackingEnabled,
    });
  const { data: creatorInsightsData, updateQuery: updateCreatorInsightsData } =
    useGetCreatorInsightsByCreatorIdForCreatorPeekingQuery({
      variables: {
        input: {
          brandId,
          creatorId: creator.id,
        },
      },
      skip: !creatorTrackingEnabled,
    });
  const trackingData = useMemo(
    () => creatorTrackingData?.getTopicsTrackingList || [],
    [creatorTrackingData],
  );

  const { topics, selectedTrackedTopicIds } = useMemo(() => {
    const topics = trackingData
      .filter((data) => data.topic.active)
      .map((data) => data.topic);

    const selectedTrackedTopicIds = trackingData
      .filter((data) => data.isTracking)
      .map((data) => data.topic.id);

    return { topics, selectedTrackedTopicIds };
  }, [trackingData]);

  if (!creatorTrackingEnabled) {
    return null;
  }

  return (
    <CreatorPeekingButton
      brandId={brandId}
      selectedTopicIds={selectedTrackedTopicIds}
      handleEngagementTracking={async (topicId) => {
        if (selectedTrackedTopicIds.includes(topicId)) {
          await handleUntrackCreator(
            creator.platformId,
            topicId,
            creator.handle,
          );
        } else {
          await handleTrackCreator(creator.platformId, topicId, creator.handle);
        }
        onAfterTracking?.({
          isTrackingInsights:
            creatorInsightsData?.isTrackingCreatorProfileInsightsByCreatorId ||
            false,
          trackedTopicIds: selectedTrackedTopicIds.includes(topicId)
            ? selectedTrackedTopicIds.filter((id) => id !== topicId)
            : [...selectedTrackedTopicIds, topicId],
        });
        updateCreatorTrackingData((prevData) => {
          const newData = prevData.getTopicsTrackingList.map((data) => {
            if (data.topic.id === topicId) {
              return {
                ...data,
                isTracking: !data.isTracking,
              };
            }
            return data;
          });
          return {
            ...prevData,
            getTopicsTrackingList: newData,
          };
        });
      }}
      handleUntrackEngagementTrackingForTopics={async (topicIds) => {
        await handleUntrackCreatorForTopics(
          creator.platformId,
          topicIds,
          creator.handle,
        );
        onAfterTracking?.({
          isTrackingInsights:
            creatorInsightsData?.isTrackingCreatorProfileInsightsByCreatorId ||
            false,
          trackedTopicIds: [],
        });

        updateCreatorTrackingData((prevData) => {
          return {
            ...prevData,
            getTopicsTrackingList: prevData.getTopicsTrackingList.map(
              (data) => {
                if (topicIds.includes(data.topic.id)) {
                  return {
                    ...data,
                    isTracking: false,
                  };
                }
                return data;
              },
            ),
          };
        });
      }}
      handleUpdateCreatorTrackingInsights={async (isTrackingInsights) => {
        await handleUpdateCreatorTrackingInsights({
          brandId,
          creatorHandle: creator.handle,
          creatorPlatform: creator.platform,
          creatorPlatformId: creator.platformId,
          isTrackingInsights,
        });
        onAfterTracking?.({
          isTrackingInsights,
          trackedTopicIds: selectedTrackedTopicIds,
        });
        updateCreatorInsightsData((prevData) => {
          return {
            ...prevData,
            isTrackingCreatorProfileInsightsByCreatorId: isTrackingInsights,
          };
        });
      }}
      isTrackingInsights={
        creatorInsightsData?.isTrackingCreatorProfileInsightsByCreatorId ||
        false
      }
      topics={topics}
    />
  );
};
