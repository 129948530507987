import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  SocialMediaListeningCreatorProfile,
  TrackUntrackCreatorButton,
} from 'features/socialMediaListening/components';
import {
  CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragment,
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment,
  PlotFeature,
  TopicFragmentCreatorPeekingContentFragmentDoc,
  TrackCreatorInput,
  UntrackCreatorForTopicsInput,
  UpdateTrackingInsightsForCreatorProfileByCreatorIdInput,
  useGetBrandTopicsForCreatorsListingQuery,
} from 'graphql/generated';
import { useMemo } from 'react';
import { CreatorPeekingButton } from '../creatorPeekingButton';

// eslint-disable-next-line
gql`
  query GetBrandTopicsForCreatorsListing($brandId: String!) {
    brand(id: $brandId) {
      id
      topics {
        ...TopicFragmentCreatorPeekingContent
        active
        creatorTrackingTopics {
          id
          creatorId
        }
      }
    }
  }
  ${TopicFragmentCreatorPeekingContentFragmentDoc}
`;

type Props = {
  trackedCreators: CreatorFragmentUseCreatorsTopicTrackingForSocialListeningFragment[];
  usersListToDisplay: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment[];
  showDetailsOnHover?: boolean;
  handleEngagementTracking: (track: boolean, input: TrackCreatorInput) => void;
  brandId: string;
  handleUntrackEngagementTrackingForTopics: (
    input: UntrackCreatorForTopicsInput,
  ) => void;
  handleUpdateCreatorTrackingInsights: (
    input: UpdateTrackingInsightsForCreatorProfileByCreatorIdInput,
  ) => void;
  currentTopicId: string;
  updatingCreatorPlatformIds: string[];
};

export const CreatorsListing = ({
  showDetailsOnHover,
  trackedCreators,
  usersListToDisplay,
  handleEngagementTracking,
  brandId,
  handleUntrackEngagementTrackingForTopics,
  handleUpdateCreatorTrackingInsights,
  currentTopicId,
  updatingCreatorPlatformIds,
}: Props) => {
  const { data } = useGetBrandTopicsForCreatorsListingQuery({
    variables: {
      brandId,
    },
    skip: !brandId,
  });

  const allTopics = useMemo(() => {
    return data?.brand?.topics.filter((t) => t.active) || [];
  }, [data]);

  const { isFeatureEnabled } = useFeatureFlagContext();
  const isSocialListeningCrmEnabled = isFeatureEnabled(
    PlotFeature.SocialListeningCrm,
  );

  return (
    <>
      {usersListToDisplay.map((creator) => {
        const isTrackingInsights =
          trackedCreators.find((c) => c.platformId === creator.platformId)
            ?.isTrackingInsightsForCreatorProfile || false;
        const trackedTopics =
          trackedCreators.find((c) => c.platformId === creator.platformId)
            ?.creatorTrackedTopics || [];

        const topics = allTopics.filter((t) =>
          t.creatorTrackingTopics.some((ct) => ct.creatorId === creator.id),
        );

        const currentTopic = allTopics.find((t) => t.id === currentTopicId);

        if (!topics.some((t) => t.id === currentTopicId) && currentTopic) {
          topics.push(currentTopic);
        }

        return (
          <Box display="flex" justifyContent="space-between" width="100%">
            <SocialMediaListeningCreatorProfile
              creator={
                creator as unknown as CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment
              }
              componentProps={{
                showDetailsOnHover,
                avatarSx: {
                  sx: {
                    height: 36,
                    width: 36,
                  },
                },
                containerSx: {
                  sx: {
                    gap: 3,
                    '& .handle': {
                      ...typography['headline-sm'],
                    },
                    '& .nickName': {
                      ...typography['subhead-lg'],
                    },
                  },
                },
              }}
            />
            {isSocialListeningCrmEnabled ? (
              <CreatorPeekingButton
                selectedTopicIds={trackedTopics.map((t) => t.id)}
                isTrackingInsights={isTrackingInsights}
                brandId={brandId}
                handleUpdateCreatorTrackingInsights={async (
                  isTrackingInsights,
                ) => {
                  await handleUpdateCreatorTrackingInsights({
                    brandId,
                    creatorHandle: creator.handle,
                    creatorPlatform: creator.platform,
                    creatorPlatformId: creator.platformId,
                    isTrackingInsights,
                  });
                }}
                handleUntrackEngagementTrackingForTopics={async (topicIds) => {
                  await handleUntrackEngagementTrackingForTopics({
                    topicIds,
                    data: {
                      handle: creator.handle,
                      platform: creator.platform,
                      platformId: creator.platformId,
                    },
                  });
                }}
                handleEngagementTracking={async (topicId) => {
                  const track = trackedTopics.some((t) => t.id === topicId);
                  await handleEngagementTracking(!track, {
                    topicId,
                    data: {
                      handle: creator.handle,
                      platform: creator.platform,
                      platformId: creator.platformId,
                    },
                  });
                }}
                topics={topics}
              />
            ) : (
              <TrackUntrackCreatorButton
                updating={updatingCreatorPlatformIds.includes(
                  creator.platformId,
                )}
                tracking={
                  !!trackedCreators.find(
                    (c) => c.platformId === creator.platformId,
                  )
                }
                onChange={(track) => {
                  handleEngagementTracking(track, {
                    topicId: currentTopicId,
                    data: {
                      handle: creator.handle,
                      platform: creator.platform,
                      platformId: creator.platformId,
                    },
                  });
                }}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};
