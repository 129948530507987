import { Box, Typography } from '@mui/material';
import { RadioMenuItem } from 'components/common/form/Select';
import { UseAsyncOptionsProps } from 'features/nestedFilters/hooks';
import { theme } from 'styles/theme';
import {
  NestedFiltersMenuItemBaseType,
  NestedFiltersMenuItemBaseValueType,
} from '../menuItem';
import { NestedFiltersOptionType } from '../multiSelect';

export type NestedFiltersSingleSelectType = NestedFiltersMenuItemBaseType &
  UseAsyncOptionsProps & {
    type: 'single-select';
    label?: string;
    options: NestedFiltersOptionType[];
    filterable?: boolean;
    disableDefaultFiltering?: boolean;
  };

export type NestedFiltersSingleSelectProps = {
  singleSelect: NestedFiltersSingleSelectType;
  value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType>;
  onChange: (
    value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType>,
  ) => void;
};

export const NestedFiltersSingleSelect = (
  props: NestedFiltersSingleSelectProps,
) => {
  const { singleSelect, value: _value, onChange } = props;

  const value = _value.value;

  const options = [
    _value.value,
    ...singleSelect.options.filter(
      (option) => option.value !== _value.value?.value,
    ),
  ].filter(Boolean) as NestedFiltersOptionType[];

  return (
    <Box>
      {singleSelect.label && (
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          {singleSelect.label}
        </Typography>
      )}
      {options.map((option, index) => {
        return (
          <RadioMenuItem
            key={index}
            label={option.label}
            value={option.value}
            description={option.description}
            checked={value?.value === option.value}
            onClick={() =>
              onChange({
                value: option,
              })
            }
          />
        );
      })}
    </Box>
  );
};
