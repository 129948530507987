import { gql } from '@apollo/client';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { CommunityLabelColorMap } from 'features/socialListeningCommunity/constants';
import {
  CommunicationChannelOptionType,
  SocialListeningRadarMultiSelectLabel,
} from 'features/socialListeningRadar/components';
import {
  OrganizationCreatorProfileLabelFragmentSlLabelSelectorFragment,
  OrganizationCreatorProfileLabelFragmentSlLabelSelectorFragmentDoc,
  useAddLabelToCreatorProfileForSlLabelSelectorMutation,
  useCreateCreatorProfileLabelForSlLabelSelectorMutation,
  useGetCreatorProfileLabelsForSlLabelSelectorQuery,
  useRemoveLabelFromCreatorProfileForSlLabelSelectorMutation,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment OrganizationCreatorProfileLabelFragmentSLLabelSelector on OrganizationCreatorProfileLabelModel {
    id
    label
    color
    bgColor
  }
`;

// eslint-disable-next-line
gql`
  query getCreatorProfileLabelsForSLLabelSelector(
    $input: PaginatedOrganizationCreatorProfileLabelInput!
  ) {
    paginatedOrganizationCreatorProfileLabels(input: $input) {
      data {
        ...OrganizationCreatorProfileLabelFragmentSLLabelSelector
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${OrganizationCreatorProfileLabelFragmentSlLabelSelectorFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateCreatorProfileLabelForSLLabelSelector(
    $input: CreateOrganizationCreatorProfileLabelInput!
  ) {
    createOrganizationCreatorProfileLabel(input: $input) {
      ...OrganizationCreatorProfileLabelFragmentSLLabelSelector
    }
  }
  ${OrganizationCreatorProfileLabelFragmentSlLabelSelectorFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation AddLabelToCreatorProfileForSLLabelSelector(
    $input: AddOrganizationCreatorProfileLabelToCreatorProfileInput!
  ) {
    addOrganizationCreatorProfileLabelToCreatorProfile(input: $input) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation RemoveLabelFromCreatorProfileForSLLabelSelector(
    $input: RemoveOrganizationCreatorProfileLabelFromCreatorProfileInput!
  ) {
    removeOrganizationCreatorProfileLabelFromCreatorProfile(input: $input) {
      message
      success
    }
  }
`;

interface SocialListeningLabelSelectorProps {
  selectedLabels: OrganizationCreatorProfileLabelFragmentSlLabelSelectorFragment[];
  creatorProfileId: string;
  onLabelAdded?: (labelId: string) => void;
  onLabelRemoved?: (labelId: string) => void;
  brandId: string;
}

export const SocialListeningLabelSelector = ({
  selectedLabels,
  creatorProfileId,
  onLabelAdded,
  onLabelRemoved,
  brandId,
}: SocialListeningLabelSelectorProps) => {
  const [labelSearch, setLabelSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabelOptions, setSelectedLabelOptions] = useState<
    CommunicationChannelOptionType[]
  >([]);

  const [createCreatorProfileLabel] =
    useCreateCreatorProfileLabelForSlLabelSelectorMutation();
  const [removeCreatorProfileLabel] =
    useRemoveLabelFromCreatorProfileForSlLabelSelectorMutation();
  const [addCreatorProfileLabel] =
    useAddLabelToCreatorProfileForSlLabelSelectorMutation();

  useEffect(() => {
    setSelectedLabelOptions(
      selectedLabels.map((label) => {
        return {
          id: label.id,
          name: label.label,
          color: label.color,
          bgColor: label.bgColor,
        };
      }),
    );
  }, [selectedLabels]);

  const debouncedSetLabelSearch = debounce((newQuery) => {
    setLabelSearch(newQuery);
  }, 250);

  const labelVariables = useMemo(() => {
    return {
      search: labelSearch,
      take: 10,
    };
  }, [labelSearch]);

  const { data: labelData, fetchMore: fetchMoreLabelData } =
    useGetCreatorProfileLabelsForSlLabelSelectorQuery({
      variables: {
        input: { ...labelVariables, brandId },
      },
      skip: !isOpen,
      fetchPolicy: 'cache-and-network',
    });

  const options = useMemo(() => {
    return (
      labelData?.paginatedOrganizationCreatorProfileLabels.data ?? []
    ).map((label) => {
      return {
        id: label.id,
        name: label.label,
        color: label.color,
        bgColor: label.bgColor,
      };
    });
  }, [labelData]);

  const onAddLabelToCreatorProfile = (labelId: string) => {
    addCreatorProfileLabel({
      variables: {
        input: {
          labelId,
          creatorProfileId,
        },
      },
    });

    onLabelAdded?.(labelId);
  };

  const onRemoveLabelFromCreatorProfile = (labelId: string) => {
    removeCreatorProfileLabel({
      variables: {
        input: {
          labelId,
          creatorProfileId,
        },
      },
    });

    onLabelRemoved?.(labelId);
  };

  const onCreateProfileLabel = (option: CommunicationChannelOptionType) => {
    createCreatorProfileLabel({
      variables: {
        input: {
          label: option.name,
          color: option.color,
          bgColor: option.bgColor,
          creatorProfileId,
        },
      },
      onCompleted: (data) => {
        onAddLabelToCreatorProfile(
          data.createOrganizationCreatorProfileLabel.id,
        );
        setSelectedLabelOptions((prev) => [
          ...prev,
          { ...option, id: data.createOrganizationCreatorProfileLabel.id },
        ]);
      },
      updateQueries: {
        getCreatorProfileLabelsForSLLabelSelector: (
          prev,
          { mutationResult },
        ) => {
          return {
            ...prev,
            paginatedOrganizationCreatorProfileLabels: {
              ...prev.paginatedOrganizationCreatorProfileLabels,
              data: [
                mutationResult.data?.createOrganizationCreatorProfileLabel,
                ...prev.paginatedOrganizationCreatorProfileLabels.data,
              ],
            },
          };
        },
      },
    });
  };

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}
    >
      <SocialListeningRadarMultiSelectLabel
        values={selectedLabelOptions.map((label) => label.id)}
        onChange={(value) => {
          setSelectedLabelOptions([
            ...(options || selectedLabelOptions).filter((o) =>
              value.includes(o.id),
            ),
          ]);

          const selectedLabelIds = selectedLabelOptions.map(
            (label) => label.id,
          );
          const removedLabelIds = selectedLabelIds.filter(
            (id) => !value.includes(id),
          );
          const newLabelIds = value.filter(
            (id) => !selectedLabelIds.includes(id),
          );
          if (removedLabelIds.length) {
            removedLabelIds.forEach((id) => {
              onRemoveLabelFromCreatorProfile(id);
            });
          }
          if (newLabelIds.length) {
            newLabelIds.forEach((id) => {
              onAddLabelToCreatorProfile(id);
            });
          }
        }}
        onSetSearch={debouncedSetLabelSearch}
        fetchMoreItems={() => {
          if (
            !labelData?.paginatedOrganizationCreatorProfileLabels.pageInfo
              .hasNextPage
          ) {
            return;
          }
          fetchMoreLabelData({
            variables: {
              input: {
                ...labelVariables,
                after:
                  labelData?.paginatedOrganizationCreatorProfileLabels.pageInfo
                    .endCursor,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                ...prev,
                paginatedOrganizationCreatorProfileLabels: {
                  ...prev.paginatedOrganizationCreatorProfileLabels,
                  data: [
                    ...prev.paginatedOrganizationCreatorProfileLabels.data,
                    ...fetchMoreResult.paginatedOrganizationCreatorProfileLabels
                      .data,
                  ].filter(
                    (channel, index, self) =>
                      self.findIndex((c) => c.id === channel.id) === index,
                  ),
                  pageInfo: {
                    ...prev.paginatedOrganizationCreatorProfileLabels.pageInfo,
                    ...fetchMoreResult.paginatedOrganizationCreatorProfileLabels
                      .pageInfo,
                  },
                },
              };
            },
          });
        }}
        options={options}
        onCreate={async (option) => {
          onCreateProfileLabel(option);
        }}
        renderTrigger={({ isOpen }) => {
          if (isOpen) {
            setIsOpen(true);
          }

          return (
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              flexWrap="wrap"
              width="fit-content"
            >
              {!!selectedLabelOptions.length && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  {selectedLabelOptions.map((option) => {
                    return (
                      <Box
                        sx={{
                          color:
                            option.color || CommunityLabelColorMap[0].color,
                          bgcolor:
                            option.bgColor ||
                            CommunityLabelColorMap[0].backgroundColor,
                          borderRadius: 2,
                          padding: theme.spacing(1, 2),
                          ...theme.typography['headline-sm'],
                          width: 'fit-content',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        {option.name}
                      </Box>
                    );
                  })}
                </Box>
              )}
              <Button
                variant="text"
                startIcon={
                  <IconButton
                    sx={{
                      bgcolor: theme.colors?.utility[300],
                      height: 20,
                      width: 20,
                      ':hover': {
                        bgcolor: theme.colors?.utility[300],
                      },
                    }}
                  >
                    <IconLinearAdd
                      color={theme.colors?.utility[800]}
                      size={16}
                      style={{
                        flexShrink: 0,
                      }}
                    />
                  </IconButton>
                }
                size="small"
                sx={{
                  p: 0,
                  minWidth: 'unset',
                }}
              >
                {selectedLabelOptions.length === 0 ? (
                  <Typography
                    variant="subhead-xl"
                    color={theme.colors?.utility[700]}
                  >
                    Add a label
                  </Typography>
                ) : (
                  <></>
                )}
              </Button>
            </Box>
          );
        }}
      />
    </Box>
  );
};
