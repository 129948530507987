import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { countries as allCountries } from 'countries-list';
import {
  BrandInboundFiltersInput,
  Platform,
  useGetSocialPostsCountriesDataForSlaBrandAnalyticsCountriesViewQuery,
} from 'graphql/generated';
import { useCallback, useEffect, useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { ShowItemsContextMenu } from '../../components/slaShowItemsContextMenu';
import { SLABrandAnalyticsCountriesDialogView } from '../slaBrandAnalyticsCountriesDialog';
import { SLABrandAnalyticsCountriesViewSkeleton } from './SLABrandAnalyticsCountriesViewSkeleton';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialPostsCountriesDataForSLABrandAnalyticsCountriesView(
    $data: BrandInboundFiltersInput!
  ) {
    getSocialPostsCountriesData(data: $data) {
      country
      totalEngagement
      totalImpressions
      totalPosts
      statsForFirstDate {
        totalEngagement
        totalImpressions
        totalPosts
      }
      statsForLastDate {
        totalEngagement
        totalImpressions
        totalPosts
      }
    }
  }
`;

export enum CountriesSortField {
  TotalPosts = 'TotalPosts',
  TotalEngagement = 'TotalEngagement',
  TotalImpressions = 'TotalImpressions',
}

const SORT_OPTIONS = [
  {
    label: 'Total post count',
    value: CountriesSortField.TotalPosts,
  },
  {
    label: 'Total engagement',
    value: CountriesSortField.TotalEngagement,
  },
  {
    label: 'Total view count',
    value: CountriesSortField.TotalImpressions,
  },
] as const;

type Props = {
  filters: BrandInboundFiltersInput;
};

export const SLABrandAnalyticsCountriesView = ({ filters }: Props) => {
  const {
    isOpen: isSocialPostsDialogOpen,
    onOpen: onSocialPostsDialogOpen,
    onClose: onSocialPostsDialogClose,
  } = useDisclosure();

  const [sortKey, setSortKey] = useState(CountriesSortField.TotalPosts);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [itemCount, setItemCount] = useState(5);
  const [isCustomCount, setIsCustomCount] = useState(false);
  const [customCount, setCustomCount] = useState('');

  const [sortedCountryCodes, setSortedCountryCodes] = useState<string[]>([]);
  const [maxValue, setMaxValue] = useState(0);

  const { data: countriesData, loading } =
    useGetSocialPostsCountriesDataForSlaBrandAnalyticsCountriesViewQuery({
      variables: {
        data: {
          ...filters,
        },
      },
    });

  useEffect(() => {
    if (countriesData) {
      switch (sortKey) {
        case CountriesSortField.TotalPosts:
          setSortedCountryCodes(
            [...(countriesData.getSocialPostsCountriesData || [])]
              .sort((a, b) => b.totalPosts - a.totalPosts)
              .map((country) => country.country)
              .filter((country) => country !== '')
              .slice(0, itemCount),
          );
          setMaxValue(
            Math.max(
              ...(countriesData.getSocialPostsCountriesData || []).map(
                (country) => country.totalPosts,
              ),
            ),
          );
          break;
        case CountriesSortField.TotalEngagement:
          setSortedCountryCodes(
            [...(countriesData.getSocialPostsCountriesData || [])]
              .sort((a, b) => b.totalEngagement - a.totalEngagement)
              .map((country) => country.country)
              .filter((country) => country !== '')
              .slice(0, itemCount),
          );
          setMaxValue(
            Math.max(
              ...(countriesData.getSocialPostsCountriesData || []).map(
                (country) => country.totalEngagement,
              ),
            ),
          );
          break;
        case CountriesSortField.TotalImpressions:
          setSortedCountryCodes(
            [...(countriesData.getSocialPostsCountriesData || [])]
              .sort((a, b) => b.totalImpressions - a.totalImpressions)
              .map((country) => country.country)
              .filter((country) => country !== '')
              .slice(0, itemCount),
          );
          setMaxValue(
            Math.max(
              ...(countriesData.getSocialPostsCountriesData || []).map(
                (country) => country.totalImpressions,
              ),
            ),
          );
          break;
        default:
          break;
      }
    }
  }, [countriesData, sortKey, itemCount]);

  const getDataForCountryCode = useCallback(
    (code: string) => {
      return countriesData?.getSocialPostsCountriesData?.find(
        (country) => country.country === code,
      );
    },
    [countriesData],
  );

  const getValueForCountryCode = useCallback(
    (code: string) => {
      // eslint-disable-next-line default-case
      switch (sortKey) {
        case CountriesSortField.TotalPosts:
          return (
            countriesData?.getSocialPostsCountriesData?.find(
              (country) => country.country === code,
            )?.totalPosts ?? 0
          );
        case CountriesSortField.TotalEngagement:
          return (
            countriesData?.getSocialPostsCountriesData?.find(
              (country) => country.country === code,
            )?.totalEngagement ?? 0
          );
        case CountriesSortField.TotalImpressions:
          return (
            countriesData?.getSocialPostsCountriesData?.find(
              (country) => country.country === code,
            )?.totalImpressions ?? 0
          );
      }
    },
    [countriesData, sortKey],
  );

  const maxItems = countriesData?.getSocialPostsCountriesData?.length || 0;
  const isTiktokPlatformPresent =
    !filters.platforms?.length ||
    filters.platforms?.some((platform) => platform === Platform.Tiktok);
  if (loading) {
    return <SLABrandAnalyticsCountriesViewSkeleton />;
  }

  return (
    <Box
      sx={{
        p: theme.spacing(6),
        border: `1px solid ${theme.colors?.utility[300]}`,
        borderRadius: theme.spacing(6),
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Tooltip
          title={
            <Typography variant="body-sm">
              Countries
              <br />
              Location of where content was created
              <br /> (TikTok data only).
            </Typography>
          }
        >
          <Typography
            variant="body-xl"
            color={theme.colors?.utility[700]}
            fontWeight={600}
            display="flex"
            alignItems="center"
            gap={1}
          >
            Countries
            <IconBoldInfoCircle size={16} />
          </Typography>
        </Tooltip>

        <Box display="flex" gap={2}>
          <ContextMenu
            sx={{
              '& .context-menu-item': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={SORT_OPTIONS.map((option) => ({
              renderOption: () => (
                <RadioMenuItem
                  label={option.label}
                  value={option.value}
                  checked={sortKey === option.value}
                  sx={{
                    p: `${theme.spacing(2, 3)}`,
                  }}
                />
              ),
              onClick: () => {
                setSortKey(option.value);
              },
            }))}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  height: 'fit-content',
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': { backgroundColor: theme.colors?.utility[275] },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  {
                    SORT_OPTIONS.find((option) => option.value === sortKey)
                      ?.label
                  }
                </Typography>
              </Button>
            )}
          />

          <ShowItemsContextMenu
            itemCount={itemCount}
            setItemCount={setItemCount}
            maxItems={maxItems}
            isCustomCount={isCustomCount}
            setIsCustomCount={setIsCustomCount}
            customCount={customCount}
            setCustomCount={setCustomCount}
            onCancel={() => {
              setIsCustomCount(false);
              setCustomCount('');
            }}
            labels={{
              itemLabel: 'countries',
              top5Label: 'Top 5 countries',
              top10Label: 'Top 10 countries',
              allItemsLabel: 'All countries',
              customLabel: 'Custom',
            }}
          />
        </Box>
      </Box>

      {isTiktokPlatformPresent && (
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          mt={4}
          sx={{
            minHeight: theme.spacing(52),
            maxHeight: theme.spacing(52),
            overflow: 'auto',
          }}
        >
          {sortedCountryCodes.map((code, index) => {
            const value = getValueForCountryCode(code);
            const widthPercentage = value / maxValue;

            const data = getDataForCountryCode(code);

            const totalPostsPercentDifference =
              ((data?.statsForLastDate.totalPosts ?? 0) -
                (data?.statsForFirstDate.totalPosts ?? 0)) /
              (data?.statsForFirstDate.totalPosts || 1);

            const totalImpressionsPercentDifference =
              ((data?.statsForLastDate.totalImpressions ?? 0) -
                (data?.statsForFirstDate.totalImpressions ?? 0)) /
              (data?.statsForFirstDate.totalImpressions || 1);

            const totalEngagementPercentDifference =
              ((data?.statsForLastDate.totalEngagement ?? 0) -
                (data?.statsForFirstDate.totalEngagement ?? 0)) /
              (data?.statsForFirstDate.totalEngagement || 1);

            const finalDifference =
              sortKey === CountriesSortField.TotalPosts
                ? totalPostsPercentDifference
                : sortKey === CountriesSortField.TotalImpressions
                ? totalImpressionsPercentDifference
                : totalEngagementPercentDifference;

            return (
              <Box
                display="flex"
                alignItems="center"
                gap={1}
                height={theme.spacing(8)}
                sx={{
                  cursor: 'pointer',
                  mr: 2,
                  '&:hover': {
                    backgroundColor: theme.colors?.utility[275],
                  },
                }}
                onClick={() => {
                  setSelectedCountry(code);
                  onSocialPostsDialogOpen();
                }}
              >
                <Typography
                  variant="body-xl"
                  color={theme.colors?.primary.black}
                  fontWeight={600}
                  width={theme.spacing(40)}
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {index + 1}. {allCountries[code]?.name || code}
                </Typography>

                <Box sx={{ flex: 1 }}>
                  <Box
                    sx={{
                      height: theme.spacing(8),
                      width: `${widthPercentage * 100}%`,
                      minWidth: theme.spacing(12),
                      backgroundColor: theme.colors?.utility[600],
                      borderRadius: theme.spacing(2),
                      position: 'relative',
                    }}
                  >
                    <Typography
                      position="absolute"
                      right={6}
                      top={5}
                      variant="body-xl"
                      color={theme.colors?.primary.white}
                      fontWeight={600}
                    >
                      {formatBigNumber(Number(value))}
                    </Typography>
                  </Box>
                </Box>

                {/* <Typography
                  display="flex"
                  alignItems="center"
                  gap={1}
                  ml={2}
                  width={theme.spacing(18)}
                  variant="body-xl"
                  fontWeight={500}
                  color={
                    finalDifference > 0
                      ? theme.colors?.utility['green-3']
                      : finalDifference < 0
                      ? theme.colors?.utility['pink-3']
                      : theme.colors?.utility[600]
                  }
                >
                  {finalDifference !== 0 && (
                    <>
                      {Number(finalDifference) * 100}%
                      {finalDifference > 0 ? (
                        <IconBoldArrowDown
                          style={{
                            transform: 'rotate(180deg)',
                          }}
                          size={16}
                        />
                      ) : finalDifference < 0 ? (
                        <IconBoldArrowDown size={16} />
                      ) : null}
                    </>
                  )}
                </Typography> */}
              </Box>
            );
          })}
        </Box>
      )}

      {!isTiktokPlatformPresent && (
        <Box
          sx={{
            minHeight: theme.spacing(52),
            maxHeight: theme.spacing(52),
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body-xl" color={theme.colors?.utility[600]}>
            Countries data is only available for TikTok.
          </Typography>
        </Box>
      )}

      <SLABrandAnalyticsCountriesDialogView
        open={isSocialPostsDialogOpen}
        onClose={onSocialPostsDialogClose}
        filters={filters}
        selectedCountry={selectedCountry}
      />
    </Box>
  );
};
