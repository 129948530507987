import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { SocialPostCommentFragmentSocialPostCommentLikesFragment } from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostCommentFragmentSocialPostCommentLikes on SocialPostCommentModel {
    id
    diggCount
  }
`;

type SocialPostCommentLikesProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentLikesFragment;
  componentProps?: {
    sx?: SxProps;
    textSx?: SxProps;
  };
  showUnitLabel?: boolean;
};

export const SocialPostCommentLikes = (props: SocialPostCommentLikesProps) => {
  const { socialPostComment, componentProps, showUnitLabel = true } = props;

  return (
    <Box sx={componentProps?.sx}>
      <Typography variant="headline-xxs" sx={componentProps?.textSx}>
        {socialPostComment.diggCount}
        {showUnitLabel ? ' likes' : ''}
      </Typography>
    </Box>
  );
};
