import { gql } from '@apollo/client';
import { Box, IconButton, Input, Menu, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import {
  FeatureType,
  FeatureTypeContextMenuView,
} from 'features/featureTypeContextMenu';
import {
  CollectionFragmentCollectionTopHeaderBreadcrumbsFragment,
  useGetSameLevelCollectionsForCollectionTopHeaderBreadcrumbsQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetSameLevelCollectionsForCollectionTopHeaderBreadcrumbs(
    $input: GetSameLevelCollectionsInput!
    $after: String
    $take: Int
  ) {
    sameLevelCollections(input: $input, after: $after, take: $take) {
      pageInfo {
        endCursor
        hasNextPage
      }
      data {
        id
        name
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  fragment CollectionFragmentCollectionTopHeaderBreadcrumbs on CollectionModel {
    id
    name
    breadcrumbsFromRoot {
      id
      name
    }
  }
`;

interface CollectionTopHeaderBreadcrumbProps {
  collection: CollectionFragmentCollectionTopHeaderBreadcrumbsFragment;
}

export const CollectionTopHeaderBreadcrumbs = ({
  collection,
}: CollectionTopHeaderBreadcrumbProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Box onClick={() => navigate(-1)} mt={1}>
        <IconOutlineArrowLeft
          size={20}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
        <FeatureTypeContextMenuView
          featureType={FeatureType.Juicebox}
          customLabel={
            <Typography variant="headline-xs">Creative Juicebox</Typography>
          }
        />
        <Typography variant="headline-xs">/</Typography>
      </Box>
      {collection.breadcrumbsFromRoot.map((breadcrumb, index) => {
        const isLastBreadcrumb =
          index === collection.breadcrumbsFromRoot.length - 1;
        return (
          <BreadcrumbContextMenu
            breadcrumbCollection={breadcrumb}
            isLastBreadcrumb={isLastBreadcrumb}
          />
        );
      })}
    </>
  );
};

interface BreadcrumbContextMenuProps {
  breadcrumbCollection: CollectionFragmentCollectionTopHeaderBreadcrumbsFragment['breadcrumbsFromRoot'][0];
  isLastBreadcrumb: boolean;
}

const BreadcrumbContextMenu = ({
  breadcrumbCollection,
  isLastBreadcrumb,
}: BreadcrumbContextMenuProps) => {
  const [searchStr, setSearchStr] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState(searchStr);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((value) => setDebouncedQuery(value), 300),
    [],
  );

  const {
    data: sameLevelCollectionsData,
    loading,
    fetchMore,
  } = useGetSameLevelCollectionsForCollectionTopHeaderBreadcrumbsQuery({
    variables: {
      input: {
        collectionId: breadcrumbCollection.id,
        query: debouncedQuery,
      },
    },
    skip: !isOpen,
    fetchPolicy: 'cache-and-network',
  });

  const onFetchMore = () => {
    if (!sameLevelCollectionsData?.sameLevelCollections.pageInfo.hasNextPage) {
      return;
    }
    fetchMore({
      variables: {
        input: {
          collectionId: breadcrumbCollection.id,
          query: debouncedQuery,
        },
        after:
          sameLevelCollectionsData?.sameLevelCollections.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          sameLevelCollections: {
            ...prev.sameLevelCollections,
            ...fetchMoreResult.sameLevelCollections,
            data: [
              ...prev.sameLevelCollections.data,
              ...fetchMoreResult.sameLevelCollections.data,
            ].filter((item, index, self) => {
              return self.findIndex((t) => t.id === item.id) === index;
            }),
          },
        };
      },
    });
  };

  const collections = sameLevelCollectionsData?.sameLevelCollections.data || [];

  useEffect(() => {
    setSearchStr('');
    setDebouncedQuery('');
  }, [breadcrumbCollection]);

  return (
    <>
      <IconButton
        sx={{
          borderRadius: 2,
          backgroundColor: !isOpen ? 'transparent' : 'rgba(35, 6, 3, 0.05)',
          p: theme.spacing(1),
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <Typography
          variant="headline-xs"
          color={
            isLastBreadcrumb
              ? theme.colors?.primary.black
              : theme.colors?.utility[700]
          }
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {breadcrumbCollection.name}
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            minWidth: 266,
          },
          sx: {
            p: theme.spacing(5),
            'li:first-child': {
              display: 'none',
              pointerEvents: 'none',
            },
          },
        }}
      >
        <DropDownMoreMenuItem
          label=""
          loadMoreItems={onFetchMore}
          renderDropDownOptionSearchInput={() => {
            return (
              <Input
                disableUnderline
                placeholder="search"
                value={searchStr}
                sx={{
                  borderRadius: 25,
                  bgcolor: theme.colors?.utility[300],
                  px: 3,
                  pt: 0.5,
                  ...theme.typography['subhead-lg'],
                  mb: 2,
                }}
                onChange={(e) => {
                  setSearchStr(e.target.value);
                  debouncedSearch(e.target.value);
                }}
              />
            );
          }}
          defaultOpen
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  maxHeight: 325,
                  overflowX: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 2,
                  },
                  gap: theme.spacing(2),
                  display: 'flex',
                  flexDirection: 'column',
                },
              },
            },
          }}
          dropDownOptions={
            loading || !collections.length
              ? [
                  {
                    label: 'Loading',
                    renderOption: () => (
                      <Typography
                        variant="headline-xs"
                        color={theme.colors?.utility[700]}
                        textAlign="center"
                      >
                        {loading ? 'Loading...' : 'No collections found'}
                      </Typography>
                    ),
                  },
                ]
              : collections.map((child) => ({
                  label: child.name,
                  value: child.id,
                  renderOption: () => (
                    <Link to={PlotRoutes.collection(child.id)}>
                      <IconButton
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          borderRadius: 2,
                          width: '100%',
                          color: theme.colors?.primary.black,
                        }}
                      >
                        <Typography variant="subhead-lg">
                          {child.name}
                        </Typography>
                        {breadcrumbCollection.id === child.id && (
                          <IconCustomTick
                            size={16}
                            color={theme.colors?.primary.black}
                          />
                        )}
                      </IconButton>
                    </Link>
                  ),
                }))
          }
        />
      </Menu>

      {!isLastBreadcrumb && <Typography variant="headline-sm">/</Typography>}
    </>
  );
};
