import { Box, CircularProgress, Typography } from '@mui/material';
import { IconOutlineLogout1 } from 'components/icons/components/outline/IconOutlineLogout1';
import { useUserContext } from 'contexts/users/User.context';
import { theme } from 'styles/theme';
import { TabName } from '../../types';

export const LogoutCTA = () => {
  const { isLoggingOut, logout } = useUserContext();
  return (
    <Box
      component="button"
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 3,
      }}
      onClick={logout}
      disabled={isLoggingOut}
    >
      <Box
        sx={{
          height: 24,
          width: 24,
          borderRadius: 1,
          backgroundColor: 'rgba(128, 5, 50, 0.08)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isLoggingOut ? (
          <CircularProgress
            size={16}
            sx={{
              color: theme.colors?.primary.black,
            }}
          />
        ) : (
          <IconOutlineLogout1
            size={16}
            color={theme.colors?.utility['pink-3']}
          />
        )}
      </Box>
      <Typography variant="subhead-lg" color={theme.colors?.primary.black}>
        {TabName.LOG_OUT}
      </Typography>
    </Box>
  );
};
