import { Box, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

export const RadarEventTimelineItemViewSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        p: 4,
        borderRadius: 3,
        border: `1px solid ${theme.colors?.utility[300]}`,
        bgcolor: theme.colors?.primary.white,
      }}
    >
      <Skeleton variant="circular" width={48} height={48} />
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Skeleton variant="text" width="60%" height={24} />
        <Skeleton variant="text" width="40%" height={20} />
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Skeleton variant="text" width={80} height={32} />
        </Box>
      </Box>
    </Box>
  );
};
