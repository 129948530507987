import { Box, Table, TableHead, TableRow, Typography } from '@mui/material';
import { IconCustomSort } from 'components/icons/components/custom/IconCustomSort';
import { useRenderPostReportedSnackbar } from 'features/socialMediaListening/hooks/useRenderPostReportedSnackbar';
import { SocialListeningPostsSortBy } from 'graphql/generated';
import { theme } from 'styles/theme';
import { SocialMediaListeningListItem } from './socialMediaListeningListItem/SocialMediaListeningListItem';
import { StyledTableHeadingCell } from './styles';
import { SocialMediaListeningPostsListViewProps } from './types';

export const SocialMediaListeningPostsListView = ({
  brandId,
  socialPosts,
  searchCriteria,
  onSortBy,
}: SocialMediaListeningPostsListViewProps) => {
  const {
    renderToast: renderToastForPostReported,
    showToast: showToastForPostReported,
  } = useRenderPostReportedSnackbar();

  const renderSortHeader = (title: string, key: SocialListeningPostsSortBy) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
        <Typography variant="body-lg" fontWeight={600} whiteSpace="nowrap">
          {title}
        </Typography>
        <IconCustomSort
          size={16}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onSortBy(key);
          }}
        />
      </Box>
    );
  };

  return (
    <Table>
      {/* Table headers */}
      <TableHead>
        <TableRow>
          <StyledTableHeadingCell sx={{ width: theme.spacing(10) }}>
            Preview
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{
              minWidth: theme.spacing(60),
              maxWidth: theme.spacing(60),
              width: theme.spacing(60),
            }}
          >
            Key takeaway about brand
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(32) }}>
            Status
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Sentiment
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{
              width: theme.spacing(20),
            }}
          >
            {renderSortHeader(
              'Follower count',
              SocialListeningPostsSortBy.FollowerCount,
            )}
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            {renderSortHeader(
              'Confidence score',
              SocialListeningPostsSortBy.ConfidenceScore,
            )}
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            {renderSortHeader(
              'Post Engagement',
              SocialListeningPostsSortBy.EngagementScore,
            )}
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            Author Engagement
          </StyledTableHeadingCell>
          <StyledTableHeadingCell sx={{ width: theme.spacing(20) }}>
            {renderSortHeader('Views', SocialListeningPostsSortBy.ViewCount)}
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{ width: theme.spacing(50), minWidth: theme.spacing(50) }}
          >
            Topics
          </StyledTableHeadingCell>
          <StyledTableHeadingCell
            sx={{ width: theme.spacing(24), minWidth: theme.spacing(24) }}
          >
            Go to link
          </StyledTableHeadingCell>
        </TableRow>
      </TableHead>

      {/* Table rows */}
      {socialPosts.map((post, index) => (
        <SocialMediaListeningListItem
          brandId={brandId}
          post={post}
          searchCriteria={searchCriteria}
          itemPosition={(index + 1).toString()}
          onCardDeleted={() => {
            showToastForPostReported();
          }}
        />
      ))}
      {renderToastForPostReported()}
    </Table>
  );
};
