import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Collapse } from '@mui/material';
import {
  NestedFiltersMenuItem,
  NestedFiltersMenuItemBaseType,
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuItemType,
} from '../menuItem';
import { NestedFiltersGroupHeader } from './NestedFiltersGroupHeader';

export type NestedFiltersGroupType = NestedFiltersMenuItemBaseType & {
  type: 'group';
  label: string;
  items: NestedFiltersMenuItemType[];
};

export type NestedFiltersGroupProps = {
  group: NestedFiltersGroupType;
  values: Record<string, NestedFiltersMenuItemBaseValueType<unknown>>;
  onChange: (
    values: Record<string, NestedFiltersMenuItemBaseValueType<unknown>>,
  ) => void;
};

export const NestedFiltersGroup = (props: NestedFiltersGroupProps) => {
  const { group, values, onChange } = props;

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <NestedFiltersGroupHeader {...group} onClick={onToggle} open={isOpen} />
      <Collapse
        in={isOpen}
        sx={{
          maxHeight: 300,
          overflowY: 'auto',
        }}
      >
        {group.items.map((item, index) => {
          return (
            <NestedFiltersMenuItem
              key={index}
              menuItem={item}
              {...(item.key
                ? {
                    value: values[item.key] || {},
                    onChange: (newValue) => {
                      onChange({
                        ...values,
                        [item.key as string]: newValue,
                      });
                    },
                  }
                : {})}
            />
          );
        })}
      </Collapse>
    </Box>
  );
};
