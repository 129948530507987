import { gql, useApolloClient } from '@apollo/client';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { useUserContext } from 'contexts/users/User.context';
import {
  COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER,
  CollectionPermissionMember,
} from 'features/collectionPermission/components';
import {
  UpdateCollectionPermissionsDataInput,
  useCollectionPermissionMutations,
} from 'features/collectionPermission/hooks';
import { useCollectionPermissionUpdateNestedCollection } from 'features/collectionPermission/hooks/useCollectionPermissionUpdateNestedCollection';
import {
  GeneralPermissionSelector,
  PermissionApproveRequest,
  PermissionCreateRequest,
  PermissionMemberItem,
  useCopyLink,
  usePermissionAddMemberInput,
  usePermissionUserSearch,
} from 'features/permission';
import { USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW } from 'features/user-profile';
import {
  CollectionFragmentCollectionPermissionDialogBodyFragment,
  CollectionInviteMemberFragmentCollectionPermissionMemberFragment,
  CollectionPermission,
  GeneralPermission,
  PermissionLevel,
  PermissionRequestFragmentPermissionApproveRequestFragmentDoc,
  ShareEntityType,
  UserProfileFragmentUserProfilePermissionItemViewFragment,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { theme } from 'styles/theme';
import { useImmer } from 'use-immer';
import { evictObject } from 'utils/apollo';
import { getFullName } from 'utils/users';
import { CollectionPermissionUpdatesDialog } from '../permissionUpdatesDialog';

export const COLLECTION_FRAGMENT_COLLECTION_PERMISSION_DIALOG_BODY = gql`
  fragment CollectionFragmentCollectionPermissionDialogBody on CollectionModel {
    id
    name
    myPermissions
    generalPermission
    permissionLevel
    organizationId
    organizationName
    myPermissions
    permissionRequests {
      ...PermissionRequestFragmentPermissionApproveRequest
    }
    creator {
      ...UserProfileFragmentUserProfilePermissionItemView
    }
    inviteMembers {
      ...CollectionInviteMemberFragmentCollectionPermissionMember
    }
    childCollections {
      id
    }
    posts(postCount: 3) {
      id
    }
  }
  ${USER_PROFILE_FRAGMENT_USER_PROFILE_PERMISSION_ITEM_VIEW}
  ${COLLECTION_INVITE_MEMBER_FRAGMENT_COLLECTION_PERMISSION_MEMBER}
  ${PermissionRequestFragmentPermissionApproveRequestFragmentDoc}
`;

type CollectionPermissionDialogBodyProps = {
  collection: CollectionFragmentCollectionPermissionDialogBodyFragment;
  onRequestCreateAccess: VoidFunction;
  onCallbackAfterUpdate?: (data: {
    showCustomSelectionView?: boolean;
    closeMainDialog?: boolean;
  }) => void;
  setHasPendingChanges?: (state: boolean) => void;
  updateBtnRef?: React.RefObject<HTMLButtonElement>;

  /**
   * All updates are set to this object
   */
  inputData: UpdateCollectionPermissionsDataInput;
  setInputData: Dispatch<SetStateAction<UpdateCollectionPermissionsDataInput>>;

  setShowingUpdatesConfirmation: (state: boolean) => void;
};

export const CollectionPermissionDialogBody = (
  props: CollectionPermissionDialogBodyProps,
) => {
  const {
    collection,
    onCallbackAfterUpdate,
    setHasPendingChanges,
    updateBtnRef,
    onRequestCreateAccess,
    inputData,
    setInputData,
    setShowingUpdatesConfirmation,
  } = props;
  const { renderCopyButton, renderCopyLinkToast } = useCopyLink({
    entityId: collection.id,
    entityType: ShareEntityType.Collection,
  });
  const { handleUpdateNestedCollectionPostPermissions, savingData } =
    useCollectionPermissionUpdateNestedCollection({
      collectionId: collection.id,
    });

  const searchInputRef = useRef<HTMLInputElement>(null);

  const { user, isWorkOrganization } = useUserContext();
  const navigate = useGuardNavigate();

  // Check if current user is an invited user
  const currentUserCollectionInviteMember = collection.inviteMembers.find(
    (m) => m.user.id === user?.id,
  );

  const hasFullAccess = collection.myPermissions.includes(
    CollectionPermission.Update,
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [note, setNote] = useState('');
  const [shouldCreateComment, setShouldCreateComment] = useState(true);
  const [isInputFocused, setIsInputFocused] = useState(false);

  // this is used to check if the user request is approved then we need to show the same flow as the invite member
  const [approvedRequestUser, setApprovedRequestUser] = useState<
    | (UserProfileFragmentUserProfilePermissionItemViewFragment & {
        permissionLevel?: PermissionLevel;
      })
    | null
  >(null);

  // Keep track of the original invite members
  const originalInviteMembers: CollectionInviteMemberFragmentCollectionPermissionMemberFragment[] =
    useMemo(() => {
      return collection.inviteMembers;
    }, [collection.inviteMembers]);

  const [newSelectedUsers, setNewSelectedUsers] = useState<
    UserProfileFragmentUserProfilePermissionItemViewFragment[]
  >([]);
  const [newSelectedUsersPermission, setNewSelectedUsersPermission] =
    useState<PermissionLevel>(PermissionLevel.View);

  useEffect(() => {
    setShowingUpdatesConfirmation(showConfirmation);
  }, [showConfirmation]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    renderInput,
    toggleUserSelect,
    resetInput,
    renderPermissionSelectView,
  } = usePermissionAddMemberInput({
    entityOrganizationId: collection.organizationId,
    onSearchStrUpdate: setInputValue,
    onMembersUpdate: setNewSelectedUsers,
    onPermissionUpdate: setNewSelectedUsersPermission,
    onShouldCreateCommentUpdate: setShouldCreateComment,
    onNoteUpdate: setNote,
  });

  const [overallPermission, setOverallPermission] = useImmer<{
    generalPermission: GeneralPermission;
    permission?: PermissionLevel;
  }>({
    generalPermission: collection.generalPermission,
    permission: collection.permissionLevel,
  });

  const { suggestedUsers } = usePermissionUserSearch({
    searchStr: inputValue,
    existingMembers: [
      ...originalInviteMembers.map((m) => m.user),
      ...newSelectedUsers,
    ],
  });

  const filterExistingMembers = useMemo(() => {
    return (
      originalInviteMembers
        // Filter out users that have already been marked for removal
        .filter((m) => !inputData.removeMembers?.userIds.includes(m.user.id))
        .filter(
          (m) =>
            m.user.email.toLowerCase().includes(inputValue.toLowerCase()) ||
            (getFullName(m.user)
              .toLowerCase()
              .includes(inputValue.toLowerCase()) &&
              m.id !== user?.id),
        )
    );
  }, [originalInviteMembers, inputData, inputValue, user]);

  const [loading, setLoading] = useState(false);

  // Handle permission changes for EXISTING members
  const handleInviteMemberPermissionChange = (
    inviteMember: CollectionInviteMemberFragmentCollectionPermissionMemberFragment,
    remove?: boolean,
  ) => {
    const memberIndex = originalInviteMembers.findIndex(
      (c) => c.id === inviteMember.id,
    );

    if (memberIndex === -1) {
      return;
    }

    if (remove) {
      setInputData((o) => {
        const newO = { ...o };

        if (!newO.removeMembers) {
          newO.removeMembers = {
            userIds: [],
          };
        }

        newO.removeMembers.userIds.push(inviteMember.user.id);

        return newO;
      });
    } else {
      setInputData((o) => {
        const newO = { ...o };

        if (!newO.inviteMembers) {
          newO.inviteMembers = {
            members: [],
          };
        }

        // Check if the user is already in the list
        const memberIndex = newO.inviteMembers?.members.findIndex(
          (m) => m.email === inviteMember.user.email,
        );

        if (memberIndex !== -1) {
          newO.inviteMembers.members[memberIndex].permission =
            inviteMember.permissionLevel;
        } else {
          newO.inviteMembers.members.push({
            email: inviteMember.user.email,
            permission: inviteMember.permissionLevel,
          });
        }

        return newO;
      });
    }
  };

  const hasExistingMemberDataChanged = useMemo(() => {
    return (
      (inputData.inviteMembers && inputData.inviteMembers.members.length > 0) ||
      (inputData.removeMembers && inputData.removeMembers.userIds.length > 0)
    );
  }, [inputData]);

  const hasOverallPermissionDataChanged = useMemo(() => {
    return (
      collection.generalPermission !== overallPermission.generalPermission ||
      collection.permissionLevel !== overallPermission.permission
    );
  }, [
    collection.generalPermission,
    collection.permissionLevel,
    overallPermission,
  ]);

  const isThereNewInvite = useMemo(() => {
    return newSelectedUsers.length > 0;
  }, [newSelectedUsers]);

  const { updateCollectionPermissions } = useCollectionPermissionMutations({
    collectionIds: [collection.id],
  });
  const client = useApolloClient();

  const updatePermissions = async () => {
    const data: UpdateCollectionPermissionsDataInput = {
      ...inputData,
    };

    if (isThereNewInvite) {
      if (!data.inviteMembers) {
        data.inviteMembers = {
          members: [],
        };
      }

      data.inviteMembers.members.push(
        ...newSelectedUsers.map((n) => ({
          email: n.email,
          permission: newSelectedUsersPermission,
        })),
      );
    }

    if (hasOverallPermissionDataChanged) {
      data.generalPermission = {
        generalPermission: overallPermission.generalPermission,
        permissionLevel: overallPermission.permission,
      };
    }

    data.note = note;
    data.shouldCreateComment = shouldCreateComment;

    let showConfirmation = false;
    const existingCollectionUsers = collection.inviteMembers.map(
      (m) => m.user.email,
    );
    const newInvitedEmails = data?.inviteMembers?.members
      .map((m) => m.email)
      .filter((email) => !existingCollectionUsers.includes(email));

    const collectionHasData =
      collection.posts.length > 0 || collection.childCollections.length > 0;

    if (
      collectionHasData &&
      (newInvitedEmails?.length ||
        data?.removeMembers?.userIds.length ||
        (data?.generalPermission &&
          data?.generalPermission.generalPermission !==
            collection.generalPermission))
    ) {
      showConfirmation = true;
    }

    setInputData(data);

    if (showConfirmation) {
      setShowConfirmation(showConfirmation);
    } else {
      handleUpdate(data);
      onCallbackAfterUpdate?.({ showCustomSelectionView: false });
    }
  };

  const handleUpdate = async (data?: UpdateCollectionPermissionsDataInput) => {
    setLoading(true);

    if (!data) {
      throw new Error('No input data');
    }

    await updateCollectionPermissions(data);

    setLoading(false);
    resetInput();

    // when user removes his/her access to the collection, we gracefully return them back to the previous location
    if (
      user &&
      collection.creator.id !== user.id &&
      overallPermission.generalPermission === GeneralPermission.InviteOnly &&
      (data?.removeMembers?.userIds.includes(user.id) ||
        !data?.inviteMembers?.members.map((m) => m.email).includes(user.email))
    ) {
      evictObject(client.cache, collection.id, 'CollectionModel');
      navigate(PlotRoutes.juicebox());
    }
  };

  const isUpdateDisabled =
    (!hasExistingMemberDataChanged &&
      !isThereNewInvite &&
      !hasOverallPermissionDataChanged) ||
    loading;

  useEffect(() => {
    setHasPendingChanges?.(!isUpdateDisabled);
  }, [isUpdateDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.onfocus = () => {
        setIsInputFocused(true);
      };
      searchInputRef.current.onblur = () => {
        setTimeout(() => {
          setIsInputFocused(false);
        }, 100);
      };
    }
  }, [searchInputRef]);

  const renderMembers = useMemo(() => {
    if (inputValue) {
      return (
        <>
          {filterExistingMembers.length > 0 && (
            <Box sx={{ p: theme.spacing(4, 6) }}>
              <Box mb={2}>
                <Typography
                  fontWeight={600}
                  variant="body-lg"
                  color={theme.colors?.utility['800']}
                >
                  In this collection
                </Typography>
              </Box>

              {filterExistingMembers.map((inviteMember) => (
                <CollectionPermissionMember
                  key={inviteMember.id}
                  collectionOrganizationId={collection.organizationId}
                  collectionInviteMember={inviteMember}
                  onPermissionChange={handleInviteMemberPermissionChange}
                  readonly={!hasFullAccess}
                />
              ))}
            </Box>
          )}

          <Box
            sx={{
              p: theme.spacing(4, 6),
              paddingTop: filterExistingMembers.length === 0 ? 4 : 0,
            }}
          >
            {suggestedUsers.length === 0 && inputValue !== '' && (
              <Typography
                fontWeight={600}
                variant="body-lg"
                color={theme.colors?.utility['800']}
              >
                Keep typing a valid email...
              </Typography>
            )}
            {suggestedUsers.length > 0 && (
              <>
                <Box mb={4}>
                  <Typography
                    fontWeight={600}
                    variant="body-lg"
                    color={theme.colors?.utility['800']}
                  >
                    Not in this collection
                  </Typography>
                </Box>
                {suggestedUsers.slice(0, 5).map((suggestedUser) =>
                  user ? (
                    <PermissionMemberItem
                      key={suggestedUser.id}
                      entityOrganizationId={collection.organizationId}
                      totalMembers={
                        newSelectedUsers.filter(
                          (u) =>
                            !user?.organization.users
                              .filter(
                                (user) => user.shouldBeCountedTowardPaywall,
                              )
                              .some(
                                (internalUser) => internalUser.id === u.id,
                              ) &&
                            u.organization.id === collection.organizationId,
                        ).length
                      }
                      totalGuests={
                        newSelectedUsers.filter((u) => {
                          if (isWorkOrganization) {
                            return (
                              !user?.organization.externalUsers
                                .filter(
                                  (user) => user.shouldBeCountedTowardPaywall,
                                )
                                .some((guest) => guest.id === u.id) &&
                              u.organization.id !== collection.organizationId
                            );
                          }

                          // everyone is a guests for non-work organization
                          return user?.organization.users
                            .filter((user) => user.shouldBeCountedTowardPaywall)
                            .every((internalUser) => internalUser.id !== u.id);
                        }).length
                      }
                      userProfile={suggestedUser}
                      type="suggested"
                      readonly={!hasFullAccess}
                      endAdornmentProps={{
                        suggested: {
                          isSelected: !!newSelectedUsers.find(
                            (u) => u.email === suggestedUser.email,
                          ),
                          toggleSelect: toggleUserSelect,
                        },
                      }}
                    />
                  ) : null,
                )}
              </>
            )}
          </Box>
        </>
      );
    }
    if (originalInviteMembers.length === 0 && isInputFocused) {
      return (
        <Box sx={{ p: theme.spacing(4, 6) }}>
          <Box mb={4}>
            <Typography
              fontWeight={600}
              variant="body-lg"
              color={theme.colors?.utility['800']}
            >
              Suggested
            </Typography>
          </Box>
          {suggestedUsers.slice(0, 6).map((suggestedUser) =>
            user ? (
              <PermissionMemberItem
                key={suggestedUser.id}
                userProfile={suggestedUser}
                entityOrganizationId={collection.organizationId}
                totalMembers={
                  newSelectedUsers.filter(
                    (u) =>
                      !user?.organization.users
                        .filter((user) => user.shouldBeCountedTowardPaywall)
                        .some((internalUser) => internalUser.id === u.id) &&
                      u.organization.id === collection.organizationId,
                  ).length
                }
                totalGuests={
                  newSelectedUsers.filter((u) => {
                    if (isWorkOrganization) {
                      return (
                        !user?.organization.externalUsers
                          .filter((user) => user.shouldBeCountedTowardPaywall)
                          .some((guest) => guest.id === u.id) &&
                        u.organization.id !== collection.organizationId
                      );
                    }

                    // everyone is a guests for non-work organization
                    return user?.organization.users
                      .filter((user) => user.shouldBeCountedTowardPaywall)
                      .every((internalUser) => internalUser.id !== u.id);
                  }).length
                }
                type="suggested"
                readonly={!hasFullAccess}
                endAdornmentProps={{
                  suggested: {
                    isSelected: !!newSelectedUsers.find(
                      (u) => u.email === suggestedUser.email,
                    ),
                    toggleSelect: toggleUserSelect,
                  },
                }}
              />
            ) : null,
          )}
        </Box>
      );
    }
    return (
      <Box sx={{ p: theme.spacing(4, 6) }}>
        {collection.creator && (
          <PermissionMemberItem
            userProfile={collection.creator}
            entityOrganizationId={collection.organizationId}
            type="owner"
          />
        )}

        {filterExistingMembers.map((inviteMember) => (
          <CollectionPermissionMember
            key={inviteMember.id}
            collectionOrganizationId={collection.organizationId}
            collectionInviteMember={inviteMember}
            readonly={!hasFullAccess}
            onPermissionChange={handleInviteMemberPermissionChange}
          />
        ))}
      </Box>
    );
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    isInputFocused,
    originalInviteMembers,
    collection,
    inputValue,
    suggestedUsers,
    filterExistingMembers,
    hasFullAccess,
  ]);

  const onHandleApprovedRequestUser = async (grantAllAccess: boolean) => {
    if (!approvedRequestUser) return;
    const updatedInputData = {
      ...inputData,
      inviteMembers: {
        members: [
          {
            email: approvedRequestUser.email,
            permission: approvedRequestUser.permissionLevel!,
          },
        ],
      },
    };
    if (grantAllAccess) {
      await handleUpdateNestedCollectionPostPermissions(updatedInputData, [
        {
          excludedChildCollectionIds: [],
          excludedPostIds: [],
          mode: 'selectedAll',
          collectionId: collection.id,
          nestedEntities: [],
        },
      ]);
    } else {
      setInputData(updatedInputData);
    }
    onCallbackAfterUpdate?.({
      showCustomSelectionView: !grantAllAccess,
      closeMainDialog: false,
    });
    setApprovedRequestUser(null);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: showConfirmation ? 'flex' : 'none',
          alignItems: showConfirmation ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          backgroundColor: theme.colors?.primary.parchment,
          gap: 2,
          padding: theme.spacing(4, 6),
        }}
      >
        <CollectionPermissionUpdatesDialog
          collection={collection}
          addedUserEmails={newSelectedUsers.map((u) => u.email) || []}
          generalPermissionUpdate={
            inputData?.generalPermission?.generalPermission
          }
          removedUserIds={inputData?.removeMembers?.userIds || []}
          onBackPress={() => setShowConfirmation(false)}
          loading={savingData}
          onClickYes={async () => {
            await handleUpdate(inputData);
            await handleUpdateNestedCollectionPostPermissions(inputData, [
              {
                excludedChildCollectionIds: [],
                excludedPostIds: [],
                mode: 'selectedAll',
                collectionId: collection.id,
                nestedEntities: [],
              },
            ]);
            onCallbackAfterUpdate?.({ showCustomSelectionView: false });
          }}
          onClickCustomSelect={async () => {
            await handleUpdate(inputData);
            setShowConfirmation(false);
            onCallbackAfterUpdate?.({ showCustomSelectionView: true });
          }}
        />
      </Box>

      <Box display={showConfirmation ? 'none' : 'flex'} flexDirection="column">
        <Box
          sx={{
            position: 'relative',
            borderRadius: theme.spacing(6, 6, 0, 0),
            flexDirection: 'column',
            backdropFilter: 'blur(20px)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              backgroundColor: `rgba(250, 243, 236, 0.85)`,
              gap: 2,
              padding: theme.spacing(4, 6),
              ...(newSelectedUsers.length
                ? { paddingLeft: 4, alignItems: 'flex-start' }
                : {}),
              maxHeight: 110,
              overflowY: 'auto',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              scrollbarWidth: 0,
              msOverflowStyle: 'none',
            }}
          >
            {hasFullAccess ? (
              renderInput({
                sx: { width: '100%' },
                inputRef: searchInputRef,
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2,
                }}
              >
                <IconBoldInfoCircle
                  size={16}
                  color={theme.colors?.utility[600]}
                  style={{
                    marginTop: 2,
                  }}
                />
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.utility[600]}
                >
                  Only people with full access can change permissions
                </Typography>
              </Box>
            )}
            {renderCopyButton()}
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
              backgroundColor: 'rgba(250, 243, 236, 0.8)',
              height: 408,
            }}
          >
            {/**
             * Show permission select view if
             * 1. No input value (user not searching for a user)
             * 2. User has added some new users to invite
             */}
            {!inputValue && newSelectedUsers.length ? (
              renderPermissionSelectView()
            ) : (
              // Other wise, show existing list of members
              <>
                {/**
                 * Show list of people that requested access if user is the creator of the collection
                 */}
                {collection.creator.id === user?.id &&
                  (collection.permissionRequests || []).length > 0 && (
                    <Box
                      padding={theme.spacing(6, 6, 0, 6)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 6,
                      }}
                    >
                      <Typography
                        variant="headline-xs"
                        color={theme.colors?.utility[700]}
                      >
                        {(collection.permissionRequests || []).length} person
                        requested access
                      </Typography>

                      {collection.permissionRequests?.map((request) => (
                        <PermissionApproveRequest
                          entityOrganizationId={collection.organizationId}
                          request={request}
                          onAfterApprove={(permissionLevel) => {
                            const collectionHasData =
                              collection.posts.length > 0 ||
                              collection.childCollections.length > 0;

                            if (collectionHasData) {
                              setApprovedRequestUser({
                                ...request.user,
                                permissionLevel,
                              });
                            }
                          }}
                        />
                      ))}
                      <Divider
                        sx={{
                          borderColor: 'rgba(35, 6, 3, 0.07)',
                          borderWidth: 0.5,
                        }}
                      />
                    </Box>
                  )}
                {/**
                 * If user is an invited member of the collection, and doesn't have update access yet,
                 * show UI to allow user to request access
                 * */}
                {currentUserCollectionInviteMember &&
                  !collection.myPermissions.includes(
                    CollectionPermission.Update,
                  ) && (
                    <Box
                      padding={theme.spacing(6, 6, 0, 6)}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 6,
                      }}
                    >
                      <PermissionCreateRequest
                        entityOrganizationId={collection.organizationId}
                        onRequestCreateAccess={onRequestCreateAccess}
                        permissionLevel={
                          currentUserCollectionInviteMember.permissionLevel
                        }
                        isRequestCreated={collection.permissionRequests?.some(
                          (r) => r.user.id === user?.id,
                        )}
                      />
                      <Divider
                        sx={{
                          borderColor: 'rgba(35, 6, 3, 0.07)',
                          borderWidth: 0.5,
                        }}
                      />
                    </Box>
                  )}
                {renderMembers}
              </>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backdropFilter: hasFullAccess ? 'blur(20px)' : 'none',
          }}
        >
          {collection.generalPermission && (
            <GeneralPermissionSelector
              readonly={!hasFullAccess}
              entityType="collection"
              organizationId={collection.organizationId}
              organizationName={collection.organizationName}
              initialGeneralPermission={collection.generalPermission}
              initialPermissionLevel={
                collection.permissionLevel || PermissionLevel.View
              }
              onGeneralPermissionChange={(
                generalPermission,
                permissionLevel,
              ) => {
                setOverallPermission((draft) => {
                  draft.generalPermission = generalPermission;
                  draft.permission = permissionLevel;
                });
              }}
              componentProps={{
                sx: {
                  bgcolor: 'rgba(250, 243, 236, 0.85)',
                  backdropFilter: 'blur(20px)',
                  p: theme.spacing(4, 6),
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderRadius: hasFullAccess ? 0 : theme.spacing(0, 0, 6, 6),
                },
              }}
            />
          )}

          <Button
            ref={updateBtnRef}
            disabled={isUpdateDisabled}
            variant="primary-alt"
            fullWidth
            onClick={updatePermissions}
            sx={{
              height: 42,
              borderRadius: theme.spacing(0, 0, 6, 6),
              opacity: hasFullAccess ? 1 : 0,
            }}
            startIcon={loading ? <CircularProgress size="1rem" /> : null}
          >
            Update
          </Button>
        </Box>
        {renderCopyLinkToast()}
        {approvedRequestUser && (
          <Dialog
            open={!!approvedRequestUser}
            PaperProps={{
              sx: {
                backgroundColor: theme.colors?.primary.parchment,
                gap: 2,
                padding: theme.spacing(4, 6),
                maxWidth: 500,
              },
            }}
          >
            <CollectionPermissionUpdatesDialog
              collection={collection}
              addedUserEmails={[approvedRequestUser.email]}
              generalPermissionUpdate={
                inputData?.generalPermission?.generalPermission
              }
              removedUserIds={[]}
              loading={savingData}
              onClickYes={async () => {
                await onHandleApprovedRequestUser(true);
              }}
              onClickCustomSelect={async () => {
                await onHandleApprovedRequestUser(false);
              }}
            />
          </Dialog>
        )}
      </Box>
    </Box>
  );
};
