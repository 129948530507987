import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { PlotRoutes } from 'Routes';
import { TextSwitch } from 'components/common/TextSwitch';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import { IconBoldMessages3 } from 'components/icons/components/bold/IconBoldMessages3';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useUserContext } from 'contexts/users/User.context';
import { postMessageToMobileApp } from 'features/billing/utils';
import {
  BillingPlanName,
  BillingSubscriptionStatus,
  InternalOrganizationRole,
  OrganizationType,
  useGetBillingAvailablePricesQuery,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { theme } from 'styles/theme';
import { DateUtils } from 'utils/date';
import { useAllBillingPlans, useBillingMutations } from '../../hooks';
import { BillingDowngradeModal } from '../downgrade';
import { BillingFreeTrialSuccessModal } from '../freeTrialSuccess';
import { Plan, plans } from './types';
import { BillingCanUpgradeDialog } from '../canUpgrade';

type PlanOptionsProps = {
  title?: string;
  onSelected?: VoidFunction;
  onGoBack?: VoidFunction;
};

export const BillingPlanOptions = forwardRef(
  (props: PlanOptionsProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { title, onSelected, onGoBack } = props;

    const navigate = useGuardNavigate();
    const analytics = useAnalytics();

    const { user, orgBilling, isMobileAppWebView, isEnterpriseOrganization } =
      useUserContext();
    const isMobileView = useMediaQueryMobile();

    const isMobileViewActive = useMemo(() => {
      return isMobileView || isMobileAppWebView;
    }, [isMobileView, isMobileAppWebView]);

    const [selectedPriceInterval, setSelectedPriceInterval] = useState<
      'month' | 'year'
    >('year');

    const { data, loading } = useGetBillingAvailablePricesQuery();
    const availablePrices = data?.getAvailablePrices ?? [];

    const isAdmin = useMemo(() => {
      return user?.role === InternalOrganizationRole.Admin;
    }, [user?.role]);

    const allPlans = useAllBillingPlans();

    const processedPlan = useMemo(() => {
      if (isEnterpriseOrganization) {
        return plans.filter(
          (plan) =>
            plan.value === BillingPlanName.Free ||
            plan.value === BillingPlanName.Enterprise,
        );
      }
      return plans;
    }, [user?.organization.type]);

    const handleContactSales = () => {
      window.open('https://plotworkspace.typeform.com/plot-demo', '_blank');
      analytics.track(EventName.PlanSelected, {
        userId: user?.id,
        organizationId: user?.organization.id,
        organizationName: user?.organization.name,
        currentPlan: orgBilling?.plan,
        selectedPlan: BillingPlanName.Standard,
      });
    };

    const { requestAdminToUpgrade } = useBillingMutations();
    const handleRequestAdminToUpgrade = async (
      requestPlan: BillingPlanName,
    ) => {
      await requestAdminToUpgrade(requestPlan);
    };

    const {
      isOpen: downgradeOpen,
      onOpen: downgradeOnOpen,
      onClose: downgradeOnClose,
    } = useDisclosure();
    const handleDowngrade = () => {
      const typeFormLink = 'https://plotworkspace.typeform.com/to/zUF9e3Zx';
      if (isMobileViewActive) {
        // open the typeForm link in a form sheet modal on mobile app
        postMessageToMobileApp({
          message: typeFormLink,
          options: {
            behavior: 'openLinkInModalScreen',
            presentation: 'modal',
          },
        });
        return;
      }
      downgradeOnOpen();
    };

    const {
      isOpen: canUpgradeOpen,
      onOpen: canUpgradeOnOpen,
      onClose: canUpgradeOnClose,
    } = useDisclosure();
    const [selectedPlan, setSelectedPlan] = useState<{
      plan: BillingPlanName;
      type: 'upgrade' | 'freeTrial';
    }>({
      plan: BillingPlanName.Free,
      type: 'upgrade',
    });
    const canUpgradeOrFreeTrial = useCallback(
      (planName: BillingPlanName, type: 'upgrade' | 'freeTrial') => {
        setSelectedPlan({
          plan: planName,
          type,
        });
        const planData = allPlans[planName];

        if (planData?.organizationMemberLimit) {
          return (
            (orgBilling?.organizationMemberUsage ?? 0) <=
            planData.organizationMemberLimit
          );
        }

        return true;
      },
      [orgBilling?.organizationMemberUsage, allPlans],
    );

    const handleUpgrade = (planName: BillingPlanName) => {
      if (canUpgradeOrFreeTrial(planName, 'upgrade')) {
        onSelected?.();
        navigate(PlotRoutes.billingCheckout({ planName }));
      } else {
        canUpgradeOnOpen();
      }
    };

    const { isOpen: freeTrialSuccessOpen, onOpen: freeTrialSuccessOnOpen } =
      useDisclosure();

    const { createFreeTrialSubscription } = useBillingMutations();
    const [createFreeTrialLoading, setCreateFreeTrialLoading] = useState<
      BillingPlanName | undefined
    >();
    const handleTryFreeTrial = async (planName: BillingPlanName) => {
      if (canUpgradeOrFreeTrial(planName, 'freeTrial')) {
        setCreateFreeTrialLoading(planName);
        createFreeTrialSubscription(planName)
          .then(() => {
            freeTrialSuccessOnOpen();
          })
          .finally(() => {
            setCreateFreeTrialLoading(undefined);
          });
      } else {
        canUpgradeOnOpen();
      }
    };

    const getPlanNameValue = (planName: BillingPlanName) => {
      if (planName === BillingPlanName.Basic) {
        return 1;
      }
      if (planName === BillingPlanName.Standard) {
        return 2;
      }
      if (planName === BillingPlanName.Enterprise) {
        return 3;
      }
      return 0;
    };

    const renderSubscriptionStatus = (planName: BillingPlanName) => {
      if (
        orgBilling?.plan === planName &&
        orgBilling?.subscription?.status ===
          BillingSubscriptionStatus.Trialing &&
        orgBilling.subscription?.trialEnd
      ) {
        return `Your Free Trial expires in ${DateUtils.fromNowInDays(
          (orgBilling?.subscription?.trialEnd || 0) * 1000,
        )}`;
      }

      return null;
    };

    const renderPrice = (planName: BillingPlanName) => {
      if (loading) {
        return <Skeleton />;
      }

      const planPrices =
        availablePrices?.find((p) => p.name === planName)?.prices ?? [];

      const selectedPrice = planPrices.find(
        (p) => p.recurringInterval === selectedPriceInterval,
      );

      let monthlyPrice = 0;
      if (selectedPrice && selectedPrice.amount) {
        monthlyPrice =
          selectedPriceInterval === 'month'
            ? selectedPrice.amount / 100
            : selectedPriceInterval === 'year'
            ? selectedPrice.amount / 100 / 12
            : 0;
      }

      switch (planName) {
        case BillingPlanName.Free:
        case BillingPlanName.Basic:
        case BillingPlanName.Standard:
          return (
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(3),
                alignItems: 'center',
              }}
            >
              <Typography
                variant="headline-xl"
                fontSize={28}
                color={theme.colors?.primary.black}
              >
                ${monthlyPrice}
              </Typography>
              <Typography
                variant="subhead-md"
                color={theme.colors?.utility[700]}
              >
                per user/month
              </Typography>
            </Box>
          );
        case BillingPlanName.Enterprise:
          return (
            <Box
              sx={{
                display: 'flex',
                gap: theme.spacing(3),
                alignItems: 'center',
              }}
            >
              <IconBoldMessages3 size={40} />
              <Typography
                variant="subhead-md"
                color={theme.colors?.utility[700]}
              >
                Chat with sales for pricing
              </Typography>
            </Box>
          );
        default:
          return null;
      }
    };

    const renderActionButton = (plan: Plan) => {
      if (
        orgBilling?.plan === plan.value &&
        orgBilling.subscription?.status !== BillingSubscriptionStatus.Trialing
      ) {
        return (
          <Button
            variant="outlined"
            fullWidth
            disabled
            sx={{
              color: theme.colors?.utility[600],
              background: theme.colors?.utility[300],
              borderRadius: theme.spacing(2),
              py: theme.spacing(3),
              border: 'none !important',
            }}
          >
            Current Plan
          </Button>
        );
      }

      if (plan.value === BillingPlanName.Enterprise) {
        return isAdmin ? (
          <Button
            variant="outlined"
            fullWidth
            disabled={!!createFreeTrialLoading}
            sx={{
              py: theme.spacing(3),
              color: theme.colors?.primary.black,
              border: `2px solid ${theme.colors?.utility[300]}`,
              background: theme.colors?.primary.white,
              borderRadius: theme.spacing(2),
              boxShadow: '0px 2px 10px -3px #0000000D',
              ':hover': {
                bgcolor: 'transparent',
                border: `2px solid ${theme.colors?.utility[300]}`,
              },
            }}
            onClick={handleContactSales}
          >
            Contact Sales
          </Button>
        ) : (
          <Button
            variant="outlined"
            fullWidth
            disabled={
              !!createFreeTrialLoading ||
              !!(
                orgBilling?.requestedAdminToUpgradePlans &&
                orgBilling?.requestedAdminToUpgradePlans.includes(
                  BillingPlanName.Enterprise,
                )
              )
            }
            sx={{
              py: theme.spacing(3),
              color: theme.colors?.primary.black,
              border: `2px solid ${theme.colors?.utility[300]}`,
              background: theme.colors?.primary.white,
              borderRadius: theme.spacing(2),
              boxShadow: '0px 2px 10px -3px #0000000D',
              ':hover': {
                bgcolor: 'transparent',
                border: `2px solid ${theme.colors?.utility[300]}`,
              },
              '&.Mui-disabled': {
                borderColor: 'transparent',
              },
            }}
            onClick={() =>
              handleRequestAdminToUpgrade(BillingPlanName.Enterprise)
            }
          >
            {orgBilling?.requestedAdminToUpgradePlans &&
            orgBilling?.requestedAdminToUpgradePlans.includes(
              BillingPlanName.Enterprise,
            )
              ? 'Requested Admin'
              : 'Request Admin to Upgrade'}
          </Button>
        );
      }

      if (
        orgBilling?.plan === BillingPlanName.Free ||
        // allow users to upgrade during free trial for Basic and Standard
        (orgBilling?.subscription?.status ===
          BillingSubscriptionStatus.Trialing &&
          (plan.value === BillingPlanName.Basic ||
            plan.value === BillingPlanName.Standard)) ||
        (orgBilling?.plan === BillingPlanName.Basic &&
          plan.value === BillingPlanName.Standard)
      ) {
        return isAdmin ? (
          <Button
            variant="outlined"
            fullWidth
            disabled={!!createFreeTrialLoading}
            sx={{
              py: theme.spacing(3),
              color: plan.isSuggested
                ? theme.colors?.primary.white
                : theme.colors?.primary.black,
              border: `2px solid ${theme.colors?.utility[300]}`,
              background: plan.isSuggested
                ? theme.colors?.primary.black
                : theme.colors?.primary.white,
              borderRadius: theme.spacing(2),
              boxShadow: '0px 2px 10px -3px #0000000D',
              ':hover': {
                bgcolor: plan.isSuggested
                  ? theme.colors?.primary.black
                  : 'transparent',
                border: `2px solid ${theme.colors?.utility[300]}`,
              },
              '&.Mui-disabled': {
                background: theme.colors?.primary.white,
              },
            }}
            onClick={() => handleUpgrade(plan.value)}
          >
            Upgrade
          </Button>
        ) : (
          <Button
            variant="outlined"
            fullWidth
            disabled={
              !!createFreeTrialLoading ||
              !!(
                orgBilling?.requestedAdminToUpgradePlans &&
                ((plan.value === BillingPlanName.Standard &&
                  orgBilling?.requestedAdminToUpgradePlans.includes(
                    BillingPlanName.Standard,
                  )) ||
                  (plan.value === BillingPlanName.Basic &&
                    orgBilling?.requestedAdminToUpgradePlans.includes(
                      BillingPlanName.Basic,
                    )))
              )
            }
            sx={{
              py: theme.spacing(3),
              color: plan.isSuggested
                ? theme.colors?.primary.white
                : theme.colors?.primary.black,
              border: `2px solid ${theme.colors?.utility[300]}`,
              bgcolor: plan.isSuggested
                ? theme.colors?.primary.black
                : 'transparent',
              borderRadius: theme.spacing(2),
              boxShadow: '0px 2px 10px -3px #0000000D',
              ':hover': {
                bgcolor: plan.isSuggested
                  ? theme.colors?.primary.black
                  : 'transparent',
                border: `2px solid ${theme.colors?.utility[300]}`,
              },
              '&.Mui-disabled': {
                color: plan.isSuggested
                  ? theme.colors?.primary.white
                  : 'rgba(0, 0, 0, 0.26)',
                bgcolor: plan.isSuggested
                  ? theme.colors?.utility[500]
                  : 'transparent',
                borderColor: 'transparent',
              },
            }}
            onClick={() => handleRequestAdminToUpgrade(plan.value)}
          >
            {orgBilling?.requestedAdminToUpgradePlans &&
            ((plan.value === BillingPlanName.Standard &&
              orgBilling?.requestedAdminToUpgradePlans.includes(
                BillingPlanName.Standard,
              )) ||
              (plan.value === BillingPlanName.Basic &&
                orgBilling?.requestedAdminToUpgradePlans.includes(
                  BillingPlanName.Basic,
                )))
              ? 'Requested Admin'
              : 'Request Admin to Upgrade'}
          </Button>
        );
      }

      if (
        (orgBilling?.plan === BillingPlanName.Basic &&
          plan.value === BillingPlanName.Free) ||
        (orgBilling?.plan === BillingPlanName.Standard &&
          (plan.value === BillingPlanName.Free ||
            plan.value === BillingPlanName.Basic))
      ) {
        return (
          <Button
            variant="outlined"
            fullWidth
            disabled={!isAdmin || !!createFreeTrialLoading}
            sx={{
              py: theme.spacing(3),
              color: theme.colors?.utility[600],
              border: `2px solid ${theme.colors?.utility[300]}`,
              background: theme.colors?.primary.parchment,
              borderRadius: theme.spacing(2),
              boxShadow: '0px 2px 10px -3px #0000000D',
              ':hover': {
                bgcolor: 'transparent',
                border: `2px solid ${theme.colors?.utility[300]}`,
              },
              '&.Mui-disabled': {
                borderColor: 'transparent',
              },
            }}
            onClick={handleDowngrade}
          >
            Downgrade
          </Button>
        );
      }

      return null;
    };

    return (
      <>
        <Box
          ref={ref}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: isMobileViewActive ? theme.spacing(6) : theme.spacing(3),
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobileAppWebView ? 'column' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              pt: isMobileAppWebView ? theme.spacing(6) : 0,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {onGoBack && (
                <IconButton onClick={onGoBack}>
                  <IconOutlineArrowLeft
                    size={32}
                    color={theme.colors?.primary.black}
                  />
                </IconButton>
              )}

              {title && !isMobileAppWebView && (
                <Typography
                  variant={isMobileViewActive ? 'headline-md' : 'headline-lg'}
                >
                  {title}
                </Typography>
              )}
            </Box>

            {user?.organization.type !== OrganizationType.EnterpriseLead && (
              <TextSwitch
                textOptionLeft={
                  <Typography
                    variant={isMobileViewActive ? 'headline-xs' : 'headline-md'}
                  >
                    Annual (40% off)
                  </Typography>
                }
                textOptionRight={
                  <Typography
                    variant={isMobileViewActive ? 'headline-xs' : 'headline-md'}
                  >
                    Monthly
                  </Typography>
                }
                selectedOptionColor={
                  selectedPriceInterval === 'year'
                    ? theme.colors?.utility['green-4']
                    : theme.colors?.utility['yellow-4']
                }
                isLeftOptionSelected={selectedPriceInterval === 'year'}
                sx={{
                  bgcolor: theme.colors?.utility[275],
                  width: theme.spacing(isMobileViewActive ? 64 : 93),
                  alignSelf: 'center',
                  color: theme.colors?.utility[700],
                }}
                onClick={(isLeftOptionSelected) =>
                  setSelectedPriceInterval(
                    isLeftOptionSelected ? 'year' : 'month',
                  )
                }
                componentProps={{
                  text: {
                    sx: {
                      ...theme.typography['headline-md'],
                    },
                  },
                  indicator: {
                    sx: {
                      boxShadow: `0px 1px 4px 0px #00000029`,
                      bgcolor:
                        selectedPriceInterval === 'month'
                          ? theme.colors?.utility['yellow-1']
                          : theme.colors?.utility['green-1'],
                    },
                  },
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: theme.spacing(4),
              flexDirection: isMobileViewActive ? 'column' : 'row',
              height: '100%',
            }}
          >
            {processedPlan.map((plan, index) => {
              const isPlanTrialable =
                plan.value !== orgBilling?.plan &&
                (orgBilling?.highestFreeTrialUsed
                  ? getPlanNameValue(plan.value) >
                    getPlanNameValue(orgBilling?.highestFreeTrialUsed)
                  : true);

              return (
                <Box
                  key={`${plan.name}-${index}`}
                  sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    width: isMobileViewActive
                      ? '100%'
                      : `${95 / processedPlan.length}%`,
                    borderRadius: theme.spacing(6),
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      overflow: 'hidden',
                      borderRadius: theme.spacing(6),
                      border: plan.isSuggested
                        ? '5px solid #23060333'
                        : `1px solid ${theme.colors?.utility[400]}`,
                      height: isMobileViewActive ? 'auto' : '100%',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderTopLeftRadius: '5px',
                        gap: theme.spacing(2),
                        padding: theme.spacing(6, 4, 4, 4),
                        bgcolor: plan.isSuggested
                          ? theme.colors?.primary.black
                          : theme.colors?.primary.white,
                        color: plan.isSuggested
                          ? theme.colors?.primary.white
                          : theme.colors?.primary.black,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="headline-lg">
                          {plan.emoji}
                        </Typography>

                        {plan.isSuggested && (
                          <Box
                            sx={{
                              bgcolor: theme.colors?.utility['yellow-1'],
                              width: theme.spacing(31),
                              padding: theme.spacing(1, 3),
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              borderRadius: theme.spacing(8),
                            }}
                          >
                            <Typography
                              variant="headline-xxs"
                              color={theme.colors?.utility['yellow-4']}
                            >
                              SUGGESTED
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Typography variant="headline-lg">{plan.name}</Typography>
                      <Typography
                        variant="subhead-md"
                        color={
                          plan.isSuggested
                            ? theme.colors?.primary.white
                            : theme.colors?.utility[700]
                        }
                      >
                        {plan.description}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(6),
                        padding: theme.spacing(4, 4, 6, 4),
                        bgcolor: theme.colors?.utility[250],
                        height: isMobileViewActive ? 'auto' : '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing(3),
                        }}
                      >
                        {renderPrice(plan.value)}
                        {renderActionButton(plan)}

                        {plan.isTrialable &&
                          (createFreeTrialLoading &&
                          createFreeTrialLoading === plan.value ? (
                            <Typography
                              variant="subhead-lg"
                              color={theme.colors?.utility[800]}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: theme.spacing(2),
                              }}
                            >
                              Please wait{' '}
                              <CircularProgress
                                size={14}
                                sx={{ color: theme.colors?.utility[800] }}
                              />
                            </Typography>
                          ) : (
                            <Typography
                              variant="headline-sm"
                              sx={{
                                opacity:
                                  !createFreeTrialLoading && isPlanTrialable
                                    ? 1
                                    : 0.25,
                              }}
                            >
                              {renderSubscriptionStatus(plan.value) ??
                                (isAdmin ? (
                                  <>
                                    or{' '}
                                    <Typography
                                      variant="headline-sm"
                                      sx={{
                                        textDecoration: 'underline',
                                        cursor:
                                          !createFreeTrialLoading &&
                                          isPlanTrialable
                                            ? 'pointer'
                                            : 'inherit',
                                      }}
                                      onClick={() => {
                                        // if free trial is creating then disable
                                        if (
                                          !createFreeTrialLoading &&
                                          isPlanTrialable
                                        ) {
                                          handleTryFreeTrial(plan.value);
                                        }
                                      }}
                                    >
                                      Free Trial
                                    </Typography>
                                  </>
                                ) : null)}
                            </Typography>
                          ))}
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: theme.spacing(3.25),
                        }}
                      >
                        {plan.features.map((feature, index) => (
                          <Box
                            key={`${feature.name}-${index}`}
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              gap: theme.spacing(2),
                            }}
                          >
                            {feature.icon}
                            <Typography
                              variant="subhead-md"
                              sx={{ fontWeight: feature.isBold ? 600 : 500 }}
                            >
                              {feature.name}
                            </Typography>
                            {feature.description && (
                              <Tooltip
                                title={feature.description}
                                PopperProps={{ sx: { zIndex: 1500 } }}
                              >
                                <IconButton size="small" sx={{ p: 0 }}>
                                  <IconBoldInfoCircleAlt size={18} />
                                </IconButton>
                              </Tooltip>
                            )}
                            {feature.isComingSoon && (
                              <Typography variant="headline-xxs">
                                - Coming Soon
                              </Typography>
                            )}
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <BillingFreeTrialSuccessModal isOpen={freeTrialSuccessOpen} />
          <BillingDowngradeModal
            isOpen={downgradeOpen}
            onClose={downgradeOnClose}
          />
        </Box>

        <BillingCanUpgradeDialog
          plan={selectedPlan.plan}
          isOpen={canUpgradeOpen}
          onOpen={canUpgradeOnOpen}
          onClose={canUpgradeOnClose}
          selectedType={selectedPlan.type}
        />
      </>
    );
  },
);
