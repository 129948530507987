import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import {
  SocialPostCommentFragmentSocialPostCommentCardViewFragment,
  SortOrder,
} from 'graphql/generated';
import { CSSProperties } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { IconCustomSort } from 'components/icons/components/custom/IconCustomSort';
import { IconCustomSortAsc } from 'components/icons/components/custom/IconCustomSortAsc';
import { IconCustomSortDesc } from 'components/icons/components/custom/IconCustomSortDesc';
import { SocialPostCommentsListViewTableRow } from './SocialPostCommentsListViewTableRow';

type SortConfig = {
  field: 'diggCount' | 'childCommentCount';
  order: SortOrder;
} | null;

export const socialPostCommentsTableHeaders = [
  {
    label: 'Date',
    key: 'date',
    width: '12%',
  },
  {
    label: 'Post',
    key: 'post',
    width: '4%',
  },
  {
    label: 'Question',
    key: 'question',
    width: '42%',
  },
  {
    label: 'Commented by',
    key: 'commented_by',
    width: '18%',
  },
  {
    label: 'Likes',
    key: 'likes',
    width: '8%',
    sortable: true,
    sortField: 'diggCount' as const,
  },
  {
    label: 'Comments',
    key: 'comments',
    width: '8%',
    sortable: true,
    sortField: 'childCommentCount' as const,
  },
  {
    label: 'Links',
    key: 'links',
    width: '8%',
  },
];

export type SocialPostCommentsListViewProps = {
  socialPostComments: SocialPostCommentFragmentSocialPostCommentCardViewFragment[];
  brandId: string;
  hasMore?: boolean;
  loadMore?: VoidFunction;
  style?: CSSProperties;
  columnSort?: SortConfig;
  onColumnSort?: (field: 'diggCount' | 'childCommentCount') => void;
};

// table view
export const SocialPostCommentsListView = ({
  socialPostComments,
  brandId,
  hasMore,
  loadMore,
  style,
  columnSort,
  onColumnSort,
}: SocialPostCommentsListViewProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      sx={{ overflow: 'auto', ...style }}
    >
      <InfiniteScroll
        useWindow={false}
        loadMore={() => {
          if (loadMore && hasMore) {
            loadMore();
          }
        }}
        hasMore={hasMore}
        threshold={100}
      >
        <Box display="flex" width="100%" flexDirection="column">
          <Table>
            <TableHead>
              <TableRow>
                {socialPostCommentsTableHeaders.map((header, idx) => (
                  <TableCell
                    key={`socialPostCommentsTableHeader-${header.key}`}
                    sx={{
                      padding: theme.spacing(2.5),
                      borderBottom: '1px solid rgba(224, 224, 224, 1)',
                      width: header.width,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(1),
                      }}
                    >
                      <Typography
                        variant="headline-xs"
                        color={theme.colors?.utility[700]}
                      >
                        {header.label}
                      </Typography>
                      {header.sortable && onColumnSort && (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            ml: 1,
                          }}
                          onClick={() => {
                            if (
                              !columnSort ||
                              columnSort.field !== header.sortField
                            ) {
                              // If no sort or different field, set to desc first
                              onColumnSort(header.sortField);
                            } else if (columnSort.order === SortOrder.Desc) {
                              // If already desc, switch to asc
                              onColumnSort(header.sortField);
                            } else {
                              // If asc, clear sort
                              onColumnSort(header.sortField);
                            }
                          }}
                        >
                          {!columnSort ||
                          columnSort.field !== header.sortField ? (
                            <IconCustomSort
                              size={16}
                              color={theme.colors?.utility[600]}
                            />
                          ) : columnSort.order === SortOrder.Asc ? (
                            <IconCustomSortAsc
                              size={16}
                              color={theme.colors?.utility[800]}
                            />
                          ) : (
                            <IconCustomSortDesc
                              size={16}
                              color={theme.colors?.utility[800]}
                            />
                          )}
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {socialPostComments.map((comment, idx) => {
                return (
                  <SocialPostCommentsListViewTableRow
                    key={`socialPostComments-${comment.id}.${idx}`}
                    socialPostComment={comment}
                    brandId={brandId}
                  />
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
