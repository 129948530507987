import { Box, Typography } from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomYoutubeShorts } from 'components/icons/components/custom/IconCustomYoutubeShorts';
import { InstagramCreatorsDialogButton } from './sections/instagramCreatorsDialogButton';
import { TiktokCreatorsDialogButton } from './sections/tiktokCreatorsDialogButton';
import { YoutubeCreatorsDialogButton } from './sections/youtubeCreatorsDialogButton';

type Props = {
  tiktokHandle?: string;
  instagramHandle?: string;
  youtubeHandle?: string;
  topicId: string;
  brandId: string;
};

export const SocialMediaListeningManageCreatorsView = ({
  topicId,
  instagramHandle,
  tiktokHandle,
  youtubeHandle,
  brandId,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {tiktokHandle && (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography
            variant="headline-xxs"
            display="flex"
            gap={1}
            alignItems="center"
          >
            <IconCustomTiktok size={16} /> TikTok
          </Typography>
          <TiktokCreatorsDialogButton
            brandId={brandId}
            topicId={topicId}
            tiktokHandle={tiktokHandle}
          />
        </Box>
      )}
      {instagramHandle && (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography
            variant="headline-xxs"
            display="flex"
            gap={1}
            alignItems="center"
          >
            <IconBoldInstagram size={16} /> Instagram
          </Typography>

          <InstagramCreatorsDialogButton
            brandId={brandId}
            instagramHandle={instagramHandle}
            topicId={topicId}
          />
        </Box>
      )}
      {youtubeHandle && (
        <Box display="flex" width="100%" justifyContent="space-between">
          <Typography
            variant="headline-xxs"
            display="flex"
            gap={1}
            alignItems="center"
          >
            <IconCustomYoutubeShorts size={16} /> Youtube Shorts
          </Typography>
          <YoutubeCreatorsDialogButton
            brandId={brandId}
            youtubeHandle={youtubeHandle}
            topicId={topicId}
          />
        </Box>
      )}
    </Box>
  );
};
