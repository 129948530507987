import { Box, Button, SxProps, TextField, Typography } from '@mui/material';
import { CustomController } from 'components/common/form/CustomController';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { typography } from 'components/common/Typography/styles';
import React, { ComponentProps } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { theme } from 'styles/theme';
import {
  NestedFiltersMenuItemBaseType,
  NestedFiltersMenuItemBaseValueType,
} from '../menuItem';

export type NestedFiltersFormFieldType = {
  type: 'number' | 'checkbox';
  key: string;
  label: string;
  sx?: SxProps;
  rules?: ComponentProps<typeof Controller>['rules'];
};

export type NestedFiltersFormType = NestedFiltersMenuItemBaseType & {
  type: 'form';
  label?: string;
  fields: NestedFiltersFormFieldType[];
};

export type NestedFiltersFormProps = {
  form: NestedFiltersFormType;
  value: NestedFiltersMenuItemBaseValueType<Record<string, unknown>>;
  onChange: (
    value: NestedFiltersMenuItemBaseValueType<Record<string, unknown>>,
  ) => void;
};

export const NestedFiltersForm = (props: NestedFiltersFormProps) => {
  const { form, value: _value, onChange } = props;

  const value = _value.value;

  const defaultValues = {
    ...form.fields.reduce((acc, field) => {
      acc[field.key] = '';
      return acc;
    }, {} as Record<string, any>),
    ...value,
  };
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit((values) => {
    onChange({
      value: values,
    });
    reset({}, { keepValues: true });
  });

  const renderField = (fieldConfig: NestedFiltersFormFieldType) => {
    return (
      <CustomController
        key={fieldConfig.key}
        control={control}
        name={fieldConfig.key}
        label={fieldConfig.type === 'checkbox' ? undefined : fieldConfig.label}
        sx={{
          p: 3,
          ...fieldConfig.sx,
        }}
        componentProps={{
          label: {
            sx: {
              ...typography['headline-xxs'],
              color: theme.colors?.utility[900],
            },
          },
        }}
        rules={fieldConfig.rules}
        render={({ field }) => {
          switch (fieldConfig.type) {
            case 'number':
              return (
                <TextField
                  {...field}
                  type="number"
                  variant="outlined"
                  fullWidth
                  sx={{
                    fontSize: 14,
                  }}
                />
              );
            case 'checkbox':
              return (
                <CheckboxMenuItem
                  {...field}
                  onClick={() => field.onChange(!field.value)}
                  checked={field.value}
                  label={fieldConfig.label}
                  isCheckboxExclusiveClick={false}
                  disableRipple
                  sx={{
                    p: 0,
                    bgcolor: 'transparent !important',
                  }}
                />
              );
            default:
              return <></>;
          }
        }}
      />
    );
  };

  return (
    <Box component="form" onSubmit={onSubmit}>
      {form.label && (
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          {form.label}
        </Typography>
      )}
      {form.fields.map((field, index) => {
        return (
          <React.Fragment key={index}>{renderField(field)}</React.Fragment>
        );
      })}
      {formState.isDirty && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: 3,
            my: 3,
          }}
        >
          <Button
            variant="text"
            onClick={() => reset(defaultValues)}
            size="small"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary-alt"
            size="small"
            sx={{
              borderRadius: 50,
            }}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
};
