import { Box, SxProps } from '@mui/material';
import { useEffect, useState } from 'react';
import { videoFrameCaptureQueue } from 'utils/video';

export type ImageWithVideoFallbackProps = {
  imageUrl: string;
  videoUrl: string;
  sx?: SxProps;
};

export const ImageWithVideoFallback = (props: ImageWithVideoFallbackProps) => {
  const { imageUrl, videoUrl, sx } = props;

  const [videoFrameUrl, setVideoFrameUrl] = useState<string | undefined>();

  const [isImageInvalid, setIsImageInvalid] = useState(false);
  const [isVideoFrameInvalid, setIsVideoFrameInvalid] = useState(false);
  const [isVideoInvalid, setIsVideoInvalid] = useState(false);

  useEffect(() => {
    if (isImageInvalid) {
      videoFrameCaptureQueue.enqueue(videoUrl).then((frameUrl) => {
        setVideoFrameUrl(frameUrl);
      });
    }
  }, [videoUrl, isImageInvalid]);

  // First, render the image
  if (!isImageInvalid) {
    return (
      <Box
        component="img"
        sx={{
          width: '100%',
          height: '100%',
          ...sx,
        }}
        src={imageUrl}
        onError={() => setIsImageInvalid(true)}
      />
    );
  }

  // Second, render the video frame captured from the video
  if (videoFrameUrl && !isVideoFrameInvalid) {
    return (
      <Box
        component="img"
        sx={{
          width: '100%',
          height: '100%',
          ...sx,
        }}
        src={videoFrameUrl}
        onError={() => setIsVideoFrameInvalid(true)}
      />
    );
  }

  // Last resort, render the paused video
  if (!isVideoInvalid) {
    return (
      <Box
        component="video"
        sx={{
          width: '100%',
          height: '100%',
          ...sx,
        }}
        src={videoUrl}
        onError={() => setIsVideoInvalid(true)}
      />
    );
  }

  return null;
};
