import { gql } from '@apollo/client';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { CustomDialog } from 'components/common/CustomDialog';
import { SLARankingProductSocialPosts } from 'features/socialListeningAnalytics';
import { SocialMediaListeningFilterByDateRangeButton } from 'features/socialMediaListening/components';
import {
  BrandInboundFiltersInputForCapturedProduct,
  CapturedProductFragmentForSocialListeningCreatorProfileProductsDialogViewFragment,
  CapturedProductFragmentForSocialListeningCreatorProfileProductsViewFragmentDoc,
  CapturedProductSectionSortField,
  CreatorProfileFragmentAboutTabContentFragmentDoc,
  CreatorProfileFragmentSocialListeningCreatorProfileProductsDialogViewFragment,
  PaginatedBrandInboundFiltersInputForCapturedProductSortField,
  SocialPostFragmentSlaRankingProductSocialPostsFragmentDoc,
  SortOrder,
  useGetCapturedProductsSocialPostsForSocialListeningCreatorProfileProductsDialogViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetCapturedProductsSocialPostsForSocialListeningCreatorProfileProductsDialogView(
    $data: PaginatedBrandInboundFiltersInputForCapturedProductSocialPosts!
  ) {
    capturedProductsSocialPostsPaginatedForInbound(data: $data) {
      data {
        ...SocialPostFragmentSLARankingProductSocialPosts
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${SocialPostFragmentSlaRankingProductSocialPostsFragmentDoc}
`;

// eslint-disable-next-line
gql`
  fragment CreatorProfileFragmentSocialListeningCreatorProfileProductsDialogView on CreatorProfileModel {
    id
    creators {
      id
      handle
      profilePictureUrl
      nickName
    }
  }
`;

// eslint-disable-next-line
gql`
  fragment CapturedProductFragmentForSocialListeningCreatorProfileProductsDialogView on CapturedProductDataForInbound {
    id
    ...CapturedProductFragmentForSocialListeningCreatorProfileProductsView
  }
  ${CapturedProductFragmentForSocialListeningCreatorProfileProductsViewFragmentDoc}
`;

type Props = {
  mentionsCount: number;
  filters: Omit<BrandInboundFiltersInputForCapturedProduct, 'sortBy'>;
  onClose: () => void;
  capturedProduct: CapturedProductFragmentForSocialListeningCreatorProfileProductsDialogViewFragment;
  creatorProfile: CreatorProfileFragmentSocialListeningCreatorProfileProductsDialogViewFragment;
};

export const SocialListeningCreatorProfileProductsDialogView = ({
  mentionsCount,
  filters,
  onClose,
  capturedProduct,
  creatorProfile,
}: Props) => {
  const { brandId } = filters;
  const [socialPostsSortKey, setSocialPostsSortKey] =
    useState<PaginatedBrandInboundFiltersInputForCapturedProductSortField>(
      PaginatedBrandInboundFiltersInputForCapturedProductSortField.TotalEngagement,
    );
  const [topProductsSortKey, setTopProductsSortKey] =
    useState<CapturedProductSectionSortField>(
      CapturedProductSectionSortField.NumberOfPosts,
    );
  const [currentPostId, setCurrentPostId] = useState('');

  const [dateRange, setDateRange] = useState({
    startDate:
      filters.dateRange.startDate || moment().subtract(7, 'days').toDate(),
    endDate: filters.dateRange.endDate || moment().toDate(),
  });

  const socialPostsVariables = useMemo(() => {
    const { viewType, ...rest } = filters;
    return {
      data: {
        ...rest,
        dateRange,
        sortBy: {
          field: socialPostsSortKey,
          order: SortOrder.Desc,
        },
      },
    };
  }, [filters, dateRange, socialPostsSortKey]);

  const { data: socialPostsData, fetchMore: fetchMoreSocialPosts } =
    useGetCapturedProductsSocialPostsForSocialListeningCreatorProfileProductsDialogViewQuery(
      {
        variables: {
          ...socialPostsVariables,
        },
        fetchPolicy: 'cache-and-network',
      },
    );

  const socialPosts =
    socialPostsData?.capturedProductsSocialPostsPaginatedForInbound.data || [];

  const handleBackClick = () => {
    if (currentPostId) {
      setCurrentPostId('');
    } else {
      onClose();
    }
  };

  return (
    <CustomDialog
      open
      onClose={onClose}
      titleBreadcrumbs={[creatorProfile.creators[0].nickName]}
      onBackClick={handleBackClick}
      title={`${creatorProfile.creators[0].nickName}`}
    >
      <Box
        p={3}
        flexGrow={1}
        overflow="auto"
        sx={{ backgroundColor: '#FAF3ECCC', height: '100%' }}
      >
        <InfiniteScroll
          loadMore={() => {
            fetchMoreSocialPosts({
              variables: {
                data: {
                  ...socialPostsVariables.data,
                  after:
                    socialPostsData
                      ?.capturedProductsSocialPostsPaginatedForInbound.pageInfo
                      .endCursor,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  capturedProductsSocialPostsPaginatedForInbound: {
                    ...fetchMoreResult.capturedProductsSocialPostsPaginatedForInbound,
                    data: [
                      ...prev.capturedProductsSocialPostsPaginatedForInbound
                        .data,
                      ...(fetchMoreResult
                        ?.capturedProductsSocialPostsPaginatedForInbound.data ||
                        []),
                    ],
                  },
                };
              },
            });
          }}
          hasMore={
            socialPostsData?.capturedProductsSocialPostsPaginatedForInbound
              .pageInfo.hasNextPage
          }
          threshold={50}
          useWindow={false}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            gap={2}
            mb={3}
            justifyContent="space-between"
            width="100%"
            sx={{
              p: 4,
            }}
          >
            <Box display="flex" alignItems="center" gap={2}>
              <Avatar
                src={creatorProfile.creators[0].profilePictureUrl || ''}
              />
              <Box display="flex" flexDirection="column">
                <Typography variant="headline-md">
                  {creatorProfile.creators[0].nickName}
                </Typography>
                <Typography
                  variant="headline-sm"
                  color={theme.colors?.utility[700]}
                >
                  {mentionsCount} mentions
                </Typography>
              </Box>
            </Box>

            <SocialMediaListeningFilterByDateRangeButton
              onChange={(range) => {
                if (range[0] && range[1]) {
                  setDateRange({
                    startDate: range[0],
                    endDate: range[1],
                  });
                }
              }}
              selectedDateRange={[dateRange.startDate, dateRange.endDate]}
              componentProps={{
                sx: {
                  backgroundColor: theme.colors?.primary.parchment,
                },
              }}
            />
          </Box>

          <Box
            sx={{
              borderBottom: `2px solid rgba(35, 6, 3, 0.05)`,
              my: 6,
              mx: 2,
            }}
          />

          <SLARankingProductSocialPosts
            socialPosts={socialPosts}
            onPostClick={setCurrentPostId}
            setSelectedSortKey={setSocialPostsSortKey}
            selectedSortKey={socialPostsSortKey}
          />
        </InfiniteScroll>
      </Box>
    </CustomDialog>
  );
};
