/**
 * TODO: WE SHOULD BE REMOVING THIS SOON
 */

import { gql } from '@apollo/client';
import { Badge, Box, Button, IconButton } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldFlash } from 'components/icons/components/bold/IconBoldFlash';
import { IconLinearSidebarRight } from 'components/icons/components/linear/IconLinearSidebarRight';
import { IconOutlineMenu1 } from 'components/icons/components/outline/IconOutlineMenu1';
import { MOBILE_WIDTH_SM } from 'constants/mediaSizes';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingPlanName,
  BillingSubscriptionStatus,
  useGetNotificationUnreadCountQuery,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useMemo } from 'react';
import { theme } from 'styles/theme/theme';
import { useLeftDrawerContext } from '../LeftDrawer';
import { DRAWER_WIDTH } from '../LeftDrawer/LeftDrawer';
import { useVariant } from './hooks/useVariant';
import { HeaderWrapper } from './styles';
import { SiteHeaderProps } from './types';
import { getHeaderColors } from './utils';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetNotificationUnreadCount {
    unreadNotificationsV2
  }
`;

export const SiteHeader = ({
  variant: defaultVariant,
  sx = {},
}: SiteHeaderProps) => {
  const isMobileView = useMediaQueryMobile(MOBILE_WIDTH_SM);
  const navigate = useGuardNavigate();

  const {
    isOpenDrawer,
    onOpenDrawer,
    setIsPersistentDrawer,
    isPersistentDrawer,
    onCloseDrawer,
  } = useLeftDrawerContext();

  const { user, isMobileAppWebView } = useUserContext();

  const { data: unreadNotificationDataV2 } =
    useGetNotificationUnreadCountQuery();

  const { variant } = useVariant();
  const { bgColor, iconColor, borderColor } = getHeaderColors(
    defaultVariant || variant,
  );
  const iconSize = isMobileView ? 20 : 24;

  // Show upgrade button if:
  // 1. User is in free plan
  // 2. User is in trial for standard plan
  const shouldShowUpgradeButton = useMemo(() => {
    if (!user) {
      return false;
    }

    // if user is viewing billing page in mobile webview => do not show upgrade button
    if (isMobileAppWebView) {
      return false;
    }

    return (
      user.organization.billing.plan === BillingPlanName.Free ||
      user.organization.billing.subscription?.status ===
        BillingSubscriptionStatus.Trialing
    );
  }, [isMobileAppWebView, user]);

  return (
    <HeaderWrapper
      sx={{
        height: 64,
        color: iconColor,
        backgroundColor: bgColor,
        borderColor,
        ...sx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Tooltip title={isOpenDrawer ? 'Lock sidebar open' : ''}>
            <IconButton
              onClick={() => {
                if (isOpenDrawer) {
                  setIsPersistentDrawer(true);
                }
              }}
              id="site-header-menu"
              sx={{
                height: 40,
                width: 40,
                zIndex: 1000,
                visibility: isPersistentDrawer ? 'hidden' : 'visible',
              }}
              onMouseEnter={() => {
                if (!isPersistentDrawer) {
                  onOpenDrawer();
                }
              }}
              disableRipple
            >
              {isOpenDrawer ? (
                <IconLinearSidebarRight size={iconSize} color={iconColor} />
              ) : (
                <Badge
                  badgeContent={unreadNotificationDataV2?.unreadNotificationsV2}
                  max={99}
                  sx={{
                    '& .MuiBadge-badge': {
                      backgroundColor: theme.colors?.utility['pink-3'],
                      color: theme.colors?.primary.white,
                      p: 1,
                      height: 'auto',
                      fontWeight: 600,
                      top: 2,
                      ...theme.typography['headline-xxs'],
                      fontSize: 11,
                    },
                  }}
                >
                  <IconOutlineMenu1 color={iconColor} size={iconSize} />
                </Badge>
              )}
            </IconButton>
          </Tooltip>
        </Box>

        {shouldShowUpgradeButton && (
          <Button
            variant="contained"
            startIcon={<IconBoldFlash size={20} />}
            sx={{
              bgcolor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
              borderRadius: theme.spacing(2),
              ':hover': {
                bgcolor: theme.colors?.primary.black,
                color: theme.colors?.primary.white,
              },
              display: 'flex',
              mr: 2,
              flexShrink: 0,
              height: 36,
            }}
            onClick={() => navigate(PlotRoutes.billing())}
          >
            Upgrade
          </Button>
        )}
      </Box>
    </HeaderWrapper>
  );
};
