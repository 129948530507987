import { IconBold3dCubeScan } from 'components/icons/components/bold/IconBold3dCubeScan';
import { noop } from 'lodash';
import { NestedFiltersCustom, NestedFiltersCustomType } from '../custom';
import {
  NestedFiltersDateRange,
  NestedFiltersDateRangeOptionType,
  NestedFiltersDateRangeType,
} from '../dateRange';
import { NestedFiltersDivider, NestedFiltersDividerType } from '../divider';
import { NestedFiltersForm, NestedFiltersFormType } from '../form';
import { NestedFiltersGroup, NestedFiltersGroupType } from '../group';
import {
  NestedFiltersMultiSelect,
  NestedFiltersMultiSelectType,
  NestedFiltersOptionType,
} from '../multiSelect';
import {
  NestedFiltersSingleSelect,
  NestedFiltersSingleSelectType,
} from '../singleSelect';
import { NestedFiltersSubMenu, NestedFiltersSubMenuType } from '../subMenu';

export const FILTERABLE_ITEM_TYPES = [
  'single-select',
  'multi-select',
  'form',
  'date-range',
];

export type NestedFiltersMenuItemBaseValueType<T> = {
  value?: T;
};

export type NestedFiltersMenuItemBaseType = {
  type: /**
   * Collection of items
   */
  | 'group'
    | 'submenu'
    /**
     * Filterable items
     */
    | 'single-select'
    | 'multi-select'
    | 'form'
    | 'date-range'
    /**
     * Special items
     */
    | 'divider'
    | 'custom';
  key?: string;
  Icon?: typeof IconBold3dCubeScan;
};

export type NestedFiltersMenuItemType =
  | NestedFiltersGroupType
  | NestedFiltersSubMenuType
  | NestedFiltersSingleSelectType
  | NestedFiltersMultiSelectType
  | NestedFiltersFormType
  | NestedFiltersDividerType
  | NestedFiltersCustomType
  | NestedFiltersDateRangeType;

export type NestedFiltersMenuItemProps = {
  menuItem: NestedFiltersMenuItemType;
  value?:
    | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
    | NestedFiltersMenuItemBaseValueType<unknown>;
  onChange?: (
    value:
      | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
      | NestedFiltersMenuItemBaseValueType<unknown>,
  ) => void;
};

export const NestedFiltersMenuItem = (props: NestedFiltersMenuItemProps) => {
  const { menuItem, value, onChange = noop } = props;

  switch (menuItem.type) {
    /**
     * Collection of items.
     *
     * value: {
     *   [field1]: { value: ... },
     *   [field2]: { value: ... },
     * }
     */
    case 'group': {
      return (
        <NestedFiltersGroup
          group={menuItem}
          values={
            value as Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
          }
          onChange={onChange}
        />
      );
    }
    case 'submenu':
      return (
        <NestedFiltersSubMenu
          subMenu={menuItem}
          values={
            value as Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
          }
          onChange={onChange}
        />
      );
    /**
     * Single item
     *
     * value: { value: ... }
     */
    case 'single-select':
      return (
        <NestedFiltersSingleSelect
          singleSelect={menuItem}
          value={
            value as NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType>
          }
          onChange={onChange}
        />
      );
    case 'multi-select':
      return (
        <NestedFiltersMultiSelect
          multiSelect={menuItem}
          value={
            value as NestedFiltersMenuItemBaseValueType<
              NestedFiltersOptionType[]
            >
          }
          onChange={onChange}
        />
      );
    case 'form':
      return (
        <NestedFiltersForm
          form={menuItem}
          value={
            value as NestedFiltersMenuItemBaseValueType<Record<string, unknown>>
          }
          onChange={onChange}
        />
      );
    case 'date-range':
      return (
        <NestedFiltersDateRange
          dateRange={menuItem}
          value={
            value as NestedFiltersMenuItemBaseValueType<NestedFiltersDateRangeOptionType>
          }
          onChange={onChange}
        />
      );
    /**
     * Special items
     */
    case 'divider':
      return <NestedFiltersDivider divider={menuItem} />;
    case 'custom':
      return <NestedFiltersCustom custom={menuItem} />;
    default:
      return null;
  }
};
