import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldSettings2 } from 'components/icons/components/bold/IconBoldSettings2';
import { IconCustomSort } from 'components/icons/components/custom/IconCustomSort';
import { IconOutlineImage } from 'components/icons/components/outline/IconOutlineImage';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  BrandCompetitorActionContextMenu,
  BrandCompetitorsPlatformSelector,
} from 'features/brandCompetitors/components';
import { CreatorHandleChip } from 'features/creator';
import { SocialMediaListeningFilterByDateRangeButton } from 'features/socialMediaListening';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  BrandFragmentForBrandCompetitorsViewFragment,
  BrandPermission,
  CompetitorFragmentForBrandCompetitorActionContextMenuFragment,
  Platform,
  PlotFeature,
  SortOrder,
  useGetCompetitorFlatCreatorsListForBrandCompetitorsViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { BrandCompetitorsListViewSkeleton } from './BrandCompetitorsListViewSkeleton';
import { TableHeaders } from './consts';

type Props = {
  brand?: BrandFragmentForBrandCompetitorsViewFragment;
  brandId: string;
};

export const BrandCompetitorsView = ({
  brand: currentBrand,
  brandId,
}: Props) => {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    moment().subtract(7, 'days').toDate(),
    moment().toDate(),
  ]);
  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const hasFullAccess = useMemo(() => {
    return (currentBrand?.myPermissions || []).includes(BrandPermission.Write);
  }, [currentBrand]);

  const [selectedCompetitor, setSelectedCompetitor] =
    useState<CompetitorFragmentForBrandCompetitorActionContextMenuFragment>();

  const { isFeatureEnabled } = useFeatureFlagContext();

  const defaultPlatforms = [
    Platform.Tiktok,
    Platform.Instagram,
    ...(isFeatureEnabled(PlotFeature.YoutubeParsing) ? [Platform.Youtube] : []),
  ];

  const [selectedPlatforms, setSelectedPlatforms] =
    useState<Platform[]>(defaultPlatforms);
  const [sortData, setSortData] = useState<{
    key: string;
    order: SortOrder;
  }>();

  const { competitorFlatCreatorPayload, socialPostsDataPayload } =
    useMemo(() => {
      const competitorFlatCreatorPayload = {
        brandId,
        filters: {
          platforms: selectedPlatforms.length
            ? selectedPlatforms
            : defaultPlatforms,
          dateRange: {
            startDate: dateRange[0],
            endDate: dateRange[1],
          },
        },
        ...(sortData
          ? { sortBy: { field: sortData?.key, order: sortData?.order } }
          : {}),
      };

      const socialPostsDataPayload = {
        take: 3,
        dateRange: {
          startDate: dateRange[0],
          endDate: dateRange[1],
        },
      };

      return {
        competitorFlatCreatorPayload,
        socialPostsDataPayload,
      };
    }, [brandId, selectedPlatforms, dateRange, sortData]); // eslint-disable-line

  const {
    data: competitorsData,
    loading: loadingCompetitors,
    fetchMore,
    updateQuery,
  } = useGetCompetitorFlatCreatorsListForBrandCompetitorsViewQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      data: competitorFlatCreatorPayload,
      socialPostsData: socialPostsDataPayload,
    },
  });
  const flatCompetitors = useMemo(() => {
    return competitorsData?.searchCompetitorFlatCreators.data || [];
  }, [competitorsData]);

  const onFetchMore = () => {
    if (!competitorsData?.searchCompetitorFlatCreators.pageInfo.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        data: {
          ...competitorFlatCreatorPayload,
          skip: flatCompetitors.length,
        },
        socialPostsData: socialPostsDataPayload,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...fetchMoreResult,
          searchCompetitorFlatCreators: {
            ...fetchMoreResult.searchCompetitorFlatCreators,
            data: [
              ...prev.searchCompetitorFlatCreators.data,
              ...fetchMoreResult.searchCompetitorFlatCreators.data,
            ],
          },
        };
      },
    });
  };

  return (
    <Box mt={16}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={4}
        px={8}
      >
        <Typography
          variant="headline-xl"
          sx={{
            fontSize: 32,
            letterSpacing: '-1.28px',
          }}
        >
          Competitor Analytics
        </Typography>
        {currentBrand && (
          <Box
            borderRadius={10}
            bgcolor={theme.colors?.utility[275]}
            padding={3}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: 'center',
            }}
            component="button"
            onClick={() => {
              navigate(
                PlotRoutes.socialListeningSettings({
                  brandId: currentBrand.id,
                }),
              );
            }}
          >
            <IconBoldSettings2 size={22} color={theme.colors?.utility[600]} />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          borderBottom: `1px solid ${theme.colors?.utility[300]}`,
          borderTop: `1px solid ${theme.colors?.utility[300]}`,
          position: 'sticky',
          top: 64,
          zIndex: 10,
          backgroundColor: theme.colors?.utility[200],
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '100%',
          p: theme.spacing(4, 8),
          my: 4,
          gap: 4,
        }}
      >
        <BrandCompetitorsPlatformSelector
          selectedPlatforms={selectedPlatforms}
          onPlatformSelect={setSelectedPlatforms}
        />
        <SocialMediaListeningFilterByDateRangeButton
          onChange={(range) => {
            if (range[0] && range[1]) {
              setDateRange(range as [Date, Date]);
            }
          }}
          selectedDateRange={dateRange}
        />
      </Box>
      <Box
        sx={{
          mx: 8,
        }}
      >
        <TableContainer sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          <InfiniteScroll
            loadMore={onFetchMore}
            threshold={510}
            useWindow={false}
            initialLoad={false}
            hasMore={
              competitorsData?.searchCompetitorFlatCreators.pageInfo.hasNextPage
            }
          >
            <Table
              sx={{
                mt: 4,
                overflowX: 'auto',
                '& td': {
                  border: 'none',
                },
              }}
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {TableHeaders.map((header, index) => (
                    <TableCell
                      key={`${header.key}-${index}`}
                      sx={{
                        padding: 2.5,
                        borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                        ...(index === 0 && {
                          width: 8,
                        }),
                        ...(header.canSort ? { cursor: 'pointer' } : {}),
                      }}
                      onClick={() => {
                        if (header.canSort && flatCompetitors.length) {
                          if (
                            sortData?.key === header.key &&
                            sortData?.order === SortOrder.Desc
                          ) {
                            setSortData(undefined);
                          } else {
                            setSortData({
                              key: header.key,
                              order:
                                sortData?.key === header.key
                                  ? sortData?.order === SortOrder.Asc
                                    ? SortOrder.Desc
                                    : SortOrder.Asc
                                  : SortOrder.Asc,
                            });
                          }
                        }
                      }}
                    >
                      <Typography
                        variant="headline-xs"
                        component="span"
                        display="flex"
                        alignItems="center"
                        gap={1}
                        color={theme.colors?.utility[700]}
                        whiteSpace="nowrap"
                      >
                        {header.label}

                        {header.canSort && (
                          <Box display="flex" gap={0} flexDirection="column">
                            <IconCustomSort size={16} />
                          </Box>
                        )}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {loadingCompetitors ? (
                  new Array(3).fill(0).map(() => {
                    return <BrandCompetitorsListViewSkeleton />;
                  })
                ) : flatCompetitors.length ? (
                  flatCompetitors.map((flatCompetitor, index) => {
                    return (
                      <TableRow
                        key={flatCompetitor.competitorId}
                        component={Link}
                        to={PlotRoutes.socialListeningCompetitor(
                          flatCompetitor.competitorId,
                          brandId,
                        )}
                        sx={{
                          cursor: 'pointer',
                          '& td': {
                            px: 2.5,
                            borderBottom: `2px solid ${theme.colors?.utility[275]}`,
                          },
                          ':hover': {
                            backgroundColor: theme.colors?.utility[275],
                            '& td': {
                              ':first-child': {
                                borderRadius: '8px 0 0 8px',
                              },
                              ':last-child': {
                                borderRadius: '0 8px 8px 0',
                              },
                            },
                          },
                        }}
                        onContextMenu={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setContextMenuAnchorPosition({
                            top: e.clientY,
                            left: e.clientX,
                          });
                          setSelectedCompetitor(flatCompetitor.competitor);
                        }}
                      >
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>
                          <Avatar
                            src={
                              flatCompetitor.trackingBrandCreators[0]
                                ?.profilePictureUrl || ''
                            }
                            sx={{
                              width: theme.spacing(12.5),
                              height: theme.spacing(12.5),
                              border: `2px solid ${theme.colors?.utility[300]}`,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            ...typography['headline-sm'],
                          }}
                        >
                          {flatCompetitor.trackingBrand?.name}
                          <CreatorHandleChip
                            creators={flatCompetitor.trackingBrandCreators}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            ...typography['body-lg'],
                            fontWeight: 600,
                          }}
                        >
                          {formatBigNumber(flatCompetitor.followerCount)}{' '}
                          followers
                        </TableCell>

                        <TableCell
                          sx={{
                            ...typography['body-lg'],
                            fontWeight: 600,
                          }}
                        >
                          {Number(
                            flatCompetitor.averageEngagementRate * 100,
                          ).toFixed(1)}
                          %
                        </TableCell>
                        <TableCell
                          sx={{
                            ...typography['body-lg'],
                            fontWeight: 600,
                          }}
                        >
                          {formatBigNumber(flatCompetitor.averageVideoViews)}{' '}
                          average views
                        </TableCell>
                        <TableCell
                          sx={{
                            ...typography['body-lg'],
                            fontWeight: 600,
                          }}
                        >
                          {formatBigNumber(flatCompetitor.averageLikes)} average
                          likes
                        </TableCell>
                        <TableCell>
                          <Box display="flex" gap={2} alignItems="center">
                            {flatCompetitor.socialPosts?.data.length ? (
                              flatCompetitor.socialPosts?.data.map((post) => (
                                <SocialPostThumbnail
                                  socialPost={post}
                                  sx={{
                                    width:
                                      Math.floor(
                                        Math.random() * (75 - 20 + 1),
                                      ) + 40,
                                    height: theme.spacing(18),
                                    borderRadius: theme.spacing(1),
                                    backgroundColor: theme.colors?.utility[300],
                                    p: 0,
                                  }}
                                  componentProps={{
                                    hideCreatedTime: true,
                                    hidePlatform: true,
                                    thumbnailFallback: (
                                      <IconOutlineImage size={24} />
                                    ),
                                  }}
                                />
                              ))
                            ) : (
                              <Typography
                                variant="body-lg"
                                color={theme.colors?.utility[700]}
                              >
                                -
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={TableHeaders.length}
                      sx={{
                        borderBottom: 'none',
                        padding: 0,
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          ...theme.typography['subhead-xl'],
                          color: theme.colors?.utility[700],
                          height: theme.spacing(20),
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        No data found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </InfiniteScroll>
        </TableContainer>
        {contextMenuAnchorPosition && selectedCompetitor && (
          <BrandCompetitorActionContextMenu
            renderButton={false}
            competitor={selectedCompetitor}
            canDelete={hasFullAccess}
            anchorPosition={contextMenuAnchorPosition}
            anchorReference="anchorPosition"
            onClose={() => setContextMenuAnchorPosition(null)}
            open
            onAfterDelete={() => {
              setSelectedCompetitor(undefined);
              setContextMenuAnchorPosition(null);
              updateQuery((prev) => {
                const newData = {
                  ...prev,
                  searchCompetitorFlatCreators: {
                    ...prev.searchCompetitorFlatCreators,
                    data: prev.searchCompetitorFlatCreators.data.filter(
                      (competitor) =>
                        competitor.competitorId !== selectedCompetitor.id,
                    ),
                  },
                };
                return newData;
              });
            }}
          />
        )}
      </Box>
    </Box>
  );
};
