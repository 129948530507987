import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { CommandKey } from 'components/common/CommandKey';
import { ContextMenu } from 'components/common/ContextMenu';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconLinearSidebarLeft } from 'components/icons/components/linear/IconLinearSidebarLeft';
import { IconLogoPlotLogo } from 'components/icons/components/logo/IconLogoPlotLogo';
import { IconOutlineSetting2 } from 'components/icons/components/outline/IconOutlineSetting2';
import { usePendingActions } from 'contexts/users/PendingActions';
import { useUserContext } from 'contexts/users/User.context';
import { BillingLimitProgressView } from 'features/billing';
import { BookmarkSidebarView } from 'features/bookmark';
import { UserProfileModel } from 'graphql/generated';
import { Fragment, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme/theme';
import { FrequentlyVisitedPages } from './components';
import { useLeftDrawerContext } from './contexts';
import { useDrawerTabs } from './hooks/useDrawerTabs';
import { StyledTab } from './styles';
import { DrawerTab, TabName } from './types';

export const DRAWER_WIDTH = 260;

export const LeftDrawer = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { productTabs, settingTabs, topTabs } = useDrawerTabs();
  const {
    isOpenDrawer,
    isPersistentDrawer,
    onCloseDrawer,
    setIsPersistentDrawer,
    drawerOpenedState,
    onAddDrawerOpenedState,
    onRemoveDrawerOpenedState,
  } = useLeftDrawerContext();

  const variant = isPersistentDrawer ? 'persistent' : 'temporary';

  const { areTherePendingUserRequests } = usePendingActions();

  const pendingNotifications = useCallback(
    (tabName: TabName) => {
      if (tabName === TabName.SETTINGS) {
        return areTherePendingUserRequests;
      }
      return false;
    },
    [areTherePendingUserRequests],
  );

  useEffect(() => {
    // Define the event handler function
    const handleMouseMove = (e) => {
      if (
        e.clientX >= DRAWER_WIDTH - 10 &&
        isOpenDrawer &&
        !isPersistentDrawer
      ) {
        onCloseDrawer();
      }
    };

    // Attach the event listener
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup the event listener on component unmount or dependencies change
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isOpenDrawer, onCloseDrawer, isPersistentDrawer]);

  const renderDrawerTab = (tab: DrawerTab) => {
    return tab.type === 'link' ? (
      <Box
        sx={{
          borderTop: tab.paywall
            ? `1px solid ${theme.colors?.utility[300]}`
            : 'none',
          borderBottom: tab.paywall
            ? `1px solid ${theme.colors?.utility[300]}`
            : 'none',
          padding: tab.paywall ? theme.spacing(4, 0) : theme.spacing(0),
        }}
      >
        <Box
          component={Link}
          to={tab.disabled ? '#' : tab.href}
          key={tab.href}
          sx={{
            width: '100%',
            display: 'flex',
            opacity: tab.disabled ? 0.5 : 1,
            pointerEvents: tab.disabled ? 'none' : 'auto',
            p: 2,
            borderRadius: 2,
            ':hover': {
              backgroundColor: 'rgba(35, 6, 3, 0.05)',
            },
          }}
        >
          <Box
            sx={{
              cursor: tab.disabled ? 'none' : 'auto',
              display: 'flex',
              gap: theme.spacing(2),
              flexDirection: 'column',
            }}
          >
            <StyledTab>
              <Badge
                color="primary"
                variant="dot"
                badgeContent={pendingNotifications(tab.name) ? 1 : 0}
              >
                {tab.loading ? (
                  <CircularProgress
                    size={16}
                    sx={{
                      color: tab.color ?? theme.colors?.utility[700],
                    }}
                  />
                ) : (
                  <tab.icon
                    size={16}
                    color={
                      tab.color ??
                      (tab.isActive
                        ? theme.colors?.primary.black
                        : theme.colors?.utility[700])
                    }
                  />
                )}
              </Badge>
              <Box display="flex" alignItems="center" gap={2} flex={1}>
                <Typography
                  variant="subhead-lg"
                  color={
                    tab.isActive
                      ? theme.colors?.primary.black
                      : theme.colors?.utility[800]
                  }
                  width="100%"
                >
                  {tab.name}
                </Typography>
                {tab.beta && (
                  <Chip
                    sx={{
                      ...typography['headline-xxs'],
                      bgcolor: theme.colors?.utility['blue-1'],
                      color: theme.colors?.utility['blue-4'],
                      height: 'unset',
                      px: 2,
                      py: 1,
                      border: 'none',
                      ml: 'auto',
                      span: {
                        padding: 0,
                      },
                    }}
                    label="Beta"
                  />
                )}
                {tab.new && (
                  <Chip
                    sx={{
                      ...typography['headline-xxs'],
                      bgcolor: theme.colors?.utility['purple-1'],
                      color: theme.colors?.utility['purple-4'],
                      height: 'unset',
                      px: 2,
                      py: 1,
                      border: 'none',
                      ml: 'auto',
                      span: {
                        padding: 0,
                      },
                    }}
                    label="New"
                  />
                )}
              </Box>
              {tab.shortCut && (
                <Tooltip title={tab.tooltip || ''} placement="top">
                  <CommandKey
                    sx={{
                      border: 'none',
                      background: theme.colors?.utility[400],
                    }}
                  >
                    {tab.shortCut}
                  </CommandKey>
                </Tooltip>
              )}
            </StyledTab>

            {tab.paywall && <Box>{tab.paywall}</Box>}
          </Box>
        </Box>
      </Box>
    ) : tab.type === 'component' ? (
      <Box
        sx={{
          pointerEvents: tab.disabled ? 'none' : 'auto',
        }}
      >
        {tab.renderComponent()}
      </Box>
    ) : (
      <Fragment key={tab.name} />
    );
  };

  return (
    <Fragment key="left-drawer">
      <Drawer
        variant={variant}
        anchor="left"
        open={isOpenDrawer}
        onClose={onCloseDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        SlideProps={{
          unmountOnExit: false,
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            padding: theme.spacing(4),
            width: DRAWER_WIDTH,
            display: 'flex',
            flexDirection: 'column',
            background: 'rgb(249, 249, 249)',
            border: 'none',
            ...(variant === 'temporary' && {
              height: 'calc(100vh - 82px)',
              marginTop: 72,
              borderRadius: '0px 16px 16px 0px',
              boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.10)',
            }),
          },
        }}
        hideBackdrop
        BackdropProps={{
          sx: {
            background: 'transparent',
          },
        }}
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" flexDirection="column" gap={1} mb={6}>
            <Box
              sx={{
                position: 'sticky',
                top: -16,
                background: 'rgb(249, 249, 249)',
                zIndex: 99,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {variant !== 'temporary' && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  ml={2}
                  mb={3}
                >
                  <IconLogoPlotLogo
                    style={{
                      height: 20,
                      width: 23,
                      color: theme.colors?.primary.maroon,
                    }}
                  />
                  <Tooltip title="Close sidebar">
                    <IconButton
                      onClick={() => {
                        setIsPersistentDrawer(false);
                        setTimeout(() => {
                          onCloseDrawer();
                        }, 5);
                      }}
                    >
                      <IconLinearSidebarLeft
                        color={theme.colors?.utility[800]}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}

              {topTabs.map((tab) => {
                return renderDrawerTab(tab);
              })}
            </Box>
            <CollapsibleSection
              headerTitle="Products"
              headerFullWidth
              headerTitleClass="sidebar-product-title"
              sx={{
                p: 2,
                borderRadius: 2,
                ':hover': {
                  background: 'rgba(35, 6, 3, 0.05)',
                },
                '.sidebar-product-title': {
                  color: theme.colors?.utility[800],
                  ...theme.typography['headline-xs'],
                },
              }}
              divider={false}
              iconColor={theme.colors?.utility[600]}
              defaultIsOpen={drawerOpenedState.includes(TabName.PRODUCTS)}
              onStateUpdated={(isOpen) => {
                if (isOpen) {
                  onAddDrawerOpenedState(TabName.PRODUCTS);
                } else {
                  onRemoveDrawerOpenedState(TabName.PRODUCTS);
                }
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {productTabs.map((tab) => {
                  return renderDrawerTab(tab);
                })}
              </Box>
            </CollapsibleSection>
            <Divider
              sx={{ borderColor: theme.colors?.utility[300], my: 3, mx: 2 }}
            />
            <FrequentlyVisitedPages />
            <BookmarkSidebarView />
          </Box>

          <Box
            sx={{
              position: 'sticky',
              bottom: -16,
              margin: theme.spacing(-4),
              padding: 4,
              pb: 2,
              borderTop: `1px solid ${theme.colors?.utility[300]}`,
              background: 'rgba(249, 249, 249, 0.70)',
              boxShadow: '0px -4px 10px 0px rgba(0, 0, 0, 0.03)',
              backdropFilter: 'blur(18px)',
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {user && (
                <Accordion
                  sx={{
                    p: 0,
                    bgcolor: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    '& .MuiAccordionSummary-content': {
                      m: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      p: 0,
                      ':focus': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    expandIcon={<IconLinearArrowDown size={16} />}
                  >
                    <Link
                      to={PlotRoutes.account()}
                      style={{ display: 'block' }}
                    >
                      <Box display="flex" gap={3} alignItems="flex-start">
                        {/* Converting MeModel to UserProfileModel */}
                        <Avatar
                          user={user as unknown as UserProfileModel}
                          size={16}
                        />
                        <Box display="flex" flexDirection="column" gap={1}>
                          <Typography
                            variant="body-lg"
                            color={theme.colors?.utility[900]}
                            fontWeight={500}
                          >
                            {user.firstName} {user.lastName}
                          </Typography>
                          <Typography
                            variant="body-md"
                            color={theme.colors?.utility[700]}
                          >
                            {user.email}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: theme.spacing(2),
                              mt: 3,
                            }}
                            id="sidebar-plan-info"
                          />
                        </Box>
                      </Box>
                    </Link>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, pt: 4 }}>
                    <BillingLimitProgressView
                      organizationBilling={user.organization.billing}
                    />
                  </AccordionDetails>
                </Accordion>
              )}

              <Divider
                sx={{ borderColor: theme.colors?.utility[300], mt: 2 }}
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  p: 2,
                  borderRadius: 2,
                  ':hover': {
                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                  },
                }}
              >
                <Link to={PlotRoutes.settings()}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <IconOutlineSetting2
                      size={16}
                      color={
                        location.pathname.includes(PlotRoutes.settings())
                          ? theme.colors?.primary.black
                          : theme.colors?.utility[700]
                      }
                    />
                    <Typography
                      variant="subhead-lg"
                      color={
                        location.pathname.includes(PlotRoutes.settings())
                          ? theme.colors?.primary.black
                          : theme.colors?.utility[800]
                      }
                    >
                      Settings
                    </Typography>
                  </Box>
                </Link>

                <ContextMenu
                  renderButton={() => {
                    return (
                      <IconButton sx={{ p: 1 }}>
                        <IconLinearMore
                          size={16}
                          color={theme.colors?.utility[700]}
                        />
                      </IconButton>
                    );
                  }}
                  options={settingTabs.map((tab, idx) => {
                    return {
                      closeOnClick: tab.type === 'link',
                      renderCustomComponent: () => {
                        return tab.type === 'link' ? (
                          <Link
                            to={tab.disabled ? '#' : tab.href}
                            key={idx}
                            state={tab.state}
                            style={{
                              opacity: tab.disabled ? 0.5 : 1,
                              cursor: tab.disabled ? 'not-allowed' : 'auto',
                              pointerEvents: tab.disabled ? 'none' : 'auto',
                            }}
                          >
                            <StyledTab onClick={tab.onClick}>
                              <Badge
                                color="primary"
                                variant="dot"
                                badgeContent={
                                  pendingNotifications(tab.name) ? 1 : 0
                                }
                              >
                                <Box
                                  sx={{
                                    height: 24,
                                    width: 24,
                                    borderRadius: 1,
                                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {tab.loading ? (
                                    <CircularProgress
                                      size={16}
                                      sx={{
                                        color:
                                          tab.color ??
                                          theme.colors?.primary.black,
                                      }}
                                    />
                                  ) : (
                                    <tab.icon
                                      size={16}
                                      color={
                                        tab.color ?? theme.colors?.primary.black
                                      }
                                    />
                                  )}
                                </Box>
                              </Badge>

                              <Typography
                                variant="subhead-lg"
                                color={theme.colors?.primary.black}
                              >
                                {tab.name}
                              </Typography>

                              {tab.name === TabName.SETTINGS &&
                                areTherePendingUserRequests && (
                                  <Chip
                                    sx={{
                                      ...typography['headline-xxs'],
                                      bgcolor:
                                        theme.colors?.utility['yellow-1'],
                                      color: theme.colors?.utility['yellow-4'],
                                      height: 'unset',
                                      px: 2,
                                      py: 1,
                                      border: 'none',
                                      ml: 'auto',
                                      span: {
                                        padding: 0,
                                      },
                                    }}
                                    label="Approval Pending"
                                  />
                                )}
                            </StyledTab>
                          </Link>
                        ) : tab.type === 'component' ? (
                          <Box
                            key={idx}
                            sx={{
                              pointerEvents: tab.disabled ? 'none' : 'auto',
                              cursor: tab.disabled ? 'not-allowed' : 'auto',
                              opacity: tab.disabled ? 0.5 : 1,
                            }}
                          >
                            {tab.renderComponent()}
                          </Box>
                        ) : tab.type === 'button' ? (
                          <StyledTab
                            key={idx}
                            onClick={tab.disabled ? () => {} : tab.onClick}
                            sx={{
                              opacity: tab.disabled ? 0.5 : 1,
                              pointerEvents: tab.disabled ? 'none' : 'auto',
                            }}
                          >
                            <Box
                              sx={{
                                height: 24,
                                width: 24,
                                borderRadius: 1,
                                backgroundColor: 'rgba(35, 6, 3, 0.05)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              {tab.loading ? (
                                <CircularProgress
                                  size={16}
                                  sx={{
                                    color:
                                      tab.color ?? theme.colors?.primary.black,
                                  }}
                                />
                              ) : (
                                <tab.icon
                                  size={16}
                                  color={
                                    tab.color ?? theme.colors?.primary.black
                                  }
                                />
                              )}
                            </Box>
                            <Typography
                              variant="subhead-lg"
                              color={theme.colors?.primary.black}
                            >
                              {tab.name}
                            </Typography>
                          </StyledTab>
                        ) : (
                          <Fragment key={idx} />
                        );
                      },
                    };
                  })}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  anchorOrigin={{
                    vertical: -8,
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    sx: {
                      background: `${theme.colors?.primary.white} !important`,
                      border: 'none',
                      padding: theme.spacing(4),
                      minWidth: `266px !important`,
                      ul: {
                        gap: `${theme.spacing(6)} !important`,
                      },
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </Fragment>
  );
};
