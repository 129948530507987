import { Avatar, Box, Button, Chip, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { IconCustomSLHorn } from 'components/icons/components/custom/IconCustomSLHorn';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { PlotFeature } from 'graphql/generated';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { useLeftDrawerContext } from '../../contexts';
import { TabName } from '../../types';

export const SocialListeningSidebarLink = () => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const navigate = useNavigate();
  const competitorAnalysisEnabled = isFeatureEnabled(
    PlotFeature.CompetitorAnalysis,
  );
  const isSocialListeningCRMEnabled = isFeatureEnabled(
    PlotFeature.SocialListeningCrm,
  );
  const { user } = useUserContext();
  const hasSocialListeningData = !!user?.socialListeningBrands.length;
  const slBrands = user?.socialListeningBrands || [];

  const {
    onAddDrawerOpenedState,
    onRemoveDrawerOpenedState,
    drawerOpenedState,
  } = useLeftDrawerContext();

  const isActivePage = location.pathname.includes(PlotRoutes.socialListening());

  const hasBrands = slBrands.length > 0;

  const renderScheduleDemoButton = () => {
    return (
      <Button
        variant="text"
        sx={{
          textDecoration: 'underline',
          ...theme.typography['headline-xxs'],
          px: 0,
          py: 2,
          justifyContent: 'flex-start',
          pl: 3,
          ml: 7,
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
        onClick={() => {
          window.open(
            'https://plotworkspace.typeform.com/plot-demo?typeform-source=www.plot.so',
            '_blank',
          );
        }}
      >
        Schedule a demo
      </Button>
    );
  };

  return (
    <CollapsibleSection
      renderHeaderTitle={() => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}
          onClick={() => {
            if (!hasBrands) {
              navigate(PlotRoutes.socialListeningIntro());
            }
          }}
        >
          <IconCustomSLHorn
            size={18}
            color={
              isActivePage
                ? theme.colors?.primary.black
                : theme.colors?.utility[700]
            }
          />
          <Typography
            variant="subhead-lg"
            color={
              isActivePage
                ? theme.colors?.primary.black
                : theme.colors?.utility[800]
            }
            textAlign="left"
          >
            Social Listening
          </Typography>
        </Box>
      )}
      headerFullWidth
      sx={{
        p: 2,
        borderRadius: 2,
        ':hover': {
          background: 'rgba(35, 6, 3, 0.05)',
        },
      }}
      divider={false}
      iconColor={theme.colors?.utility[600]}
      defaultIsOpen={
        drawerOpenedState.includes(TabName.SOCIAL_LISTENING) ||
        slBrands.some((slBrand) => drawerOpenedState.includes(slBrand.id))
      }
      onStateUpdated={(isOpen) => {
        if (isOpen) {
          onAddDrawerOpenedState(TabName.SOCIAL_LISTENING);
        } else {
          onRemoveDrawerOpenedState(TabName.SOCIAL_LISTENING);
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 2,
        }}
      >
        {hasBrands ? (
          slBrands.map((slBrand) => {
            const isActiveBrand = location.pathname.includes(
              PlotRoutes.socialListeningForBrand(slBrand.id),
            );
            return (
              <CollapsibleSection
                renderHeaderTitle={() => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Avatar
                      src={
                        slBrand?.creators?.[0]?.profilePictureUrl ||
                        slBrand?.creators?.[1]?.profilePictureUrl ||
                        ''
                      }
                      sx={{
                        width: theme.spacing(3.5),
                        height: theme.spacing(3.5),
                      }}
                    />
                    <Typography
                      component={Link}
                      onClick={(e) => e.stopPropagation()}
                      to={PlotRoutes.socialListeningForBrand(slBrand.id)}
                      variant="subhead-lg"
                      sx={{
                        '-webkit-line-clamp': '1',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        '-webkit-box-orient': 'vertical',
                        textAlign: 'left',
                      }}
                      style={{
                        color: isActiveBrand
                          ? theme.colors?.primary.black
                          : theme.colors?.utility[800],
                      }}
                    >
                      {slBrand.name}
                    </Typography>
                  </Box>
                )}
                headerFullWidth
                sx={{
                  p: 2,
                  borderRadius: 2,
                  ml: 7,
                  ':hover': {
                    background: 'rgba(35, 6, 3, 0.05)',
                  },
                }}
                divider={false}
                iconColor={theme.colors?.utility[600]}
                defaultIsOpen={drawerOpenedState.includes(slBrand.id)}
                onStateUpdated={(isOpen) => {
                  if (isOpen) {
                    onAddDrawerOpenedState(slBrand.id);
                  } else {
                    onRemoveDrawerOpenedState(slBrand.id);
                  }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    ml: 11.5,
                  }}
                >
                  {hasSocialListeningData ? (
                    <>
                      <Box
                        component={Link}
                        to={PlotRoutes.socialListeningForBrand(slBrand.id)}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          px: 2,
                          py: 1,
                          borderRadius: 2,
                          ':hover': {
                            backgroundColor: 'rgba(35, 6, 3, 0.05)',
                          },
                        }}
                      >
                        <Typography
                          ml={1}
                          variant="subhead-lg"
                          width="100%"
                          sx={{
                            color: location.pathname.endsWith(
                              PlotRoutes.socialListeningForBrand(slBrand.id),
                            )
                              ? theme.colors?.primary.black
                              : theme.colors?.utility[700],
                          }}
                        >
                          Engagement
                        </Typography>
                      </Box>
                      <Box
                        component={Link}
                        to={PlotRoutes.socialListeningAnalyticsInbound(
                          slBrand.id,
                        )}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          px: 2,
                          py: 1,
                          borderRadius: 2,
                          ':hover': {
                            backgroundColor: 'rgba(35, 6, 3, 0.05)',
                          },
                        }}
                      >
                        <Typography
                          ml={1}
                          variant="subhead-lg"
                          width="100%"
                          sx={{
                            color:
                              location.pathname.endsWith(
                                PlotRoutes.socialListeningAnalyticsInbound(
                                  slBrand.id,
                                ),
                              ) ||
                              location.pathname.endsWith(
                                PlotRoutes.socialListeningAnalyticsTopics(
                                  slBrand.id,
                                ),
                              ) ||
                              location.pathname.endsWith(
                                PlotRoutes.socialListeningAnalyticsCompetitors(
                                  slBrand.id,
                                ),
                              )
                                ? theme.colors?.primary.black
                                : theme.colors?.utility[700],
                          }}
                        >
                          Brand Analytics
                        </Typography>
                      </Box>
                      {(competitorAnalysisEnabled ||
                        (user.organization.id !== slBrand.organizationId &&
                          slBrand.competitorAnalysisEnabled)) && (
                        <Box
                          component={Link}
                          to={PlotRoutes.socialListeningCompetitors(slBrand.id)}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            px: 2,
                            py: 1,
                            borderRadius: 2,
                            ':hover': {
                              backgroundColor: 'rgba(35, 6, 3, 0.05)',
                            },
                          }}
                        >
                          <Typography
                            ml={1}
                            variant="subhead-lg"
                            width="100%"
                            sx={{
                              color: location.pathname.endsWith(
                                PlotRoutes.socialListeningCompetitors(
                                  slBrand.id,
                                ),
                              )
                                ? theme.colors?.primary.black
                                : theme.colors?.utility[700],
                            }}
                          >
                            Competitor Analytics
                          </Typography>
                        </Box>
                      )}
                      <Box
                        component={Link}
                        to={PlotRoutes.socialListeningAnalyticsOutbound(
                          slBrand.id,
                        )}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          px: 2,
                          py: 1,
                          borderRadius: 2,
                          ':hover': {
                            backgroundColor: 'rgba(35, 6, 3, 0.05)',
                          },
                        }}
                      >
                        <Typography
                          ml={1}
                          variant="subhead-lg"
                          width="100%"
                          sx={{
                            color:
                              location.pathname.includes(
                                PlotRoutes.socialListeningAnalyticsOutbound(
                                  slBrand.id,
                                ),
                              ) ||
                              location.pathname.includes(
                                PlotRoutes.socialListeningAnalyticsOutboundInteractions(
                                  slBrand.id,
                                ),
                              )
                                ? theme.colors?.primary.black
                                : theme.colors?.utility[700],
                          }}
                        >
                          Outbound
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <Box
                      component={Link}
                      to={PlotRoutes.socialListeningIntro()}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 2,
                        py: 1,
                        borderRadius: 2,
                        ':hover': {
                          backgroundColor: 'rgba(35, 6, 3, 0.05)',
                        },
                      }}
                    >
                      <Typography
                        ml={1}
                        variant="subhead-md"
                        width="100%"
                        sx={{
                          color: location.pathname.endsWith(
                            PlotRoutes.socialListeningIntro(),
                          )
                            ? theme.colors?.primary.black
                            : theme.colors?.utility[700],
                        }}
                      >
                        Intro
                      </Typography>
                    </Box>
                  )}
                  {isSocialListeningCRMEnabled && (
                    <Box
                      component={Link}
                      to={PlotRoutes.socialListeningCommunity(slBrand.id)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: 2,
                        py: 1,
                        borderRadius: 2,
                        ':hover': {
                          backgroundColor: 'rgba(35, 6, 3, 0.05)',
                        },
                      }}
                    >
                      <Typography
                        ml={1}
                        variant="subhead-lg"
                        width="100%"
                        sx={{
                          color:
                            location.pathname.endsWith(
                              PlotRoutes.socialListeningCommunity(slBrand.id),
                            ) ||
                            location.pathname.includes(
                              PlotRoutes.socialListeningCreatorProfile(
                                slBrand.id,
                                '',
                              ),
                            ) ||
                            location.pathname.includes(
                              PlotRoutes.socialListeningRadar(slBrand.id),
                            )
                              ? theme.colors?.primary.black
                              : theme.colors?.utility[700],
                        }}
                      >
                        Community Hub
                      </Typography>
                    </Box>
                  )}
                </Box>
              </CollapsibleSection>
            );
          })
        ) : (
          <Box
            component={Link}
            to={PlotRoutes.socialListeningCommunityIntro()}
            display="flex"
            alignItems="center"
            gap={2}
            flex={1}
            ml={9}
          >
            <Typography
              variant="subhead-md"
              color={
                location.pathname.endsWith(
                  PlotRoutes.socialListeningCommunityIntro(),
                )
                  ? theme.colors?.primary.black
                  : theme.colors?.utility[700]
              }
              width="100%"
            >
              Community Hub
            </Typography>
            <Chip
              sx={{
                ...theme.typography['headline-xxs'],
                bgcolor: theme.colors?.utility['blue-1'],
                color: theme.colors?.utility['blue-4'],
                height: 'unset',
                px: 2,
                py: 1,
                border: 'none',
                ml: 'auto',
                span: {
                  padding: 0,
                },
              }}
              label="Beta"
            />
          </Box>
        )}
        {!hasBrands && renderScheduleDemoButton()}
      </Box>
    </CollapsibleSection>
  );
};
