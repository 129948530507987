import { Skeleton, Stack, TableCell, TableRow } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialListeningCommunityTableRowSkeleton = () => {
  return (
    <TableRow
      sx={{
        td: {
          borderBottom: 'none',
        },
      }}
    >
      <TableCell>
        <Stack direction="row" spacing={1.5} alignItems="center">
          <Skeleton variant="circular" width={32} height={32} />
          <Stack>
            <Skeleton width={120} height={20} />
            <Skeleton width={100} height={16} sx={{ opacity: 0.7 }} />
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Skeleton
          variant="rounded"
          width={120}
          height={30}
          sx={{ borderRadius: theme.spacing(2) }}
        />
      </TableCell>

      <TableCell align="center">
        <Skeleton width={50} height={24} />
      </TableCell>

      <TableCell align="center">
        <Skeleton width={40} height={24} />
      </TableCell>

      <TableCell align="center">
        <Skeleton width={40} height={24} />
      </TableCell>

      <TableCell align="center">
        <Skeleton width={40} height={24} />
      </TableCell>

      <TableCell>
        <Stack spacing={0.5}>
          <Skeleton width={150} height={16} />
          <Skeleton width={150} height={16} />
          <Skeleton width={100} height={16} />
        </Stack>
      </TableCell>

      <TableCell>
        <Skeleton
          variant="rounded"
          width={100}
          height={30}
          sx={{ borderRadius: theme.spacing(2) }}
        />
      </TableCell>

      <TableCell align="center">
        <Skeleton width={30} height={20} />
      </TableCell>

      <TableCell align="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Skeleton
            variant="rounded"
            width={90}
            height={24}
            sx={{ borderRadius: theme.spacing(2) }}
          />
          <Skeleton variant="circular" width={20} height={20} />
        </Stack>
      </TableCell>

      <TableCell align="center">
        <Stack spacing={1}>
          <Skeleton
            variant="rounded"
            height={26}
            sx={{
              borderRadius: 6,
            }}
          />

          <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton width={100} height={16} />
            <Skeleton variant="circular" width={16} height={16} />
            <Skeleton width={60} height={16} />
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
