import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileProductsViewSkeleton = () => {
  return (
    <Box
      sx={{
        px: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        maxHeight: 450,
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'sticky',
          top: 0,
          backgroundColor: theme.colors?.primary.white,
          py: 6,
          zIndex: 1,
        }}
      >
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Products
        </Typography>
        {/* Skeleton for PeekingPill */}
        <Box
          sx={{
            width: 100,
            height: 32,
            borderRadius: 16,
            bgcolor: theme.colors?.utility[200],
          }}
        />
      </Box>

      {/* Product items */}
      {[1, 2, 3].map((index) => (
        <Box
          key={index}
          sx={{
            p: 4,
            borderRadius: 3,
            gap: 3,
            background: theme.colors?.utility[250],
            display: 'flex',
            justifyContent: 'space-between',
            mt: index === 0 ? -4 : 0,
            mb: index === 2 ? 6 : 0,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Product title */}
            <Box
              sx={{
                width: 200,
                height: 24,
                borderRadius: 1,
                bgcolor: theme.colors?.utility[300],
              }}
            />

            {/* Sentiment reasons */}
            <Box component="ul" sx={{ pl: 7, m: 0 }}>
              {[1, 2, 3].map((item) => (
                <Box
                  key={item}
                  component="li"
                  sx={{
                    listStyleType: 'none',
                    mb: 1,
                    width: 150,
                    height: 16,
                    borderRadius: 1,
                    bgcolor: theme.colors?.utility[300],
                  }}
                />
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 4,
              alignItems: 'flex-end',
            }}
          >
            {/* Mentions count */}
            <Box
              sx={{
                width: 80,
                height: 24,
                borderRadius: 1,
                bgcolor: theme.colors?.utility[300],
              }}
            />

            {/* Preview posts */}
            <Box sx={{ display: 'flex', gap: 3 }}>
              {[1, 2, 3].map((item) => (
                <Box
                  key={item}
                  sx={{
                    width: 48,
                    height: 68,
                    borderRadius: 2,
                    bgcolor: theme.colors?.utility[300],
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
