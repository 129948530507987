import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { AutocompletePopup } from 'components/common/form/Autocomplete';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import {
  CreatorProfileFragmentSlRadarCreatorAutocompleteFragment,
  CreatorProfileFragmentSlRadarCreatorAutocompleteFragmentDoc,
  useGetCreatorProfileByIdsForSlRadarCreatorAutocompleteLazyQuery,
  useGetCreatorProfilesForSlRadarCreatorAutocompleteQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CreatorProfileFragmentSLRadarCreatorAutocomplete on CreatorProfileModel {
    id
    fullName
    emails
    creators {
      id
      profilePictureUrl
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetCreatorProfilesForSLRadarCreatorAutocomplete(
    $input: PaginatedCreatorProfileInput!
  ) {
    paginatedCreatorProfiles(input: $input) {
      data {
        ...CreatorProfileFragmentSLRadarCreatorAutocomplete
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${CreatorProfileFragmentSlRadarCreatorAutocompleteFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCreatorProfileByIdsForSLRadarCreatorAutocomplete(
    $input: CreatorProfilesByIdsInput!
  ) {
    creatorProfileByIds(input: $input) {
      ...CreatorProfileFragmentSLRadarCreatorAutocomplete
    }
  }
  ${CreatorProfileFragmentSlRadarCreatorAutocompleteFragmentDoc}
`;

type SocialListeningRadarCreatorAutocompleteProps = {
  selectedCreatorIds: string[];
  onToggleCreator: (creatorId: string) => void;
  brandId: string;
};

export const SocialListeningRadarCreatorAutocomplete = ({
  selectedCreatorIds,
  onToggleCreator,
  brandId,
}: SocialListeningRadarCreatorAutocompleteProps) => {
  const [inputValue, setInputValue] = useState('');
  const [searchCreator, setSearchCreator] = useState('');
  const creatorPopover = useDisclosure();

  const [selectedCreatorOptions, setSelectedCreatorOptions] = useState<
    CreatorProfileFragmentSlRadarCreatorAutocompleteFragment[]
  >([]);

  const debouncedSetChannelSearch = debounce((newQuery) => {
    setSearchCreator(newQuery);
  }, 250);

  const defaultDateRange = useMemo(() => {
    return {
      startDate: moment().subtract(1, 'month').toISOString(),
      endDate: moment().toISOString(),
    };
  }, []);

  const creatorVariables = useMemo(() => {
    return {
      brandId,
      dateRange: defaultDateRange,
      take: 10,
      query: searchCreator,
    };
  }, [brandId, defaultDateRange, searchCreator]);

  const [getCreatorProfileByIds] =
    useGetCreatorProfileByIdsForSlRadarCreatorAutocompleteLazyQuery();

  const { data, loading, fetchMore } =
    useGetCreatorProfilesForSlRadarCreatorAutocompleteQuery({
      variables: {
        input: creatorVariables,
      },
    });

  const creators = useMemo(
    () => data?.paginatedCreatorProfiles.data ?? [],
    [data],
  );

  useEffect(() => {
    const uniqueIds = selectedCreatorIds.filter(
      (id) => !selectedCreatorOptions.some((c) => c.id === id),
    );
    if (uniqueIds.length > 0) {
      getCreatorProfileByIds({
        variables: {
          input: { creatorProfileIds: uniqueIds },
        },
        onCompleted: (data) => {
          setSelectedCreatorOptions((prev) => [
            ...prev,
            ...data.creatorProfileByIds,
          ]);
        },
      });
    }
  }, [selectedCreatorIds, selectedCreatorOptions, getCreatorProfileByIds]);

  const creatorsOptions = useMemo(() => {
    return creators.map((creator) => ({
      value: creator.id,
      label: creator.fullName,
    }));
  }, [creators]);
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        flexWrap: 'wrap',
      }}
    >
      {selectedCreatorOptions.map((creator) => {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1,
              pr: 2,
              borderRadius: 6,
              background: 'rgba(35, 6, 3, 0.07)',
            }}
          >
            <Avatar
              variant="circular"
              src={creator.creators[0].profilePictureUrl ?? ''}
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="subhead-lg" whiteSpace="nowrap">
              {creator.fullName}
            </Typography>
            <IconBoldCloseCircle
              size={16}
              color={theme.colors?.utility[600]}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                onToggleCreator(creator.id);
                setSelectedCreatorOptions((prev) =>
                  prev.filter((c) => c.id !== creator.id),
                );
              }}
            />
          </Box>
        );
      })}

      <AutocompletePopup
        loading={loading}
        options={creatorsOptions}
        renderTrigger={() => (
          <IconButton
            sx={{
              borderRadius: '100%',
              background: theme.colors?.utility[400],
              width: 20,
              height: 20,
              ':hover': {
                background: theme.colors?.utility[400],
              },
            }}
            onClick={creatorPopover.onOpen}
          >
            <IconLinearAdd
              size={16}
              color={theme.colors?.utility[800]}
              style={{
                flexShrink: 0,
              }}
            />
          </IconButton>
        )}
        inputValue={inputValue}
        onInputChange={(_, value, reason) => {
          if (reason === 'input') {
            setInputValue(value);
            debouncedSetChannelSearch(value);
          }
        }}
        noOptionsText="No creators found"
        // integrate fetch more
        loadMoreItems={() => {
          if (!data?.paginatedCreatorProfiles.pageInfo.hasNextPage) return;
          fetchMore({
            variables: {
              input: {
                ...creatorVariables,
                after: data?.paginatedCreatorProfiles.pageInfo.endCursor,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                paginatedCreatorProfiles: {
                  ...prev.paginatedCreatorProfiles,
                  data: [
                    ...prev.paginatedCreatorProfiles.data,
                    ...fetchMoreResult.paginatedCreatorProfiles.data,
                  ],
                  pageInfo: {
                    ...prev.paginatedCreatorProfiles.pageInfo,
                    endCursor:
                      fetchMoreResult.paginatedCreatorProfiles.pageInfo
                        .endCursor,
                  },
                },
              };
            },
          });
        }}
        multiple
        disableDefaultSearchFilter
        renderOption={(props, option) => {
          const creator = creators.find(
            (creator) => creator.id === option.value,
          );
          return (
            <Box
              component="li"
              {...props}
              sx={{
                borderRadius: 2,
                p: `0 !important`,
              }}
              onClick={() => {
                onToggleCreator(option.value);
                if (!selectedCreatorIds.includes(option.value) && creator) {
                  setSelectedCreatorOptions((prev) => {
                    return [...prev, creator];
                  });
                } else {
                  setSelectedCreatorOptions((prev) =>
                    prev.filter((c) => c.id !== option.value),
                  );
                }
              }}
            >
              <CheckboxMenuItem
                checked={selectedCreatorIds.includes(option.value)}
                value={option.value}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Avatar
                      variant="circular"
                      src={creator?.creators[0].profilePictureUrl ?? ''}
                      sx={{ width: 20, height: 20 }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        variant="headline-xs"
                        fontSize={13}
                        color={theme.colors?.utility[900]}
                      >
                        {creator?.fullName}
                      </Typography>
                      {creator?.emails && (
                        <Typography
                          variant="subhead-sm"
                          color={theme.colors?.utility[700]}
                        >
                          {creator?.emails[0]}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                }
              />
            </Box>
          );
        }}
        placeholder="Search"
        popoverProps={{
          open: creatorPopover.isOpen,
          onClose: creatorPopover.onClose,
          sx: {
            '& .MuiPaper-root': {
              boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
            },
          },
          PaperProps: {
            sx: {
              minWidth: 270,
              border: 'none',
              background: `rgba(255, 255, 255, 0.80)`,
              backdropFilter: 'blur(20px)',
              '& ul': {
                '::-webkit-scrollbar': {
                  display: 'none',
                },
                scrollbarWidth: 0,
                msOverflowStyle: 'none',
              },
              '.MuiAutocomplete-listbox': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
            },
          },
        }}
        inputSx={{
          border: 'none',
          '& .MuiInputBase-root': {
            padding: `${theme.spacing(2, 3)} !important`,
            ...theme.typography['subhead-lg'],
            color: theme.colors?.utility[1100],
          },
        }}
      />
    </Box>
  );
};
