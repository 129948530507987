import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Collapse, IconButton } from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { theme } from 'styles/theme';
import { NestedFiltersMenuItemBaseValueType } from '../menuItem';
import { NestedFiltersOptionType } from './NestedFiltersMultiSelect';
import { NestedFiltersMultiSelectOptionGroup } from './NestedFiltersMultiSelectOptionGroup';

export type NestedFiltersMultiSelectOptionProps = {
  option: NestedFiltersOptionType;
  value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
  onChange: (
    value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>,
  ) => void;
};

export const NestedFiltersMultiSelectOption = (
  props: NestedFiltersMultiSelectOptionProps,
) => {
  const { option, value: _value, onChange } = props;

  const value = _value.value || [];

  const { isOpen, onToggle } = useDisclosure();

  const isSelected = value.some((v) => v.value === option.value);

  return (
    <Box>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <CheckboxMenuItem
          label={option.renderLabel ? option.renderLabel() : option.label}
          description={option.description}
          value={option.value}
          checked={isSelected}
          isCheckboxExclusiveClick={false}
          onClick={() => {
            if (isSelected) {
              onChange({
                ..._value,
                value: value.filter((v) => v.value !== option.value),
              });
            } else {
              onChange({
                ..._value,
                value: [...value, option],
              });
            }
          }}
        />
        {option.optionGroups && option.optionGroups.length > 0 && (
          <IconButton
            size="small"
            sx={{
              width: 24,
              height: 24,
              p: 0,
              position: 'absolute',
              right: theme.spacing(2),
              top: 'calc(50% - 12px)',
              color: theme.colors?.primary.black,
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onToggle();
            }}
          >
            <IconLinearArrowRight
              size={16}
              style={{
                transform: `rotate(${isOpen ? 90 : 0}deg)`,
                transition: 'transform 0.2s ease',
              }}
            />
          </IconButton>
        )}
      </Box>
      {option.optionGroups && option.optionGroups.length > 0 && (
        <Collapse
          in={isOpen}
          sx={{
            pl: 7,
          }}
        >
          {option.optionGroups.map((group, index) => {
            const groupValue = _value[group.key] || { value: [] };

            return (
              <NestedFiltersMultiSelectOptionGroup
                key={index}
                optionGroup={group}
                value={groupValue}
                onChange={(value) => {
                  onChange({
                    ..._value,
                    [group.key]: value,
                  });
                }}
              />
            );
          })}
        </Collapse>
      )}
    </Box>
  );
};
