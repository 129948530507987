import { gql } from '@apollo/client';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { bookmarkOptions } from 'features/bookmark';
import { useSocialListeningAnalyticsBookmarkHandlers } from 'features/socialListeningAnalytics/hooks';
import {
  BookmarkType,
  BrandContentType,
  BrandFragmentSlaBrandContentTypeSelectorFragment,
  CreatorFragmentCreatorAvatarFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment BrandFragmentSLABrandContentTypeSelector on BrandModel {
    id
    name
    bookmarkState {
      ownedBrandAnalytics
      ugcAnalytics
    }
    creators {
      id
      ...CreatorFragmentCreatorAvatar
    }
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
`;

const contentTypeToColor = {
  [BrandContentType.Owned]: {
    color: theme.colors?.utility['teal-3'],
    backgroundColor: theme.colors?.utility['teal-1'],
  },
  [BrandContentType.Ugc]: {
    color: theme.colors?.utility['blue-3'],
    backgroundColor: theme.colors?.utility['blue-1'],
  },
};

type Props = {
  brand: BrandFragmentSlaBrandContentTypeSelectorFragment;
  contentType: BrandContentType;
  onChange: (contentType: BrandContentType) => void;
};

export const SLABrandContentTypeSelector = ({
  brand,
  contentType,
  onChange,
}: Props) => {
  const creatorProfilePicture =
    brand.creators.find((c) => c.profilePictureUrl)?.profilePictureUrl || '';

  const { onBookmarkBrandAnalytics, onRemoveBrandAnalyticsBookmark } =
    useSocialListeningAnalyticsBookmarkHandlers();

  const renderMoreButton = ({
    isBookmarked,
    type,
  }: {
    isBookmarked: boolean;
    type: BookmarkType;
  }) => {
    return (
      <Box
        className="SL-analytics-more-button"
        sx={{
          opacity: 0,
        }}
      >
        <ContextMenu
          renderButton={() => (
            <IconButton sx={{ p: 0 }} disableRipple>
              <IconLinearMore color={theme.colors?.primary.black} size={17} />
            </IconButton>
          )}
          disablePortal
          options={bookmarkOptions({
            isBookmarked,
            onToggleBookmark: () => {
              if (isBookmarked) {
                onRemoveBrandAnalyticsBookmark(brand.id, type);
              } else {
                onBookmarkBrandAnalytics(brand.id, type);
              }
            },
          })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      </Box>
    );
  };

  return (
    <ContextMenu
      options={[
        {
          renderCustomComponent: () => (
            <Box
              minWidth={250}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              sx={{
                cursor: 'pointer',
                p: theme.spacing(2),
                borderRadius: theme.spacing(2),
                '&:hover': {
                  backgroundColor: theme.colors?.utility[275],
                  '.SL-analytics-more-button': {
                    opacity: 1,
                  },
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                onChange(BrandContentType.Owned);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <IconCustomTick
                  size={12}
                  color={theme.colors?.primary.black}
                  style={{
                    opacity: contentType === BrandContentType.Owned ? 1 : 0,
                  }}
                />

                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="body-lg" fontWeight={500}>
                    Owned analytics
                  </Typography>
                  <Typography
                    variant="body-xs"
                    sx={{
                      ...contentTypeToColor[BrandContentType.Owned],
                      p: theme.spacing(1, 2),
                      borderRadius: theme.spacing(5),
                      fontWeight: 600,
                      width: 'fit-content',
                    }}
                  >
                    Brand Content
                  </Typography>
                </Box>
              </Box>
              {renderMoreButton({
                isBookmarked: brand.bookmarkState.ownedBrandAnalytics,
                type: BookmarkType.BrandOwnedAnalytics,
              })}
            </Box>
          ),
        },
        {
          renderCustomComponent: () => (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              sx={{
                cursor: 'pointer',
                p: theme.spacing(2),
                borderRadius: theme.spacing(2),
                '&:hover': {
                  backgroundColor: theme.colors?.utility[275],
                  '.SL-analytics-more-button': {
                    opacity: 1,
                  },
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                onChange(BrandContentType.Ugc);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <IconCustomTick
                  size={12}
                  color={theme.colors?.primary.black}
                  style={{
                    opacity: contentType === BrandContentType.Ugc ? 1 : 0,
                  }}
                />
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography variant="body-lg" fontWeight={500}>
                    User Generated Content
                  </Typography>
                  <Typography
                    variant="body-xs"
                    sx={{
                      ...contentTypeToColor[BrandContentType.Ugc],
                      p: theme.spacing(1, 2),
                      borderRadius: theme.spacing(5),
                      fontWeight: 600,
                      width: 'fit-content',
                    }}
                  >
                    Brand Mention
                  </Typography>
                </Box>
              </Box>
              {renderMoreButton({
                isBookmarked: brand.bookmarkState.ugcAnalytics,
                type: BookmarkType.BrandUgcAnalytics,
              })}
            </Box>
          ),
        },
      ]}
      PaperProps={{
        sx: {
          p: 1,
        },
        style: {
          backdropFilter: 'none',
          background: theme.colors?.primary.white,
        },
      }}
      renderButton={() => (
        <Box
          display="flex"
          alignItems="center"
          gap={3}
          sx={{
            backgroundColor: theme.colors?.utility[275],
            p: theme.spacing(2, 4),
            borderRadius: theme.spacing(20),
            cursor: 'pointer',
          }}
        >
          <Avatar
            sx={{
              width: 24,
              height: 24,
            }}
            src={creatorProfilePicture}
          />
          <Typography variant="headline-lg" color={theme.colors?.utility[800]}>
            {brand.name}
          </Typography>
          <Typography
            variant="body-xl"
            sx={{
              ...contentTypeToColor[contentType],
              p: theme.spacing(1, 2),
              borderRadius: theme.spacing(5),
              fontWeight: 600,
            }}
          >
            {contentType === BrandContentType.Owned
              ? 'Owned analytics'
              : 'User Generated Content'}
          </Typography>

          <IconLinearArrowDown size={16} />
        </Box>
      )}
    />
  );
};
