import { gql } from '@apollo/client';
import { Grid } from '@mui/material';
import { SocialPostCardView } from 'features/socialPost';
import {
  SocialPostFragmentSlaInfinitePostsGridFragment,
  SocialPostFragmentSocialPostCardViewFragmentDoc,
} from 'graphql/generated';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostFragmentSLAInfinitePostsGrid on SocialPostModel {
    ...SocialPostFragmentSocialPostCardView
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

type Props = {
  brandId: string;
  socialPosts: SocialPostFragmentSlaInfinitePostsGridFragment[];
  hasMore: boolean;
  loadMore: () => void;
};

export const SLAInfinitePostsGrid = ({
  brandId,
  loadMore,
  hasMore,
  socialPosts,
}: Props) => {
  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      threshold={200}
      useWindow={false}
    >
      <Grid container spacing={4} columns={20}>
        {socialPosts.map((post, index) => {
          return (
            <Grid
              item
              xs={10}
              md={5}
              lg={4}
              key={post.id + index}
              component={Link}
              to={PlotRoutes.socialListeningPost(post.id, brandId)}
            >
              <SocialPostCardView socialPost={post} />
            </Grid>
          );
        })}
      </Grid>
    </InfiniteScroll>
  );
};
