import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Popover } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import { theme } from 'styles/theme';
import peekingAnimationData from '../../../../../../assets/lotties/peeking.json';
import {
  CreatorPeekingContent,
  CreatorPeekingContentProps,
} from '../creatorPeekingContent';

interface CreatorPeekingButtonProps extends CreatorPeekingContentProps {
  renderButton?: (isOpen: boolean) => ReactNode;
}

export const CreatorPeekingButton = ({
  renderButton,
  ...rest
}: CreatorPeekingButtonProps) => {
  const peekingButtonRef = useRef<HTMLButtonElement>(null);
  const peekingPopover = useDisclosure();

  const hasPeekedAt =
    rest.isTrackingInsights || rest.selectedTopicIds.length > 0;

  const [hasAnimationPlayed, setHasAnimationPlayed] = useState(true);

  // Whenever hasPeekedAt changes to true, we need to trigger the animation to play
  useEffect(() => {
    if (hasPeekedAt) {
      setHasAnimationPlayed(false);
    }
  }, [hasPeekedAt]);

  return (
    <>
      <Box ref={peekingButtonRef} onClick={peekingPopover.onOpen}>
        {renderButton ? (
          renderButton(peekingPopover.isOpen)
        ) : (
          <Button
            variant="primary-alt"
            startIcon={
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'visible',
                  pt: 1,
                }}
              >
                <Lottie
                  options={{
                    loop: false,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice',
                    },
                    animationData: peekingAnimationData,
                  }}
                  width={28}
                  height={28}
                  style={{
                    flexShrink: 0,
                    filter: hasPeekedAt
                      ? 'grayscale(100%) invert(1)'
                      : undefined,
                  }}
                  isStopped={!hasPeekedAt || hasAnimationPlayed}
                  eventListeners={[
                    {
                      eventName: 'complete',
                      callback: () => setHasAnimationPlayed(true),
                    },
                  ]}
                />
              </Box>
            }
            sx={{
              borderRadius: 2,
              padding: theme.spacing(2, 3),

              ...(!hasPeekedAt && {
                background: 'transparent',
                color: theme.colors?.primary.black,
                '&:hover': {
                  background: 'transparent',
                  color: theme.colors?.primary.black,
                },
              }),

              border: `2px solid ${theme.colors?.primary.black}`,
            }}
          >
            {hasPeekedAt ? 'Peeking' : 'Peek'}
          </Button>
        )}
      </Box>
      <Popover
        open={peekingPopover.isOpen}
        anchorEl={peekingButtonRef.current}
        onClose={peekingPopover.onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        // need to add the zIndex cause when the tooltip is open, the popover is hiding behind the tooltip
        sx={{
          zIndex: 16000,
        }}
        PaperProps={{
          sx: {
            p: theme.spacing(4, 3),
            borderRadius: 3,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            border: 'none',
            maxWidth: 410,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            li: {
              '> :first-child': {
                alignItems: 'flex-start',
              },
            },
          },
        }}
      >
        <CreatorPeekingContent {...rest} />
      </Popover>
    </>
  );
};
