import { gql } from '@apollo/client';
import { useBookmarkHandlers } from 'features/bookmark';
import {
  BookmarkFragmentForBookmarkHandlersFragmentDoc,
  BookmarkType,
  useCreateAnalyticBookmarkForBrandCompetitorBookmarkHandlersMutation,
  useCreateCompetitorBookmarkForBrandCompetitorBookmarkHandlersMutation,
  useDeleteAnalyticBookmarkForBrandCompetitorBookmarkHandlersMutation,
  useDeleteCompetitorBookmarkForBrandCompetitorBookmarkHandlersMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreateAnalyticBookmarkForBrandCompetitorBookmarkHandlers(
    $input: CreateBrandBookmarkInput!
  ) {
    createBrandBookmark(input: $input) {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
  ${BookmarkFragmentForBookmarkHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteAnalyticBookmarkForBrandCompetitorBookmarkHandlers(
    $input: DeleteBrandBookmarkInput!
  ) {
    deleteBrandBookmark(input: $input) {
      message
      success
      deleted {
        id
      }
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation CreateCompetitorBookmarkForBrandCompetitorBookmarkHandlers(
    $input: CreateCompetitorBookmarkInput!
  ) {
    createCompetitorBookmark(input: $input) {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
  ${BookmarkFragmentForBookmarkHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteCompetitorBookmarkForBrandCompetitorBookmarkHandlers(
    $input: DeleteCompetitorBookmarkInput!
  ) {
    deleteCompetitorBookmark(input: $input) {
      message
      success
      deleted {
        id
      }
    }
  }
`;

export const useBrandCompetitorBookmarkHandlers = () => {
  const [createAnalyticBookmark] =
    useCreateAnalyticBookmarkForBrandCompetitorBookmarkHandlersMutation();
  const [deleteAnalyticBookmark] =
    useDeleteAnalyticBookmarkForBrandCompetitorBookmarkHandlersMutation();

  const [createCompetitorBookmark] =
    useCreateCompetitorBookmarkForBrandCompetitorBookmarkHandlersMutation();
  const [deleteCompetitorBookmark] =
    useDeleteCompetitorBookmarkForBrandCompetitorBookmarkHandlersMutation();

  const { addBookmarkItemToSidebar, removeBookmarkItemFromSidebar } =
    useBookmarkHandlers();

  const onBookmarkCompetitorAnalytic = (brandId: string) => {
    createAnalyticBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.BrandCompetitorAnalytics,
        },
      },
      update: (cache, { data }) => {
        if (data?.createBrandBookmark.id) {
          addBookmarkItemToSidebar(data.createBrandBookmark);
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                competitorAnalytics: true,
              };
            },
          });
        }
      },
    });
  };

  const onRemoveCompetitorAnalyticBookmark = (brandId: string) => {
    deleteAnalyticBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.BrandCompetitorAnalytics,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteBrandBookmark.success) {
          removeBookmarkItemFromSidebar(
            data.deleteBrandBookmark.deleted?.[0]?.id ?? '',
          );
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                competitorAnalytics: false,
              };
            },
          });
        }
      },
    });
  };

  const onBookmarkCompetitor = (competitorId: string) => {
    createCompetitorBookmark({
      variables: {
        input: {
          competitorId,
        },
      },
      update: (cache, { data }) => {
        if (data?.createCompetitorBookmark.id) {
          addBookmarkItemToSidebar(data.createCompetitorBookmark);
          modifyObject(cache, competitorId, 'CompetitorModel', {
            isBookmarked: () => true,
          });
        }
      },
    });
  };

  const onRemoveCompetitorBookmark = (competitorId: string) => {
    deleteCompetitorBookmark({
      variables: {
        input: {
          competitorId,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteCompetitorBookmark.success) {
          removeBookmarkItemFromSidebar(
            data.deleteCompetitorBookmark.deleted?.[0]?.id ?? '',
          );
          modifyObject(cache, competitorId, 'CompetitorModel', {
            isBookmarked: () => false,
          });
        }
      },
    });
  };

  return {
    onBookmarkCompetitorAnalytic,
    onRemoveCompetitorAnalyticBookmark,
    onBookmarkCompetitor,
    onRemoveCompetitorBookmark,
  };
};
