import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { IconOutlineNotification } from 'components/icons/components/outline/IconOutlineNotification';
import { useGetNotificationUnreadCountForNotificationsSidebarLinkQuery } from 'graphql/generated';
import { Link, useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetNotificationUnreadCountForNotificationsSidebarLink {
    unreadNotificationsV2
  }
`;

export const NotificationSidebarLink = () => {
  const { data: unreadNotificationData } =
    useGetNotificationUnreadCountForNotificationsSidebarLinkQuery();
  const location = useLocation();

  const unreadNotificationCount =
    unreadNotificationData?.unreadNotificationsV2 ?? 0;

  const isActive = location.pathname.includes(PlotRoutes.inbox());

  return (
    <Link to={PlotRoutes.inbox()}>
      <IconButton
        disableRipple
        sx={{
          p: 2,
          borderRadius: 2,
          ':hover': {
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
          },
          color: isActive
            ? theme.colors?.primary.black
            : theme.colors?.utility[800],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 3,
          width: '100%',
        }}
      >
        <IconOutlineNotification
          size={16}
          color={
            isActive ? theme.colors?.primary.black : theme.colors?.utility[700]
          }
        />
        <Typography variant="subhead-lg">Notifications</Typography>
        {!!unreadNotificationCount && (
          <Box
            sx={{
              borderRadius: 1,
              padding: theme.spacing(0.5, 1),
              background: theme.colors?.utility['pink-3'],
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              ...theme.typography['headline-xxs'],
              color: theme.colors?.primary.white,
            }}
          >
            {unreadNotificationCount > 99 ? '99+' : unreadNotificationCount}
          </Box>
        )}
      </IconButton>
    </Link>
  );
};
