import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledStatBox = styled(Box)({
  padding: theme.spacing(4, 6),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3.5),
  borderRadius: theme.spacing(4),
  background: 'rgba(205, 226, 223, 0.50)',
  cursor: 'pointer',
});
