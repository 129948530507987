import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { SocialPostThumbnail } from 'features/socialPost';
import {
  SocialPostCommentFragmentSocialPostCommentResponderPillFragmentDoc,
  SocialPostCommentFragmentSocialPostCommentCardViewFragment,
  SocialPostCommentFragmentSocialPostCommentCreatedTimeFragmentDoc,
  SocialPostCommentFragmentSocialPostCommentLikesFragmentDoc,
  SocialPostCommentFragmentSocialPostCommentRepliesFragmentDoc,
  SocialPostCommentFragmentSocialPostCommentTextFragmentDoc,
  SocialPostFragmentSocialPostThumbnailFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import {
  SocialPostCommentResponderPill,
  SocialPostCommentCreatedTime,
  SocialPostCommentLikes,
  SocialPostCommentReplies,
  SocialPostCommentText,
  SocialPostCommentTextProps,
} from '../../components';

// eslint-disable-next-line
gql`
  fragment SocialPostCommentFragmentSocialPostCommentCardView on SocialPostCommentModel {
    id
    socialPost {
      ...SocialPostFragmentSocialPostThumbnail
    }
    ...SocialPostCommentFragmentSocialPostCommentReplies
    ...SocialPostCommentFragmentSocialPostCommentText
    ...SocialPostCommentFragmentSocialPostCommentLikes
    ...SocialPostCommentFragmentSocialPostCommentCreatedTime
    ...SocialPostCommentFragmentSocialPostCommentResponderPill
  }
  ${SocialPostFragmentSocialPostThumbnailFragmentDoc}
  ${SocialPostCommentFragmentSocialPostCommentRepliesFragmentDoc}
  ${SocialPostCommentFragmentSocialPostCommentTextFragmentDoc}
  ${SocialPostCommentFragmentSocialPostCommentLikesFragmentDoc}
  ${SocialPostCommentFragmentSocialPostCommentCreatedTimeFragmentDoc}
  ${SocialPostCommentFragmentSocialPostCommentResponderPillFragmentDoc}
`;

export type SocialPostCommentCardViewProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentCardViewFragment;
  brandId: string;
  componentsProps?: {
    text?: Pick<SocialPostCommentTextProps, 'sx'>;
  };
};

export const SocialPostCommentCardView = (
  props: SocialPostCommentCardViewProps,
) => {
  const { socialPostComment, componentsProps = {}, brandId } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box>
        <SocialPostThumbnail
          socialPost={socialPostComment.socialPost}
          sx={{
            width: theme.spacing(37),
            height: theme.spacing(49),
            borderRadius: theme.spacing(4),
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(3),
          bgcolor: theme.colors?.primary.parchment,
          borderRadius: theme.spacing(4),
          padding: theme.spacing(3),
          marginLeft: theme.spacing(-7),
          flex: 1,
          zIndex: 1,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SocialPostCommentCreatedTime socialPostComment={socialPostComment} />
          <SocialPostCommentResponderPill
            socialPostComment={socialPostComment}
            brandId={brandId}
          />
        </Box>

        <Box>
          <SocialPostCommentText
            socialPostComment={socialPostComment}
            {...componentsProps.text}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: theme.spacing(2) }}>
          <SocialPostCommentLikes
            socialPostComment={socialPostComment}
            componentProps={{
              sx: {
                bgcolor: theme.colors?.utility['purple-1'],
                borderRadius: theme.spacing(1),
                padding: theme.spacing(0.2, 2),
              },
              textSx: {
                color: theme.colors?.utility['purple-4'],
              },
            }}
          />
          <SocialPostCommentReplies
            socialPostComment={socialPostComment}
            componentProps={{
              sx: {
                bgcolor: theme.colors?.utility['teal-1'],
                borderRadius: theme.spacing(1),
                padding: theme.spacing(0.2, 2),
              },
              textSx: {
                color: theme.colors?.utility['teal-4'],
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
