import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import moment from 'moment';
import { theme } from 'styles/theme';
import { StyledIconContainer, StyledMenuItemContainer } from '../../styles';

type ContentCalendarFilterByPeriodProps = {
  selectedPeriodLabel: string | null;
  selectedPeriodCustomStartDate: string | null;
  selectedPeriodCustomEndDate: string | null;
  onSelectedPeriodLabel: (
    selectedPeriodLabel: string,
    startDate: string,
    endDate: string,
  ) => void;
};

export const ContentCalendarFilterByPeriod = (
  props: ContentCalendarFilterByPeriodProps,
) => {
  const {
    selectedPeriodLabel,
    selectedPeriodCustomStartDate,
    selectedPeriodCustomEndDate,
    onSelectedPeriodLabel,
  } = props;

  const {
    isOpen: dateRangeOpen,
    onOpen: onDateRangeOnOpen,
    onClose: onDateRangeOnClose,
  } = useDisclosure();

  return (
    <DropDownMoreMenuItem
      disableRipple
      singleSelectOnly
      label={
        <StyledMenuItemContainer>
          <StyledIconContainer>
            <IconOutlineCalendar size={16} />
          </StyledIconContainer>

          <Typography variant="subhead-lg">
            Period{' '}
            {selectedPeriodLabel && (
              <Typography
                variant="subhead-lg"
                color={theme.colors?.utility[600]}
              >
                ({selectedPeriodLabel})
              </Typography>
            )}
          </Typography>
        </StyledMenuItemContainer>
      }
      dropDownOptions={[
        {
          label: <Typography variant="subhead-lg">Today</Typography>,
          isChecked: selectedPeriodLabel === 'Today',
          onClick: () =>
            onSelectedPeriodLabel(
              'Today',
              moment().toISOString(),
              moment().toISOString(),
            ),
        },
        {
          label: <Typography variant="subhead-lg">Last 7 days</Typography>,
          isChecked: selectedPeriodLabel === 'Last 7 days',
          onClick: () =>
            onSelectedPeriodLabel(
              'Last 7 days',
              moment().subtract(7, 'day').toISOString(),
              moment().toISOString(),
            ),
        },
        {
          label: <Typography variant="subhead-lg">Last 30 days</Typography>,
          isChecked: selectedPeriodLabel === 'Last 30 days',
          onClick: () =>
            onSelectedPeriodLabel(
              'Last 30 days',
              moment().subtract(30, 'day').toISOString(),
              moment().toISOString(),
            ),
        },
        {
          label: (
            <Typography variant="subhead-lg">
              This year ({moment().startOf('year').year()})
            </Typography>
          ),
          isChecked: selectedPeriodLabel === 'This year',
          onClick: () =>
            onSelectedPeriodLabel(
              'This year',
              moment().startOf('year').toISOString(),
              moment().toISOString(),
            ),
        },
        {
          label: (
            <Typography variant="subhead-lg">
              Last year ({moment().subtract(1, 'year').year()})
            </Typography>
          ),
          isChecked: selectedPeriodLabel === 'Last year',
          onClick: () =>
            onSelectedPeriodLabel(
              'Last year',
              moment().subtract(1, 'year').startOf('year').toISOString(),
              moment().subtract(1, 'year').endOf('year').toISOString(),
            ),
        },
        {
          onClick: onDateRangeOnOpen,
          label: (
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="subhead-lg">
                  {selectedPeriodLabel === CUSTOM_RANGE_LABEL
                    ? `${moment(selectedPeriodCustomStartDate).format(
                        'DD MMM',
                      )} - 
                              ${moment(selectedPeriodCustomEndDate).format(
                                'DD MMM',
                              )}`
                    : CUSTOM_RANGE_LABEL}
                </Typography>
                <IconLinearArrowRight
                  size={16}
                  style={{
                    position: 'absolute',
                    right: 16,
                    marginTop: 2,
                  }}
                />
              </Box>
              <DateRangePicker
                value={[
                  moment(selectedPeriodCustomStartDate),
                  moment(selectedPeriodCustomEndDate),
                ]}
                calendars={1}
                open={dateRangeOpen}
                onClose={onDateRangeOnClose}
                onChange={(dates) => {
                  if (dates.length === 2 && dates[0] && dates[1]) {
                    onSelectedPeriodLabel(
                      CUSTOM_RANGE_LABEL,
                      dates[0].toISOString(),
                      dates[1].toISOString(),
                    );
                  }
                }}
                sx={{ '& .MuiTypography-root': { display: 'none' } }}
                slots={{
                  // eslint-disable-next-line
                  textField: () => null,
                }}
                slotProps={{
                  actionBar: {
                    actions: [],
                  },
                }}
              />
            </Box>
          ),
          isChecked: selectedPeriodLabel === CUSTOM_RANGE_LABEL,
        },
      ]}
    />
  );
};
