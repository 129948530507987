import { Box, MenuItem, MenuItemProps, Typography } from '@mui/material';
import { IconBold3dCubeScan } from 'components/icons/components/bold/IconBold3dCubeScan';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { forwardRef } from 'react';
import { theme } from 'styles/theme';

export type NestedFiltersGroupHeaderProps = MenuItemProps & {
  open?: boolean;
  label: string;
  Icon?: typeof IconBold3dCubeScan;
  renderSelectedOptions?: (selectedOptions: string[]) => JSX.Element;
  onClick: () => void;
};

export const NestedFiltersGroupHeader = forwardRef(
  (props: NestedFiltersGroupHeaderProps, ref: React.Ref<HTMLLIElement>) => {
    const { open, label, Icon, renderSelectedOptions, onClick } = props;

    return (
      <MenuItem
        ref={ref}
        onClick={onClick}
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          borderRadius: 2,
        }}
      >
        {Icon && (
          <Box
            sx={{
              width: 24,
              height: 24,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 1,
              color: theme.colors?.primary.black,
              bgcolor: 'rgba(35, 6, 3, 0.05)',
            }}
          >
            <Icon size={16} />
          </Box>
        )}
        <Typography
          variant="subhead-lg"
          color={theme.colors?.primary.black}
          flex={1}
        >
          {label}
        </Typography>
        {renderSelectedOptions && renderSelectedOptions([])}
        <IconLinearArrowRight
          size={16}
          style={{
            transform: `rotate(${open ? 90 : 0}deg)`,
            transition: 'transform 0.2s ease',
          }}
        />
      </MenuItem>
    );
  },
);
