import { Rule } from 'graphql/generated';

export const getFixedRules = (rule: Rule): Rule[] => {
  const fixedRules =
    rule?.rules?.filter(
      (rule) =>
        rule.field !== 'followerCount' &&
        rule.field !== 'playCount' &&
        rule.field !== 'likeCount',
    ) ?? [];
  return fixedRules;
};
