import { Box, Button, Grid, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { CustomDialog } from 'components/common/CustomDialog';
import { RadioMenuItem } from 'components/common/form/Select/RadioMenuItem';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { getCreatorHandleWithPrefix } from 'features/creator';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailView,
} from 'features/socialMediaListening';
import { SocialPostCardView } from 'features/socialPost';
import {
  BrandContentType,
  BrandFragmentSlaBrandTopCreatorPostsViewFragment,
  BrandInboundFiltersInput,
  SortByInputForTopCreatorPostsThatMentionedBrand,
  useGetCreatorForBrandTopCreatorPostsForSlaBrandTopCreatorPostsViewQuery,
  useGetTopCreatorPostsThatMentionedBrandForSlaBrandTopCreatorPostsViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

type Props = {
  brand: BrandFragmentSlaBrandTopCreatorPostsViewFragment;
  filters: BrandInboundFiltersInput;
  creatorId: string;
  isOpen: boolean;
  onClose: () => void;
  defaultDateRange?: {
    startDate: Date;
    endDate: Date;
  };
};

export const SLABrandTopCreatorPostsView = ({
  brand,
  filters,
  creatorId,
  isOpen,
  onClose,
  defaultDateRange,
}: Props) => {
  const [sortKey, setSortKey] = useState(
    SortByInputForTopCreatorPostsThatMentionedBrand.PlayCount,
  );
  const [currentPostId, setCurrentPostId] = useState('');

  const { data: creatorData } =
    useGetCreatorForBrandTopCreatorPostsForSlaBrandTopCreatorPostsViewQuery({
      variables: { data: { creatorId } },
      skip: !creatorId,
    });
  const creator = creatorData?.getCreator;

  const [dateRange, setDateRange] = useState({
    startDate: moment(filters.dateRange.startDate).subtract(7, 'days').toDate(),
    endDate: moment(filters.dateRange.endDate).toDate(),
  });

  useEffect(() => {
    setDateRange({
      startDate: filters.dateRange?.startDate,
      endDate: filters.dateRange?.endDate,
    });
  }, [filters.dateRange?.startDate, filters.dateRange?.endDate, isOpen]);

  useEffect(() => {
    if (defaultDateRange) {
      setDateRange(defaultDateRange);
    }
  }, [defaultDateRange]);

  const {
    data: socialPostsData,
    loading: socialPostsLoading,
    fetchMore: fetchMoreSocialPosts,
  } = useGetTopCreatorPostsThatMentionedBrandForSlaBrandTopCreatorPostsViewQuery(
    {
      variables: {
        data: {
          creatorId,
          filters: {
            ...filters,
            dateRange: {
              startDate: dateRange.startDate,
              endDate: dateRange.endDate,
            },
          },
          sortBy: sortKey,
        },
      },
    },
  );
  const socialPosts = socialPostsData?.topCreatorPostsThatMentionedBrand;

  const handleBackClick = () => {
    if (currentPostId) {
      setCurrentPostId('');
    } else {
      onClose();
    }
  };

  return (
    <CustomDialog
      open={isOpen}
      onClose={onClose}
      titleBreadcrumbs={[
        `${brand.name}'s Top Creators`,
        creator?.nickName ?? '',
      ]}
      copyLink={`${
        window.location.origin
      }${PlotRoutes.socialListeningAnalyticsInbound(brand.id)}`}
      onBackClick={handleBackClick}
    >
      <Box display="flex" flexDirection="column" height="100%">
        {!currentPostId && (
          <Box
            p={3}
            flexGrow={1}
            overflow="auto"
            sx={{ backgroundColor: '#FAF3ECCC' }}
          >
            <InfiniteScroll
              loadMore={() => {
                fetchMoreSocialPosts({
                  variables: {
                    data: {
                      creatorId,
                      filters,
                      sortBy: { field: sortKey },
                      skip: socialPosts?.data?.length,
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      topCreatorPostsThatMentionedBrand: {
                        ...fetchMoreResult.topCreatorPostsThatMentionedBrand,
                        data: [
                          ...prev.topCreatorPostsThatMentionedBrand.data,
                          ...fetchMoreResult.topCreatorPostsThatMentionedBrand
                            .data,
                        ],
                      },
                    };
                  },
                });
              }}
              hasMore={
                socialPostsData?.topCreatorPostsThatMentionedBrand.pageInfo
                  .hasNextPage
              }
              threshold={50}
              useWindow={false}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                mb={3}
                justifyContent="space-between"
                width="100%"
                sx={{
                  p: 4,
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="headline-lg" mr={4}>
                      {creator?.nickName}
                    </Typography>
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.utility[700]}
                    >
                      {getCreatorHandleWithPrefix(creator?.handle ?? '')}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body-xl"
                    fontWeight={600}
                    mt={2}
                    color={theme.colors?.utility[700]}
                  >
                    {socialPosts?.meta.totalCount} posts
                  </Typography>
                </Box>

                <SocialMediaListeningFilterByDateRangeButton
                  onChange={(range) => {
                    if (range[0] && range[1]) {
                      setDateRange({
                        startDate: range[0],
                        endDate: range[1],
                      });
                    }
                  }}
                  selectedDateRange={[dateRange.startDate, dateRange.endDate]}
                />
              </Box>

              <Box sx={{ m: 4 }}>
                <Grid
                  container
                  spacing={2}
                  mb={3}
                  sx={{
                    backgroundColor: theme.colors?.primary.parchment,
                    borderRadius: 2,
                    p: 4,
                  }}
                >
                  <Grid item xs={6}>
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Number of{' '}
                        {filters.contentType === BrandContentType.Topic
                          ? 'topic'
                          : 'brand'}{' '}
                        mentions
                        <Tooltip
                          title={`Number of ${
                            filters.contentType === BrandContentType.Topic
                              ? 'topic'
                              : 'brand'
                          } mentions`}
                        >
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                        fontFamily="Good Sans"
                      >
                        {socialPosts?.meta.totalCount}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      borderLeft: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      pl={4}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Number of followers
                        <Tooltip title="Number of followers creator has">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                        fontFamily="Good Sans"
                      >
                        {creator?.followerCount
                          ? formatBigNumber(creator?.followerCount)
                          : '-'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                  my: 4,
                }}
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                m={4}
                mb={2}
              >
                <Typography variant="headline-sm">Posts</Typography>
                <ContextMenu
                  sx={{
                    '& .context-menu-item': {
                      p: 0,
                      color: theme.colors?.primary.black,
                    },
                  }}
                  options={[
                    {
                      renderOption: () => (
                        <RadioMenuItem
                          label="Play Count"
                          value={
                            SortByInputForTopCreatorPostsThatMentionedBrand.PlayCount
                          }
                          checked={
                            sortKey ===
                            SortByInputForTopCreatorPostsThatMentionedBrand.PlayCount
                          }
                          sx={{
                            p: `${theme.spacing(2, 3)}`,
                          }}
                        />
                      ),
                      onClick: () => {
                        setSortKey(
                          SortByInputForTopCreatorPostsThatMentionedBrand.PlayCount,
                        );
                      },
                    },
                    {
                      renderOption: () => (
                        <RadioMenuItem
                          label="Engagement Rate"
                          value={
                            SortByInputForTopCreatorPostsThatMentionedBrand.EngagementRate
                          }
                          checked={
                            sortKey ===
                            SortByInputForTopCreatorPostsThatMentionedBrand.EngagementRate
                          }
                          sx={{
                            p: `${theme.spacing(2, 3)}`,
                          }}
                        />
                      ),
                      onClick: () => {
                        setSortKey(
                          SortByInputForTopCreatorPostsThatMentionedBrand.EngagementRate,
                        );
                      },
                    },
                    {
                      renderOption: () => (
                        <RadioMenuItem
                          label="Like Count"
                          value={
                            SortByInputForTopCreatorPostsThatMentionedBrand.LikeCount
                          }
                          checked={
                            sortKey ===
                            SortByInputForTopCreatorPostsThatMentionedBrand.LikeCount
                          }
                          sx={{
                            p: `${theme.spacing(2, 3)}`,
                          }}
                        />
                      ),
                      onClick: () => {
                        setSortKey(
                          SortByInputForTopCreatorPostsThatMentionedBrand.LikeCount,
                        );
                      },
                    },
                  ]}
                  renderButton={() => (
                    <Button
                      sx={{
                        backgroundColor: theme.colors?.utility[275],
                        color: theme.colors?.primary.black,
                        borderRadius: theme.spacing(2),
                        display: 'flex',
                        gap: 2,
                        p: theme.spacing(1, 2),
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                      disableRipple={false}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundColor: 'rgba(35, 6, 3, 0.05);',
                          p: theme.spacing(1),
                          borderRadius: theme.spacing(1),
                        }}
                      >
                        <IconLinearSort size={16} />
                      </Box>
                      <Typography fontWeight={500} variant="body-xl">
                        Sort by
                      </Typography>
                    </Button>
                  )}
                />
              </Box>

              {!socialPostsLoading && (
                <Box sx={{ m: 4 }}>
                  {socialPosts?.data?.length ? (
                    <Grid container spacing={2}>
                      {socialPosts?.data?.map((post) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            lg={2.4}
                            key={post.id}
                          >
                            <Box
                              sx={{
                                position: 'relative',
                              }}
                              onClick={() => setCurrentPostId(post.id)}
                            >
                              <SocialPostCardView socialPost={post} />
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="body-xl"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        No posts found
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </InfiniteScroll>
          </Box>
        )}
        {currentPostId && (
          <Box
            px={12}
            sx={{
              backgroundColor: theme.colors?.primary.parchment,
              minHeight: theme.spacing(100),
              overflow: 'auto',
            }}
          >
            <Box
              p={4}
              mt={8}
              sx={{
                backgroundColor: theme.colors?.primary.white,
                borderRadius: theme.spacing(4),
              }}
            >
              <SocialMediaListeningPostDetailView
                brandId={brand.id}
                socialPostId={currentPostId}
                componentProps={{
                  modalView: true,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
};
