import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { CreatorAvatar } from 'features/creator';
import { SocialListeningCreatorProfileFiltersView } from 'features/socialListeningCreatorProfile/views/filters';
import {
  CreatorPeeking,
  SocialListeningFeatureType,
  SocialMediaListeningTopHeaderBreadcrumbs,
} from 'features/socialMediaListening';
import {
  CreatorFragmentCreatorAvatarFragmentDoc,
  CreatorFragmentCreatorPeekingFragmentDoc,
  CreatorProfileFragmentAboutTabContentFragmentDoc,
  CreatorProfileFragmentActivityTabContentFragmentDoc,
  Platform,
  PlotFeature,
  SimpleDateRangeFilterInput,
  useGetCreatorProfileForSlCreatorProfilePageQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import * as yup from 'yup';
import { dateRangeOptions, NestedFiltersValues } from '../community/Filters';
import { StyledTabButton, StyledTabContainer } from './styles';
import { AboutTabContent, ActivityTabContent } from './tabContent';

// eslint-disable-next-line
gql`
  query GetCreatorProfileForSLCreatorProfilePage(
    $id: String!
    $brandId: String!
  ) {
    creatorProfile(id: $id) {
      id
      fullName
      brand {
        id
        name
      }
      defaultCreatorId
      creators {
        id
        ...CreatorFragmentCreatorAvatar
        ...CreatorFragmentCreatorPeeking
      }
      ...CreatorProfileFragmentAboutTabContent
      ...CreatorProfileFragmentActivityTabContent
    }
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
  ${CreatorFragmentCreatorPeekingFragmentDoc}
  ${CreatorProfileFragmentAboutTabContentFragmentDoc}
  ${CreatorProfileFragmentActivityTabContentFragmentDoc}
`;

enum CreatorProfileTab {
  Activity = 'Activity',
  About = 'About',
}

const SOCIAL_LISTENING_CREATOR_PROFILE_FILTERS =
  'social-listening-creator-profile-filters';

export type CreatorProfileFilters = {
  platforms: Platform[];
  dateRange: {
    label: string;
    value: SimpleDateRangeFilterInput;
  };
};

export const SocialMediaListeningCreatorProfilePage = () => {
  const { creatorProfileId = '', brandId = '' } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const analytics = useAnalytics();
  const { user } = useUserContext();
  // See SocialListeningCommunityTableRow for where the state is set
  const location = useLocation();
  const state = location.state as {
    rawFilters: NestedFiltersValues;
  };

  const { isFeatureEnabled, loading } = useFeatureFlagContext();
  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  const [currentTab, _setCurrentTab] = useState<CreatorProfileTab>(
    Object.values(CreatorProfileTab).includes(
      searchParams.get('tab') as CreatorProfileTab,
    )
      ? (searchParams.get('tab') as CreatorProfileTab)
      : CreatorProfileTab.Activity,
  );
  const setCurrentTab = (tab: CreatorProfileTab) => {
    _setCurrentTab(tab);
    searchParams.set('tab', tab);
    navigate(
      { search: searchParams.toString() },
      { replace: true, state: location.state },
    );
  };

  const { value: filters, setValue: setFilters } =
    useLocalStorage<CreatorProfileFilters>(
      SOCIAL_LISTENING_CREATOR_PROFILE_FILTERS + creatorProfileId,
      {
        platforms: [],
        dateRange: {
          label: dateRangeOptions[0].label,
          value: {
            startDate: dateRangeOptions[0].startDate,
            endDate: dateRangeOptions[0].endDate,
          },
        },
      },
      yup
        .object()
        .shape({
          platforms: yup
            .array()
            .of(yup.string().oneOf(Object.values(Platform)))
            .optional(),
          dateRange: yup
            .object()
            .shape({
              label: yup.string().optional(),
              value: yup
                .object()
                .shape({
                  startDate: yup.date().optional(),
                  endDate: yup.date().optional(),
                })
                .optional(),
            })
            .optional(),
        })
        .noUnknown(),
    );

  useEffect(() => {
    analytics.track(EventName.CreatorProfileAboutFilterApplied, {
      brandId,
      userId: user?.id,
      creatorProfileId,
      filters,
    });
  }, [JSON.stringify(filters)]);

  // If rawFilters are available, they are passed in from the community table
  // We should update the filters to match the rawFilters
  useEffect(() => {
    if (state?.rawFilters?.dateRange?.value) {
      setFilters({
        ...filters,
        dateRange: {
          label: state.rawFilters.dateRange.value.label,
          value: {
            startDate: state.rawFilters.dateRange.value.startDate,
            endDate: state.rawFilters.dateRange.value.endDate,
          },
        },
      });
    }
  }, [state?.rawFilters]); // eslint-disable-line

  const {
    data,
    updateQuery,
    loading: queryLoading,
  } = useGetCreatorProfileForSlCreatorProfilePageQuery({
    variables: {
      id: creatorProfileId,
      brandId,
    },
    skip: !brandId || !creatorProfileId,
    fetchPolicy: 'cache-and-network',
  });
  const creatorProfile = data?.creatorProfile;

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.SocialListeningCrm,
      );
      if (!_isFeatureEnabled) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line

  // Initially when getting filters from local storage,
  // fields like dateRange could be out of sync with the actual date range
  // so we need to update the date range to the latest date range by label
  useEffect(() => {
    if (filters.dateRange?.label) {
      const option = dateRangeOptions.find(
        (option) => option.label === filters.dateRange?.label,
      );

      if (option) {
        setFilters({
          ...filters,
          dateRange: {
            label: option?.label,
            value: {
              startDate: option.startDate,
              endDate: option.endDate,
            },
          },
        });
      }
    }
  }, []); // eslint-disable-line -- Run only once on mount

  const tabOptions = [
    {
      key: CreatorProfileTab.Activity,
      label: (
        <StyledTabButton isActive={currentTab === CreatorProfileTab.Activity}>
          Activity
        </StyledTabButton>
      ),
      content: <ActivityTabContent creatorProfile={creatorProfile!} />,
    },
    {
      key: CreatorProfileTab.About,
      label: (
        <StyledTabButton isActive={currentTab === CreatorProfileTab.About}>
          About
        </StyledTabButton>
      ),
      content: (
        <AboutTabContent
          creatorProfile={creatorProfile!}
          dateRange={filters.dateRange.value}
          platforms={filters.platforms}
          loading={queryLoading}
        />
      ),
    },
  ];

  useEffect(() => {
    setHeaderSx({
      borderBottom: '1px solid rgba(35, 6, 3, 0.05)',
      color: theme.colors?.primary.black,
      bgcolor: theme.colors?.primary.white,
    });

    setRenderTitle(() => (
      <Box display="flex" alignItems="center" gap={3}>
        <SocialMediaListeningTopHeaderBreadcrumbs
          brandId={brandId}
          featureType={SocialListeningFeatureType.Community}
        />
      </Box>
    ));

    return () => {
      setRenderTitle(() => null);
      setHeaderSx({});
    };
  }, [location.pathname, creatorProfile]); // eslint-disable-line

  if (!creatorProfile) {
    return null;
  }

  return (
    <Box pt={24} px={8} pb={10} bgcolor={theme.colors?.utility[200]}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 8,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
          <CreatorAvatar
            creator={creatorProfile.creators[0]}
            size={80}
            hidePlatformIcon
          />
          <Typography
            variant="headline-xl"
            color={theme.colors?.primary.black}
            letterSpacing="-0.96px"
          >
            {creatorProfile.fullName}
          </Typography>
        </Box>
        <CreatorPeeking
          brandId={creatorProfile.brand.id}
          creator={creatorProfile.creators[0]}
          onAfterTracking={({ isTrackingInsights, trackedTopicIds }) => {
            analytics.track(EventName.CrmDashboardCreatorPeeked, {
              brandId: creatorProfile.brand.id,
              userId: user?.id,
              creatorProfileId: creatorProfile.id,
              isTrackingInsights,
              trackedTopicIds,
            });

            updateQuery((prev) => ({
              ...prev,
              creatorProfile: {
                ...prev.creatorProfile,
                isTrackingInsights,
                creatorTrackedTopics: !trackedTopicIds.length
                  ? []
                  : trackedTopicIds.map((id) => ({
                      id,
                    })),
              },
            }));
          }}
        />
      </Box>
      <StyledTabContainer>
        <Tabs
          tab={currentTab}
          onChange={(val) => {
            setCurrentTab(val as CreatorProfileTab);
            analytics.track(EventName.CreatorProfileTabSwitched, {
              brandId: creatorProfile.brand.id,
              userId: user?.id,
              creatorProfileId: creatorProfile.id,
              tab: val,
            });
          }}
          tabs={tabOptions}
          className="creator-profile-tabs"
          {...(currentTab === CreatorProfileTab.About && {
            tabsExtra: (
              <SocialListeningCreatorProfileFiltersView
                filters={filters}
                setFilters={setFilters}
              />
            ),
          })}
          tabsExtraAlign="right"
        />
      </StyledTabContainer>
    </Box>
  );
};
