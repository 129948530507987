import { Box, Button, Dialog, Typography } from '@mui/material';
import {
  useBlockCreatorForBlockCreatorDialogMutation,
  useGetCreatorTrackingTopicsForBlockingCreatorDialogQuery,
} from 'graphql/generated';
import { useEffect, useMemo } from 'react';
import { theme } from 'styles/theme';

type BlockCreatorDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  creatorId: string;
  brandId: string;
};

export const BlockCreatorDialog = ({
  isOpen,
  onClose,
  brandId,
  creatorId,
}: BlockCreatorDialogProps) => {
  const [blockCreator, blockCreatorMetadata] =
    useBlockCreatorForBlockCreatorDialogMutation();
  const { data: creatorTrackingTopicData } =
    useGetCreatorTrackingTopicsForBlockingCreatorDialogQuery({
      variables: {
        data: {
          brandId,
          creatorIds: [creatorId],
        },
      },
    });

  useEffect(() => {
    if (
      blockCreatorMetadata.data &&
      blockCreatorMetadata.data.blockCreator.success
    ) {
      onClose();
    }
  }, [blockCreatorMetadata, onClose]);

  const isTrackingCreator = useMemo(
    () =>
      (
        creatorTrackingTopicData?.getTopicsTrackingList?.filter(
          (topic) => topic.isTracking,
        ) || []
      ).length > 0,
    [creatorTrackingTopicData],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 370,
          borderRadius: theme.spacing(6),
          display: isOpen ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          p: 0,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          border: 'none',
        },
        // Stop event propagation in case this component is rendered
        // from within another component that handles click (e.g. link)
        onClick: (e) => e.stopPropagation(),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <Box
            sx={{
              p: 6,
              pb: 4,
              backgroundColor: theme.colors?.primary.parchment,
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Typography
              variant="headline-lg"
              sx={{
                color: theme.colors?.primary.black,
                marginBottom: theme.spacing(2),
              }}
            >
              {isTrackingCreator
                ? 'Awkward! You are currently tracking this creator.'
                : 'Continue to block all content from this account?'}
            </Typography>
            {isTrackingCreator ? (
              <>
                <Typography
                  variant="body-lg"
                  sx={{
                    color: theme.colors?.primary.black,
                    marginBottom: theme.spacing(4),
                  }}
                >
                  Blocking this account will remove them from your tracking
                  list.
                </Typography>
                <Typography
                  variant="body-lg"
                  sx={{
                    color: theme.colors?.primary.black,
                    marginBottom: theme.spacing(4),
                  }}
                >
                  Are you sure you want to ignore all their posts?
                </Typography>
              </>
            ) : (
              <Typography
                variant="body-lg"
                sx={{
                  color: theme.colors?.primary.black,
                  marginBottom: theme.spacing(4),
                }}
              >
                This will remove all content from this account in your feed. Use
                this for irrelevant, noisy, or spammy posts. We'll stop
                processing their content in social listening.
              </Typography>
            )}
            <Button
              variant="primary"
              disabled={blockCreatorMetadata.loading}
              onClick={() => {
                blockCreator({
                  variables: {
                    data: {
                      brandId,
                      creatorId,
                    },
                  },
                });
              }}
              sx={{
                backgroundColor: theme.colors?.primary.black,
                border: 'none',
                borderRadius: theme.spacing(2),
                letterSpacing: 'normal',
              }}
            >
              <Typography
                variant="body-md"
                sx={{
                  color: theme.colors?.primary.white,
                }}
              >
                Yes, block account.
              </Typography>
            </Button>
            <Box
              onClick={onClose}
              component="button"
              sx={{
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(3),
              }}
            >
              <Typography
                variant="body-lg"
                fontWeight="600"
                sx={{
                  color: theme.colors?.primary.black,
                }}
              >
                Cancel
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
