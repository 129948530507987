import { Box, Chip, SxProps } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import {
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuItemType,
} from 'features/nestedFilters/components';
import { useNestedFiltersDefaultContext } from 'features/nestedFilters/contexts/default';
import {
  collectFilterableItems,
  getValueForItem,
  hasFilterValue,
} from 'features/nestedFilters/utils';
import { useMemo, useRef } from 'react';
import { theme } from 'styles/theme';
import { NestedFiltersMenuView } from '../menu/NestedFiltersMenuView';

export type NestedFiltersSelectedChipsProps = {
  id?: string;
  sx?: SxProps;
  componentsProps?: {
    chip?: {
      sx?: SxProps;
    };
  };
};

/**
 * Component that displays selected filters as chips for quick overview
 * Uses NestedFiltersDefaultContext to stay in sync with NestedFiltersMenuView
 * Allows users to click on chips to modify individual filters
 */
export const NestedFiltersSelectedChips = (
  props: NestedFiltersSelectedChipsProps,
) => {
  const { id = 'secondary', sx, componentsProps = {} } = props;

  // Use context for values and items
  const context = useNestedFiltersDefaultContext();

  // If no context is available, return null
  if (!context) {
    return null;
  }

  // Get values, items, and onChange from context
  const { items, values: contextValues, setValues } = context;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const anchorElRef = useRef<HTMLDivElement | null>(null);

  // Collect all filterable items
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const filterableItems = useMemo(() => {
    return collectFilterableItems(items);
  }, [JSON.stringify({ items })]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Check if a filter item has a value selected
   * Uses the shared hasFilterValue utility
   */
  const hasValue = (
    item: NestedFiltersMenuItemType,
    parentKeys: string[],
  ): boolean => {
    const value = getValueForItem(item, parentKeys, contextValues);

    return hasFilterValue(item.type, value);
  };

  /**
   * Get display label for a selected filter value
   */
  const getValueLabel = (
    item: NestedFiltersMenuItemType,
    parentKeys: string[],
  ): string => {
    const value = getValueForItem(item, parentKeys, contextValues);
    if (!value) return '';

    switch (item.type) {
      case 'single-select':
        return value.value?.label || '';
      case 'multi-select':
        if (Array.isArray(value.value)) {
          return `${value.value.map((v) => v.label).join(', ')}${
            value.value.length > 1 ? ` (${value.value.length})` : ''
          }`;
        }
        return '';
      case 'date-range':
        if (value.value?.label) {
          return value.value.label;
        }
        if (value.value?.startDate && value.value?.endDate) {
          return `${value.value.startDate} - ${value.value.endDate}`;
        }
        return '';
      case 'form':
        // Show in format of: [field label]: [field value]
        return item.fields
          .filter((field) => field.key && value.value[field.key])
          .map((field) => `${field.label}: ${value.value[field.key]}`)
          .join(', ');
      default:
        return '';
    }
  };

  /**
   * Handle updating a filter (setting its value)
   */
  const handleUpdateFilter = (
    item: NestedFiltersMenuItemType,
    parentKeys: string[],
    values: NestedFiltersMenuItemBaseValueType<unknown>,
  ) => {
    const originalValues = JSON.parse(JSON.stringify(contextValues));
    let currentLevel = originalValues;

    for (const key of parentKeys) {
      if (!currentLevel[key]) {
        return; // Path doesn't exist, nothing to update
      }

      currentLevel = currentLevel[key] as Record<string, any>;
    }

    if (item.key) {
      currentLevel[item.key] = values[item.key];
    }

    setValues({ ...originalValues, __source: id });
  };

  /**
   * Handle removing a filter (clearing its value)
   */
  const handleRemoveFilter = (
    item: NestedFiltersMenuItemType,
    parentKeys: string[],
  ) => {
    const updatedValues = JSON.parse(JSON.stringify(contextValues));
    let currentLevel = updatedValues;

    // Navigate to the correct nesting level
    for (const key of parentKeys) {
      if (!currentLevel[key]) {
        return; // Path doesn't exist, nothing to remove
      }
      currentLevel = currentLevel[key] as Record<string, any>;
    }

    // Clear the value at the correct level
    if (item.key) {
      if (item.type === 'multi-select') {
        currentLevel[item.key] = { value: [] };
      } else {
        currentLevel[item.key] = { value: undefined };
      }
    }

    setValues({
      ...updatedValues,
      __source: id,
    });
  };

  // Filter out items that have values
  const selectedFilterItems = filterableItems.filter(({ item, parentKeys }) =>
    hasValue(item, parentKeys),
  );

  // If no filters are selected, don't render anything
  if (selectedFilterItems.length === 0) {
    return null;
  }

  return (
    <Box
      ref={anchorElRef}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        ...sx,
      }}
    >
      {selectedFilterItems.map(({ item, parentKeys }) => {
        if (!item.key) {
          return null;
        }

        // Get the label for the item (using the 'label' property if available)
        const label = 'label' in item ? item.label : '';
        const value = getValueForItem(item, parentKeys, contextValues);
        const valueLabel = getValueLabel(item, parentKeys);
        const chipLabel = valueLabel || label;
        const Icon = item.Icon;

        return (
          <NestedFiltersMenuView
            key={`${parentKeys.join('-')}-${item.key}`}
            items={[item]}
            values={{
              [item.key]: value,
            }}
            onChange={(v) => handleUpdateFilter(item, parentKeys, v)}
            renderTrigger={({ onOpen, anchorElRef }) => (
              <Chip
                ref={anchorElRef}
                component="button"
                variant="filled-borderless"
                size="small"
                label={chipLabel}
                onClick={onOpen}
                onDelete={() => handleRemoveFilter(item, parentKeys)}
                deleteIcon={
                  <IconBoldCloseCircle
                    size={16}
                    color={theme.colors?.utility[600]}
                  />
                }
                icon={
                  Icon ? (
                    <Icon
                      size={16}
                      style={{
                        color: theme.colors?.primary.black,
                      }}
                    />
                  ) : undefined
                }
                sx={{
                  bgcolor: theme.colors?.utility[275],
                  span: {
                    ...typography['headline-xs'],
                  },
                  ...componentsProps.chip?.sx,
                }}
              />
            )}
          />
        );
      })}
    </Box>
  );
};
