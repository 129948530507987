import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { HighlightableSection } from 'components/common/HighlightableSection';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { NestedFiltersMenuView } from 'features/nestedFilters';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileBrandMentionViewSkeleton = () => {
  // Mock sort options for the filter menu
  const sortOptions = [{ label: 'Play count', value: 'PLAY_COUNT' }];

  const stats = [
    { label: 'Brand mentions' },
    { label: 'Total view count' },
    { label: 'Total like count' },
    { label: 'Engagement rate' },
    { label: 'Overall sentiment' },
  ];

  return (
    <HighlightableSection
      sectionKey="brand-mention"
      sx={{
        p: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
          Brand mentions
        </Typography>
        <NestedFiltersMenuView
          values={{ sort: { value: sortOptions[0] } }}
          items={[{ type: 'single-select', key: 'sort', options: sortOptions }]}
          onChange={() => {}}
          componentsProps={{
            trigger: {
              text: 'Sort',
              Icon: IconLinearSort,
            },
          }}
        />
      </Box>

      <Box
        sx={{
          background: theme.colors?.utility[275],
          borderRadius: 3,
          display: 'flex',
          flexWrap: 'wrap',
          p: 4,
          gap: 4,
        }}
      >
        {stats.map((stat, index) => (
          <Box
            key={index}
            sx={{
              [theme.breakpoints.up('md')]: {
                borderRight:
                  index + 1 !== stats.length
                    ? `1px solid ${theme.colors?.utility[400]}`
                    : 'none',
              },
              flex: 1,
              minWidth: 140,
            }}
          >
            <Box
              sx={{
                display: 'grid',
                gap: 3,
              }}
            >
              <Typography
                variant="headline-xxs"
                color={theme.colors?.utility[800]}
              >
                {stat.label}
              </Typography>
              <Skeleton
                variant="text"
                width={index === 4 ? 120 : 50}
                height={30}
              />
            </Box>
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          maxHeight: 500,
          overflowY: 'auto',
        }}
      >
        <Grid container spacing={6}>
          {Array.from({ length: 10 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={3} lg={2.4} key={index}>
              <Box
                sx={{
                  aspectRatio: '9/16',
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              >
                <Skeleton variant="rectangular" width="100%" height="100%" />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </HighlightableSection>
  );
};
