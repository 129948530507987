import { gql } from '@apollo/client';
import { useBookmarkHandlers } from 'features/bookmark';
import {
  BookmarkFragmentForBookmarkHandlersFragmentDoc,
  BookmarkType,
  useCreateBookmarkForSocialListeningBookmarkHandlersMutation,
  useDeleteBookmarkForSocialListeningBookmarkHandlersMutation,
} from 'graphql/generated';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation CreateBookmarkForSocialListeningBookmarkHandlers(
    $input: CreateBrandBookmarkInput!
  ) {
    createBrandBookmark(input: $input) {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
  ${BookmarkFragmentForBookmarkHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation DeleteBookmarkForSocialListeningBookmarkHandlers(
    $input: DeleteBrandBookmarkInput!
  ) {
    deleteBrandBookmark(input: $input) {
      message
      success
      deleted {
        id
      }
    }
  }
`;

export const useSocialListeningBookmarkHandlers = () => {
  const [createBookmark] =
    useCreateBookmarkForSocialListeningBookmarkHandlersMutation();
  const [deleteBookmark] =
    useDeleteBookmarkForSocialListeningBookmarkHandlersMutation();

  const { addBookmarkItemToSidebar, removeBookmarkItemFromSidebar } =
    useBookmarkHandlers();

  const onBookmarkSocialEngagement = (brandId: string) => {
    createBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.SocialListeningEngagement,
        },
      },
      update: (cache, { data }) => {
        if (data?.createBrandBookmark.id) {
          addBookmarkItemToSidebar(data.createBrandBookmark);
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                socialListeningEngagement: true,
              };
            },
          });
        }
      },
    });
  };

  const onRemoveSocialEngagementBookmark = (brandId: string) => {
    deleteBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.SocialListeningEngagement,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteBrandBookmark.success) {
          removeBookmarkItemFromSidebar(
            data.deleteBrandBookmark.deleted?.[0]?.id ?? '',
          );
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                socialListeningEngagement: false,
              };
            },
          });
        }
      },
    });
  };

  const onBookmarkOutbound = (brandId: string) => {
    createBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.Outbound,
        },
      },
      update: (cache, { data }) => {
        if (data?.createBrandBookmark.id) {
          addBookmarkItemToSidebar(data.createBrandBookmark);
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                outbound: true,
              };
            },
          });
        }
      },
    });
  };

  const onRemoveOutboundBookmark = (brandId: string) => {
    deleteBookmark({
      variables: {
        input: {
          brandId,
          type: BookmarkType.Outbound,
        },
      },
      update: (cache, { data }) => {
        if (data?.deleteBrandBookmark.success) {
          removeBookmarkItemFromSidebar(
            data.deleteBrandBookmark.deleted?.[0]?.id ?? '',
          );
          modifyObject(cache, brandId, 'BrandModel', {
            bookmarkState: (existing) => {
              return {
                ...existing,
                outbound: false,
              };
            },
          });
        }
      },
    });
  };

  return {
    onBookmarkSocialEngagement,
    onRemoveSocialEngagementBookmark,
    onBookmarkOutbound,
    onRemoveOutboundBookmark,
  };
};
