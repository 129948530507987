import { gql } from '@apollo/client';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import {
  AvatarWithName,
  AvatarWithNameProps,
} from 'components/common/AvatarGroup/AvatarWithName';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { useResponderChange } from 'features/socialPostComment/hooks/useResponderChange';
import { SocialPostCommentFragmentSocialPostCommentResponderPillFragment } from 'graphql/generated';
import { useRef } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment SocialPostCommentFragmentSocialPostCommentResponderPill on SocialPostCommentModel {
    id
    socialPost {
      brandSocialPostEngagement(brandId: $brandId) {
        id
        respondedByUser {
          ...UserFragmentAvatarGroup
        }
      }
    }
    ...SocialPostCommentFragmentUseResponderChange
  }
`;

export type SocialPostCommentResponderPillProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentResponderPillFragment;
  brandId?: string;
  onResponderChanged?: (responderId: string | undefined) => void;
  componentsProps?: {
    avatarWithName?: AvatarWithNameProps;
    sx?: BoxProps['sx'];
  };
  sx?: BoxProps['sx'];
};

export const SocialPostCommentResponderPill = (
  props: SocialPostCommentResponderPillProps,
) => {
  const {
    socialPostComment,
    componentsProps,
    sx,
    onResponderChanged,
    brandId,
  } = props;
  const anchorElRef = useRef<HTMLButtonElement>(null);

  const {
    selectedResponder,
    canUpdateResponder,
    canAddResponder,
    renderPopoverUI,
    openPopover,
  } = useResponderChange({
    socialPostComment,
    onResponderChanged,
    anchorElRef,
    brandId,
  });

  const hasResponder = Boolean(selectedResponder);

  return (
    <>
      <Box sx={{ ...sx }}>
        <Button
          ref={anchorElRef}
          variant="tertiary"
          disabled={!(canUpdateResponder || canAddResponder)} // enable button if can update OR add responder
          onClick={openPopover}
          sx={{
            p: 0,
            minWidth: 'auto',
            '&:hover': {
              bgcolor: 'transparent',
            },
            borderRadius: theme.spacing(5),
          }}
        >
          {hasResponder && selectedResponder ? (
            <AvatarWithName
              user={selectedResponder}
              shouldHideEmail
              avatarSize={20}
              {...componentsProps?.avatarWithName}
              componentsProps={{
                sx: {
                  bgcolor: '#2306030D',
                  borderRadius: theme.spacing(5),
                  padding: theme.spacing(0.5, 1, 0.5, 0.5),
                  gap: theme.spacing(1),
                  ...componentsProps?.avatarWithName?.componentsProps?.sx,
                },
                name: {
                  sx: {
                    ...theme.typography['subhead-sm'],
                    ...componentsProps?.avatarWithName?.componentsProps?.name,
                  },
                },
              }}
            />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
                bgcolor: 'rgba(35, 6, 3, 0.05)',
                borderRadius: theme.spacing(5),
                padding: theme.spacing(0.5, 1.5, 0.5, 0.5),
              }}
            >
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                  bgcolor: theme.colors?.utility['purple-4'],
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: `1px solid ${theme.colors?.primary[500]}`,
                }}
              >
                <IconLinearAdd size={12} color={theme.colors?.primary.white} />
              </Box>
              <Typography
                variant="subhead-sm"
                color={theme.colors?.utility[800]}
              >
                Add
              </Typography>
            </Box>
          )}
        </Button>
      </Box>
      {renderPopoverUI()}
    </>
  );
};
