import {
  CUSTOM_COLLECTION,
  CollectionCardView,
  CollectionCardViewProps,
} from 'features/collection';
import { GeneralPermission, PostFilterType } from 'graphql/generated';
import { usePreviewablePostQuery } from './usePreviewablePostQuery';

export const SavedCollectionCardView = (
  props: Omit<CollectionCardViewProps, 'collection'>,
) => {
  const { data, previewablePosts } = usePreviewablePostQuery({
    filters: {
      filterType: PostFilterType.MyFavoritePosts,
    },
  });
  const posts = previewablePosts;
  const totalPosts = data?.posts.meta.totalCount || 0;

  return (
    <CollectionCardView
      {...props}
      collection={{
        id: CUSTOM_COLLECTION.SAVED,
        __typename: 'CollectionModel',
        name: 'Saved',
        totalPosts,
        posts,
        totalChildCollections: 0,
        creatorId: '',
        generalPermission: GeneralPermission.OrganizationMembers,
        inviteMembers: [],
        hasPreviewPost: false,
        organizationName: '',
        myPermissions: [],
        isDisabled: false,
        isPinnedToRoot: false,
        isPinnedToParent: false,
        breadcrumbsFromRoot: [],
        isBookmarked: false,
      }}
      componentsProps={{
        disableContextMenu: true,
        ...props.componentsProps,
      }}
    />
  );
};
