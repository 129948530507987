import { Box, IconButton, Typography } from '@mui/material';
import { IconLinearClose } from 'components/icons/components/linear/IconLinearClose';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconLinearGrid4 } from 'components/icons/components/linear/IconLinearGrid4';
import { theme } from 'styles/theme';

interface props {
  isBookmarked: boolean;
  onNavigate?: () => void;
  onToggleBookmark: () => void;
}

export const bookmarkOptions = ({
  isBookmarked,
  onNavigate,
  onToggleBookmark,
}: props) => {
  const renderOption = ({
    Icon,
    text,
    color = theme.colors?.primary.black,
    iconBgColor = 'rgba(35, 6, 3, 0.05)',
    iconSize = 16,
    onClick,
  }: {
    Icon: any;
    text: string;
    color?: string;
    iconSize?: number;
    iconBgColor?: string;
    onClick: () => void;
  }) => {
    return (
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 2,
          borderRadius: 2,
          width: '100%',
          justifyContent: 'flex-start',
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: 1,
            bgcolor: iconBgColor,
          }}
        >
          <Icon size={iconSize} color={color} />
        </Box>
        <Typography variant="subhead-lg" color={color}>
          {text}
        </Typography>
      </IconButton>
    );
  };

  return [
    ...(onNavigate
      ? [
          {
            renderCustomComponent: () => {
              return renderOption({
                Icon: IconLinearExport2,
                text: 'Open in new tab',
                onClick: onNavigate,
              });
            },
          },
        ]
      : []),
    {
      renderCustomComponent: () => {
        const Icon = isBookmarked ? IconLinearClose : IconLinearGrid4;
        return renderOption({
          Icon,
          text: isBookmarked ? 'Remove from Bookmarks' : 'Add to Bookmarks',
          onClick: onToggleBookmark,
          ...(isBookmarked && {
            color: theme.colors?.utility['pink-3'],
            iconSize: 9,
            iconBgColor: 'rgba(128, 5, 50, 0.08)',
          }),
        });
      },
    },
  ];
};
