import { CreatorProfileRelationshipStrength } from 'graphql/generated';
import { theme } from 'styles/theme';

export const RelationshipBackgroundColorMap = {
  [CreatorProfileRelationshipStrength.VeryStrong]: 'rgba(224, 228, 202, 0.50)',
  [CreatorProfileRelationshipStrength.Strong]: 'rgba(224, 228, 202, 0.50)',
  [CreatorProfileRelationshipStrength.Neutral]: 'rgba(217, 227, 243, 0.50)',
  [CreatorProfileRelationshipStrength.Weak]: 'rgba(246, 225, 230, 0.50)',
  [CreatorProfileRelationshipStrength.VeryWeak]: 'rgba(246, 225, 230, 0.50)',
};

export const RelationshipColorMap = {
  [CreatorProfileRelationshipStrength.VeryStrong]:
    theme.colors?.utility['green-4'],
  [CreatorProfileRelationshipStrength.Strong]: theme.colors?.utility['green-4'],
  [CreatorProfileRelationshipStrength.Neutral]: theme.colors?.utility['blue-4'],
  [CreatorProfileRelationshipStrength.Weak]: theme.colors?.utility['pink-4'],
  [CreatorProfileRelationshipStrength.VeryWeak]:
    theme.colors?.utility['pink-4'],
};

export const RelationshipLabelMap = {
  [CreatorProfileRelationshipStrength.VeryStrong]: 'Very strong',
  [CreatorProfileRelationshipStrength.Strong]: 'Strong',
  [CreatorProfileRelationshipStrength.Neutral]: 'Neutral',
  [CreatorProfileRelationshipStrength.Weak]: 'Weak',
  [CreatorProfileRelationshipStrength.VeryWeak]: 'Very weak',
};
