import { Button } from '@mui/material';
import { theme } from 'styles/theme';

type Props = {
  updating?: boolean;
  tracking: boolean;
  onChange: (track: boolean) => void;
};

export const TrackUntrackCreatorButton = ({
  tracking,
  onChange,
  updating,
}: Props) => {
  return (
    // eslint-disable-next-line
    <>
      {tracking ? (
        <Button
          disabled={updating}
          disableRipple
          variant="primary"
          onClick={() => {
            onChange(false);
          }}
          sx={{
            backgroundColor: theme.colors?.primary.parchment,
            color: theme.colors?.primary.black,
            border: 'none',
            borderRadius: theme.spacing(3),
            '&:hover': {
              backgroundColor: theme.colors?.primary.parchment,
              color: theme.colors?.primary.black,
              border: 'none',
            },
          }}
        >
          {updating ? 'Updating...' : 'Remove from list'}
        </Button>
      ) : (
        <Button
          disabled={updating}
          disableRipple
          variant="primary"
          onClick={() => {
            onChange(true);
          }}
          sx={{
            backgroundColor: theme.colors?.primary.black,
            color: theme.colors?.primary.white,
            border: 'none',
            borderRadius: theme.spacing(3),
            '&:hover': {
              backgroundColor: theme.colors?.primary.black,
              color: theme.colors?.primary.white,
              border: 'none',
            },
          }}
        >
          {updating ? 'Updating...' : 'Track'}
        </Button>
      )}
    </>
  );
};
