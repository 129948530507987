import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Typography } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import moment from 'moment';
import { theme } from 'styles/theme';
import {
  NestedFiltersMenuItemBaseType,
  NestedFiltersMenuItemBaseValueType,
} from '../menuItem';

export type NestedFiltersDateRangeOptionType = {
  label: string;
  startDate: Date;
  endDate: Date;
};

export type NestedFiltersDateRangeType = NestedFiltersMenuItemBaseType & {
  type: 'date-range';
  label?: string;
  options: NestedFiltersDateRangeOptionType[];
  customRange?: boolean;
};

export type NestedFiltersDateRangeProps = {
  dateRange: NestedFiltersDateRangeType;
  value: NestedFiltersMenuItemBaseValueType<NestedFiltersDateRangeOptionType>;
  onChange: (
    value: NestedFiltersMenuItemBaseValueType<NestedFiltersDateRangeOptionType>,
  ) => void;
};

export const NestedFiltersDateRange = (props: NestedFiltersDateRangeProps) => {
  const { dateRange, value: _value, onChange } = props;
  const { customRange = true } = dateRange;

  const value = _value.value;

  const {
    isOpen: isDateRangePickerOpen,
    onOpen: openDateRangePicker,
    onClose: closeDateRangePicker,
  } = useDisclosure();
  const isCustomRangeSelected = value?.label === CUSTOM_RANGE_LABEL;

  return (
    <Box>
      {dateRange.label && (
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          {dateRange.label}
        </Typography>
      )}
      {dateRange.options.map((option, index) => {
        return (
          <RadioMenuItem
            key={index}
            label={option.label}
            value={option.label}
            checked={value?.label === option.label}
            onClick={() =>
              onChange({
                value: option,
              })
            }
          />
        );
      })}
      {customRange && (
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <RadioMenuItem
              label={
                isCustomRangeSelected
                  ? `${moment(value?.startDate).format('DD MMM')} - ${moment(
                      value?.endDate,
                    ).format('DD MMM')}`
                  : CUSTOM_RANGE_LABEL
              }
              value={CUSTOM_RANGE_LABEL}
              checked={isCustomRangeSelected}
              onClick={openDateRangePicker}
            />
            <IconLinearArrowRight
              size={16}
              style={{
                position: 'absolute',
                top: 'calc(50% - 8px)',
                right: theme.spacing(2),
              }}
            />
          </Box>
          <DateRangePicker
            value={
              value?.label === CUSTOM_RANGE_LABEL
                ? [
                    value?.startDate ? moment(value.startDate) : null,
                    value?.endDate ? moment(value.endDate) : null,
                  ]
                : [null, null]
            }
            calendars={1}
            open={isDateRangePickerOpen}
            onClose={closeDateRangePicker}
            onChange={(dates) => {
              if (dates.length === 2 && dates[0] && dates[1]) {
                onChange({
                  value: {
                    label: CUSTOM_RANGE_LABEL,
                    startDate: dates[0].toDate(),
                    endDate: dates[1].toDate(),
                  },
                });
              }
            }}
            sx={{ '& .MuiTypography-root': { display: 'none' } }}
            slots={{
              // eslint-disable-next-line
              textField: () => null,
            }}
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};
