import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileStatsViewSkeleton = () => {
  return (
    <Box
      sx={{
        borderRadius: 6,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 4,
              borderRadius: 3,
              border: `1px solid ${theme.colors?.utility[300]}`,
              background: 'rgba(205, 226, 223, 0.50)',
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              <Skeleton variant="text" width="60%" height={20} />
            </Typography>
            <Skeleton variant="text" width="40%" height={32} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 4,
              borderRadius: 3,
              border: `1px solid ${theme.colors?.utility[300]}`,
              background: 'rgba(230, 221, 237, 0.50)',
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              <Skeleton variant="text" width="60%" height={20} />
            </Typography>
            <Skeleton variant="text" width="40%" height={32} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              p: 4,
              borderRadius: 3,
              border: `1px solid ${theme.colors?.utility[300]}`,
              background: 'rgba(230, 221, 237, 0.50)',
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              <Skeleton variant="text" width="60%" height={20} />
            </Typography>
            <Skeleton variant="text" width="40%" height={32} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
