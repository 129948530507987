import { IconBoldArrowDown2 } from 'components/icons/components/bold/IconBoldArrowDown2';
import { IconBoldArrowUp } from 'components/icons/components/bold/IconBoldArrowUp';
import { IconBoldMessage } from 'components/icons/components/bold/IconBoldMessage';
import { IconBoldTag } from 'components/icons/components/bold/IconBoldTag';
import { RadarEventActivityDirection, RadarEventType } from 'graphql/generated';

export const getMessageByEventType = (eventType: RadarEventType) => {
  switch (eventType) {
    case RadarEventType.Ugc: {
      return 'mentioned you in a post';
    }
    case RadarEventType.Engagement: {
      return 'commented on your post';
    }
    case RadarEventType.Comment: {
      return 'mentioned you in a comment';
    }
    case RadarEventType.Internal: {
      return 'logged an activity';
    }
    default: {
      return '';
    }
  }
};

export const getIconByEventType = (
  eventType: RadarEventType,
  direction?: RadarEventActivityDirection,
) => {
  switch (eventType) {
    case RadarEventType.Ugc: {
      return IconBoldTag;
    }
    case RadarEventType.Engagement: {
      return IconBoldMessage;
    }
    case RadarEventType.Comment: {
      return IconBoldMessage;
    }
    default: {
      switch (direction) {
        case RadarEventActivityDirection.Outbound: {
          return IconBoldArrowUp;
        }
        default: {
          return IconBoldArrowDown2;
        }
      }
    }
  }
};
