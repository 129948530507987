import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ImageWithVideoFallback } from 'components/common/Image';
import { IconBoldClock } from 'components/icons/components/bold/IconBoldClock';
import { useUserContext } from 'contexts/users/User.context';
import { SocialListeningCreatorProfilePeekingPill } from 'features/socialListeningCreatorProfile/components';
import {
  PaginatedRadarEventInput,
  RadarEventType,
  RadarEventViewType,
  useGetRadarEventsForSlCreatorMomentsViewQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SocialListeningCreatorProfileMomentsViewSkeleton } from './SocialListeningCreatorProfileMomentsViewSkeleton';

// eslint-disable-next-line
gql`
  query GetRadarEventsForSLCreatorMomentsView(
    $input: PaginatedRadarEventInput!
  ) {
    getPaginatedRadarEvents(input: $input) {
      data {
        id
        eventType
        eventTypeCounter
        message
        creatorProfileId
        platformCreateTime
        socialPost {
          id
          thumbnailUrl
          videoUrl
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

type SocialListeningCreatorProfileMomentsViewProps = {
  filters: Pick<
    PaginatedRadarEventInput,
    'brandId' | 'creatorProfileIds' | 'platforms' | 'dateRange'
  >;
};

export const SocialListeningCreatorProfileMomentsView = ({
  filters,
}: SocialListeningCreatorProfileMomentsViewProps) => {
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const radarEventsVariables: PaginatedRadarEventInput = {
    ...filters,
    eventTypes: [RadarEventType.Moment],
    viewType: RadarEventViewType.AllEvents,
    take: 5,
  };
  const { data, fetchMore, loading } =
    useGetRadarEventsForSlCreatorMomentsViewQuery({
      variables: {
        input: radarEventsVariables,
      },
    });
  const moments = data?.getPaginatedRadarEvents.data || [];

  if (loading) {
    return <SocialListeningCreatorProfileMomentsViewSkeleton />;
  }

  return (
    <Box
      sx={{
        px: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        maxHeight: 400,
        overflowY: 'auto',
      }}
    >
      <InfiniteScroll
        loadMore={() =>
          fetchMore({
            variables: {
              input: {
                ...radarEventsVariables,
                skip: moments.length,
                take: 10,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              return {
                ...prev,
                getPaginatedRadarEvents: {
                  ...prev.getPaginatedRadarEvents,
                  ...fetchMoreResult.getPaginatedRadarEvents,
                  data: [
                    ...prev.getPaginatedRadarEvents.data,
                    ...fetchMoreResult.getPaginatedRadarEvents.data,
                  ],
                },
              };
            },
          })
        }
        initialLoad={false}
        useWindow={false}
        threshold={250}
        hasMore={data?.getPaginatedRadarEvents.pageInfo.hasNextPage || false}
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
        }}
        loader={
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mb: 6,
              mt: -6,
            }}
          >
            <Typography variant="subhead-lg" color={theme.colors?.utility[900]}>
              Loading...
            </Typography>
          </Box>
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'sticky',
            top: 0,
            backgroundColor: theme.colors?.primary.white,
            py: 6,
            zIndex: 1,
          }}
        >
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            Moments
          </Typography>
          <SocialListeningCreatorProfilePeekingPill />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {!moments.length ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={-4}
              mb={6}
            >
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[600]}
              >
                {loading
                  ? 'Loading...'
                  : 'Still learning about their journey! No life updates yet 🌱'}
              </Typography>
            </Box>
          ) : (
            moments.map((m, index) => {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'flex-start',
                    p: theme.spacing(6, 4, 0, 4),
                    ...(index === 0 ? { pt: 0, mt: -4 } : {}),
                    mb: index === moments.length - 1 ? 6 : 0,
                  }}
                >
                  {/* Clock icon */}
                  {moments.length > 1 && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 0.5,
                        height: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <IconBoldClock
                        size={15}
                        style={{
                          color: theme.colors?.utility[500],
                          flexShrink: 0,
                        }}
                      />
                      {index !== moments.length - 1 && (
                        <Box
                          sx={{
                            width: 2,
                            height: '100%',
                            backgroundColor: theme.colors?.utility[300],
                            mb: -6,
                          }}
                        />
                      )}
                    </Box>
                  )}
                  <Box
                    component={Link}
                    to={PlotRoutes.socialListeningPost(
                      m.socialPost?.id || '',
                      filters.brandId,
                    )}
                    onClick={(e) => {
                      analytics.track(
                        EventName.CreatorProfileAboutPostClicked,
                        {
                          brandId: filters.brandId,
                          userId: user?.id,
                          section: 'moments',
                          postId: m.socialPost?.id,
                        },
                      );
                    }}
                  >
                    <ImageWithVideoFallback
                      imageUrl={m.socialPost?.thumbnailUrl || ''}
                      videoUrl={m.socialPost?.videoUrl || ''}
                      sx={{
                        width: 48,
                        height: 68,
                        objectFit: 'cover',
                        borderRadius: 2,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      maxWidth: 472,
                      ml: 2,
                    }}
                  >
                    <Typography
                      variant="subhead-lg"
                      color={theme.colors?.utility[800]}
                    >
                      {moment(m.platformCreateTime).format('MMMM DD, YYYY')}
                    </Typography>
                    <Typography
                      variant="headline-sm"
                      color={theme.colors?.primary.black}
                    >
                      {m.message}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          )}
        </Box>
      </InfiniteScroll>
    </Box>
  );
};
