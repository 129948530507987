import { Box, Divider } from '@mui/material';
import { theme } from 'styles/theme';
import { NestedFiltersMenuItemBaseType } from '../menuItem';

export type NestedFiltersDividerType = NestedFiltersMenuItemBaseType & {
  type: 'divider';
};

export type NestedFiltersDividerProps = {
  divider: NestedFiltersDividerType;
};

export const NestedFiltersDivider = (props: NestedFiltersDividerProps) => {
  return (
    <Box
      sx={{
        px: 3,
      }}
    >
      <Divider
        sx={{
          color: theme.colors?.utility[400],
          my: 3,
        }}
      />
    </Box>
  );
};
