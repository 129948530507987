import { Box } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { Image } from 'components/common/Image';
import { useLeftDrawerContext } from 'components/layouts/LeftDrawer';
import { TabName } from 'components/layouts/LeftDrawer/types';
import { BookmarkSidebarItem } from 'features/bookmark/components';
import { useGetBookmarksForBookmarkHandlersQuery } from 'graphql/generated';
import { theme } from 'styles/theme';

export const BookmarkSidebarView = () => {
  const { data } = useGetBookmarksForBookmarkHandlersQuery();
  const bookmarks = data?.bookmarks || [];

  const {
    onAddDrawerOpenedState,
    onRemoveDrawerOpenedState,
    drawerOpenedState,
  } = useLeftDrawerContext();

  return (
    <CollapsibleSection
      headerTitle={TabName.BOOKMARKS}
      headerTitleClass="bookmark-sidebar-header"
      headerFullWidth
      sx={{
        p: 2,
        borderRadius: 2,
        ':hover': {
          background: 'rgba(35, 6, 3, 0.05)',
        },
        '.bookmark-sidebar-header': {
          color: theme.colors?.utility[800],
          ...theme.typography['headline-xs'],
        },
      }}
      divider={false}
      iconColor={theme.colors?.utility[600]}
      defaultIsOpen={drawerOpenedState.includes(TabName.BOOKMARKS)}
      onStateUpdated={(isOpen) => {
        if (isOpen) {
          onAddDrawerOpenedState(TabName.BOOKMARKS);
        } else {
          onRemoveDrawerOpenedState(TabName.BOOKMARKS);
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {!bookmarks.length ? (
          <Box
            sx={{
              maxWidth: 208,
              maxHeight: 163,
            }}
          >
            <Image
              src="/images/bookmark-intro.svg"
              sx={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
              draggable={false}
            />
          </Box>
        ) : (
          bookmarks.map((bookmark) => (
            <BookmarkSidebarItem bookmark={bookmark} />
          ))
        )}
      </Box>
    </CollapsibleSection>
  );
};
