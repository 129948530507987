// this is the old component, will used when socialListeningCrmEnabled is false

import { gql } from '@apollo/client';
import { Box, Button, Popover, Typography } from '@mui/material';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearAddCircle } from 'components/icons/components/linear/IconLinearAddCircle';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useCreatorsTopicTrackingForSocialListening } from 'features/socialMediaListening/hooks/useCreatorsTopicTrackingForSocialListening';
import {
  CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment,
  PlotFeature,
  useGetTopicsTrackingListForCreatorTrackingListQuery,
} from 'graphql/generated';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { CreatorPeeking } from '../creatorPeeking';

// eslint-disable-next-line
gql`
  query GetTopicsTrackingListForCreatorTrackingList(
    $data: CreatorTopicTrackingInput!
  ) {
    getTopicsTrackingList(data: $data) {
      isTracking
      topic {
        id
        name
      }
    }
  }
`;

type Props = {
  brandId: string;
  creator: CreatorProfileFragmentSocialMediaListeningCreatorProfileFragment;
};

export const CreatorTrackingList = ({ brandId, creator }: Props) => {
  const [showPopover, setShowPopover] = useState(false);
  const anchorElRef = useRef<HTMLButtonElement | null>(null);

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const isSocialListeningCrmEnabled = isFeatureEnabled(
    PlotFeature.SocialListeningCrm,
  );

  const {
    actions: { handleTrackCreator, handleUntrackCreator },
  } = useCreatorsTopicTrackingForSocialListening({
    platform: creator.platform,
    brandId,
  });
  const {
    data: creatorTrackingData,
    loading,
    refetch,
  } = useGetTopicsTrackingListForCreatorTrackingListQuery({
    variables: {
      data: {
        creatorIds: [creator.id],
        brandId,
      },
    },
    skip: !creatorTrackingEnabled || isSocialListeningCrmEnabled,
  });
  const trackingData = creatorTrackingData?.getTopicsTrackingList || [];
  const isTracking = trackingData.some((data) => data.isTracking);

  if (!creatorTrackingEnabled) {
    return null;
  }

  if (isSocialListeningCrmEnabled) {
    return <CreatorPeeking brandId={brandId} creator={creator} />;
  }

  return (
    <Box>
      <Button
        ref={anchorElRef}
        variant="primary"
        disabled={loading}
        onClick={() => {
          setShowPopover(true);
        }}
        sx={{
          backgroundColor: isTracking
            ? theme.colors?.utility[400]
            : theme.colors?.primary.black,
          color: isTracking
            ? theme.colors?.primary.black
            : theme.colors?.primary.white,
          border: 'none',
          borderRadius: theme.spacing(2),
          '&:hover': {
            backgroundColor: isTracking
              ? theme.colors?.utility[400]
              : theme.colors?.primary.black,
            color: isTracking
              ? theme.colors?.primary.black
              : theme.colors?.primary.white,
          },
        }}
      >
        {loading
          ? 'Loading...'
          : !isTracking
          ? 'Add to list'
          : 'Tracking Creator'}
      </Button>
      <Popover
        anchorOrigin={{
          vertical: -16,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showPopover}
        anchorEl={anchorElRef.current}
        onClose={() => {
          setShowPopover(false);
        }}
        sx={{
          zIndex: 999999,
        }}
        PaperProps={{
          sx: {
            maxHeight: 400,
            padding: theme.spacing(4),
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(24px)',
            boxShadow:
              '0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12)',
            border: 'none',
          },
        }}
      >
        {trackingData.map((data) => (
          <Box
            sx={{
              display: 'flex',
              gap: theme.spacing(2),
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: theme.spacing(2, 4),
              cursor: 'pointer',
              minWidth: 200,
              '&:hover': {
                backgroundColor: theme.colors?.utility[275],
                borderRadius: theme.spacing(2),
              },
            }}
            onClick={async () => {
              if (data.isTracking) {
                await handleUntrackCreator(
                  creator.platformId,
                  data.topic.id,
                  creator.handle,
                );
              } else {
                await handleTrackCreator(
                  creator.platformId,
                  data.topic.id,
                  creator.handle,
                );
              }
              refetch();
            }}
          >
            <Typography variant="body-lg" fontWeight={500}>
              {data.topic.name}
            </Typography>

            {data.isTracking ? (
              <IconBoldTickCircle size={16} />
            ) : (
              <IconLinearAddCircle size={16} />
            )}
          </Box>
        ))}

        {!trackingData.length && (
          <Box display="flex" flexDirection="column" gap={2} width={250}>
            <Typography variant="body-xl" fontWeight={600}>
              Creator Tracking
            </Typography>
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[800]}
              fontWeight={500}
            >
              Add your first Creator Tracking Topic to track specific creators
              discussing your key topics.
            </Typography>
          </Box>
        )}

        {!!trackingData.length && (
          <Box
            sx={{
              my: 4,
              width: '100%',
              borderBottom: `1px solid ${theme.colors?.utility[400]}`,
            }}
          />
        )}

        <Link to={PlotRoutes.socialListeningOnboarding('creator', brandId)}>
          <Typography
            variant="body-lg"
            fontWeight={500}
            sx={{
              textAlign: 'center',
              display: 'block',
              width: '100%',
              mt: theme.spacing(4),
              p: theme.spacing(2, 4),
              backgroundColor: theme.colors?.utility[275],
              borderRadius: theme.spacing(4),
              cursor: 'pointer',
            }}
            onClick={() => {}}
          >
            + New Creator Tracking topic
          </Typography>
        </Link>
      </Popover>
    </Box>
  );
};
