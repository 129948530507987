import { gql } from '@apollo/client';
import {
  CreatorFragmentUseSocialListeningCreatorLinkGeneratorFragment,
  Platform,
} from 'graphql/generated';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment CreatorFragmentUseSocialListeningCreatorLinkGenerator on CreatorModel {
    id
    handle
    platform
    platformId
  }
`;

export const useSocialListeningCreatorLinkGenerator = () => {
  const generateLink = (
    creator: CreatorFragmentUseSocialListeningCreatorLinkGeneratorFragment,
  ) => {
    if (creator.platform === Platform.Tiktok) {
      return `https://www.tiktok.com/@${creator.handle}`;
    }

    if (creator.platform === Platform.Instagram) {
      return `https://instagram.com/${creator.handle}`;
    }

    if (creator.platform === Platform.Youtube) {
      return `https://www.youtube.com/channel/${creator.platformId}`;
    }

    return '';
  };

  return { generateLink };
};
