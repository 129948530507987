import { gql, LazyQueryExecFunction } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { RadioMenuItem } from 'components/common/form/Select';
import { IconBoldCamera } from 'components/icons/components/bold/IconBoldCamera';
import { IconCustomSmiley } from 'components/icons/components/custom/IconCustomSmiley';
import { IconCustomTodo } from 'components/icons/components/custom/IconCustomTodo';
import { IconLinearFilter } from 'components/icons/components/linear/IconLinearFilter';
import { IconLinearMessage } from 'components/icons/components/linear/IconLinearMessage';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { IconLinearTag2 } from 'components/icons/components/linear/IconLinearTag2';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import {
  RelationshipLabelMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity';
import {
  CreatorProfileFilterType,
  CreatorProfileRelationshipStrength,
  Exact,
  GetPaginatedOrganizationCreatorProfileLabelsForSlCommunityPageFiltersQuery,
  PaginatedCreatorProfileInput,
  PaginatedOrganizationCreatorProfileLabelInput,
  Sentiment,
  useGetPaginatedOrganizationCreatorProfileLabelsForSlCommunityPageFiltersLazyQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import * as yup from 'yup';
import { SaveViewsMenu } from './SaveViewsMenu';

export const dateRangeOptions = [
  {
    label: 'All time',
    startDate: moment().subtract(100, 'years').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 7 days',
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 30 days',
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate(),
  },
  {
    label: 'Last 3 months',
    startDate: moment().subtract(3, 'months').toDate(),
    endDate: moment().toDate(),
  },
];

// eslint-disable-next-line
gql`
  query GetPaginatedOrganizationCreatorProfileLabelsForSLCommunityPageFilters(
    $input: PaginatedOrganizationCreatorProfileLabelInput!
  ) {
    paginatedOrganizationCreatorProfileLabels(input: $input) {
      data {
        id
        label
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export type NestedFiltersValues = {
  dateRange: NestedFiltersMenuItemBaseValueType<{
    label: string;
    startDate: Date;
    endDate: Date;
  }>;
  filterType?: NestedFiltersMenuItemBaseValueType<CreatorProfileFilterType>;
  filterBy?: {
    relationshipsGroup?: {
      relationships: NestedFiltersMenuItemBaseValueType<
        NestedFiltersOptionType[]
      >;
    };
    sentimentsGroup?: {
      sentiments: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
    };
    labelsGroup?: {
      labels: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
    };
    followersGroup?: {
      followers: NestedFiltersMenuItemBaseValueType<{
        min: number;
        max: number;
      }>;
    };
    mentionsGroup?: {
      mentions: NestedFiltersMenuItemBaseValueType<{
        min: number;
        max: number;
      }>;
    };
    commentsGroup?: {
      comments: NestedFiltersMenuItemBaseValueType<{
        min: number;
        max: number;
      }>;
    };
  };
};

export type FiltersProps = {
  brandId: string;
  configForSaving?: PaginatedCreatorProfileInput;
  setFilters: (
    filters: Pick<
      PaginatedCreatorProfileInput,
      | 'dateRange'
      | 'brandMentionsCount'
      | 'commentsCount'
      | 'followersCount'
      | 'relationshipStrength'
      | 'labelIds'
      | 'sentiment'
      | 'filterType'
    >,
  ) => void;
  setFiltersFromSavedConfig: (config: PaginatedCreatorProfileInput) => void;
  setRawFilters: (filters: NestedFiltersValues) => void;
};

const SOCIAL_LISTENING_COMMUNITY_FILTERS = 'social-listening-community-filters';

const adjustDateRange = (startDate?: string, endDate?: string) => {
  const now = moment();
  if (!endDate) return { startDate, endDate: now.toISOString() };

  const endMoment = moment(endDate);
  const oneDayAgo = moment().subtract(1, 'days');

  if (endMoment.isBefore(oneDayAgo)) {
    const daysDiff = now.diff(endMoment, 'days');
    return {
      startDate: moment(startDate).add(daysDiff, 'days').toISOString(),
      endDate: now.toISOString(),
    };
  }

  return { startDate, endDate };
};

const transformToFilterValues = (
  config: PaginatedCreatorProfileInput,
  labelOptions: Array<{ label: string; value: string }>,
): NestedFiltersValues => {
  const { startDate, endDate } = adjustDateRange(
    config.dateRange?.startDate,
    config.dateRange?.endDate,
  );

  return {
    dateRange: {
      value: {
        startDate: startDate ? new Date(startDate) : new Date(),
        endDate: endDate ? new Date(endDate) : new Date(),
        label: 'Custom Range',
      },
    },
    filterType: config.filterType ? { value: config.filterType } : undefined,
    filterBy: {
      relationshipsGroup: {
        relationships: {
          value:
            config.relationshipStrength?.map((value) => ({
              label: RelationshipLabelMap[value],
              value,
            })) || [],
        },
      },
      sentimentsGroup: {
        sentiments: {
          value:
            config.sentiment?.map((value) => ({
              label: SentimentLabelMap[value],
              value,
            })) || [],
        },
      },
      labelsGroup: {
        labels: {
          value: labelOptions,
        },
      },
      ...(config.followersCount?.min || config.followersCount?.max
        ? {
            followersGroup: {
              followers: {
                value: {
                  min: config.followersCount?.min ?? 0,
                  max: config.followersCount?.max ?? 0,
                },
              },
            },
          }
        : {}),
      ...(config.brandMentionsCount?.min || config.brandMentionsCount?.max
        ? {
            mentionsGroup: {
              mentions: {
                value: {
                  min: config.brandMentionsCount?.min ?? 0,
                  max: config.brandMentionsCount?.max ?? 0,
                },
              },
            },
          }
        : {}),
      ...(config.commentsCount?.min || config.commentsCount?.max
        ? {
            commentsGroup: {
              comments: {
                value: {
                  min: config.commentsCount?.min ?? 0,
                  max: config.commentsCount?.max ?? 0,
                },
              },
            },
          }
        : {}),
    },
  };
};

const fetchLabels = async (
  getLabels: LazyQueryExecFunction<
    GetPaginatedOrganizationCreatorProfileLabelsForSlCommunityPageFiltersQuery,
    Exact<{
      input: PaginatedOrganizationCreatorProfileLabelInput;
    }>
  >,
  brandId: string,
  labelIds?: string[],
): Promise<Array<{ label: string; value: string }>> => {
  if (!labelIds?.length) return [];

  const response = await getLabels({
    variables: {
      input: {
        take: 1000,
        brandId,
      },
    },
    fetchPolicy: 'network-only',
  });

  const allLabels =
    response.data?.paginatedOrganizationCreatorProfileLabels.data || [];

  return labelIds.map((labelId) => {
    const label = allLabels.find((l) => l.id === labelId);
    return {
      label: label?.label || '',
      value: labelId,
    };
  });
};

export const Filters = (props: FiltersProps) => {
  const {
    brandId,
    setFilters: _setFilters,
    setRawFilters,
    configForSaving,
    setFiltersFromSavedConfig,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const [getLabels] =
    useGetPaginatedOrganizationCreatorProfileLabelsForSlCommunityPageFiltersLazyQuery();

  const { value: filters, setValue: setFilters } =
    useLocalStorage<NestedFiltersValues>(
      SOCIAL_LISTENING_COMMUNITY_FILTERS + brandId,
      {
        dateRange: {
          value: dateRangeOptions[0],
        },
      },
      yup
        .object()
        .shape({
          dateRange: yup
            .object()
            .shape({
              value: yup
                .object()
                .shape({
                  label: yup.string().optional(),
                  startDate: yup.date().optional(),
                  endDate: yup.date().optional(),
                })
                .optional(),
            })
            .optional(),
          filterType: yup
            .object()
            .shape({
              value: yup
                .mixed<CreatorProfileFilterType>()
                .oneOf([CreatorProfileFilterType.IsTrackingInsights])
                .optional(),
            })
            .optional(),
          filterBy: yup
            .object()
            .shape({
              relationshipsGroup: yup
                .object()
                .shape({
                  relationships: yup
                    .object()
                    .shape({
                      value: yup
                        .array()
                        .of(
                          yup
                            .mixed<CreatorProfileRelationshipStrength>()
                            .oneOf(
                              Object.values(CreatorProfileRelationshipStrength),
                            ),
                        )
                        .optional(),
                    })
                    .optional(),
                })
                .optional(),
              sentimentsGroup: yup
                .object()
                .shape({
                  sentiments: yup
                    .object()
                    .shape({
                      value: yup
                        .array()
                        .of(
                          yup
                            .mixed<Sentiment>()
                            .oneOf(Object.values(Sentiment)),
                        )
                        .optional(),
                    })
                    .optional(),
                })
                .optional(),
              labelsGroup: yup
                .object()
                .shape({
                  labels: yup
                    .object()
                    .shape({
                      value: yup
                        .array()
                        .of(
                          yup.object().shape({
                            label: yup.string().required(),
                            value: yup.string().required(),
                          }),
                        )
                        .optional(),
                    })
                    .optional(),
                })
                .optional(),
              followersGroup: yup
                .object()
                .shape({
                  followers: yup
                    .object()
                    .shape({
                      value: yup
                        .object()
                        .shape({
                          min: yup.string().optional(),
                          max: yup.string().optional(),
                        })
                        .optional(),
                    })
                    .optional(),
                })
                .optional(),
              mentionsGroup: yup
                .object()
                .shape({
                  mentions: yup
                    .object()
                    .shape({
                      value: yup
                        .object()
                        .shape({
                          min: yup.string().optional(),
                          max: yup.string().optional(),
                        })
                        .optional(),
                    })
                    .optional(),
                })
                .optional(),
              commentsGroup: yup
                .object()
                .shape({
                  comments: yup
                    .object()
                    .shape({
                      value: yup
                        .object()
                        .shape({
                          min: yup.string().optional(),
                          max: yup.string().optional(),
                        })
                        .optional(),
                    })
                    .optional(),
                })
                .optional(),
            })
            .optional(),
        })
        .noUnknown(),
    );

  // Handle URL config parameter
  useEffect(() => {
    const handleUrlConfig = async () => {
      const configParam = searchParams.get('config');
      if (configParam) {
        try {
          const decodedConfig = JSON.parse(window.atob(configParam));
          const labelOptions = await fetchLabels(
            getLabels,
            decodedConfig.labelIds ?? [],
          );
          const transformedFilters = transformToFilterValues(
            decodedConfig,
            labelOptions,
          );

          const { startDate, endDate } = adjustDateRange(
            decodedConfig.dateRange?.startDate,
            decodedConfig.dateRange?.endDate,
          );

          setFilters(transformedFilters);
          setFiltersFromSavedConfig({
            ...decodedConfig,
            dateRange: {
              startDate,
              endDate,
            },
          });
          // Remove the config param after applying
          searchParams.delete('config');
          setSearchParams(searchParams);
        } catch (e) {
          console.error('Failed to parse config from URL', e);
        }
      }
    };

    handleUrlConfig();
  }, []); // eslint-disable-line -- Run only once on mount

  useEffect(() => {
    _setFilters({
      dateRange: {
        startDate: filters.dateRange?.value?.startDate,
        endDate: filters.dateRange?.value?.endDate,
      },
      filterType: filters.filterType?.value,
      relationshipStrength: (
        filters.filterBy?.relationshipsGroup?.relationships.value ?? []
      ).map((v) => v.value) as CreatorProfileRelationshipStrength[],
      sentiment: (
        filters.filterBy?.sentimentsGroup?.sentiments.value ?? []
      ).map((v) => v.value) as Sentiment[],
      labelIds: (filters.filterBy?.labelsGroup?.labels.value ?? []).map(
        (v) => v.value,
      ),
      followersCount: {
        min: filters.filterBy?.followersGroup?.followers.value?.min
          ? Number(filters.filterBy?.followersGroup?.followers.value?.min)
          : undefined,
        max: filters.filterBy?.followersGroup?.followers.value?.max
          ? Number(filters.filterBy?.followersGroup?.followers.value?.max)
          : undefined,
      },
      brandMentionsCount: {
        min: filters.filterBy?.mentionsGroup?.mentions.value?.min
          ? Number(filters.filterBy?.mentionsGroup?.mentions.value?.min)
          : undefined,
        max: filters.filterBy?.mentionsGroup?.mentions.value?.max
          ? Number(filters.filterBy?.mentionsGroup?.mentions.value?.max)
          : undefined,
      },
      commentsCount: {
        min: filters.filterBy?.commentsGroup?.comments.value?.min
          ? Number(filters.filterBy?.commentsGroup?.comments.value?.min)
          : undefined,
        max: filters.filterBy?.commentsGroup?.comments.value?.max
          ? Number(filters.filterBy?.commentsGroup?.comments.value?.max)
          : undefined,
      },
    });

    setRawFilters(filters);
  }, [filters]); // eslint-disable-line

  // Initially when getting filters from local storage,
  // fields like dateRange could be out of sync with the actual date range
  // so we need to update the date range to the latest date range by label
  useEffect(() => {
    if (filters.dateRange?.value?.label) {
      const option = dateRangeOptions.find(
        (option) => option.label === filters.dateRange?.value?.label,
      );

      if (option) {
        setFilters({
          ...filters,
          dateRange: {
            value: {
              label: option.label,
              startDate: option.startDate,
              endDate: option.endDate,
            },
          },
        });
      }
    }
  }, []); // eslint-disable-line -- Run only once on mount

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 4,
      }}
    >
      <NestedFiltersMenuView
        values={{
          dateRange: filters.dateRange,
        }}
        onChange={(_values) => {
          const values = _values as NestedFiltersValues;

          setFilters({
            ...filters,
            dateRange: values.dateRange,
          });
        }}
        componentsProps={{
          trigger: {
            text:
              filters.dateRange?.value?.label === CUSTOM_RANGE_LABEL
                ? `${moment(filters.dateRange?.value.startDate).format(
                    'MMM DD, YYYY',
                  )} - ${moment(filters.dateRange?.value.endDate).format(
                    'MMM DD, YYYY',
                  )}`
                : filters.dateRange?.value?.label ?? 'Date range',
            Icon: IconOutlineCalendar1,
          },
        }}
        items={[
          {
            type: 'date-range',
            key: 'dateRange',
            options: dateRangeOptions,
          },
        ]}
      />
      <NestedFiltersMenuView
        isContextSource
        showSelectedFilterCount
        values={filters as unknown as any}
        onChange={(_values) => {
          setFilters((o) => {
            return {
              ...o,
              ..._values,
            };
          });
        }}
        componentsProps={{
          menu: {
            sx: {
              width: theme.spacing(70),
            },
          },
        }}
        items={[
          {
            type: 'submenu',
            key: 'filterBy',
            label: 'Filter by',
            Icon: IconLinearFilter,
            items: [
              {
                type: 'group',
                key: 'relationshipsGroup',
                label: 'Relationship',
                Icon: IconCustomTodo,
                items: [
                  {
                    type: 'multi-select',
                    key: 'relationships',
                    Icon: IconCustomTodo,
                    options: [
                      CreatorProfileRelationshipStrength.VeryStrong,
                      CreatorProfileRelationshipStrength.Strong,
                      CreatorProfileRelationshipStrength.Neutral,
                      CreatorProfileRelationshipStrength.Weak,
                      CreatorProfileRelationshipStrength.VeryWeak,
                    ].map((value) => ({
                      label: RelationshipLabelMap[value],
                      value,
                    })),
                  },
                ],
              },
              {
                type: 'group',
                key: 'sentimentsGroup',
                label: 'Sentiment',
                Icon: IconCustomSmiley,
                items: [
                  {
                    type: 'multi-select',
                    key: 'sentiments',
                    Icon: IconCustomSmiley,
                    options: [
                      Sentiment.Positive,
                      Sentiment.Neutral,
                      Sentiment.Negative,
                    ].map((value) => ({
                      label: SentimentLabelMap[value],
                      value,
                    })),
                  },
                ],
              },
              {
                type: 'group',
                key: 'labelsGroup',
                label: 'Labels',
                Icon: IconLinearNote,
                items: [
                  {
                    type: 'multi-select',
                    key: 'labels',
                    options: [],
                    Icon: IconLinearNote,
                    filterable: true,
                    getAsyncOptions: async ({ query, nextPageCursor }) => {
                      const labelsData = await getLabels({
                        variables: {
                          input: {
                            brandId,
                            search: query,
                            after: nextPageCursor,
                          },
                        },
                        fetchPolicy: 'network-only',
                      });
                      const labels =
                        labelsData?.data
                          ?.paginatedOrganizationCreatorProfileLabels.data ||
                        [];
                      const endCursor = labelsData?.data
                        ?.paginatedOrganizationCreatorProfileLabels.pageInfo
                        ?.hasNextPage
                        ? labelsData?.data
                            ?.paginatedOrganizationCreatorProfileLabels.pageInfo
                            ?.endCursor ?? ''
                        : undefined;

                      return {
                        options: labels.map((label) => ({
                          label: label.label,
                          value: label.id,
                        })),
                        nextPageCursor: endCursor,
                      };
                    },
                  },
                ],
              },
              {
                type: 'group',
                key: 'followersGroup',
                label: 'Followers',
                Icon: IconLinearUser,
                items: [
                  {
                    type: 'form',
                    key: 'followers',
                    Icon: IconLinearUser,
                    fields: [
                      {
                        type: 'number',
                        key: 'min',
                        label: 'Mininum number of followers',
                      },
                      {
                        type: 'number',
                        key: 'max',
                        label: 'Maximum number of followers',
                      },
                    ],
                  },
                ],
              },
              {
                type: 'group',
                key: 'mentionsGroup',
                label: 'Mentions',
                Icon: IconLinearTag2,
                items: [
                  {
                    type: 'form',
                    key: 'mentions',
                    Icon: IconLinearTag2,
                    fields: [
                      {
                        type: 'number',
                        key: 'min',
                        label: 'Mininum number of mentions',
                      },
                      {
                        type: 'number',
                        key: 'max',
                        label: 'Maximum number of mentions',
                        rules: {
                          validate: (value: number) => {
                            if (
                              value &&
                              value <=
                                (filters.filterBy?.mentionsGroup?.mentions.value
                                  ?.min || 0)
                            ) {
                              return 'Max should be greater than min';
                            }

                            return true;
                          },
                        },
                      },
                    ],
                  },
                ],
              },
              {
                type: 'group',
                key: 'commentsGroup',
                label: 'Comments',
                Icon: IconLinearMessage,
                items: [
                  {
                    type: 'form',
                    key: 'comments',
                    Icon: IconLinearMessage,
                    fields: [
                      {
                        type: 'number',
                        key: 'min',
                        label: 'Mininum number of comments',
                      },
                      {
                        type: 'number',
                        key: 'max',
                        label: 'Maximum number of comments',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            type: 'divider',
          },
          {
            type: 'custom',
            render: () => {
              return (
                <RadioMenuItem
                  value={
                    filters.filterType?.value ===
                    CreatorProfileFilterType.IsTrackingInsights
                      ? 'tracking-insights'
                      : 'all'
                  }
                  checked={
                    filters.filterType?.value ===
                    CreatorProfileFilterType.IsTrackingInsights
                  }
                  onClick={() => {
                    setFilters({
                      ...filters,
                      filterType: {
                        value:
                          filters.filterType?.value ===
                          CreatorProfileFilterType.IsTrackingInsights
                            ? undefined
                            : CreatorProfileFilterType.IsTrackingInsights,
                      },
                    });
                  }}
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: 24,
                          height: 24,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: 1,
                          color: theme.colors?.primary.black,
                          bgcolor: 'rgba(35, 6, 3, 0.05)',
                        }}
                      >
                        <IconBoldCamera size={16} />
                      </Box>
                      <Typography
                        variant="subhead-lg"
                        color={theme.colors?.primary.black}
                        sx={{
                          whiteSpace: 'break-spaces',
                        }}
                      >
                        Peeking only
                      </Typography>
                    </Box>
                  }
                  radioPosition="end"
                />
              );
            },
          },
          {
            type: 'custom',
            render: () => {
              return (
                <SaveViewsMenu
                  brandId={brandId}
                  configForSaving={configForSaving}
                  onApplyConfig={async (config) => {
                    // validate labelIds before applying labels filter
                    const labelOptions = await fetchLabels(
                      getLabels,
                      brandId,
                      config.labelIds ?? [],
                    );

                    const filteredLabels = labelOptions.filter(
                      (label) => !config.labelIds?.includes(label.value),
                    );

                    const transformedFilters = transformToFilterValues(
                      config,
                      filteredLabels,
                    );

                    const { startDate, endDate } = adjustDateRange(
                      config.dateRange?.startDate,
                      config.dateRange?.endDate,
                    );

                    setFilters(transformedFilters);
                    setFiltersFromSavedConfig({
                      ...config,
                      dateRange: {
                        startDate,
                        endDate,
                      },
                    });
                  }}
                />
              );
            },
          },
        ]}
      />
    </Box>
  );
};
