import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldDollarCircle } from 'components/icons/components/bold/IconBoldDollarCircle';
import { IconBoldGps } from 'components/icons/components/bold/IconBoldGps';
import { IconBoldLike } from 'components/icons/components/bold/IconBoldLike';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconBoldSend2 } from 'components/icons/components/bold/IconBoldSend2';
import { IconCustomChart3 } from 'components/icons/components/custom/IconCustomChart3';
import { IconCustomExpire } from 'components/icons/components/custom/IconCustomExpire';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconOutlineLink2 } from 'components/icons/components/outline/IconOutlineLink2';
import { getCreatorHandleWithPrefix } from 'features/creator';
import {
  ExpandableText,
  SentimentIcon,
} from 'features/socialMediaListening/components';
import { SocialListeningPlatformLogo } from 'features/socialMediaListening/components/platformLogo/SocialListeningPlatformLogo';
import { SocialMediaListeningPostContextMenu } from 'features/socialMediaListening/components/postContextMenu';
import {
  useBrandDetails,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening/hooks';
import { SocialPostBrandEngagementStatus } from 'features/socialPost';
import { colors as topicLabelColors } from 'features/topic';
import {
  BrandSocialPostEngagementStatus,
  SocialListeningPostsSortBy,
  SocialPostType,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { BlockCreatorDialog } from 'features/socialMediaListening/components/blockCreatorDialog';
import { IconBoldSave } from 'components/icons/components/bold/IconBoldSave';
import { SocialListeningReportPostView } from '../../reportPost/SocialListeningReportPostView';
import { HoverPlayableVideo } from './HoverPlayableVideo';
import { StaticImage } from './StaticImage';
import { SocialMediaListeningPostCardViewProps } from './types';

// NOTE: These values are inferred (& toned down) from the actual distributions of the data
// in production database. We might need to update these from time to time.
// 1. Median (50th percentile)
// 2. 75th percentile
// 3. 90th percentile
const totalEngagementThresholds = [1800, 22000, 280000];
const engagementRateThresholds = [5, 11, 21];
const topicRelevancyScoreThresholds = [70, 80, 90];

const totalEngagementIcons = [
  '/images/icons/fire-1.svg',
  '/images/icons/fire-2.png',
  '/images/icons/fire-3.png',
];
const engagementRateIcons = [
  '/images/icons/fire-1.svg',
  '/images/icons/fire-2.png',
  '/images/icons/fire-3.png',
];
const topicRelevancyScoreIcons = [
  '/images/icons/dart-1.png',
  '/images/icons/dart-2.png',
  '/images/icons/dart-3.png',
];

export const SocialMediaListeningPostCardView = ({
  brandId,
  post,
  searchCriteria,
  itemPosition,
  className,
  componentsProps = {},
  onCardDeleted,
  sortBy,
}: SocialMediaListeningPostCardViewProps) => {
  const { brand } = useBrandDetails({ brandId });

  const [isHidden, setHidden] = useState<boolean>(false);
  const { generateLink } = useSocialListeningPostLinkGenerator();

  const { onCopy } = useClipboard(generateLink(post) || post.videoUrl || '');
  const [isCopied, setIsCopied] = useState(false);

  const ref = useRef<HTMLAnchorElement | null>(null);

  const {
    isOpen: isBlockCreatorDialogOpen,
    onClose: closeBlockCreatorDialog,
    onOpen: openBlockCreatorDialog,
  } = useDisclosure();

  const {
    isOpen: isReportPostDialogOpen,
    onClose: closeReportPostDialog,
    onOpen: openReportPostDialog,
  } = useDisclosure();

  const onTextCopy = () => {
    onCopy();
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const renderExtraActionsTopLeft = () => {
    if (post.isAd) {
      return (
        <Box display="flex" gap={2} alignItems="center" py={2} px={1}>
          <IconBoldDollarCircle color={theme.colors?.primary.white} size={16} />
        </Box>
      );
    }

    return null;
  };

  const renderActionsTopRight = (isHovered: boolean) => {
    // If the post has expiresAt -> it's a story,
    // we should render expiration data
    const isPostAStory = !!post.expiresAt;
    const hasExpired = isPostAStory && new Date(post.expiresAt) < new Date();
    // If post is to expire in 5 hours
    const isExpiringSoon =
      isPostAStory && moment(post.expiresAt).diff(moment(), 'hours') <= 5;

    return (
      <Box
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          button: {
            p: 1,
            borderRadius: 50,
            backgroundColor: 'rgba(35, 6, 3, 0.10)',
            color: theme.colors?.primary.white,
            backdropFilter: 'blur(4px)',
          },
        }}
      >
        {isHovered && post.videoUrl && (
          <IconButtonWithTooltip
            tooltip={isCopied ? 'Copied link' : 'Copy link'}
            disableRipple
            onClick={onTextCopy}
          >
            <IconOutlineLink2 size={12} />
          </IconButtonWithTooltip>
        )}
        {isHovered && post.creator?.handle && (
          <IconButton
            disableRipple
            onClick={() => {
              window.open(generateLink(post) || '', '_blank');
            }}
          >
            <IconLinearExport2 size={12} />
          </IconButton>
        )}
        {isPostAStory && (
          <Tooltip title={moment(post.expiresAt).format('MMM DD, YYYY h:mm A')}>
            <Box
              component="button"
              sx={{
                py: `2px !important`,
                pl: 1,
                pr: 2,
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                ...(isExpiringSoon && {
                  bgcolor: `${theme.colors?.utility['pink-3']} !important`,
                }),
                ...(hasExpired && {
                  bgcolor: `${theme.colors?.utility[600]} !important`,
                }),
              }}
            >
              <IconCustomExpire size={14} />
              <Typography variant="subhead-md">
                {hasExpired
                  ? 'Expired'
                  : `${moment(post.expiresAt).diff(moment(), 'hours')}h left`}
              </Typography>
            </Box>
          </Tooltip>
        )}
      </Box>
    );
  };

  const renderActionsBottom = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={2}
    >
      <Box
        display="flex"
        gap={1}
        alignItems="center"
        ml={2}
        width="70%"
        maxWidth="70%"
      >
        <Avatar
          src={post.creator?.profilePictureUrl || ''}
          sx={{
            width: theme.spacing(4),
            height: theme.spacing(4),
          }}
        />
        <Typography
          color={theme.colors?.primary.parchment}
          variant="body-lg"
          fontWeight={600}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getCreatorHandleWithPrefix(post.creator?.handle ?? '')}
        </Typography>
      </Box>

      {post.type === SocialPostType.Video && post.playCount > -1 && (
        <Box mr={2} display="flex" gap={1} alignItems="center">
          <IconBoldPlay size={16} color={theme.colors?.primary.parchment} />
          <Typography
            color={theme.colors?.primary.parchment}
            variant="body-lg"
            fontWeight={600}
          >
            {formatBigNumber(post.playCount || 0)}
          </Typography>
        </Box>
      )}
    </Box>
  );

  const totalEngagement = post.totalEngagement || 0;
  const engagementRate = Number.parseFloat(
    ((post.engagementRate || 0) * 100).toFixed(1),
  );
  const topicRelevancyScore = Number.parseFloat(
    ((post.socialPostEngagementSuggestions?.[0]?.score || 0) * 100).toFixed(1),
  );
  const topic = useMemo(() => {
    return (
      post.socialPostEngagementSuggestionsWithTopic?.[0]?.topic ||
      post.socialPostEngagementSuggestions?.[0]?.topic
    );
  }, [post]);

  /**
   * Depending on the total engagement, engagement rate, and topic relevancy score
   * comparing to the thresholds, we will render different icons.
   *
   * 1. 1st icon: >= 1st threshold (median)
   * 2. 2nd icon: >= 2nd threshold (75th percentile) and < 3rd threshold (90th percentile)
   * 3. 3rd icon: >= 3rd threshold (90th percentile)
   */
  const { totalEngagementIcon, engagementRateIcon, topicRelevancyScoreIcon } =
    useMemo(() => {
      const totalEngagementIcon =
        totalEngagement >= totalEngagementThresholds[2]
          ? totalEngagementIcons[2]
          : totalEngagement >= totalEngagementThresholds[1]
          ? totalEngagementIcons[1]
          : totalEngagement >= totalEngagementThresholds[0]
          ? totalEngagementIcons[0]
          : '';

      const engagementRateIcon =
        engagementRate >= engagementRateThresholds[2]
          ? engagementRateIcons[2]
          : engagementRate >= engagementRateThresholds[1]
          ? engagementRateIcons[1]
          : engagementRate >= engagementRateThresholds[0]
          ? engagementRateIcons[0]
          : '';

      const topicRelevancyScoreIcon =
        topicRelevancyScore >= topicRelevancyScoreThresholds[2]
          ? topicRelevancyScoreIcons[2]
          : topicRelevancyScore >= topicRelevancyScoreThresholds[1]
          ? topicRelevancyScoreIcons[1]
          : topicRelevancyScore >= topicRelevancyScoreThresholds[0]
          ? topicRelevancyScoreIcons[0]
          : '';

      return {
        totalEngagementIcon,
        engagementRateIcon,
        topicRelevancyScoreIcon,
      };
    }, [engagementRate, totalEngagement, topicRelevancyScore]);

  const renderTopicPill = useMemo(() => {
    return (
      <Box
        display="flex"
        gap={2}
        flexWrap="wrap"
        mt={2}
        alignItems="flex-end"
        key={new Date().getTime()}
      >
        <Typography
          variant="headline-sm"
          sx={{
            backgroundColor: theme.colors?.utility[400],
            borderRadius: 2,
            p: theme.spacing(1, 2),
            ' -webkit-line-clamp': 1,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-box-orient': 'vertical',
            textAlign: 'left',
            color: topic
              ? topicLabelColors[topic.type]?.color ??
                theme.colors?.utility[700]
              : theme.colors?.utility[700],
          }}
        >
          {topic?.name}
        </Typography>
      </Box>
    );
  }, [topic]);

  return (
    <>
      <RightClickContextMenuWrapper
        key={post.id}
        renderMenu={(props) =>
          post.socialPostEngagementSuggestions?.[0].id ? (
            <SocialMediaListeningPostContextMenu
              socialPostId={post.id}
              socialPostEngagementSuggestionId={
                post.socialPostEngagementSuggestions?.[0].id
              }
              onShowReportPostDialog={openReportPostDialog}
              onShowBlockCreatorDialog={openBlockCreatorDialog}
              {...props}
            />
          ) : null
        }
        sx={{
          height: '100%',
          // fadeout effect when hiding the post card
          animation: isHidden
            ? `fadeOut 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards`
            : `fadeIn 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards`,
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
              display: 'none',
              transform: 'translateY(-20px)',
            },
            '100%': {
              opacity: 1,
              display: 'inherit',
              transform: 'translateY(0)',
            },
          },
          '@keyframes fadeOut': {
            '0%': {
              opacity: 1,
              display: 'inherit',
              transform: 'translateY(0)',
            },
            '100%': {
              opacity: 0,
              display: 'none',
              transform: 'translateY(20px)',
            },
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Link
          style={{
            display: 'inherit',
            height: '100%',
          }}
          ref={ref}
          to={{
            pathname: PlotRoutes.socialListeningPost(post.id, brandId),
            search: `?searchCriteria=${searchCriteria}&position=${itemPosition}`,
          }}
          {...componentsProps.link}
        >
          <Box
            sx={{
              borderRadius: 6,
              backgroundColor: theme.colors?.utility[275],
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2,
            }}
            height="100%"
            className={className}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[600]}
                display="flex"
                gap={1}
                alignItems="center"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.colors?.utility[400],
                    borderRadius: 2,
                    p: theme.spacing(1.5),
                    mr: 1,
                  }}
                >
                  <SocialListeningPlatformLogo
                    platform={post.platform}
                    size={20}
                  />
                </Box>

                {moment(post.platformCreateTime).format('DD MMM YYYY')}
              </Typography>
              <Box onClick={(e) => e.stopPropagation()}>
                <SocialPostBrandEngagementStatus
                  brandId={brand?.id ?? ''}
                  socialPost={post}
                  showIcon
                  componentProps={{
                    button: {
                      sx: {
                        p: theme.spacing(1, 2),
                      },
                    },
                  }}
                  onUpdateCallBack={async (status) => {
                    // if new status is Pass or Responded, hide the post card
                    if (
                      [
                        BrandSocialPostEngagementStatus.Pass,
                        BrandSocialPostEngagementStatus.Responded,
                      ].includes(status)
                    ) {
                      setHidden(true);
                    } else {
                      setHidden(false);
                    }
                  }}
                />
              </Box>
            </Box>

            {post.socialPostEngagementSuggestions?.[0]?.oneLineSummary && (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                alignItems="flex-start"
              >
                <ExpandableText
                  text={
                    post.socialPostEngagementSuggestions[0].oneLineSummary || ''
                  }
                  componentProps={{
                    text: {
                      sx: {
                        ...typography['body-xl'],
                        fontFamily: 'Good Sans',
                        color: theme.colors?.utility[800],
                        fontWeight: 500,
                        fontSize: theme.spacing(4),
                      },
                    },
                  }}
                  collapsedHeight={40}
                />
              </Box>
            )}

            {renderTopicPill}

            <Box display="flex" mt={4}>
              <Box display="flex" flex={3} alignItems="flex-end">
                <Box
                  sx={{ width: '100%' }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {post.type === SocialPostType.Video && (
                    <HoverPlayableVideo
                      thumbnailUrl={post.thumbnailUrl || ''}
                      videoUrl={post.videoUrl || ''}
                      renderActionsTopRight={renderActionsTopRight}
                      renderActionsBottom={renderActionsBottom}
                      renderExtraActionsTopLeft={renderExtraActionsTopLeft}
                    />
                  )}
                  {(post.type === SocialPostType.Photo ||
                    post.type === SocialPostType.Carousel) && (
                    <StaticImage
                      thumbnailUrl={post.thumbnailUrl || ''}
                      renderActionsTopRight={renderActionsTopRight}
                      renderActionsBottom={renderActionsBottom}
                    />
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                flex={1}
                ml={2}
                gap={2}
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: 1,
                    p: theme.spacing(3, 4),
                    backgroundColor: 'rgba(35, 6, 3, 0.05)',
                    borderRadius: 2,
                  }}
                >
                  {post.socialPostEngagementSuggestions?.[0]?.sentiment && (
                    <SentimentIcon
                      sentiment={
                        post.socialPostEngagementSuggestions[0].sentiment
                      }
                      size={16}
                    />
                  )}
                </Box>
                {sortBy === SocialListeningPostsSortBy.TotalBookmarks && (
                  <Tooltip
                    title={
                      <Typography variant="body-sm">
                        <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                          Total bookmarks
                        </Box>
                        Total bookmarks for this post.
                      </Typography>
                    }
                  >
                    <Box
                      color={theme.colors?.utility[700]}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 1,
                        p: theme.spacing(3, 4),
                        backgroundColor: 'rgba(35, 6, 3, 0.05)',
                        borderRadius: 2,
                      }}
                    >
                      <IconBoldSave
                        size={20}
                        style={{
                          color: theme.colors?.utility[700],
                        }}
                      />
                      <Typography variant="body-lg" fontWeight={600}>
                        {formatBigNumber(post.bookmarkCount || 0)}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
                {sortBy === SocialListeningPostsSortBy.TotalShares && (
                  <Tooltip
                    title={
                      <Typography variant="body-sm">
                        <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                          Total shares
                        </Box>
                        Total shares on a post.
                      </Typography>
                    }
                  >
                    <Box
                      color={theme.colors?.utility[700]}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 1,
                        p: theme.spacing(3, 4),
                        backgroundColor: 'rgba(35, 6, 3, 0.05)',
                        borderRadius: 2,
                      }}
                    >
                      <IconBoldSend2
                        size={20}
                        style={{
                          color: theme.colors?.utility[700],
                        }}
                      />
                      <Typography variant="body-lg" fontWeight={600}>
                        {formatBigNumber(post.shareCount || 0)}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
                {post.type === SocialPostType.Video &&
                  typeof post.totalEngagement === 'number' &&
                  sortBy ===
                    SocialListeningPostsSortBy.TotalEngagementScore && (
                    <Tooltip
                      title={
                        <Typography variant="body-sm">
                          <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                            Total engagement
                          </Box>
                          The sum of likes, comments, and shares
                          <br /> on a post.
                          <Box
                            sx={{
                              height: '1px',
                              borderBottom: `1px solid #FAF3EC26`,
                              width: '100%',
                              my: 2,
                            }}
                          />
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap={2}
                            my={2}
                            variant="body-sm"
                            fontWeight={600}
                          >
                            <Box
                              component="img"
                              src={totalEngagementIcons[0]}
                            />
                            On the Rise
                          </Typography>
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap={2}
                            my={2}
                            variant="body-sm"
                            fontWeight={600}
                          >
                            <Box
                              component="img"
                              src={totalEngagementIcons[1]}
                            />
                            Viral
                          </Typography>
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap={2}
                            my={2}
                            variant="body-sm"
                            fontWeight={600}
                          >
                            <Box
                              component="img"
                              src={totalEngagementIcons[2]}
                            />
                            Breaking the Internet
                          </Typography>
                        </Typography>
                      }
                    >
                      <Box
                        color={theme.colors?.utility[700]}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          gap: 1,
                          p: theme.spacing(3, 4),
                          backgroundColor: 'rgba(35, 6, 3, 0.05)',
                          borderRadius: 2,
                        }}
                      >
                        {!totalEngagementIcon ? (
                          <IconCustomChart3
                            size={20}
                            style={{
                              color: theme.colors?.utility[700],
                            }}
                          />
                        ) : (
                          <Box
                            component="img"
                            src={totalEngagementIcon}
                            height={16}
                          />
                        )}
                        <Typography variant="body-lg" fontWeight={600}>
                          {formatBigNumber(totalEngagement)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                {post.type === SocialPostType.Video &&
                  typeof post.engagementRate === 'number' &&
                  sortBy !==
                    SocialListeningPostsSortBy.TotalEngagementScore && (
                    <Tooltip
                      title={
                        <Typography variant="body-sm">
                          <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                            Post engagement rate
                          </Box>
                          Post engagement rate measures <br />
                          interactions (likes, comments, and
                          <br />
                          shares) on a post. A rate exceeding 10% <br />
                          indicates potential viral traction.
                          <Box
                            sx={{
                              height: '1px',
                              borderBottom: `1px solid #FAF3EC26`,
                              width: '100%',
                              my: 2,
                            }}
                          />
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap={2}
                            my={2}
                            variant="body-sm"
                            fontWeight={600}
                          >
                            <Box component="img" src={engagementRateIcons[0]} />
                            On the Rise
                          </Typography>
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap={2}
                            my={2}
                            variant="body-sm"
                            fontWeight={600}
                          >
                            <Box component="img" src={engagementRateIcons[1]} />
                            Viral
                          </Typography>
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap={2}
                            my={2}
                            variant="body-sm"
                            fontWeight={600}
                          >
                            <Box component="img" src={engagementRateIcons[2]} />
                            Breaking the Internet
                          </Typography>
                        </Typography>
                      }
                    >
                      <Box
                        color={theme.colors?.utility[700]}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          flexDirection: 'column',
                          gap: 1,
                          p: theme.spacing(3, 4),
                          backgroundColor: 'rgba(35, 6, 3, 0.05)',
                          borderRadius: 2,
                        }}
                      >
                        {!engagementRateIcon ? (
                          <IconBoldLike
                            size={20}
                            style={{
                              color: theme.colors?.utility[700],
                            }}
                          />
                        ) : (
                          <Box
                            component="img"
                            src={engagementRateIcon}
                            height={16}
                          />
                        )}
                        <Typography variant="body-lg" fontWeight={600}>
                          {engagementRate}%
                        </Typography>
                      </Box>
                    </Tooltip>
                  )}
                {typeof post.socialPostEngagementSuggestions?.[0]?.score ===
                  'number' && (
                  <Tooltip
                    title={
                      <Typography variant="body-sm">
                        <Box sx={{ fontWeight: 600, my: 0, py: 0 }}>
                          Topic Relevancy Score
                        </Box>
                        This score rates how closely the <br />
                        content aligns with your chosen topics <br />
                        and prompts; a higher score suggests a <br />
                        user worth engaging with.
                        <Box
                          sx={{
                            height: '1px',
                            borderBottom: `1px solid #FAF3EC26`,
                            width: '100%',
                            my: 2,
                          }}
                        />
                        <Typography
                          display="flex"
                          alignItems="center"
                          gap={2}
                          my={2}
                          variant="body-sm"
                          fontWeight={600}
                        >
                          <Box
                            component="img"
                            src={topicRelevancyScoreIcons[0]}
                          />
                          Slightly relevant
                        </Typography>
                        <Typography
                          display="flex"
                          alignItems="center"
                          gap={2}
                          my={2}
                          variant="body-sm"
                          fontWeight={600}
                        >
                          <Box
                            component="img"
                            src={topicRelevancyScoreIcons[1]}
                          />
                          Relevant
                        </Typography>
                        <Typography
                          display="flex"
                          alignItems="center"
                          gap={2}
                          my={2}
                          variant="body-sm"
                          fontWeight={600}
                        >
                          <Box
                            component="img"
                            src={topicRelevancyScoreIcons[2]}
                          />
                          Very Relevant
                        </Typography>
                      </Typography>
                    }
                  >
                    <Box
                      color={theme.colors?.utility[700]}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 1,
                        p: theme.spacing(3, 4),
                        backgroundColor: 'rgba(35, 6, 3, 0.05)',
                        borderRadius: 2,
                      }}
                    >
                      {!topicRelevancyScoreIcon ? (
                        <IconBoldGps size={16} />
                      ) : (
                        <Box
                          component="img"
                          src={topicRelevancyScoreIcon}
                          height={16}
                        />
                      )}
                      <Typography variant="body-lg" fontWeight={600}>
                        {topicRelevancyScore}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
                {post.creator && post.creator.followerCount > -1 && (
                  <Tooltip title="Number of followers">
                    <Box
                      color={theme.colors?.utility[700]}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: 1,
                        p: theme.spacing(3, 4),
                        backgroundColor: 'rgba(35, 6, 3, 0.05)',
                        borderRadius: 2,
                      }}
                    >
                      <IconCustomUsers size={16} />
                      <Typography variant="body-lg" fontWeight={600}>
                        {formatBigNumber(post.creator.followerCount || 0)}
                      </Typography>
                    </Box>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </Box>
          <Box id="social-media-post-creative-juicebox-popover" />
          {post.creator && (
            <BlockCreatorDialog
              isOpen={isBlockCreatorDialogOpen}
              onClose={closeBlockCreatorDialog}
              creatorId={post.creator?.id}
              brandId={brandId}
            />
          )}
        </Link>
      </RightClickContextMenuWrapper>
      <SocialListeningReportPostView
        isOpen={isReportPostDialogOpen}
        onClose={() => {
          closeReportPostDialog();
        }}
        handleOnSubmit={() => {
          onCardDeleted?.();
        }}
        postId={post.id}
        brandId={brandId}
      />
    </>
  );
};
