import { gql } from '@apollo/client';
import { Avatar, Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomSLHorn } from 'components/icons/components/custom/IconCustomSLHorn';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { IconOutlineHashtag } from 'components/icons/components/outline/IconOutlineHashtag';
import { useBookmarkHandlers } from 'features/bookmark/hooks';
import {
  BookmarkFragmentForBookmarkSidebarItemFragment,
  BookmarkType,
  BrandFragmentForBookmarkSidebarItemFragment,
  BrandFragmentForBookmarkSidebarItemFragmentDoc,
} from 'graphql/generated';
import { useCallback } from 'react';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { bookmarkOptions } from '../bookmarkOptions';

// eslint-disable-next-line
gql`
  fragment BrandFragmentForBookmarkSidebarItem on BrandModel {
    id
    name
    creators {
      id
      profilePictureUrl
    }
  }
`;

// eslint-disable-next-line
gql`
  fragment BookmarkFragmentForBookmarkSidebarItem on BookmarkModel {
    id
    type
    collection {
      id
      name
      parentCollection {
        id
        name
      }
    }
    brand {
      ...BrandFragmentForBookmarkSidebarItem
    }
    competitor {
      id
      name
      forBrand {
        ...BrandFragmentForBookmarkSidebarItem
      }
    }
    topic {
      id
      name
      brand {
        ...BrandFragmentForBookmarkSidebarItem
      }
    }
  }
  ${BrandFragmentForBookmarkSidebarItemFragmentDoc}
`;

type BookmarkSidebarItemProps = {
  bookmark: BookmarkFragmentForBookmarkSidebarItemFragment;
};

export const BookmarkSidebarItem = (props: BookmarkSidebarItemProps) => {
  const { bookmark } = props;

  const { onDeleteBookmark } = useBookmarkHandlers();

  const renderBookmarkItemIcon = useCallback(() => {
    switch (bookmark.type) {
      case BookmarkType.Collection:
        return (
          <IconOutlineHashtag
            size={16}
            color={theme.colors?.utility[700]}
            style={{ strokeWidth: 1.5 }}
          />
        );
      default:
        return (
          <IconCustomSLHorn size={16} color={theme.colors?.utility[700]} />
        );
    }
  }, [bookmark.type]);

  const renderTitle = useCallback(() => {
    switch (bookmark.type) {
      case BookmarkType.Collection:
        return bookmark.collection?.name;
      case BookmarkType.SocialListeningEngagement:
        return 'Engagement';
      case BookmarkType.BrandAnalytics:
        return 'Brand Analytics';
      case BookmarkType.BrandOwnedAnalytics:
        return 'Owned Analytics';
      case BookmarkType.BrandUgcAnalytics:
        return 'UGC Analytics';
      case BookmarkType.BrandCompetitorAnalytics:
        return 'Competitor Analytics';
      case BookmarkType.BrandCommunityHub:
        return 'Your Community';
      case BookmarkType.Outbound:
        return 'Outbound';
      case BookmarkType.Competitor:
        return bookmark.competitor?.name;
      case BookmarkType.Topic:
        return bookmark.topic?.name;
      default:
        return '';
    }
  }, [bookmark]);

  const renderBrandAvatar = useCallback(() => {
    let brand: BrandFragmentForBookmarkSidebarItemFragment | null = null;
    switch (bookmark.type) {
      case BookmarkType.SocialListeningEngagement:
      case BookmarkType.BrandAnalytics:
      case BookmarkType.BrandCompetitorAnalytics:
      case BookmarkType.BrandCommunityHub:
      case BookmarkType.BrandOwnedAnalytics:
      case BookmarkType.BrandUgcAnalytics:
      case BookmarkType.Outbound:
        brand = bookmark.brand ?? null;
        break;
      case BookmarkType.Competitor:
        brand = bookmark.competitor?.forBrand ?? null;
        break;
      case BookmarkType.Topic:
        brand = bookmark.topic?.brand ?? null;
        break;
      default:
        return '';
    }
    if (!brand) return '';
    return (
      <Avatar
        src={
          brand.creators?.[0]?.profilePictureUrl ||
          brand.creators?.[1]?.profilePictureUrl ||
          ''
        }
        variant="circular"
        sx={{
          width: 15,
          height: 15,
        }}
      />
    );
  }, [bookmark]);

  const renderSubtitle = useCallback(() => {
    switch (bookmark.type) {
      case BookmarkType.Collection:
        return (
          bookmark.collection?.parentCollection?.name ?? 'Creative Juicebox'
        );
      case BookmarkType.SocialListeningEngagement:
      case BookmarkType.BrandAnalytics:
      case BookmarkType.BrandCompetitorAnalytics:
      case BookmarkType.BrandCommunityHub:
      case BookmarkType.Outbound:
        return `· ${bookmark.brand?.name}`;
      case BookmarkType.BrandOwnedAnalytics:
      case BookmarkType.BrandUgcAnalytics:
        return `· ${bookmark.brand?.name}· Brand analytics`;
      case BookmarkType.Competitor:
        return `· ${bookmark.competitor?.forBrand?.name} · Competitor analytics`;
      case BookmarkType.Topic:
        return `· ${bookmark.topic?.brand?.name} · Research`;
      default:
        return '';
    }
  }, [bookmark]);

  const onNavigateToItem = useCallback(() => {
    switch (bookmark.type) {
      case BookmarkType.Collection:
        window.open(PlotRoutes.collection(bookmark.collection?.id!), '_blank');
        break;
      case BookmarkType.SocialListeningEngagement:
        window.open(
          PlotRoutes.socialListeningForBrand(bookmark.brand?.id!),
          '_blank',
        );
        break;
      case BookmarkType.Outbound:
        window.open(
          PlotRoutes.socialListeningAnalyticsOutbound(bookmark.brand?.id!),
          '_blank',
        );
        break;
      case BookmarkType.BrandAnalytics:
      case BookmarkType.BrandOwnedAnalytics:
      case BookmarkType.BrandUgcAnalytics:
        window.open(
          PlotRoutes.socialListeningAnalyticsInbound(bookmark.brand?.id!),
          '_blank',
        );
        break;
      case BookmarkType.BrandCompetitorAnalytics:
        window.open(
          PlotRoutes.socialListeningCompetitors(bookmark.brand?.id!),
          '_blank',
        );
        break;
      case BookmarkType.Competitor:
        window.open(
          PlotRoutes.socialListeningCompetitor(
            bookmark.competitor?.id!,
            bookmark.competitor?.forBrand?.id!,
          ),
          '_blank',
        );
        break;
      case BookmarkType.Topic:
        window.open(
          PlotRoutes.socialListeningAnalyticsTopics(bookmark.topic?.brand?.id!),
          '_blank',
        );
        break;
      case BookmarkType.BrandCommunityHub:
        window.open(
          PlotRoutes.socialListeningCommunity(bookmark.brand?.id!),
          '_blank',
        );
        break;
      default:
        break;
    }
  }, [bookmark]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 2,
        borderRadius: 2,
        cursor: 'pointer',
        ':hover': {
          background: 'rgba(35, 6, 3, 0.05)',
          '.more-button': {
            opacity: 1,
          },
        },
      }}
      onClick={onNavigateToItem}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 3,
        }}
      >
        <Box mt={0.5}>{renderBookmarkItemIcon()}</Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            variant="subhead-lg"
            color={theme.colors?.utility[800]}
            sx={{
              '-webkit-line-clamp': '1',
              overflow: 'hidden',
              display: '-webkit-box',
              '-webkit-box-orient': 'vertical',
              width: '100%',
              wordBreak: 'break-word',
            }}
          >
            {renderTitle()}
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            {renderBrandAvatar()}
            <Typography
              variant="subhead-sm"
              color={theme.colors?.utility[600]}
              sx={{
                '-webkit-line-clamp': '1',
                overflow: 'hidden',
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                width: '100%',
              }}
            >
              {renderSubtitle()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          opacity: 0,
        }}
        className="more-button"
      >
        <ContextMenu
          renderButton={() => (
            <IconButton sx={{ p: 0 }} disableRipple>
              <IconLinearMore size={18} color={theme.colors?.utility[600]} />
            </IconButton>
          )}
          disablePortal
          options={bookmarkOptions({
            isBookmarked: true,
            onToggleBookmark: () => {
              onDeleteBookmark(bookmark);
            },
            onNavigate: onNavigateToItem,
          })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 50,
          }}
        />
      </Box>
    </Box>
  );
};
