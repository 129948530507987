import { Box, Typography } from '@mui/material';
import { createDateRangesForGraph } from 'features/socialMediaListening/hooks/useGraphDataMakerNew';
import {
  MultiChartDataPoint,
  SimpleDateRangeFilterInput,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useState } from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// Define line colors array with primary and highlight colors
const LINE_COLORS = [
  {
    primary: theme.colors?.utility['yellow-3'],
    highlight: theme.colors?.utility['yellow-1'],
  },
  {
    primary: theme.colors?.utility['green-3'],
    highlight: theme.colors?.utility['green-1'],
  },
  {
    primary: theme.colors?.utility['blue-3'],
    highlight: theme.colors?.utility['blue-1'],
  },
  {
    primary: theme.colors?.utility['purple-3'],
    highlight: theme.colors?.utility['purple-1'],
  },
  {
    primary: theme.colors?.utility['teal-3'],
    highlight: theme.colors?.utility['teal-1'],
  },
  {
    primary: theme.colors?.utility['pink-3'],
    highlight: theme.colors?.utility['pink-1'],
  },
  {
    primary: theme.colors?.utility['orange-3'],
    highlight: theme.colors?.utility['orange-1'],
  },
] as const;

// Export function to get color by index
export const getColorByIndex = (index: number, isHighlight = false): string => {
  const normalizedIndex =
    ((index % LINE_COLORS.length) + LINE_COLORS.length) % LINE_COLORS.length;
  const colors = LINE_COLORS[normalizedIndex];
  return isHighlight
    ? colors?.highlight ?? theme.colors?.utility['yellow-1']!
    : colors?.primary ?? theme.colors?.utility['yellow-3']!;
};

/**
 * Helper function to format and style percentage change values
 * @param percentageChange The percentage change value
 * @param isZeroToNonZero Whether this is a case of zero to non-zero (for "NEW" display)
 * @returns A string containing formatted text for the percentage change
 */
const formatPercentageChange = (
  percentageChange: number | undefined,
  isZeroToNonZero: boolean,
): string => {
  // If percentage is undefined, return empty display
  if (percentageChange === undefined) {
    return '';
  }

  // Special case - "NEW" for zero to non-zero scenarios
  if (isZeroToNonZero && percentageChange === 100) {
    return 'NEW';
  }

  // For positive changes
  if (percentageChange > 0) {
    return `↑${Math.abs(percentageChange).toFixed(1)}%`;
  }

  // For negative changes
  if (percentageChange < 0) {
    return `↓${Math.abs(percentageChange).toFixed(1)}%`;
  }

  // For zero change (0%)
  return `0%`;
};

type LabelData = {
  id: string;
  value: number;
  dateRange: SimpleDateRangeFilterInput;
  parsingSince: Date;
  percentageChange?: number;
};

/**
 * Represents the combined data structure used in the chart,
 * containing label data for each dataset and metadata
 */
interface CombinedChartData {
  date: string;
  zeroFirstValueDatasets?: string[];
  [datasetLabel: string]: LabelData | string | string[] | undefined;
}

type Props = {
  multiGraphData: MultiChartDataPoint[];
  onClick: (id: string, dateRange?: SimpleDateRangeFilterInput) => void;
  hiddenDatasets: string[];
};

// Custom tooltip component
const CustomTooltip = ({
  active,
  payload,
  coordinate,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: TooltipProps<number, string> & {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClick: (id: string, dateRange?: SimpleDateRangeFilterInput) => void;
}) => {
  if (!active || !payload?.length) return null;

  const handleItemClick = (
    id: string,
    dateRange?: SimpleDateRangeFilterInput,
  ) => {
    onClick(id, dateRange);
  };

  return (
    <Box
      onMouseEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseEnter();
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onMouseLeave();
      }}
      sx={{
        backgroundColor: 'rgba(251, 247, 245, 1)',
        border: (theme) => `1px solid ${theme.colors?.utility[300]}`,
        borderRadius: theme.spacing(1),
        padding: theme.spacing(8),
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        minWidth: '200px',
        position: 'relative',
        left: coordinate?.x && coordinate.x > 500 ? -220 : 10,
        pointerEvents: 'auto',
        overflowY: 'auto',
        maxHeight: theme.spacing(60),
      }}
    >
      <Typography
        variant="body-xl"
        color={theme.colors?.utility[600]}
        mb={2}
        display="block"
      >
        {payload[0].payload?.date}
      </Typography>

      {payload.map((entry) => {
        const key = (entry.dataKey as string).replace('.value', '');

        return (
          <Box
            key={entry.name}
            onClick={() => {
              if (entry.payload?.[key]?.id) {
                handleItemClick(
                  entry.payload?.[key]?.id ?? '',
                  entry.payload?.[key]?.dateRange,
                );
              }
            }}
            sx={{
              display: 'flex',
              py: theme.spacing(1),
              my: theme.spacing(1),
              alignItems: 'flex-start',
              cursor: 'pointer',
              transition: 'background-color 0.2s',
              color: entry.color,
              marginBottom: theme.spacing(2),
              '&:hover': {
                backgroundColor: (theme) => theme.colors?.utility[200],
              },
            }}
          >
            <Box display="flex" width="100%">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  marginRight: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: (theme) => theme.colors?.utility[900],
                  }}
                  variant="body-xl"
                >
                  {(entry.dataKey as string).replace('.value', '')}
                </Typography>
                <Typography
                  variant="body-sm"
                  color={theme.colors?.utility[500]}
                >
                  Parsing since{' '}
                  {entry.payload?.[key]?.parsingSince
                    ? moment(entry.payload?.[key]?.parsingSince).format(
                        'MMMM DD, YYYY',
                      )
                    : ''}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginLeft: 'auto',
                  display: 'flex',
                  alignItems: 'start',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  variant="body-xl"
                  sx={{
                    fontWeight: 600,
                    textAlign: 'right',
                    marginRight: 1,
                  }}
                >
                  {entry.value ? formatBigNumber(entry.value) : '0'}
                </Typography>
                {entry.payload?.[key]?.percentageChange !== undefined && (
                  <Typography
                    variant="body-xl"
                    sx={{
                      fontWeight: 600,
                      color: (theme) => theme.colors?.utility[500],
                    }}
                  >
                    {formatPercentageChange(
                      entry.payload?.[key]?.percentageChange,
                      !!entry.payload?.zeroFirstValueDatasets?.includes(key),
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const SLAMultiLineChartRenderer = (props: Props) => {
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const { multiGraphData, hiddenDatasets } = props;

  const allChartDataPoints = multiGraphData
    .filter((dataset) => !hiddenDatasets.includes(dataset.label))
    .flatMap((dataset) => dataset.chartDataPoints);
  const lowestDate = Math.min(
    ...allChartDataPoints.map((point) => moment(point.X).valueOf()),
  );

  const highestDate = Math.max(
    ...allChartDataPoints.map((point) => moment(point.X).valueOf()),
  );

  const graphDataRanges = useMemo(
    () =>
      createDateRangesForGraph(
        moment(lowestDate).toDate(),
        moment(highestDate).toDate(),
      ),
    [lowestDate, highestDate],
  );

  const combinedDataForGraph = useMemo(() => {
    if (!graphDataRanges.length) return [];

    // Initialize the base data structure with date labels
    const baseCombinedDataForGraph = graphDataRanges.map((range) => ({
      date: range.label,
    })) as CombinedChartData[];

    // Track which datasets had zero first value
    const zeroFirstValueDatasets = new Set<string>();

    multiGraphData.forEach((dataset) => {
      // For each dataset, find matching data points for each date range
      baseCombinedDataForGraph.forEach((baseData, index) => {
        const rangeStart = moment(graphDataRanges[index].startDate);
        const rangeEnd = moment(graphDataRanges[index].endDate);

        // Find all points that fall within this date range
        const pointsInRange = dataset.chartDataPoints.filter((point) => {
          const pointDate = moment(point.X);
          return pointDate.isBetween(rangeStart, rangeEnd, 'day', '[]'); // Include start and end dates
        });

        // Calculate the sum of Y values for points in this range
        const sumY = pointsInRange.reduce((sum, point) => sum + point.Y, 0);

        // Store an object with id and value for each label
        baseData[dataset.label] = {
          id: dataset.id,
          dateRange: {
            startDate: rangeStart.startOf('day').toDate(),
            endDate: rangeEnd.endOf('day').toDate(),
          },
          value: pointsInRange.length ? sumY : 0,
          parsingSince: dataset.parsingSince,
        };
      });

      // Calculate percentage change for each dataset across date ranges
      if (baseCombinedDataForGraph.length > 1) {
        // Get baseline value from the first date range
        const baselineValue =
          (baseCombinedDataForGraph[0][dataset.label] as LabelData)?.value || 0;

        if (baselineValue === 0) {
          zeroFirstValueDatasets.add(dataset.label);
        }

        // Calculate percentage change for each subsequent date range
        baseCombinedDataForGraph.forEach((baseData, index) => {
          if (index > 0 && baseData[dataset.label]) {
            const currentValue = (baseData[dataset.label] as LabelData).value;

            // Only calculate percentage change if baseline isn't zero
            if (baselineValue !== 0) {
              (baseData[dataset.label] as LabelData).percentageChange =
                ((currentValue - baselineValue) / baselineValue) * 100;
            } else if (currentValue > 0) {
              // If baseline is zero but current value exists, mark as "new" with 100%
              (baseData[dataset.label] as LabelData).percentageChange = 100;
            } else {
              // If both are zero, don't show percentage change (undefined)
              (baseData[dataset.label] as LabelData).percentageChange =
                undefined;
            }
          }
        });
      }
    });

    // Add the zero first value info to the return object
    const result = baseCombinedDataForGraph.map((item) => ({
      ...item,
      zeroFirstValueDatasets: Array.from(zeroFirstValueDatasets),
    }));

    return result;
  }, [graphDataRanges, multiGraphData]);

  // Find min/max Y values across all processed datasets
  const { minY, maxY } = useMemo(() => {
    const allYValues = multiGraphData.flatMap((dataset) =>
      dataset.chartDataPoints.map((point) => point.Y),
    );
    return {
      minY: Math.min(...allYValues),
      maxY: Math.max(...allYValues),
    };
  }, [multiGraphData]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={combinedDataForGraph}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} stroke={theme.colors?.utility[300]} />
        <XAxis dataKey="date" />
        <YAxis
          domain={[minY, maxY]}
          tickFormatter={(value) => formatBigNumber(value)}
        />
        <Tooltip
          position={{ y: 50 }}
          allowEscapeViewBox={{ x: true, y: true }}
          coordinate={{ x: 0, y: 0 }}
          content={
            <CustomTooltip
              onMouseEnter={() => setIsTooltipHovered(true)}
              onMouseLeave={() => setIsTooltipHovered(false)}
              onClick={props.onClick}
            />
          }
          cursor={{ stroke: theme.colors?.utility[300], strokeWidth: 1 }}
          isAnimationActive
          wrapperStyle={{
            pointerEvents: isTooltipHovered ? 'auto' : 'none',
            zIndex: 100,
            visibility: isTooltipHovered ? 'visible' : undefined,
          }}
          contentStyle={{
            pointerEvents: 'auto',
          }}
        />
        {multiGraphData.map((dataset, index) => {
          if (hiddenDatasets.includes(dataset.label)) return null;

          return (
            <Line
              key={dataset.label}
              type="monotone"
              dataKey={`${dataset.label}.value`}
              stroke={getColorByIndex(index)}
              strokeWidth={2}
              dot={false}
              activeDot={{
                stroke: getColorByIndex(index, true),
                strokeWidth: 2,
                r: 6,
              }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
