import { useEffect } from 'react';
import { Updater, useImmer } from 'use-immer';
import { ObjectSchema, StringSchema } from 'yup';

interface LocalStorageReturn<T> {
  value: T;
  setValue: Updater<T>;
  removeKey: VoidFunction;
}

function getLocalStorageValue<T>(key: string, defaultValue: T): T {
  const saved = localStorage.getItem(key);

  try {
    if (saved) {
      return JSON.parse(saved) as T;
    }

    return defaultValue;
  } catch (error) {
    console.error(error);

    return defaultValue;
  }
}

export function useLocalStorage<T>(
  key: string,
  defaultValue: T,
  validator?: ObjectSchema<any> | StringSchema,
): LocalStorageReturn<T> {
  const [value, setValue] = useImmer<T>(() => {
    const localStorageValue = getLocalStorageValue<T>(key, defaultValue);

    try {
      validator?.validateSync(localStorageValue, {
        stripUnknown: false,
      });

      return localStorageValue;
    } catch (error) {
      console.error(error);

      return defaultValue;
    }
  });

  useEffect(() => {
    const localStorageValue = getLocalStorageValue<T>(key, defaultValue);

    try {
      validator?.validateSync(localStorageValue, {
        stripUnknown: false,
      });

      setValue(localStorageValue);
    } catch (error) {
      console.error(error);

      setValue(defaultValue);
    }
  }, [JSON.stringify({ defaultValue, key })]); // eslint-disable-line

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  const removeKey = () => {
    localStorage.removeItem(key);
  };

  return { value, setValue, removeKey };
}
