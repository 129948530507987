import { Box, Divider, Skeleton } from '@mui/material';
import { theme } from 'styles/theme';

const SkeletonLabel = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      width: '40%',
      flexShrink: 0,
    }}
  >
    <Skeleton variant="circular" width={14} height={14} />
    <Skeleton variant="text" width={80} height={24} />
  </Box>
);

const SkeletonField = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'flex-start',
      gap: 4,
    }}
  >
    <SkeletonLabel />
    <Box flex={1}>
      <Skeleton variant="text" width="100%" height={24} />
    </Box>
  </Box>
);

export const SocialListeningCreatorProfileDetailsViewSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Box
        sx={{
          p: 6,
          borderRadius: 6,
          border: `1px solid ${theme.colors?.utility[300]}`,
          backgroundColor: theme.colors?.primary.white,
          boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
        }}
      >
        <Skeleton variant="text" width={80} height={24} />
        <Divider
          sx={{ borderWidth: '1.5px', borderColor: theme.colors?.utility[275] }}
        />
        {/* Name */}
        <SkeletonField />
        {/* Email addresses */}
        <SkeletonField />
        {/* Social Handles */}
        <SkeletonField /> {/* Instagram */}
        <SkeletonField /> {/* TikTok */}
        <SkeletonField /> {/* YouTube */}
        {/* Address */}
        <SkeletonField />
        {/* Birthday */}
        <SkeletonField />
        <Divider
          sx={{
            borderWidth: '1.5px',
            borderColor: theme.colors?.utility[275],
          }}
        />
        {/* Relationship */}
        <SkeletonField />
        {/* Labels */}
        <SkeletonField />
      </Box>

      {/* Creator Handler Views - showing 2 placeholder blocks */}
      {[1, 2].map((index) => (
        <Box
          key={index}
          sx={{
            p: 6,
            borderRadius: 6,
            border: `1px solid ${theme.colors?.utility[300]}`,
            backgroundColor: theme.colors?.primary.white,
            boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
          }}
        >
          <Skeleton variant="rectangular" width="100%" height={120} />
        </Box>
      ))}
    </Box>
  );
};
