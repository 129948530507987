import { useDisclosure } from '@dwarvesf/react-hooks';
import { Menu } from '@mui/material';
import { useRef } from 'react';
import { NestedFiltersGroupHeader } from '../group';
import {
  NestedFiltersMenuItem,
  NestedFiltersMenuItemBaseType,
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuItemType,
} from '../menuItem';

export type NestedFiltersSubMenuType = NestedFiltersMenuItemBaseType & {
  type: 'submenu';
  label: string;
  items: NestedFiltersMenuItemType[];
};

export type NestedFiltersSubMenuProps = {
  subMenu: NestedFiltersSubMenuType;
  values: Record<string, NestedFiltersMenuItemBaseValueType<unknown>>;
  onChange: (
    values: Record<string, NestedFiltersMenuItemBaseValueType<unknown>>,
  ) => void;
};

export const NestedFiltersSubMenu = (props: NestedFiltersSubMenuProps) => {
  const { subMenu, values, onChange } = props;

  const anchorElRef = useRef<HTMLLIElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <NestedFiltersGroupHeader
        ref={anchorElRef}
        {...subMenu}
        onClick={onOpen}
      />
      <Menu
        open={isOpen}
        onClose={onClose}
        anchorEl={anchorElRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        PaperProps={{
          sx: {
            maxHeight: '70vh',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(20px)',
            width: 266,
            p: 3,
            borderRadius: 3,
            boxShadow:
              'box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12); box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12);',
          },
        }}
      >
        {subMenu.items.map((item, index) => {
          return (
            <NestedFiltersMenuItem
              key={index}
              menuItem={item}
              {...(item.key
                ? {
                    value: values[item.key] || {},
                    onChange: (newValue) => {
                      onChange({
                        ...values,
                        [item.key as string]: newValue,
                      });
                    },
                  }
                : {})}
            />
          );
        })}
      </Menu>
    </>
  );
};
