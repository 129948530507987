import { NestedFiltersMenuItemBaseType } from '../menuItem';

export type NestedFiltersCustomType = NestedFiltersMenuItemBaseType & {
  type: 'custom';
  render: (
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  ) => JSX.Element;
};

export type NestedFiltersCustomProps = {
  custom: NestedFiltersCustomType;
};

export const NestedFiltersCustom = (props: NestedFiltersCustomProps) => {
  const { custom } = props;

  return custom.render(() => {});
};
