import { gql } from '@apollo/client';
import { Box, SxProps, Typography } from '@mui/material';
import { SocialPostCommentFragmentSocialPostCommentRepliesFragment } from 'graphql/generated';

gql`
  fragment SocialPostCommentFragmentSocialPostCommentReplies on SocialPostCommentModel {
    id
    childCommentCount
  }
`;

type SocialPostCommentRepliesProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentRepliesFragment;
  componentProps?: {
    sx?: SxProps;
    textSx?: SxProps;
  };
  showUnitLabel?: boolean;
};

export const SocialPostCommentReplies = (
  props: SocialPostCommentRepliesProps,
) => {
  const { socialPostComment, componentProps, showUnitLabel = true } = props;

  return (
    <Box sx={componentProps?.sx}>
      <Typography variant="headline-xxs" sx={componentProps?.textSx}>
        {socialPostComment.childCommentCount}
        {showUnitLabel ? ' replies' : ''}
      </Typography>
    </Box>
  );
};
