import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { toast } from 'components/common/Toast';
import {
  GetPostsSmartSearchForCollectionPageNormalCollectionDocument,
  GetSuggestedPostsForCollectionPageDocument,
  GetSuggestedPostsForCollectionPageQuery,
  PostFilterType,
  PostFragmentPostMasonryCardViewFragmentDoc,
  PostFragmentPostSuggestionCardViewFragmentDoc,
  ViewEntityType,
  useAddPostToCollectionForCollectionPageMutation,
  useDeletePostForCollectionPageMutation,
  useGetSuggestedPostsForCollectionPageQuery,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useViewEntity } from 'hooks/useViewEntity';
import { useEffect } from 'react';

export const LOCAL_STORAGE_HIDE_SUGGESTED_POST_SECTION =
  'hideSuggestedPostSection';

// eslint-disable-next-line
gql`
  query GetSuggestedPostsForCollectionPage($filters: SuggestedPostsFilters!) {
    suggestedPosts(filters: $filters) {
      ...PostFragmentPostSuggestionCardView
      ...PostFragmentPostMasonryCardView
    }
  }
  ${PostFragmentPostSuggestionCardViewFragmentDoc}
  ${PostFragmentPostMasonryCardViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation AddPostToCollectionForCollectionPage(
    $data: AddPostToCollectionInput!
  ) {
    addPostToCollection(data: $data) {
      success
      message
    }
  }
`;

// eslint-disable-next-line
gql`
  mutation DeletePostForCollectionPage($data: DeleteSuggestedPostInput!) {
    deleteSuggestedPost(data: $data) {
      message
      success
    }
  }
`;

export const useSuggestedPostSection = (collectionId: string) => {
  const analytics = useAnalytics();
  const { viewEntity } = useViewEntity();

  const [addPostToCollection] =
    useAddPostToCollectionForCollectionPageMutation();
  const [deletePostForCollectionPage] =
    useDeletePostForCollectionPageMutation();

  const onDeletePostFromCollection = async (
    postId: string,
    collectionId: string,
  ) => {
    await deletePostForCollectionPage({
      variables: {
        data: {
          postId,
          collectionId,
        },
      },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GetSuggestedPostsForCollectionPageDocument,
            variables: {
              filters: {
                collectionId,
              },
            },
          },
          (data: GetSuggestedPostsForCollectionPageQuery | null) => {
            if (!data) return null;

            const updatedPosts = {
              ...data,
              suggestedPosts: data.suggestedPosts.filter(
                (suggestedPost) => suggestedPost.id !== postId,
              ),
            };

            return updatedPosts;
          },
        );
      },
    });
  };

  const onAddPostToCollection = async (
    post: GetSuggestedPostsForCollectionPageQuery['suggestedPosts'][0],
    collectionId: string,
    position: number,
    total: number,
  ) => {
    // === Analytics ===
    analytics.track(EventName.SuggestedPostAdded, {
      postId: post.id,
      collectionId,
      position,
      totalSuggestedPosts: total,
    });
    // === End of Analytics ===

    await addPostToCollection({
      variables: {
        data: {
          collectionId,
          postId: post.id,
        },
      },
      update: (cache) => {
        cache.updateQuery(
          {
            query: GetSuggestedPostsForCollectionPageDocument,
            variables: {
              filters: {
                collectionId,
              },
            },
          },
          (data: GetSuggestedPostsForCollectionPageQuery | null) => {
            if (!data) return null;

            const updatedPosts = {
              ...data,
              suggestedPosts: data.suggestedPosts.filter(
                (suggestedPost) => suggestedPost.id !== post.id,
              ),
            };

            return updatedPosts;
          },
        );

        cache.updateQuery(
          {
            query: GetPostsSmartSearchForCollectionPageNormalCollectionDocument,
            variables: {
              filters: {
                collectionIds: [collectionId],
                filterType: PostFilterType.OrganizationPosts,
              },
            },
          },
          (data) => {
            // TODO: fix typing here
            if (!data) return null;

            toast({
              message: 'Post added to collection',
              type: 'success',
            });

            const updatedPosts = {
              ...data,
              postsSmartSearch: {
                ...data.postsSmartSearch,
                data: [{ item: post }, ...data.postsSmartSearch.data],
              },
            };

            return updatedPosts;
          },
        );
      },
    });
  };

  const { data: suggestedPostsData, loading } =
    useGetSuggestedPostsForCollectionPageQuery({
      variables: {
        filters: {
          collectionId,
        },
      },
      fetchPolicy: 'cache-and-network',
    });
  const suggestedPosts = suggestedPostsData?.suggestedPosts || [];

  const defaultIsSuggestedPostSectionVisible =
    localStorage.getItem(LOCAL_STORAGE_HIDE_SUGGESTED_POST_SECTION) !== 'true';
  const {
    isOpen: isSuggestedPostSectionVisible,
    onOpen: showSuggestedPostSection,
    onClose: hideSuggestedPostSection,
  } = useDisclosure({
    defaultIsOpen: defaultIsSuggestedPostSectionVisible,
  });

  // Log the view if isSuggestedPostSectionVisible is true
  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE_HIDE_SUGGESTED_POST_SECTION,
      isSuggestedPostSectionVisible ? '' : 'true',
    );
    if (isSuggestedPostSectionVisible) {
      viewEntity(collectionId, ViewEntityType.SuggestedPostSection);
    }
  }, [isSuggestedPostSectionVisible, collectionId]); // eslint-disable-line

  return {
    suggestedPosts,
    isSuggestedPostSectionVisible,
    loading,
    onAddPostToCollection,
    onDeletePostFromCollection,
    showSuggestedPostSection,
    hideSuggestedPostSection,
  };
};
