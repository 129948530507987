import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import { PostPreview } from 'features/post';
import {
  PostFragmentPostPreviewFragment,
  PostFragmentPostPreviewFragmentDoc,
  PostType,
  useGetPostByIdsForSlAssetsSelectorLazyQuery,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetPostByIdsForSLAssetsSelector($input: GetPostByIdsInput!) {
    postByIds(input: $input) {
      ...PostFragmentPostPreview
    }
  }
  ${PostFragmentPostPreviewFragmentDoc}
`;

interface SocialListeningAssetsSelectorProps {
  selectedAssetIds: string[];
  onAfterPostCreated: (postIds: string[]) => void;
}

export const SocialListeningAssetsSelector = ({
  onAfterPostCreated,
  selectedAssetIds,
}: SocialListeningAssetsSelectorProps) => {
  const { triggerCommand } = useCommandContext();
  const shouldShowAfterCreationDialog = false;

  const [posts, setPosts] = useState<PostFragmentPostPreviewFragment[]>([]);
  const [getPosts] = useGetPostByIdsForSlAssetsSelectorLazyQuery();

  useEffect(() => {
    // return only those ids that are not in the posts array
    const uniquePostIds = selectedAssetIds.filter(
      (id) => !posts.some((post) => post.id === id),
    );
    if (uniquePostIds.length > 0) {
      getPosts({
        variables: {
          input: { postIds: uniquePostIds },
        },
        onCompleted: (data) => {
          setPosts((prev) => [...prev, ...data.postByIds]);
        },
      });
    }
  }, [selectedAssetIds, posts, getPosts]);

  const options = [
    {
      Icon: IconOutlineLink,
      text: 'Paste a link or Image',
      onClick: () =>
        triggerCommand(COMMAND_TYPE.POST_PASTE_A_LINK, {
          shouldShowAfterCreationDialog,
          onCompleted: (data) => {
            onAfterPostCreated(data.map((post) => post.id));
            setPosts((prev) => [...prev, ...data]);
          },
        }),
    },
    {
      Icon: IconLinearDocumentUpload,
      text: 'Upload media',
      onClick: () =>
        triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
          shouldShowAfterCreationDialog,
          onCompleted: (data) => {
            onAfterPostCreated(data.map((post) => post.id));
            setPosts((prev) => [...prev, ...data]);
          },
        }),
    },
    {
      Icon: IconCustomCreativeJuiceBox,
      text: 'Add from Creative Juicebox',
      onClick: () => {
        triggerCommand(COMMAND_TYPE.POST_SELECT_FROM_CJB, {
          onCompleted: (data) => {
            onAfterPostCreated(data.map((post) => post.id));
            setPosts((prev) => [...prev, ...data]);
          },
        });
      },
    },
  ];
  return (
    <>
      {posts.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            overflowX: 'auto',
            flexWrap: 'nowrap',
            mr: 2,
          }}
        >
          {posts.map((post) => (
            <Link
              to={
                post.type === PostType.Note
                  ? PlotRoutes.juiceboxNote({ id: post.id })
                  : PlotRoutes.juice(post.id)
              }
              target="_blank"
            >
              <PostPreview
                disableMouseEvents
                post={post}
                sx={{
                  height: 120,
                  maxWidth: 75,
                  borderRadius: 2,
                }}
              />
            </Link>
          ))}
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ContextMenu
          PaperProps={{
            style: {
              minWidth: 266,
              borderRadius: theme.spacing(3),
              boxShadow: `0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12)`,
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          renderButton={() => (
            <IconButton
              sx={{
                bgcolor: theme.colors?.utility[400],
                height: 20,
                width: 20,
                ':hover': {
                  bgcolor: theme.colors?.utility[400],
                },
              }}
            >
              <IconLinearAdd
                color={theme.colors?.utility[800]}
                size={16}
                style={{
                  flexShrink: 0,
                }}
              />
            </IconButton>
          )}
          options={options.map((option) => ({
            onClick: () => {
              option.onClick();
            },
            sx: {
              borderRadius: 3,
              flex: 1,
              cursor: 'pointer',
              padding: theme.spacing(3, 4),
              ':hover': {
                backgroundColor: `rgba(35, 6, 3, 0.1)`,
              },
            },
            renderOption: () => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Box
                  color={theme.colors?.utility[600]}
                  flex={1}
                  display="flex"
                  alignItems="center"
                  gap={2}
                >
                  <option.Icon size={16} color={theme.colors?.primary.black} />
                  <Typography
                    flex={1}
                    variant="subhead-md"
                    fontWeight={600}
                    color={theme.colors?.utility[900]}
                  >
                    {option.text}
                  </Typography>
                </Box>
              </Box>
            ),
          }))}
        />
      </Box>
    </>
  );
};
