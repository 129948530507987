import { Box, Button, Typography } from '@mui/material';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme';

export const SocialMediaListeningCommunityIntroPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setRenderTitle, setHeaderSx } = useCustomHeaderContext();

  useEffect(() => {
    setHeaderSx({
      borderBottom: `2px solid rgba(250, 243, 236, 0.25)`,
      color: `${theme.colors?.primary.parchment} !important`,
      backgroundColor: theme.colors?.utility['yellow-4'],
      '& svg': {
        color: theme.colors?.primary.parchment,
      },
    });

    setRenderTitle(() => (
      <Box
        component="button"
        onClick={() => navigate(-1)}
        display="flex"
        alignItems="center"
        gap={4}
        sx={{
          cursor: 'pointer',
          textAlign: 'left',
          overflow: 'hidden',
          flex: 1,
        }}
      >
        <IconOutlineArrowLeft
          size={24}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
        <Typography
          variant="headline-sm"
          color={theme.colors?.primary.parchment}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          Community Hub
        </Typography>
      </Box>
    ));

    return () => {
      setHeaderSx({});
      setRenderTitle(() => null);
    };
  }, [location.pathname]); // eslint-disable-line
  return (
    <Box
      sx={{
        pt: 18,
        px: 8,
        bgcolor: theme.colors?.utility['yellow-4'],
        height: 'calc(100vh - 100%)',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: { xs: 320, sm: 400, md: 400 },
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 66,
              position: 'absolute',
              top: '-16%',
              left: '86%',
              rotate: '6deg',
            }}
          >
            🥰
          </Box>
          <Box
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 74,
              position: 'absolute',
              top: '10%',
              transform: 'translate(-83%, 0)',
              rotate: '-20deg',
            }}
          >
            👀️
          </Box>
          <Box
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 65,
              position: 'absolute',
              bottom: 0,
              transform: 'translate(-80%, 18%)',
              rotate: '-13deg',
            }}
          >
            🌈
          </Box>
          <Box
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 66,
              position: 'absolute',
              right: '-20%',
              top: '50%',
              rotate: '-21deg',
              transform: `translate(0, -50%)`,
            }}
          >
            🤝
          </Box>

          <Box
            sx={{
              bgcolor: `rgba(35, 6, 3, 0.10)`,
              backdropFilter: 'blur(24px)',
              padding: 3,
              border: `1px solid rgba(35, 6, 3, 0.10)`,
              borderRadius: 11,
              boxShadow: `0px 0px 2px 0px rgba(0, 0, 0, 0.20) inset`,
            }}
          >
            <Box
              sx={{
                borderRadius: 10,
                padding: theme.spacing(12, 8),
                width: '100%',
                height: '100%',
                bgcolor: `rgba(35, 6, 3, 0.30)`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 3,
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  padding: theme.spacing(1.5, 3),
                  ...theme.typography['headline-xl'],
                  fontSize: 12,
                  color: theme.colors?.primary.parchment,
                  borderRadius: 25,
                  background: `rgba(255, 255, 255, 0.20)`,
                  width: 'fit-content',
                }}
              >
                Community Hub
              </Box>
              <Typography
                variant="headline-xl"
                fontSize={28}
                fontWeight={500}
                color={theme.colors?.primary.parchment}
                textAlign="center"
              >
                Turn your fans into superfans
              </Typography>
              <Typography
                variant="subhead-xl"
                color={theme.colors?.primary.parchment}
                textAlign="center"
              >
                One place to nurture your community and connect teams. Included
                in the enterprise plan—schedule a demo with sales.
              </Typography>
              <Box
                sx={{
                  display: 'grid',
                  mt: 5,
                  gap: 2,
                  width: '100%',
                }}
              >
                <Button
                  fullWidth
                  variant="primary-alt"
                  sx={{
                    borderRadius: 2,
                    py: 3,
                  }}
                  onClick={() => {
                    window.open(
                      'https://plotworkspace.typeform.com/plot-demo?typeform-source=www.plot.so',
                      '_blank',
                    );
                  }}
                >
                  Schedule demo
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
