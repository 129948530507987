import { Box, Chip, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCamera } from 'components/icons/components/bold/IconBoldCamera';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconCustomTodo } from 'components/icons/components/custom/IconCustomTodo';
import { IconLinearMessage } from 'components/icons/components/linear/IconLinearMessage';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { IconLinearUser } from 'components/icons/components/linear/IconLinearUser';
import {
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import { eventTypeLabelMap, eventTypeTooltipMap } from 'features/radarEvent';
import { RelationshipLabelMap } from 'features/socialListeningCommunity';
import { platformLabelMap } from 'features/socialPost';
import {
  CreatorProfileRelationshipStrength,
  PaginatedRadarEventInput,
  Platform,
  RadarEventType,
  useGetPaginatedOrganizationCreatorProfileLabelsForSlCommunityPageFiltersLazyQuery,
} from 'graphql/generated';
import { useLocalStorage } from 'hooks/localStorage/useLocalStorage';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
import * as yup from 'yup';

export type NestedFiltersValues = {
  relationshipsGroup?: {
    relationships: NestedFiltersMenuItemBaseValueType<
      NestedFiltersOptionType[]
    >;
  };
  labelsGroup?: {
    labels: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
  };
  eventTypesGroup?: {
    eventTypes: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
  };
  platformsGroup?: {
    platforms: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
  };
  followersGroup?: {
    followers: NestedFiltersMenuItemBaseValueType<{
      min?: string;
      max?: string;
    }>;
  };
};

export type FiltersProps = {
  setFilters: (
    filters: Pick<
      PaginatedRadarEventInput,
      | 'relationshipStrength'
      | 'creatorProfileLabelIds'
      | 'platforms'
      | 'eventTypes'
      | 'followersCount'
    >,
  ) => void;
  brandId: string;
};

const SOCIAL_LISTENING_RADAR_FILTERS = 'social-listening-radar-filters';

export const Filters = (props: FiltersProps) => {
  const { setFilters: _setFilters, brandId } = props;

  const { value: filters, setValue: setFilters } =
    useLocalStorage<NestedFiltersValues>(
      SOCIAL_LISTENING_RADAR_FILTERS + brandId,
      {},
      yup
        .object()
        .shape({
          relationshipsGroup: yup
            .object()
            .shape({
              relationships: yup
                .object()
                .shape({
                  value: yup
                    .array()
                    .of(
                      yup
                        .mixed<CreatorProfileRelationshipStrength>()
                        .oneOf(
                          Object.values(CreatorProfileRelationshipStrength),
                        ),
                    )
                    .optional(),
                })
                .optional(),
            })
            .optional(),
          labelsGroup: yup
            .object()
            .shape({
              labels: yup
                .object()
                .shape({
                  value: yup
                    .array()
                    .of(
                      yup.object().shape({
                        label: yup.string().required(),
                        value: yup.string().required(),
                      }),
                    )
                    .optional(),
                })
                .optional(),
            })
            .optional(),
          eventTypesGroup: yup
            .object()
            .shape({
              eventTypes: yup
                .object()
                .shape({
                  value: yup
                    .array()
                    .of(
                      yup
                        .mixed<RadarEventType>()
                        .oneOf(Object.values(RadarEventType)),
                    )
                    .optional(),
                })
                .optional(),
            })
            .optional(),
          platformsGroup: yup
            .object()
            .shape({
              platforms: yup
                .object()
                .shape({
                  value: yup
                    .array()
                    .of(yup.mixed<Platform>().oneOf(Object.values(Platform)))
                    .optional(),
                })
                .optional(),
            })
            .optional(),
          followersGroup: yup
            .object()
            .shape({
              followers: yup
                .object()
                .shape({
                  value: yup
                    .object()
                    .shape({
                      min: yup.string().optional(),
                      max: yup.string().optional(),
                    })
                    .optional(),
                })
                .optional(),
            })
            .optional(),
        })
        .noUnknown(),
    );

  useEffect(() => {
    _setFilters({
      relationshipStrength: (
        filters.relationshipsGroup?.relationships.value ?? []
      ).map((v) => v.value) as CreatorProfileRelationshipStrength[],
      creatorProfileLabelIds: (filters.labelsGroup?.labels.value ?? []).map(
        (v) => v.value,
      ),
      eventTypes: (filters.eventTypesGroup?.eventTypes.value ?? []).map(
        (v) => v.value,
      ) as RadarEventType[],
      platforms: (filters.platformsGroup?.platforms.value ?? []).map(
        (v) => v.value,
      ) as Platform[],
      followersCount: {
        min: filters.followersGroup?.followers.value?.min
          ? Number(filters.followersGroup?.followers.value?.min)
          : undefined,
        max: filters.followersGroup?.followers.value?.max
          ? Number(filters.followersGroup?.followers.value?.max)
          : undefined,
      },
    });
  }, [filters]); // eslint-disable-line

  const [getLabels] =
    useGetPaginatedOrganizationCreatorProfileLabelsForSlCommunityPageFiltersLazyQuery();

  return (
    <NestedFiltersMenuView
      isContextSource
      showSelectedFilterCount
      values={filters}
      onChange={(_values) => {
        setFilters((o) => {
          return {
            ...o,
            ..._values,
          };
        });
      }}
      items={[
        {
          type: 'group',
          key: 'relationshipsGroup',
          label: 'Relationships',
          Icon: IconCustomTodo,
          items: [
            {
              type: 'multi-select',
              key: 'relationships',
              options: [
                CreatorProfileRelationshipStrength.VeryStrong,
                CreatorProfileRelationshipStrength.Strong,
                CreatorProfileRelationshipStrength.Neutral,
                CreatorProfileRelationshipStrength.Weak,
                CreatorProfileRelationshipStrength.VeryWeak,
              ].map((value) => ({
                label: RelationshipLabelMap[value],
                value,
              })),
            },
          ],
        },
        {
          type: 'group',
          key: 'labelsGroup',
          label: 'Labels',
          Icon: IconLinearNote,
          items: [
            {
              type: 'multi-select',
              key: 'labels',
              options: [],
              filterable: true,
              getAsyncOptions: async ({ query, nextPageCursor }) => {
                const labelsData = await getLabels({
                  variables: {
                    input: {
                      brandId,
                      search: query,
                      after: nextPageCursor,
                    },
                  },
                  fetchPolicy: 'network-only',
                });
                const labels =
                  labelsData?.data?.paginatedOrganizationCreatorProfileLabels
                    .data || [];
                const endCursor = labelsData?.data
                  ?.paginatedOrganizationCreatorProfileLabels.pageInfo
                  ?.hasNextPage
                  ? labelsData?.data?.paginatedOrganizationCreatorProfileLabels
                      .pageInfo?.endCursor ?? ''
                  : undefined;

                return {
                  options: labels.map((label) => ({
                    label: label.label,
                    value: label.id,
                  })),
                  nextPageCursor: endCursor,
                };
              },
            },
          ],
        },
        {
          type: 'group',
          key: 'eventTypesGroup',
          label: 'Events',
          Icon: IconLinearMessage,
          items: [
            {
              type: 'multi-select',
              key: 'eventTypes',
              options: [
                RadarEventType.Ugc,
                RadarEventType.Comment,
                RadarEventType.Engagement,
                RadarEventType.Moment,
              ].map((value) => ({
                label: eventTypeLabelMap[value],
                value,
                ...(value === RadarEventType.Engagement
                  ? {
                      renderLabel: () => (
                        <Typography
                          variant="headline-xs"
                          color={theme.colors?.utility[900]}
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          {eventTypeLabelMap[value]}
                          <Tooltip title={eventTypeTooltipMap[value]}>
                            <Box sx={{ display: 'inline-flex' }}>
                              <IconBoldInfoCircle
                                size={16}
                                color={theme.colors?.utility[700]}
                              />
                            </Box>
                          </Tooltip>
                        </Typography>
                      ),
                    }
                  : {}),
                ...(value === RadarEventType.Moment
                  ? {
                      renderLabel: () => (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="headline-xs"
                            color={theme.colors?.utility[900]}
                          >
                            {eventTypeLabelMap[value]}
                          </Typography>
                          <Chip
                            variant="filled-borderless"
                            size="small"
                            sx={{
                              bgcolor: theme.colors?.utility['yellow-1'],
                              color: theme.colors?.utility['yellow-3'],
                              span: {
                                fontWeight: 600,
                              },
                            }}
                            icon={
                              <IconBoldCamera
                                size={16}
                                style={{
                                  color: theme.colors?.utility['yellow-3'],
                                }}
                              />
                            }
                            label="Peeking"
                          />
                        </Box>
                      ),
                    }
                  : {}),
              })),
            },
          ],
        },
        {
          type: 'group',
          key: 'platformsGroup',
          label: 'Platforms',
          Icon: IconLinearMonitorMobile,
          items: [
            {
              type: 'multi-select',
              key: 'platforms',
              options: [
                Platform.Instagram,
                Platform.Tiktok,
                Platform.Youtube,
              ].map((value) => ({
                label: platformLabelMap[value],
                value,
              })),
            },
          ],
        },
        {
          type: 'group',
          key: 'followersGroup',
          label: 'Followers',
          Icon: IconLinearUser,
          items: [
            {
              type: 'form',
              key: 'followers',
              fields: [
                {
                  type: 'number',
                  key: 'min',
                  label: 'Mininum number of followers',
                },
                {
                  type: 'number',
                  key: 'max',
                  label: 'Maximum number of followers',
                },
              ],
            },
          ],
        },
      ]}
    />
  );
};
