import { expose } from 'comlink';
import { PostModel } from 'graphql/generated';
import JSZip from 'jszip';
import { mime2ext } from 'utils/mime';

export type PostDownloadWorker = {
  download: (
    posts: {
      id: string;
      urlMetadata: Pick<PostModel['urlMetadata'][0], 'url' | 'metadata'>;
    }[],
  ) => Promise<{
    blob: Blob;
    errors: string[];
    canDownload: boolean;
  }>;
  downloadSinglePost: (post: {
    id: string;
    urlMetadata: Pick<PostModel['urlMetadata'][0], 'url' | 'metadata'>;
  }) => Promise<{
    blob: Blob | null;
    error: string;
    canDownload: boolean;
    name: string;
  }>;
  downloads: (
    posts: {
      id: string;
      urlMetadata: Pick<PostModel['urlMetadata'][0], 'url' | 'metadata'>;
    }[],
  ) => Promise<{
    blob: Blob;
    errors: string[];
    canDownload: boolean;
  }>;
};

const worker: PostDownloadWorker = {
  download: async (posts) => {
    const zip = new JSZip();
    const errors: string[] = [];
    let canDownload = false;

    // Loop through the array of objects
    for (const post of posts) {
      const urlMetadataItem = post.urlMetadata?.[0];

      if (urlMetadataItem) {
        const { metadata } = urlMetadataItem;
        const url = urlMetadataItem.url;

        // Skip if we have no type from metadata. That means the post is likely not downloadable
        if (!metadata?.type && !metadata?.medias?.length) {
          continue;
        }

        const urls = urlMetadataItem.metadata?.medias?.length
          ? urlMetadataItem.metadata?.medias
          : [
              {
                url,
                type: metadata?.type,
              },
            ];

        for (const { url, type } of urls) {
          try {
            // eslint-disable-next-line
            const response = await fetch(url);

            if (!response.ok) {
              throw new Error(`Cannot fetch url ${url}`);
            }

            // eslint-disable-next-line
            const blob = await response.blob();

            // Extract the filename as the last fragment of the url, or default to post id
            const filename = url.split('/').pop() || post.id;

            // Check if filename already has an extension.
            // If it does, use it. Otherwise, try to guess the extension from the mime type.
            const filenameContainsExt = filename.includes('.');

            // Get the mime type from the metadata and GUESS the file extension.
            // NOTE that we are just guessing, e.g. if it's application/octet-stream, it can be anything.
            const extFromMimeType =
              type === 'video'
                ? 'mp4'
                : type === 'image'
                ? 'jpg'
                : mime2ext(type || '');

            if (extFromMimeType || filenameContainsExt) {
              zip.file(
                `${filename}${
                  !filenameContainsExt ? `.${extFromMimeType}` : ''
                }`,
                blob,
              );
              canDownload = true;
            } else {
              errors.push(
                `Unknown mime type or extension for ${url}: ${
                  metadata?.type || ''
                }`,
              );
            }
          } catch (error: any) {
            errors.push(error.message);
          }
        }
      }
    }

    // Return the zip file as a blob
    return {
      blob: await zip.generateAsync({ type: 'blob' }),
      errors,
      canDownload,
    };
  },
  downloadSinglePost: async (post) => {
    const { urlMetadata } = post;
    let error: string = '';
    let canDownload = false;
    let blob: Blob | null = null;
    let name = '';

    if (urlMetadata) {
      const { metadata } = urlMetadata;
      const url = urlMetadata.url;

      if (!metadata?.type && !metadata?.medias?.length && !url) {
        return {
          blob,
          error: 'No downloadable content found',
          canDownload,
          name,
        };
      }

      const urls = metadata?.medias?.length
        ? metadata?.medias
        : [{ url, type: metadata?.type }];

      for (const { url, type: fileType } of urls) {
        try {
          let type = fileType;
          // eslint-disable-next-line
          const response = await fetch(url);

          if (!response.ok) {
            throw new Error(`Cannot fetch url ${url}`);
          } else {
            type = response.headers.get('Content-Type');
          }
          // eslint-disable-next-line
          const blobResponse = await response.blob();
          blob = blobResponse;

          const extFromMimeType =
            type === 'video'
              ? 'mp4'
              : type === 'image'
              ? 'jpg'
              : mime2ext(type || '');

          const filename = url.split('/').pop()?.split('?')?.[0] || post.id;
          const filenameContainsExt = filename.includes('.');
          name = `${filename}${
            !filenameContainsExt ? `.${extFromMimeType}` : ''
          }`;

          if (extFromMimeType) {
            canDownload = true;
          } else {
            error = `Unknown mime type or extension for ${url}: ${
              metadata?.type || ''
            }`;
          }
        } catch (err: any) {
          error = err.message;
        }
      }
    }

    return { blob, error, canDownload, name };
  },
  downloads: async (
    posts: {
      id: string;
      urlMetadata: Pick<PostModel['urlMetadata'][0], 'url' | 'metadata'>;
    }[],
  ) => {
    const results = await Promise.all(
      posts.map((post) => worker.downloadSinglePost(post)),
    );

    const successfulDownloads = results.filter((result) => result.canDownload);
    const errors = results
      .filter((result) => result.error)
      .map((result) => result.error);

    if (successfulDownloads.length === 0) {
      return {
        blob: new Blob(),
        errors,
        canDownload: false,
      };
    }

    const zip = new JSZip();
    successfulDownloads.forEach((result) => {
      if (result.blob && result.name) {
        zip.file(result.name, result.blob);
      }
    });

    return {
      blob: await zip.generateAsync({ type: 'blob' }),
      errors,
      canDownload: true,
    };
  },
};

expose(worker);
