import { gql } from '@apollo/client';
import { AnalyticsBrowser } from '@segment/analytics-next';
import * as Sentry from '@sentry/browser';
import { useAnalyticsContext } from 'contexts/Analytic.context';
import {
  MeFragmentUserQueriesFragment,
  useTrackEventForAnalyticsMutation,
} from 'graphql/generated';
import { useEffect, useMemo } from 'react';

// eslint-disable-next-line
gql`
  mutation TrackEventForAnalytics($data: TrackEventInput!) {
    trackEvent(data: $data) {
      success
    }
  }
`;

export const EventName = {
  AppError: 'AppError',
  FilterRequest: 'FilterRequest',
  FilterTask: 'FilterTask',
  SearchBarOpen: 'SearchBarOpen',
  SearchBarResultClick: 'SearchBarResultClick',
  PaywallHit: 'PaywallHit',
  PlanOptionsViewed: 'PlanOptionsViewed',
  PlanSelected: 'PlanSelected',
  PriorityTaskAdded: 'PriorityTaskAdded',
  PriorityTaskCompleted: 'PriorityTaskCompleted',
  PriorityTaskDeliverableUploaded: 'PriorityTaskDeliverableUploaded',
  PriorityTaskDelightfulModalViewed: 'PriorityTaskDelightfulModalViewed',
  NewTaskShortCutOpened: 'NewTaskShortCutOpened',
  CreativeJuiceboxPageOpened: 'CreativeJuiceboxPageOpened',
  NotePostOpened: 'NotePostOpened',
  JuiceboxSidebarOpened: 'JuiceboxSidebarOpened',
  UploadFilePostOpened: 'UploadFilePostOpened',
  LinkPostOpened: 'LinkPostOpened',
  PhoneNumberProvided: 'PhoneNumberProvided',
  NewRequestShortCutOpened: 'NewRequestShortCutOpened',
  CJBSearchStarted: 'CJBSearchStarted',
  SuggestedCollectionSelected: 'SuggestedCollectionSelected',
  SuggestedPostAdded: 'SuggestedPostAdded',
  PostMultiSelectEntered: 'PostMultiSelectEntered',
  PostMultiSelectExited: 'PostMultiSelectExited',
  FEPostCreation: 'FEPostCreation',
  PostSearchResultClicked: 'PostSearchResultClicked',
  CollectionSearchResultClicked: 'CollectionSearchResultClicked',
  OnboardingCompleted: 'OnboardingCompleted',
  CheckoutPageViewed: 'CheckoutPageViewed',
  TrendDetailViewed: 'TrendDetailViewed',
  TrialWrappedViewed: 'TrialWrappedViewed',
  PostAIOrganizeStart: 'PostAIOrganizeStart',
  PostAIOrganizeCompleted: 'PostAIOrganizeCompleted',
  SocialListeningDashboardViewed: 'SocialListeningDashboardViewed',
  SocialListeningPostViewed: 'SocialListeningPostViewed',
  SocialListeningPostStatusUpdated: 'SocialListeningPostStatusUpdated',
  SocialListeningPostCommentPosted: 'SocialListeningPostCommentPosted',
  SocialListeningOverageChargesAgreed: 'SocialListeningOverageChargesAgreed',
  CommunityUpdatesViewed: 'CommunityUpdatesViewed',
  CommunityUpdatesFilterApplied: 'CommunityUpdatesFilterApplied',
  CommunityUpdatesPostClicked: 'CommunityUpdatesPostClicked',
  CommunityUpdatesCreatorClicked: 'CommunityUpdatesCreatorClicked',
  CommunityUpdatesActivityAdded: 'CommunityUpdatesActivityAdded',
  CommunityUpdatesActivityReused: 'CommunityUpdatesActivityReused',
  CrmDashboardViewed: 'CrmDashboardViewed',
  CrmDashboardSortApplied: 'CrmDashboardSortApplied',
  CrmDashboardFilterApplied: 'CrmDashboardFilterApplied',
  CrmDashboardSearchUsed: 'CrmDashboardSearchUsed',
  CrmDashboardCreatorClicked: 'CrmDashboardCreatorClicked',
  CrmDashboardCommunityUpdatesClicked: 'CrmDashboardCommunityUpdatesClicked',
  CrmDashboardCreatorPeeked: 'CrmDashboardCreatorPeeked',
  CrmDashboardLabelUpdated: 'CrmDashboardLabelUpdated',
  CrmDashboardActivityAdded: 'CrmDashboardActivityAdded',
  CrmDashboardActivityReused: 'CrmDashboardActivityReused',
  CreatorProfilePeeked: 'CreatorProfilePeeked',
  CreatorProfileLabelUpdated: 'CreatorProfileLabelUpdated',
  CreatorProfileSocialAccountUpdated: 'CreatorProfileSocialAccountUpdated',
  CreatorProfileMergeTriggered: 'CreatorProfileMergeTriggered',
  CreatorProfileMergeDecision: 'CreatorProfileMergeDecision',
  CreatorProfileTabSwitched: 'CreatorProfileTabSwitched',
  CreatorProfileActivityViewed: 'CreatorProfileActivityViewed',
  CreatorProfileActivityAdded: 'CreatorProfileActivityAdded',
  CreatorProfileActivityPostClicked: 'CreatorProfileActivityPostClicked',
  CreatorProfileAboutViewed: 'CreatorProfileAboutViewed',
  CreatorProfileAboutTileClicked: 'CreatorProfileAboutTileClicked',
  CreatorProfileAboutPostClicked: 'CreatorProfileAboutPostClicked',
  CreatorProfileAboutFilterApplied: 'CreatorProfileAboutFilterApplied',
  ActivityChannelSelected: 'ActivityChannelSelected',
  ActivityAssetAdded: 'ActivityAssetAdded',
  CommunityHubSettingsViewed: 'CommunityHubSettingsViewed',
  CommunityHubSettingsUpdated: 'CommunityHubSettingsUpdated',
};

export const useAnalytics = () => {
  const result = useAnalyticsContext();
  const [trackEvent] = useTrackEventForAnalyticsMutation();
  const updatedResult = useMemo(() => {
    if (!result) {
      return undefined;
    }

    return {
      ...result,
      track: (eventName: string, properties?: object) => {
        return result.track
          ? result.track(eventName, properties)
          : trackEvent({
              variables: {
                data: {
                  eventName,
                  data: properties,
                },
              },
            });
      },
    };
  }, [result]); // eslint-disable-line

  if (!updatedResult) {
    console.log('Context used outside of its Provider!');
    return {} as AnalyticsBrowser;
  }
  return updatedResult;
};

export const usePageAnalytics = (name: string, options?: object) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.page(name, options);
  }, []); // eslint-disable-line -- run once on mount
};

export const useUserAnalytics = (
  user: MeFragmentUserQueriesFragment | null,
) => {
  const analytics = useAnalytics();

  if (!user) {
    return;
  }

  // Analytics only - Track current user
  Sentry.setUser({ email: user.email });
  analytics.identify(user.id, {
    id: user.id,
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    role: user.role,
    fields: user.fields,
    title: user.title,
    company: {
      id: user.organization?.id,
      name: user.organization?.name,
    },
    taskCreatedCount: user.taskCreatedCount,
    invitedByOrganization: user.invitedByOrganization?.name,
    invitedByUser: user.invitedByUser?.email,
    createdAt: user.createdAt,
    postCreatedCount: user.postCreatedCount,
    phone: user.phoneNumber,
    joinedOrganizationIds: user.joinedOrganizations.map((org) => org.id),
    cohorts: user.cohorts,
    plan: user.plan,
  });

  analytics.group(user.organization?.id, {
    name: user.organization?.name,
    plan: user.organization.billing.plan,
    totalUsers: user.organization.users?.length,
  });
};
