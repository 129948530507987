import { Sentiment } from 'graphql/generated';
import { theme } from 'styles/theme';

export const SentimentBackgroundColorMap = {
  [Sentiment.Positive]: 'rgba(224, 228, 202, 0.50)',
  [Sentiment.Neutral]: 'rgba(217, 227, 243, 0.50)',
  [Sentiment.Negative]: 'rgba(246, 225, 230, 0.50)',
};

export const SentimentColorMap = {
  [Sentiment.Positive]: theme.colors?.utility['green-4'],
  [Sentiment.Neutral]: theme.colors?.utility['blue-4'],
  [Sentiment.Negative]: theme.colors?.utility['pink-4'],
};

export const SentimentLabelMap = {
  [Sentiment.Positive]: 'Positive',
  [Sentiment.Neutral]: 'Neutral',
  [Sentiment.Negative]: 'Negative',
};
