import { gql } from '@apollo/client';
import { Avatar, Box, Typography } from '@mui/material';
import { CollapsibleSection } from 'components/common/CollapsibleSection';
import { IconCustomSLHorn } from 'components/icons/components/custom/IconCustomSLHorn';
import { IconLinearSaveAdd } from 'components/icons/components/linear/IconLinearSaveAdd';
import {
  ActivityEntity,
  ActivityFragmentForFrequentlyVisitedPagesFragment,
  ActivityFragmentForFrequentlyVisitedPagesFragmentDoc,
  BrandFragmentForFrequentlyVisitedPagesFragment,
  BrandFragmentForFrequentlyVisitedPagesFragmentDoc,
  useGetFrequentlyViewedEntitiesForFrequentlyVisitedPagesQuery,
} from 'graphql/generated';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { useLeftDrawerContext } from '../../contexts';
import { TabName } from '../../types';

// eslint-disable-next-line
gql`
  fragment BrandFragmentForFrequentlyVisitedPages on BrandModel {
    id
    name
    creators {
      id
      profilePictureUrl
    }
  }
`;

// eslint-disable-next-line
gql`
  fragment ActivityFragmentForFrequentlyVisitedPages on ActivityModel {
    id
    entity
    brand {
      ...BrandFragmentForFrequentlyVisitedPages
    }
    competitor {
      id
      name
      forBrand {
        ...BrandFragmentForFrequentlyVisitedPages
      }
    }
    collection {
      id
      name
      parentCollection {
        id
        name
      }
    }
  }
  ${BrandFragmentForFrequentlyVisitedPagesFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetFrequentlyViewedEntitiesForFrequentlyVisitedPages {
    frequentlyViewedEntities {
      ...ActivityFragmentForFrequentlyVisitedPages
    }
  }
  ${ActivityFragmentForFrequentlyVisitedPagesFragmentDoc}
`;

export const FrequentlyVisitedPages = () => {
  const { data } =
    useGetFrequentlyViewedEntitiesForFrequentlyVisitedPagesQuery();
  const activities = data?.frequentlyViewedEntities || [];

  const {
    onAddDrawerOpenedState,
    onRemoveDrawerOpenedState,
    drawerOpenedState,
  } = useLeftDrawerContext();

  const renderActivityItemIcon = (
    activity: ActivityFragmentForFrequentlyVisitedPagesFragment,
  ) => {
    switch (activity.entity) {
      case ActivityEntity.Collection:
        return (
          <IconLinearSaveAdd size={16} color={theme.colors?.utility[700]} />
        );
      default:
        return (
          <IconCustomSLHorn size={16} color={theme.colors?.utility[700]} />
        );
    }
  };

  const renderTitle = (
    activity: ActivityFragmentForFrequentlyVisitedPagesFragment,
  ) => {
    switch (activity.entity) {
      case ActivityEntity.Collection:
        return activity.collection?.name;
      case ActivityEntity.SocialListeningEngagement:
        return 'Engagement';
      case ActivityEntity.BrandAnalytics:
        return 'Brand Analytics';
      case ActivityEntity.BrandCompetitorAnalytics:
        return 'Competitor Analytics';
      case ActivityEntity.BrandCommunityHub:
        return 'Your Community';
      case ActivityEntity.Competitor:
        return activity.competitor?.name;
      default:
        return '';
    }
  };
  const renderBrandAvatar = (
    activity: ActivityFragmentForFrequentlyVisitedPagesFragment,
  ) => {
    let brand: BrandFragmentForFrequentlyVisitedPagesFragment | null = null;
    switch (activity.entity) {
      case ActivityEntity.SocialListeningEngagement:
      case ActivityEntity.BrandAnalytics:
      case ActivityEntity.BrandCompetitorAnalytics:
      case ActivityEntity.BrandCommunityHub:
        brand = activity.brand ?? null;
        break;
      case ActivityEntity.Competitor:
        brand = activity.competitor?.forBrand ?? null;
        break;
      default:
        break;
    }
    if (!brand) return '';
    return (
      <Avatar
        src={
          brand.creators?.[0]?.profilePictureUrl ||
          brand.creators?.[1]?.profilePictureUrl ||
          ''
        }
        variant="circular"
        sx={{
          width: 15,
          height: 15,
        }}
      />
    );
  };

  const renderSubtitle = (
    activity: ActivityFragmentForFrequentlyVisitedPagesFragment,
  ) => {
    switch (activity.entity) {
      case ActivityEntity.Collection:
        return (
          activity.collection?.parentCollection?.name ?? 'Creative Juicebox'
        );
      case ActivityEntity.SocialListeningEngagement:
      case ActivityEntity.BrandAnalytics:
      case ActivityEntity.BrandCompetitorAnalytics:
      case ActivityEntity.BrandCommunityHub:
        return `· ${activity.brand?.name}`;
      case ActivityEntity.Competitor:
        return `· ${activity.competitor?.forBrand?.name} · Competitor analytics`;
      default:
        return '';
    }
  };

  const onNavigateToItem = (
    activity: ActivityFragmentForFrequentlyVisitedPagesFragment,
  ) => {
    switch (activity.entity) {
      case ActivityEntity.Collection:
        window.open(PlotRoutes.collection(activity.collection?.id!), '_blank');
        break;
      case ActivityEntity.SocialListeningEngagement:
        window.open(
          PlotRoutes.socialListeningForBrand(activity.brand?.id!),
          '_blank',
        );
        break;
      case ActivityEntity.BrandAnalytics:
        window.open(
          PlotRoutes.socialListeningAnalyticsInbound(activity.brand?.id!),
          '_blank',
        );
        break;
      case ActivityEntity.BrandCompetitorAnalytics:
        window.open(
          PlotRoutes.socialListeningCompetitors(activity.brand?.id!),
          '_blank',
        );
        break;
      case ActivityEntity.Competitor:
        window.open(
          PlotRoutes.socialListeningCompetitor(
            activity.competitor?.id!,
            activity.competitor?.forBrand?.id!,
          ),
          '_blank',
        );
        break;
      case ActivityEntity.BrandCommunityHub:
        window.open(
          PlotRoutes.socialListeningCommunity(activity.brand?.id!),
          '_blank',
        );
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        '& .MuiDivider-root': {
          mt: 3,
          mb: 2,
        },
      }}
    >
      <CollapsibleSection
        headerTitle="Frequently visited"
        headerTitleClass="frequently-visited-sidebar-header"
        headerFullWidth
        sx={{
          p: 2,
          borderRadius: 2,
          ':hover': {
            background: 'rgba(35, 6, 3, 0.05)',
          },
          '.frequently-visited-sidebar-header': {
            color: theme.colors?.utility[800],
            ...theme.typography['headline-xs'],
          },
        }}
        iconColor={theme.colors?.utility[600]}
        defaultIsOpen={drawerOpenedState.includes(TabName.FREQUENTLY_VISITED)}
        onStateUpdated={(isOpen) => {
          if (isOpen) {
            onAddDrawerOpenedState(TabName.FREQUENTLY_VISITED);
          } else {
            onRemoveDrawerOpenedState(TabName.FREQUENTLY_VISITED);
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {activities.map((activity) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                p: 2,
                borderRadius: 2,
                gap: 3,
                cursor: 'pointer',
                ':hover': {
                  background: 'rgba(35, 6, 3, 0.05)',
                },
              }}
              onClick={() => onNavigateToItem(activity)}
            >
              <Box mt={0.5}>{renderActivityItemIcon(activity)}</Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography
                  variant="subhead-lg"
                  color={theme.colors?.utility[800]}
                  sx={{
                    '-webkit-line-clamp': '1',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    width: '100%',
                    wordBreak: 'break-word',
                  }}
                >
                  {renderTitle(activity)}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  {renderBrandAvatar(activity)}
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.utility[600]}
                    sx={{
                      '-webkit-line-clamp': '1',
                      overflow: 'hidden',
                      display: '-webkit-box',
                      '-webkit-box-orient': 'vertical',
                      width: '100%',
                    }}
                  >
                    {renderSubtitle(activity)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </CollapsibleSection>
    </Box>
  );
};
