import { MenuItem, Select, Typography, selectClasses } from '@mui/material';
import { PlaybackSpeed } from 'components/common/Annotation/types';
import { IconBoldArrowDown } from 'components/icons/components/bold/IconBoldArrowDown';
import { theme } from 'styles/theme/theme';

interface Props {
  playbackSpeed: number;
  onPlaybackSpeedChanged: React.Dispatch<React.SetStateAction<number>>;
}

export const PlaybackSpeedSelect = ({
  onPlaybackSpeedChanged,
  playbackSpeed,
}: Props) => {
  return (
    <Select
      value={playbackSpeed}
      onChange={(e) => {
        onPlaybackSpeedChanged(e.target.value as number);
      }}
      renderValue={(value) => (
        <Typography
          variant="headline-sm"
          fontWeight={600}
          color={theme.colors?.primary.white}
        >
          {`${value}x`}
        </Typography>
      )}
      size="small"
      sx={{
        '& .MuiSelect-select': {
          padding: '0 4px !important',
        },
        [`svg.${selectClasses.iconOutlined}`]: {
          color: theme.colors?.primary.white,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `1px solid transparent`,
        },
        '.MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
      }}
      IconComponent={() => (
        <IconBoldArrowDown
          size={10}
          color={theme.colors?.primary.white}
          style={{
            flexShrink: 0,
          }}
        />
      )}
      MenuProps={{
        PaperProps: {
          sx: {
            boxShadow: ` 0px 3px 12px -2px rgba(0, 0, 0, 0.1)`,
            borderRadius: 2,
            width: 160,
            color: theme.colors?.utility[900],
            paddingX: 4,
            '& .Mui-selected': {
              backgroundColor: `${theme.colors?.utility[275]} !important`,
              '&:hover': {
                backgroundColor: theme.colors?.utility[275],
              },
            },
            '& li': {
              width: '100%',
              marginTop: 1,
              fontSize: 14,
              fontWeight: 600,
              fontFamily: 'TT Commons Pro',
              '&:hover': {
                backgroundColor: theme.colors?.utility[275],
              },
            },
          },
        },
      }}
    >
      {PlaybackSpeed.map((speed) => (
        <MenuItem key={`playback-speed-${speed}`} value={speed}>
          {speed}x
        </MenuItem>
      ))}
    </Select>
  );
};
