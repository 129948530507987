import { gql } from '@apollo/client';
import { Avatar, Box, IconButton, SxProps, Typography } from '@mui/material';
import { IconBoldPlay } from 'components/icons/components/bold/IconBoldPlay';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { getCreatorHandleWithPrefix } from 'features/creator';
import { useSocialListeningPostLinkGenerator } from 'features/socialMediaListening';
import { HoverPlayableVideo } from 'features/socialMediaListening/views/postsGrid/postCard/HoverPlayableVideo';
import { StaticImage } from 'features/socialMediaListening/views/postsGrid/postCard/StaticImage';
import { platformIconMap } from 'features/socialPost/constants';
import {
  SocialPostFragmentSocialPostCardViewFragment,
  SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc,
  SocialPostType,
} from 'graphql/generated';
import { useCallback } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

// eslint-disable-next-line
gql`
  fragment SocialPostFragmentSocialPostCardView on SocialPostModel {
    id
    platform
    thumbnailUrl
    videoUrl
    type
    playCount
    creator {
      id
      handle
      profilePictureUrl
    }
    ...SocialPostFragmentUseSocialListeningPostLinkGenerator
  }
  ${SocialPostFragmentUseSocialListeningPostLinkGeneratorFragmentDoc}
`;

export type SocialPostCardViewProps = {
  socialPost: SocialPostFragmentSocialPostCardViewFragment;
  sx?: SxProps;
};

export const SocialPostCardView = (props: SocialPostCardViewProps) => {
  const { socialPost, sx } = props;

  const { generateLink } = useSocialListeningPostLinkGenerator();

  const renderActionsTopRight = useCallback(
    (isHovered: boolean) => {
      const Icon = platformIconMap[socialPost.platform];
      return (
        <Box
          display="flex"
          gap={2}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {isHovered && socialPost.creator?.handle && (
            <IconButton
              disableRipple
              sx={{
                p: 1,
                backgroundColor: 'rgba(35, 6, 3, 0.10)',
                color: theme.colors?.primary.white,
                backdropFilter: 'blur(4px)',
              }}
              onClick={() => {
                window.open(generateLink(socialPost) || '', '_blank');
              }}
            >
              <IconLinearExport2 size={16} />
            </IconButton>
          )}
          {Icon && (
            <Icon
              size={16}
              style={{
                color: theme.colors?.primary.white,
              }}
            />
          )}
        </Box>
      );
    },
    [socialPost, generateLink],
  );

  const renderActionsBottom = useCallback(
    () => (
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          ml={2}
          width="70%"
          maxWidth="70%"
        >
          <Avatar
            src={socialPost.creator?.profilePictureUrl || ''}
            sx={{
              width: theme.spacing(4),
              height: theme.spacing(4),
            }}
          />
          <Typography
            color={theme.colors?.primary.parchment}
            variant="body-lg"
            fontWeight={600}
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {getCreatorHandleWithPrefix(socialPost.creator?.handle ?? '')}
          </Typography>
        </Box>

        {socialPost.type === SocialPostType.Video && (
          <Box mr={2} display="flex" gap={1} alignItems="center">
            <IconBoldPlay size={16} color={theme.colors?.primary.parchment} />
            <Typography
              color={theme.colors?.primary.parchment}
              variant="body-lg"
              fontWeight={600}
            >
              {formatBigNumber(socialPost.playCount || 0)}
            </Typography>
          </Box>
        )}
      </Box>
    ),
    [socialPost],
  );

  return (
    <Box
      sx={{
        position: 'relative',
        ...sx,
      }}
    >
      {socialPost.type === SocialPostType.Video ? (
        <HoverPlayableVideo
          thumbnailUrl={socialPost.thumbnailUrl || ''}
          videoUrl={socialPost.videoUrl || ''}
          renderActionsTopRight={renderActionsTopRight}
          renderActionsBottom={renderActionsBottom}
        />
      ) : (
        <StaticImage
          thumbnailUrl={socialPost.thumbnailUrl || ''}
          renderActionsTopRight={renderActionsTopRight}
          renderActionsBottom={renderActionsBottom}
        />
      )}
    </Box>
  );
};
