import { gql } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldCamera } from 'components/icons/components/bold/IconBoldCamera';
import { IconBoldCircleArrowRight } from 'components/icons/components/bold/IconBoldCircleArrowRight';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearCamera } from 'components/icons/components/linear/IconLinearCamera';
import { useUserContext } from 'contexts/users/User.context/User.context';
import { getCreatorHandleWithPrefix } from 'features/creator';
import { SocialListeningLabelSelector } from 'features/socialListeningCommunity/components';
import {
  RelationshipBackgroundColorMap,
  RelationshipColorMap,
  RelationshipLabelMap,
  SentimentBackgroundColorMap,
  SentimentColorMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity/constants';
import { SocialListeningRadarAddActivityButtonView } from 'features/socialListeningRadar';
import { ExpandableText } from 'features/socialMediaListening';
import { useSocialListeningCreatorLinkGenerator } from 'features/socialMediaListening/hooks/useSocialListeningCreatorLinkGenerator';
import { platformIconMap } from 'features/socialPost';
import {
  CreatorProfileFragmentSlCommunityTableFragment,
  CreatorProfileFragmentSlPeekCreatorDialogViewFragment,
  RadarEventActivityDirection,
  Sentiment,
  useGetCreatorProfileForSlCommunityTableRowQuery,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import { NestedFiltersValues } from 'pages/socialMediaListening/community/Filters';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { SocialListeningPeekCreatorDialogView } from '../peekCreatorDialog';

// eslint-disable-next-line
gql`
  query GetCreatorProfileForSLCommunityTableRow(
    $id: String!
    $brandId: String!
  ) {
    creatorProfile(id: $id) {
      id
      isTrackingInsights
      creatorTrackedTopics(brandId: $brandId) {
        id
      }
      latestRadarEventActivity {
        id
        direction
        title
        dateTime
        createdByUser {
          id
          ...UserFragmentAvatarGroup
        }
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type SocialListeningCommunityTableRowProps = {
  creatorProfile: CreatorProfileFragmentSlCommunityTableFragment;
  scrollDirection: 'left' | 'right' | null;
  defaultStyleForFixedColumn: React.CSSProperties;
  rawFilters?: NestedFiltersValues;
};

export const SocialListeningCommunityTableRow = ({
  creatorProfile,
  scrollDirection,
  defaultStyleForFixedColumn,
  rawFilters,
}: SocialListeningCommunityTableRowProps) => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const analytics = useAnalytics();

  const { generateLink: generateCreatorLink } =
    useSocialListeningCreatorLinkGenerator();

  // Fetch creator profile to get the latest data after adding activity
  const { refetch } = useGetCreatorProfileForSlCommunityTableRowQuery({
    variables: {
      id: creatorProfile.id,
      brandId: creatorProfile.brandId,
    },
    fetchPolicy: 'cache-and-network',
    skip: true, // Only fetch when needed
  });

  const [selectedPeekingCreatorProfile, setSelectedPeekingCreatorProfile] =
    useState<CreatorProfileFragmentSlPeekCreatorDialogViewFragment | null>(
      null,
    );

  const products = creatorProfile.capturedProducts
    ?.map((product) =>
      product.line && product.model
        ? `${product.line} ${product.model}`
        : product.line || product.model || null,
    )
    .filter(Boolean)
    .join(', ');

  const niche = creatorProfile.creators
    ?.flatMap((creator) => creator.industry?.split('|'))
    .filter(Boolean)
    .join(', ');

  const firstCreator = creatorProfile.creators?.[0];
  const PlatformIcon = platformIconMap[firstCreator?.platform];

  const totalCreators = creatorProfile.creators?.length || 0;

  const averageSentiment =
    creatorProfile.averageSentimentScore === 0
      ? Sentiment.Neutral
      : creatorProfile.averageSentimentScore < 0
      ? Sentiment.Negative
      : Sentiment.Positive;

  const hasPeekedAt =
    creatorProfile.isTrackingInsights ||
    creatorProfile.creatorTrackedTopics.length > 0;

  // Handle activity added
  const handleActivityAdded = (
    activityId: string,
    reusedExistingActivity?: boolean,
  ) => {
    if (reusedExistingActivity) {
      analytics.track(EventName.CrmDashboardActivityReused, {
        brandId: creatorProfile.brandId,
        userId: user?.id,
        creatorProfileId: creatorProfile.id,
        activityId,
      });
    } else {
      analytics.track(EventName.CrmDashboardActivityAdded, {
        brandId: creatorProfile.brandId,
        userId: user?.id,
        creatorProfileId: creatorProfile.id,
        activityId,
      });
    }

    // Refetch the creator profile to get the latest activity
    refetch();
  };

  const handleLabelAdded = () => {
    analytics.track(EventName.CrmDashboardLabelUpdated, {
      brandId: creatorProfile.brandId,
      userId: user?.id,
      creatorProfileId: creatorProfile.id,
      type: 'added',
    });
  };

  const handleLabelRemoved = () => {
    analytics.track(EventName.CrmDashboardLabelUpdated, {
      brandId: creatorProfile.brandId,
      userId: user?.id,
      creatorProfileId: creatorProfile.id,
      type: 'removed',
    });
  };

  return (
    <>
      <TableRow
        sx={{
          cursor: 'pointer',
          td: {
            borderBottom: 'none',
            ...theme.typography['subhead-xl'],
            color: theme.colors?.primary.black,
            ':first-child': {
              ...(scrollDirection && {
                ...defaultStyleForFixedColumn,
              }),
            },
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          analytics.track(EventName.CrmDashboardCreatorClicked, {
            brandId: creatorProfile.brandId,
            userId: user?.id,
            creatorProfileId: creatorProfile.id,
          });

          navigate(
            PlotRoutes.socialListeningCreatorProfile(
              creatorProfile.brandId,
              creatorProfile.id,
            ),
            {
              state: {
                rawFilters,
              },
            },
          );
        }}
      >
        {/* super fan */}
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: 'fit-content',
              }}
            >
              <Avatar
                src={creatorProfile.creators?.[0]?.profilePictureUrl || ''}
                sizes="32px"
                variant="circular"
              />
              <Tooltip title="Peek at creator">
                <IconButton
                  sx={{
                    borderRadius: '100%',
                    bgcolor: hasPeekedAt
                      ? theme.colors?.utility['yellow-1']
                      : theme.colors?.utility[275],
                    p: 0.7,
                    position: 'absolute',
                    top: '50%',
                    right: 0,
                    transform: 'translate(30%, 20%)',
                    ':hover': {
                      bgcolor: theme.colors?.utility[275],
                    },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setSelectedPeekingCreatorProfile(creatorProfile);
                  }}
                >
                  {hasPeekedAt ? (
                    <IconBoldCamera
                      size={16}
                      color={theme.colors?.utility['yellow-3']}
                    />
                  ) : (
                    <IconLinearCamera
                      size={16}
                      color={theme.colors?.utility[700]}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography variant="headline-sm">
                {creatorProfile.fullName}
              </Typography>

              {creatorProfile.creators?.[0] && (
                <Typography
                  variant="subhead-lg"
                  color={`${theme.colors?.utility[700]} !important`}
                  component="a"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    window.open(
                      generateCreatorLink(creatorProfile.creators[0]),
                      '_blank',
                    );
                  }}
                  href={generateCreatorLink(creatorProfile.creators[0])}
                  target="_blank"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    whiteSpace: 'nowrap',
                  }}
                >
                  <PlatformIcon size={16} color={theme.colors?.utility[600]} />
                  {getCreatorHandleWithPrefix(firstCreator?.handle)} &nbsp;
                  <Tooltip
                    title={
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        {creatorProfile.creators?.map((creator) => {
                          const Icon = platformIconMap[creator.platform];

                          return (
                            <Box
                              key={creator.id}
                              sx={{ display: 'flex', gap: 1 }}
                            >
                              <Icon
                                size={16}
                                color={theme.colors?.primary.parchment}
                              />
                              <Typography
                                variant="subhead-lg"
                                color={theme.colors?.primary.parchment}
                              >
                                {getCreatorHandleWithPrefix(creator?.handle)}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    }
                  >
                    <span>{totalCreators > 1 && `+${totalCreators - 1}`}</span>
                  </Tooltip>
                </Typography>
              )}
            </Box>
          </Box>
        </TableCell>
        {/* relationship */}
        <TableCell>
          <Box
            sx={{
              color: RelationshipColorMap[creatorProfile.relationshipStrength],
              bgcolor:
                RelationshipBackgroundColorMap[
                  creatorProfile.relationshipStrength
                ],
              borderRadius: 2,
              padding: theme.spacing(1, 2),
              ...theme.typography['headline-sm'],
              width: 'fit-content',
              whiteSpace: 'nowrap',
            }}
          >
            {RelationshipLabelMap[creatorProfile.relationshipStrength]}
          </Box>
        </TableCell>
        {/* followers */}
        <TableCell>{formatBigNumber(creatorProfile.followersCount)}</TableCell>
        {/* brand mentions */}
        <TableCell>
          {formatBigNumber(creatorProfile.brandMentionCount)}
        </TableCell>
        {/* brand engagement */}
        <TableCell>{formatBigNumber(creatorProfile.totalComments)}</TableCell>
        {/* ugc engagement rate */}
        <TableCell>
          {(creatorProfile.engagementRate * 100).toFixed(0)}%
        </TableCell>

        {/* average sentiment */}
        <TableCell>
          <Box
            sx={{
              color: SentimentColorMap[averageSentiment],
              bgcolor: SentimentBackgroundColorMap[averageSentiment],
              borderRadius: 2,
              padding: theme.spacing(1, 2),
              ...theme.typography['headline-sm'],
              width: 'fit-content',
            }}
          >
            {SentimentLabelMap[averageSentiment]}
          </Box>
        </TableCell>

        {/* labels */}
        <TableCell
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <SocialListeningLabelSelector
            selectedLabels={creatorProfile.labels}
            creatorProfileId={creatorProfile.id}
            onLabelAdded={handleLabelAdded}
            onLabelRemoved={handleLabelRemoved}
            brandId={creatorProfile.brandId}
          />
        </TableCell>
        {/* activity */}
        <TableCell>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            {creatorProfile.latestRadarEventActivity && (
              <Box
                sx={{
                  borderRadius: 6,
                  background: 'rgba(35, 6, 3, 0.05)',
                  padding: theme.spacing(1, 2),
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  width: 'fit-content',
                }}
              >
                <IconBoldCircleArrowRight
                  size={17}
                  style={{
                    flexShrink: 0,
                    rotate:
                      creatorProfile.latestRadarEventActivity.direction ===
                      RadarEventActivityDirection.Inbound
                        ? '-90deg'
                        : '90deg',
                    color: theme.colors?.utility['blue-4'],
                  }}
                />
                <Typography variant="subhead-xl" whiteSpace="nowrap">
                  {creatorProfile.latestRadarEventActivity.title}
                </Typography>
              </Box>
            )}
            <Box onClick={(e) => e.stopPropagation()}>
              <SocialListeningRadarAddActivityButtonView
                brandId={creatorProfile.brandId}
                creatorProfileIds={[creatorProfile.id]}
                onActivityAdded={handleActivityAdded}
                renderBtn={
                  <Button
                    variant="text"
                    startIcon={
                      <IconButton
                        sx={{
                          bgcolor: theme.colors?.utility[300],
                          height: 20,
                          width: 20,
                          ':hover': {
                            bgcolor: theme.colors?.utility[300],
                          },
                        }}
                      >
                        <IconLinearAdd
                          color={theme.colors?.utility[800]}
                          size={16}
                          style={{
                            flexShrink: 0,
                          }}
                        />
                      </IconButton>
                    }
                    size="small"
                    sx={{
                      p: 0,
                      minWidth: 'unset',
                    }}
                  >
                    {!creatorProfile.latestRadarEventActivity ? (
                      <Typography
                        variant="subhead-xl"
                        color={theme.colors?.utility[700]}
                      >
                        Add an activity
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Button>
                }
              />
            </Box>
          </Box>
          {creatorProfile.latestRadarEventActivity && (
            <Typography
              variant="subhead-lg"
              color={theme.colors?.utility[600]}
              component="div"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                whiteSpace: 'nowrap',
                mt: 2,
              }}
            >
              {creatorProfile.latestRadarEventActivity.dateTime &&
                `${moment(
                  creatorProfile.latestRadarEventActivity.dateTime,
                ).format('MMM DD, YYYY')} · `}
              <AvatarWithName
                user={creatorProfile.latestRadarEventActivity.createdByUser}
                avatarSize={16}
                componentsProps={{
                  name: {
                    sx: {
                      ...typography['subhead-lg'],
                      color: theme.colors?.utility[600],
                    },
                  },
                }}
                shouldHideEmail
              />
            </Typography>
          )}
        </TableCell>
        {/* content niche */}
        <TableCell>
          <ExpandableText
            text={niche || '-'}
            collapsedHeight={44}
            componentProps={{
              text: {
                sx: {
                  ...typography['subhead-xl'],
                },
              },
              seeMore: {
                sx: {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  pl: 6,
                  background:
                    'linear-gradient(to right, transparent, white 20%)',
                },
              },
            }}
          />
        </TableCell>
        {/* products */}
        <TableCell>
          <ExpandableText
            text={products || '-'}
            collapsedHeight={44}
            componentProps={{
              text: {
                sx: {
                  ...typography['subhead-xl'],
                },
              },
              seeMore: {
                sx: {
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  pl: 6,
                  background:
                    'linear-gradient(to right, transparent, white 20%)',
                },
              },
            }}
          />
        </TableCell>
      </TableRow>
      {selectedPeekingCreatorProfile && (
        <SocialListeningPeekCreatorDialogView
          brandId={creatorProfile.brandId}
          creatorProfile={selectedPeekingCreatorProfile}
          onClose={(isDataUpdated) => {
            if (isDataUpdated) {
              refetch();
            }
            setSelectedPeekingCreatorProfile(null);
          }}
        />
      )}
    </>
  );
};
