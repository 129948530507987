import { useClipboard, useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, SxProps, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { typography } from 'components/common/Typography/styles';
import { IconBoldChart1 } from 'components/icons/components/bold/IconBoldChart1';
import { IconBoldEye } from 'components/icons/components/bold/IconBoldEye';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldMessage } from 'components/icons/components/bold/IconBoldMessage';
import { IconLinearExport2 } from 'components/icons/components/linear/IconLinearExport2';
import { IconOutlineLink } from 'components/icons/components/outline/IconOutlineLink';
import {
  CreatorTrackingList,
  ExpandableText,
  SocialMediaListeningCreatorProfile,
} from 'features/socialMediaListening/components';
import { BlockCreatorButton } from 'features/socialMediaListening/components/blockCreatorButton';
import {
  useSLBrandPermissions,
  useSocialListeningPostLinkGenerator,
} from 'features/socialMediaListening/hooks';
import { platformIconMap } from 'features/socialPost';
import {
  SocialPostFragmentSocialMediaListeningPostSummaryFragment,
  SocialPostType,
} from 'graphql/generated';
import { useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { HoverPlayableVideo } from '../postsGrid/postCard/HoverPlayableVideo';
import { PostVideoDialog } from './sections/postVideoDialog/PostVideoDialog';
import { VideoHighlights } from './sections/postVideoDialog/sections/VideoHighlights';

type SocialMediaListeningPostSummaryViewProps = {
  brandId: string;
  post: SocialPostFragmentSocialMediaListeningPostSummaryFragment;
  componentProps?: {
    videoHighlights?: {
      hide?: boolean;
    };
    showOpenLink?: boolean;
    preventVideoDialog?: boolean;
    container?: {
      sx?: SxProps;
    };
    videoContainer?: {
      hide?: boolean;
      sx?: SxProps;
    };
  };
};

export const SocialMediaListeningPostSummaryView = ({
  brandId,
  post,
  componentProps,
}: SocialMediaListeningPostSummaryViewProps) => {
  const videoDialogDisclosure = useDisclosure();
  const { hasFullAccess } = useSLBrandPermissions({ brandId });
  const { generateLink } = useSocialListeningPostLinkGenerator();
  const { onCopy } = useClipboard(generateLink(post) || post.videoUrl || '');
  const [isCopied, setIsCopied] = useState(false);
  const {
    isOpen: isBlockCreatorDialogOpen,
    onClose: closeBlockCreatorDialog,
    onOpen: openBlockCreatorDialog,
  } = useDisclosure();
  const onTextCopy = () => {
    onCopy();
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const Icon = platformIconMap[post.platform];

  return (
    <Box
      onClick={
        !componentProps?.preventVideoDialog
          ? videoDialogDisclosure.onOpen
          : undefined
      }
      sx={{
        position: 'relative',
        cursor: componentProps?.preventVideoDialog ? 'inherit' : 'pointer',
        backgroundColor: 'rgba(246, 229, 216, 0.60)',
        borderRadius: 4,
        p: theme.spacing(4, 6),
        display: 'flex',
        gap: 4,
        '&:hover .open-link': {
          opacity: 1,
        },
        ...(componentProps?.container?.sx || {}),
      }}
    >
      {componentProps?.showOpenLink && (
        <Box
          className="open-link"
          position="absolute"
          right={theme.spacing(6)}
          top={theme.spacing(4)}
          sx={{
            opacity: 0,
          }}
        >
          <IconLinearExport2 size={16} />
        </Box>
      )}
      {/* Video container */}
      {!componentProps?.videoContainer?.hide && (
        <Box sx={{ display: 'flex', flex: 1 }}>
          <Box
            display="flex"
            position="relative"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                borderRadius: 4,
              }}
            >
              <HoverPlayableVideo
                thumbnailUrl={post.thumbnailUrl || ''}
                videoUrl={post.videoUrl || ''}
                renderActionsTopRight={(isHovered: boolean) => (
                  <Box
                    display="flex"
                    gap={2}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    {isHovered && post.videoUrl && (
                      <Tooltip title={isCopied ? 'Copied' : 'Copy'}>
                        <IconButton
                          disableRipple
                          sx={{
                            p: 1,
                            transform: 'rotate(-45deg)',
                            backgroundColor: 'rgba(35, 6, 3, 0.10)',
                            color: theme.colors?.primary.white,
                            backdropFilter: 'blur(4px)',
                          }}
                          onClick={onTextCopy}
                        >
                          <IconOutlineLink size={16} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isHovered && post.videoUrl && post.creator?.handle && (
                      <IconButton
                        disableRipple
                        sx={{
                          p: 1,
                          backgroundColor: 'rgba(35, 6, 3, 0.10)',
                          color: theme.colors?.primary.white,
                          backdropFilter: 'blur(4px)',
                        }}
                        onClick={() => {
                          window.open(generateLink(post) || '', '_blank');
                        }}
                      >
                        <IconLinearExport2 size={16} />
                      </IconButton>
                    )}
                    {Icon && (
                      <Icon
                        size={16}
                        style={{
                          color: theme.colors?.primary.white,
                        }}
                      />
                    )}
                  </Box>
                )}
              />
            </Box>
            {/* <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                cursor: 'pointer',
                pointerEvents: 'none',
                '&:hover': {
                  color: 'rgba(255, 255, 255, 0.5)',
                },
              }}
              onClick={() => {}}
            >
              <IconBoldPlayCircle size={48} />
            </Box> */}
          </Box>
        </Box>
      )}

      {!componentProps?.preventVideoDialog && videoDialogDisclosure.isOpen && (
        <PostVideoDialog
          brandId={brandId}
          post={post}
          isOpen={videoDialogDisclosure.isOpen}
          onClose={() => {
            setTimeout(() => {
              videoDialogDisclosure.onClose();
            }, 0);
          }}
        />
      )}

      {/* Post Summary Container */}
      <Box sx={{ display: 'flex', flex: 3, flexDirection: 'column', gap: 4 }}>
        {post.creator && (
          <SocialMediaListeningCreatorProfile
            creator={post.creator}
            brandId={brandId}
            platformCreateTime={post.platformCreateTime}
            componentProps={{
              showDetailsOnHover: !isBlockCreatorDialogOpen,
              containerSx: {
                sx: { color: theme.colors?.primary.black },
              },
              renderBlockCreatorButton: () =>
                post.creator && (
                  <BlockCreatorButton
                    brandId={brandId}
                    creatorId={post.creator?.id}
                    isOpen={isBlockCreatorDialogOpen}
                    onClose={closeBlockCreatorDialog}
                    onOpen={openBlockCreatorDialog}
                  />
                ),
              renderCreatorTrackingButton: () =>
                hasFullAccess && (
                  <CreatorTrackingList
                    brandId={brandId}
                    creator={post.creator!}
                  />
                ),
            }}
          />
        )}

        {post.description && (
          <ExpandableText
            text={post.description}
            componentProps={{
              text: {
                sx: {
                  ...typography['body-xl'],
                  color: theme.colors?.utility[800],
                  fontWeight: 500,
                  wordBreak: 'break-word',
                },
              },
            }}
            collapsedHeight={68}
          />
        )}

        {/* TODO:LISTENING: To be fixed later */}
        {/* <Box display="flex" gap={2} alignItems="center">
          <IconBoldMusic size={16} />
          <Typography
            display="flex"
            variant="body-lg"
            color={theme.colors?.utility[900]}
            fontWeight={500}
          >
            Music: The Weeknd - Blinding Lights
          </Typography>
        </Box> */}

        <Box display="flex" gap={3} alignItems="center">
          {post.diggCount > -1 && (
            <Box
              display="flex"
              gap={1}
              alignItems="center"
              color={theme.colors?.utility[600]}
            >
              <IconBoldHeart size={16} />
              <Typography
                variant="body-lg"
                color={theme.colors?.utility[900]}
                fontWeight={600}
              >
                {formatBigNumber(post.diggCount)}
              </Typography>
            </Box>
          )}

          <Box
            display="flex"
            gap={1}
            alignItems="center"
            color={theme.colors?.utility[600]}
          >
            <IconBoldMessage size={16} />
            <Typography
              variant="body-lg"
              color={theme.colors?.utility[900]}
              fontWeight={600}
            >
              {formatBigNumber(post.commentCount)}
            </Typography>
          </Box>

          {post.type === SocialPostType.Video && (
            <>
              <Box
                display="flex"
                gap={1}
                alignItems="center"
                color={theme.colors?.utility[600]}
              >
                <IconBoldEye size={16} />
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[900]}
                  fontWeight={600}
                >
                  {formatBigNumber(post.playCount)}
                </Typography>
              </Box>

              <Box
                display="flex"
                gap={1}
                alignItems="center"
                color={theme.colors?.utility[600]}
              >
                <IconBoldChart1 size={16} />
                <Typography
                  variant="body-lg"
                  color={theme.colors?.utility[900]}
                  fontWeight={600}
                >
                  {parseFloat((post.engagementRate * 100).toFixed(1))}%
                </Typography>
              </Box>
            </>
          )}
        </Box>

        {!componentProps?.videoHighlights?.hide &&
          !!post.socialPostEngagementSuggestions?.[0]?.highlights.length && (
            <Box
              sx={{
                borderTop: `2px solid ${theme.colors?.utility[400]}`,
                pt: 4,
              }}
            >
              <VideoHighlights post={post} />
            </Box>
          )}
      </Box>
    </Box>
  );
};
