import { gql } from '@apollo/client';
import {
  CreateRadarEventActivityCommunicationChannelInput,
  RadarEventActivityCommunicationChannelFragmentSlRadarActivityCommunicationChannelHandlersFragmentDoc,
  useCreateCommunicationChannelForSlRadarActivityCommunicationChannelHandlersMutation,
} from 'graphql/generated';

// eslint-disable-next-line
gql`
  fragment RadarEventActivityCommunicationChannelFragmentSLRadarActivityCommunicationChannelHandlers on RadarEventActivityCommunicationChannelModel {
    id
    color
    bgColor
    name
  }
`;

// eslint-disable-next-line
gql`
  query GetCommunicationChannelByIdsForSLRadarActivityCommunicationChannelHandlers(
    $input: RadarEventActivityCommunicationChannelByIdsInput!
  ) {
    radarEventActivityCommunicationChannelByIds(input: $input) {
      ...RadarEventActivityCommunicationChannelFragmentSLRadarActivityCommunicationChannelHandlers
    }
  }
  ${RadarEventActivityCommunicationChannelFragmentSlRadarActivityCommunicationChannelHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetCommunicationChannelForSLRadarActivityCommunicationChannelHandlers(
    $input: PaginatedRadarEventActivityCommunicationChannelInput!
  ) {
    paginatedRadarEventActivityCommunicationChannels(input: $input) {
      data {
        ...RadarEventActivityCommunicationChannelFragmentSLRadarActivityCommunicationChannelHandlers
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${RadarEventActivityCommunicationChannelFragmentSlRadarActivityCommunicationChannelHandlersFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation CreateCommunicationChannelForSLRadarActivityCommunicationChannelHandlers(
    $input: CreateRadarEventActivityCommunicationChannelInput!
  ) {
    createRadarEventActivityCommunicationChannel(input: $input) {
      ...RadarEventActivityCommunicationChannelFragmentSLRadarActivityCommunicationChannelHandlers
    }
  }
  ${RadarEventActivityCommunicationChannelFragmentSlRadarActivityCommunicationChannelHandlersFragmentDoc}
`;

export const useSocialListeningRadarActivityCommunicationChannelHandlers =
  () => {
    const [createCommunicationChannel] =
      useCreateCommunicationChannelForSlRadarActivityCommunicationChannelHandlersMutation();

    const onCreateCommunicationChannel = (
      input: CreateRadarEventActivityCommunicationChannelInput,
    ) => {
      return createCommunicationChannel({
        variables: { input },
      });
    };

    return { onCreateCommunicationChannel };
  };
