import { gql } from '@apollo/client';
import { Grid } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context/User.context';
import {
  SocialListeningCreatorProfileBrandMentionView,
  SocialListeningCreatorProfileDetailsView,
  SocialListeningCreatorProfileDetailsViewSkeleton,
  SocialListeningCreatorProfileInsightsView,
  SocialListeningCreatorProfileInsightsViewSkeleton,
  SocialListeningCreatorProfileMentionedBrandsView,
  SocialListeningCreatorProfileMomentsView,
  SocialListeningCreatorProfileProductsView,
  SocialListeningCreatorProfileStatsView,
  SocialListeningCreatorProfileStatsViewSkeleton,
  SocialListeningCreatorProfileSuperfanEngagementView,
} from 'features/socialListeningCreatorProfile';
import {
  CreatorProfileFragmentAboutTabContentFragment,
  CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc,
  CreatorProfileFragmentSlCreatorProfileInsightsViewFragmentDoc,
  CreatorProfileFragmentSlCreatorProfileStatsViewFragmentDoc,
  Platform,
  SimpleDateRangeFilterInput,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect, useState } from 'react';

export const CREATOR_PROFILE_FRAGMENT_ABOUT_TAB_CONTENT = gql`
  fragment CreatorProfileFragmentAboutTabContent on CreatorProfileModel {
    id
    brandId
    isTrackingInsights
    creatorTrackedTopics(brandId: $brandId) {
      id
    }
    ...CreatorProfileFragmentSLCreatorProfileInsightsView
    ...CreatorProfileFragmentSLCreatorProfileStatsView
    ...CreatorProfileFragmentSLCreatorProfileDetailsView
  }
  ${CreatorProfileFragmentSlCreatorProfileInsightsViewFragmentDoc}
  ${CreatorProfileFragmentSlCreatorProfileStatsViewFragmentDoc}
  ${CreatorProfileFragmentSlCreatorProfileDetailsViewFragmentDoc}
`;

export type AboutTabContentProps = {
  creatorProfile: CreatorProfileFragmentAboutTabContentFragment;
  platforms?: Platform[];
  dateRange: SimpleDateRangeFilterInput;
  loading: boolean;
};

export const AboutTabContent = (props: AboutTabContentProps) => {
  const { creatorProfile, dateRange, platforms, loading } = props;

  const [brandMentionCount, setBrandMentionCount] = useState(0);
  const [brandMentionSentimentScore, setBrandMentionSentimentScore] =
    useState(0);
  const [superfanEngagementCount, setSuperfanEngagementCount] = useState(0);
  const [
    superfanEngagementSentimentScore,
    setSuperfanEngagementSentimentScore,
  ] = useState(0);

  const hasPeekedAt =
    creatorProfile.isTrackingInsights ||
    !!creatorProfile.creatorTrackedTopics.length;

  const analytics = useAnalytics();
  const { user } = useUserContext();

  useEffect(() => {
    analytics.track(EventName.CreatorProfileAboutViewed, {
      brandId: creatorProfile.brandId,
      userId: user?.id,
      creatorProfileId: creatorProfile.id,
    });
  }, [creatorProfile.id]);

  return (
    <Grid container spacing={6}>
      <Grid
        item
        xs={12}
        md={8}
        sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}
      >
        {!loading ? (
          <SocialListeningCreatorProfileStatsView
            creatorProfile={creatorProfile}
            brandMentionCount={brandMentionCount}
            superfanEngagementCount={superfanEngagementCount}
            sentimentScore={
              (brandMentionSentimentScore + superfanEngagementSentimentScore) /
              2
            }
            filters={{
              platforms,
              dateRange,
            }}
          />
        ) : (
          <SocialListeningCreatorProfileStatsViewSkeleton />
        )}
        {hasPeekedAt && (
          <>
            <SocialListeningCreatorProfileMomentsView
              filters={{
                platforms,
                brandId: creatorProfile.brandId,
                creatorProfileIds: [creatorProfile.id],
                dateRange,
              }}
            />
            <SocialListeningCreatorProfileProductsView
              filters={{
                brandId: creatorProfile.brandId,
                dateRange,
                creatorIds: creatorProfile.creators.map(
                  (creator) => creator.id,
                ),
              }}
              creatorProfile={creatorProfile}
            />
          </>
        )}

        {!loading ? (
          <SocialListeningCreatorProfileInsightsView
            creatorProfile={creatorProfile}
          />
        ) : (
          <SocialListeningCreatorProfileInsightsViewSkeleton />
        )}

        {hasPeekedAt && (
          <SocialListeningCreatorProfileMentionedBrandsView
            filters={{
              currentBrandId: creatorProfile.brandId,
              creatorProfileId: creatorProfile.id,
              dateRange,
              platforms,
            }}
          />
        )}

        <SocialListeningCreatorProfileBrandMentionView
          filters={{
            currentBrandId: creatorProfile.brandId,
            brandId: creatorProfile.brandId,
            creatorProfileId: creatorProfile.id,
            dateRange,
            platforms,
          }}
          setBrandMentionCount={setBrandMentionCount}
          setSentimentScore={setBrandMentionSentimentScore}
        />

        <SocialListeningCreatorProfileSuperfanEngagementView
          filters={{
            brandId: creatorProfile.brandId,
            creatorProfileId: creatorProfile.id,
            dateRange,
            platforms,
          }}
          setSuperfanEngagementCount={setSuperfanEngagementCount}
          setSentimentScore={setSuperfanEngagementSentimentScore}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        {!loading ? (
          <SocialListeningCreatorProfileDetailsView
            creatorProfile={creatorProfile}
          />
        ) : (
          <SocialListeningCreatorProfileDetailsViewSkeleton />
        )}
      </Grid>
    </Grid>
  );
};
