import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomCreativeJuiceBox } from 'components/icons/components/custom/IconCustomCreativeJuiceBox';
import { IconCustomSLHorn } from 'components/icons/components/custom/IconCustomSLHorn';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconCustomTrend } from 'components/icons/components/custom/IconCustomTrend';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import { IconOutlineHome } from 'components/icons/components/outline/IconOutlineHome';
import { IconOutlineNotification } from 'components/icons/components/outline/IconOutlineNotification';
import { IconOutlineTask } from 'components/icons/components/outline/IconOutlineTask';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { FeatureType } from 'features/featureTypeContextMenu/constants';
import { PlotFeature } from 'graphql/generated';
import { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

const featureTypeIconMap = {
  [FeatureType.Home]: IconOutlineHome,
  [FeatureType.Juicebox]: IconCustomCreativeJuiceBox,
  [FeatureType.ContentCalendar]: IconOutlineCalendar,
  [FeatureType.Trends]: IconCustomTrend,
  [FeatureType.Tasks]: IconOutlineTask,
  [FeatureType.SocialListening]: IconCustomSLHorn,
  [FeatureType.Inbox]: IconOutlineNotification,
};

const featureTypeLabelMap = {
  [FeatureType.Home]: 'Home',
  [FeatureType.Juicebox]: 'Creative Juicebox',
  [FeatureType.ContentCalendar]: 'Content Calendar',
  [FeatureType.Trends]: 'Trends',
  [FeatureType.Tasks]: 'Tasks',
  [FeatureType.SocialListening]: 'Social Listening',
  [FeatureType.Inbox]: 'Inbox',
};

interface FeatureTypeContextMenuViewProps {
  featureType?: FeatureType;
  customLabel?: ReactNode;
}

export const FeatureTypeContextMenuView = (
  props: FeatureTypeContextMenuViewProps,
) => {
  const { featureType, customLabel } = props;

  const { isFeatureEnabled } = useFeatureFlagContext();
  const { user } = useUserContext();

  const options = useMemo(() => {
    const options = Object.values(FeatureType);

    if (!isFeatureEnabled(PlotFeature.ContentCalendar)) {
      options.splice(options.indexOf(FeatureType.ContentCalendar), 1);
    }

    return options;
  }, [isFeatureEnabled]);

  const onNavigate = (type: FeatureType) => {
    switch (type) {
      case FeatureType.Juicebox:
        return PlotRoutes.juicebox();
      case FeatureType.ContentCalendar:
        return PlotRoutes.contentCalendar();
      case FeatureType.Trends:
        return PlotRoutes.juiceboxTrending();
      case FeatureType.Tasks:
        return PlotRoutes.tasks();
      case FeatureType.SocialListening:
        if (user?.socialListeningBrands.length) {
          return PlotRoutes.socialListeningForBrand(
            user.socialListeningBrands[0].id,
          );
        }
        return PlotRoutes.socialListeningIntro();
      case FeatureType.Inbox:
        return PlotRoutes.inbox();
      default:
        return PlotRoutes.home();
    }
  };

  return (
    <ContextMenu
      renderButton={(open) => {
        const isOpen = open ?? false;
        return (
          <IconButton
            sx={{
              ...theme.typography['headline-sm'],
              color: theme.colors?.utility[700],
              borderRadius: 2,
              backgroundColor: !isOpen ? 'transparent' : 'rgba(35, 6, 3, 0.05)',
              p: theme.spacing(1),
            }}
          >
            {customLabel ||
              (featureType ? featureTypeLabelMap[featureType] : 'Products')}
          </IconButton>
        );
      }}
      options={options.map((key) => ({
        renderCustomComponent: () => {
          const Icon = featureTypeIconMap[key];
          return (
            <Link to={onNavigate(key)}>
              <IconButton
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: 2,
                  width: '100%',
                  color: theme.colors?.primary.black,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                  }}
                >
                  <Icon size={16} color={theme.colors?.primary.black} />
                  <Typography variant="subhead-lg">
                    {featureTypeLabelMap[key]}
                  </Typography>
                </Box>
                {key === featureType && (
                  <IconCustomTick
                    size={16}
                    color={theme.colors?.primary.black}
                  />
                )}
              </IconButton>
            </Link>
          );
        },
      }))}
      PaperProps={{
        style: {
          minWidth: 266,
        },
        sx: {
          p: 3,
          ul: {
            gap: `${theme.spacing(2)} !important`,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'left',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom',
      }}
    />
  );
};
