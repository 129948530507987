/**
 * This component will reuse queries from SocialListeningCreatorProfileSuperfanEngagementView
 */

import { gql } from '@apollo/client';
import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { CustomDialog } from 'components/common/CustomDialog';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { InfiniteScrollBoundary } from 'components/common/InfiniteScrollBoundary';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import { CreatorAvatar } from 'features/creator';
import {
  NestedFiltersDateRangeOptionType,
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import {
  SentimentColorMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity';
import { platformLabelMap } from 'features/socialPost';
import { SocialPostCommentCardView } from 'features/socialPostComment';
import {
  CreatorFragmentCreatorAvatarFragmentDoc,
  CreatorProfilePaginatedSuperfanEngagementInputSortField,
  CreatorProfileSuperfanEngagementStatsInput,
  Platform,
  useGetSocialPostCommentsForSlCreatorProfileSuperfanEngagementViewQuery,
  useGetStatsForSlCreatorProfileSuperfanEngagementViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { dateRangeOptions } from 'pages/socialMediaListening/community/Filters';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

export const CREATOR_PROFILE_FRAGMENT_SL_CREATOR_PROFILE_FAN_ENGAGEMENT_DIALOG_VIEW = gql`
  fragment CreatorProfileFragmentSLCreatorProfileFanEngagementDialogView on CreatorProfileModel {
    id
    brandId
    fullName
    creators {
      id
      ...CreatorFragmentCreatorAvatar
    }
  }
  ${CreatorFragmentCreatorAvatarFragmentDoc}
`;

export type SocialListeningCreatorProfileSuperfanEngagementDialogViewProps = {
  creatorProfile: any;
  open: boolean;
  filters: Partial<
    Pick<CreatorProfileSuperfanEngagementStatsInput, 'dateRange' | 'platforms'>
  >;
  onClose: () => void;
};

export const SocialListeningCreatorProfileSuperfanEngagementDialogView = (
  props: SocialListeningCreatorProfileSuperfanEngagementDialogViewProps,
) => {
  const { creatorProfile, open, filters: _filters, onClose } = props;

  const [filters, setFilters] =
    useState<CreatorProfileSuperfanEngagementStatsInput>({
      brandId: creatorProfile.brandId,
      creatorProfileId: creatorProfile.id,
      dateRange: _filters.dateRange ?? {
        startDate: dateRangeOptions[0].startDate,
        endDate: dateRangeOptions[0].endDate,
      },
      platforms: _filters.platforms ?? [],
    });

  const selectedDateRangeOption = useMemo(
    () =>
      dateRangeOptions.find(
        (option) =>
          moment(option.startDate).isSame(filters.dateRange.startDate, 'day') &&
          moment(option.endDate).isSame(filters.dateRange.endDate, 'day'),
      ) ?? {
        label: CUSTOM_RANGE_LABEL,
        startDate: filters.dateRange.startDate,
        endDate: filters.dateRange.endDate,
      },
    [filters],
  );
  const selectedDateRangeOptionLabel = useMemo(() => {
    if (selectedDateRangeOption.label === CUSTOM_RANGE_LABEL) {
      return `${moment(filters.dateRange.startDate).format(
        'DD MMM',
      )} - ${moment(filters.dateRange.endDate).format('DD MMM')}`;
    }

    return selectedDateRangeOption.label;
  }, [selectedDateRangeOption, filters]);

  /**
   * These logic are copied over from SocialListeningCreatorProfileSuperfanEngagementView
   */
  const [sortKey, setSortKey] =
    useState<CreatorProfilePaginatedSuperfanEngagementInputSortField>(
      CreatorProfilePaginatedSuperfanEngagementInputSortField.Recency,
    );

  const { data: engagementStatsData, loading: engagementStatsLoading } =
    useGetStatsForSlCreatorProfileSuperfanEngagementViewQuery({
      variables: {
        input: filters,
      },
    });

  const socialPostCommentVariables = useMemo(() => {
    return {
      brandId: filters.brandId,
      input: {
        ...filters,
        take: 10,
        sortBy: {
          field: sortKey,
        },
      },
    };
  }, [filters, sortKey]);

  const {
    data: socialPostCommentData,
    loading: socialPostCommentLoading,
    fetchMore: fetchMoreSocialPostComments,
  } = useGetSocialPostCommentsForSlCreatorProfileSuperfanEngagementViewQuery({
    variables: socialPostCommentVariables,
  });

  const socialPostComments =
    socialPostCommentData?.getCreatorProfileSuperfanEngagement.data || [];

  const statsData =
    engagementStatsData?.getCreatorProfileSuperfanEngagementStats;

  const sortOptions = [
    {
      label: 'Total like count',
      value: CreatorProfilePaginatedSuperfanEngagementInputSortField.DiggCount,
    },
    {
      label: 'Total reply count',
      value:
        CreatorProfilePaginatedSuperfanEngagementInputSortField.ChildCommentCount,
    },
    {
      label: 'Total engagement',
      value:
        CreatorProfilePaginatedSuperfanEngagementInputSortField.EngagementRate,
    },
    {
      label: 'Recency',
      value: CreatorProfilePaginatedSuperfanEngagementInputSortField.Recency,
    },
  ];

  const stats = useMemo(() => {
    const renderSkeleton = (width = 50) => {
      return <Skeleton variant="text" width={width} height={40} />;
    };
    return [
      {
        label: 'Number of comments',
        text: engagementStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.totalComments ?? 0),
      },
      {
        label: 'Total engagement',
        text: engagementStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.totalEngagement ?? 0),
      },
      {
        label: 'Max likes',
        text: engagementStatsLoading
          ? renderSkeleton()
          : formatBigNumber(statsData?.maxLikes ?? 0),
      },
      {
        label: 'Overall sentiment',
        text: engagementStatsLoading
          ? renderSkeleton(120)
          : statsData && (
              <Typography
                variant="headline-xl"
                fontSize={32}
                fontWeight={500}
                color={SentimentColorMap[statsData.sentiment]}
              >
                {SentimentLabelMap[statsData.sentiment]}
              </Typography>
            ),
      },
    ];
  }, [statsData, engagementStatsLoading]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      onBackClick={onClose}
      titleBreadcrumbs={[creatorProfile.fullName, 'Superfan Engagement']}
    >
      <Box
        sx={{
          p: 6,
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <CreatorAvatar
              creator={creatorProfile.creators[0]}
              size={48}
              hidePlatformIcon
            />
            <Typography variant="headline-xl" fontSize={24} fontWeight={600}>
              {creatorProfile.fullName}
            </Typography>
          </Box>
          <Box display="flex" gap={3} alignItems="center">
            <NestedFiltersMenuView
              items={[
                {
                  type: 'multi-select',
                  key: 'platforms',
                  options: [
                    Platform.Instagram,
                    Platform.Tiktok,
                    Platform.Youtube,
                  ].map((platform) => ({
                    label: platformLabelMap[platform],
                    value: platform,
                  })),
                },
              ]}
              values={{
                platforms: {
                  value: (filters.platforms ?? []).map((p) => ({
                    label: platformLabelMap[p],
                    value: p,
                  })),
                },
              }}
              onChange={(values) => {
                setFilters((o) => ({
                  ...o,
                  platforms: (
                    values.platforms.value as NestedFiltersOptionType[]
                  )?.map((v) => v.value) as Platform[],
                }));
              }}
              componentsProps={{
                trigger: {
                  text: filters.platforms?.join(', ') || 'Platforms',
                  Icon: IconLinearMonitorMobile,
                  sx: {
                    bgcolor: theme.colors?.primary.parchment,
                  },
                },
              }}
            />
            <NestedFiltersMenuView
              items={[
                {
                  type: 'date-range',
                  key: 'dateRange',
                  options: dateRangeOptions,
                },
              ]}
              values={{
                dateRange: {
                  value: selectedDateRangeOption,
                },
              }}
              onChange={(values) => {
                setFilters((o) => ({
                  ...o,
                  dateRange: {
                    startDate: (
                      values.dateRange.value as NestedFiltersDateRangeOptionType
                    ).startDate,
                    endDate: (
                      values.dateRange.value as NestedFiltersDateRangeOptionType
                    ).endDate,
                  },
                }));
              }}
              componentsProps={{
                trigger: {
                  text: selectedDateRangeOptionLabel,
                  Icon: IconOutlineCalendar1,
                  sx: {
                    bgcolor: theme.colors?.primary.parchment,
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* stats */}
        <Box
          sx={{
            background: theme.colors?.primary.parchment,
            borderRadius: 3,
            display: 'flex',
            flexWrap: 'wrap',
            p: 4,
            gap: 4,
          }}
        >
          {stats.map((stat, index) => (
            <Box
              sx={{
                [theme.breakpoints.up('md')]: {
                  borderRight:
                    index + 1 !== stats.length
                      ? `1px solid rgba(35, 6, 3, 0.07)`
                      : 'none',
                },
                flex: 1,
                minWidth: 140,
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gap: 3,
                }}
              >
                <Typography variant="headline-sm">{stat.label}</Typography>
                <Typography
                  variant="headline-xl"
                  fontSize={32}
                  fontWeight={500}
                >
                  {stat.text}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Divider
          sx={{
            bgcolor: 'rgba(35, 6, 3, 0.05)',
            height: 2,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="headline-lg"
              fontSize={20}
              color={theme.colors?.utility[900]}
            >
              Comments
            </Typography>
            <NestedFiltersMenuView
              values={{
                sort: {
                  value: sortOptions.find((option) => option.value === sortKey),
                },
              }}
              items={[
                {
                  type: 'single-select',
                  key: 'sort',
                  options: sortOptions,
                },
              ]}
              onChange={(values) => {
                setSortKey(
                  (values.sort.value as NestedFiltersOptionType)
                    ?.value as CreatorProfilePaginatedSuperfanEngagementInputSortField,
                );
              }}
              componentsProps={{
                trigger: {
                  text:
                    sortOptions.find((option) => option.value === sortKey)
                      ?.label ?? 'Sort',
                  Icon: IconLinearSort,
                  sx: {
                    bgcolor: theme.colors?.primary.parchment,
                  },
                },
              }}
            />
          </Box>
          {socialPostComments.length ? (
            <>
              <Grid container spacing={6}>
                {socialPostComments.map((socialPostComment) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={socialPostComment.id}>
                      <SocialPostCommentCardView
                        socialPostComment={socialPostComment}
                        brandId={filters.brandId}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <InfiniteScrollBoundary
                disabled={
                  socialPostCommentLoading ||
                  !socialPostCommentData?.getCreatorProfileSuperfanEngagement
                    .pageInfo.hasNextPage
                }
                onVisible={() => {
                  fetchMoreSocialPostComments({
                    variables: {
                      input: {
                        ...socialPostCommentVariables,
                        skip: socialPostComments.length,
                      },
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) return prev;
                      return {
                        getCreatorProfileSuperfanEngagement: {
                          ...fetchMoreResult.getCreatorProfileSuperfanEngagement,
                          data: [
                            ...prev.getCreatorProfileSuperfanEngagement.data,
                            ...fetchMoreResult
                              .getCreatorProfileSuperfanEngagement.data,
                          ],
                        },
                      };
                    },
                  });
                }}
              />
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography
                variant="headline-sm"
                color={theme.colors?.utility[600]}
              >
                {socialPostCommentLoading
                  ? 'Loading...'
                  : 'No superfan engagement spotted 👀'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </CustomDialog>
  );
};
