import { Badge, Box, Button, IconButton, SxProps } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldFlash } from 'components/icons/components/bold/IconBoldFlash';
import { IconLinearSidebarRight } from 'components/icons/components/linear/IconLinearSidebarRight';
import { IconOutlineMenu1 } from 'components/icons/components/outline/IconOutlineMenu1';
import { useUserContext } from 'contexts/users/User.context';
import {
  BillingPlanName,
  BillingSubscriptionStatus,
  useGetNotificationUnreadCountQuery,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import { useMediaQueryMobile } from 'hooks/useMediaQueryMobile';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { useLeftDrawerContext } from '../LeftDrawer';
import { DRAWER_WIDTH } from '../LeftDrawer/LeftDrawer';
import { HeaderCopyLinkButton } from './components';
import { useCustomHeaderContext } from './contexts/CustomHeader.context';

export type CustomHeaderProps = {
  sx?: SxProps;
  iconColor?: string;
};

export const CustomHeader = (props: CustomHeaderProps) => {
  const { sx, iconColor } = props;

  const {
    isPersistentDrawer,
    isOpenDrawer,
    setIsPersistentDrawer,
    onOpenDrawer,
  } = useLeftDrawerContext();

  const location = useLocation();
  const navigate = useGuardNavigate();

  const { data: unreadNotificationDataV2 } =
    useGetNotificationUnreadCountQuery();

  const isMobileView = useMediaQueryMobile();

  const { orgBilling } = useUserContext();

  const {
    headerMenuOptions,
    renderMenuExtraLeft,
    renderMenuExtraRight,
    renderTitle,
    headerSx,
  } = useCustomHeaderContext();

  // Show upgrade button if:
  // 1. User is in free plan
  // 2. User is in trial for standard plan
  const shouldShowUpgradeButton = useMemo(() => {
    if (!orgBilling) {
      return false;
    }

    if (
      orgBilling.plan !== BillingPlanName.Free &&
      orgBilling.subscription?.status !== BillingSubscriptionStatus.Trialing
    ) {
      return false;
    }

    if (isMobileView && !['/', '/juicebox'].includes(location.pathname)) {
      return false;
    }

    return true;
  }, [orgBilling, isMobileView, location.pathname]);

  const style = {
    ...sx,
    ...headerSx,
  };

  return (
    <Box
      sx={{
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
        width: '100%',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.colors?.utility[300]}`,
        transition: 'all 0.2s linear',
        height: theme.spacing(16),
        bgcolor: theme.colors?.primary.white,
        pointerEvents: 'auto',
        padding: theme.spacing(4, 6, 4, 0),
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(4, 2, 4, 2),
        },
        position: 'relative',
        ...style,
      }}
      id="custom-header"
    >
      <Box
        display="flex"
        alignItems="center"
        gap={3}
        flex={1}
        overflow="hidden"
      >
        <Box
          sx={{
            width: 'fit-content',
            display: 'flex',
            alignItems: 'center',
            minHeight: 64,
            pl: 6,
            ...(isOpenDrawer ? { minWidth: DRAWER_WIDTH } : {}),
          }}
        >
          {!isPersistentDrawer && (
            <Tooltip title={isOpenDrawer ? 'Lock sidebar open' : ''}>
              <IconButton
                onClick={() => {
                  if (isOpenDrawer) {
                    setIsPersistentDrawer(true);
                  }
                }}
                onMouseEnter={() => {
                  if (!isPersistentDrawer && !isOpenDrawer) {
                    onOpenDrawer();
                  }
                }}
                style={{ flexShrink: 0, marginRight: theme.spacing(1) }}
                {...(isMobileView ? { size: 'small' } : {})}
              >
                {isOpenDrawer ? (
                  <IconLinearSidebarRight
                    size={isMobileView ? 20 : 24}
                    color={iconColor}
                  />
                ) : (
                  <Badge
                    badgeContent={
                      unreadNotificationDataV2?.unreadNotificationsV2
                    }
                    max={99}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: theme.colors?.utility['pink-3'],
                        color: theme.colors?.primary.white,
                        p: 1,
                        height: 'auto',
                        top: 2,
                        ...theme.typography['headline-xxs'],
                        fontSize: 11,
                      },
                    }}
                  >
                    <IconOutlineMenu1
                      size={isMobileView ? 20 : 24}
                      color={iconColor}
                    />
                  </Badge>
                )}
              </IconButton>
            </Tooltip>
          )}

          {typeof renderTitle === 'function' ? renderTitle() : renderTitle}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        {shouldShowUpgradeButton && (
          <Button
            startIcon={
              <IconBoldFlash size={20} color={theme.colors?.primary.black} />
            }
            sx={{
              color: iconColor ?? theme.colors?.primary.black,
              borderColor: iconColor ?? theme.colors?.primary.black,
              borderRadius: theme.spacing(2),
              ':hover': {
                borderColor: iconColor ?? theme.colors?.primary.black,
              },
              mr: 2,
              flexShrink: 0,
              height: 36,
            }}
            onClick={() => navigate(PlotRoutes.billing())}
            variant="outlined"
            className="upgrade-button"
          >
            Upgrade
          </Button>
        )}
        {renderMenuExtraLeft?.map((render, i) =>
          render ? <Box key={i}>{render!()}</Box> : null,
        )}
        {headerMenuOptions?.copyLink && (
          <HeaderCopyLinkButton iconColor={iconColor} />
        )}
        {renderMenuExtraRight?.filter(Boolean).map((render, i) => (
          <Box key={i}>{render!()}</Box>
        ))}
      </Box>
    </Box>
  );
};
