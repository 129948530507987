import { gql } from '@apollo/client';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { useResponderChange } from 'features/socialPostComment/hooks';
import {
  SocialPostCommentFragmentSocialPostCommentResponderButtonFragment,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import { useRef } from 'react';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  fragment SocialPostCommentFragmentSocialPostCommentResponderButton on SocialPostCommentModel {
    id
    ...SocialPostCommentFragmentUseResponderChange
  }
`;

export type SocialPostCommentResponderButtonProps = {
  socialPostComment: SocialPostCommentFragmentSocialPostCommentResponderButtonFragment;
  brandId: string;
  // this is the function that will be called when the responder is changed
  onResponderChanged?: (responderId: string | undefined) => void;
  componentProps?: {
    sx?: SxProps;
  };
  // whether to show the end icon (arrow down)
  showEndIcon?: boolean;
};

// show comment responder and can update responder
export const SocialPostCommentResponderButton = (
  props: SocialPostCommentResponderButtonProps,
) => {
  const {
    socialPostComment,
    onResponderChanged,
    brandId,
    componentProps,
    showEndIcon = true,
  } = props;
  const anchorElRef: React.RefObject<HTMLButtonElement> =
    useRef<HTMLButtonElement>(null);

  const {
    selectedResponder,
    canUpdateResponder,
    canAddResponder,
    renderPopoverUI,
    isPopoverOpen,
    openPopover,
  } = useResponderChange({
    socialPostComment,
    onResponderChanged,
    anchorElRef,
    brandId,
  });

  const hasResponder = Boolean(selectedResponder);

  return (
    <>
      <Button
        ref={anchorElRef}
        variant="tertiary"
        disabled={!(canUpdateResponder || canAddResponder)} // enable button if can update OR add responder
        startIcon={
          hasResponder && selectedResponder ? (
            <Avatar
              user={selectedResponder as UserFragmentAvatarGroupFragment}
              size={24}
            />
          ) : (
            <Box
              sx={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                bgcolor: theme.colors?.utility['purple-4'],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: `1px solid ${theme.colors?.primary[500]}`,
              }}
            >
              <IconLinearAdd size={12} color={theme.colors?.primary.white} />
            </Box>
          )
        }
        endIcon={showEndIcon ? <IconLinearArrowDown size={16} /> : undefined}
        sx={{
          borderRadius: 2,
          backgroundColor: isPopoverOpen
            ? theme.colors?.utility[300]
            : hasResponder
            ? 'transparent'
            : theme.colors?.utility[275],
          '& .MuiButton-endIcon': {
            opacity: isPopoverOpen ? 1 : 0,
            transition: 'opacity 0.2s, transform 0.2s',
            transform: isPopoverOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          },
          '&:hover .MuiButton-endIcon': {
            opacity: showEndIcon ? 1 : 0,
          },
          ...componentProps?.sx,
        }}
        onClick={openPopover}
      >
        <Typography variant="subhead-sm" color={theme.colors?.utility[800]}>
          {hasResponder && selectedResponder
            ? getFullName(selectedResponder)
            : 'Add'}
        </Typography>
      </Button>
      {renderPopoverUI()}
    </>
  );
};
