import {
  Box,
  IconButton,
  MenuItem,
  MenuItemProps,
  SxProps,
} from '@mui/material';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconOutlineArrowUp2 } from 'components/icons/components/outline/IconOutlineArrowUp2';
import {
  Fragment,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useId,
  useState,
} from 'react';
import { theme } from 'styles/theme';
import { CheckboxMenuItem, RadioMenuItem } from '../form/Select';

type DropDownOption = {
  label: ReactNode;
  value?: string | number;
  description?: string;
  isChecked?: boolean;
  onClick?: VoidFunction;
  renderOption?: (option: DropDownOption, index: number) => ReactNode;
};

type DropDownMoreMenuItemProps = Omit<MenuItemProps, 'onKeyDown'> & {
  label: ReactNode;
  singleSelectOnly?: boolean;
  onOpenCallback?: VoidFunction;
  onCloseCallback?: VoidFunction;
  renderDropDownOptionSearchInput?: () => ReactNode;
  dropDownOptions: DropDownOption[];
  loadMoreItems?: VoidFunction;
  componentsProps?: {
    dropDownOptions?: {
      container?: {
        sx?: SxProps;
      };
      item?: {
        sx?: SxProps;
      };
    };
  };
  defaultOpen?: boolean;

  /**
   * Should render the header as an option (the group label),
   * and allow the user to select it
   */
  renderHeaderAsOption?: boolean;
  // custom icon to render in the header
  headerCustomIcon?: () => ReactNode;
};

export const DropDownMoreMenuItem = (props: DropDownMoreMenuItemProps) => {
  const {
    label,
    singleSelectOnly,
    onOpenCallback,
    onCloseCallback,
    renderDropDownOptionSearchInput,
    dropDownOptions,
    loadMoreItems,
    id,
    sx,
    componentsProps,
    defaultOpen = false,
    renderHeaderAsOption = false,
    headerCustomIcon,
    ...other
  } = props;

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const menuItemId = useId();
  const normMenuItemId = id ?? menuItemId;

  const handleToggleDropDown = () => {
    if (isOpen) {
      close();
      onCloseCallback?.();
    } else {
      open();
      onOpenCallback?.();
    }
  };

  const handleItemKeyDown: KeyboardEventHandler<HTMLLIElement> = (ev) => {
    if (
      (ev.key !== 'ArrowRight' && ev.key !== 'Enter') ||
      ev.ctrlKey ||
      ev.shiftKey ||
      ev.altKey ||
      ev.metaKey
    )
      return;
    ev.preventDefault();
    ev.stopPropagation();
    open();
  };

  const renderHeader = () => {
    const headerContent = (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 4,
          flex: 1,
          width: '100%',
        }}
      >
        <Box sx={{ flex: 1 }}>{label}</Box>
        <IconButton
          size="small"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleToggleDropDown();
          }}
          className="dropdown-more-menu-item-toggle-button"
        >
          {isOpen ? (
            <IconOutlineArrowUp2 size={16} />
          ) : (
            <IconLinearArrowDown size={16} />
          )}
        </IconButton>
      </Box>
    );

    // Rn we don't support rendering header as option if singleSelectOnly is true
    // We will only support checkbox (multi-select)
    if (!renderHeaderAsOption || singleSelectOnly) {
      return (
        <MenuItem
          id={normMenuItemId}
          onKeyDown={handleItemKeyDown}
          onClick={handleToggleDropDown}
          sx={{
            borderRadius: theme.spacing(4),
            ...sx,
          }}
          {...other}
        >
          {headerContent}
        </MenuItem>
      );
    }

    return (
      <CheckboxMenuItem
        label={headerContent}
        value={-1}
        checked={
          // this is hack for manually set the checked state
          other.defaultChecked ??
          dropDownOptions.every((option) => option.isChecked)
        }
        sx={sx}
        onKeyDown={handleItemKeyDown}
        {...(headerCustomIcon ? { renderCheckbox: headerCustomIcon } : {})}
        {...other}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      {isOpen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            py: renderDropDownOptionSearchInput ? 2 : 0,
            ...componentsProps?.dropDownOptions?.container?.sx,
          }}
          onScrollCapture={(event) => {
            if (loadMoreItems) {
              const node = event.currentTarget;
              if (node.scrollTop + node.clientHeight > node.scrollHeight - 10) {
                loadMoreItems();
              }
            }
          }}
        >
          {renderDropDownOptionSearchInput?.()}

          {dropDownOptions.map((option, index) => {
            if (option.renderOption) {
              return (
                <Fragment key={index}>
                  {option.renderOption(option, index)}
                </Fragment>
              );
            }

            return (
              <Box key={index} onClick={option.onClick}>
                {singleSelectOnly ? (
                  <RadioMenuItem
                    label={option.label}
                    description={option.description}
                    value={index}
                    checked={option.isChecked}
                    sx={componentsProps?.dropDownOptions?.item?.sx}
                  />
                ) : (
                  <CheckboxMenuItem
                    label={option.label}
                    description={option.description}
                    value={index}
                    checked={option.isChecked}
                    sx={componentsProps?.dropDownOptions?.item?.sx}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
};
