import { gql } from '@apollo/client';
import { useViewEntityMutation, ViewEntityType } from 'graphql/generated';
import { useEffect, useMemo } from 'react';

// eslint-disable-next-line
gql`
  mutation ViewEntity($data: ViewEntityInput!) {
    viewEntity(data: $data) {
      message
      success
    }
  }
`;

export const useViewEntity = () => {
  const [viewEntity] = useViewEntityMutation();
  const updatedResult = useMemo(() => {
    return {
      ...viewEntity,
      viewEntity: (entityId: string, entityType: ViewEntityType) => {
        return viewEntity({
          variables: {
            data: {
              entityId,
              entityType,
            },
          },
        });
      },
    };
  }, [viewEntity]); // eslint-disable-line

  return updatedResult;
};

export const usePageViewEntity = (
  entityId: string,
  entityType: ViewEntityType,
) => {
  const { viewEntity } = useViewEntity();

  useEffect(() => {
    if (!entityId || !entityType) {
      return;
    }
    viewEntity(entityId, entityType);
  }, [entityId, entityType, viewEntity]); // eslint-disable-line -- run once on mount
};
