import { useEffect, useState } from 'react';
import { NestedFiltersOptionType } from '../components';

export type UseAsyncOptionsProps = {
  query?: string;
  getAsyncOptions?: (params: {
    query: string;
    nextPageCursor?: string;
  }) => Promise<{
    options: NestedFiltersOptionType[];
    nextPageCursor?: string;
  }>;
};

/**
 * Hook to fetch async options.
 * Handle loading states & pagination.
 */
export const useAsyncOptions = (props: UseAsyncOptionsProps) => {
  const { query = '', getAsyncOptions } = props;

  const [options, setOptions] = useState<NestedFiltersOptionType[]>([]);
  const [endCursor, setEndCursor] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (!getAsyncOptions) {
      return;
    }

    setLoading(true);

    getAsyncOptions({ query })
      .then(({ options, nextPageCursor }) => {
        setOptions(options);
        setEndCursor(nextPageCursor);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNextPage = async () => {
    setLoadingMore(true);
    await getAsyncOptions?.({
      query,
      nextPageCursor: endCursor,
    }).then(({ options, nextPageCursor }) => {
      setOptions((prevOptions) => [...prevOptions, ...options]);
      setEndCursor(nextPageCursor);
    });
    setLoadingMore(false);
  };

  return {
    options,
    loading,
    loadingMore,
    hasMore: !!endCursor,
    getNextPage,
  };
};
