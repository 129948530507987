import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Badge, Box, Button, Dialog, Switch, Typography } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { Tooltip } from 'components/common/Tooltip';
import { useUserContext } from 'contexts/users/User.context';
import { BillingOrganizationMemberLimitIndicator } from 'features/billing';
import { useOrgMemberManagement } from 'features/organizationMembers/hooks';
import { AddUsersToOrgView } from 'features/organizationMembers/views/AddUsersToOrgView';
import {
  InternalOrganizationRole,
  PermissionLevel,
  UpdateBrandGeneralPermissionMember,
  useGetMyPendingOrganizationAccessRequestsForAdminQuery,
  useRestrictAccessForUserForMembersViewMutation,
  useUnrestrictAccessForUserForMembersViewMutation,
  useUpdateBrandPermissionsForMembersViewMutation,
} from 'graphql/generated';
import { useCheckOrgDomainUtils } from 'hooks/useCheckOrgDomain';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { theme } from 'styles/theme/theme';
import { emailValidation } from 'utils/validations';
import {
  EmailValidationErrorForAddUser,
  InviteUserInfo,
  ValidateEmailParams,
} from '../AddUsersToOrgView/types';
import { GuestMembersTable } from './GuestMembersTable';
import { InternalMembers } from './InternalMembers';
import { UserApprovalRequestsTable } from './UserApprovalRequestsTable';

gql`
  mutation UpdateBrandPermissionsForMembersView(
    $data: UpdateBrandPermissionsInput!
  ) {
    updateBrandPermissions(data: $data) {
      success
      message
    }
  }
`;

type TabType = 'members' | 'guests' | 'requests';

export const MembersView = () => {
  const { isWorkDomain } = useCheckOrgDomainUtils();
  const navigate = useNavigate();
  const { user, orgBilling } = useUserContext();
  const isSocialListeningEnabled = orgBilling?.socialListeningEnabled;

  const { inviteMembersForNonSLOrg, inviteMembersForSLOrg } =
    useOrgMemberManagement();

  const canAddMember = useMemo(() => {
    return (
      isSocialListeningEnabled ||
      // Null organizationMemberLimit = unlimited members
      !orgBilling?.organizationMemberLimit ||
      orgBilling.organizationMemberUsage < orgBilling.organizationMemberLimit
    );
  }, [orgBilling, isSocialListeningEnabled]);

  const [tab, setTab] = useState<TabType>('members');

  const { data } = useGetMyPendingOrganizationAccessRequestsForAdminQuery();
  const requests = data?.myPendingOrganizationAccessRequestsForAdmin || [];

  const { isOpen, onClose, onOpen } = useDisclosure();

  const fragment = window.location.hash;
  const isAdmin = useMemo(() => {
    return user?.role === InternalOrganizationRole.Admin;
  }, [user]);

  useEffect(() => {
    if (fragment && isAdmin) {
      const tab: TabType = fragment.slice(1) as TabType;
      if (['members', 'guests', 'requests'].includes(tab)) {
        setTab(tab);
      }
    }
  }, [fragment]); // eslint-disable-line react-hooks/exhaustive-deps

  const [restrictAccessForUser] =
    useRestrictAccessForUserForMembersViewMutation();
  const [unrestrictAccessForUser] =
    useUnrestrictAccessForUserForMembersViewMutation();

  const [hasRestrictedAccess, setHasRestrictedAccess] = useState(
    user?.organization.settings.restrictedAccessForUser,
  );

  const internalUsers = useMemo(
    () =>
      user?.organization.users.filter((u) => u.hasBeenApprovedByAdmin) || [],
    [user],
  );
  const guestUsers = useMemo(
    () => user?.organization.externalUsers.filter((u) => !u.isDisabled) || [],
    [user],
  );

  const validateEmailBeforeAdd = ({
    selectedEmails,
    email,
  }: ValidateEmailParams): { type: EmailValidationErrorForAddUser } => {
    if (email && email.trim()) {
      // Check if email is already in selectedEmails
      if (selectedEmails.includes(email)) {
        return { type: 'invalidEmail' };
      }

      const isEmailValid = emailValidation.isValidSync(email);
      if (!isEmailValid) {
        return { type: 'invalidEmail' };
      }

      if (isEmailValid) {
        const existingUser = [...internalUsers, ...guestUsers]
          .filter((u) => !u.isDisabled)
          .find((x) => x.email === email);

        if (existingUser) {
          return { type: 'existsAlready' };
        }
        return { type: null };
      }
    }
    return { type: 'invalidEmail' };
  };

  const [updateBrandPermissions] =
    useUpdateBrandPermissionsForMembersViewMutation();

  return (
    <Box position="relative">
      {(tab === 'members' || tab === 'requests') && (
        <Box
          sx={{
            position: 'absolute',
            right: theme.spacing(4),
            top: theme.spacing(4),
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            zIndex: 9,
          }}
        >
          {tab === 'requests' ? (
            <Box
              sx={{
                display: 'flex',
                gap: 1,
              }}
            >
              {!isSocialListeningEnabled && (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      alignItems: 'flex-start',
                    }}
                  >
                    <Typography
                      variant="headline-xs"
                      color={theme.colors?.utility[1100]}
                    >
                      Restricted Workspace
                    </Typography>
                    <Typography
                      variant="subhead-sm"
                      color={theme.colors?.utility[600]}
                    >
                      Only approved members can access.
                    </Typography>
                  </Box>
                  <Switch
                    checked={hasRestrictedAccess}
                    disabled={!isAdmin}
                    onChange={(_, checked) => {
                      if (checked) {
                        restrictAccessForUser();
                      } else {
                        unrestrictAccessForUser();
                      }
                      setHasRestrictedAccess(checked);
                    }}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb': {
                        color: theme.colors?.primary.maroon,
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track':
                        {
                          backgroundColor: theme.colors?.utility[275],
                        },
                    }}
                  />
                </>
              )}
            </Box>
          ) : (
            <Tooltip
              title={
                !canAddMember && orgBilling ? (
                  <BillingOrganizationMemberLimitIndicator
                    organizationBilling={orgBilling}
                    variant="text"
                    customText="to add more members"
                  />
                ) : (
                  ''
                )
              }
            >
              <Box component="div">
                <Button
                  variant="primary-alt"
                  onClick={onOpen}
                  disabled={!canAddMember}
                  sx={{
                    backgroundColor: theme.colors?.primary.black,
                    '&:hover': { backgroundColor: theme.colors?.primary.black },
                  }}
                >
                  Add members
                </Button>
              </Box>
            </Tooltip>
          )}
        </Box>
      )}

      <Box
        sx={{
          '& .MuiTabPanel-root': {
            paddingTop: 6,
          },
          '& .MuiTab-root': { padding: `0 !important` },
          '& .MuiTabs-root': {
            pb: 6,
          },
        }}
      >
        <Tabs
          tab={tab}
          onChange={(val) => {
            if (isAdmin) {
              navigate(`#${val}`);
            } else {
              setTab(val as TabType);
            }
          }}
          tabs={[
            {
              key: 'members',
              label: (
                <TabTitle
                  title={`Members (${orgBilling?.organizationMemberUsage})`}
                  isActive={tab === 'members'}
                />
              ),
              content: <InternalMembers users={internalUsers} />,
            },
            {
              key: 'guests',
              label: (
                <TabTitle
                  title={`Guests (${orgBilling?.guestUsage})`}
                  isActive={tab === 'guests'}
                />
              ),
              content: <GuestMembersTable users={guestUsers} />,
            },
            ...(isAdmin
              ? [
                  {
                    key: 'requests',
                    label: (
                      <Badge
                        variant="dot"
                        invisible={!requests.length}
                        sx={{
                          '& .MuiBadge-badge': {
                            backgroundColor: theme.colors?.primary.maroon,
                            width: 8,
                            height: 8,
                            borderRadius: 50,
                            bottom: '50%',
                            right: theme.spacing(-2),
                          },
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                      >
                        <TabTitle
                          title={`Requests (${requests.length})`}
                          isActive={tab === 'requests'}
                        />
                      </Badge>
                    ),
                    content: (
                      <UserApprovalRequestsTable
                        organizationName={user?.organization.name || ''}
                        requests={requests}
                        canEdit={isAdmin}
                      />
                    ),
                  },
                ]
              : []),
          ]}
        />
      </Box>

      {tab === 'members' && (
        <Dialog
          open={isOpen}
          onClose={onClose}
          PaperProps={{
            sx: {
              p: 0,
              boxShadow: 'none',
              backgroundColor: 'transparent',
              borderRadius: theme.spacing(5),
            },
          }}
        >
          <AddUsersToOrgView
            existingInternalUsers={internalUsers.filter((u) => !u.isDisabled)}
            existingExternalUsers={guestUsers.filter((u) => !u.isDisabled)}
            hideShareModal={onClose}
            onInviteMembers={async (
              data: InviteUserInfo[],
              closeShareModalAfterUpdate?: boolean,
            ) => {
              if (!data.length) {
                return;
              }

              if (isSocialListeningEnabled) {
                // Filter internal roles
                const internalRoles = data.filter((d) =>
                  [
                    InternalOrganizationRole.User,
                    InternalOrganizationRole.Admin,
                    InternalOrganizationRole.SocialListeningUser,
                  ].includes(d.role as InternalOrganizationRole),
                );

                if (internalRoles.length > 0) {
                  // Verify internal users being added to org
                  await inviteMembersForSLOrg(
                    internalRoles.map((d) => ({
                      email: d.email,
                      role: d.role as InternalOrganizationRole,
                    })),
                  );
                }

                // For social listening, we need to add ALL users to brand permissions
                const brandId: string | undefined = data[0].brandIds?.[0];
                if (brandId) {
                  const brand = user?.socialListeningBrands.find(
                    (b) => b.id === brandId,
                  );

                  if (brand) {
                    const existingBrandMembers = brand.inviteMembers || [];
                    const newBrandMembers: UpdateBrandGeneralPermissionMember[] =
                      [
                        ...existingBrandMembers
                          .map((mem) => ({
                            email: mem.user.email,
                            permission: mem.permissionLevel,
                          }))
                          .filter(
                            (mem) => !data.some((d) => d.email === mem.email),
                          ),
                        ...data.map((d) => ({
                          email: d.email,
                          permission: PermissionLevel.Full,
                        })),
                      ];

                    await updateBrandPermissions({
                      variables: {
                        data: {
                          brandId,
                          members: newBrandMembers,
                        },
                      },
                    });
                  }
                } else {
                  console.warn('[AddMembers] Error: No brandId found in data');
                }
              } else {
                await inviteMembersForNonSLOrg(
                  data.map((x) => x.email),
                  data[0].role as InternalOrganizationRole,
                );
              }

              if (closeShareModalAfterUpdate) {
                onClose();
              }
            }}
            validateEmail={validateEmailBeforeAdd}
            guestOptions={{
              allowGuests: true,
              defaultSelectedRoleInUI:
                InternalOrganizationRole.SocialListeningUser,
            }}
          />
        </Dialog>
      )}
    </Box>
  );
};

const TabTitle = ({
  title,
  isActive,
}: {
  title: string;
  isActive: boolean;
}) => (
  <Typography
    variant="headline-sm"
    color={isActive ? theme.colors?.utility[1100] : theme.colors?.utility[600]}
  >
    {title}
  </Typography>
);
