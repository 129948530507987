import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Badge,
  Box,
  Menu,
  MenuProps,
  SxProps,
  Typography,
} from '@mui/material';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import {
  NestedFiltersMenuItem,
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuItemType,
} from 'features/nestedFilters/components';
import {
  NestedFiltersDefaultContextValues,
  useNestedFiltersDefaultContext,
} from 'features/nestedFilters/contexts/default';
import { countSelectedFilters } from 'features/nestedFilters/utils';
import { useEffect, useRef } from 'react';
import { theme } from 'styles/theme';

export type NestedFiltersMenuViewProps = Pick<
  MenuProps,
  'anchorOrigin' | 'transformOrigin'
> & {
  items: NestedFiltersMenuItemType[];
  values: Record<
    string,
    | NestedFiltersMenuItemBaseValueType<unknown>
    | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
  >;
  onChange: (
    values: Record<string, NestedFiltersMenuItemBaseValueType<unknown>>,
  ) => void;

  renderTrigger?: (props: {
    onOpen: () => void;
    anchorElRef: React.RefObject<HTMLButtonElement>;
  }) => JSX.Element;

  componentsProps?: {
    trigger?: {
      text?: string;
      Icon?: React.ComponentType<{ size: number }>;
      sx?: SxProps;
    };
    menu?: {
      sx?: SxProps;
    };
  };

  // NOTE: Not sure about this prop, but will keep it for now
  // Basically this marks the component as the primary source of the filter values
  isContextSource?: boolean;
  showSelectedFilterCount?: boolean;
  id?: string;
};

export const NestedFiltersMenuView = (props: NestedFiltersMenuViewProps) => {
  const {
    items,
    values,
    onChange,
    renderTrigger,
    componentsProps = {},
    isContextSource = false,
    showSelectedFilterCount = false,
    id = 'primary',
    ...menuProps
  } = props;

  // Get context if available
  const context = useNestedFiltersDefaultContext();

  // Update context if it exists
  useEffect(() => {
    if (context && isContextSource) {
      context.registerContextSource(id);
      context.setItems(items);
    }
  }, [JSON.stringify({ context, items, isContextSource, id })]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context && isContextSource) {
      // Mark values as coming from the primary source
      const markedValues = {
        ...values,
        __source: id,
      } as NestedFiltersDefaultContextValues['values'];
      context.setValues(markedValues);
    }
  }, [JSON.stringify({ context, values, isContextSource })]); // eslint-disable-line react-hooks/exhaustive-deps

  // Listen for context changes that didn't come from this component
  useEffect(() => {
    if (
      context &&
      isContextSource &&
      context.values &&
      context.values.__source !== id
    ) {
      // Remove the source marker before passing to onChange
      const { __source, ...cleanValues } = context.values;
      onChange(cleanValues);
    }
  }, [JSON.stringify({ values: context?.values, isContextSource })]); // eslint-disable-line react-hooks/exhaustive-deps

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const TriggerIcon = componentsProps.trigger?.Icon ?? IconBoldSetting5;
  const selectedFilterCount = countSelectedFilters({ items, values });

  return (
    <Box>
      {renderTrigger ? (
        renderTrigger({
          onOpen,
          anchorElRef,
        })
      ) : (
        <Badge
          badgeContent={selectedFilterCount}
          color="primary"
          invisible={!showSelectedFilterCount || selectedFilterCount === 0}
          sx={{
            '& .MuiBadge-badge': {
              backgroundColor: theme.colors?.primary.black,
              minWidth: 16,
              height: 16,
              fontSize: 11,
              padding: 0,
            },
          }}
        >
          <Box
            component="button"
            type="button"
            ref={anchorElRef}
            onClick={onOpen}
            sx={{
              borderRadius: 2,
              p: 2,
              bgcolor: theme.colors?.utility[275],
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              ...componentsProps.trigger?.sx,
            }}
          >
            <Box
              sx={{
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1,
                color: theme.colors?.primary.black,
                bgcolor: 'rgba(35, 6, 3, 0.05)',
              }}
            >
              <TriggerIcon size={16} />
            </Box>
            <Typography
              variant="subhead-xl"
              color={theme.colors?.primary.black}
              flex={1}
            >
              {componentsProps.trigger?.text ?? 'Filters'}
            </Typography>
          </Box>
        </Badge>
      )}

      <Menu
        anchorEl={anchorElRef.current}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -10,
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            maxHeight: '70vh',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(20px)',
            width: 266,
            p: 3,
            borderRadius: 3,
            boxShadow:
              'box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12); box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12);',
            ...componentsProps.menu?.sx,
          },
        }}
        {...menuProps}
      >
        {items.map((item, index) => {
          return (
            <NestedFiltersMenuItem
              key={index}
              menuItem={item}
              {...(item.key
                ? {
                    value: values[item.key] || {},
                    onChange: (newValue) => {
                      onChange({
                        ...values,
                        [item.key as string]: newValue,
                      });
                    },
                  }
                : {})}
            />
          );
        })}
      </Menu>
    </Box>
  );
};
