import { gql } from '@apollo/client';
import {
  Box,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AvatarWithName } from 'components/common/AvatarGroup/AvatarWithName';
import { ScrollableContainer } from 'components/common/ScrollableContainer';
import { typography } from 'components/common/Typography/styles';
import { IconCustomSort } from 'components/icons/components/custom/IconCustomSort';
import { IconCustomSortAsc } from 'components/icons/components/custom/IconCustomSortAsc';
import { IconCustomSortDesc } from 'components/icons/components/custom/IconCustomSortDesc';
import { ImageWithVideoFallback } from 'components/common/Image/ImageWithVideoFallback';
import { useUserContext } from 'contexts/users/User.context';
import {
  BrandOutboundEngagementPerformanceByUserFilters,
  BrandOutboundLeaderboardItemFragmentLeaderboardSectionFragment,
  SortByInputData,
  SortOrder,
  useGetBrandOutboundLeaderboardForLeaderboardSectionQuery,
  UserFragmentAvatarGroupFragmentDoc,
} from 'graphql/generated';
import { useGuardNavigate } from 'hooks/navigation/useGuardNavigation';
import moment from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

gql`
  fragment BrandOutboundLeaderboardItemFragmentLeaderboardSection on BrandOutboundLeaderboardItem {
    id
    user {
      id
      ...UserFragmentAvatarGroup
    }
    averageEngagement
    numberOfPostsCommentedOn
    maxLikes
    bestPerformingSocialPosts {
      id
      thumbnailUrl
      videoUrl
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetBrandOutboundLeaderboardForLeaderboardSection(
    $brandIds: [String!]!
    $filters: BrandOutboundLeaderboardFilters!
    $sortBy: SortByInputData
    $take: Int
    $after: String
  ) {
    brandOutboundLeaderboard(
      brandIds: $brandIds
      filters: $filters
      sortBy: $sortBy
      take: $take
      after: $after
    ) {
      data {
        id
        ...BrandOutboundLeaderboardItemFragmentLeaderboardSection
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${UserFragmentAvatarGroupFragmentDoc}
`;

export type LeaderboardSectionProps = {
  brandId: string;
  filters: BrandOutboundEngagementPerformanceByUserFilters;
};

export const LeaderboardSection = (props: LeaderboardSectionProps) => {
  const { brandId, filters } = props;

  const location = useLocation();
  const navigate = useGuardNavigate();

  const { user } = useUserContext();

  const [sortBy, setSortBy] = useState<SortByInputData | undefined>();

  const { data, loading, fetchMore } =
    useGetBrandOutboundLeaderboardForLeaderboardSectionQuery({
      variables: {
        brandIds: [brandId],
        filters,
        sortBy: sortBy || {
          field: 'averageEngagement',
          order: SortOrder.Desc,
        },
        take: 10,
      },
      fetchPolicy: 'cache-and-network',
    });
  const items: BrandOutboundLeaderboardItemFragmentLeaderboardSectionFragment[] =
    data?.brandOutboundLeaderboard.data || [];
  const isEmpty = !loading && items.length === 0;

  const fetchNextPage = async (_after?: string) => {
    const after = _after || items[items.length - 1]?.id;

    if (!after && allowPagination) {
      return;
    }

    await fetchMore({
      variables: {
        brandIds: [brandId],
        filters,
        sortBy: sortBy || {
          field: 'averageEngagement',
          order: SortOrder.Desc,
        },
        take: 10,
        after,
      },
      updateQuery: (prevData, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prevData;
        }

        return {
          brandOutboundLeaderboard: {
            data: [
              ...prevData.brandOutboundLeaderboard.data,
              ...fetchMoreResult.brandOutboundLeaderboard.data,
              // Dedupe by cursor
            ].filter(
              (e, index, self) =>
                index === self.findIndex((t) => t.id === e.id),
            ),
            pageInfo: {
              ...prevData.brandOutboundLeaderboard.pageInfo,
              hasNextPage:
                fetchMoreResult.brandOutboundLeaderboard.pageInfo.hasNextPage,
            },
          },
        };
      },
    });
  };

  const [allowPagination, _setAllowPagination] = useState(false);
  const setAllowPagination = (value: boolean) => {
    _setAllowPagination(value);
    if (value) {
      fetchNextPage(data?.brandOutboundLeaderboard.pageInfo.endCursor || '');
    }
  };

  const canToggleSeeAll =
    !allowPagination &&
    data?.brandOutboundLeaderboard.pageInfo.hasNextPage &&
    !isEmpty;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        maxHeight: 'calc(100vh - 64px)',
        overflow: 'hidden',
      }}
    >
      <Card
        sx={{
          flex: 1,
          borderRadius: 5,
          my: 2,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            gap: 4,
            p: 6,
            borderBottom: `1px solid ${theme.colors?.utility[300]}`,
            [theme.breakpoints.down('md')]: {
              flexWrap: 'wrap',
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={1}
          >
            <Typography variant="headline-md" fontSize={theme.spacing(5)}>
              Leaderboard
            </Typography>
            <Typography variant="subhead-xl" color={theme.colors?.utility[700]}>
              This displays the performance of the users engaging from your
              brand account.
              <br />
              All posts marked as 'Responded' by you on the Social Listening
              page will be counted as your comment.
            </Typography>
          </Box>
        </Box>
        <ScrollableContainer
          sx={{
            px: 6,
            pb: 6,
            flex: 1,
          }}
          hasNextPage={data?.brandOutboundLeaderboard.pageInfo.hasNextPage}
          onEndReached={() => {
            if (allowPagination) {
              fetchNextPage(
                data?.brandOutboundLeaderboard.pageInfo.endCursor || '',
              );
            }
          }}
        >
          <Table
            sx={{
              minWidth: 650,
              th: {
                ...typography['headline-xs'],
                color: theme.colors?.utility[700],
                py: 3,
                borderBottom: `2px solid ${theme.colors?.utility[275]}`,

                '&:first-child': {
                  paddingLeft: 0,
                },

                '&:last-child': {
                  paddingRight: 0,
                },
              },
              td: {
                ...typography['subhead-xl'],
                py: 3,
                borderBottom: `2px solid ${theme.colors?.utility[275]}`,

                '&:first-child': {
                  paddingLeft: 0,
                },

                '&:last-child': {
                  paddingRight: 0,
                },
              },
              'tr:last-child td': {
                borderBottom: !canToggleSeeAll ? 'none' : undefined,
              },
            }}
          >
            <TableHead>
              <TableRow>
                {[
                  {
                    key: 'rank',
                    label: 'Rank',
                    sortable: false,
                  },
                  {
                    key: 'name',
                    label: 'Name',
                    sortable: false,
                  },
                  {
                    key: 'averageEngagement',
                    label: 'Average Engagement',
                    sortable: true,
                  },
                  {
                    key: 'numberOfPostsCommentedOn',
                    label: '# posts commented on',
                    sortable: true,
                  },
                  {
                    key: 'maxLikes',
                    label: 'Max likes',
                    sortable: true,
                  },
                  {
                    key: 'bestPerformingSocialPosts',
                    label: 'Highest performing comments',
                    sortable: false,
                  },
                ].map((column, index) => {
                  return (
                    <TableCell
                      key={`${column.key}-${index}`}
                      onClick={() => {
                        if (column.sortable) {
                          if (sortBy?.field === column.key) {
                            if (sortBy.order === SortOrder.Desc) {
                              setSortBy({
                                field: column.key,
                                order: SortOrder.Asc,
                              });
                            } else {
                              setSortBy(undefined);
                            }
                          } else {
                            setSortBy({
                              field: column.key,
                              order: SortOrder.Desc,
                            });
                          }
                        }
                      }}
                    >
                      <Box
                        sx={{
                          display: 'inline-flex',
                          gap: 1,
                          alignItems: 'center',
                          cursor: column.sortable ? 'pointer' : 'default',
                        }}
                      >
                        {' '}
                        <Box component="span">{column.label}</Box>
                        {column.sortable ? (
                          !sortBy || sortBy.field !== column.key ? (
                            <IconCustomSort size={16} />
                          ) : sortBy.order === SortOrder.Asc ? (
                            <IconCustomSortAsc size={16} />
                          ) : (
                            <IconCustomSortDesc size={16} />
                          )
                        ) : null}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isEmpty
                ? //  If there's no data, show a single row with the current user
                  [
                    {
                      id: '',
                      user,
                      averageEngagement: undefined,
                      numberOfPostsCommentedOn: undefined,
                      maxLikes: undefined,
                      bestPerformingSocialPosts: new Array(3)
                        .fill(null)
                        .map(() => ({
                          id: '',
                          thumbnailUrl: '',
                          videoUrl: '',
                        })),
                    },
                  ]
                : items
              ).map((item, index) => {
                const bestPerformingSocialPosts =
                  item?.bestPerformingSocialPosts || [];
                return (
                  <TableRow
                    key={`${item.id}-${index}`}
                    sx={{
                      position: 'relative',

                      '&::after': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: -12,
                        width: 'calc(100% + 24px)',
                        height: '100%',
                        borderRadius: 3,
                        pointerEvents: 'none',
                        backgroundColor: theme.colors?.utility[275],
                        opacity: 0,
                        transition: 'opacity 0.2s',
                      },

                      '&:hover::after': {
                        opacity: 1,
                      },

                      td: {
                        position: 'relative',
                        zIndex: 1,
                      },
                    }}
                    onClick={() => {
                      if (!item.id) {
                        return;
                      }
                      navigate(
                        {
                          pathname:
                            PlotRoutes.socialListeningAnalyticsOutboundLeaderboardDetail(
                              item.id,
                              brandId,
                            ),
                          search: `?filters=${JSON.stringify(
                            filters,
                          )}&brandIds=${JSON.stringify([brandId])}`,
                        },
                        {
                          state: {
                            backgroundLocation: location,
                          },
                        },
                      );
                    }}
                  >
                    <TableCell>{index + 1}.</TableCell>
                    <TableCell>
                      <AvatarWithName
                        user={item.user}
                        avatarSize={24}
                        componentsProps={{
                          name: {
                            sx: {
                              fontSize: 16,
                            },
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {isNaN(item.averageEngagement)
                        ? 'n/a'
                        : formatBigNumber(item.averageEngagement)}
                    </TableCell>
                    <TableCell>
                      {isNaN(item.numberOfPostsCommentedOn)
                        ? 'n/a'
                        : formatBigNumber(item.numberOfPostsCommentedOn)}
                    </TableCell>
                    <TableCell>
                      {' '}
                      {isNaN(item.maxLikes)
                        ? 'n/a'
                        : formatBigNumber(item.maxLikes)}
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          gap: 2,
                          overflow: 'auto',
                        }}
                      >
                        {bestPerformingSocialPosts.map((socialPost, index) => {
                          return (
                            <Box
                              key={`${item.id}-${index}`}
                              sx={{
                                width: 'auto',
                                height: 72,
                                minWidth: 56,
                                borderRadius: 2,
                                bgcolor: theme.colors?.utility[275],
                                position: 'relative',
                                overflow: 'hidden',
                              }}
                            >
                              <ImageWithVideoFallback
                                imageUrl={socialPost.thumbnailUrl || ''}
                                videoUrl={socialPost.videoUrl || ''}
                                sx={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
              {loading &&
                new Array(10).fill(null).map((_, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Skeleton height={32} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={32} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={32} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={32} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={32} width={100} />
                      </TableCell>
                      <TableCell>
                        <Skeleton height={32} width={100} />
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {canToggleSeeAll && (
            <Typography
              component="button"
              type="button"
              variant="subhead-xl"
              sx={{
                display: 'block',
                textDecoration: 'underline',
                mt: 6,
                mx: 'auto',
                color: theme.colors?.utility[800],
              }}
              onClick={() => setAllowPagination(true)}
            >
              See all
            </Typography>
          )}
        </ScrollableContainer>
      </Card>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.utility[600]}
        ml="auto"
      >
        Last updated {moment().subtract(1, 'days').fromNow()}
      </Typography>
    </Box>
  );
};
