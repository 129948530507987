import { Box, Typography } from '@mui/material';
import { theme } from 'styles/theme';
import { NestedFiltersMenuItemBaseValueType } from '../menuItem';
import {
  NestedFiltersOptionGroupType,
  NestedFiltersOptionType,
} from './NestedFiltersMultiSelect';
import { NestedFiltersMultiSelectOption } from './NestedFiltersMultiSelectOption';

export type NestedFiltersMultiSelectOptionGroupProps = {
  optionGroup: NestedFiltersOptionGroupType;
  value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>;
  onChange: (
    value: NestedFiltersMenuItemBaseValueType<NestedFiltersOptionType[]>,
  ) => void;
};

export const NestedFiltersMultiSelectOptionGroup = (
  props: NestedFiltersMultiSelectOptionGroupProps,
) => {
  const { optionGroup, value: _value, onChange } = props;

  return (
    <Box>
      {optionGroup.label && (
        <Typography
          variant="subhead-sm"
          color={theme.colors?.utility[700]}
          sx={{
            px: 3,
          }}
        >
          {optionGroup.label}
        </Typography>
      )}
      {optionGroup.options.map((option, index) => {
        return (
          <NestedFiltersMultiSelectOption
            key={index}
            option={option}
            value={_value}
            onChange={onChange}
          />
        );
      })}
    </Box>
  );
};
