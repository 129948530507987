import { gql } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import { IconLinearMore } from 'components/icons/components/linear/IconLinearMore';
import { useCustomHeaderContext } from 'components/layouts/CustomHeader/contexts/CustomHeader.context';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import { BrandCompetitorActionContextMenu } from 'features/brandCompetitors';
import { BrandCompetitorDetailView } from 'features/brandCompetitors/views/brandCompetitorDetail';
import { SocialListeningUserPermissionsView } from 'features/socialListeningPermissions';
import {
  SocialListeningFeatureType,
  SocialMediaListeningTopHeaderBreadcrumbs,
  useBrandDetails,
} from 'features/socialMediaListening';
import {
  BrandPermission,
  CompetitorFragmentForBrandCompetitorActionContextMenuFragment,
  CompetitorFragmentForBrandCompetitorActionContextMenuFragmentDoc,
  PlotFeature,
  ViewEntityType,
} from 'graphql/generated';
import { usePageViewEntity } from 'hooks/useViewEntity';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment CompetitorFragmentForSLCompetitorDetailPage on CompetitorModel {
    ...CompetitorFragmentForBrandCompetitorActionContextMenu
  }
  ${CompetitorFragmentForBrandCompetitorActionContextMenuFragmentDoc}
`;

export const SocialMediaListeningCompetitorDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { competitorId = '', brandId = '' } = useParams();
  const { user } = useUserContext();

  const { brand, brandNotFound } = useBrandDetails({ brandId });

  const hasFullAccessToBrand = useMemo(() => {
    if (brandNotFound) {
      return false;
    }
    return (brand?.myPermissions || []).includes(BrandPermission.Update);
  }, [brand, brandNotFound]);

  const [competitor, setCompetitor] =
    useState<CompetitorFragmentForBrandCompetitorActionContextMenuFragment | null>(
      null,
    );

  const {
    setRenderTitle,
    addRenderMenuExtraLeft,
    removeRenderMenuExtraLeft,
    renderMenuExtraLeft,
  } = useCustomHeaderContext();
  const { isFeatureEnabled, loading } = useFeatureFlagContext();

  usePageViewEntity(competitorId, ViewEntityType.Competitor);

  useEffect(() => {
    if (!loading) {
      const _isFeatureEnabled = isFeatureEnabled(
        PlotFeature.CompetitorAnalysis,
      );
      if (
        !_isFeatureEnabled &&
        !user?.socialListeningBrands.find((b) => b.id === brandId)
      ) {
        navigate(PlotRoutes.home());
      }
    }
  }, [isFeatureEnabled, loading]); // eslint-disable-line
  useEffect(() => {
    const renderInviteButton = () => (
      <SocialListeningUserPermissionsView brandId={brandId} />
    );
    addRenderMenuExtraLeft(renderInviteButton, 1);

    if (competitor) {
      addRenderMenuExtraLeft(
        () => (
          <BrandCompetitorActionContextMenu
            renderButton={() => (
              <IconButton sx={{ ml: 1 }}>
                <IconLinearMore color={theme.colors?.primary.black} />
              </IconButton>
            )}
            competitor={competitor}
            canDelete={hasFullAccessToBrand}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onAfterDelete={() => {
              setTimeout(() => {
                navigate(PlotRoutes.socialListeningCompetitors(brandId));
              }, 100);
            }}
          />
        ),
        2,
      );
    }
    setRenderTitle(() => (
      <Box display="flex" alignItems="center" gap={3}>
        <SocialMediaListeningTopHeaderBreadcrumbs
          brandId={brandId}
          featureType={SocialListeningFeatureType.CompetitorAnalytics}
          componentProps={{
            brandSelector: {
              generateUrl: (brandId) =>
                PlotRoutes.socialListeningCompetitors(brandId),
            },
          }}
        />
      </Box>
    ));

    return () => {
      setRenderTitle(() => null);
      renderMenuExtraLeft?.map((render, index) =>
        // eslint-disable-next-line
        removeRenderMenuExtraLeft(() => <>{render ?? ''}</>, index),
      );
    };
  }, [
    location.pathname,
    JSON.stringify({ competitor, hasFullAccessToBrand, brandId }),
  ]);

  if (!competitorId) {
    navigate('/');
  }

  return (
    <BrandCompetitorDetailView
      currentBrandId={brandId}
      competitorId={competitorId}
      onSetCompetitor={setCompetitor}
    />
  );
};
