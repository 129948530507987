import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { ContextMenu, ContextMenuOption } from 'components/common/ContextMenu';
import { ContextMenuProps } from 'components/common/ContextMenu/types';
import { IconButtonWithTooltip } from 'components/common/IconButton/IconButtonWithTooltip';
import { IconBoldPauseCircle } from 'components/icons/components/bold/IconBoldPauseCircle';
import { IconBoldPlayCircle } from 'components/icons/components/bold/IconBoldPlayCircle';
import { IconLinearTimerPause } from 'components/icons/components/linear/IconLinearTimerPause';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  TopicFragmentTopicPauseContextMenuFragment,
  TopicStatus,
  usePauseTopicForTopicPauseContextMenuMutation,
  useResumeTopicForTopicPauseContextMenuMutation,
} from 'graphql/generated';
import moment from 'moment';
import { theme } from 'styles/theme';

export const TOPIC_FRAGMENT_TOPIC_PAUSE_CONTEXT_MENU = gql`
  fragment TopicFragmentTopicPauseContextMenu on TopicModel {
    id
    status
    resetOnNextCycle
  }
`;

// eslint-disable-next-line
gql`
  mutation PauseTopicForTopicPauseContextMenu($data: PauseTopicInput!) {
    pauseTopic(data: $data) {
      id
      ...TopicFragmentTopicPauseContextMenu
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_PAUSE_CONTEXT_MENU}
`;

// eslint-disable-next-line
gql`
  mutation ResumeTopicForTopicPauseContextMenu($data: ResumeTopicInput!) {
    resumeTopic(data: $data) {
      id
      ...TopicFragmentTopicPauseContextMenu
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_PAUSE_CONTEXT_MENU}
`;

export type TopicPauseContextMenuProps = Pick<
  ContextMenuProps,
  'disablePortal' | 'sx' | 'anchorOrigin' | 'transformOrigin'
> & {
  topic: TopicFragmentTopicPauseContextMenuFragment;
};

export const TopicPauseContextMenu = (props: TopicPauseContextMenuProps) => {
  const { topic, ...rest } = props;

  const [pauseTopic] = usePauseTopicForTopicPauseContextMenuMutation();
  const [resumeTopic] = useResumeTopicForTopicPauseContextMenuMutation();

  const isTopicPaused = topic.status === TopicStatus.Paused;

  if (isTopicPaused) {
    return (
      <IconButtonWithTooltip
        tooltip="Resume"
        size="small"
        onClick={() => {
          resumeTopic({
            variables: {
              data: {
                topicId: topic.id,
              },
            },
          });
        }}
      >
        <IconBoldPlayCircle size={24} />
      </IconButtonWithTooltip>
    );
  }

  return (
    <ContextMenu
      renderButton={() => {
        return (
          <IconButtonWithTooltip tooltip="Pause" size="small">
            <IconBoldPauseCircle size={24} />
          </IconButtonWithTooltip>
        );
      }}
      options={[
        {
          renderOption: () => (
            <ContextMenuOption
              label={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="subhead-lg">
                    Pause until reset
                  </Typography>
                  <Typography
                    variant="subhead-sm"
                    color={theme.colors?.utility[800]}
                  >
                    (will reset on{' '}
                    {moment()
                      .add(1, 'month')
                      .startOf('month')
                      .format('MM-DD-YYYY')}
                    )
                  </Typography>
                </Box>
              }
              icon={<IconOutlineCalendar1 size={16} />}
            />
          ),
          onClick: () => {
            pauseTopic({
              variables: {
                data: {
                  topicId: topic.id,
                },
              },
            });
          },
        },
        {
          renderOption: () => (
            <ContextMenuOption
              label="Pause indefinitely"
              icon={<IconLinearTimerPause size={16} />}
            />
          ),
          onClick: () => {
            pauseTopic({
              variables: {
                data: {
                  topicId: topic.id,
                  resetOnNextCycle: false,
                },
              },
            });
          },
        },
      ]}
      {...rest}
    />
  );
};
