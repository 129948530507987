import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledTabButton = styled(Box)<{ isActive: boolean }>(
  ({ isActive }) => ({
    ...theme.typography['headline-md'],
    color: theme.colors?.utility[700],
    ...(isActive && {
      borderRadius: 25,
      padding: theme.spacing(1.5, 3),
      background: 'rgba(35, 6, 3, 0.07)',
      color: theme.colors?.primary.black,
    }),
  }),
);

export const StyledTabContainer = styled(Box)({
  '.creator-profile-tabs': {
    '> :first-child': {
      position: 'sticky',
      top: 64,
      zIndex: 10,
      backgroundColor: theme.colors?.utility[200],
      padding: theme.spacing(3, 0),
    },
    '.MuiTabs-root': {
      borderBottom: 'none',
      minHeight: 'auto',
      '.MuiTab-root': {
        minHeight: 'auto',
        padding: 0,
      },
    },
    '.MuiTabs-indicator': {
      display: 'none',
    },
  },
});
