import { gql } from '@apollo/client';
import { Box, TextField, Typography } from '@mui/material';
import { useUserContext } from 'contexts/users/User.context';
import {
  useGetBrandRadarEventsConfigForCommunityHubQuery,
  useUpdateBrandRadarEventsConfigForCommunityHubMutation,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useEffect } from 'react';
import { theme } from 'styles/theme';
import { modifyObject } from 'utils/apollo';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetBrandRadarEventsConfigForCommunityHub($brandId: String!) {
    getBrandRadarEventsConfig(brandId: $brandId) {
      id
      engagementRateThreshold
      engagementThreshold
      mentionsThreshold
      brandId
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation UpdateBrandRadarEventsConfigForCommunityHub(
    $brandId: String!
    $input: UpdateBrandRadarEventsConfigInput!
  ) {
    updateBrandRadarEventsConfig(brandId: $brandId, input: $input) {
      success
      message
    }
  }
`;

type Props = {
  currentBrandId: string;
};

export const SocialListeningCommunityHubView = ({ currentBrandId }: Props) => {
  const { data: brandRadarEventsConfig, loading } =
    useGetBrandRadarEventsConfigForCommunityHubQuery({
      variables: {
        brandId: currentBrandId,
      },
    });

  const [updateBrandRadarEventsConfig] =
    useUpdateBrandRadarEventsConfigForCommunityHubMutation();

  const config = brandRadarEventsConfig?.getBrandRadarEventsConfig;

  const analytics = useAnalytics();
  const { user } = useUserContext();

  useEffect(() => {
    analytics.track(EventName.CommunityHubSettingsViewed, {
      brandId: currentBrandId,
      userId: user?.id,
    });
  }, [currentBrandId]);

  const validateInput = (
    value: number,
    type: 'mentions' | 'comments' | 'engagement',
  ): boolean => {
    if (isNaN(value) || value < 0) {
      return false;
    }

    switch (type) {
      case 'mentions':
      case 'comments':
        return Number.isInteger(value);
      case 'engagement':
        return value >= 0 && value <= 100;
      default:
        return false;
    }
  };

  const handleUpdateConfig = async (
    mentionsThreshold?: number,
    engagementThreshold?: number,
    engagementRateThreshold?: number,
  ) => {
    try {
      await updateBrandRadarEventsConfig({
        variables: {
          brandId: currentBrandId,
          input: {
            brandId: currentBrandId,
            data: {
              mentionsThreshold:
                mentionsThreshold ?? config?.mentionsThreshold ?? 0,
              engagementThreshold:
                engagementThreshold ?? config?.engagementThreshold ?? 0,
              engagementRateThreshold:
                engagementRateThreshold ?? config?.engagementRateThreshold ?? 0,
            },
          },
        },
        update: (cache) => {
          if (!config) {
            return;
          }

          modifyObject(cache, config.id, 'BrandRadarEventsConfigModel', {
            mentionsThreshold: () =>
              mentionsThreshold ?? config?.mentionsThreshold ?? 0,
            engagementThreshold: () =>
              engagementThreshold ?? config?.engagementThreshold ?? 0,
            engagementRateThreshold: () =>
              engagementRateThreshold ?? config?.engagementRateThreshold ?? 0,
          });
        },
      });

      analytics.track(EventName.CommunityHubSettingsUpdated, {
        brandId: currentBrandId,
        userId: user?.id,
      });
    } catch (error) {}
  };

  if (loading) {
    return null;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
      }}
    >
      <Typography variant="headline-lg">Community Hub</Typography>

      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="headline-sm">Community Updates</Typography>

        <Typography variant="body-xl" color={theme.colors?.utility[700]}>
          Stay informed about key creator moments and brand interactions.
          Customize thresholds to <br />
          track the engagement that matters most to you.
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" width="100%" gap={4}>
        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography
            variant="body-lg"
            fontWeight={600}
            minWidth={theme.spacing(50)}
          >
            Brand mentions (UGC)
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body-lg" fontWeight={500}>
              Alert me every
            </Typography>
            <TextField
              type="number"
              inputProps={{ min: 1, step: 1 }}
              defaultValue={config?.mentionsThreshold ?? 1}
              onBlur={(e) => {
                const value = Number(e.target.value);
                if (!validateInput(value, 'mentions')) {
                  return;
                }
                handleUpdateConfig(value);
              }}
              sx={{
                width: '80px',
                '.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(2),
                  bgcolor: 'rgba(0, 0, 0, 0.05)',
                  '& input': {
                    ...theme.typography['body-lg'],
                    textAlign: 'center',
                    p: theme.spacing(2),
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors?.utility[300],
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors?.utility[400],
                  },
                },
              }}
            />
            <Typography variant="body-lg" fontWeight={500}>
              mentions
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography
            variant="body-lg"
            fontWeight={600}
            minWidth={theme.spacing(50)}
          >
            Brand engagement
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body-lg" fontWeight={500}>
              Alert me every
            </Typography>
            <TextField
              type="number"
              inputProps={{ min: 1, step: 1 }}
              defaultValue={config?.engagementThreshold ?? 1}
              onBlur={(e) => {
                const value = Number(e.target.value);
                if (!validateInput(value, 'comments')) {
                  return;
                }
                handleUpdateConfig(undefined, value);
              }}
              sx={{
                width: '80px',
                '.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(2),
                  bgcolor: 'rgba(0, 0, 0, 0.05)',
                  '& input': {
                    ...theme.typography['body-lg'],
                    textAlign: 'center',
                    p: theme.spacing(2),
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors?.utility[300],
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors?.utility[400],
                  },
                },
              }}
            />
            <Typography variant="body-lg" fontWeight={500}>
              comments
            </Typography>
          </Box>
        </Box>

        <Box display="flex" flexDirection="row" gap={2} alignItems="center">
          <Typography
            variant="body-lg"
            fontWeight={600}
            minWidth={theme.spacing(50)}
          >
            UGC engagement rate
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="body-lg" fontWeight={500}>
              Alert me when a brand mention surpasses
            </Typography>
            <TextField
              type="number"
              defaultValue={config?.engagementRateThreshold ?? 1}
              onBlur={(e) => {
                const value = Number(e.target.value);
                if (!validateInput(value, 'engagement')) {
                  return;
                }
                handleUpdateConfig(undefined, undefined, value);
              }}
              sx={{
                width: '80px',
                '.MuiOutlinedInput-root': {
                  borderRadius: theme.spacing(2),
                  bgcolor: 'rgba(0, 0, 0, 0.05)',
                  '& input': {
                    ...theme.typography['body-lg'],
                    textAlign: 'center',
                    p: theme.spacing(2),
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors?.utility[300],
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.colors?.utility[400],
                  },
                },
              }}
            />
            <Typography variant="body-lg" fontWeight={500}>
              % engagement rate
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
