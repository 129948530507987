import { gql, useApolloClient } from '@apollo/client';
import {
  BookmarkFragmentForBookmarkHandlersFragment,
  BookmarkFragmentForBookmarkSidebarItemFragmentDoc,
  BookmarkType,
  useDeleteBookmarkForBookmarkHandlersMutation,
  useGetBookmarksForBookmarkHandlersQuery,
} from 'graphql/generated';
import { evictObject, modifyObject } from 'utils/apollo';

// eslint-disable-next-line
gql`
  mutation DeleteBookmarkForBookmarkHandlers($deleteBookmarkId: String!) {
    deleteBookmark(id: $deleteBookmarkId) {
      message
      success
    }
  }
`;

// eslint-disable-next-line
gql`
  fragment BookmarkFragmentForBookmarkHandlers on BookmarkModel {
    ...BookmarkFragmentForBookmarkSidebarItem
  }
  ${BookmarkFragmentForBookmarkSidebarItemFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetBookmarksForBookmarkHandlers {
    bookmarks {
      ...BookmarkFragmentForBookmarkHandlers
    }
  }
`;

const bookmarkTypeToBookmarkState = {
  [BookmarkType.SocialListeningEngagement]: 'socialListeningEngagement',
  [BookmarkType.BrandAnalytics]: 'brandAnalytics',
  [BookmarkType.BrandOwnedAnalytics]: 'ownedBrandAnalytics',
  [BookmarkType.BrandUgcAnalytics]: 'ugcAnalytics',
  [BookmarkType.BrandCompetitorAnalytics]: 'competitorAnalytics',
  [BookmarkType.BrandCommunityHub]: 'communityHub',
  [BookmarkType.Outbound]: 'outbound',
};

export const useBookmarkHandlers = () => {
  const [deleteBookmark] = useDeleteBookmarkForBookmarkHandlersMutation();
  const client = useApolloClient();
  const { updateQuery } = useGetBookmarksForBookmarkHandlersQuery({
    skip: true,
  });

  const addBookmarkItemToSidebar = (
    bookmark: BookmarkFragmentForBookmarkHandlersFragment,
  ) => {
    updateQuery((prev) => {
      return {
        ...prev,
        bookmarks: [bookmark, ...prev.bookmarks],
      };
    });
  };

  const removeBookmarkItemFromSidebar = (bookmarkId: string) => {
    evictObject(client.cache, bookmarkId, 'BookmarkModel');
  };

  const onDeleteBookmark = (
    bookmark: BookmarkFragmentForBookmarkHandlersFragment,
  ) => {
    deleteBookmark({
      variables: {
        deleteBookmarkId: bookmark.id,
      },
      update: (cache) => {
        switch (bookmark.type) {
          case BookmarkType.Collection:
            modifyObject(
              cache,
              bookmark.collection?.id ?? '',
              'CollectionModel',
              {
                isBookmarked: () => false,
              },
            );
            break;
          case BookmarkType.Competitor:
            modifyObject(
              cache,
              bookmark.competitor?.id ?? '',
              'CompetitorModel',
              {
                isBookmarked: () => false,
              },
            );
            break;
          case BookmarkType.Topic:
            modifyObject(cache, bookmark.topic?.id ?? '', 'TopicModel', {
              isBookmarked: () => false,
            });
            break;
          default:
            if (!bookmark.brand?.id) {
              break;
            }
            modifyObject(cache, bookmark.brand.id, 'BrandModel', {
              bookmarkState: (existing) => {
                return {
                  ...existing,
                  [bookmarkTypeToBookmarkState[bookmark.type]]: false,
                };
              },
            });
            break;
        }
        evictObject(cache, bookmark.id, 'BookmarkModel');
      },
    });
  };

  return {
    onDeleteBookmark,
    addBookmarkItemToSidebar,
    removeBookmarkItemFromSidebar,
  };
};
