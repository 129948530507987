import { Box, Radio, Typography, Avatar } from '@mui/material';
import { theme } from 'styles/theme';
import { useUserContext } from 'contexts/users/User.context';
import { useMemo } from 'react';

type Props = {
  selectedBrandId: string;
  onBrandSelect: (brandId: string) => void;
};

export const BrandSelectionView = ({
  selectedBrandId,
  onBrandSelect,
}: Props) => {
  const { user } = useUserContext();
  const slBrands = useMemo(() => user?.socialListeningBrands || [], [user]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: theme.spacing(6),
        gap: theme.spacing(3),
      }}
    >
      <Box sx={{ px: theme.spacing(3) }}>
        <Typography variant="headline-xs" color={theme.colors?.utility[1000]}>
          Which brand's Social Listening would you like to add them to?
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          maxHeight: '400px',
          overflow: 'auto',
        }}
      >
        {slBrands.map((brand) => (
          <Box
            key={brand.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(2),
              paddingY: theme.spacing(3),
              paddingX: theme.spacing(3),
              borderRadius: 2,
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.colors?.utility[275],
              },
            }}
            onClick={() => onBrandSelect(brand.id)}
          >
            <Radio
              checked={selectedBrandId === brand.id}
              sx={{
                color: theme.colors?.utility[600],
                '&.Mui-checked': {
                  color: theme.colors?.primary.black,
                },
                padding: 0,
              }}
            />
            <Avatar
              sx={{
                width: 20,
                height: 20,
              }}
              src={brand.creators[0]?.profilePictureUrl || ''}
            />
            <Typography
              variant="subhead-lg"
              color={theme.colors?.utility[1000]}
            >
              {brand.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
