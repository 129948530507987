import { gql } from '@apollo/client';
import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { MoreMenuItem } from 'components/common/Menu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useSLBrandPermissions } from 'features/socialMediaListening';
import {
  TopicLabel,
  TopicMoreContextMenu,
  TopicPauseContextMenu,
} from 'features/topic';
import {
  ListeningTopicType,
  PlotFeature,
  TopicFragmentTopicLabelFragmentDoc,
  TopicFragmentTopicMoreContextMenuFragmentDoc,
  TopicFragmentTopicPauseContextMenuFragmentDoc,
  TopicFragmentTopicSelectorFragment,
  TopicStatus,
  useGetBrandForTopicSelectorQuery,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

const TOPIC_FRAGMENT_TOPIC_SELECTOR = gql`
  fragment TopicFragmentTopicSelector on TopicModel {
    id
    name
    ...TopicFragmentTopicLabel
    ...TopicFragmentTopicMoreContextMenu
    ...TopicFragmentTopicPauseContextMenu
  }
  ${TopicFragmentTopicLabelFragmentDoc}
  ${TopicFragmentTopicMoreContextMenuFragmentDoc}
  ${TopicFragmentTopicPauseContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetBrandForTopicSelector($brandId: String!) {
    brand(id: $brandId) {
      id
      topics {
        ...TopicFragmentTopicSelector
      }
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_SELECTOR}
`;

interface SLATopicSelectorProps {
  selectedTopicId?: string;
  hideBrandMentionTopic?: boolean;
  brandId: string;
  onSelect: (topicId: string) => void;
}

export const SLATopicSelector = ({
  selectedTopicId,
  brandId,
  onSelect,
  hideBrandMentionTopic = false,
}: SLATopicSelectorProps) => {
  const [_showZeroState, setShowZeroState] = useState(false);
  const [selectedTopic, setSelectedTopic] =
    useState<TopicFragmentTopicSelectorFragment>();
  const { isFeatureEnabled } = useFeatureFlagContext();
  const { data: brandData, loading: loadingBrandData } =
    useGetBrandForTopicSelectorQuery({
      variables: {
        brandId,
      },
      skip: !brandId,
    });
  const { hasFullAccess } = useSLBrandPermissions({
    brandId,
  });
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);
  const topics = useMemo(() => {
    if (hideBrandMentionTopic) {
      return (brandData?.brand.topics ?? []).filter(
        (t) => t.type !== ListeningTopicType.BrandMention,
      );
    }
    return brandData?.brand.topics ?? [];
  }, [brandData, hideBrandMentionTopic]);

  useEffect(() => {
    if (loadingBrandData) return;

    if (topics.length) {
      const topic = topics.find((t) => t.id === selectedTopicId);
      setSelectedTopic(topic ?? topics[0]);
      setShowZeroState(false);
    } else if (topics.length === 0) {
      setShowZeroState(true);
    }
  }, [topics]); // eslint-disable-line

  if (_showZeroState) {
    return (
      <Box>
        <IconButton
          sx={{
            cursor: 'default',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
            padding: theme.spacing(2.5, 4),
            borderRadius: 25,
            backgroundColor: theme.colors?.utility[300],
          }}
          disableRipple
        >
          <IconLinearNote color={theme.colors?.utility[800]} />
          <Typography variant="headline-lg" color={theme.colors?.utility[600]}>
            No topics found
          </Typography>
        </IconButton>
      </Box>
    );
  }

  if (!selectedTopic) {
    return null;
  }

  return (
    <ContextMenu
      PaperProps={{
        sx: {
          '::-webkit-scrollbar': {
            width: 0,
          },
          '& li': {
            px: 1.5,
          },
        },
        style: {
          boxShadow:
            '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14)',
          border: 'none',
          minWidth: 400,
          maxHeight: '60vh',
          overflowY: 'auto',
          backdropFilter: 'none',
          backgroundColor: theme.colors?.primary.white,
        },
      }}
      renderButton={() => {
        const color = theme.colors?.utility[800];
        return (
          <IconButton
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
              padding: theme.spacing(2.5, 3),
              borderRadius: 25,
              backgroundColor: theme.colors?.utility[300],
            }}
            disableRipple
          >
            <IconLinearNote color={color} />
            <Typography variant="headline-lg" color={color}>
              {selectedTopic.name}
            </Typography>
            <TopicLabel topic={selectedTopic} />
            <IconLinearArrowDown size={16} color={color} />
          </IconButton>
        );
      }}
      options={[
        ...topics.map((topic) => ({
          renderOption: () => (
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              sx={{
                ':hover': {
                  '.actions': {
                    opacity: 1,
                  },
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTopic(topic);
                onSelect(topic.id);
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <IconCustomTick
                  size={16}
                  style={{
                    opacity: selectedTopic.id === topic.id ? 1 : 0,
                  }}
                />
                <Box flex={1} gap={1} display="grid">
                  <Typography
                    variant="subhead-lg"
                    color={
                      topic.status === TopicStatus.Paused
                        ? theme.colors?.utility[700]
                        : theme.colors?.primary.black
                    }
                  >
                    {topic.name}{' '}
                    {topic.status === TopicStatus.Paused && (
                      <Typography variant="headline-xxs">• Paused</Typography>
                    )}
                  </Typography>
                  <TopicLabel
                    topic={topic}
                    sx={{
                      width: 'fit-content',
                    }}
                  />
                </Box>
              </Box>
              <Box
                className="actions"
                sx={{
                  opacity: 0,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <TopicPauseContextMenu
                  topic={topic}
                  disablePortal
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                />
                <TopicMoreContextMenu
                  topic={topic}
                  disablePortal
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                />
              </Box>
            </Box>
          ),
        })),
        ...(hasFullAccess
          ? [
              {
                isDivider: true,
              },
              {
                renderOption: () => (
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      py: 1,
                      '& li': {
                        padding: 0,
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      },
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <MoreMenuItem
                      disableRipple
                      sx={{
                        minWidth: 240,
                        width: '100%',
                      }}
                      label={
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <Typography variant="subhead-lg">
                            Create a New Topic
                          </Typography>
                          <IconLinearArrowRight size={16} />
                        </Box>
                      }
                    >
                      <Box
                        sx={{
                          width: 270,
                          py: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        <Link
                          to={PlotRoutes.socialListeningOnboarding(
                            'topic',
                            brandId,
                          )}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 2,
                              padding: theme.spacing(3),
                              borderRadius: theme.spacing(3),
                              '&:hover': {
                                backgroundColor: theme.colors?.utility[275],
                              },
                            }}
                          >
                            <Typography variant="body-lg" fontWeight={500}>
                              Topic Insights
                            </Typography>
                            <Typography
                              variant="body-sm"
                              fontWeight={500}
                              color={theme.colors?.utility[900]}
                            >
                              Track conversations across customized topics
                            </Typography>
                          </Box>
                        </Link>
                        {creatorTrackingEnabled && (
                          <Link
                            to={PlotRoutes.socialListeningOnboarding(
                              'creator',
                              brandId,
                            )}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                padding: theme.spacing(3),
                                borderRadius: theme.spacing(3),
                                '&:hover': {
                                  backgroundColor: theme.colors?.utility[275],
                                },
                              }}
                            >
                              <Typography variant="body-lg" fontWeight={500}>
                                Creator Focus
                              </Typography>
                              <Typography
                                variant="body-sm"
                                fontWeight={500}
                                color={theme.colors?.utility[900]}
                              >
                                Track specific creators discussing your key
                                topics
                              </Typography>
                            </Box>
                          </Link>
                        )}
                      </Box>
                    </MoreMenuItem>
                  </Box>
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
