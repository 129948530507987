import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import { IconOutlineSearchNormal1 } from 'components/icons/components/outline/IconOutlineSearchNormal1';
import { theme } from 'styles/theme';

type Props = {
  searchStr: string;
  onChange: (value: string) => void;
  onSearch: (searchStr: string) => void;
  showHelperText?: boolean;
};

export const CreatorsSearch = ({
  searchStr,
  onChange,
  onSearch,
  showHelperText,
}: Props) => {
  return (
    <TextField
      fullWidth
      autoFocus
      placeholder="Search..."
      value={searchStr}
      disabled={false}
      InputProps={{
        startAdornment: (
          <InputAdornment
            sx={{ color: theme.colors?.utility[700] }}
            position="start"
          >
            <IconOutlineSearchNormal1 size={20} />
          </InputAdornment>
        ),
        endAdornment: showHelperText && (
          <InputAdornment
            sx={{ color: theme.colors?.utility[800] }}
            position="end"
          >
            <Typography variant="body-lg" color={theme.colors?.utility[800]}>
              <Box
                component="span"
                p={theme.spacing(0.5, 1)}
                sx={{
                  backgroundColor: 'rgba(250, 243, 236, 0.15)',
                  mx: 1,
                  border: `2px solid ${theme.colors?.utility[500]}`,
                  borderRadius: theme.spacing(2),
                  fontWeight: 600,
                  fontSize: theme.spacing(3),
                }}
              >
                ⏎
              </Box>{' '}
              to search
            </Typography>
          </InputAdornment>
        ),
      }}
      onChange={(e) => {
        onChange(e.target.value);
        if (e.target.value === '') {
          onSearch('');
        }
      }}
      onKeyDown={async (e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();

          onSearch(searchStr);
        }
      }}
      sx={{
        '.MuiOutlinedInput-root': {
          borderRadius: theme.spacing(10),
          py: `${theme.spacing(3)} !important`,
          px: `${theme.spacing(6)} !important`,
          bgcolor: 'rgba(250, 243, 236, 1)',
          color: theme.colors?.primary.black,

          '& .Mui-disabled': {
            backgroundColor: 'transparent',
            color: theme.colors?.primary.parchment,
          },

          input: {
            ...theme.typography['body-xl'],
            p: '0 !important',
          },

          '.MuiOutlinedInput-notchedOutline': {
            display: 'none !important',
          },
        },
      }}
    />
  );
};
