import { Box, IconButton, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { IconCustomTick } from 'components/icons/components/custom/IconCustomTick';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  FeatureType,
  FeatureTypeContextMenuView,
} from 'features/featureTypeContextMenu';
import { PlotFeature } from 'graphql/generated';
import { ReactNode, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SLPageBrandSelector } from '../sLPageBrandSelector';

export enum SocialListeningFeatureType {
  Engagement = 'Engagement',
  BrandAnalytics = 'BrandAnalytics',
  CompetitorAnalytics = 'CompetitorAnalytics',
  Outbound = 'Outbound',
  Community = 'Community',
}

const featureTypeLabelMap = {
  [SocialListeningFeatureType.Engagement]: 'Engagement',
  [SocialListeningFeatureType.BrandAnalytics]: 'Brand Analytics',
  [SocialListeningFeatureType.CompetitorAnalytics]: 'Competitor Analytics',
  [SocialListeningFeatureType.Outbound]: 'Outbound',
  [SocialListeningFeatureType.Community]: 'Community Hub',
};

interface SocialMediaListeningTopHeaderBreadcrumbsProps {
  featureType: SocialListeningFeatureType;
  brandId: string;
  customLabel?: ReactNode;
  componentProps?: {
    brandSelector?: {
      generateUrl: (brandId: string) => string;
    };
  };
}

export const SocialMediaListeningTopHeaderBreadcrumbs = ({
  featureType,
  brandId,
  customLabel,
  componentProps = {},
}: SocialMediaListeningTopHeaderBreadcrumbsProps) => {
  const { isFeatureEnabled } = useFeatureFlagContext();
  const navigate = useNavigate();

  const options = useMemo(() => {
    const options = Object.values(SocialListeningFeatureType);

    if (!isFeatureEnabled(PlotFeature.SocialListeningCrm)) {
      options.splice(options.indexOf(SocialListeningFeatureType.Community), 1);
    }

    return options;
  }, [isFeatureEnabled]);

  const onNavigate = (type: SocialListeningFeatureType) => {
    switch (type) {
      case SocialListeningFeatureType.Engagement:
        return PlotRoutes.socialListeningForBrand(brandId);
      case SocialListeningFeatureType.BrandAnalytics:
        return PlotRoutes.socialListeningAnalyticsInbound(brandId);
      case SocialListeningFeatureType.CompetitorAnalytics:
        return PlotRoutes.socialListeningCompetitors(brandId);
      case SocialListeningFeatureType.Outbound:
        return PlotRoutes.socialListeningAnalyticsOutbound(brandId);
      case SocialListeningFeatureType.Community:
        return PlotRoutes.socialListeningCommunity(brandId);
      default:
        return PlotRoutes.home();
    }
  };

  return (
    <>
      <Box
        onClick={() => navigate(-1)}
        mt={1}
        sx={{
          cursor: 'pointer',
        }}
      >
        <IconOutlineArrowLeft
          size={20}
          color={theme.colors?.primary.black}
          style={{ flexShrink: 0 }}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FeatureTypeContextMenuView
          featureType={FeatureType.SocialListening}
          customLabel="📣 Social Listening"
        />
        <Typography variant="headline-sm">/</Typography>
        <SLPageBrandSelector
          generateUrl={(brandId) => PlotRoutes.socialListeningForBrand(brandId)}
          {...componentProps?.brandSelector}
        />
        <Typography variant="headline-sm">/</Typography>
      </Box>
      <ContextMenu
        renderButton={(open) => {
          const isOpen = open ?? false;
          return (
            <IconButton
              sx={{
                ...theme.typography['headline-sm'],
                color: theme.colors?.primary.black,
                borderRadius: 2,
                backgroundColor: !isOpen
                  ? 'transparent'
                  : 'rgba(35, 6, 3, 0.05)',
                p: theme.spacing(1),
              }}
            >
              {customLabel ?? featureTypeLabelMap[featureType]}
            </IconButton>
          );
        }}
        options={options.map((key) => ({
          renderCustomComponent: () => {
            return (
              <Link to={onNavigate(key)}>
                <IconButton
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    width: '100%',
                    color: theme.colors?.primary.black,
                  }}
                >
                  <Typography variant="subhead-lg">
                    {featureTypeLabelMap[key]}
                  </Typography>

                  {key === featureType && (
                    <IconCustomTick
                      size={16}
                      color={theme.colors?.primary.black}
                    />
                  )}
                </IconButton>
              </Link>
            );
          },
        }))}
        PaperProps={{
          style: {
            minWidth: 266,
          },
          sx: {
            p: 3,
            ul: {
              gap: `${theme.spacing(2)} !important`,
            },
          },
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      />
    </>
  );
};
