import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Button, Checkbox, Grid, Radio, Typography } from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RightClickContextMenuWrapper } from 'components/common/RightClickContextMenuWrapper';
import { Tooltip } from 'components/common/Tooltip';
import { TooltipForTruncatedText } from 'components/common/TooltipForTruncatedText';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearEye } from 'components/icons/components/linear/IconLinearEye';
import { IconLinearEyeSlash } from 'components/icons/components/linear/IconLinearEyeSlash';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { BrandHashtagPostsView } from 'features/brandCompetitors/views/brandHashtagPosts/BrandHashtagPosts';
import {
  SortOrder,
  useGetHashtagUsedForCompetitorForCompetitorTopHashtagsQuery,
  useHideHashtagUsedForCreatorForCompetitorTopHashtagsMutation,
  useUnhideHashtagUsedForCreatorForCompetitorTopHashtagsMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';
import { CompetitorTopHashtagsSkeleton } from './CompetitorTopHashtagsSkeleton';
import { SocialMediaListeningCompetitorDetailTopHashtagsProps } from './types';

type HashtagType = [string, string]; // hashtag & engagement rate

enum SortKey {
  PlayCount = 'numberOfTimesUsed',
  EngagementRate = 'averageEngagementRate',
}

export const CompetitorTopHashtags = ({
  filters,
  trackingBrand,
  currentBrandId,
}: SocialMediaListeningCompetitorDetailTopHashtagsProps) => {
  const {
    isOpen: isHashtagPostsOpen,
    onOpen: onHashtagPostsOpen,
    onClose: onHashtagPostsClose,
  } = useDisclosure();
  const [params] = useSearchParams();
  const hashtag = params.get('hashtag');

  const [selectedHashtag, setSelectedHashtag] = useState('');
  const [includeHidden, setIncludeHidden] = useState(false);
  const [sortKey, setSortKey] = useState(SortKey.EngagementRate);
  const { competitorId } = filters;

  const [hideHashtagUsed] =
    useHideHashtagUsedForCreatorForCompetitorTopHashtagsMutation();
  const [unhideHashtagUsed] =
    useUnhideHashtagUsedForCreatorForCompetitorTopHashtagsMutation();

  useEffect(() => {
    if (hashtag) {
      setSelectedHashtag(hashtag);
      onHashtagPostsOpen();
    }
  }, [hashtag]); // eslint-disable-line

  const { data: hashtagsData, loading: loadingHashtags } =
    useGetHashtagUsedForCompetitorForCompetitorTopHashtagsQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        data: {
          ...filters,
          filters: {
            includeHidden,
          },
          take: 10,
          sortBy: {
            field: sortKey,
            order: SortOrder.Desc,
          },
        },
      },
    });

  const [hashTags, setHashTags] = useState([] as HashtagType[]);
  const [disabledHashtags, setDisabledHashtags] = useState([] as string[]);

  useEffect(() => {
    setHashTags(
      (hashtagsData?.getHashtagUsedByCompetitor || []).map((d) => [
        d.name,
        sortKey === SortKey.EngagementRate
          ? `${
              String(
                parseFloat(
                  (typeof d.averageEngagementRate === 'number'
                    ? d.averageEngagementRate * 100
                    : 0
                  ).toFixed(2),
                ),
              ) || '0'
            }%`
          : String(formatBigNumber(d?.numberOfTimesUsed || 0)),
      ]),
    );

    setDisabledHashtags(
      (hashtagsData?.getHashtagUsedByCompetitor || [])
        .filter((d) => d.isHidden)
        .map((d) => d.name),
    );
  }, [hashtagsData, sortKey]);

  // Function to distribute data into columns
  const distributeDataIntoColumns = (
    data: typeof hashTags,
    columns: number,
  ) => {
    const columnData: (typeof hashTags)[] = Array.from(
      { length: columns },
      () => [],
    );
    data.forEach((item, index) => {
      columnData[index % columns].push(item);
    });
    return columnData;
  };

  // Assuming 2 columns for the grid
  const columns = 2;
  const columnData = distributeDataIntoColumns(hashTags, columns);
  const hasHashtagData = hashTags.length > 0;

  const onHideHashtag = async (hashtag: string) => {
    await hideHashtagUsed({
      variables: {
        data: {
          competitorId,
          hashtagName: hashtag,
        },
      },
    });
    setDisabledHashtags([...disabledHashtags, hashtag]);
  };
  const onUnhideHashtag = async (hashtag: string) => {
    await unhideHashtagUsed({
      variables: {
        data: {
          competitorId,
          hashtagName: hashtag,
        },
      },
    });
    setDisabledHashtags(disabledHashtags.filter((h) => h !== hashtag));
  };

  if (loadingHashtags && !hashTags.length) {
    return <CompetitorTopHashtagsSkeleton />;
  }

  return (
    <>
      <Box
        display="flex"
        gap={4}
        flexDirection="column"
        p={8}
        sx={{
          borderRadius: 6,
          border: `1px solid ${theme.colors?.utility[300]}`,
          boxShadow: '0px 2px 10px -3px rgba(0, 0, 0, 0.05)',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography
            variant="headline-sm"
            color={theme.colors?.utility[700]}
            pb={1}
            display="flex"
            alignItems="center"
            gap={2}
          >
            Top hashtags
            <Tooltip title="The ranking of hashtags is based on the engagement of the posts that the hashtag was used on">
              <Box display="flex" alignItems="center">
                <IconBoldInfoCircle size={16} />
              </Box>
            </Tooltip>
          </Typography>

          <ContextMenu
            sx={{
              '& .MuiMenuItem-root': {
                p: 0,
                color: theme.colors?.primary.black,
              },
            }}
            options={[
              {
                renderCustomComponent: () => (
                  <Typography fontWeight={600} px={3} variant="body-lg">
                    Sort by
                  </Typography>
                ),
              },
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio
                      disableRipple
                      checked={sortKey === SortKey.EngagementRate}
                    />
                    <Typography variant="body-lg">
                      Post Engagement Rate
                    </Typography>
                  </Box>
                ),
                onClick: () => {
                  setSortKey(SortKey.EngagementRate);
                },
              },
              {
                renderOption: () => (
                  <Box display="flex" alignItems="center">
                    <Radio
                      disableRipple
                      checked={sortKey === SortKey.PlayCount}
                    />
                    <Typography variant="body-lg">
                      Number of times used
                    </Typography>
                  </Box>
                ),
                onClick: () => {
                  setSortKey(SortKey.PlayCount);
                },
              },
              {
                isDivider: true,
              },
              {
                renderCustomComponent: () => (
                  <Typography
                    fontWeight={600}
                    px={3}
                    pt={3}
                    variant="body-lg"
                    display="block"
                  >
                    Show
                  </Typography>
                ),
              },
              {
                renderOption: () => (
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      setIncludeHidden(!includeHidden);
                      setHashTags([]);
                      setDisabledHashtags([]);
                    }}
                  >
                    <Checkbox
                      sx={{
                        '&.Mui-checked': { color: theme.colors?.utility[800] },
                      }}
                      checked={includeHidden}
                      disableRipple
                    />
                    <Typography variant="body-lg">Hidden hashtags</Typography>
                  </Box>
                ),
              },
            ]}
            renderButton={() => (
              <Button
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  color: theme.colors?.primary.black,
                  borderRadius: theme.spacing(2),
                  display: 'flex',
                  gap: 2,
                  p: theme.spacing(1, 2),
                  alignItems: 'center',
                  '&:hover': { backgroundColor: theme.colors?.utility[275] },
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    backgroundColor: 'rgba(35, 6, 3, 0.05);',
                    p: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <IconLinearSort size={16} />
                </Box>
                <Typography fontWeight={500} variant="body-xl">
                  {sortKey === SortKey.EngagementRate && 'Post engagement rate'}
                  {sortKey === SortKey.PlayCount && 'Number of times used'}
                </Typography>
              </Button>
            )}
          />
        </Box>

        <Grid container spacing={2}>
          {hasHashtagData && (
            <>
              {columnData.map((column, columnIndex) => (
                <Grid item xs={12} sm={6} key={columnIndex}>
                  {column.map(([hashtag, percentage], index) => (
                    <RightClickContextMenuWrapper
                      renderMenu={(props) => (
                        <ContextMenu
                          options={[
                            ...(!disabledHashtags.includes(hashtag)
                              ? [
                                  {
                                    onClick: () => onHideHashtag(hashtag),
                                    renderOption: () => (
                                      <Button
                                        sx={{
                                          color:
                                            theme.colors?.utility['pink-3'],
                                          borderRadius: theme.spacing(2),
                                          display: 'flex',
                                          gap: 2,
                                          p: theme.spacing(1, 2),
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{
                                            backgroundColor:
                                              'rgba(128, 5, 50, 0.08)',
                                            p: theme.spacing(1),
                                            borderRadius: theme.spacing(1),
                                          }}
                                        >
                                          <IconLinearEyeSlash size={16} />
                                        </Box>
                                        <Typography
                                          fontWeight={500}
                                          variant="body-xl"
                                        >
                                          Hide hashtag
                                        </Typography>
                                      </Button>
                                    ),
                                  },
                                ]
                              : []),
                            ...(disabledHashtags.includes(hashtag)
                              ? [
                                  {
                                    onClick: () => onUnhideHashtag(hashtag),
                                    renderOption: () => (
                                      <Button
                                        sx={{
                                          color: theme.colors?.primary.black,
                                          borderRadius: theme.spacing(2),
                                          display: 'flex',
                                          gap: 2,
                                          p: theme.spacing(1, 2),
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Box
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          sx={{
                                            backgroundColor:
                                              theme.colors?.utility[275],
                                            p: theme.spacing(1),
                                            borderRadius: theme.spacing(1),
                                          }}
                                        >
                                          <IconLinearEye size={16} />
                                        </Box>
                                        <Typography
                                          fontWeight={500}
                                          variant="body-xl"
                                        >
                                          Unhide hashtag
                                        </Typography>
                                      </Button>
                                    ),
                                  },
                                ]
                              : []),
                          ]}
                          renderButton={false}
                          {...props}
                        />
                      )}
                    >
                      <Typography
                        variant="headline-sm"
                        display="flex"
                        width="100%"
                        justifyContent="space-between"
                        key={index}
                        my={3}
                        sx={{
                          cursor: 'pointer',
                        }}
                        color={
                          disabledHashtags.includes(hashtag)
                            ? theme.colors?.utility[700]
                            : theme.colors?.primary.black
                        }
                        onClick={() => {
                          setSelectedHashtag(hashtag);
                          onHashtagPostsOpen();
                        }}
                      >
                        <TooltipForTruncatedText title={hashtag}>
                          <Box
                            flex={1}
                            component="span"
                            display="flex"
                            alignItems="center"
                            gap={2}
                            pr={2}
                            color={
                              disabledHashtags.includes(hashtag)
                                ? theme.colors?.utility[700]
                                : theme.colors?.primary.black
                            }
                          >
                            {hashtag}
                            {disabledHashtags.includes(hashtag) && (
                              <IconLinearEyeSlash size={16} />
                            )}
                          </Box>
                        </TooltipForTruncatedText>

                        <Box
                          flex={1}
                          component="span"
                          color={theme.colors?.utility[800]}
                          textAlign="right"
                          mr={4}
                        >
                          {percentage}
                        </Box>
                      </Typography>
                    </RightClickContextMenuWrapper>
                  ))}
                </Grid>
              ))}
            </>
          )}
          {!hasHashtagData && (
            <Typography
              ml={2}
              mt={4}
              minHeight={140}
              variant="body-lg"
              color={theme.colors?.utility[700]}
              display="flex"
              justifyContent="center"
              width="100%"
            >
              No hashtags found
            </Typography>
          )}
        </Grid>
      </Box>
      {isHashtagPostsOpen && (
        <BrandHashtagPostsView
          currentBrandId={currentBrandId}
          trackingBrand={trackingBrand}
          hashtag={selectedHashtag}
          isOpen={isHashtagPostsOpen}
          onClose={() => {
            onHashtagPostsClose();
          }}
          baseFilters={filters}
        />
      )}
    </>
  );
};
