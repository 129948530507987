import { Box, IconButton, Typography } from '@mui/material';
import { IconBoldVolumeHigh } from 'components/icons/components/bold/IconBoldVolumeHigh';
import { IconBoldVolumeSlash } from 'components/icons/components/bold/IconBoldVolumeSlash';
import { IconBoldWarning2 } from 'components/icons/components/bold/IconBoldWarning2';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { theme } from 'styles/theme';

// Maximum number of retry attempts
const MAX_RETRY_ATTEMPTS = 3;
// Delay between retries in milliseconds
const RETRY_DELAY = 1000;

export type HoverPlayableVideoProps = {
  thumbnailUrl?: string;
  videoUrl: string;
  onPlay?: () => void;
  renderActionsTopRight?: (isHovered: boolean) => React.ReactNode;
  renderActionsBottom?: (isHovered: boolean) => React.ReactNode;
  renderExtraActionsTopLeft?: (isHovered: boolean) => React.ReactNode;
};

export const HoverPlayableVideo = ({
  thumbnailUrl,
  videoUrl,
  onPlay,
  renderActionsTopRight,
  renderActionsBottom,
  renderExtraActionsTopLeft,
}: HoverPlayableVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const thumbnailTimeoutRef = useRef<number | null>(null);
  const videoRetryTimeoutRef = useRef<number | null>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);
  const [isThumbnailValid, setIsThumbnailValid] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Thumbnail retry state
  const [thumbnailRetryCount, setThumbnailRetryCount] = useState(0);
  const [isThumbnailRetrying, setIsThumbnailRetrying] = useState(false);

  // Reset retry count when video URL changes
  useEffect(() => {
    setRetryCount(0);
    setHasError(false);
    setIsRetrying(false);
    setIsLoading(true);
  }, [videoUrl]);

  // Reset thumbnail retry count when thumbnail URL changes
  useEffect(() => {
    setThumbnailRetryCount(0);
    setIsThumbnailValid(false);
    setThumbnailLoaded(false);
    setIsThumbnailRetrying(false);
  }, [thumbnailUrl]);

  const attemptLoadVideo = useCallback(() => {
    if (!videoRef.current || !videoUrl) return;

    // Set loading state to true at the beginning to reflect accurate loading state
    setIsLoading(true);

    // Reset the video element
    try {
      videoRef.current.load();
    } catch (error) {
      console.error('Error reloading video:', error);
      // Only set loading to false on error, as successful load will trigger handleVideoLoad
      setIsLoading(false);
    }
  }, [videoUrl]);

  const attemptLoadThumbnail = useCallback(() => {
    if (!thumbnailUrl) {
      setIsThumbnailValid(false);
      setThumbnailLoaded(true);
      return;
    }

    const img = new Image();
    img.src = `${thumbnailUrl}${
      thumbnailUrl.includes('?') ? '&' : '?'
    }retry=${thumbnailRetryCount}`;

    img.onload = () => {
      setIsThumbnailValid(true);
      setThumbnailLoaded(true);
      setIsThumbnailRetrying(false);
    };

    img.onerror = () => {
      // If we haven't exceeded max retries, attempt retry
      if (thumbnailRetryCount < MAX_RETRY_ATTEMPTS) {
        setIsThumbnailRetrying(true);
        setThumbnailRetryCount((prev) => prev + 1);

        // Clear any existing timeout
        if (thumbnailTimeoutRef.current !== null) {
          clearTimeout(thumbnailTimeoutRef.current);
        }

        // Schedule a retry after delay and store the timeout ID
        thumbnailTimeoutRef.current = window.setTimeout(() => {
          thumbnailTimeoutRef.current = null;
          attemptLoadThumbnail();
        }, RETRY_DELAY);
      } else {
        // We've exhausted our retries, mark thumbnail as invalid
        setIsThumbnailValid(false);
        setThumbnailLoaded(true);
        setIsThumbnailRetrying(false);
        console.warn(
          `Failed to load thumbnail after ${MAX_RETRY_ATTEMPTS} attempts`,
        );
      }
    };
  }, [thumbnailUrl, thumbnailRetryCount]);

  const handleError = () => {
    // If we haven't exceeded max retries, attempt retry
    if (retryCount < MAX_RETRY_ATTEMPTS) {
      setIsRetrying(true);
      setRetryCount((prev) => prev + 1);

      // Clear any existing timeout
      if (videoRetryTimeoutRef.current !== null) {
        clearTimeout(videoRetryTimeoutRef.current);
      }

      // Schedule a retry after delay
      videoRetryTimeoutRef.current = window.setTimeout(() => {
        videoRetryTimeoutRef.current = null;
        attemptLoadVideo();
        setIsRetrying(false);
      }, RETRY_DELAY);
    } else {
      // We've exhausted our retries, show error
      setHasError(true);
      setIsRetrying(false);
      console.warn(`Failed to load video after ${MAX_RETRY_ATTEMPTS} attempts`);
    }
  };

  // Event handler when video successfully loads
  const handleVideoLoad = () => {
    setIsLoading(false);
    setHasError(false);
  };

  const handleMouseEnter = () => {
    if (videoRef.current && !hasError && !isRetrying) {
      try {
        // In tests, play() might not return a promise, so we
        // directly call it without catching the promise
        const playPromise = videoRef.current.play();

        // Only use .catch if playPromise is actually a Promise
        if (playPromise && typeof playPromise.catch === 'function') {
          playPromise.catch((error) => {
            // Don't treat autoplay blocking as a fatal error that needs retries
            // Only retry for network/loading errors
            if (error.name === 'NotAllowedError') {
              console.warn('Autoplay blocked by browser');
            } else {
              handleError();
            }
          });
        }
      } catch (error) {
        handleError();
      }

      // Call onPlay callback if provided
      if (onPlay) {
        onPlay();
      }
    }
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (videoRef.current && !isRetrying) {
      try {
        videoRef.current.pause();
        videoRef.current.load();
      } catch (error) {
        // Silently handle any errors
        console.error('Error when pausing video:', error);
      }
    }
    setIsHovered(false);
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  // Programmatically load the thumbnail image to check if it's valid
  useEffect(() => {
    if (thumbnailUrl) {
      attemptLoadThumbnail();
    } else {
      setIsThumbnailValid(false);
      setThumbnailLoaded(true);
    }

    // Return cleanup function
    return () => {
      // Clear any pending thumbnail retry timeout on unmount
      if (thumbnailTimeoutRef.current !== null) {
        clearTimeout(thumbnailTimeoutRef.current);
        thumbnailTimeoutRef.current = null;
      }
    };
  }, [thumbnailUrl, attemptLoadThumbnail]);

  // Initial video loading
  useEffect(() => {
    if (videoUrl) {
      attemptLoadVideo();
    }

    // Clear video retry timeout on unmount or when videoUrl changes
    return () => {
      if (videoRetryTimeoutRef.current !== null) {
        clearTimeout(videoRetryTimeoutRef.current);
        videoRetryTimeoutRef.current = null;
      }
    };
  }, [videoUrl, attemptLoadVideo]);

  const errorRender = useMemo(() => {
    // If there's an error loading the video or the video URL is empty
    // 1. Render thumbnail if it's valid, otherwise
    // 2. Render error message
    if ((hasError && !isRetrying) || !videoUrl) {
      if (isThumbnailValid) {
        return (
          <Box
            component="img"
            src={thumbnailUrl}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: 4,
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        );
      }

      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
          minHeight={100}
          sx={{
            backgroundColor: theme.colors?.utility[400],
            borderRadius: 4,
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          data-testid="error-message-container"
        >
          <Typography
            variant="body-md"
            color={theme.colors?.utility[700]}
            fontWeight={600}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <IconBoldWarning2 size={16} />
            {isRetrying
              ? `Retrying... (${retryCount}/${MAX_RETRY_ATTEMPTS})`
              : 'Error loading video'}
          </Typography>
        </Box>
      );
    }

    return null;
  }, [
    hasError,
    isThumbnailValid,
    thumbnailUrl,
    videoUrl,
    isRetrying,
    retryCount,
  ]);

  // Display a fallback thumbnail while the video is not hovered
  const renderThumbnail = !isHovered &&
    thumbnailLoaded &&
    isThumbnailValid &&
    !errorRender && (
      <Box
        component="img"
        src={thumbnailUrl}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: 4,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
        data-testid="thumbnail-image"
      />
    );

  return (
    <Box
      position="relative"
      width="100%"
      height="0"
      sx={{ paddingTop: '177.77%', overflow: 'hidden' }} // 56.25% for 16:9 aspect ratio
      {...(errorRender || isRetrying || isLoading
        ? {}
        : {
            onMouseEnter: handleMouseEnter,
            onMouseLeave: handleMouseLeave,
          })}
      data-testid="video-container"
    >
      {/* Show loading state if thumbnail is still being checked, video is loading or retrying */}
      {(isLoading || !thumbnailLoaded || isRetrying || isThumbnailRetrying) &&
        !errorRender && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: theme.colors?.utility[200],
              borderRadius: 4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            data-testid="loading-state"
          >
            <Typography
              variant="body-md"
              color={theme.colors?.utility[700]}
              fontWeight={600}
            >
              Loading video...
            </Typography>
          </Box>
        )}

      {errorRender || (
        <>
          {/* Always render the thumbnail if it's valid and not hovered */}
          {renderThumbnail}

          <Box
            position="absolute"
            top="0"
            left="0"
            width="100%"
            height="100%"
            sx={{
              borderRadius: 4,
              cursor: 'pointer',
              objectFit: 'cover',
              zIndex: isHovered ? 2 : 0, // Show video above thumbnail when hovered
            }}
            component="video"
            ref={videoRef}
            src={videoUrl}
            poster={isThumbnailValid ? thumbnailUrl : undefined}
            muted={isMuted}
            onError={handleError}
            onLoadedData={handleVideoLoad}
            data-testid="video-element"
          />

          <Box
            position="absolute"
            top={theme.spacing(2)}
            left={theme.spacing(2)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            sx={{ zIndex: 3 }}
          >
            <Box display="flex" gap={2} alignItems="center">
              {renderExtraActionsTopLeft &&
                renderExtraActionsTopLeft(isHovered)}
              {isHovered && (
                <IconButton
                  onClick={toggleMute}
                  disableRipple
                  sx={{
                    p: 1,
                    color: theme.colors?.primary.white,
                  }}
                  data-testid="mute-button"
                >
                  {isMuted ? (
                    <IconBoldVolumeSlash size={16} />
                  ) : (
                    <IconBoldVolumeHigh size={16} />
                  )}
                </IconButton>
              )}
            </Box>
          </Box>

          <Box
            position="absolute"
            top={theme.spacing(2)}
            right={theme.spacing(2)}
            minHeight={24}
            sx={{ zIndex: 3 }}
          >
            {renderActionsTopRight && renderActionsTopRight(isHovered)}
          </Box>
          {renderActionsBottom && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                px: 2,
                pb: 2,
                pt: 6,
                borderRadius: `0 0 16px 16px`,
                zIndex: 3,

                // Linear gradient from bottom to top, black -> transparent
                backgroundImage:
                  'linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6))',
              }}
            >
              {renderActionsBottom(isHovered)}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
