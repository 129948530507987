/**
 * This context acts as a "bridge" between multiple nested filters.
 * A nested filter will register as the source, setting the initial values & the configuration items of the filters.
 *
 * Example:
 * 1. Community Page > Filters is the primary, context source
 * 2. Community Page > NestedFiltersSelectedChips is the secondary. Its rendering logic will rely
 *    on how the primary filter is configured.
 * 3. When there are new changes coming from the filters, a `__source` key should be specified,
 *    indicating the source of the changes. Primary or secondary filters can rely on this to decide
 *    whether to update its values or not.
 */

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  NestedFiltersMenuItemBaseValueType,
  NestedFiltersMenuItemType,
} from '../../components';

export type NestedFiltersDefaultContextValues = {
  items: NestedFiltersMenuItemType[];
  setItems: (items: NestedFiltersMenuItemType[]) => void;
  values: Record<
    string,
    | NestedFiltersMenuItemBaseValueType<unknown>
    | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
  > & {
    __source?: string;
  };
  setValues: (
    values: Record<
      string,
      | NestedFiltersMenuItemBaseValueType<unknown>
      | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
    > & {
      __source?: string;
    },
  ) => void;

  registerContextSource: (key: string) => void;
};

const NestedFiltersDefaultContext =
  createContext<NestedFiltersDefaultContextValues | null>(null);

export type NestedFiltersDefaultContextProviderProps = {
  children: React.ReactNode;
};

export const NestedFiltersDefaultContextProvider = (
  props: NestedFiltersDefaultContextProviderProps,
) => {
  const { children } = props;

  const [contextSource, setContextSource] = useState<string>();

  const registerContextSource = useCallback(
    (key: string) => {
      if (contextSource && contextSource !== key) {
        throw new Error('Context source already registered');
      }

      setContextSource(key);
    },
    [contextSource],
  );

  const [items, setItems] = useState<NestedFiltersMenuItemType[]>([]);
  const [values, setValues] = useState<
    Record<
      string,
      | NestedFiltersMenuItemBaseValueType<unknown>
      | Record<string, NestedFiltersMenuItemBaseValueType<unknown>>
    >
  >({});

  const contextValue: NestedFiltersDefaultContextValues = useMemo(
    () => ({
      items,
      setItems,
      values,
      setValues,
      registerContextSource,
    }),
    [items, values, registerContextSource],
  );

  return (
    <NestedFiltersDefaultContext.Provider value={contextValue}>
      {children}
    </NestedFiltersDefaultContext.Provider>
  );
};

export const useNestedFiltersDefaultContext = () => {
  try {
    return useContext(NestedFiltersDefaultContext);
  } catch (error) {
    return null;
  }
};
