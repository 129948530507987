import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { theme } from 'styles/theme';
import { socialPostCommentsTableHeaders } from './SocialPostCommentsListView';

type SocialPostCommentsListViewSkeletonProps = {
  numberOfRows?: number;
};

export const SocialPostCommentsListViewSkeleton = ({
  numberOfRows = 5,
}: SocialPostCommentsListViewSkeletonProps) => {
  return (
    <Box width="100%">
      <Table>
        <TableHead>
          <TableRow>
            {socialPostCommentsTableHeaders.map((header) => (
              <TableCell
                key={header.key}
                sx={{
                  padding: theme.spacing(2.5),
                  borderBottom: '1px solid rgba(224, 224, 224, 1)',
                  width: header.width,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing(1),
                  }}
                >
                  <Skeleton width={80} height={24} />
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from({ length: numberOfRows }).map((_, index) => (
            <TableRow key={index}>
              {/* Date */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Skeleton width={80} height={24} />
              </TableCell>
              {/* Post */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Skeleton width={40} height={24} />
              </TableCell>
              {/* Question */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Skeleton width="90%" height={24} />
                  <Skeleton width="60%" height={24} />
                </Box>
              </TableCell>
              {/* Commented by */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Skeleton variant="circular" width={24} height={24} />
                  <Skeleton width={100} height={24} />
                </Box>
              </TableCell>
              {/* Likes */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Skeleton width={40} height={24} />
              </TableCell>
              {/* Comments */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Skeleton width={40} height={24} />
              </TableCell>
              {/* Links */}
              <TableCell sx={{ padding: theme.spacing(2.5) }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Skeleton width={24} height={24} />
                  <Skeleton width={24} height={24} />
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
