import { Box, Button, Typography } from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { theme } from 'styles/theme';
import { useEffect, useRef } from 'react';

type Props = {
  customTitle?: string;
  emails: string[];
  onDone: () => void;
};

export const SocialListeningUsersInvited = ({
  customTitle,
  emails,
  onDone,
}: Props) => {
  const doneButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Focus the Done button when component mounts
    doneButtonRef.current?.focus();
  }, []);

  return (
    <Box
      sx={{
        p: theme.spacing(6),
        backgroundColor: theme.colors?.primary.white,
        width: theme.spacing(100),
        minHeight: 'inherit',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        boxShadow:
          '0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);',
      }}
    >
      <Typography variant="headline-lg">
        {!customTitle ? 'Member Added ✅' : customTitle}
      </Typography>

      <Typography variant="body-xl">
        The below members have access to social listening now:
      </Typography>

      <Box display="flex" flexDirection="column" gap={2} flexWrap="wrap" mt={2}>
        {emails.map((email) => (
          <Box
            key={email}
            display="flex"
            gap={1}
            alignItems="center"
            sx={{
              backgroundColor: 'rgba(35, 6, 3, 0.10)',
              borderRadius: theme.spacing(25),
              p: theme.spacing(1, 2),
              width: 'fit-content',
            }}
          >
            <Avatar
              user={{
                id: '',
                email,
                firstName: '',
                lastName: '',
              }}
              size={16}
            />
            <Typography variant="body-xl" fontWeight={600}>
              {email}
            </Typography>
          </Box>
        ))}
      </Box>

      <Button
        ref={doneButtonRef}
        variant="contained"
        onClick={onDone}
        sx={{
          border: 'none',
          mt: 6,
          p: 3,
          borderRadius: theme.spacing(2),
          backgroundColor: theme.colors?.primary.black,
          fontWeight: 600,
          color: theme.colors?.primary.white,
          '&:hover': {
            backgroundColor: theme.colors?.primary.black,
            fontWeight: 600,
            color: theme.colors?.primary.white,
          },
          '&:focus': {
            outline: 'none',
            backgroundColor: theme.colors?.primary.black,
          },
          '&:focus-visible': {
            outline: `2px solid ${theme.colors?.primary.maroon}`,
            outlineOffset: -2,
          },
        }}
      >
        Done
      </Button>
    </Box>
  );
};
