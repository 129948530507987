import {
  GeneralPermission,
  PermissionLevel,
  PostFilterType,
} from 'graphql/generated';
import { CUSTOM_COLLECTION } from '../../collection/constants';
import { usePreviewablePostQuery } from '../../collection/views/card/customCollections/usePreviewablePostQuery';

export const useCustomCollections = () => {
  const {
    data: organizationPosts,
    previewablePosts: organizationPreviewablePosts,
  } = usePreviewablePostQuery({
    filters: {
      filterType: PostFilterType.OrganizationPosts,
    },
  });

  const { data: myPosts, previewablePosts: myPreviewablePosts } =
    usePreviewablePostQuery({
      filters: {
        filterType: PostFilterType.MyPosts,
      },
    });

  const { previewablePosts: myContentCalendarPreviewablePosts } =
    usePreviewablePostQuery({
      filters: {
        filterType: PostFilterType.ContentCalendar,
      },
    });

  const { data: myFavPosts, previewablePosts: myFavPreviewablePosts } =
    usePreviewablePostQuery({
      filters: {
        filterType: PostFilterType.MyFavoritePosts,
      },
    });

  const {
    data: mySavedTrendsPosts,
    previewablePosts: mySavedTrendsPreviewablePosts,
  } = usePreviewablePostQuery({
    filters: {
      filterType: PostFilterType.MySavedTrends,
    },
  });

  return {
    organizationPostsCollection: {
      __typename: 'CollectionModel' as 'CollectionModel',
      id: CUSTOM_COLLECTION.ALL_POSTS,
      name: 'All Posts',
      posts: organizationPreviewablePosts,
      totalPosts: organizationPosts?.posts.meta.totalCount || 0,
      totalChildCollections: 0,
      creatorId: '',
      generalPermission: GeneralPermission.OrganizationMembers,
      inviteMembers: [],
      hasPreviewPost: false,
      organizationName: '',
      myPermissions: [],
      isDisabled: false,
      isPinnedToRoot: false,
      isPinnedToParent: false,
      isBookmarked: false,
    },
    myPostsCollection: {
      __typename: 'CollectionModel' as 'CollectionModel',
      id: CUSTOM_COLLECTION.MY_POSTS,
      name: 'My Posts',
      posts: myPreviewablePosts,
      totalPosts: myPosts?.posts.meta.totalCount || 0,
      totalChildCollections: 0,
      creatorId: '',
      generalPermission: GeneralPermission.OrganizationMembers,
      inviteMembers: [],
      hasPreviewPost: false,
      organizationName: '',
      myPermissions: [],
      isDisabled: false,
      isPinnedToRoot: false,
      isPinnedToParent: false,
      isBookmarked: false,
    },
    myContentCalendarCollection: {
      __typename: 'CollectionModel' as 'CollectionModel',
      id: CUSTOM_COLLECTION.CONTENT_CALENDAR,
      name: 'Content Calendar',
      posts: myContentCalendarPreviewablePosts,
      totalPosts: 0,
      totalChildCollections: 0,
      creatorId: '',
      generalPermission: GeneralPermission.OrganizationMembers,
      permissionLevel: PermissionLevel.View,
      inviteMembers: [],
      hasPreviewPost: false,
      organizationName: '',
      myPermissions: [],
      isDisabled: false,
      isPinnedToRoot: false,
      isPinnedToParent: false,
      isBookmarked: false,
    },
    myFavPostsCollection: {
      __typename: 'CollectionModel' as 'CollectionModel',
      id: CUSTOM_COLLECTION.SAVED,
      name: 'Saved',
      posts: myFavPreviewablePosts,
      totalPosts: myFavPosts?.posts.meta.totalCount || 0,
      totalChildCollections: 0,
      creatorId: '',
      generalPermission: GeneralPermission.OrganizationMembers,
      inviteMembers: [],
      hasPreviewPost: false,
      organizationName: '',
      myPermissions: [],
      isDisabled: false,
      isPinnedToRoot: false,
      isPinnedToParent: false,
      isBookmarked: false,
    },
    mySavedTrendsPostsCollection: {
      __typename: 'CollectionModel' as 'CollectionModel',
      id: CUSTOM_COLLECTION.SAVED_TRENDS,
      name: 'My Saved Trends',
      posts: mySavedTrendsPreviewablePosts,
      totalPosts: mySavedTrendsPosts?.posts.meta.totalCount || 0,
      totalChildCollections: 0,
      creatorId: '',
      generalPermission: GeneralPermission.InviteOnly,
      inviteMembers: [],
      hasPreviewPost: false,
      organizationName: '',
      myPermissions: [],
      isDisabled: false,
      isPinnedToRoot: false,
      isPinnedToParent: false,
      isBookmarked: false,
    },
  };
};
