import { gql } from '@apollo/client';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { Timestamp } from 'components/common/Timestamp';
import { useUserContext } from 'contexts/users/User.context';
import {
  RadarEventTimelineItemInternalActivity,
  RadarEventTimelineItemSocialPost,
  RadarEventTimelineItemSocialPostComment,
} from 'features/radarEvent/components';
import {
  getIconByEventType,
  getMessageByEventType,
} from 'features/radarEvent/utils';
import {
  RadarEventFragmentRadarEventTimelineItemInternalActivityFragmentDoc,
  RadarEventFragmentRadarEventTimelineItemSocialPostCommentFragmentDoc,
  RadarEventFragmentRadarEventTimelineItemSocialPostFragmentDoc,
  RadarEventFragmentRadarEventTimelineItemViewFragment,
  RadarEventType,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';

export const RADAR_EVENT_FRAGMENT_RADAR_EVENT_TIMELINE_ITEM_VIEW = gql`
  fragment RadarEventFragmentRadarEventTimelineItemView on RadarEventModel {
    id
    brandId
    eventType
    platformCreateTime
    message
    radarEventActivities {
      id
      createdByUser {
        id
        firstName
        lastName
        email
      }
    }
    creator {
      id
      nickName
      handle
    }
    socialPostComment {
      id
      commentCreator {
        id
        nickName
        handle
      }
    }
    socialPost {
      id
      creator {
        id
        nickName
        handle
      }
    }
    ...RadarEventFragmentRadarEventTimelineItemInternalActivity
    ...RadarEventFragmentRadarEventTimelineItemSocialPostComment
    ...RadarEventFragmentRadarEventTimelineItemSocialPost
  }
  ${RadarEventFragmentRadarEventTimelineItemInternalActivityFragmentDoc}
  ${RadarEventFragmentRadarEventTimelineItemSocialPostCommentFragmentDoc}
  ${RadarEventFragmentRadarEventTimelineItemSocialPostFragmentDoc}
`;

export type RadarEventTimelineItemViewProps = {
  radarEvent: RadarEventFragmentRadarEventTimelineItemViewFragment;
  sx?: SxProps;
};

export const RadarEventTimelineItemView = (
  props: RadarEventTimelineItemViewProps,
) => {
  const { radarEvent, sx } = props;
  const analytics = useAnalytics();
  const { user } = useUserContext();

  const socialPost = radarEvent.socialPost;
  const socialPostComment = radarEvent.socialPostComment;
  const radarEventActivity = radarEvent.radarEventActivities?.[0];

  const Icon = getIconByEventType(
    radarEvent.eventType,
    radarEventActivity?.direction,
  );
  const isToday = moment(radarEvent.platformCreateTime).isSame(moment(), 'day');

  const getEventCreatorName = () => {
    if (radarEvent.creator) {
      return radarEvent.creator.handle;
    }

    if (
      socialPostComment &&
      socialPostComment.commentCreator &&
      [RadarEventType.Engagement].includes(radarEvent.eventType)
    ) {
      return socialPostComment.commentCreator.handle;
    }

    if (
      socialPost &&
      socialPost.creator &&
      [RadarEventType.Ugc, RadarEventType.Comment].includes(
        radarEvent.eventType,
      )
    ) {
      return socialPost.creator.handle;
    }

    if (radarEventActivity) {
      return getFullName(radarEventActivity.createdByUser);
    }

    return 'Unknown';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        gap: 4,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 1,
          width: 24,
          height: 24,
          bgcolor: 'rgba(35, 6, 3, 0.05)',
        }}
      >
        <Icon size={16} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          flex: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
          <Box>
            <Typography variant="headline-xs">
              {getEventCreatorName()}
            </Typography>{' '}
            <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
              {getMessageByEventType(radarEvent.eventType) ||
                radarEvent.message}
              {' · '}
              <Timestamp
                date={moment(radarEvent.platformCreateTime)}
                humanized={isToday}
                format="dddd hh:mma"
              />
            </Typography>
          </Box>
        </Box>
        {socialPost ? (
          <Box
            component={Link}
            to={PlotRoutes.socialListeningPost(
              socialPost.id,
              radarEvent.brandId,
            )}
            onClick={(e) => {
              analytics.track(EventName.CreatorProfileActivityPostClicked, {
                brandId: radarEvent.brandId,
                userId: user?.id,
                creatorProfileId: radarEvent.creator?.id,
                postId: socialPost.id,
              });
            }}
          >
            {[RadarEventType.Comment, RadarEventType.Engagement].includes(
              radarEvent.eventType,
            ) ? (
              socialPostComment ? (
                <RadarEventTimelineItemSocialPostComment
                  radarEvent={radarEvent}
                />
              ) : null
            ) : (
              <RadarEventTimelineItemSocialPost radarEvent={radarEvent} />
            )}
          </Box>
        ) : radarEventActivity ? (
          <RadarEventTimelineItemInternalActivity radarEvent={radarEvent} />
        ) : null}
      </Box>
    </Box>
  );
};
