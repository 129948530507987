import { Badge, Box, Button, Divider, Menu, Typography } from '@mui/material';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import { IconBoldTickCircle } from 'components/icons/components/bold/IconBoldTickCircle';
import { IconLinearShop } from 'components/icons/components/linear/IconLinearShop';
import { IconLinearTag2 } from 'components/icons/components/linear/IconLinearTag2';
import { SortOrder } from 'graphql/generated';
import { SocialListeningFiltersKey } from 'pages/socialMediaListening/constant';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { FilterBy } from './FilterBy/FilterBy';
import { SortBy } from './SortBy/SortBy';
import { SocialMediaListeningFilterSelectionProps } from './types';

export const SocialMediaListeningFilterSelection = ({
  filterByOptions,
  onFilterUpdated,
  sortByOptions,
  onSortUpdated,
  selectedFilters,
  selectedSortBy,
  brandId,
}: SocialMediaListeningFilterSelectionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // remove the keys that are not needed for the filter count
  const newSelectedFilters = useMemo(() => {
    const filters = { ...selectedFilters };
    delete filters[SocialListeningFiltersKey.Types];
    return filters;
  }, [selectedFilters]);

  // count the number of filters selected
  const selectedFilterCount = Object.values(newSelectedFilters).reduce<number>(
    (acc, value) => {
      if (Array.isArray(value) && value.length > 0) {
        return acc + 1;
      }
      if (typeof value === 'string' && value.trim() !== '') {
        return acc + 1;
      }
      if (typeof value === 'boolean' && value) {
        return acc + 1;
      }
      if (typeof value === 'number' && value > 0.5) {
        return acc + 1;
      }
      return acc;
    },
    0,
  );

  const isFilterSelected = selectedFilterCount > 0;

  return (
    <>
      <Badge
        badgeContent={selectedFilterCount}
        color="primary"
        invisible={!isFilterSelected}
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: theme.colors?.primary.black,
            minWidth: 16,
            height: 16,
            fontSize: 11,
            padding: 0,
          },
        }}
      >
        <Button
          onClick={handleClick}
          sx={{
            display: 'flex',
            backgroundColor:
              theme.colors?.utility[isFilterSelected ? 400 : 275],
            p: 3,
            borderRadius: 3,
            gap: 1,
            alignItems: 'center',
            '&:hover': {
              backgroundColor:
                theme.colors?.utility[isFilterSelected ? 400 : 275],
            },
          }}
        >
          <IconBoldSetting5
            size={12}
            color={theme.colors?.utility[isFilterSelected ? 800 : 700]}
          />
          <Typography
            variant="body-xl"
            fontWeight={isFilterSelected ? 600 : 500}
            color={theme.colors?.utility[isFilterSelected ? 800 : 700]}
          >
            Sort & Filter
          </Typography>
        </Button>
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            p: 3,
          },
        }}
      >
        <FilterBy
          selectedFilters={selectedFilters}
          filterByOptions={filterByOptions}
          onFilterUpdated={onFilterUpdated}
          brandId={brandId}
        />

        <SortBy
          currentSortBy={selectedSortBy}
          options={sortByOptions}
          onOptionClick={(key) => {
            if (key !== selectedSortBy) {
              onSortUpdated?.(key, SortOrder.Desc);
            }
          }}
        />

        <Divider
          sx={{
            width: theme.spacing(72),
          }}
        />

        <Box
          sx={{
            width: theme.spacing(72),
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            mt: 1,
          }}
        >
          <Button
            onClick={() => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.OnlyShowBrandMentions]:
                  !selectedFilters[
                    SocialListeningFiltersKey.OnlyShowBrandMentions
                  ],
              });
            }}
            sx={{
              width: '100%',
              minWidth: theme.spacing(20),
              gap: 0,
              justifyContent: 'flex-start',
              py: 1.5,
              px: 4,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            startIcon={
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  borderRadius: 2,
                  p: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconLinearShop size={20} color={theme.colors?.primary.black} />
              </Box>
            }
            endIcon={
              selectedFilters[
                SocialListeningFiltersKey.OnlyShowBrandMentions
              ] ? (
                <IconBoldTickCircle
                  size={20}
                  color={theme.colors?.primary.black}
                />
              ) : (
                <Box
                  sx={{
                    pr: 1,
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    border: `1px solid ${theme.colors?.primary.black}`,
                  }}
                />
              )
            }
          >
            <Typography
              variant="body-lg"
              sx={{
                textAlign: 'left',
                fontWeight: 500,
                width: '100%',
                flex: 1,
              }}
            >
              Only see brand mentions
            </Typography>
          </Button>

          <Button
            onClick={() => {
              onFilterUpdated({
                ...selectedFilters,
                [SocialListeningFiltersKey.IncludeTaggedPosts]:
                  !selectedFilters[
                    SocialListeningFiltersKey.IncludeTaggedPosts
                  ],
              });
            }}
            sx={{
              minWidth: theme.spacing(20),
              width: '100%',
              gap: 0,
              justifyContent: 'flex-start',
              py: 1.5,
              px: 4,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            startIcon={
              <Box
                sx={{
                  backgroundColor: theme.colors?.utility[275],
                  borderRadius: 2,
                  p: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <IconLinearTag2 size={20} color={theme.colors?.primary.black} />
              </Box>
            }
            endIcon={
              selectedFilters[SocialListeningFiltersKey.IncludeTaggedPosts] ? (
                <IconBoldTickCircle
                  size={20}
                  color={theme.colors?.primary.black}
                />
              ) : (
                <Box
                  sx={{
                    pr: 1,
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    border: `1px solid ${theme.colors?.primary.black}`,
                  }}
                />
              )
            }
          >
            <Typography
              variant="body-lg"
              sx={{
                textAlign: 'left',
                fontWeight: 500,
                width: '100%',
                flex: 1,
              }}
            >
              Include tagged mentions
            </Typography>
          </Button>
        </Box>
      </Menu>
    </>
  );
};
