import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, IconButton, Menu, Typography } from '@mui/material';
import { DateRangePicker } from 'components/common/DatePicker';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconLinearArrowDown } from 'components/icons/components/linear/IconLinearArrowDown';
import { IconLinearArrowRight } from 'components/icons/components/linear/IconLinearArrowRight';
import { IconOutlineCalendar } from 'components/icons/components/outline/IconOutlineCalendar';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

interface SLAAnalyticsFilterByPeriodProps {
  onToggle: (data: { label: string; range: Date[] }) => void;
  selectedPeriod?: {
    label: string;
    startDate: Date;
    endDate: Date;
  };
  oldestPostDate?: Date;
  renderTitle?: () => React.ReactNode;
}

export const LastNDaysDateLabels = {
  Last7Days: {
    label: 'Last 7 days',
    dateRange: [
      moment().subtract(7, 'day').startOf('day').toDate(),
      moment().toDate(),
    ],
  },
  Last30Days: {
    label: 'Last 30 days',
    dateRange: [
      moment().subtract(30, 'day').startOf('day').toDate(),
      moment().toDate(),
    ],
  },
  Last3Months: {
    label: 'Last 3 months',
    dateRange: [
      moment().subtract(3, 'month').startOf('day').toDate(),
      moment().toDate(),
    ],
  },
};

export const SLAAnalyticsFilterByPeriod = ({
  onToggle,
  selectedPeriod,
  renderTitle,
  oldestPostDate,
}: SLAAnalyticsFilterByPeriodProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    isOpen: dateRangeOpen,
    onOpen: onDateRangeOnOpen,
    onClose: onDateRangeOnClose,
  } = useDisclosure();

  const onSelectedPeriodLabel = useCallback(
    (
      selectedPeriodLabel: string | null,
      startDate: Date | null,
      endDate: Date | null,
    ) => {
      if (selectedPeriodLabel && startDate && endDate) {
        onToggle({
          label: selectedPeriodLabel,
          range: [startDate, endDate],
        });
      }
    },
    [onToggle],
  );
  const selectedPeriodLabel = selectedPeriod?.label;
  const selectedPeriodCustomStartDate = selectedPeriod?.startDate;
  const selectedPeriodCustomEndDate = selectedPeriod?.endDate;

  const oldestPostDateLabel = oldestPostDate
    ? moment(oldestPostDate).format('MMM DD, YYYY')
    : null;

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconOutlineCalendar
            size={16}
            style={{
              color: theme.colors?.primary.black,
            }}
          />
        </Box>

        <Typography variant="subhead-xl">
          {selectedPeriodLabel ?? 'Period'}
        </Typography>

        <IconLinearArrowDown size={16} color={theme.colors?.utility[700]} />
      </Box>
    );
  }, [renderTitle, selectedPeriodLabel]);

  return (
    <>
      <IconButton
        sx={{
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(2),
          p: theme.spacing(2),
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        {renderLabel}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
            border: 'none',
            '& ul > :first-child': {
              display: 'none',
            },
          },
        }}
      >
        <DropDownMoreMenuItem
          disableRipple
          singleSelectOnly
          defaultOpen
          label=""
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  gap: 2,
                },
              },
            },
          }}
          dropDownOptions={[
            {
              label: (
                <Typography variant="subhead-lg">
                  {LastNDaysDateLabels.Last7Days.label}
                </Typography>
              ),
              isChecked:
                selectedPeriodLabel === LastNDaysDateLabels.Last7Days.label,
              onClick: () => {
                if (
                  selectedPeriodLabel === LastNDaysDateLabels.Last7Days.label
                ) {
                  onSelectedPeriodLabel(null, null, null);
                } else {
                  onSelectedPeriodLabel(
                    LastNDaysDateLabels.Last7Days.label,
                    LastNDaysDateLabels.Last7Days.dateRange[0],
                    LastNDaysDateLabels.Last7Days.dateRange[1],
                  );
                }
              },
            },
            {
              label: (
                <Typography variant="subhead-lg">
                  {LastNDaysDateLabels.Last30Days.label}
                </Typography>
              ),
              isChecked:
                selectedPeriodLabel === LastNDaysDateLabels.Last30Days.label,
              onClick: () => {
                if (
                  selectedPeriodLabel === LastNDaysDateLabels.Last30Days.label
                ) {
                  onSelectedPeriodLabel(null, null, null);
                } else {
                  onSelectedPeriodLabel(
                    LastNDaysDateLabels.Last30Days.label,
                    LastNDaysDateLabels.Last30Days.dateRange[0],
                    LastNDaysDateLabels.Last30Days.dateRange[1],
                  );
                }
              },
            },
            {
              label: (
                <Typography variant="subhead-lg">
                  {LastNDaysDateLabels.Last3Months.label}
                </Typography>
              ),
              isChecked:
                selectedPeriodLabel === LastNDaysDateLabels.Last3Months.label,
              onClick: () => {
                if (
                  selectedPeriodLabel === LastNDaysDateLabels.Last3Months.label
                ) {
                  onSelectedPeriodLabel(null, null, null);
                } else {
                  onSelectedPeriodLabel(
                    LastNDaysDateLabels.Last3Months.label,
                    LastNDaysDateLabels.Last3Months.dateRange[0],
                    LastNDaysDateLabels.Last3Months.dateRange[1],
                  );
                }
              },
            },
            ...(oldestPostDate
              ? [
                  {
                    label: (
                      <Typography variant="subhead-lg">
                        Since {oldestPostDateLabel}
                      </Typography>
                    ),
                    isChecked: selectedPeriodLabel === oldestPostDateLabel,
                    onClick: () => {
                      if (selectedPeriodLabel === oldestPostDateLabel) {
                        onSelectedPeriodLabel(null, null, null);
                      } else {
                        onSelectedPeriodLabel(
                          oldestPostDateLabel,
                          oldestPostDate,
                          moment().toDate(),
                        );
                      }
                    },
                  },
                ]
              : []),
            {
              onClick: onDateRangeOnOpen,
              label: (
                <Box sx={{ width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant="subhead-lg">
                      {selectedPeriodLabel === CUSTOM_RANGE_LABEL
                        ? `${moment(selectedPeriodCustomStartDate).format(
                            'DD MMM',
                          )} - 
                                  ${moment(selectedPeriodCustomEndDate).format(
                                    'DD MMM',
                                  )}`
                        : CUSTOM_RANGE_LABEL}
                    </Typography>
                    <IconLinearArrowRight
                      size={16}
                      style={{
                        position: 'absolute',
                        right: 16,
                        marginTop: 2,
                      }}
                    />
                  </Box>
                  <DateRangePicker
                    value={
                      selectedPeriodLabel === CUSTOM_RANGE_LABEL
                        ? [
                            moment(selectedPeriodCustomStartDate),
                            moment(selectedPeriodCustomEndDate),
                          ]
                        : [null, null]
                    }
                    calendars={1}
                    open={dateRangeOpen}
                    onClose={onDateRangeOnClose}
                    onChange={(dates) => {
                      if (dates.length === 2 && dates[0] && dates[1]) {
                        onSelectedPeriodLabel(
                          CUSTOM_RANGE_LABEL,
                          dates[0].toDate(),
                          moment(dates[1]).endOf('day').toDate(),
                        );
                      }
                    }}
                    sx={{ '& .MuiTypography-root': { display: 'none' } }}
                    slots={{
                      // eslint-disable-next-line
                      textField: () => null,
                    }}
                    slotProps={{
                      actionBar: {
                        actions: [],
                      },
                    }}
                  />
                </Box>
              ),
              isChecked: selectedPeriodLabel === CUSTOM_RANGE_LABEL,
            },
          ]}
        />
      </Menu>
    </>
  );
};
