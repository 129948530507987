import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Popover, Typography } from '@mui/material';
import {
  CustomStackedBarChart,
  CustomStackedBarChartDataPoint,
  colors as chartColors,
} from 'components/common/CustomStackedBarChart';
import {
  ListeningTopicType,
  TopicFragmentTopicBreakdownPopoverViewFragment,
  TopicFragmentTopicMoreContextMenuFragmentDoc,
  TopicStatus,
  useGetAggregatedTopicDurationUsageForTopicBreakdownPopoverViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ListItem } from './ListItem';

export const TOPIC_FRAGMENT_TOPIC_BREAKDOWN_POPOVER_VIEW = gql`
  fragment TopicFragmentTopicBreakdownPopoverView on TopicModel {
    id
    name
    status
    resetOnNextCycle
    type
    ...TopicFragmentTopicMoreContextMenu
  }
  ${TopicFragmentTopicMoreContextMenuFragmentDoc}
`;

// eslint-disable-next-line
gql`
  query GetAggregatedTopicDurationUsageForTopicBreakdownPopoverView(
    $filters: AggregatedTopicDurationUsageFilters!
    $brandId: String!
  ) {
    aggregatedTopicDurationUsage(filters: $filters, brandId: $brandId) {
      topicId
      topic {
        id
        ...TopicFragmentTopicBreakdownPopoverView
        ...TopicFragmentTopicMoreContextMenu
      }
      durationUsed
    }
  }
  ${TOPIC_FRAGMENT_TOPIC_BREAKDOWN_POPOVER_VIEW}
  ${TopicFragmentTopicMoreContextMenuFragmentDoc}
`;

export type TopicBreakdownPopoverViewProps = {};

export const TopicBreakdownPopoverView = (
  props: TopicBreakdownPopoverViewProps,
) => {
  const { brandId = '' } = useParams();

  const {
    isOpen: isPopoverOpen,
    onOpen: openPopover,
    onClose: closePopover,
  } = useDisclosure();
  const anchorElRef = useRef<HTMLButtonElement | null>(null);

  const { data: aggregatedTopicDurationUsageData } =
    useGetAggregatedTopicDurationUsageForTopicBreakdownPopoverViewQuery({
      variables: {
        brandId,
        filters: {
          // By default, we show the current month's data
          dateRange: {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
          },
        },
      },
    });
  const aggregatedTopicDurationUsage = useMemo(
    () => aggregatedTopicDurationUsageData?.aggregatedTopicDurationUsage ?? [],
    [aggregatedTopicDurationUsageData],
  );

  const [highlightedChartDataPointIndex, setHighlightedChartDataPointIndex] =
    useState<number | null>(null);

  const chartData = useMemo(() => {
    const data = [
      ...aggregatedTopicDurationUsage.map((usage, index) => {
        return {
          label: usage.topic.name,
          value: usage.durationUsed / 3600,
          item: usage.topic,
          color: chartColors[index % chartColors.length],
        };
      }),
    ] as CustomStackedBarChartDataPoint<TopicFragmentTopicBreakdownPopoverViewFragment>[];

    // Order by: active -> paused -> deleted
    return [
      ...data.filter((i) => i.item?.status === TopicStatus.Active),
      ...data.filter((i) => i.item?.status === TopicStatus.Paused),
      ...data.filter((i) => i.item?.status === TopicStatus.Deleted),
    ];
  }, [aggregatedTopicDurationUsage]);

  if (
    !brandId ||
    !aggregatedTopicDurationUsageData?.aggregatedTopicDurationUsage.length
  ) {
    return null;
  }

  return (
    <>
      <Typography
        ref={anchorElRef}
        component="button"
        onClick={openPopover}
        variant="subhead-lg"
        sx={{
          textDecoration: 'underline',
        }}
      >
        View breakdown
      </Typography>
      <Popover
        anchorEl={anchorElRef.current}
        open={isPopoverOpen}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        // Do not apply the backdrop filter, as it prevents the context menu from appearing and causes the popover position to be incorrect
        PaperProps={{
          sx: {
            maxWidth: 444,
            width: '100vw',
            overflow: 'hidden',
            borderRadius: 4,
            boxShadow:
              '0px 18px 88px -4px rgba(24, 39, 75, 0.14), 0px 8px 28px -6px rgba(24, 39, 75, 0.12)',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            overflow: 'hidden',
            flex: 1,
          }}
        >
          <Box
            sx={{
              px: 6,
              pt: 6,
              pb: 2,
            }}
          >
            <CustomStackedBarChart
              data={chartData}
              highlightedIndex={highlightedChartDataPointIndex ?? undefined}
            />
          </Box>
          <Box
            sx={{
              maxHeight: '60vh',
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                flex: 1,
                overflow: 'auto',
                px: 3,
                pb: 6,
              }}
            >
              {chartData.map((i) => {
                const unsortedIndex = chartData.findIndex(
                  (data) => data.label === i.label,
                );

                return (
                  <ListItem
                    key={i.label}
                    topic={
                      i.item ?? {
                        id: '',
                        name: i.label,
                        status: TopicStatus.Active,
                        resetOnNextCycle: true,
                        type: ListeningTopicType.BrandMention,
                        isBookmarked: false,
                        brand: {
                          id: '',
                          creators: [],
                        },
                      }
                    }
                    durationUsedInHour={i.value}
                    sx={{
                      '::before': {
                        bgcolor: i.color,
                      },
                    }}
                    onMouseOver={() => {
                      setHighlightedChartDataPointIndex(unsortedIndex);
                    }}
                    onMouseOut={() => {
                      setHighlightedChartDataPointIndex(null);
                    }}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};
