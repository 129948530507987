import { Box } from '@mui/material';
import { Tabs } from 'components/common/Tabs';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import { useUserContext } from 'contexts/users/User.context';
import {
  useSLBrandPermissions,
  useSocialListeningBrandSelector,
} from 'features/socialMediaListening';
import { PlotFeature } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { SocialListeningSettingsCompetitorsView } from '../settingsCompetitors';
import { SocialListeningSettingsGeneralView } from '../settingsGeneral';
import { SocialListeningSettingsTopicsView } from '../settingsTopics';
import { SocialListeningUsersView } from '../socialListeningUsers';
import { SocialListeningCommunityHubView } from '../settingsCommunityHub/SocialListeningCommunityHubView';

export type TabType =
  | 'general'
  | 'topics'
  | 'competitors'
  | 'users'
  | 'community-hub';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SocialListeningSettingsView = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const defaultTab = params.get('tab');
  const currentTopicId = params.get('topicId');
  const brandId = params.get('brandId');

  const { user } = useUserContext();

  const { currentBrandId, renderBrandSelector, handleBrandSelect } =
    useSocialListeningBrandSelector({
      defaultBrandId: brandId ?? '',
    });
  const { hasFullAccess: userHasFullAccess } = useSLBrandPermissions({
    brandId: currentBrandId,
  });

  const [tab, _setTab] = useState((defaultTab || 'general') as TabType);
  const setTab = (tab: TabType) => {
    _setTab(tab);
    navigate(
      PlotRoutes.socialListeningSettings({
        tab,
        topicId: currentTopicId ?? '',
        brandId: currentBrandId ?? '',
      }),
    );
  };
  const { isFeatureEnabled } = useFeatureFlagContext();

  const isSLCrmEnabled = isFeatureEnabled(PlotFeature.SocialListeningCrm);

  useEffect(() => {
    if (defaultTab) {
      setTab(defaultTab as TabType);
    }
  }, [defaultTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (brandId) {
      handleBrandSelect(brandId);
    }
  }, [brandId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update to url query params when currentBrandId changes
  useEffect(() => {
    navigate(
      PlotRoutes.socialListeningSettings({
        tab,
        topicId: currentTopicId ?? '',
        brandId: currentBrandId ?? '',
      }),
      {
        replace: true,
      },
    );
  }, [currentBrandId]); // eslint-disable-line react-hooks/exhaustive-deps

  const userSlBrand = user?.socialListeningBrands?.find(
    (brand) => brand.id === currentBrandId,
  );
  const competitorAnalysisEnabled =
    isFeatureEnabled(PlotFeature.CompetitorAnalysis) ||
    (userSlBrand?.organizationId !== user?.organization.id &&
      userSlBrand?.competitorAnalysisEnabled);

  return (
    <Box
      key={`${currentTopicId}-${brandId}`}
      sx={{
        width: '100%',
        fontSize: theme.spacing(4),

        '& .settings-container': {
          width: '100%',
          height: `calc(100% - ${theme.spacing(20)})`,
        },
        '& button.MuiTab-root': {
          pb: 0,
          fontSize: theme.spacing(4),
          my: 3,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          mt: 6,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {renderBrandSelector()}
        {/* <Box>{renderShareSocialListeningButton()}</Box> */}
      </Box>

      <Tabs
        className="settings-container"
        tab={tab}
        onChange={(val) => {
          setTab(val as TabType);
        }}
        tabs={[
          {
            key: 'general',
            label: <Box>General</Box>,
            content: (
              <SocialListeningSettingsGeneralView
                key={currentBrandId}
                currentBrandId={currentBrandId || ''}
                readOnly={!userHasFullAccess}
              />
            ),
          },
          {
            key: 'users',
            label: <Box>Users</Box>,
            content: (
              <SocialListeningUsersView
                key={currentBrandId}
                brandId={currentBrandId}
              />
            ),
          },
          {
            key: 'topics',
            label: <Box>Topics</Box>,
            content: (
              <SocialListeningSettingsTopicsView
                key={currentBrandId}
                currentBrandId={currentBrandId || ''}
                currentTopicId={currentTopicId || ''}
                readOnly={!userHasFullAccess}
              />
            ),
          },
          ...(competitorAnalysisEnabled
            ? [
                {
                  key: 'competitors',
                  label: <Box>Competitors</Box>,
                  content: (
                    <SocialListeningSettingsCompetitorsView
                      key={currentBrandId}
                      currentBrandId={currentBrandId || ''}
                      readOnly={!userHasFullAccess}
                    />
                  ),
                },
              ]
            : []),
          ...(isSLCrmEnabled
            ? [
                {
                  key: 'community-hub',
                  label: <Box>Community Hub</Box>,
                  content: (
                    <SocialListeningCommunityHubView
                      currentBrandId={currentBrandId || ''}
                    />
                  ),
                },
              ]
            : []),
        ]}
      />
    </Box>
  );
};
