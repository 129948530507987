import { Box, Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { theme } from 'styles/theme';

type Props = {
  isUnread?: boolean;
};

export const RadarEventCommunityUpdateItemSkeleton = (props: Props) => {
  const { isUnread } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(3, 4),
        borderRadius: 4,
        background: isUnread ? 'rgba(35, 6, 3, 0.05)' : 'transparent',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 4,
        }}
      >
        <Skeleton variant="circular" width={56} height={56} />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Skeleton
              variant="text"
              width={Math.random() * 150 + 200}
              height={22}
            />
            <Skeleton variant="text" width={70} height={18} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 1.5,
            }}
          >
            <Skeleton
              variant="rounded"
              width={useMemo(() => Math.random() * 120 + 50, [])}
              height={26}
              sx={{ borderRadius: 2 }}
            />
            <Skeleton
              variant="rounded"
              width={useMemo(() => Math.random() * 80 + 60, [])}
              height={26}
              sx={{ borderRadius: 2 }}
            />
            <Skeleton
              variant="rounded"
              width={useMemo(() => Math.random() * 50 + 60, [])}
              height={26}
              sx={{ borderRadius: 2 }}
            />
          </Box>
        </Box>
      </Box>

      <Skeleton variant="circular" width={24} height={24} />
    </Box>
  );
};
