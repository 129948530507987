import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { theme } from 'styles/theme';

export const SocialListeningCreatorProfileMentionedBrandsViewSkeleton = () => {
  return (
    <Box
      sx={{
        px: 6,
        borderRadius: 6,
        border: `1px solid ${theme.colors?.utility[300]}`,
        backgroundColor: theme.colors?.primary.white,
        boxShadow: `0px 2px 10px -3px rgba(0, 0, 0, 0.05)`,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 450,
        overflowY: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 4,
          position: 'sticky',
          top: 0,
          backgroundColor: theme.colors?.primary.white,
          py: 6,
          zIndex: 1,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          {[1, 2].map((index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={140}
              height={36}
              sx={{ borderRadius: 2 }}
            />
          ))}
        </Box>
        <Skeleton variant="rounded" width={120} height={32} />
      </Box>

      {[1, 2, 3].map((index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            pb: 6,
          }}
        >
          <Skeleton variant="circular" width={48} height={48} />
          <Box
            sx={{ display: 'flex', flexDirection: 'column', gap: 1, flex: 1 }}
          >
            <Skeleton variant="text" width="30%" height={24} />
            <Skeleton variant="text" width="20%" height={20} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
