import { Input, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledInput = styled(Input)(() => ({
  ...theme.typography['headline-sm'],
  color: theme.colors?.utility[800],
  padding: 0,
  width: '100%',
  '& input': {
    padding: 0,
    height: 24,
  },
  '& input::placeholder': {
    color: theme.colors?.utility[600],
    opacity: 1,
  },
}));
