import { ReactNode } from 'react';

export enum TabName {
  CUSTOM_UI = 'Custom UI',
  SETTINGS = 'Settings',
  SUPPORT = 'Support',
  CREATIVE_JUICEBOX = 'Creative Juicebox',
  TRENDS = 'Trends',
  SOCIAL_LISTENING = 'Social Listening',
  ALL_TASKS = 'Tasks',
  CONTENT_CALENDAR = 'Content Calendar',
  TEXT_IDEAS = 'Text your ideas',
  DOWNLOAD_THE_MOBILE_APP = 'Download the Mobile App',
  LOG_OUT = 'Log Out',
  TOP_AUDIOS = 'Top Audios',
  GLOBAL_SEARCH = 'Global Search',
  NOTIFICATIONS = 'Notifications',
  HOME = 'Home',
  BOOKMARKS = 'Bookmarks',
  PRODUCTS = 'Products',
  FREQUENTLY_VISITED = 'Frequently Visited',
}

type DrawerTabTypeLink = {
  type: 'link';
  href: string;
  state?: any;
  icon: ({
    size,
    style,
  }: React.HTMLAttributes<SVGElement> & {
    size?: number | undefined;
  }) => React.ReactSVGElement;
};

type DrawerTabTypeComponent = {
  type: 'component';
  renderComponent: () => ReactNode;
};

type DrawerTabTypeButton = {
  type: 'button';
  icon: ({
    size,
    style,
  }: React.HTMLAttributes<SVGElement> & {
    size?: number | undefined;
  }) => React.ReactSVGElement;
};

type DrawerTabType =
  | DrawerTabTypeLink
  | DrawerTabTypeComponent
  | DrawerTabTypeButton;

export type DrawerTab = DrawerTabType & {
  name: TabName;
  shortCut?: string;
  tooltip?: string;
  beta?: boolean;
  new?: boolean;
  color?: string;
  paywall?: ReactNode;
  onClick?: VoidFunction;
  disabled?: boolean;
  loading?: boolean;
  isActive?: boolean;
};
