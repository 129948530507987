import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Divider,
  IconButton,
  Input,
  Menu,
  Typography,
} from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconBoldAddCircle } from 'components/icons/components/bold/IconBoldAddCircle';
import { IconLinearAdd } from 'components/icons/components/linear/IconLinearAdd';
import {
  RadarEventActivityDirection,
  RadarEventActivityFragmentSlRadarActivityHandlersFragment,
  useGetRadarEventActivitiesForSlRadarActivityHandlersQuery,
} from 'graphql/generated';
import { debounce } from 'lodash';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SocialListeningRadarActivityDialogView } from '../activityDialog';

export type SocialListeningRadarAddActivityButtonViewProps = {
  renderBtn?: React.ReactNode;
  radarEventId?: string;
  brandId: string;
  creatorProfileIds?: string[];
  onActivityAdded?: (
    radarEventActivityId: string,
    reusedExistingActivity?: boolean,
  ) => void;
};

export const SocialListeningRadarAddActivityButtonView = ({
  renderBtn,
  radarEventId,
  brandId,
  creatorProfileIds,
  onActivityAdded,
}: SocialListeningRadarAddActivityButtonViewProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isReusing, setIsReusing] = useState(false);
  const activityDialog = useDisclosure();
  const open = Boolean(anchorEl);
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState('');
  const debouncedSetQuery = debounce(setSearch, 250);

  const [selectedActivity, setSelectedActivity] =
    useState<RadarEventActivityFragmentSlRadarActivityHandlersFragment | null>(
      null,
    );

  const { data, fetchMore, loading } =
    useGetRadarEventActivitiesForSlRadarActivityHandlersQuery({
      variables: {
        input: {
          brandId,
          search,
          take: 10,
          deduplicateByNameAndCreator: true,
        },
      },
    });

  const activites = useMemo(() => {
    return data?.paginatedRadarEventActivities?.data || [];
  }, [data]);

  const onClose = () => {
    activityDialog.onClose();
    setAnchorEl(null);
    setSelectedActivity(null);
  };

  const onSave = (radarEventActivityId: string) => {
    onActivityAdded?.(radarEventActivityId, isReusing);
    onClose();
  };

  return (
    <Box>
      <IconButton
        sx={{ p: 0 }}
        disableRipple
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        {renderBtn ?? <IconBoldAddCircle color={theme.colors?.utility[700]} />}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        disablePortal
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 316,
            padding: 6,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
            border: 'none',
            '& ul > :nth-child(2)': {
              display: 'none',
            },
          },
        }}
      >
        <Box mb={2}>
          <IconButton
            sx={{
              p: 0,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: theme.colors?.primary.black,
            }}
            onClick={() => {
              setIsReusing(false);
              activityDialog.onOpen();
            }}
            disableRipple
          >
            <IconLinearAdd size={16} />
            <Typography variant="subhead-lg">Create new activity</Typography>
          </IconButton>
          <Divider sx={{ my: 6, borderColor: theme.colors?.utility[400] }} />

          <Typography variant="headline-xxs" color={theme.colors?.utility[700]}>
            Choose from existing activities
          </Typography>
        </Box>

        <DropDownMoreMenuItem
          defaultOpen
          label=""
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  maxHeight: 280,
                  overflow: 'auto',
                },
              },
            },
          }}
          loadMoreItems={() => {
            if (!data?.paginatedRadarEventActivities?.pageInfo.hasNextPage) {
              return null;
            }
            fetchMore({
              variables: {
                input: {
                  brandId,
                  search,
                  take: 20,
                  after:
                    data?.paginatedRadarEventActivities?.pageInfo.endCursor,
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  ...prev,
                  paginatedRadarEventActivities: {
                    ...prev.paginatedRadarEventActivities,
                    data: [
                      ...prev.paginatedRadarEventActivities.data,
                      ...fetchMoreResult.paginatedRadarEventActivities.data,
                    ].filter(
                      (activity, index, self) =>
                        self.findIndex((t) => t.id === activity.id) === index,
                    ),
                    pageInfo:
                      fetchMoreResult.paginatedRadarEventActivities.pageInfo,
                  },
                };
              },
            });
          }}
          renderDropDownOptionSearchInput={() => {
            return (
              <Input
                disableUnderline
                autoFocus
                value={query}
                placeholder="Search"
                onKeyDown={(e) => e.stopPropagation()}
                onChange={(e) => {
                  setQuery(e.currentTarget.value);
                  debouncedSetQuery(e.currentTarget.value);
                }}
                fullWidth
                sx={{
                  mb: 2,
                  p: theme.spacing(1, 3),
                  ...theme.typography['subhead-lg'],
                  backgroundColor: theme.colors?.utility[300],
                  borderRadius: 25,
                }}
              />
            );
          }}
          dropDownOptions={
            loading || activites.length === 0
              ? [
                  {
                    label: '',
                    value: '',
                    renderOption: () => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          pt: 4,
                        }}
                      >
                        <Typography
                          variant="subhead-lg"
                          color={theme.colors?.utility[700]}
                        >
                          {loading ? 'Loading...' : 'No activities'}
                        </Typography>
                      </Box>
                    ),
                  },
                ]
              : activites.map((activity) => ({
                  label: activity.title,
                  value: activity.id,
                  renderOption: () => (
                    <IconButton
                      sx={{
                        color: theme.colors?.primary.black,
                        justifyContent: 'flex-start',
                        maxWidth: 260,
                        borderRadius: 2,
                      }}
                      onClick={() => {
                        setSelectedActivity(activity);
                        setIsReusing(true);
                        activityDialog.onOpen();
                      }}
                    >
                      <Typography
                        variant="subhead-lg"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {activity.title}
                      </Typography>
                    </IconButton>
                  ),
                }))
          }
        />
      </Menu>
      {activityDialog.isOpen && (
        <SocialListeningRadarActivityDialogView
          radarEventId={radarEventId}
          onSave={onSave}
          onClose={onClose}
          brandId={brandId}
          variant="create"
          defaultValues={{
            direction: RadarEventActivityDirection.Outbound,
            title: '',
            summary: '',
            createdForCreatorProfileIds: creatorProfileIds ?? [],
            radarEventIds: radarEventId ? [radarEventId] : [],
            radarEventActivityCommunicationChannelIds: [],
            assetIds: [],
            dateTime: null,
            ...(selectedActivity
              ? {
                  assetIds: selectedActivity.assets.map((asset) => asset.id),
                  direction: selectedActivity.direction,
                  summary: selectedActivity.summary,
                  title: selectedActivity.title,
                  dateTime: selectedActivity.dateTime,
                  radarEventActivityCommunicationChannelIds:
                    selectedActivity.channels.map((channel) => channel.id),

                  // Other fields we reuse from the selected activity,
                  // but these two should be specific to the new activity - creator profile - radar event
                  createdForCreatorProfileIds: creatorProfileIds ?? [],
                  radarEventIds: radarEventId ? [radarEventId] : [],
                }
              : {}),
          }}
        />
      )}
    </Box>
  );
};
