import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { typography } from 'components/common/Typography/styles';
import { IconBoldEye } from 'components/icons/components/bold/IconBoldEye';
import { IconBoldHeart } from 'components/icons/components/bold/IconBoldHeart';
import { IconBoldLike } from 'components/icons/components/bold/IconBoldLike';
import { IconBoldMessage } from 'components/icons/components/bold/IconBoldMessage';
import { ExpandableText, SentimentIcon } from 'features/socialMediaListening';
import { SocialPostCardView } from 'features/socialPost';
import {
  RadarEventFragmentRadarEventTimelineItemSocialPostFragment,
  Sentiment,
  SocialPostFragmentSocialPostCardViewFragmentDoc,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

export const RADAR_EVENT_FRAGMENT_RADAR_EVENT_TIMELINE_ITEM_SOCIAL_POST = gql`
  fragment RadarEventFragmentRadarEventTimelineItemSocialPost on RadarEventModel {
    id
    brandId
    socialPost {
      id
      description
      diggCount
      commentCount
      playCount
      engagementRate
      brandAnalysis {
        id
        brandId
        sentimentScore
        sentimentTone
      }
      ...SocialPostFragmentSocialPostCardView
    }
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

export type RadarEventTimelineItemSocialPostProps = {
  radarEvent: RadarEventFragmentRadarEventTimelineItemSocialPostFragment;
};

export const RadarEventTimelineItemSocialPost = (
  props: RadarEventTimelineItemSocialPostProps,
) => {
  const { radarEvent } = props;

  const socialPost = radarEvent.socialPost;

  if (!socialPost) {
    return null;
  }

  const currentBrandAnalysis =
    socialPost.brandAnalysis.find((x) => x.brandId === radarEvent.brandId) ||
    socialPost.brandAnalysis?.[0];
  const sentimentScore = currentBrandAnalysis?.sentimentScore || 0;
  const sentiment =
    currentBrandAnalysis?.sentimentTone ||
    (sentimentScore > 0
      ? Sentiment.Positive
      : sentimentScore < 0
      ? Sentiment.Negative
      : Sentiment.Neutral);

  return (
    <Box
      sx={{
        p: 6,
        borderRadius: 3,
        bgcolor: theme.colors?.utility[250],
        display: 'flex',
        gap: 4,
      }}
    >
      <Box>
        <Box
          sx={{
            width: 100,
          }}
        >
          <SocialPostCardView socialPost={socialPost} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 4,
          flex: 1,
          minWidth: 0,
          wordBreak: 'break-word',
        }}
      >
        <ExpandableText
          collapsedHeight={64}
          text={socialPost.description}
          componentProps={{
            text: {
              sx: {
                ...typography['subhead-xl'],
              },
            },
            seeMore: {
              label: 'See more',
            },
            seeLess: {
              label: 'Hide',
            },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 4,
            flexWrap: 'wrap',
            alignItems: 'center',
            mt: 'auto',
          }}
        >
          {[
            ...(socialPost.diggCount >= 0
              ? [[IconBoldHeart, formatBigNumber(socialPost.diggCount || 0)]]
              : []),
            ...(socialPost.commentCount >= 0
              ? [
                  [
                    IconBoldMessage,
                    formatBigNumber(socialPost.commentCount || 0),
                  ],
                ]
              : []),
            ...(socialPost.playCount >= 0
              ? [[IconBoldEye, formatBigNumber(socialPost.playCount || 0)]]
              : []),
            ...(socialPost.engagementRate >= 0
              ? [
                  [
                    IconBoldLike,
                    `${(socialPost.engagementRate * 100).toFixed(0)}%`,
                  ],
                ]
              : []),
            [
              SentimentIcon,
              sentiment.charAt(0) + sentiment.slice(1).toLowerCase(),
            ],
          ].map(([Icon, text], index) => {
            return (
              <Box key={index} display="flex" gap={1} alignItems="center">
                <Icon
                  size={16}
                  color={theme.colors?.utility[800]}
                  sentiment={(text as string).toUpperCase() as Sentiment}
                />
                <Typography
                  color={theme.colors?.utility[800]}
                  variant="headline-xs"
                >
                  {String(text)}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
