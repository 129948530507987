import { Box } from '@mui/material';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { IconLinearMonitorMobile } from 'components/icons/components/linear/IconLinearMonitorMobile';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  NestedFiltersDateRangeOptionType,
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import { platformLabelMap } from 'features/socialPost';
import { Platform } from 'graphql/generated';
import moment from 'moment';
import { CreatorProfileFilters } from 'pages/socialMediaListening';
import { dateRangeOptions } from 'pages/socialMediaListening/community/Filters';

export type SocialListeningCreatorProfileFiltersViewProps = {
  filters: CreatorProfileFilters;
  setFilters: (filters: CreatorProfileFilters) => void;
};

export const SocialListeningCreatorProfileFiltersView = ({
  filters,
  setFilters,
}: SocialListeningCreatorProfileFiltersViewProps) => {
  return (
    <Box
      sx={{
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <NestedFiltersMenuView
        values={{
          platforms: {
            value: (filters.platforms ?? []).map((platform) => ({
              label: platformLabelMap[platform],
              value: platform,
            })),
          },
        }}
        componentsProps={{
          trigger: {
            text:
              filters.platforms.length > 0
                ? filters.platforms.join(', ')
                : 'Platforms',
            Icon: IconLinearMonitorMobile,
          },
        }}
        items={[
          {
            type: 'multi-select',
            key: 'platforms',
            options: [
              Platform.Instagram,
              Platform.Tiktok,
              Platform.Youtube,
            ].map((platform) => ({
              label: platformLabelMap[platform],
              value: platform,
            })),
          },
        ]}
        onChange={(values) => {
          setFilters({
            ...filters,
            platforms: (
              values.platforms.value as NestedFiltersOptionType[]
            )?.map((v) => v.value) as Platform[],
          });
        }}
      />

      <NestedFiltersMenuView
        values={{
          dateRange: {
            value: filters.dateRange,
          },
        }}
        onChange={(values) => {
          setFilters({
            ...filters,
            dateRange: {
              label: (
                values.dateRange.value as NestedFiltersDateRangeOptionType
              ).label,
              value: {
                startDate: (
                  values.dateRange.value as NestedFiltersDateRangeOptionType
                ).startDate,
                endDate: (
                  values.dateRange.value as NestedFiltersDateRangeOptionType
                ).endDate,
              },
            },
          });
        }}
        componentsProps={{
          trigger: {
            text:
              filters.dateRange.label === CUSTOM_RANGE_LABEL
                ? `${moment(filters.dateRange.value.startDate).format(
                    'MMM DD, YYYY',
                  )} - ${moment(filters.dateRange.value.endDate).format(
                    'MMM DD, YYYY',
                  )}`
                : filters.dateRange.label,
            Icon: IconOutlineCalendar1,
          },
        }}
        items={[
          {
            type: 'date-range',
            key: 'dateRange',
            options: dateRangeOptions,
          },
        ]}
      />
    </Box>
  );
};
