import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { ContextMenu } from 'components/common/ContextMenu';
import { RadioMenuItem } from 'components/common/form/Select';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineArrowLeft } from 'components/icons/components/outline/IconOutlineArrowLeft';
import { languages as allLanguages } from 'countries-list';
import {
  SocialMediaListeningFilterByDateRangeButton,
  SocialMediaListeningPostDetailView,
} from 'features/socialMediaListening';
import { SocialPostCardView } from 'features/socialPost';
import {
  BrandInboundFiltersInput,
  PaginatedBrandInboundFiltersInputSortField,
  SocialPostFragmentSocialPostCardViewFragmentDoc,
  SortOrder,
  useGetSocialPostsForSlaBrandAnalyticsLanguagesDialogViewQuery,
  useGetSocialPostsLanguagesDataForSlaBrandAnalyticsLanguagesDialogViewQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

const SORT_OPTIONS = [
  {
    label: 'Total Engagement',
    value: PaginatedBrandInboundFiltersInputSortField.TotalEngagement,
  },
  {
    label: 'Play Count',
    value: PaginatedBrandInboundFiltersInputSortField.TotalImpressions,
  },
] as const;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialPostsLanguagesDataForSLABrandAnalyticsLanguagesDialogView(
    $data: BrandInboundFiltersInput!
  ) {
    getSocialPostsLanguagesData(data: $data) {
      language
      totalEngagement
      totalImpressions
      totalPosts
      statsForFirstDate {
        totalEngagement
        totalImpressions
        totalPosts
      }
      statsForLastDate {
        totalEngagement
        totalImpressions
        totalPosts
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetSocialPostsForSLABrandAnalyticsLanguagesDialogView(
    $data: PaginatedBrandInboundFiltersInputForSocialPosts!
  ) {
    getBrandInboundSocialPosts(data: $data) {
      data {
        ...SocialPostFragmentSocialPostCardView
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      meta {
        totalCount
      }
    }
  }
  ${SocialPostFragmentSocialPostCardViewFragmentDoc}
`;

type Props = {
  open: boolean;
  onClose: () => void;
  selectedLanguage: string;

  filters: BrandInboundFiltersInput;
};

export const SLABrandAnalyticsLanguagesDialogView = ({
  open,
  onClose,
  filters,
  selectedLanguage,
}: Props) => {
  const [currentPostId, setCurrentPostId] = useState('');
  const [socialPostsSortKey, setSocialPostsSortKey] = useState(
    PaginatedBrandInboundFiltersInputSortField.TotalEngagement,
  );
  const [dateRange, setDateRange] = useState({
    startDate: moment(filters.dateRange.startDate).toDate(),
    endDate: moment(filters.dateRange.endDate).toDate(),
  });

  useEffect(() => {
    if (filters.dateRange.startDate && filters.dateRange.endDate) {
      setDateRange({
        startDate: moment(filters.dateRange.startDate).toDate(),
        endDate: moment(filters.dateRange.endDate).toDate(),
      });
    }
  }, [filters.dateRange.startDate, filters.dateRange.endDate, open]);

  const {
    data: socialPostsData,
    loading: socialPostsLoading,
    fetchMore: fetchMoreSocialPosts,
  } = useGetSocialPostsForSlaBrandAnalyticsLanguagesDialogViewQuery({
    variables: {
      data: {
        ...filters,
        languages: [selectedLanguage],
        dateRange: {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        },
        sortBy: {
          field: socialPostsSortKey,
          order: SortOrder.Desc,
        },
      },
    },
  });
  const { data: languagesData } =
    useGetSocialPostsLanguagesDataForSlaBrandAnalyticsLanguagesDialogViewQuery({
      variables: {
        data: {
          ...filters,
          languages: [selectedLanguage],
          dateRange: {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          },
        },
      },
    });
  const languageStatsData = languagesData?.getSocialPostsLanguagesData[0];

  const handleBackClick = () => {
    if (currentPostId) {
      setCurrentPostId('');
    } else {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '90vw',
          height: '90vh',
          backgroundColor: '#FAF3EC88',
          maxWidth: 'unset',
          maxHeight: 'unset',
          backdropFilter: 'blur(20px)',
          borderRadius: 4,
          margin: 'auto',
        },
      }}
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: theme.colors?.primary.parchment,
            borderBottom: `2px solid rgba(35, 6, 3, 0.10)`,
            p: 4,
          }}
        >
          <Box display="flex" alignItems="center" gap={3}>
            <IconButton
              onClick={handleBackClick}
              disableRipple
              sx={{
                color: theme.colors?.primary.black,
              }}
            >
              <IconOutlineArrowLeft size={20} />
            </IconButton>
            <Typography variant="headline-sm">
              {allLanguages[selectedLanguage.toLowerCase()]?.name ||
                selectedLanguage}
            </Typography>
          </Box>

          <IconButton onClick={onClose} disableRipple>
            <IconBoldCloseCircle
              style={{ color: theme.colors?.utility[700] }}
              size={24}
            />
          </IconButton>
        </Box>

        {currentPostId ? (
          <Box
            px={12}
            sx={{
              backgroundColor: theme.colors?.primary.parchment,
              minHeight: theme.spacing(100),
              overflow: 'auto',
            }}
          >
            <Box
              p={4}
              my={8}
              sx={{
                backgroundColor: theme.colors?.primary.white,
                borderRadius: theme.spacing(4),
              }}
            >
              <SocialMediaListeningPostDetailView
                brandId={filters.brandId}
                socialPostId={currentPostId}
                componentProps={{
                  modalView: true,
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box
            p={3}
            flexGrow={1}
            overflow="auto"
            sx={{ backgroundColor: '#FAF3ECCC' }}
          >
            <InfiniteScroll
              loadMore={() => {
                fetchMoreSocialPosts({
                  variables: {
                    data: {
                      ...filters,
                      languages: [selectedLanguage],
                      dateRange: {
                        startDate: dateRange.startDate,
                        endDate: dateRange.endDate,
                      },
                      after:
                        socialPostsData?.getBrandInboundSocialPosts.pageInfo
                          .endCursor,
                      sortBy: {
                        field: socialPostsSortKey,
                        order: SortOrder.Desc,
                      },
                    },
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return {
                      getBrandInboundSocialPosts: {
                        ...fetchMoreResult.getBrandInboundSocialPosts,
                        data: [
                          ...prev.getBrandInboundSocialPosts.data,
                          ...(fetchMoreResult?.getBrandInboundSocialPosts
                            .data || []),
                        ],
                      },
                    };
                  },
                });
              }}
              hasMore={
                socialPostsData?.getBrandInboundSocialPosts.pageInfo
                  .hasNextPage || false
              }
              threshold={50}
              useWindow={false}
            >
              <Box
                display="flex"
                alignItems="flex-start"
                gap={2}
                mb={3}
                justifyContent="space-between"
                width="100%"
                sx={{
                  p: 4,
                }}
              >
                <Box display="flex" flexDirection="column">
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <Typography
                      variant="headline-xl"
                      fontSize={24}
                      fontWeight={500}
                      letterSpacing="-0.96px"
                    >
                      {allLanguages[selectedLanguage.toLowerCase()]?.name ||
                        selectedLanguage}
                    </Typography>
                  </Box>

                  <Typography
                    variant="headline-sm"
                    mt={1}
                    color={theme.colors?.utility[700]}
                  >
                    {formatBigNumber(languageStatsData?.totalPosts || 0)} posts
                  </Typography>
                </Box>

                <SocialMediaListeningFilterByDateRangeButton
                  onChange={(range) => {
                    if (range[0] && range[1]) {
                      setDateRange({
                        startDate: range[0],
                        endDate: range[1],
                      });
                    }
                  }}
                  selectedDateRange={[dateRange.startDate, dateRange.endDate]}
                  componentProps={{
                    sx: {
                      backgroundColor: theme.colors?.primary.parchment,
                    },
                  }}
                />
              </Box>

              <Box sx={{ m: 4 }}>
                <Grid
                  container
                  spacing={2}
                  mb={3}
                  sx={{
                    backgroundColor: theme.colors?.primary.parchment,
                    borderRadius: 2,
                    p: 4,
                  }}
                >
                  <Grid item xs={4}>
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Total Engagement
                        <Tooltip title="Total Engagement">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                        fontFamily="Good Sans"
                      >
                        {formatBigNumber(
                          languageStatsData?.totalEngagement || 0,
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      borderLeft: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      pl={4}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Total post count
                        <Tooltip title="Total post count">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        fontFamily="Good Sans"
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                      >
                        {formatBigNumber(languageStatsData?.totalPosts || 0)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      borderLeft: `1px solid ${theme.colors?.utility[400]}`,
                    }}
                  >
                    <Box
                      flexDirection="column"
                      borderRadius={2}
                      display="flex"
                      alignItems="flex-start"
                      gap={1}
                      pl={4}
                    >
                      <Typography
                        variant="body-xl"
                        color={theme.colors?.utility[800]}
                        fontWeight={600}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Total view count
                        <Tooltip title="Total view count">
                          <Box
                            component="span"
                            display="flex"
                            alignItems="center"
                          >
                            <IconBoldInfoCircle size={16} />
                          </Box>
                        </Tooltip>
                      </Typography>
                      <Typography
                        fontSize={theme.spacing(8)}
                        fontFamily="Good Sans"
                        variant="headline-lg"
                        color={theme.colors?.utility[900]}
                      >
                        {formatBigNumber(
                          languageStatsData?.totalImpressions || 0,
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              <Box
                sx={{
                  borderBottom: `1px solid ${theme.colors?.utility[400]}`,
                  my: 4,
                }}
              />

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                m={4}
                mb={2}
              >
                <Typography variant="headline-sm">Posts</Typography>
                <ContextMenu
                  sx={{
                    '& .context-menu-item': {
                      p: 0,
                      color: theme.colors?.primary.black,
                    },
                  }}
                  options={SORT_OPTIONS.map((option) => ({
                    renderOption: () => (
                      <RadioMenuItem
                        label={option.label}
                        value={option.value}
                        checked={socialPostsSortKey === option.value}
                        sx={{
                          p: `${theme.spacing(2, 3)}`,
                        }}
                      />
                    ),
                    onClick: () => {
                      setSocialPostsSortKey(option.value);
                    },
                  }))}
                  renderButton={() => (
                    <Button
                      sx={{
                        backgroundColor: theme.colors?.utility[275],
                        color: theme.colors?.primary.black,
                        borderRadius: theme.spacing(2),
                        display: 'flex',
                        gap: 2,
                        p: theme.spacing(1, 2),
                        alignItems: 'center',
                        '&:hover': {
                          backgroundColor: theme.colors?.utility[275],
                        },
                      }}
                      disableRipple={false}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          backgroundColor: 'rgba(35, 6, 3, 0.05);',
                          p: theme.spacing(1),
                          borderRadius: theme.spacing(1),
                        }}
                      >
                        <IconLinearSort size={16} />
                      </Box>
                      <Typography fontWeight={500} variant="body-xl">
                        Sort by
                      </Typography>
                    </Button>
                  )}
                />
              </Box>

              {!socialPostsLoading && (
                <Box sx={{ m: 4 }}>
                  {socialPostsData?.getBrandInboundSocialPosts?.data?.length ? (
                    <Grid container spacing={2}>
                      {socialPostsData?.getBrandInboundSocialPosts?.data?.map(
                        (post) => {
                          return (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={3}
                              lg={2.4}
                              key={post.id}
                            >
                              <Box onClick={() => setCurrentPostId(post.id)}>
                                <SocialPostCardView socialPost={post} />
                              </Box>
                            </Grid>
                          );
                        },
                      )}
                    </Grid>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        variant="body-xl"
                        fontWeight={600}
                        color={theme.colors?.utility[800]}
                      >
                        No posts found
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </InfiniteScroll>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
