import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Input,
  Typography,
} from '@mui/material';
import { Avatar } from 'components/common/AvatarGroup';
import { DatePicker } from 'components/common/DatePicker';
import { IconLinearNote } from 'components/icons/components/linear/IconLinearNote';
import { IconLinearSend2 } from 'components/icons/components/linear/IconLinearSend2';
import { IconLinearUserOctagon } from 'components/icons/components/linear/IconLinearUserOctagon';
import { IconLinearDocumentUpload } from 'components/icons/components/linear/IconLinearDocumentUpload';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import { IconOutlineCloseCircle } from 'components/icons/components/outline/IconOutlineCloseCircle';
import { useUserContext } from 'contexts/users/User.context';
import { useCommandContext } from 'contexts/commands/Command.context';
import { COMMAND_TYPE } from 'contexts/commands/constants';
import {
  SocialListeningRadarCommunicationChannelSelector,
  SocialListeningRadarCreatorAutocomplete,
} from 'features/socialListeningRadar/components';
import { SocialListeningAssetsSelector } from 'features/socialListeningRadar/components/assetsSelector';
import { useSocialListeningRadarActivityHandlers } from 'features/socialListeningRadar/hooks';
import {
  CreateRadarEventActivityInput,
  RadarEventActivityDirection,
  UserFragmentAvatarGroupFragment,
} from 'graphql/generated';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { theme } from 'styles/theme';
import { getFullName } from 'utils/users';
import { StyledInoutContainer } from './styles';

export type SocialListeningRadarActivityDialogViewProps = {
  onSave: (radarEventActivityId: string) => void;
  onClose: () => void;
  radarEventId?: string;
  brandId: string;
  variant?: 'edit' | 'create';
  defaultValues?: CreateRadarEventActivityInput;
  radarEventActivityId?: string;
};

export const SocialListeningRadarActivityDialogView = ({
  onSave,
  onClose,
  radarEventId,
  brandId,
  variant = 'create',
  defaultValues,
  radarEventActivityId,
}: SocialListeningRadarActivityDialogViewProps) => {
  const analytics = useAnalytics();
  const { user } = useUserContext();
  const { triggerCommand } = useCommandContext();
  const [isDragging, setIsDragging] = useState(false);
  const shouldShowAfterCreationDialog = false;

  const [activityInput, setActivityInput] =
    useState<CreateRadarEventActivityInput>(
      defaultValues ?? {
        direction: RadarEventActivityDirection.Outbound,
        title: '',
        summary: '',
        createdForCreatorProfileIds: [],
        radarEventIds: radarEventId ? [radarEventId] : [],
        radarEventActivityCommunicationChannelIds: [],
        assetIds: [],
        dateTime: null,
      },
    );

  const { onCreateRadarEventActivity, onUpdateRadarEventActivity } =
    useSocialListeningRadarActivityHandlers();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit = async () => {
    setIsSubmitting(true);

    try {
      if (variant === 'create') {
        const { data } = await onCreateRadarEventActivity(activityInput);

        if (!data) {
          throw new Error('Could not create new activity');
        }

        onSave(data.createRadarEventActivity.id);
      } else {
        if (!radarEventActivityId) {
          throw new Error('radarEventActivityId is required for update');
        }

        await onUpdateRadarEventActivity({
          id: radarEventActivityId,
          data: activityInput,
        });

        onSave(radarEventActivityId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const canSubmit = useMemo(() => {
    return (
      !isSubmitting &&
      activityInput.createdForCreatorProfileIds.length > 0 &&
      activityInput.radarEventActivityCommunicationChannelIds.length > 0 &&
      activityInput.title.trim()
    );
  }, [activityInput, isSubmitting]);

  const onToggleCreator = (creatorId: string) => {
    if (activityInput.createdForCreatorProfileIds.includes(creatorId)) {
      setActivityInput((prev) => ({
        ...prev,
        createdForCreatorProfileIds: prev.createdForCreatorProfileIds.filter(
          (id) => id !== creatorId,
        ),
      }));
    } else {
      setActivityInput((prev) => ({
        ...prev,
        createdForCreatorProfileIds: [
          ...prev.createdForCreatorProfileIds,
          creatorId,
        ],
      }));
    }
  };

  const renderInputLabel = (Icon: React.ElementType, label: string) => {
    return (
      <Box display="flex" alignItems="center" gap={1} minWidth={150}>
        <Icon
          size={16}
          color={theme.colors?.utility[700]}
          style={{ flexShrink: 0 }}
        />
        <Typography variant="subhead-lg" color={theme.colors?.utility[700]}>
          {label}
        </Typography>
      </Box>
    );
  };

  const onDrop = async (acceptedFiles: File[]) => {
    setIsDragging(false);
    triggerCommand(COMMAND_TYPE.POST_UPLOAD_FILES, {
      shouldShowAfterCreationDialog,
      skipFileBrowser: true,
      initialFiles: acceptedFiles.map((file) => ({ file })),
      onCompleted: (data) => {
        setActivityInput((prev) => ({
          ...prev,
          assetIds: [...prev.assetIds, ...data.map((post) => post.id)].filter(
            (id, index, self) => self.indexOf(id) === index,
          ),
        }));
      },
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      '*/*': ['.*'],
    },
    onDragEnter: () => setIsDragging(true),
    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget as Node | null;
      if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
        setIsDragging(false);
      }
    },
    noClick: true,
  });

  // Extract the props we want to use from getRootProps
  const { ref, onDragEnter, onDragLeave, ...dropzoneProps } = getRootProps();

  // Create combined drag handlers
  const handleDragEnter = (e: React.DragEvent) => {
    setIsDragging(true);
    onDragEnter?.(e as React.DragEvent<HTMLDivElement>);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    const relatedTarget = e.relatedTarget as Node | null;
    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setIsDragging(false);
    }
    onDragLeave?.(e as React.DragEvent<HTMLDivElement>);
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        ref,
        sx: {
          width: '100%',
          maxWidth: 850,
          borderRadius: 6,
          border: isDragging
            ? `2px dashed ${theme.colors?.utility[700]}`
            : 'none',
          background: isDragging
            ? 'rgba(255, 255, 255, 0.95)'
            : 'rgba(255, 255, 255, 0.90)',
          boxShadow:
            '0px 8px 22px -6px rgba(24, 39, 75, 0.12), 0px 14px 64px -4px rgba(24, 39, 75, 0.12)',
          backdropFilter: 'blur(3px)',
          minHeight: 490,
          maxHeight: 670,
          position: 'relative',
        },
      }}
      {...dropzoneProps}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      <input {...getInputProps()} />
      {isDragging && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              background: 'white',
              borderRadius: 4,
              p: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              boxShadow: '0px 8px 22px -6px rgba(24, 39, 75, 0.12)',
            }}
          >
            <IconLinearDocumentUpload
              size={32}
              color={theme.colors?.primary.black}
            />
            <Typography
              variant="headline-md"
              color={theme.colors?.utility[900]}
            >
              Drop files here
            </Typography>
          </Box>
        </Box>
      )}
      <DialogTitle
        sx={{
          p: theme.spacing(9, 10, 0, 10),
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton onClick={onClose} sx={{ padding: 0 }} disableRipple>
          <IconOutlineCloseCircle color={theme.colors?.utility[700]} />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 6,
          my: 4,
          padding: theme.spacing(0, 10),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 3,
          }}
        >
          <Input
            disableUnderline
            fullWidth
            placeholder="Activity"
            value={activityInput.title}
            onChange={(e) => {
              setActivityInput((prev) => ({
                ...prev,
                title: e.target.value,
              }));
            }}
            sx={{
              ...theme.typography['headline-xl'],
              fontSize: 28,
              fontWeight: 500,
              letterSpacing: '-1.12px',
              input: {
                p: 0,
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              p: 1,
              pr: 2,
              borderRadius: 6,
              background: 'rgba(35, 6, 3, 0.07)',
            }}
          >
            <Avatar
              user={user as unknown as UserFragmentAvatarGroupFragment}
              size={20}
            />
            <Typography variant="subhead-lg" whiteSpace="nowrap">
              {getFullName(user)}
            </Typography>
          </Box>
        </Box>
        <Input
          disableUnderline
          fullWidth
          multiline
          maxRows={10}
          placeholder="Add a small summary..."
          value={activityInput.summary}
          onChange={(e) => {
            setActivityInput((prev) => ({
              ...prev,
              summary: e.target.value,
            }));
          }}
          sx={{
            ...theme.typography['subhead-lg'],
            p: 0,
            input: {
              p: 0,
            },
          }}
        />
        <Divider sx={{ borderColor: theme.colors?.utility[400], my: 2 }} />

        <StyledInoutContainer>
          {renderInputLabel(IconLinearUserOctagon, 'Creator')}
          <SocialListeningRadarCreatorAutocomplete
            brandId={brandId}
            selectedCreatorIds={activityInput.createdForCreatorProfileIds}
            onToggleCreator={onToggleCreator}
          />
        </StyledInoutContainer>
        {/* NOTE: Removed for now by Megan's request */}
        {/* <StyledInoutContainer>
          {renderInputLabel(IconOutlineArrowSwapHorizontal, 'Direction')}
          <SocialListeningRadarDirectionSelector
            selectedDirection={activityInput.direction}
            onSelectDirection={(direction) => {
              setActivityInput((prev) => ({
                ...prev,
                direction,
              }));
            }}
          />
        </StyledInoutContainer> */}
        <StyledInoutContainer>
          {renderInputLabel(IconLinearSend2, 'Communicated via')}
          <SocialListeningRadarCommunicationChannelSelector
            selectedCommunicationChannelIds={
              activityInput.radarEventActivityCommunicationChannelIds
            }
            onChange={(ids) => {
              setActivityInput((prev) => ({
                ...prev,
                radarEventActivityCommunicationChannelIds: ids,
              }));

              analytics.track(EventName.ActivityChannelSelected, {
                brandId,
                userId: user?.id,
                activityChannelIds: ids,
              });
            }}
            onDelete={(v) => {
              setActivityInput((prev) => ({
                ...prev,
                radarEventActivityCommunicationChannelIds:
                  prev.radarEventActivityCommunicationChannelIds.filter(
                    (id) => id !== v,
                  ),
              }));
            }}
          />
        </StyledInoutContainer>
        <StyledInoutContainer>
          {renderInputLabel(IconOutlineCalendar1, 'Date & Time')}
          <DatePicker
            value={activityInput.dateTime}
            onChange={(date) => {
              if (date) {
                setActivityInput((prev) => ({
                  ...prev,
                  dateTime: date,
                }));
              } else {
                setActivityInput((prev) => ({
                  ...prev,
                  dateTime: null,
                }));
              }
            }}
            renderInput={(props) => {
              const { inputRef, inputProps, openPicker } = props;
              const { value } = inputProps || {};

              return (
                <Typography
                  ref={inputRef}
                  component="button"
                  variant="subhead-lg"
                  color={!value ? theme.colors?.utility[600] : undefined}
                  onClick={openPicker}
                  sx={{
                    display: 'block',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  {value
                    ? moment(value).format('MMMM D, YYYY | hh:mmA')
                    : 'Add date'}
                </Typography>
              );
            }}
            includeTimeByDefault
          />
        </StyledInoutContainer>
        <StyledInoutContainer>
          {renderInputLabel(IconLinearNote, 'Assets')}
          <SocialListeningAssetsSelector
            selectedAssetIds={activityInput.assetIds}
            onAfterPostCreated={(postIds) => {
              analytics.track(EventName.ActivityAssetAdded, {
                brandId,
                userId: user?.id,
                assetIds: postIds,
              });

              setActivityInput((prev) => ({
                ...prev,
                assetIds: [...prev.assetIds, ...postIds].filter(
                  (id, index, self) => self.indexOf(id) === index,
                ),
              }));
            }}
          />
        </StyledInoutContainer>
      </DialogContent>
      <DialogActions
        sx={{
          background: 'rgba(35, 6, 3, 0.07)',
          backdropFilter: 'blur(6px)',
          padding: theme.spacing(4, 10),
        }}
      >
        <Button
          variant="primary-alt"
          onClick={onSubmit}
          disabled={!canSubmit}
          sx={{
            borderRadius: 2,
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};
