import { Box, styled } from '@mui/material';
import { theme } from 'styles/theme';

export const StyledSection = styled(Box)({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(3),
  background: theme.colors?.utility[250],
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  height: '100%',
});

export const StyledSectionTitle = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
});
