import {
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { IconBoldInstagram } from 'components/icons/components/bold/IconBoldInstagram';
import { IconBoldShare } from 'components/icons/components/bold/IconBoldShare';
import { IconBoldTag } from 'components/icons/components/bold/IconBoldTag';
import { IconCustomTiktok } from 'components/icons/components/custom/IconCustomTiktok';
import { IconCustomUsers } from 'components/icons/components/custom/IconCustomUsers';
import { IconCustomVideo } from 'components/icons/components/custom/IconCustomVideo';
import { IconLinearExport4 } from 'components/icons/components/linear/IconLinearExport4';
import {
  CommunityLabelColorMap,
  RelationshipBackgroundColorMap,
  RelationshipColorMap,
  RelationshipLabelMap,
  SentimentBackgroundColorMap,
  SentimentColorMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity';
import { platformIconMap } from 'features/socialPost';
import {
  Platform,
  Sentiment,
  SocialListeningCreatorProfileMergeViewFragmentCreatorProfileFragment,
} from 'graphql/generated';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';
import { formatBigNumber } from 'utils/number';

interface SocialListeningCreatorProfileHoverViewProps {
  creatorProfile: SocialListeningCreatorProfileMergeViewFragmentCreatorProfileFragment;
  totalComments: number;
  brandMentionCount: number;
  sentimentScore: number;
}

export const SocialListeningCreatorProfileHoverView = ({
  creatorProfile,
  totalComments,
  brandMentionCount,
  sentimentScore,
}: SocialListeningCreatorProfileHoverViewProps) => {
  const audienceDemographics =
    creatorProfile.creators.find((creator) => creator.audience_demographics)
      ?.audience_demographics ?? '';

  const industries = creatorProfile.creators
    .flatMap((creator) => creator.industry?.split('|'))
    .filter(Boolean);

  const otherInsights = creatorProfile.creators
    .flatMap((creator) => creator.otherInsights?.split('|'))
    .filter(Boolean);

  const stats = useMemo(() => {
    const sentiment =
      sentimentScore > 0
        ? Sentiment.Positive
        : sentimentScore < 0
        ? Sentiment.Negative
        : Sentiment.Neutral;

    return [
      {
        title: 'Brand mentions',
        value: formatBigNumber(brandMentionCount),
        color: theme.colors?.utility['teal-4'],
        backgroundColor: 'rgba(205, 226, 223, 0.50)',
      },
      {
        title: 'Comments',
        value: formatBigNumber(totalComments),
        color: theme.colors?.utility['purple-4'],
        backgroundColor: 'rgba(230, 221, 237, 0.50)',
      },
      {
        title: 'Sentiment',
        value: SentimentLabelMap[sentiment],
        color: SentimentColorMap[sentiment],
        backgroundColor: SentimentBackgroundColorMap[sentiment],
      },
    ];
  }, [brandMentionCount, sentimentScore, totalComments]);

  const renderLabel = useCallback(({ icon: Icon, label, containerSx = {} }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          width: '40%',
          flexShrink: 0,
          height: '24px',
          lineHeight: '24px',
          ...containerSx,
        }}
      >
        <Icon size={16} color={theme.colors?.utility[1100]} />
        <Typography variant="headline-sm" color={theme.colors?.primary.black}>
          {label}
        </Typography>
      </Box>
    );
  }, []);

  const isTiktokCreatorExist = creatorProfile.creators.some(
    (creator) => creator.platform === Platform.Tiktok,
  );

  const isInstagramCreatorExist = creatorProfile.creators.some(
    (creator) => creator.platform === Platform.Instagram,
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="headline-lg">{creatorProfile.fullName}</Typography>

        <Link
          to={PlotRoutes.socialListeningCreatorProfile(
            creatorProfile.brandId,
            creatorProfile.id,
          )}
          target="_blank"
        >
          <IconButton
            sx={{
              borderRadius: 2,
              bgcolor: theme.colors?.utility[300],
              p: theme.spacing(2, 2.5),
            }}
          >
            <IconLinearExport4 size={16} />
          </IconButton>
        </Link>
      </Box>
      <Typography
        variant="subhead-xl"
        color={theme.colors?.utility[700]}
        mt={-2}
      >
        {creatorProfile.creators.length} platform added
      </Typography>
      <Grid container spacing={4}>
        {stats.map((stat) => (
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                padding: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3.5,
                borderRadius: 4,
                background: stat.backgroundColor,
              }}
            >
              <Typography
                variant="headline-xs"
                color={theme.colors?.utility[700]}
              >
                {stat.title}
              </Typography>
              <Typography
                variant="headline-xl"
                fontSize={28}
                color={stat.color}
                fontWeight={500}
              >
                {stat.value}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {creatorProfile.creators.map((creator) => {
        const PlatformIcon = platformIconMap[creator.platform];
        return (
          <Box
            sx={{
              display: 'flex',
              p: 3,
              borderRadius: 3,
              border: `1px solid ${theme.colors?.utility[300]}`,
              justifyContent: 'space-between',
              alignItems: 'center',
              background: theme.colors?.primary.white,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
              }}
            >
              <PlatformIcon size={20} color={theme.colors?.utility[700]} />
              <Typography
                variant="subhead-xl"
                color={theme.colors?.utility[700]}
              >
                ·
              </Typography>
              <Avatar
                src={creator.profilePictureUrl || ''}
                sx={{ width: 20, height: 20 }}
                variant="circular"
              />
              <Typography variant="headline-sm" ml={1}>
                {creator.handle}
              </Typography>
            </Box>
            <Typography variant="headline-sm">
              {formatBigNumber(creator.videoCount)}
              <Typography variant="subhead-xl" ml={2}>
                posts
              </Typography>
            </Typography>
            <Typography variant="headline-sm">
              {formatBigNumber(creator.followerCount)}
              <Typography variant="subhead-xl" ml={2}>
                followers
              </Typography>
            </Typography>
          </Box>
        );
      })}
      {(!isInstagramCreatorExist || !isTiktokCreatorExist) && (
        <Box
          sx={{
            display: 'flex',
            p: 3,
            borderRadius: 3,
            border: `1px solid ${theme.colors?.utility[300]}`,
            alignItems: 'center',
            background: theme.colors?.primary.white,
            gap: 1,
          }}
        >
          {!isInstagramCreatorExist ? (
            <IconBoldInstagram size={20} color={theme.colors?.utility[700]} />
          ) : (
            <IconCustomTiktok size={20} color={theme.colors?.utility[700]} />
          )}
          <Typography variant="headline-sm" color={theme.colors?.utility[700]}>
            {!isInstagramCreatorExist ? 'Instagram' : 'TikTok'} username not
            available
          </Typography>
        </Box>
      )}
      <Divider sx={{ my: 2, borderColor: 'rgba(35, 6, 3, 0.07)' }} />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
        {renderLabel({ icon: IconBoldShare, label: 'Relationship' })}
        <Box flex={1}>
          <Chip
            variant="filled-borderless"
            sx={{
              borderRadius: 2,
              bgcolor:
                RelationshipBackgroundColorMap[
                  creatorProfile.relationshipStrength
                ],
              span: {
                ...theme.typography['headline-sm'],
                color:
                  RelationshipColorMap[creatorProfile.relationshipStrength],
              },
            }}
            label={RelationshipLabelMap[creatorProfile.relationshipStrength]}
          />
        </Box>
      </Box>
      {creatorProfile.labels.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: 4,
          }}
        >
          {renderLabel({
            icon: IconBoldTag,
            label: 'Labels',
          })}
          <Box flex={1} display="flex" flexWrap="wrap" gap={1}>
            {creatorProfile.labels.map((label) => (
              <Chip
                variant="filled-borderless"
                sx={{
                  borderRadius: 2,
                  bgcolor:
                    label.bgColor ?? CommunityLabelColorMap[0].backgroundColor,

                  span: {
                    ...theme.typography['headline-sm'],
                    color: label.color ?? CommunityLabelColorMap[0].color,
                  },
                }}
                label={label.label}
              />
            ))}
          </Box>
        </Box>
      )}

      {audienceDemographics && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {renderLabel({
            icon: IconCustomUsers,
            label: 'Audience',
            containerSx: {
              gap: 2,
            },
          })}
          <Typography
            component="p"
            variant="subhead-xl"
            color={theme.colors?.utility[900]}
          >
            {audienceDemographics}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            {industries.map((industry) => (
              <Chip
                key={industry}
                variant="filled-borderless"
                label={industry}
              />
            ))}
          </Box>
        </Box>
      )}
      {otherInsights.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          {renderLabel({
            icon: IconCustomVideo,
            label: 'Content Type',
            containerSx: {
              gap: 2,
            },
          })}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            {otherInsights.map((insight) => (
              <Typography
                key={insight}
                component="p"
                variant="subhead-xl"
                color={theme.colors?.utility[900]}
              >
                {insight}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
