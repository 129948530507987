import { Box, Input, styled } from '@mui/material';
import { DRAWER_WIDTH } from './LeftDrawer/LeftDrawer';

export const StyledImageContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(3, 0, 3, 2),
  background: '#230603FF',

  [theme.breakpoints.down('md')]: {
    height: '70vh',
  },

  '& .transparent-overlay': {
    margin: theme.spacing(3, 0, 3, 2),
    position: 'absolute',
    inset: 0,
    backgroundColor: `rgba(0, 0, 0, 0.30)`,
    borderRadius: theme.spacing(6),
  },

  '& .title': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    position: 'absolute',
    paddingRight: theme.spacing(10),
    bottom: theme.spacing(13),
    left: theme.spacing(10),
    color: theme.colors?.primary.white,
  },

  '& .auth-cover': {
    minWidth: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: theme.spacing(6),
  },
}));

export const StyledAuthFormContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(10),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100%',
  width: '100%',
}));

export const StyledSearch = styled(Input)(({ theme }) => ({
  border: 'none',
  width: '100%',
  borderRadius: 100,
  padding: `4px 12px`,
  backgroundColor: 'rgba(241, 241, 241, 0.4)',
  fontSize: 12,
  color: theme.colors?.primary.white,
  '& ::placeholder': {
    color: theme.colors?.primary.white,
    opacity: 1,
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: 450,
  },
}));

export const StartOptionsGroupWrapper = styled(Box)<{ isOpenDrawer: boolean }>(
  ({ theme, isOpenDrawer }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(4.5),
    color: 'inherit',

    // It's specific area for opening the drawer
    // when hover over this area, the drawer will open
    minHeight: 'calc(100vh - 64px)',
    position: 'absolute',
    width: isOpenDrawer ? DRAWER_WIDTH : 32,
    zIndex: 999,
    marginTop: -10,
  }),
);
