import { Box, TextField, Typography } from '@mui/material';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { typography } from 'components/common/Typography/styles';
import { countries } from 'countries-list';
import {
  socialListeningFilterIconMap,
  SocialListeningFiltersKey,
} from 'pages/socialMediaListening/constant';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

type FilterByRegionProps = {
  selectedRegions: string[];
  type?: 'accordion' | 'normal';
  renderTitle?: () => React.ReactNode;
  onChange: (values: { selectedRegions: string[] }) => void;
  options: string[];
};

export const FilterByRegion = ({
  selectedRegions,
  type = 'accordion',
  onChange,
  renderTitle,
  options,
}: FilterByRegionProps) => {
  const dropdownOptions = useMemo(() => {
    return options.map((region) => ({
      label: countries[region]?.name || region,
      value: region,
      onClick: () => {
        onChange({
          selectedRegions: selectedRegions.includes(region)
            ? selectedRegions.filter((o) => o !== region)
            : [...selectedRegions, region],
        });
      },
      isChecked: selectedRegions.includes(region),
    }));
  }, [options, selectedRegions, onChange]);

  const [optionsSearch, setOptionsSearch] = useState('');
  const filteredOptions = useMemo(() => {
    if (!optionsSearch) {
      return dropdownOptions;
    }

    const searchRegex = new RegExp(optionsSearch, 'gi');
    return dropdownOptions.filter((option) =>
      searchRegex.test(option.label?.toString() ?? ''),
    );
  }, [optionsSearch, dropdownOptions]);

  const Icon = socialListeningFilterIconMap[SocialListeningFiltersKey.Regions];

  const renderLabel = useMemo(() => {
    if (renderTitle) {
      return renderTitle();
    }

    if (type === 'normal') {
      return <Typography variant="subhead-lg">Country</Typography>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(35, 6, 3, 0.05)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(2),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Icon size={16} />
        </Box>

        <Typography variant="subhead-lg">Country</Typography>
      </Box>
    );
  }, [Icon, renderTitle, type]);

  return (
    <DropDownMoreMenuItem
      disableRipple
      defaultOpen={type === 'normal'}
      sx={{
        ...(type === 'normal' && {
          pointerEvents: 'none',
        }),
        svg: {
          color: theme.colors?.primary.black,
          ...(type === 'normal' && {
            display: 'none',
          }),
        },
      }}
      label={renderLabel}
      renderDropDownOptionSearchInput={() => (
        <TextField
          variant="outlined"
          placeholder="Search"
          sx={{
            '.MuiOutlinedInput-root': {
              py: '6px !important',
              pl: '16px !important',
              borderRadius: 25,
              boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.20) inset',
              mb: 2,
              input: {
                ...typography['subhead-lg'],
                p: '0 !important',
                '::placeholder': {
                  color: theme.colors?.utility[600],
                },
              },

              '.MuiOutlinedInput-notchedOutline': {
                display: 'none !important',
              },
            },
          }}
          value={optionsSearch}
          onChange={(ev) => {
            setOptionsSearch(ev.target.value);
          }}
          onKeyDown={(ev) => {
            ev.stopPropagation();
          }}
        />
      )}
      dropDownOptions={filteredOptions}
    />
  );
};
