import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Typography,
} from '@mui/material';
import { CheckboxMenuItem } from 'components/common/form/Select';
import { DropDownMoreMenuItem } from 'components/common/Menu';
import { IconBoldInfoCircle } from 'components/icons/components/bold/IconBoldInfoCircle';
import { TopicFragmentCreatorPeekingContentFragment } from 'graphql/generated';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PlotRoutes } from 'Routes';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  fragment TopicFragmentCreatorPeekingContent on TopicModel {
    id
    name
  }
`;

export interface CreatorPeekingContentProps {
  selectedTopicIds: string[];
  topics: TopicFragmentCreatorPeekingContentFragment[];
  handleEngagementTracking: (topicId: string) => void;
  isTrackingInsights: boolean;
  handleUntrackEngagementTrackingForTopics: (topicIds: string[]) => void;
  handleUpdateCreatorTrackingInsights: (isTrackingInsights: boolean) => void;
  brandId: string;
  componentsProps?: {
    item?: {
      sx?: SxProps;
    };
    itemOption?: {
      sx?: SxProps;
    };
  };
}

export const CreatorPeekingContent = ({
  selectedTopicIds,
  topics,
  handleEngagementTracking,
  isTrackingInsights,
  handleUntrackEngagementTrackingForTopics,
  handleUpdateCreatorTrackingInsights,
  brandId,
  componentsProps = {},
}: CreatorPeekingContentProps) => {
  const peekingPopover = useDisclosure();
  const [isEngagementTrackingLoading, setIsEngagementTrackingLoading] =
    useState(false);

  const [isTrackingInsightsLoading, setIsTrackingInsightsLoading] =
    useState(false);

  // will store the topic id that is being tracked
  const [topicIdForTrackingLoading, setTopicIdForTrackingLoading] =
    useState('');

  const [isEngagementTracking, setIsEngagementTracking] = useState(
    selectedTopicIds.length > 0,
  );

  useEffect(() => {
    setIsEngagementTracking(selectedTopicIds.length > 0);
  }, [selectedTopicIds]);

  const onToggleEngagementTracking = async () => {
    if (isEngagementTrackingLoading || topicIdForTrackingLoading) return;
    if (isEngagementTracking && selectedTopicIds.length) {
      setIsEngagementTrackingLoading(true);
      await handleUntrackEngagementTrackingForTopics(selectedTopicIds);
      setIsEngagementTrackingLoading(false);
      peekingPopover.onClose();
    } else {
      setIsEngagementTracking(!isEngagementTracking);
    }
  };

  const renderOptionTitle = (title: string, description: string) => {
    return (
      <Box sx={{ display: 'grid', alignItems: 'center', mt: -0.5 }}>
        <Typography variant="headline-sm">{title}</Typography>
        <Typography
          variant="subhead-xl"
          color={theme.colors?.utility[700]}
          whiteSpace="break-spaces"
        >
          {description}
        </Typography>
      </Box>
    );
  };

  const renderLoader = () => {
    return (
      <CircularProgress size={20} sx={{ color: theme.colors?.primary.black }} />
    );
  };
  return (
    <>
      <Box sx={componentsProps.item?.sx}>
        <DropDownMoreMenuItem
          componentsProps={{
            dropDownOptions: {
              container: {
                sx: {
                  pl: 6,
                  maxHeight: 150,
                  overflowY: 'auto',
                  '::-webkit-scrollbar': {
                    width: 0,
                  },
                  ...componentsProps.itemOption?.sx,
                },
              },
            },
          }}
          disabled={!topics.length}
          dropDownOptions={[
            ...(isEngagementTracking && !selectedTopicIds.length
              ? [
                  {
                    label: 'error',
                    value: 'error',
                    renderOption: () => {
                      return (
                        <Typography
                          variant="headline-sm"
                          component="div"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.colors?.utility['pink-3'],
                            gap: 1,
                            ml: 2,
                            my: 2,
                          }}
                        >
                          <IconBoldInfoCircle
                            color={theme.colors?.utility['pink-3']}
                          />
                          Select a topic to Peek at this creator
                        </Typography>
                      );
                    },
                  },
                ]
              : []),
            ...(!topics.length
              ? [
                  {
                    label: 'New Creator topic',
                    value: 'new-creator-topic',
                    renderOption: () => {
                      return (
                        <Box
                          sx={{
                            p: 6,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 3,
                            borderRadius: 3,
                            background: theme.colors?.utility[275],
                            mt: 1,
                          }}
                        >
                          <Typography
                            variant="subhead-xl"
                            color={theme.colors?.utility[800]}
                          >
                            Add your first Creator Topic to begin tracking
                            creators for proactive engagement.{' '}
                          </Typography>
                          <Link
                            to={PlotRoutes.socialListeningOnboarding(
                              'creator',
                              brandId,
                            )}
                          >
                            <Button
                              variant="primary-alt"
                              fullWidth
                              sx={{
                                borderRadius: 2,
                                border: `2px solid ${theme.colors?.primary.black}`,
                                padding: theme.spacing(2, 4),
                                background: 'transparent',
                                color: theme.colors?.primary.black,
                                '&:hover': {
                                  background: 'transparent',
                                  color: theme.colors?.primary.black,
                                },
                              }}
                            >
                              + New Creator topic
                            </Button>
                          </Link>
                        </Box>
                      );
                    },
                  },
                ]
              : topics.map((topic) => ({
                  label: topic.name,
                  value: topic.id,
                  renderOption: () => {
                    return (
                      <CheckboxMenuItem
                        label={topic.name}
                        value={topic.id}
                        checked={selectedTopicIds.includes(topic.id)}
                        sx={{
                          overflow: 'none',
                          span: {
                            ...theme.typography['subhead-xl'],
                            mt: 0.5,
                          },
                        }}
                        {...(topicIdForTrackingLoading === topic.id
                          ? {
                              renderCheckbox: renderLoader,
                            }
                          : {})}
                        disabled={
                          isEngagementTrackingLoading ||
                          topicIdForTrackingLoading === topic.id
                        }
                        isCheckboxExclusiveClick={false}
                        onClick={async () => {
                          setTopicIdForTrackingLoading(topic.id);
                          await handleEngagementTracking(topic.id);
                          setTopicIdForTrackingLoading('');
                        }}
                      />
                    );
                  },
                }))),
          ]}
          label={
            <Box onClick={onToggleEngagementTracking}>
              {renderOptionTitle(
                'Engagement',
                'See all the creator’s posts in your engagement dashboard so you can engage daily.',
              )}
            </Box>
          }
          defaultOpen
          renderHeaderAsOption
          defaultChecked={isEngagementTracking}
          onClick={onToggleEngagementTracking}
          {...(isEngagementTrackingLoading
            ? {
                headerCustomIcon: renderLoader,
              }
            : {})}
          sx={{
            '& .dropdown-more-menu-item-toggle-button': {
              display: 'none',
              pointerEvents: 'none',
            },
          }}
        />
      </Box>
      <CheckboxMenuItem
        label={renderOptionTitle(
          'AI Creator Insights',
          'Track personal moments (eg: birthdays), brand partnerships, and their favorite products from your brand.',
        )}
        isCheckboxExclusiveClick={false}
        value="creator-insights"
        onClick={async () => {
          setIsTrackingInsightsLoading(true);
          await handleUpdateCreatorTrackingInsights(!isTrackingInsights);
          setIsTrackingInsightsLoading(false);
        }}
        checked={isTrackingInsights}
        sx={componentsProps.item?.sx}
        disabled={isTrackingInsightsLoading}
        {...(isTrackingInsightsLoading
          ? {
              renderCheckbox: renderLoader,
            }
          : {})}
      />
    </>
  );
};
