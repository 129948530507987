import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import {
  DateFilterType as DefaultDateFilterType,
  ReactionFilterType,
} from 'graphql/generated';
import { DateFilterType } from '../filters';

export const getReactionState = (reactionFilter: ReactionFilterType) => {
  switch (reactionFilter) {
    case ReactionFilterType.Like:
      return {
        emoji: '👍',
        label: 'This one!',
      };
    case ReactionFilterType.Dislike:
      return {
        emoji: '👎',
        label: "Don't love",
      };
    case ReactionFilterType.Confused:
      return {
        emoji: '🤔',
        label: 'Hmmm',
      };
    default:
      return {
        emoji: '⚰️',
        label: 'Archive',
      };
  }
};

export const getDateModifiedState = (dateFilter: DefaultDateFilterType) => {
  switch (dateFilter) {
    case DefaultDateFilterType.Today:
      return {
        value: DateFilterType.Today,
        text: 'Today',
      };
    case DefaultDateFilterType.LastSevenDays:
      return {
        value: DateFilterType.Last7Days,
        text: 'Last 7 days',
      };
    case DefaultDateFilterType.LastThirtyDays:
      return {
        value: DateFilterType.Last30Days,
        text: 'Last 30 days',
      };
    case DefaultDateFilterType.ThisYear:
      return {
        value: DateFilterType.ThisYear,
        text: `This year ${new Date().getFullYear()}`,
      };
    case DefaultDateFilterType.LastYear:
      return {
        value: DateFilterType.LastYear,
        text: `Last year ${new Date().getFullYear() - 1}`,
      };
    default:
      return {
        value: DateFilterType.CustomRange,
        text: CUSTOM_RANGE_LABEL,
      };
  }
};

export const COLLECTION_POST_VIEW_SETTING = 'collectionPostViewSetting';
