import { gql } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import { InfiniteScrollBoundary } from 'components/common/InfiniteScrollBoundary';
import {
  RadarEventCommunityUpdateItemSkeleton,
  RadarEventCommunityUpdateItemView,
} from 'features/radarEvent';
import {
  PaginatedRadarEventInput,
  RadarEventFragmentRadarEventCommunityUpdateItemViewFragmentDoc,
  RadarEventReadStatus,
  RadarEventViewType,
  useGetPaginatedRadarEventsForSlRadarEventsViewQuery,
  useMarkRadarEventAsReadForSlRadarEventsViewMutation,
} from 'graphql/generated';
import { useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';

// eslint-disable-next-line
gql`
  query GetPaginatedRadarEventsForSLRadarEventsView(
    $input: PaginatedRadarEventInput!
  ) {
    getPaginatedRadarEvents(input: $input) {
      data {
        id
        ...RadarEventFragmentRadarEventCommunityUpdateItemView
      }
      pageInfo {
        hasNextPage
      }
      meta {
        totalCount
      }
    }
  }
  ${RadarEventFragmentRadarEventCommunityUpdateItemViewFragmentDoc}
`;

// eslint-disable-next-line
gql`
  mutation MarkRadarEventAsReadForSLRadarEventsView(
    $input: MarkRadarEventAsReadInput!
  ) {
    markRadarEventAsRead(input: $input) {
      message
      success
    }
  }
`;

export type SocialListeningRadarEventsViewProps = {
  filters: PaginatedRadarEventInput;
  setTotalCount?: (totalCount: number) => void;
};

export const SocialListeningRadarEventsView = ({
  filters,
  setTotalCount,
}: SocialListeningRadarEventsViewProps) => {
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const {
    data,
    loading: radarEventsLoading,
    fetchMore,
  } = useGetPaginatedRadarEventsForSlRadarEventsViewQuery({
    variables: {
      input: {
        ...filters,
        viewType: RadarEventViewType.KeyEvents,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const isLoading = radarEventsLoading || isLoadingMore;

  const radarEvents = useMemo(
    () => data?.getPaginatedRadarEvents.data ?? [],
    [data],
  );
  const hasNextPage = data?.getPaginatedRadarEvents.pageInfo.hasNextPage;

  const [markRadarEventAsRead] =
    useMarkRadarEventAsReadForSlRadarEventsViewMutation();

  useEffect(() => {
    const unreadRadarEventIds = radarEvents.filter(
      (radarEvent) => radarEvent.readStatus === RadarEventReadStatus.Unread,
    );

    if (unreadRadarEventIds.length > 0) {
      markRadarEventAsRead({
        variables: {
          input: {
            radarEventIds: unreadRadarEventIds.map(
              (radarEvent) => radarEvent.id,
            ),
          },
        },
      });
    }
  }, [radarEvents]); // eslint-disable-line

  useEffect(() => {
    setTotalCount?.(data?.getPaginatedRadarEvents.meta.totalCount ?? 0);
  }, [data?.getPaginatedRadarEvents.meta.totalCount]); // eslint-disable-line

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, pb: 12 }}>
      {radarEvents.length > 0 &&
        radarEvents.map((radarEvent, index) => {
          return (
            <RadarEventCommunityUpdateItemView
              key={index}
              radarEvent={radarEvent}
            />
          );
        })}
      {isLoading &&
        [...Array(radarEventsLoading ? 4 : 1)].map((_, index) => (
          <RadarEventCommunityUpdateItemSkeleton
            key={index}
            isUnread={
              !filters.readStatuses?.includes(RadarEventReadStatus.Read)
            }
          />
        ))}

      {!isLoading && !radarEvents.length && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 300px)',
          }}
        >
          <Typography variant="headline-lg" color={theme.colors?.utility[500]}>
            No New Updates
          </Typography>
        </Box>
      )}
      <InfiniteScrollBoundary
        disabled={radarEventsLoading || !hasNextPage}
        onVisible={() => {
          setIsLoadingMore(true);
          fetchMore({
            variables: {
              input: {
                ...filters,
                skip: radarEvents.length,
              },
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setIsLoadingMore(false);
              if (!fetchMoreResult) return prev;

              return {
                getPaginatedRadarEvents: {
                  ...fetchMoreResult.getPaginatedRadarEvents,
                  data: [
                    ...prev.getPaginatedRadarEvents.data,
                    ...fetchMoreResult.getPaginatedRadarEvents.data,
                  ],
                },
              };
            },
          });
        }}
      />
    </Box>
  );
};
