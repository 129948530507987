import { gql } from '@apollo/client';
import { useDisclosure } from '@dwarvesf/react-hooks';
import { Box, Grid, Typography } from '@mui/material';
import { Tooltip } from 'components/common/Tooltip';
import { IconBoldInfoCircleAlt } from 'components/icons/components/bold/IconBoldInfoCircleAlt';
import {
  SentimentBackgroundColorMap,
  SentimentColorMap,
  SentimentLabelMap,
} from 'features/socialListeningCommunity';
import {
  CreatorProfileFragmentSlCreatorProfileSentimentDialogViewFragmentDoc,
  CreatorProfileFragmentSlCreatorProfileStatsViewFragment,
  Platform,
  Sentiment,
  SimpleDateRangeFilterInput,
} from 'graphql/generated';
import { theme } from 'styles/theme';
import { SocialListeningCreatorProfileBrandMentionDialogView } from '../brandMentionDialog';
import { SocialListeningCreatorProfileSentimentDialogView } from '../sentimentDialog';
import { SocialListeningCreatorProfileSuperfanEngagementDialogView } from '../superfanEngagementDialog';
import { StyledStatBox } from './styles';
import { EventName, useAnalytics } from 'hooks/useAnalytics';
import { useUserContext } from 'contexts/users/User.context/User.context';

export const CREATOR_PROFILE_FRAGMENT_SL_CREATOR_PROFILE_STATS_VIEW = gql`
  fragment CreatorProfileFragmentSLCreatorProfileStatsView on CreatorProfileModel {
    id
    ...CreatorProfileFragmentSLCreatorProfileSentimentDialogView
  }
  ${CreatorProfileFragmentSlCreatorProfileSentimentDialogViewFragmentDoc}
`;

export type SocialListeningCreatorProfileStatsViewProps = {
  creatorProfile: CreatorProfileFragmentSlCreatorProfileStatsViewFragment;
  brandMentionCount: number;
  superfanEngagementCount: number;
  sentimentScore: number;
  filters: {
    platforms?: Platform[];
    dateRange?: SimpleDateRangeFilterInput;
  };
};

export const SocialListeningCreatorProfileStatsView = (
  props: SocialListeningCreatorProfileStatsViewProps,
) => {
  const {
    creatorProfile,
    brandMentionCount,
    superfanEngagementCount,
    sentimentScore,
    filters,
  } = props;

  const analytics = useAnalytics();
  const { user } = useUserContext();

  const {
    isOpen: isBrandMentionDialogOpen,
    onOpen: openBrandMentionDialog,
    onClose: closeBrandMentionDialog,
  } = useDisclosure();
  const {
    isOpen: isSuperfanEngagementDialogOpen,
    onOpen: openSuperfanEngagementDialog,
    onClose: closeSuperfanEngagementDialog,
  } = useDisclosure();
  const {
    isOpen: isSentimentDialogOpen,
    onOpen: openSentimentDialog,
    onClose: closeSentimentDialog,
  } = useDisclosure();

  const sentiment =
    sentimentScore > 0
      ? Sentiment.Positive
      : sentimentScore < 0
      ? Sentiment.Negative
      : Sentiment.Neutral;

  return (
    <>
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
          <StyledStatBox
            sx={{
              background: 'rgba(205, 226, 223, 0.50)',
            }}
            role="button"
            onClick={() => {
              analytics.track(EventName.CreatorProfileAboutTileClicked, {
                brandId: creatorProfile.brandId,
                userId: user?.id,
                creatorProfileId: creatorProfile.id,
                tile: 'brand mentions',
              });
              openBrandMentionDialog();
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              Brand mentions
            </Typography>
            <Typography
              variant="headline-xl"
              fontSize={32}
              color={theme.colors?.utility['teal-4']}
              fontWeight={500}
            >
              {brandMentionCount}
            </Typography>
          </StyledStatBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledStatBox
            sx={{
              background: 'rgba(230, 221, 237, 0.50)',
            }}
            role="button"
            onClick={() => {
              analytics.track(EventName.CreatorProfileAboutTileClicked, {
                brandId: creatorProfile.brandId,
                userId: user?.id,
                creatorProfileId: creatorProfile.id,
                tile: 'superfan engagement',
              });
              openSuperfanEngagementDialog();
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
              display="flex"
              alignItems="center"
              gap={1}
            >
              Superfan engagement
              <Tooltip title="Comments on brand's posts">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconBoldInfoCircleAlt size={16} />
                </Box>
              </Tooltip>
            </Typography>
            <Typography
              variant="headline-xl"
              fontSize={32}
              color={theme.colors?.utility['purple-4']}
              fontWeight={500}
            >
              {superfanEngagementCount}
            </Typography>
          </StyledStatBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledStatBox
            sx={{
              background: SentimentBackgroundColorMap[sentiment],
            }}
            role="button"
            onClick={() => {
              analytics.track(EventName.CreatorProfileAboutTileClicked, {
                brandId: creatorProfile.brandId,
                userId: user?.id,
                creatorProfileId: creatorProfile.id,
                tile: 'sentiment',
              });
              openSentimentDialog();
            }}
          >
            <Typography
              variant="headline-xs"
              color={theme.colors?.utility[700]}
            >
              Sentiment
            </Typography>
            <Typography
              variant="headline-xl"
              fontSize={32}
              color={SentimentColorMap[sentiment]}
              fontWeight={500}
            >
              {SentimentLabelMap[sentiment]}
            </Typography>
          </StyledStatBox>
        </Grid>
      </Grid>

      {isBrandMentionDialogOpen && (
        <SocialListeningCreatorProfileBrandMentionDialogView
          creatorProfile={creatorProfile}
          open={isBrandMentionDialogOpen}
          onClose={closeBrandMentionDialog}
          filters={filters}
        />
      )}
      {isSuperfanEngagementDialogOpen && (
        <SocialListeningCreatorProfileSuperfanEngagementDialogView
          creatorProfile={creatorProfile}
          open={isSuperfanEngagementDialogOpen}
          onClose={closeSuperfanEngagementDialog}
          filters={filters}
        />
      )}
      {isSentimentDialogOpen && (
        <SocialListeningCreatorProfileSentimentDialogView
          creatorProfile={creatorProfile}
          open={isSentimentDialogOpen}
          onClose={closeSentimentDialog}
          filters={filters}
        />
      )}
    </>
  );
};
