import { useUserContext } from 'contexts/users/User.context';
import { SocialMediaListeningCommunityIntroPage } from 'pages/socialMediaListening';
import { SocialMediaListeningIntroPage } from 'pages/socialMediaListening/intro';
import { Outlet, useLocation } from 'react-router-dom';

export const SocialMediaListeningAuthLayout = () => {
  const { orgBilling, user } = useUserContext();
  const isCommunityPage = useLocation().pathname.includes('community');
  if (
    !orgBilling?.socialListeningEnabled &&
    user?.socialListeningBrands.length === 0
  ) {
    return isCommunityPage ? (
      <SocialMediaListeningCommunityIntroPage />
    ) : (
      <SocialMediaListeningIntroPage />
    );
  }

  return <Outlet />;
};
