import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { CustomDialog } from 'components/common/CustomDialog';
import { CUSTOM_RANGE_LABEL } from 'components/common/DatePicker/DateRangePicker';
import { IconLinearSort } from 'components/icons/components/linear/IconLinearSort';
import { IconOutlineCalendar1 } from 'components/icons/components/outline/IconOutlineCalendar1';
import {
  NestedFiltersDateRangeOptionType,
  NestedFiltersMenuView,
  NestedFiltersOptionType,
} from 'features/nestedFilters';
import { SLABrandInboundSentimentsView } from 'features/socialListeningAnalytics';
import {
  BrandContentType,
  BrandInboundFiltersInputForSentimentMetrics,
  CreatorProfileFragmentSlCreatorProfileSentimentDialogViewFragment,
  SentimentMedium,
} from 'graphql/generated';
import moment from 'moment';
import { dateRangeOptions } from 'pages/socialMediaListening/community/Filters';
import { useMemo, useState } from 'react';
import { theme } from 'styles/theme';

const mediumOptions = [
  { label: 'In Posts', value: SentimentMedium.InPosts },
  { label: 'In Comments', value: SentimentMedium.InComments },
];

export const CREATOR_PROFILE_FRAGMENT_SL_CREATOR_PROFILE_SENTIMENT_DIALOG_VIEW = gql`
  fragment CreatorProfileFragmentSLCreatorProfileSentimentDialogView on CreatorProfileModel {
    id
    brandId
    fullName
    creators {
      id
    }
  }
`;

export type SocialListeningCreatorProfileSentimentDialogViewProps = {
  creatorProfile: CreatorProfileFragmentSlCreatorProfileSentimentDialogViewFragment;
  open: boolean;
  filters?: Partial<
    Pick<BrandInboundFiltersInputForSentimentMetrics, 'dateRange'>
  >;
  onClose: () => void;
};

export const SocialListeningCreatorProfileSentimentDialogView = (
  props: SocialListeningCreatorProfileSentimentDialogViewProps,
) => {
  const { creatorProfile, open, filters: _filters, onClose } = props;

  const [medium, setMedium] = useState<SentimentMedium>(
    SentimentMedium.InPosts,
  );
  const [filters, setFilters] = useState<
    Omit<BrandInboundFiltersInputForSentimentMetrics, 'sentiment' | 'medium'>
  >({
    brandId: creatorProfile.brandId,
    contentType: BrandContentType.Ugc,
    dateRange: _filters?.dateRange ?? {
      startDate: dateRangeOptions[0].startDate,
      endDate: dateRangeOptions[0].endDate,
    },
    ...(medium === SentimentMedium.InPosts
      ? {
          creatorIds: creatorProfile.creators.map((creator) => creator.id),
        }
      : {
          commentCreatorIds: creatorProfile.creators.map(
            (creator) => creator.id,
          ),
        }),
  });

  const selectedDateRangeOption = useMemo(
    () =>
      dateRangeOptions.find(
        (option) =>
          moment(option.startDate).isSame(filters.dateRange.startDate, 'day') &&
          moment(option.endDate).isSame(filters.dateRange.endDate, 'day'),
      ) ?? {
        label: CUSTOM_RANGE_LABEL,
        startDate: filters.dateRange.startDate,
        endDate: filters.dateRange.endDate,
      },
    [filters],
  );
  const selectedDateRangeOptionLabel = useMemo(() => {
    if (selectedDateRangeOption.label === CUSTOM_RANGE_LABEL) {
      return `${moment(filters.dateRange.startDate).format(
        'DD MMM',
      )} - ${moment(filters.dateRange.endDate).format('DD MMM')}`;
    }

    return selectedDateRangeOption.label;
  }, [selectedDateRangeOption, filters]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      onBackClick={onClose}
      titleBreadcrumbs={[
        creatorProfile.fullName,
        'Overall Sentiment Breakdown',
      ]}
    >
      <Box
        sx={{
          p: 6,
        }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end" mb={4}>
          <Box display="flex" gap={3} alignItems="center">
            <NestedFiltersMenuView
              items={[
                {
                  type: 'date-range',
                  key: 'dateRange',
                  options: dateRangeOptions,
                },
              ]}
              values={{
                dateRange: {
                  value: selectedDateRangeOption,
                },
              }}
              onChange={(values) => {
                setFilters((o) => ({
                  ...o,
                  dateRange: {
                    startDate: (
                      values.dateRange.value as NestedFiltersDateRangeOptionType
                    ).startDate,
                    endDate: (
                      values.dateRange.value as NestedFiltersDateRangeOptionType
                    ).endDate,
                  },
                }));
              }}
              componentsProps={{
                trigger: {
                  text: selectedDateRangeOptionLabel,
                  Icon: IconOutlineCalendar1,
                  sx: {
                    bgcolor: theme.colors?.primary.parchment,
                  },
                },
              }}
            />
            <NestedFiltersMenuView
              items={[
                {
                  type: 'single-select',
                  key: 'medium',
                  options: mediumOptions,
                },
              ]}
              values={{
                medium: {
                  value: mediumOptions.find(
                    (option) => option.value === medium,
                  ),
                },
              }}
              onChange={(values) => {
                setMedium(
                  (values.medium.value as NestedFiltersOptionType)
                    ?.value as SentimentMedium,
                );
              }}
              componentsProps={{
                trigger: {
                  text:
                    medium === SentimentMedium.InPosts
                      ? 'In Posts'
                      : 'In Comments',
                  Icon: IconLinearSort,
                  sx: {
                    bgcolor: theme.colors?.primary.parchment,
                  },
                },
              }}
            />
          </Box>
        </Box>

        <SLABrandInboundSentimentsView filters={filters} medium={medium} />
      </Box>
    </CustomDialog>
  );
};
